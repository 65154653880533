import { Component } from '@angular/core';
import { MyCONST } from '../../CONST/myConst';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-details-plumitif',
  templateUrl: './view-details-plumitif.component.html',
  styleUrls: ['./view-details-plumitif.component.scss'],
})
export class ViewDetailsPlumitifComponent {
  tribunal: string = MyCONST.DETAIL_TRIBUNAL;
  data:any;

  constructor(private options: ModalOptions,private modalService: BsModalService,private datePipe: DatePipe){
    this.data = this.options.initialState.data;
    try{
      let tribunal_information:any = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
      if(tribunal_information.code){
        //console.log('UPDATE')
        this.tribunal= tribunal_information.detail_tribunal
        // this.tribunal_detail= tribunal_information.detail_tribunal
      }
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
    }
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
}
