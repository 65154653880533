import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AnimationOptions } from 'ngx-lottie';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LanguageService } from 'typescript';
import { DossierService } from '../../services/frapper/dossier.service';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { JugementsService } from '../../services/frapper/jugements.service';
import { GreffierService } from '../../services/greffier/greffier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { DocumentService } from '../../services/frapper/document.service';
import { Subscription } from 'rxjs';
import { MyCONST } from '../../CONST/myConst';
import { VisualizeRequisitoireComponent } from '../../dialogs/visualize-requisitoire/visualize-requisitoire.component';
import { VisualizePieceComponent } from '../../dialogs/visualize-piece/visualize-piece.component';
import { ViewDetailsDemandesComponent } from '../../dialogs/view-details-demandes/view-details-demandes.component';


@Component({
  selector: 'app-resume-dossier',
  templateUrl: './resume-dossier.component.html',
  styleUrls: ['./resume-dossier.component.scss']
})
export class ResumeDossierComponent implements OnInit, OnDestroy {

  @Input() dataType: string = '';

  isModal: any;
  req_ord: any = []
  docs: any = []
  doctype: any
  numero: any
  pieces: any = []
  pageDocs: number = 1
  pagePiece: number = 1
  pageOrd: number = 1
  pageReq: number = 1
  list_requisitoires: any[] = []
  list_ordonnances: any[] = []
  private subscription: Subscription;

  requisitoire = [
    {
      "continu": "À l’attention de Monsieur le Procureur de la République<br>\\n\\nNous, ..........................., Juge d’instruction au Tribunal de TGI-DKR-PIK_GUED-01,<br>\\nVu les pièces de la procédure concernant adja diop,<br>\\nVu les éléments de preuve réunis au cours de l’instruction,<br>\\nVu les articles [références légales applicables] du Code de procédure pénale,<br>\\n\\nAprès avoir constaté :<br>\\n\\nQue les faits reprochés, à savoir: <br> ........................... <br> sont établis par <b> [preuves, témoignages, ou autres éléments probants]</b> ;<br>\\nQue les éléments à charge réunis lors de l’instruction sont suffisants pour renvoyer adja diop devant le tribunal correctionnel pour y répondre des faits de:  <br> ........................... <br>Ordonnons :<br>\\n\\nLe renvoi de  adja diop devant le tribunal correctionnel de TGI-DKR-PIK_GUED-01 pour y être jugé des chefs d'accusation de:<br> ........................... <br>.\\n\\n<div class=\"float-right\">Fait à [Lieu], le [Date]</div><br>\\n\\n<br>Le Juge d’instruction,\\n[Nom du Juge]",
      "creation": "2024-11-18 15:27:34.263405",
      "destinataire": "procureur@justice.sn",
      "docstatus": 0,
      "idx": 0,
      "modified": "2024-11-18 15:27:34.263405",
      "modified_by": "juge_instruction@justice.sn",
      "name": "ORDONNANCE AU FIN DE RENVOI DEVANT LE TRIBUNAL CORRECTIONNEL-24-03960",
      "numero_dossier": "4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953",
      "owner": "juge_instruction@justice.sn",
      "tag": "ORDONNANCE AU FIN DE RENVOI DEVANT LE TRIBUNAL CORRECTIONNEL"
    },
    {
      "continu": "À l’attention de Monsieur/Madame le Juge d’instruction<br>\\n\\nNous, ..........................., Procureur de la République près le Tribunal de TGI-DKR-PIK_GUED-01,<br>\\n\\nVu les articles ........................... du Code de procédure pénale,<br>Vu les faits rapportés et les pièces jointes transmises à notre connaissance,<br>\\n\\nConsidérant que les éléments de la procédure permettent de soupçonner la commission des infractions de:<br> <b>...........................</b>,<br>\\nConsidérant que ces faits, s’ils sont établis, sont de nature à engager des poursuites pénales contre <b>fatou fall</b>,<br>\\n\\nRequérons l’ouverture d’une information judiciaire contre <b>fatou fall</b> pour les chefs d'accusations suivants :<br>\\n\\n<b>...........................</b><br>\\nDemandons qu'il soit procédé à tous actes d’information utiles, et notamment aux mesures suivantes :<br>\\n\\nAudition des témoins,\\nExpertise(s) <b> ........................... </b>,\\nConfrontation(s),<br>Réquisition des pièces nécessaires <b>[préciser si pertinent]</b>.<br>Nous laissons à votre appréciation la mise en œuvre de toutes autres investigations que vous jugerez nécessaires dans le cadre de cette instruction.<br>\\n<div class=\"float-right\">Fait à [Lieu], le [Date]</div><br>\\n\\n<br>Le Procureur de la République,\\n[Nom et signature]",
      "creation": "2024-11-18 15:20:02.700775",
      "destinataire": "juge_instruction@justice.sn",
      "docstatus": 0,
      "idx": 0,
      "modified": "2024-11-18 15:20:02.700775",
      "modified_by": "procureur@justice.sn",
      "name": "REQUISITOIRE INTRODUCTIF-24-03958",
      "numero_dossier": "4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953",
      "owner": "procureur@justice.sn",
      "tag": "REQUISITOIRE INTRODUCTIF"
    },
    {
      "continu": "Monsieur le Procureur,<br>\\n\\nSuite à votre demande d’avis concernant le choix du cabinet d’instruction pour le traitement du dossier en cours, j’ai pris en considération les éléments de l’affaire ainsi que les qualifications et disponibilités des cabinets d’instruction compétents.<br>\\n\\nÀ cet effet, je vous recommande le cabinet d’instruction ..........................., qui dispose des compétences et de l'expérience nécessaires pour traiter ce dossier avec le sérieux et l'impartialité requis. Ce cabinet est également bien positionné pour répondre aux exigences de rapidité et de rigueur que requiert cette affaire.<br>\\n\\nJe reste à votre disposition pour toute information complémentaire.<br>\\n\\nVeuillez agréer, Monsieur le Procureur, l’expression de mes salutations distinguées.<br>\\n<div class=\"float-right\">Fait à [Lieu], le [Date]</div><br>\\n\\nLe Président du Tribunal",
      "creation": "2024-11-18 14:08:23.125385",
      "destinataire": "procureur@justice.sn",
      "docstatus": 0,
      "idx": 0,
      "modified": "2024-11-18 14:08:23.125385",
      "modified_by": "president_t@justice.sn",
      "name": "Ordonnance de validation-24-03956",
      "numero_dossier": "4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953",
      "owner": "president_t@justice.sn",
      "tag": "Ordonnance de validation"
    }
  ]

  piece_jointes = [
    {
      "creation": "2024-11-18 09:54:41.843962",
      "docstatus": 0,
      "doctype": "Fichiers joints a un dossier",
      "fichier": "/files/WhatsApp Image 2023-09-21 at 14.30.04.jpeg",
      "idx": 1,
      "modified": "2024-11-18 15:27:40.365984",
      "modified_by": "juge_instruction@justice.sn",
      "name": "249",
      "nom_du_fichier": "WhatsApp Image 2023-09-21 at 14.30.04.jpeg",
      "owner": "procureur@justice.sn",
      "parent": "4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953",
      "parentfield": "pieces_jointe_au_dossier",
      "parenttype": "Dossier"
    },
    {
      "creation": "2024-11-18 09:54:41.843962",
      "docstatus": 0,
      "doctype": "Fichiers joins a un dossier",
      "fichier": "/files/WhatsApp Image 2023-12-14 at 10.14.36.jpeg.png",
      "idx": 2,
      "modified": "2024-11-18 16:28:54.424397",
      "modified_by": "procureur@justice.sn",
      "name": 251,
      "nom_du_fichier": "mypiece  TGI-DKR-PIK_GUED-01 -3953",
      "owner": "procureur@justice.sn",
      "parent": "4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953",
      "parentfield": "pieces_jointe_au_dossier",
      "parenttype": "Dossier"
    }
    
    
  ]
  
  
  constructor(
    private modalservice: BsModalService,
    private bsmodalRef: BsModalRef,
    private nbMenuService: NbMenuService,
    private options: ModalOptions,
    private router: Router,
    private dossierService: DossierService,
    private enrollementService: EnrollementService,
    private jugement:JugementsService,
    private alert: AlertesService,
    private documentService: DocumentService,
    private penalService: PenalCourrierService,

    private ngxService: NgxUiLoaderService,
    private greffierService: GreffierService
  ) {
   
    let num_dossier_defaulte = penalService.selectedDossier
    this.isModal = this.options.initialState.ismodal;
    this.req_ord = this.options.initialState.req_ord ;
    this.docs = this.options.initialState.docs  ;
    this.doctype = this.options.initialState.doctype;
    this.numero = this.options.initialState.numero; 
    this.pieces = this.options.initialState.pieces;

    this.list_ordonnances =  this.req_ord?.filter(reqOrd => (reqOrd.tag.substring(0,10).toLowerCase() == 'ordonnance'));
    this.list_requisitoires =  this.req_ord?.filter(reqOrd => (reqOrd.tag.substring(0,10).toLowerCase() == 'requisitoi'));
    // console.log('the data req =>',this.req_ord);
    // console.log('the data docs =>', this.docs);
    // console.log('the data pieces =>', this.pieces);
    // console.log('the data doctype =>', this.doctype);
    // console.log('the data  numero =>', this.numero);
    
    this.getOrdonancesAndRequisitoires()
    this.getAlldocuments()
  }
  ngOnInit(): void {
    console.log('init number dossier', this.numero);
  
    // S'assurer que la valeur actuelle de selectedDossier est chargée immédiatement
    // if (this.numero == undefined || this.numero == null) {


      // S'abonner pour recevoir les futures mises à jour
     this.subscription =  this.penalService.selectedDossier$.subscribe(dossier => {
      console.log('Dossier mis à jour',dossier);
        if (dossier != null) {
          this.numero = dossier.name;
          this.pieces = dossier.pieces_jointe_au_dossier;
          this.getOrdonancesAndRequisitoires();
          this.getAlldocuments();
          console.log('Numero mis à jour via abonnement:', this.numero);
        }
        
       
      });

      const items = document.querySelectorAll(".accordion a");

      function toggleAccordion() {
        const itemToggle = this.getAttribute('aria-expanded');
        
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute('aria-expanded', 'false');
        }
        
        if (itemToggle == 'false') {
          this.setAttribute('aria-expanded', 'true');
        }
      }
      
      items.forEach(item => item.addEventListener('click', toggleAccordion));
    
  
  }
  
  

  ngOnDestroy(): void {
    // Se desabonner pour eviter les fuites de memoire
    this.penalService.selectedDossier = []
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeModal(){
    this.bsmodalRef.hide()
  }

// get requisitoires and ordonnances
  getOrdonancesAndRequisitoires(){

    let filter = '[["numero_dossier" , "=","'+this.numero+'"]]'
    this.dossierService.getAllMyRequisitoireOrOrdonance(filter).then(res=>{
      this.req_ord = res.data
      if (this.req_ord.length>0) {
        console.log('list_requisitoire_ordonances ==> ',this.req_ord)
        this.list_ordonnances =  res.data.filter(reqOrd => (reqOrd.tag.substring(0,10).toLowerCase() == 'ordonnance'));
        this.list_requisitoires =  res.data.filter(reqOrd => (reqOrd.tag.substring(0,10).toLowerCase() == 'requisitoi'));
  
        console.log('list_ordonances ==> ',this.list_ordonnances)
        console.log('list_req==> ',this.list_requisitoires)
      }
     

    })
  }
// get docs
  async getAlldocuments(){
    // this.ngxService.start()
    //let filter ='ServicePermis'
    // let filter = '[["flag" , "in","'+this.ServicePermis+'"],["numero_du_jugement" , "=","' + this.data.name + '"]]'
    let filter = '[["numero_du_jugement" , "=","' + this.numero + '"]]'


    this.documentService.getAllDocuments(filter).then(res =>{
      //console.log("get result ==>", )
      
      let results = res.data
      if (results != null) {
        for(let res of results){
          res.content = JSON.parse(res.content)
        }
        this.docs = res.data
      } 
      
      console.log('listedocuments ===> ',  this.docs)
      // this.ngxService.stop()
    },err =>{
      // this.ngxService.stop()
    })

  }

  openModalVisualrequistion(data: any) {
    console.log('visualise req', data);
    
    const URL=MyCONST.DOMAIN+data.fichier
    const config: ModalOptions = {
      initialState: {
        data: data,
        url:URL,
        parentModal : null
      }
    }

     // Ouvrir le modal
     const modalRef = this.modalservice.show(VisualizeRequisitoireComponent, Object.assign({}, config, { class: 'modal-lg ' }));

     // Mettre à jour l'option initialState avec le modalRef
  }

  openModalVisualPiece(data: any) {
    const URL=MyCONST.API_URL+data.fichier
    const config: ModalOptions = {
      initialState: {
        data: URL,
        url:URL,
        parentModal : null
      }
    }

     // Ouvrir le modal
     const modalRef = this.modalservice.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

     // Mettre à jour l'option initialState avec le modalRef
  }

  openModalDetailDocument(data: any,type_request: string) {
    console.log('document ', data);
    
    const config: ModalOptions = {
      initialState: {
        data: data,
        type: data.type_demande,
        type_request : type_request,
        show_actions: false
      }
    }


    // Ouvrir le modal
    const modalRef = this.modalservice.show(ViewDetailsDemandesComponent, Object.assign({}, config, { class: 'modal-xl '}));

  }

 


}
