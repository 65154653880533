import { Component, OnInit } from '@angular/core';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { ReadNotificationComponent } from '../../widgets/read-notification/read-notification.component';
import { AlertesService } from '../../services/public/alertes.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import {AssignedService} from "../../services/frapper/assigned.service";
import {UserService} from "../../services/frapper/user.service";
import {CustomService} from "../../services/frapper/custom.service";

@Component({
  selector: 'app-actions-dossier',
  templateUrl: './actions-dossier.component.html',
  styleUrls: ['./actions-dossier.component.scss']
})
export class ActionsDossierComponent implements OnInit {

  data:any
  p: number = 1;
  pageUAllowed: number = 1;
  pageLogs: number = 1;
  pageNewrequest: number = 1;
  newRequestAcce: any[] = [
    {
      email_sender: 'greffier@gmail.com',
      email_receiver: 'resp_greffier@gmail.com',
      numero_du_dossier: '4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953',
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel risus eget leo laoreet fermentum a id erat. Ut sollicitudin, turpis a scelerisque tincidunt, libero ante dapibus leo, sit amet dignissim nunc velit a metus. Nam tempor augue at ligula scelerisque, in fringilla elit hendrerit. Integer suscipit ut erat eget consectetur. Cras sollicitudin nisi at nibh cursus, nec convallis lorem feugiat. Curabitur varius eu arcu ac tristique. Integer nec risus et leo egestas feugiat at sit amet elit. Sed dictum urna vitae neque accumsan, at dapibus tortor fermentum. Aenean nec augue eu justo fermentum gravida vel at justo.'
    },
    {
      email_sender: 'greffier@gmail.com',
      email_receiver: 'resp_greffier@gmail.com',
      numero_du_dossier: '4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953',
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel risus eget leo laoreet fermentum a id erat. Ut sollicitudin, turpis a scelerisque tincidunt, libero ante dapibus leo, sit amet dignissim nunc velit a metus. Nam tempor augue at ligula scelerisque, in fringilla elit hendrerit. Integer suscipit ut erat eget consectetur. Cras sollicitudin nisi at nibh cursus, nec convallis lorem feugiat. Curabitur varius eu arcu ac tristique. Integer nec risus et leo egestas feugiat at sit amet elit. Sed dictum urna vitae neque accumsan, at dapibus tortor fermentum. Aenean nec augue eu justo fermentum gravida vel at justo.'
    },
    {
      email_sender: 'greffier@gmail.com',
      email_receiver: 'resp_greffier@gmail.com',
      numero_du_dossier: '4/2024_Pénale | TGI-DKR-PIK_GUED-01 -3953',
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel risus eget leo laoreet fermentum a id erat. Ut sollicitudin, turpis a scelerisque tincidunt, libero ante dapibus leo, sit amet dignissim nunc velit a metus. Nam tempor augue at ligula scelerisque, in fringilla elit hendrerit. Integer suscipit ut erat eget consectetur. Cras sollicitudin nisi at nibh cursus, nec convallis lorem feugiat. Curabitur varius eu arcu ac tristique. Integer nec risus et leo egestas feugiat at sit amet elit. Sed dictum urna vitae neque accumsan, at dapibus tortor fermentum. Aenean nec augue eu justo fermentum gravida vel at justo.'
    }
  ];
  searchKeyWord: string = '';

  usersAllowedAcce: any[] = [
    {
      fullname: 'Mark Otto',
      email: 'mark.otto@example.com',

    },
    {
      fullname: 'Jacob Thornton',
      email: 'jacob.thornton@example.com',

    },
    {
      fullname: 'LARRY John',
      email: 'larry.john@example.com',

    }
  ];
  searchTerm: string = '';
  selectedUsers: { [key: string]: boolean } = {}; // Dictionnaire d'utilisateurs sélectionnés
  selectedActions: { [key: string]: boolean } = {}; // Dictionnaire d'actions sélectionnées
  selectedDocumentTypes: { [key: string]: boolean } = {};

  users: string[] = ['procureur', 'juge', 'greffier', 'agent'];
  actions: string[] = ['create', 'edit', 'delete'];
  documentTypes: string[] = ['Sales Order', 'Invoice', 'Purchase Order'];

  dropdowns = {
    users: false,
    actions: false,
    documentTypes: false
  };


  results: any[] = [];

  activity_logs = [
    {
      "timestamp": "2024-11-25T12:34:56.789Z",
      "level": "INFO",
      "message": "L'utilisateur a créé un nouveau document",
      "user": "admin",
      "action": "create",
      "document_type": "Sales Order",
      "document_name": "SO-2024-001",
      "ip_address": "192.168.1.1",
      "host": "frappe-server.local",
      "trace_id": "a1b2c3d4e5f67890"
    },
    {
      "timestamp": "2024-11-25T12:35:10.123Z",
      "level": "WARNING",
      "message": "Un utilisateur non autorisé a tenté de modifier un document",
      "user": "guest_user",
      "action": "edit",
      "document_type": "Sales Order",
      "document_name": "SO-2024-001",
      "ip_address": "192.168.1.10",
      "host": "frappe-server.local",
      "trace_id": "f1e2d3c4b5a67890"
    },
    {
      "timestamp": "2024-11-25T12:36:00.456Z",
      "level": "ERROR",
      "message": "Erreur lors de la création du document Sales Order",
      "user": "admin",
      "action": "create",
      "document_type": "Sales Order",
      "document_name": "SO-2024-002",
      "error_message": "Constraint violation: Unable to save due to missing mandatory field",
      "stack_trace": "Traceback (most recent call last): ...",
      "ip_address": "192.168.1.1",
      "host": "frappe-server.local",
      "trace_id": "d7e8f9g0h1i23456"
    }
  ]

  filtered_activity_logs: any[] = []

  doctype:any =null;
  doctype_value:any =null;
  doctype_name:any =null;



  constructor(private modalService: BsModalService,
    public options: ModalOptions,
    public bsModalRef: BsModalRef,
    private alerte:AlertesService,
    private userService : UserService,
    private customService: CustomService,
    private datePipe: DatePipe,
    private assignService: AssignedService,
    private ngxService: NgxUiLoaderService,
  ){

    this.results = this.activity_logs
    this.data = options.initialState;
    this.doctype = this.data.doctype
    this.doctype_name = this.data.numero
    console.log('fdfdfd', this.data);
    
    try{
      this.doctype_value = this.data.value
    }catch (e) {
      this.doctype_value = null
    }

  }

  closeModal(){
    this.bsModalRef.hide()
  }

  async getUsserCanAccess(){
    this.ngxService.start('GetUSER')
    this.usersAllowedAcce = []
    this.assignService.getAssignedAnOwnerUser(this.doctype,this.doctype_name).then(res =>{
      let assigned_users:any = (res.message)[0]
      this.usersAllowedAcce = [
        {'fullname':'', 'email':assigned_users['owner'], 'type':'creator','Profil':''}
      ]
      try{
        assigned_users = JSON.parse(assigned_users["_assign"])
        for( let assigned_user of assigned_users){
          this.usersAllowedAcce.push({
            'fullname':'', 'email':assigned_user, 'type':'actor','Profil':''
          })
        }
      }catch (e) {
        assigned_users=[]
        this.ngxService.stop('GetUSER')
      }


      

     /* // ADD API to get More detail about a user
     for (let view_user of this.usersAllowedAcce){
        this.userService.getOneUserDetail(view_user.email).then( res =>{
          console.log('res ==> ',res)
        })
      }*/
        console.log('usersAllowedAcce ==> ', this.usersAllowedAcce)
      console.log('Assigned USer ==> ', assigned_users)
      this.ngxService.stop('GetUSER')
    },err=>{
      this.ngxService.stop('GetUSER')
    })
  }

  async  getAllDemandes(){
    this.ngxService.startLoader('GetDemandes')
    this.newRequestAcce =[]
    this.assignService.getDemande(this.doctype,this.doctype_name).then(res =>{
      console.log('res newrequest ===> ',res)
      this.newRequestAcce= res.data
      this.ngxService.stopLoader('GetDemandes')
    },err => {
      console.log('err ===> ',err)
      this.ngxService.stopLoader('GetDemandes')
    })
  }

  allowAcce(email:string,name:string){
    this.ngxService.start()
    let me:any = localStorage.getItem('me')
    me =JSON.parse(me)
    let body={
      "status":"Accepter",
      "validator":me.name
    }
    this.assignService.updateDemande(name,body).then(res =>{
      this.assignService.doAssignation(this.doctype,this.doctype_name,[email]).then(res =>{

        this.alerte.successNotification('Acces Accepter')
        this.refresh()
        this.ngxService.stop()
      },err =>{
        this.alerte.warningNotification('erreur')
        this.ngxService.stop()
      })
    },err =>{
      this.alerte.warningNotification('erreur')
      this.ngxService.stop()
    })
  }

  rejectDemandesAcce(email:string,name:string){
    this.ngxService.start()
    let me:any = localStorage.getItem('me')
    me =JSON.parse(me)
    let body={
      "status":"Rejeter",
      "validator":me.name
    }
    this.assignService.updateDemande(name,body).then(res =>{
      this.alerte.successNotification('Acces Rejeter')
      this.refresh()
      this.ngxService.stop()
    },err =>{
      this.alerte.warningNotification('erreur')
      this.ngxService.stop()
    })

  }

  revokeAcce(email:string){

  }

  getNewRequestAcce(num_dossier:string){

  }

  getUsersAllowedAcce(num_dossier:string){

  }


  async getActivite(){
    this.ngxService.startLoader('getLog')
    this.activity_logs =[]
   this.customService.getAllLogOfDoctype(this.doctype,this.doctype_name).then(res =>{
     let logs_actiity = res.docinfo.assignment_logs
     let logs_view =  res.docinfo.views
     
     console.log('logs_activity ==> ', logs_actiity);
     
     
     for (let view of logs_view){
       logs_actiity.push({
         "name":'v-'+view.name,
         "creation": view.creation,
         "content": view.owner +" a ouvert ce Dossier le : " + view.creation,
         "owner": "procureur@local.com"
       })
     }

     logs_actiity.sort((a, b) => {
       const dateA = new Date(a.creation).getTime();
       const dateB = new Date(b.creation).getTime();
       return dateB - dateA; // Descending order
     });

     this.activity_logs = logs_actiity
     console.log('logs_view ==> ', this.activity_logs);
     this.ngxService.stopLoader('getLog')
   },err =>{
     this.ngxService.stopLoader('getLog')
   })
  }

  openModalDetailNotif(data: any) {
    // let _data = {
    //   owner: data.email_sender,
    //   for_user: data.email_receiver,
    //   subject: data.message
    // }
    data['owner'] = data.email_sender
    data['for_user'] = data.email_receiver
    data['subject'] = data.message
    const config: ModalOptions = {
      initialState: {
        data: data,
        is_demande_acce: true,
        parentModal : null
      }
    }



    // Ouvrir le modal
    const modalRef = this.modalService.show(ReadNotificationComponent, Object.assign({}, config, { class: 'modal-lg bg-primary' }));


  }

  toggleDropdown(type: string, event: MouseEvent): void {
    event.stopPropagation();


    this.dropdowns[type] = !this.dropdowns[type];

    // ouvrir un seul dropdown
    for (let key in this.dropdowns) {
      if (key !== type) {
        this.dropdowns[key] = false;
      }
    }
  }

  // Methode de recherche
  onSearch(event: Event): void {
    event.preventDefault();
    const selectedUsers = Object.keys(this.selectedUsers).filter(user => this.selectedUsers[user]);
    const selectedActions = Object.keys(this.selectedActions).filter(action => this.selectedActions[action]);
    const selectedDocumentTypes = Object.keys(this.selectedDocumentTypes).filter(docType => this.selectedDocumentTypes[docType]);

    this.results = this.searchData(this.searchTerm, selectedUsers, selectedActions, selectedDocumentTypes);
  }

  searchData(searchTerm: string, selectedUsers: string[], selectedActions: string[], selectedDocumentTypes: string[]): any[] {
    return this.activity_logs.filter(log => {
      const matchesUser = selectedUsers.length === 0 || selectedUsers.includes(log.user);
      const matchesAction = selectedActions.length === 0 || selectedActions.includes(log.action);
      const matchesDocumentType = selectedDocumentTypes.length === 0 || selectedDocumentTypes.includes(log.document_type);
      const matchesTerm = !searchTerm || log.message.toLowerCase().includes(searchTerm.toLowerCase());
      // return matchesUser || matchesAction || matchesDocumentType || matchesTerm;

      return matchesUser && matchesAction && matchesDocumentType && matchesTerm;
    });
  }
getFormattedDate(date): string {
  return this.datePipe.transform(date, 'dd-MM-yyyy');
}


  async refresh(){
 await this.getUsserCanAccess()
  await this.getAllDemandes()
  await this.getActivite()
}

ngOnInit(): void {

    this.getUsserCanAccess()
    this.getAllDemandes()
    this.getActivite()
}


}
