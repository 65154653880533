<br>
<div class="d-flex justify-content-center">
  <h5 class="text-dark text-center" style="font-size: 15px;">Demande d'accés pour le dossier: <b>{{data}}</b> </h5>
  <button type="button"  class="btn btn-link position-absolute float-right" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
    <i class="fas fa-times text-dark " style="font-size: 1.5rem; "></i>
  </button>
</div>
<br>
<div class="d-flex justify-content-between align-items-center m-auto" style="padding: 20px;" >

  <form #request="ngForm" class="col-8 m-auto">
    
    <div class="d-flex justify-content-between align-items-center " >
      <div class="d-flex flex-column shadow col-12" style="padding: 10px;">
        <h6 class="text-dark text-center">Informations</h6>
        <div class="inputbox">
          <select  class="custom-select" id="inputGroupSelect01"  name="email_receiver"  required   ngModel>
            <option value="">--destinataire--</option>
            <option *ngFor="let user of list_users" value="{{user.email}}">{{user.email}}</option>
          </select>

        </div>


        <div class="inputbox" >
          <textarea style="height: 100px;" class="form-control" placeholder="Message"  name="message" required ngModel></textarea>
        </div>

  

     
        <div class="" style="margin: auto; text-align: center;">
          <button  (click)="submitRequestAcce(request)"  [disabled]="!request.valid"  class="btn bg-success text-light" id="addRowBtn" style="margin: auto; text-align: center;">Enregistrer</button>
           </div>
      </div>
      
    </div>

    
  </form>
 
  
</div>
