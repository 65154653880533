import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertesService } from '../../services/public/alertes.service';
import * as $ from 'jquery';
import { Config, NgxPrintElementService } from 'ngx-print-element';
import download from 'downloadjs';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-visualize-piece',
  templateUrl: './visualize-piece.component.html',
  styleUrls: ['./visualize-piece.component.scss']
})
export class VisualizePieceComponent implements OnInit{


  // urlimage = 'https://petapixel.com/assets/uploads/2022/06/what-is-a-jpeg-featured.jpg';
  // urlpdf = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  urlSafe :any;
  data: any;
  is_image: boolean = false;
  extension: string;
  @ViewChild('elementToPrint') printElement!:any;
   isImage;

   is_ticket:any;

   tribunal: string = 'TGI-Pikin-Guediawaye';
   num_public: string = 'rftgyhu5678';
   ticketNumber: string = '';
   ticketDate: string = '';

   
  constructor(private modalService: BsModalService,
    public options: ModalOptions,

    private ngxService: NgxUiLoaderService,
    public print: NgxPrintElementService,
    private alerte:AlertesService,
    public sanitizer : DomSanitizer
  ){
    this.data = options.initialState
    this.is_ticket = options.initialState.is_ticket
    if (!this.is_ticket) {
      if ( options.initialState.url != null) {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          options.initialState.url.toString()
        );
      }else{
        alerte.warningNotification('Piéce', 'aucune piéce')
        // modalService.hide()
      }
    }
   


  
}

generateTicket() {
  this.ticketNumber = 'TICKET-' + Math.floor(Math.random() * 10000); // Générer un numéro de ticket aléatoire
  this.ticketDate = new Date().toLocaleDateString(); // Date de génération du ticket
}

downloadAsPdf() {
  const img = new Image();
  img.src = this.data;

  img.onload = () => {
    // Créer un élément <canvas> temporaire
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Définir la taille du canvas pour correspondre à l'image
    canvas.width = img.width;
    canvas.height = img.height;

    // Dessiner l'image SVG sur le canvas
    ctx.drawImage(img, 0, 0);

    // Convertir le canvas en image PNG
    const imgData = canvas.toDataURL('image/png');

    // Crée un objet jsPDF
    const pdf = new jsPDF();

    // Dimensions du PDF (A4 par défaut : 210 x 297 mm)
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculer la position pour centrer l'image
    const x = (pdfWidth - img.width / 2) / 2;
    const y = (pdfHeight - img.height / 2) / 2;

    // Ajouter l'image PNG dans le PDF
    pdf.addImage(imgData, 'PNG', x, y, img.width / 2, img.height / 2);

    // Télécharger le fichier PDF généré
    pdf.save('e-service-doc.pdf');
  };
}
getExtension(file: string) {
  // if (!file) {
  //   // Handle the case when file is undefined
  //   console.error("File is undefined");
  //   return;
  // }

  const parts = file.split('.');
  this.extension = parts[parts.length - 1];
  if (this.extension === 'jpeg' || this.extension === 'jpg' || this.extension === 'png' || this.extension === 'svg') {
    this.is_image = true;
    return this.extension;
  }
  
}

public config: Config = {
  printMode: 'template', // template-popup
  popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
  pageTitle: 'casier',
  // htmlType: 'text',

  styles: [
    `
    @media print {
      @page {
        margin: 20px; /* Supprime les marges par défaut */
      }
      
      body {
        width: 210mm; /* Largeur de la page A4 en millimètres */
        height: 297mm; /* Hauteur de la page A4 en millimètres */
        margin: 0; /* Supprime les marges par défaut */
        text-align: center;
      }
    }
      .ticket-form {
        width: auto;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        background-color: #f9f9f9;
        justify-content:center;
        text-align:center;
      }

      .ticket-form h2 {
        text-align: center;
        margin-bottom: 20px;
      }

      .ticket-form label {
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
      }

      .ticket-form input {
        width: 100%;
        padding: 8px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      .ticket-form button {
        width: 100%;
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
      }

      .ticket-form button:hover {
        background-color: #0056b3;
      }

      /* Style pour le ticket */
      .ticket {
        margin-top: 30px;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        background-color: #fff;
        width: 300px;
        margin: 0 auto;
      }

      .ticket-header {
        text-align: center;
        margin-bottom: 20px;
      }

      .ticket-header .event-name {
        font-weight: bold;
        font-size: 18px;
        color: #007bff;
      }

      .ticket-body {
        justify-content:center;
        text-align:center;
        margin-bottom: 15px;
      }

      .ticket-body p {
        margin: 5px 0;
      }

      .ticket-footer {
        text-align: center;
        font-style: italic;
        color: #888;
      }
    `
  ]
}

onPrint2(el: any) {
  try {
    this.print.print(el, this.config).subscribe(console.log);

  } catch (error) {
    console.log('dddddd==> '+ error);


  }

}



  async ngOnInit(): Promise<void> {

    if (this.is_ticket) {
      this.num_public = this.data.numero_public
      this.tribunal = this.data.tribunal
      this.generateTicket()
    }else{
      this.getExtension(this.data)

    }
   

     /* this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.data
      );*/

      $(document).ready(function() {
        var iframe = document.getElementById('myFrame');



        $(iframe).css({
          'background-size': 'cover',
          'height': 'auto'
        });
      });

  }
}
