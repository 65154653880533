import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import { AuthService } from '../auth/auth.service';
import {CustomService} from "./custom.service";

@Injectable({
  providedIn: 'root'
})
export class AssignedService {
  Doctype_Demmande_access ='demande acces'
  ToDO= 'ToDo'

  HIERACHIE =[
    {
      "My_Profil":"SUB PROCUREUR",
      "SUPERIOR":["PROCUREUR"]
    },
    {
      "My_Profil":"AGENT ENROLEMENT PENAL",
      "SUPERIOR":["PROCUREUR"]
    },
    {
      "My_Profil":"SEC PROCUREUR",
      "SUPERIOR":["PROCUREUR"]
    },
    {
      "My_Profil":"AGENT DU COURRIER",
      "SUPERIOR":["PROCUREUR"]
    }

    ,
    {
      "My_Profil":"GREFFIER D'INSTRUCTION",
      "SUPERIOR":["JUGE D'INSTRUCTION"]
    }
  ]


  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private customservice:CustomService,) {}


  async doAssignation(doctype, docname, users) {
    try {
      let me = localStorage.getItem('me')
    // users must be like ["anneibou1@gmail.com"]
    const body= {
      "assign_to_me": 0,
      "assign_to": users, // ["anneibou1@gmail.com"]
      "doctype": doctype,
      "name": docname
    }
    console.log('body ==> ',body)
    let assignations = await this.frapper.CallCustomAPI('frappe.desk.form.assign_to.add',body).toPromise()
    return assignations
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async doAssignationForRequiredProfil(my_profil,doctype, docname, users=[]){
    let ALL_DESTINATAIRE:any =[]
    const filteredData = this.HIERACHIE.filter(item => item.My_Profil === my_profil);
    console.log('filteredData ==> ',filteredData)
    if(filteredData.length>0){
      ALL_DESTINATAIRE =[]
      for(let superieur of filteredData[0].SUPERIOR){
        console.log(superieur)
        let val =  await this.customservice.getUserByRole(superieur)
        for (let user of val.message){
          ALL_DESTINATAIRE.push(user.parent)
        }
         console.log('ALL_DESTINATAIRE ==> ',ALL_DESTINATAIRE)
         return  this.doAssignation(doctype,docname,ALL_DESTINATAIRE)
      }

    }else {
      return null
    }
  }

  async removeAssignation(doctype, docname, users) {
    try {
      let me = localStorage.getItem('me')
      // users must be like "anneibou1@gmail.com"
      const body= {
        "assign_to": users,
        "doctype": doctype,
        "name": docname
      }
      console.log('body ==> ',body)
      let assignations = await this.frapper.CallCustomAPI('frappe.desk.form.assign_to.remove',body).toPromise()
      return assignations
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async removeReadAuth(doctype, docname, users) {
    try {
      let me = localStorage.getItem('me')
      // users must be like "anneibou1@gmail.com"
      const body= {
        "user": users,
        "doctype": doctype,
        "name": docname,
        "permission_to": "read",
        "value": 0
      }
      console.log('body ==> ',body)
      let assignations = await this.frapper.CallCustomAPI('frappe.share.set_permission',body).toPromise()
      return assignations
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAssignedUser(doctype, docname) {
    try {
      let me = localStorage.getItem('me')
      // users must be like ["anneibou1@gmail.com"]
      let doc_assigned_user = await this.frapper.GetDoctypes(doctype,'["_assign","name"]','[ ["name" ,"=", "'+ docname+'"] ]').toPromise()
      return doc_assigned_user
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAssignedAnOwnerUser(doctype, docname) {
    try {
      let body = {
        "doctype": doctype,
        "doc_name": docname,
      }
      let doc_assigned_user = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_user_assign_to_doctype',body).toPromise()
       return doc_assigned_user
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getWhoAssign(doype, filter){
    try {
      let result = this.frapper.GetDoctypes(this.ToDO,'["*"]',filter).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }
  }

  async nouvelleDemandeAccess(body) {
    try {
      let new_demande = await this.frapper.CreateDoctype(this.Doctype_Demmande_access,body).toPromise()
      return new_demande
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getDemande(doctype, docname, status='En attente') {
    try {
      let me = localStorage.getItem('me')

      let doc_demandes_liste = await this.frapper.GetDoctypes(this.Doctype_Demmande_access,'["*"]','[ ["type" ,"=", "'+ doctype+'"],["numero" ,"=", "'+ docname+'"],["status" ,"=", "'+ status+'"] ]').toPromise()
      return doc_demandes_liste
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async updateDemande(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_Demmande_access,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

}
