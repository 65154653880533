<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 text-center ">


         <!-- formulaire enrollement -->
         <div class="card" >
          <div class="d-flex justify-content-center">
            <h5 id="heading" style="font-size: 13px;">{{'enrole' | translate}} {{type}}</h5>
            <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
              <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
            </button>
          </div>
          
          <!-- <p>Fill all form field to go to next step</p> -->
          <form id="msform" #enrolementForm="ngForm" (ngSubmit)="submitEnrollement(enrolementForm)" *ngIf="type !='jugement'">
              <!-- progressbar -->
              <ul id="progressbar">
                  <li class="active" id="step1"><strong>{{'folder' | translate}}</strong></li>
                  <li id="step6"><strong>{{'elt_folder' | translate}}</strong></li>
                  <li id="step2"><strong>{{'chambres' | translate}}</strong></li>
                  <!-- <li id="step4"><strong>Infractions/Raisons</strong></li> -->
                  <li id="step5"><strong>{{'num_role' | translate}}</strong></li>
                  <li id="step3"><strong>{{'calendar' | translate}}</strong></li>

              </ul>
              <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
              </div> <br> <!-- fieldsets -->
              <fieldset class="{{step}}" >
                  <div class="form-card">
                    <ngx-user-card [dataType]="'dossiers'"></ngx-user-card>
                  </div> <input type="button" name="next" class="next action-button" (click)="verifOption()" value="{{'next' | translate}}" />
              </fieldset>
              <fieldset>
                <div class="form-card">
                  <app-resume-dossier ></app-resume-dossier>
                </div> <input type="button" name="next" class="next action-button" (click)="verifOption()" value="{{'next' | translate}}" />
                 <input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
              </fieldset>

              <fieldset>
                <div class="form-card">
                  <ngx-user-card [dataType]="'chambres'"></ngx-user-card>


                </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" /><input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
            </fieldset>

            <!-- <fieldset>
              <div class="form-card" >
                <ngx-user-card [dataType]="'infractions'"></ngx-user-card>
              </div> <input type="button" name="next" class="next action-button" value="Suivant" /><input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
          </fieldset> -->
          <fieldset>
            <div class="form-card text-center m-auto" >
              <span *ngIf="need_quitance">
                <label class="fieldlabels">{{'num_quittance' | translate}}(1): </label>
              <input type="text" class="form-control col-md-4 col-lg-4 col-sm-8 text-center m-auto" name="numero_quit1" [(ngModel)]="num_quittance1"/>

              <label class="fieldlabels">{{'num_quittance' | translate}}(2): </label>
              <input type="text" class="form-control col-md-4 col-lg-4 col-sm-8 text-center m-auto" name="numero_quit2" [(ngModel)]="num_quittance2"/>
              </span>

              <label class="fieldlabels">{{'num_du_role' | translate}}: </label>
              <input type="text" class="form-control col-md-4 col-lg-4 col-sm-8 text-center m-auto" [ngModel]="next_number_corrier.new_role_number" name="numero_du_jugement_restrint" ngModel/>
            </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" /><input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
        </fieldset>

            <fieldset>
              <div class="form-card" >
                <app-calendar [validation]="false"></app-calendar>


              </div>
                <input type="submit" name="next" class="next action-button" value="{{'enrole' | translate}}"  />
                <input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
          </fieldset>



          </form>

          <form id="msform" *ngIf="type =='jugement'" class="jugement">
            <!-- progressbar -->
            <ul id="progressbar" class="m-auto">
                <li class="active" id="step1"><strong>{{'jugements' | translate}}</strong></li>
                <li id="step2"><strong>{{'chambres' | translate}}</strong></li>
                <!-- <li id="step4"><strong>Infractions</strong></li> -->
                <li id="step4"><strong>{{'num_role' | translate}}</strong></li>
                <li id="step3"><strong>{{'calendar' | translate}}</strong></li>

            </ul>
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div> <br> <!-- fieldsets -->
            <fieldset class="{{step}}" >
                <div class="form-card">
                  <ngx-user-card [dataType]="'jugements'"></ngx-user-card>
                </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" />
            </fieldset>


            <fieldset>
              <div class="form-card">
                <ngx-user-card [dataType]="'chambres'"></ngx-user-card>


              </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" /><input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
            </fieldset>
            <fieldset>
              <div class="form-card text-center m-auto" >
                <label class="fieldlabels">{{'num_du_role' | translate}}: </label>
                <input type="text" class="form-control col-md-6 col-lg-6 col-sm-8 text-center m-auto" name="numero_role" ngModel/>
              </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" /><input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
          </fieldset>

          <!-- <fieldset>
            <div class="form-card" >
              <ngx-infraction [dataType]="''"></ngx-infraction>
            </div> <input type="button" name="next" class="next action-button" value="Suivant" /><input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
        </fieldset> -->

          <fieldset>
            <div class="form-card" >
              <app-calendar [validation]="false"></app-calendar>


            </div>
              <input type="button" name="next" class="next action-button" value="{{'enrole' | translate}}"  (click)="submitEnrollement()"/>
              <input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
        </fieldset>



        </form>
      </div>


      </div>
  </div>
</div>
