import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DossierService } from "../../services/frapper/dossier.service";
import { EnrollementService } from "../../services/frapper/enrollement.service";
import { JugementsService } from "../../services/frapper/jugements.service";
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from "../../services/public/alertes.service";
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import {MyCONST} from "../../CONST/myConst";
import {AssignedService} from "../../services/frapper/assigned.service";


@Component({
  selector: 'ngx-add-enrollement',
  templateUrl: './add-enrollement.component.html',
  styleUrls: ['./add-enrollement.component.scss']
})
export class AddEnrollementComponent {
  @Input() parentModal: BsModalRef;
  isLinear = true;
  FormEnrollement: FormGroup;
  data: any;
  type: any;
  step: any;
  modalRef!: BsModalRef;
  choicedChambre = this.penalService.selectedDossier;

  dossiers:any =[]

  numero_jugement:any =''
  num_quittance1: string;
  num_quittance2: string;
  numero_role: string;
  type_de_dossier:any=''
  need_quitance:any= true

  allinfractions:any =[]

  tribunal = MyCONST.TRIBUNAL
  my_tribunal:any = null
  profil

  next_number_corrier:any ={last_role: '', new_role_number: 'ROLE/001/2024'}

  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private jugement: JugementsService,
              private role: EnrollementService,
              private assignService:AssignedService,
              private alerte:AlertesService,
              private dossierService: DossierService,
              public bsModalRef: BsModalRef,
              private ngxService: NgxUiLoaderService,
              private penalService: PenalCourrierService) {

    try{
       this.my_tribunal = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
      if(this.my_tribunal.code){
        //console.log('UPDATE')
        this.tribunal= this.my_tribunal.tribunal
        // this.tribunal_detail= tribunal_information.detail_tribunal
      }
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal = null
    }
    this.data = options.initialState.data;
    this.type = options.initialState.type;
    this.step = options.initialState.step;
    this.profil = localStorage.getItem('profil')

    this.role.getnumeroRole().then( res =>{
      console.log('Numero role ==> ',res.message)
      this.next_number_corrier = res.message
      // console.log('---> ',this.next_number_corrier)
    },err=>{
      console.log('ERR==> ',err)
    })

  }

  closeModal(){
    this.bsModalRef.hide()
  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  generateRandomString(): void {
    const length = 5;
    const characters = '0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    this.numero_jugement = result;
  }

  verifOption(){
    this.type_de_dossier= this.penalService.selectedDossier
      console.log('verif dossier',this.type_de_dossier)
    this.type_de_dossier = this.type_de_dossier.classification
    console.log(this.type_de_dossier)
    if(this.type_de_dossier =='Pénale'){
      this.need_quitance=false
    }
  }

  convertDate(originalDate): string {
    console.log('originalDate==> ',originalDate)
    const parts = originalDate.split('/');
    let convertedDate =''
    if (parts.length === 3) {
      const year = parts[2];
      const month = parts[1].padStart(2, '0');
      const day = parts[0].padStart(2, '0');
      convertedDate = `${year}-${month}-${day}`;
    } else {
      convertedDate = 'Invalid date format';
    }
    console.log('convertedDate==> ',convertedDate)
    return convertedDate
  }


  async getAllInfrations() {
    this.ngxService.start();
    try {
      this.allinfractions = (await this.role.getAllInfractions()).data
      this.ngxService.stop();

    } catch (error) {
      this.ngxService.stop();
    }

  }

   submitEnrollement(form){

    this.ngxService.start();



    let dossier = this.penalService.selectedDossier;
    let num_jugement = this.penalService.selectedJugement;
    let num_chambre = this.penalService.selectedChambre;
    let infraction = this.penalService.selectedInf;
    let date = this.penalService.selectedDate;
    let array_infraction = [];
    let My_profil = localStorage.getItem('profil');

    this.generateRandomString();
    //console.log('numero de jugement ==> ', this.numero_jugement)
    // console.log({'dossier': dossier},{'chambre': num_chambre},{'infr': infraction}, {'date': date}, {'jugment': num_jugement});

    //return
    if (num_chambre && infraction && date && dossier) {
        //Creer Un Jugement
     if(this.type !='jugement'){
      console.log('In ENROLEMENT WITH JUGEMENT ==> ',this.type)

      let body_jugement = {
        //"docstatus": 1,
        "status": "En attente de decision",
        "classification": dossier.classification,
       "association_de_prevenu": dossier.association_de_prevenu,
       "is_multiple_prevenu": dossier.is_multiple_prevenu,
        "numero_de_dossier": dossier.name,
        "numero_de_jugement": form.value.numero_du_jugement_restrint,
        "date_de_jugement": this.convertDate(date[0]),
        "tribunal": this.tribunal ,//MyCONST.TRIBUNAL,
        "identification_type": dossier.type_identification_du_poursuivis,
        "identification_value": dossier.numéro_didentification_du_poursuivis,
        // "genre": "Femme",
        "nom_complet_plaignant":dossier.nom_complet_du_plaignant,
        "prenom": dossier.nom_complet_du_poursuivis,
        // "nom": "Gueye",
        // "nationalité": "Gambien(ne)",
        "date_de_naissance": dossier['date_de_naissance_du_prévenu'],
        "pays_de_residence":dossier.pays,
        // "status_matrimonial": "Celibataire",
        "adresse": dossier.addresse_complete_du_poursuivis ,
        "date_de_decision": "",
        "b1": 1,
        "b2": 1,
        "b3": 1,

        "table_amendes_j": [],
        "table_emp": [],
        "table_tqhi": dossier.table_tqhi,
        "affiliations": [
         {'id': '','Lien': 'Mere','nom': dossier.nom_complet_de_la_mere_du_plaignant ?dossier.nom_complet_de_la_mere_du_plaignant :'Y','prenom': 'y'},
         {'id': '','Lien': 'Pere','nom': dossier.nom_complet_du_pere_du_plaignant? dossier.nom_complet_du_pere_du_plaignant  : 'X' ,'prenom': 'X'},
        ]
      }
      try{
        console.log('inf perso=> ',body_jugement);

       for (const key in infraction) {
         // let _infraction = {
         //   "docstatus": 0,
         //   "nature": infraction[key].name,
         //   "parentfield": "table_infractions",
         //   "parenttype": "Jugements",
         //   "doctype": "fiche_a_classer_infractions"
         // }

         let _infraction = {
           "docstatus": 0,
           "nature": infraction[key].nature,
           "parentfield": "table_infractions",
           "parenttype": "Jugements",
           "doctype": "fiche_a_classer_infractions"
         }

         array_infraction.push(_infraction);
       }
       body_jugement['table_infractions'] = array_infraction;


      }catch (e) {
      console.log("error getting infraction=> ", e);

      }
        if(this.my_tribunal!=null){
          body_jugement['tribunal']=this.my_tribunal.name
        }
        console.log("viewbody_jugement", body_jugement);

        this.jugement.nouveauJugement(body_jugement).then( (res:any)=> {
          try {
            console.log('Create Jugement !', res)
            let audience = res.data
            let body_role ={
              "docstatus": 1,
              "type_daffaire": res.data.classification,
              "numero_du_jugement": res.data.numero_de_jugement,
              "numero_du_jugement_restrint":form.value.numero_du_jugement_restrint,
              "audience":res.data.name,
              "num_quittance1":form.value.num_quittance1,
              "num_quittance2":form.value.num_quittance2,
              "chambre": num_chambre.name,
              "date_du_jugement": res.data.date_de_jugement
            }
            
            if(this.my_tribunal!=null){
              body_role['tribunal']=this.my_tribunal.name
            }

            this.role.nouveauRole(body_role).then(async res =>{
                let body_role_general= body_role
                body_role_general["identifiant_e_role"] = res.data.name
                body_role_general["numero_du_role"] = res.data.numero_du_jugement_restrint
                body_role_general["plaignants"] = audience.nom_complet_plaignant
                body_role_general["prevenue"] = audience.prenom
                try {
                  await this.role.addInRoleGeneral(body_role_general)
                }catch (e) {

                }


              console.log('RES==kkk ==> ',res)
                this.assignService.doAssignationForRequiredProfil(this.profil,this.role.Doctype_enrolement,res.data.name)
                this.assignService.doAssignationForRequiredProfil(this.profil,this.jugement.Doctype_jugements,audience.name) // assignation audience aux autres profils

              let role_info = res
              try{
                await this.jugement.updateJugement(audience.name,{"role":role_info.data.name})

              }catch (e) {

              }

              if (My_profil != 'GREFFIER INSTRUCTION' && My_profil != 'JUGE D\'INSTRUCTION') {
                await this.dossierService.updateSatate(dossier.name,'Enrolement').then(ress => {
                    this.dossierService.updateDossier(dossier.name,{"position_unite":num_chambre.nom}).then(ress => {
                        this.openModalSuccessDialog('Dossier enrôlé avec succés');
                        this.closeWithMessage("success")
                        this.ngxService.stop();
                      },
                      err=>{
                        console.log('Error On update dossier status ==> ',err)
                        this.alerte.errorNotification('Mise a jour du dossier','Impossible d\'effectuer la mise a jour')
                        this.closeWithMessage("")
                        this.ngxService.stop();
                      })
                },
                    err=>{
                  console.log('Error On update dossier status ==> ',err)
                  this.alerte.errorNotification('Mise a jour du dossier','Impossible d\'effectuer la mise a jour')
                  this.closeWithMessage("")
                  this.ngxService.stop();
                })
              }


                let detail_chambre=null
                this.role.getOneChambre(num_chambre.name).then(res=>{
                  //console.log('res.data ==> ',res.data)
                  detail_chambre=res.data
                  let membres = detail_chambre.membres
                  console.log('membres (utilisateur) ==> ',membres)
                  for(let membre of membres){
                   // if(membre.poste=='PRESIDENT'){
                      this.assignService.doAssignation(this.dossierService.Doctype_dossier,dossier.name,[membre.utilisateur]).then(res=>{})
                   // }
                    this.assignService.doAssignation(this.jugement.Doctype_jugements,audience.name,[membre.utilisateur]).then(res=>{})
                    this.assignService.doAssignation(this.role.Doctype_enrolement,role_info.data.name,[membre.utilisateur]).then(res=>{})
                  }
                });
                this.closeWithMessage("success")
            },
            err=>{
              console.log('Error ==> ',err)
              this.alerte.errorNotification('Creation du Role','Impossible d\'effectuer l\'enrôlement')
              this.closeWithMessage("")
              this.ngxService.stop();
            })
          } catch (error) {
            this.alerte.errorNotification('Creation du Role','Impossible d\'effectuer l\'enrôlement, une erreur est survenue')
            this.bsModalRef.hide()
            this.ngxService.stop();
          }

          },
          (error:any)=> {
            this.alerte.errorNotification('Creation du Role','Impossible d\'effectuer l\'enrôlement')
            this.closeWithMessage("")
            this.ngxService.stop();
            this.bsModalRef.hide()
            console.log('Error on creaste Jugement !', error)
          }
        )
      }

      if(this.type =='jugement'){
        console.log('In ENROLEMENT WITH JUGEMENT ==> ',this.type)
        let body_role ={
          "docstatus": 1,
          "type_daffaire":'#'+num_jugement.classification,
          "numero_du_jugement": num_jugement.name,
          "numero_du_jugement_restrint":form.value.numero_du_jugement_restrint,
          "num_quittance1":form.value.num_quittance1,
          "num_quittance2":form.value.num_quittance2,
          "chambre": num_chambre.name,
          "date_du_jugement": this.convertDate(date[0])
        }
        if(this.my_tribunal!=null){
          body_role['tribunal']=this.my_tribunal.name
        }

        this.role.nouveauRole(body_role).then(async res =>{
          this.assignService.doAssignationForRequiredProfil(this.profil,this.role.Doctype_enrolement,res.data.name)
          console.log('RES ==> ',res)
          let body_jugement_update ={
            "status": "En attente de decision",
            "date_de_jugement": this.convertDate(date[0])
          }
          await this.jugement.updateJugement(num_jugement.name,body_jugement_update).then(res =>{
            this.assignService.doAssignationForRequiredProfil(this.profil,this.role.Doctype_enrolement,res.data.name)
            

            this.openModalSuccessDialog('Dossier enrôlé avec succés');
            this.closeWithMessage("success")
            this.ngxService.stop();
          },err =>{
            console.log('err ==> ',err)
            this.alerte.errorNotification('Jugement','Erreur lors de la mise a jour du status');
            this.ngxService.stop();
            this.bsModalRef.hide()
          });


        },err=>{
          console.log('Error ==> ',err)
          this.alerte.errorNotification('Creation du Role','Impossible d\'effectuer l\'enrôlement')
          this.closeWithMessage("")
          this.ngxService.stop();
          this.bsModalRef.hide()
        })
      }
    }else{
      this.alerte.errorNotification('Enrôlement','Veuillez séléctionner dossier, chambre et une date pour l\'enrôlement')
      this.ngxService.stop();
      // this.bsModalRef.hide()
    }



    //End Create Jugmenet

   /* let validDay:Boolean = false;
    let dayChecked: string;
    let authorizeEnrollement: boolean = false;
    // check day
    console.log('check jour => '+ date[1]);
    // Vérifier si "Samedi" est présent dans la liste
    try {

      if (num_chambre.jour_travail.includes(date[1])) {
        validDay = true;
        dayChecked = date[1];
        console.log("jour  autorise  dans la liste.");
      } else {
        console.log("jour non autorise dans la liste.");
      }

      //check nbre_enrollement


     // Parcours du tableau affaires_chambre
      for (let i = 0; i < this.penalService._affaires_chambre.length; i++) {
        // Vérifie si le nom de la chambre est
        if (this.penalService._affaires_chambre[i].chambre === num_chambre.name) {
            // Vérifie si le jour est
            if (this.penalService._affaires_chambre[i].jours[0].hasOwnProperty(dayChecked)) {
                // Récupère le nbre_enrollements pour dayChecked
                let nbreEnrollements = this.penalService._affaires_chambre[i].jours[0][dayChecked].nbre_enrollements;
                if (nbreEnrollements<10) {
                  authorizeEnrollement = true;
                }
                console.log("Le nombre d'enrôlements pour "+dayChecked+" dans la " + num_chambre.name+" est :", nbreEnrollements);
                break; // Sort de la boucle une fois que l'élément est trouvé
            }
        }
      }
      if (authorizeEnrollement) {
        var formData = {
          'dossier': dossier.id,
          'chambre': num_chambre.name,
          'infraction': infraction.name,
          'date': date[0],

        }
        console.log( {'form data enroll = ': formData});

         this.openModalSuccessDialog('Dossier enrôlé avec success');
        formData.dossier = null;
        formData.chambre = '';
        formData.infraction = '';
        formData.date = null;

      }else{
        this.openModalSuccessDialog('Echec enrollement');
        formData.dossier = null;
        formData.chambre = '';
        formData.infraction = '';
        formData.date = null;
      }


    } catch (error) {
      console.log('error enrollement');

    }*/

  }

  ngOnInit() {

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("#msform fieldset").length;

      setProgressBar(current);
      if ($("#msform fieldset").hasClass("second")) {
        // Cacher le premier fieldset et afficher le deuxième
        $("#msform fieldset").eq(0).hide();
        $("#msform fieldset").eq(1).show();
        current = 2;

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(1).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("#msform fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("#msform fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });
  }
}
