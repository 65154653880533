import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { TraitementRoutingModule } from './traitement-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbCardModule, NbCheckboxModule, NbContextMenuModule, NbMenuModule, NbToggleModule } from '@nebular/theme';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { LottieModule } from 'ngx-lottie';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LangDropdownComponent } from "../../pages/LangDropdown/LangDropdown/LangDropdown.component";
import { LoginSigninComponent } from './login-signin/login-signin.component';
// import { Traitementcomponent } from './traitement.component';
// import { PreparationPaieComponent } from './preparation/preparation-paie/preparation-paie.component';
// import { CardMakerComponent } from './card-maker/card-maker.component';
// import { PreparationFormComponent } from './preparation/preparation-form/preparation-form.component';
// import { CardMakerComponent } from '../card-maker/card-maker.component';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        // Traitementcomponent,
        // PreparationPaieComponent,
        // CardMakerComponent,
        // PreparationFormComponent,
        LoginSigninComponent
    ],
    providers: [BsModalService],
    imports: [
        FormsModule,
        CommonModule,
        NbCardModule,
        TranslateModule,
        ModalModule ,
        // TraitementRoutingModule,
        NbCardModule,
        NbToggleModule,
        NbCheckboxModule,
        NbContextMenuModule,
        NbMenuModule.forRoot(),
        FormsModule,
        ModalModule,
        ReactiveFormsModule,
        LottieModule.forRoot({ player: playerFactory }),
        LangDropdownComponent,
        NgxUiLoaderModule
    ]
})
export class AuthModule { };
