

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { FrapperService } from '../../services/frapper/frapper.service';
import { AlertesService } from '../../services/public/alertes.service';
import { MyCONST } from '../../CONST/myConst';
import { PublicService } from '../../services/public/public.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private frapper: FrapperService,private publicService: PublicService,  private router: Router, private cookieService: CookieService, private alerte:AlertesService) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
     const token = await this.frapper.getToken()
      switch (MyCONST.IS_PUBLIC) {
        case 0:
          if (token != null) {
            console.log('in the guard',token);
            return true
    
            
          }else{
            console.log('in the guarddd',token);
            this.router.navigateByUrl('/auth');
            this.alerte.errorNotification('Authentification','veuillez s\'authentifier SVP!')
    
            return false;
          }
          break;
      
        default:
          if (this.publicService.getToken() != null) {
            console.log('in the guard',this.publicService.getToken());
            return true
    
            
          }else{
            console.log('in the guarddd',this.publicService.getToken());
            this.router.navigateByUrl('/auth');
            this.alerte.errorNotification('Authentification','veuillez s\'authentifier SVP!')
    
            return false;
          }
          break;
      }
   
    // // Vérifie si les cookies contiennent les attributs sid et system_user
    // const sid = this.cookieService.get('sid');
    // const systemUser = this.cookieService.get('system_user');

    // if (sid && systemUser === 'yes') {
    //   return true;
    // } else {
    //   this.router.navigateByUrl('/login');
    //   return false;
    // }
  }
}

