import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { PenalCourrierService } from '../../../services/penal.courrier/penal.courrier.service';
import { AddNewDocComponent } from '../../../dialogs/add-new-doc/add-new-doc.component';
import { PublicService } from '../../../services/public/public.service';
import { AlertesService } from '../../../services/public/alertes.service';
import { AddPublicServiceComponent } from '../../../dialogs/add-public-service/add-public-service.component';
import { AddPaymentComponent } from '../../../dialogs/add-payment/add-payment.component';
import { ViewDetailsServicepublicComponent } from '../../../dialogs/view-details-servicepublic/view-details-servicepublic.component';
import { PageEvent } from '@angular/material/paginator';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { FooterComponent } from "../layouts/footer/footer.component";
@Component({
  selector: 'app-dashboard-public',
  templateUrl: './dashboard-public.component.html',
  styleUrls: ['./dashboard-public.component.scss'],
})
export class DashboardPublicComponent {

  List_operations: any[] = [];
  isLoading: boolean = true;

  exec_demande;
  tags = [
    "Abacaba",
    "Bananier",
    "Cerf-volant",
    "Dromadaire",
    "Éléphant",
    "Figuier",
    "Giraffe",
    "Hibiscus",
    "Iguane",
    "Jujube",
    "Kiosque",
    "Limonade",
    "Mésange",
    "Nénuphar",
    "Ocelot",
    "Papillon",
    "Quokka",
    "Raclette",
    "Sarbacane",
    "Tamarin",
    "Utopie",
    "Violette",
    "Wombat",
    "Xylophone",
    "Yaourt",
    "Zèbre",
    "Abricot",
    "Bouquin",
    "Chiffon",
    "Doucette",
    "Érable",
    "Framboise",
    "Grizzly",
    "Herbe",
    "Isotherme",
    "Jamboree",
    "Kaleidoscope",
    "Loutre",
    "Mistral",
    "Nectar",
    "Oranger",
    "Pamplemousse",
    "Quenouille",
    "Ruban",
    "Saphir",
    "Tapis",
    "Urgence",
    "Vanille",
    "Waffle",
    "Xérès",
    "Ylang-ylang",
    "Zinnia",
    "Amande",
    "Bouleau",
    "Corbeau",
    "Dragon",
    "Étoile",
    "Fennec",
    "Goudron",
    "Histoire",
    "Iris",
    "Jonquille",
    "Klaxon",
    "Lien",
    "Mistral",
    "Nectarine",
    "Océan",
    "Poussin",
    "Quiche",
    "Rêverie",
    "Sapin",
    "Thym",
    "Ulcère",
    "Verre",
    "Whisky",
    "Xénon",
    "Yeti",
    "Zèbre",
    "Accordéon",
    "Boussole",
    "Calibre",
    "Désert",
    "Équilibre",
    "Flocon",
    "Goudron",
    "Hublot",
    "Incendie",
    "Joute",
    "Klondike",
    "Libellule",
    "Monocycle",
    "Nautique",
    "Ombrelle",
    "Palissade",
    "Quasar",
    "Récif",
    "Serpent",
    "Toupie",
    "Urn",
    "Vortex"
  ];
  categories = ['Casiers', 'Naissances', 'Demandes', 'Nationalite', 'Concours','Jugements','Autres'];

  selectedTags: string[] = [];
  selectedcategories: string[] = [];
  modalRef!: BsModalRef;
  pageDossier: number = 1;
  pageSubs: number = 1;
  pageservices: number = 1;
  numberDossierAV: number = 0;
  numberDossierJI: number = 0;
  numberDossierJE: number = 0;
  OPERATIONS: any[] = [];
  total_operation = 0;

  ELEMENT_DATA = [
    {ref: 1, name: 'Hydrogen', description: 1.0079, symbol: 'H'},
    {ref: 2, name: 'Helium', description: 4.0026, symbol: 'He'},
  ];
  dataSource :any ;
  @Input() donnees: any;

  pageIndex = 0; // Commence à 0
pageSize = 10; // Taille de la page
keyword: string = ''; 
searchTerm: string = '';
isHovered: boolean = false;



  constructor(private modalService: BsModalService, 
    private datePipe: DatePipe,
    private penalCourrierService: PenalCourrierService,private publicServive: PublicService,private alerte:AlertesService,private ngxService: NgxUiLoaderService) { 
    
  }
  onHover() {
    this.isHovered = true;
}

onLeave() {
    this.isHovered = false;
}

  toggleSelection(tag: string) {
    const index = this.selectedTags.indexOf(tag);
    if (index === -1) {
      this.selectedTags.push(tag);  
      
    } else {
      this.selectedTags.splice(index, 1);  
    }
    this.filterByTag(this.selectedTags)
  }


  toggleSelectionCategory(tag: string) {
    const index = this.selectedcategories.indexOf(tag);
    console.log('myindex=>', index);
    
    if (index === -1) {
      this.selectedcategories.push(tag);  
      
    } else {
      this.selectedcategories.splice(index, 1);  
    }
    this.filterByCategorie(this.selectedcategories)
  }

  _toggleSelectionCategory(tag: string) {
    this.selectedcategories.push(tag);
    this.filterByCategorie(this.selectedcategories)
  }

  isSelected(tag: string): boolean {
    return this.selectedTags.includes(tag); 
  }

  isSelectedcat(tag: string): boolean {
    return this.selectedcategories.includes(tag); 
  }

  clearSelectTags(){
    this.selectedTags = []
    this.List_operations = this.OPERATIONS
  }
  clearSelectCats(){
    this.selectedcategories = []
    this.List_operations = this.OPERATIONS
  }

  get filteredTags(): string[] {
    return this.tags
      .filter(tag => tag.toLowerCase().includes(this.searchTerm.toLowerCase()))
      .concat(this.tags.filter(tag => !tag.toLowerCase().includes(this.searchTerm.toLowerCase())));
  }
  async getAllowedDemande() {
    // Démarrage du loader
    this.isLoading = true; 
    this.ngxService.startLoader('servicesmain');
  
    try {
      const res = await this.publicServive.getAllowedDemande().toPromise();
  
      this.OPERATIONS = [];
      this.List_operations = [];
      this.OPERATIONS = res;
      this.List_operations = this.OPERATIONS;
      this.total_operation = res;
      this.getCategories();
      this.getTags();
  
    } catch (error) {
      this.alerte.errorNotification('Demande', 'Une erreur est survenue lors de la récupération des services');
    } finally {
      // Assurez-vous que le loader s'arrête
      this.isLoading = false;
      console.log('the loaddd', this.isLoading);
      
      this.ngxService.stopLoader('servicesmain');
    }
  }
  

  getCategories() {
    let mycats: any[] = [];
  
    for (const cat in this.List_operations) {
      if (Object.prototype.hasOwnProperty.call(this.List_operations, cat)) {
        const element = this.List_operations[cat];
        

        mycats.push(...element.categories); 
      }
    }
  
    // Utilisez un Set pour éliminer les doublons
    const uniqueCategories = Array.from(new Set(mycats));
    this.categories = uniqueCategories
  
    console.log('categories: ', uniqueCategories);
  }

  getTags() {
    let mytags: any[] = [];
  
    for (const cat in this.List_operations) {
      if (Object.prototype.hasOwnProperty.call(this.List_operations, cat)) {
        const element = this.List_operations[cat];
        

        mytags.push(...element.flags); 
      }
    }
  
    // Utilisez un Set pour éliminer les doublons
    const uniqueTags = Array.from(new Set(mytags));
    this.tags = uniqueTags
  
    console.log('tags: ', uniqueTags);
  }

  filterByCategorie(category: string[]){
    // this.ngxService.start()
    // let filteredCats = [];
    // try {
    //   for (const key in this.OPERATIONS) {
    //     if (Object.prototype.hasOwnProperty.call(this.OPERATIONS, key)) {
    //       const element = this.OPERATIONS[key];
    //       if (element.categories.includes(category)) {
    //         filteredCats.push(element);
    //       }
    //     }
    //   }
    //   this.List_operations = filteredCats;

    //   // this.ngxService.stop()
      
    // } catch (error) {
    //   // this.ngxService.stop()
    // }


    this.ngxService.startLoader("loader-01");
    let filteredCats = [];
    
    try {
      for (const key in this.OPERATIONS) {
        if (Object.prototype.hasOwnProperty.call(this.OPERATIONS, key)) {
          const element = this.OPERATIONS[key];
  
          // Vérifiez si au moins un des tags est inclus dans les flags de l'élément
          if (category.some(cat => element.categories.includes(cat))) {
            filteredCats.push(element);
          }
        }
      }
      if (filteredCats.length>0) {
        this.List_operations = filteredCats;
      }else{
        this.List_operations = this.OPERATIONS
      }
      
    } catch (error) {
      console.error('Error filtering operations:', error);
    } finally {
      this.ngxService.stopLoader("loader-01");
    }
  }

  filterByTag(tags: string[]) {
    this.ngxService.startLoader("loader-01");
    let filteredTag = [];
    
    try {
      for (const key in this.OPERATIONS) {
        if (Object.prototype.hasOwnProperty.call(this.OPERATIONS, key)) {
          const element = this.OPERATIONS[key];
  
          // Vérifiez si au moins un des tags est inclus dans les flags de l'élément
          if (tags.some(tag => element.flags.includes(tag))) {
            filteredTag.push(element);
          }
        }
      }
      if (filteredTag.length>0) {
        this.List_operations = filteredTag;
      }else{
        this.List_operations = this.OPERATIONS
      }
      
    } catch (error) {
      console.error('Error filtering operations:', error);
    } finally {
      this.ngxService.stopLoader("loader-01");
    }
  }
  

  

  filterService(){
    this.ngxService.start();
    try {
      let filteredData = this.OPERATIONS.filter(element => element.label?.toLowerCase().includes(this.keyword.toLowerCase()) || element.name?.toLowerCase().startsWith(this.keyword.toLowerCase()));

      if (filteredData.length === 0) {
        this.alerte.errorNotification('Recherche ','Aucun résultat trouvé pour le numéro de recherche ' + this.keyword)
      } else{
        this.List_operations = filteredData

      }
      this.ngxService.stop();
      this.keyword = ''
    } catch (error) {
      this.ngxService.stop();
    }


}
    

  



 // Method to handle page change event
 

  openService(service:any){
    this.exec_demande = service
    console.log({'form service': this.exec_demande})

    const config: ModalOptions = {
      initialState: {
        data: service,
        parentModal: null // Initialiser parentModal à null
      }
    }
  
    // Ouvrir le modal
    if (service != null) {
      this.modalRef = this.modalService.show(AddPublicServiceComponent, Object.assign({}, config, {class:'modal-lg  modal-centered'}));


      // return new Promise<string>((resolve, reject) => {
      //   this.modalRef = this.modalService.show(AddPublicServiceComponent, Object.assign({}, config, {class:'modal-lg  modal-centered'}));
      //   this.modalRef.content.parentModal = this.modalRef;
      //   this.modalRef.onHidden.subscribe((value: string) => {
      //     console.log('response after close=> ',value);
      //     resolve(value); // Résoudre la promesse avec la valeur retournée
      //   });
      // }).then(async (value: string) => {
      //   console.log('response after close=> ',value);
      //   // this.openPaiementDialog(res.service, res.ref)
      //   if ( value == "success") {
      //     await  this.getAllowedDemande()
      //   }
  
        
      // });
  
    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }
    
    
   
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }





  

  // openModal(type_courrier:string) {
  //   const config: ModalOptions = {
  //     initialState: {
  //       typeCourrier: type_courrier,
  //       parentModal: this.modalRef
  //     }
  //   }
  //   this.modalRef = this.modalService.show(FormStepperComponent, Object.assign({},config,{class:'modal-lg '}));
    
  // }
  


  openPaiementDialog(service: any, operation_ref:any){

    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        parentModal: null // Initialiser parentModal à null
      }
    }
  
    // Ouvrir le modal
    if (service != null) {
      const modalRef = this.modalService.show(AddPaymentComponent, Object.assign({}, config, { class: 'modal-lg modal-centered' }));
      // modalRef.content.parentModal = modalRef;
  
    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }
  }

  openDemandeDetailDialog(service: any,operation_ref:any){
    const is_traitemant = service.type=='T'? 1:0
    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        is_traitement:is_traitemant
      }
    }
    if (service != null) {
      const modalRef = this.modalService.show(ViewDetailsServicepublicComponent, Object.assign({}, config, { class: 'modal-lg modal-centered modal-viewdetailspublic' }));
      // modalRef.content.parentModal = modalRef;
  
    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }

  }


 

  async ngOnInit(): Promise<void> {
    this.pageservices = 1
   await  this.getAllowedDemande()


   $(document).ready(function() {
    const $searchButton = $('#searchButton');
    const $searchInput = $('#searchInput');
    const $mysearch = $('.mysearch');

    $searchButton.on('mouseover', function() {
        $searchInput.addClass('active');
        $searchButton.addClass('hide');
        $searchInput.focus();
    });


    $searchInput.on('mouseleave', function() {
        $searchInput.removeClass('active');
        $searchButton.removeClass('hide');
    });
});

  }
}
