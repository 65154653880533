<div class="container" >
  <div class="title-container">
      <h6  style="color: #0c83eb; text-align: center;">Notification</h6>
  </div>
  <div class="card-list d-flex flex-column justify-content-between align-items-center">
    <!-- <div class="col-md-3">
      <div class="card m-auto " >
        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle" width="auto" >
        <div>
          <h5 style="font-size: 11px;">{{data.owner}}</h5>
          <i>Trubinal: {{data.trubinal}}</i>
        </div>
      </div>
    </div> -->
    <div class="col-md-12 col-lg-12">
    

      <div class="sample">
        <div class="d-flex justify-content-between">
          <u></u>
          <button *ngIf="activeElement" class="btn btn-dark floa-right align-end justify-content-end  mb-2" (click)="openModalDetails()"><i class="fas fa-solid fa-eye"></i>&nbsp;{{data.document_type}}</button>
          <div class="d-flex justify-content-end" *ngIf="is_demande_acce" >
           Accepter: <i class="fas fa-solid fa-check text-success" (click)="allowAcce()" style="font-size: 1rem;"></i>&nbsp;&nbsp;
           Rejeter: <i class="fas fa-times text-danger" (click)="rejectDemandesAcce()" style="font-size: 1rem;"></i>
          </div>
        </div>
        <h3 class="sample-title code d-flex justify-content-between" > <span> <u class="text-dark">expéditeur</u>: {{data?.owner ?? data?.email_receiver}}</span>  <span><u class="text-dark">destinataire</u>: {{data.for_user}}</span> </h3>
        <div class="sample-code">
            <div class="code" >
              <div class="d-flex justify-content-between">
                <u>Méssage:</u>
              </div>
                   
               <div  style="background-color: rgb(234, 236, 237);max-height: 320px; overflow: scroll;">
                  <div [innerHTML]="data.subject"></div><br>
                <span class="code-details" [innerHTML]="data.email_content"></span> 
               </div>   


            </div>
        </div>

    </div>
    </div>

  </div>

</div>
