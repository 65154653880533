
<div id="date-picker-modal_ mycalendar" >
  <div class="d-flex justify-content-center">
    <h5 id="heading" class="text-dark" style="font-size: 13px;">Calendrier d'audiences </h5>
    <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" (click)="closeModal()" >
      <b style="font-size: 25px;">x</b>
    </button>
  </div>
  <div id="date-picker-top-bar">
    <div id="previous" class="date-picker-change-month">&lsaquo;</div>
    <div id="date-picker-month" class="month">December 17</div>
    <div id="next" class="date-picker-change-month">&rsaquo;</div>
  </div>
  <div id="date-picker-exit">&times;</div>
  <table id="date-picker" class="table-bordered cell  bg-gradient-primary" >
    <tr id="date-picker-weekdays">
      <th>S</th>
      <th>M</th>
      <th>T</th>
      <th>W</th>
      <th>T</th>
      <th>F</th>
      <th>S</th>
    </tr>
   
    <!-- Actual calendar rows added dynamically -->
    <!--<tr class="date-picker-calendar-row"></tr>-->
  </table><br>
  
  
</div>
<div style="margin: auto; margin-left: 45%;">
  <button class="btn btn-success align-items-center m-auto align-middle" *ngIf="data!= null && validation" (click)="newEnrole()">confirmer</button>
</div><br>
