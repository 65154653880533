<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
  </head>

  <body>
    <!-- <div class="sticky-container">
      <app-sticky-note></app-sticky-note>
    </div> -->
    <div class="page view-details-courrier">
      <div class="d-flex justify-content-center">
        <h5 id="heading" class="text-light">Informations de l'audience {{data.name}}</h5>
        <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
          <i class="fas fa-times text-light" style="font-size: 1.5rem; "></i>
        </button>
      </div>
        <div class=" text-nowrap m-auto">

          <div style="margin-bottom:20px;">
            <div class="d-flex justify-content-between" >
              <div>
                <!-- <div *ngIf="status_jugement !== 'renvoi' && status_jugement !== 'report' && status_jugement !== 'en attente de date du Jugement' && status_jugement !== 'definitive'" class="addButton col-md-3 col-lg-3 float-right w-auto" style="min-width: 220px;"   (click)="openModalEditText(data)"> -->
                <div class="addButton col-md-3 col-lg-3 float-right w-auto" style="min-width: 220px;"   (click)="openModalEditText(data)">

                  <div class="button  b4"  ><i class="fas fa-solid fa-pen text-light" style="font-size: 15px;"></i> Notes d'audience</div>
                </div>
              </div>

              <div>
                <!-- <div *ngIf="status_jugement !== 'renvoi' && status_jugement !== 'report' && status_jugement !== 'en attente de date du Jugement' && status_jugement !== 'definitive'" class="addButton col-md-3 col-lg-3 float-right w-auto" style="min-width: 220px;"   (click)="openModalListNotes(data)"> -->
                  <div  class="addButton col-md-3 col-lg-3 float-right w-auto" style="min-width: 220px;"   (click)="openModalListNotes(data)">

                  <div class="button  b3"  ><i class="fas fa-solid fa-pen text-light" style="font-size: 15px;"></i> Notes</div>
                </div>
              </div>



              <div>

              </div>

            </div>
          </div>

        </div>


       <!-- tabs -->
       <div class="pcss3t-jugement pcss3t-jugement-effect-scale pcss3t-jugement-theme-1 ">


         <input type="radio" name="pcss3t-jugement" checked   id="tab1"class="tab-content-first">

         <label for="tab1">Détails</label>

         <input type="radio" name="pcss3t-jugement" id="tab2" class="tab-content-2">
         <label for="tab2">Information judiciaire</label>

         <input type="radio" name="pcss3t-jugement" id="tab3" class="tab-content-3">
         <label for="tab3">Enrôlement</label>

         <ng-container *ngIf="status_jugement !== 'definitive' && status_jugement !== 'decision rendue' && status_jugement !== 'Mise en délibéré' && status_jugement !=='en appel' ">
          <input type="radio" name="pcss3t-jugement" id="tab7" class="tab-content-7">
          <label for="tab7">Renvoi</label>
         </ng-container>

         <ng-container *ngIf="status_jugement !== 'renvoi' && status_jugement !== 'report' && status_jugement !== 'en attente de date du jugement' && status_jugement !== 'mise en délibéré' && status_jugement !=='radiation' ">
          <input type="radio" name="pcss3t-jugement" id="tab4" class="tab-content-4">
          <label for="tab4">Décisions</label>

        </ng-container>
          <ng-container *ngIf="status_jugement !== 'en attente de date du jugement'
            && status_jugement !== 'en attente du jugement'
            && status_jugement !== 'en attente de decision'
            && status_jugement !== 'renvoi' && status_jugement !== 'report'
             && status_jugement !== 'mise en délibéré' && status_jugement !=='radiation'">
          <input type="radio" name="pcss3t-jugement" id="tab6" class="tab-content-6">
          <label for="tab6">Factas & Qualités</label>



            <input type="radio" name="pcss3t-jugement" id="tab5" class="tab-content-5">
          <label for="tab5">Imprimer décision</label>
          </ng-container>



         <ul  style="height: 450px;">
           <li class="tab-content tab-content-first typography" style="overflow: hidden;">
            <br>


            <fieldset >

              <div class="form-card">
                <div class="row">

                </div>
                <div class="row">
                  <div class="col">


                     <label class="fieldlabels">Statut: *</label> <input type="text" class="form-control" name="status"  value="{{data.status}}" />
                     <label class="fieldlabels">Numéro Dossier: *</label> <input type="text" class="form-control" name="nature" value="{{data.numero_de_dossier}}"  />

                  </div> &nbsp; &nbsp;
                  <div class="col d-flex  flex-column justify-content-between " >
                    <label class="fieldlabels">Tribunal: *</label> <input type="text" class="form-control" name="nature" value="{{data.tribunal}}" />
                    <label class="fieldlabels">Date Audience: *</label> <input type="text" class="form-control" name="nature" value="{{getFormattedDate(data.date_de_jugement)}}" />


                  </div>
                </div>
                <div class="row">
                  <div class="d-flex justify-content-between col-md-12 " >
                    <!-- <label class="">Infractions: *</label> -->
                    <!--<a (click)="openModalAddPiece()" class="text-secondary font-weight-bold text-xs float-right" data-toggle="tooltip"  data-original-title="Edit user">
                      <i class="fas fa-solid fa-plus text-primary"></i> <span class="text-primary"> Ajouter infraction</span>
                  </a>-->
                  <table class="col-md-8 col-sm-8 col-lg-8 table details_infractionTable " style="overflow: auto;min-width: 500px; font-size: 11px;">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Infractions</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    <ng-container >
                      <tr *ngFor="let selectedInfraction of Infractions; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ selectedInfraction.nature}}</td>
                        <td><i class="fas fa-solid fa-arrow-up text-success" ></i></td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="selectedInfractions.length > 0; else noData">
                        <tr *ngFor="let selectedInfraction of selectedInfractions; let i = index">
                          <td>{{ i + 1 }}</td>
                          <td>{{ selectedInfraction }}</td>
                          <td><i class="fas fa-solid fa-trash text-danger" (click)="deleteInfraction(selectedInfraction)"></i></td>
                        </tr>
                      </ng-container>
                      <ng-template #noData>
                        <tr>
                          <td colspan="3">Aucune donnée trouvée.</td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                  <div class="infrac">
                    <div class="container">
                      <div class="select-btn">
                        <span class="btn-text">infractions</span>
                        <span class="arrow-dwn">
                          <p style="font-size: 16px;color: #FFF;"><b>+</b></p>
                        </span>
                      </div>

                      <ul class="list-items">
                        <li class="item" *ngFor="let infraction of infractions" (click)="toggleSelection(infraction.name)" [class.checked]="infractions.includes(infraction.name)">
                          <span class="checkbox">
                            <i class="fa-solid fa-check check-icon"></i>
                          </span>
                          <span class="item-text">{{ infraction.name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                    <!-- <button class="btn btn-dark float-right" >Ajouter une piece</button> -->
                  </div>


                </div>



            </div>
          </fieldset>
           </li>

           <li class="tab-content tab-content-2 typography" style="overflow: auto;height: 450px;">
             <br>
             <fieldset >
              <div class="form-card">
                <form #persnalInformation="ngForm" (ngSubmit)="savePersonalInformation(persnalInformation)">
                  <div class="d-flex mb-3 col justify-content-end " >
                    <label class="fieldlabels mt-4">Activer pour le mode plusieurs prevenus: </label>
                    <div class="toggle-wrapper "  >
                      <input class="toggle-input" [checked]="data.is_multiple_prevenu === 1" type="checkbox" (change)="selectTypePrevenu($event)" id="pin-toggle" />
                      <label class="toggle-container" for="pin-toggle">
                        <div class="toggle-circle">
                          <div class="pin-icon">
                            <span class="pin-top"></span>
                            <span class="pin-middle"></span>
                            <span class="pin-bottom"></span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row">

                    <ng-container *ngIf="is_multiple_prevenu == 0 ;else secondBlock">
                      <div class="col">
                        <label class="fieldlabels">Type d'identification du {{data.classification=='Pénale'?'plaignant':'demandeur'}} : </label>
                            <input type="text" class="form-control"  name="type_identification_plaignant" [ngModel]="data.identification_type" ngModel />

                        <label class="fieldlabels">Numéro d'identification du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                            <input type="text" class="form-control" name="numéro_didentification_plaignant" [ngModel]="data['numéro_didentification_plaignant']" ngModel />

                        <label class="fieldlabels">Nom complet du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                        <input class="form-control" type="text" name="nom_complet_plaignant" [ngModel]="data.nom_complet_plaignant" ngModel/>

                        <label class="fieldlabels">Lieu de naissance du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                          <input type="text" class="form-control" name="lieu_de_naissance_du_plaignant" [ngModel]="data.lieu_de_naissance_du_plaignant"  ngModel/>

                        <label class="fieldlabels">Date de naissance du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                          <input type="date" class="form-control"  name="date_de_naissance_du_plaignant" [ngModel]="data.date_de_naissance_du_plaignant" ngModel />

                        <label class="fieldlabels">Adresse complet du {{data.classification=='Pénale'?'plaignant':'demandeur'}} </label>
                          <input class="form-control" type="text" name="addresse_complete_du_plaignant" [ngModel]="data.addresse_complete_du_plaignant" ngModel />

                        <label class="fieldlabels">Nom complet de la mére du {{data.classification=='Pénale'?'plaignant':'demandeur'}} </label>
                          <input class="form-control" type="text" name="nom_complet_de_la_mere_du_plaignant" [ngModel]="data.nom_complet_de_la_mere_du_plaignant" ngModel/>

                        <label class="fieldlabels">Nom complet du pére du {{data.classification=='Pénale'?'plaignant':'demandeur'}} </label>
                          <input class="form-control" type="text"  name="nom_complet_du_pere_du_plaignant" [ngModel]="data.nom_complet_du_pere_du_plaignant" ngModel/>

                          <div class="col d-flex flex-column justify-content-between " >
                            <label class="fieldlabels">Avocat du Plaignant/Demandeur </label>
                            <input class="form-control" type="text" name="avocat_du_plaignant" [ngModel]="data.avocat_du_plaignant" ngModel/>
                          </div>
                      </div> &nbsp; &nbsp;

                      <div class="col d-flex  flex-column justify-content-between " >


                          <label class="fieldlabels">Type d'identification demandé/poursuivis: </label>
                        <input *ngIf="data.identification_type" [ngModel]="data.identification_type" name="identification_type" ngModel>
                        <select *ngIf="!data.identification_type" class="custom-select" id="inputGroupSelect01" name="identification_type" ngModel>
                          <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                        </select>

                        <label class="fieldlabels">Numéro d'identification du {{data.classification=='Pénale'?'prévenu':'demandé'}} : </label>
                        <input type="text" class="form-control" name="numéro_didentification_plaignant" [ngModel]="data.identification_value" ngModel />
                          <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column ">
                              <label class="fieldlabels">Prenom du demandé/poursuivis : *</label>
                              <input class="form-control" type="text" name="prenom" [ngModel]="data.prenom" ngModel/>
                            </div>
                            <div class="d-flex flex-column ">
                              <label class="fieldlabels">Nomdu demandé/poursuivis : *</label>
                              <input class="form-control" type="text" name="nom" [ngModel]="data.nom" ngModel/>
                            </div>
                          </div>


                        <label class="fieldlabels">Lieu de naissance du {{data.classification=='Pénale'?'prévenu':'demandé'}} : </label>
                          <input type="text" class="form-control"   name="lieu_de_naissance_du_prévenu" [ngModel]="data['lieu_de_naissance_du_prévenu']" ngModel/>

                        <label class="fieldlabels">Date de naissance du {{data.classification=='Pénale'?'prévenu':'demandé'}} : </label>
                          <input type="date" class="form-control" [ngModel]="data['date_de_naissance_du_prévenu']"  name="date_de_naissance_du_prévenu" ngModel/>

                        <label class="fieldlabels">Adresse complet du {{data.classification=='Pénale'?'prévenu':'demandé'}}  </label>
                          <input class="form-control" type="text" [ngModel]="data['addresse_complete_du_poursuivis']"  name="addresse_complete_du_poursuivis" ngModel />

                        <label class="fieldlabels">Nom complet de la mére du {{data.classification=='Pénale'?'prévenu':'demandé'}}  </label>
                          <input class="form-control" type="text" [ngModel]="data['nom_complet_de_la_mere_du_prévenu']"  name="nom_complet_de_la_mere_du_prévenu" ngModel/>

                        <label class="fieldlabels">Nom complet du pére du {{data.classification=='Pénale'?'prévenu':'demandé'}}  </label>
                          <input class="form-control" type="text" [ngModel]="data['nom_complet_du_pere_du_prévenu']" name="nom_complet_du_pere_du_prévenu" ngModel />

                          <label class="fieldlabels">Nom Complet de l'avocat du demandé/poursuivis : *</label>
                          <input class="form-control" type="text" name="avocat_du_poursuivis" [ngModel]="data.avocat_du_poursuivis"  ngModel/>
                      </div>

                    </ng-container>

                    <ng-template #secondBlock>
                      <div class="col">
                        <label class="fieldlabels">Type d'identification du {{data.classification=='Pénale'?'plaignant':'demandeur'}} : </label>
                            <input type="text" class="form-control"  name="type_identification_plaignant" [ngModel]="data.type_identification_plaignant" ngModel />

                        <label class="fieldlabels">Numéro d'identification du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                            <input type="text" class="form-control" name="numéro_didentification_plaignant" [ngModel]="data['numéro_didentification_plaignant']" ngModel />

                        <label class="fieldlabels">Nom complet du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                        <input class="form-control" type="text" name="nom_complet_plaignant" [ngModel]="data.nom_complet_plaignant" ngModel/>


                        <label class="fieldlabels">Lieu de naissance du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                          <input type="text" class="form-control" name="lieu_de_naissance_du_plaignant" [ngModel]="data.lieu_de_naissance_du_plaignant"  ngModel/>

                        <label class="fieldlabels">Date de naissance du {{data.classification=='Pénale'?'plaignant':'demandeur'}}: </label>
                          <input type="date" class="form-control"  name="date_de_naissance_du_plaignant" [ngModel]="data.date_de_naissance_du_plaignant" ngModel />

                        <label class="fieldlabels">Adresse complet du {{data.classification=='Pénale'?'plaignant':'demandeur'}} </label>
                          <input class="form-control" type="text" name="addresse_complete_du_plaignant" [ngModel]="data.addresse_complete_du_plaignant" ngModel />

                        <label class="fieldlabels">Nom complet de la mére du {{data.classification=='Pénale'?'plaignant':'demandeur'}} </label>
                          <input class="form-control" type="text" name="nom_mere_plaignant" [ngModel]="data.nom_complet_de_la_mere_du_plaignant" ngModel/>

                        <label class="fieldlabels">Nom complet du pére du {{data.classification=='Pénale'?'plaignant':'demandeur'}} </label>
                          <input class="form-control" type="text"  name="nom_pere_plaignant" [ngModel]="data.nom_complet_du_pere_du_plaignant" ngModel/>

                          <div class="col d-flex flex-column justify-content-between " >
                            <label class="fieldlabels">Avocat du Plaignant/Demandeur </label>
                            <input class="form-control" type="text" name="avocat_du_plaignant" [ngModel]="data.avocat_du_plaignant" ngModel/>
                          </div>
                      </div>  &nbsp; &nbsp;

                        <div class="col d-flex  flex-column justify-content-start " >
                          <div class="d-flex mb-3"  >
                            <label class="fieldlabels mt-4">Activer le mode plusieurs prevenus: </label>
                            <div class="toggle-wrapper " [ngStyle]="{'opacity': data.is_multiple_prevenu == 1 ? '0.3' : '1'}" >
                              <input class="toggle-input" [checked]="data.is_multiple_prevenu == 1? true: false" [disabled]="data.is_multiple_prevenu == 1? true: false"   type="checkbox" type="checkbox" (change)="selectTypePrevenu($event)"  id="pin-toggle"/>
                              <label class="toggle-container" for="pin-toggle">
                                <div class="toggle-circle">
                                  <div class="pin-icon">
                                    <span class="pin-top"></span>
                                    <span class="pin-middle"></span>
                                    <span class="pin-bottom"></span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                          <label ><b class="bg-dark text-light">Liste prevenus:</b>&nbsp;&nbsp; <i id="addRowBtn"  class="fa fa-solid fa-plus text-success" style="font-size:22px; cursor:pointer" (click)="openModalAddPrevenu()"></i></label>
                          <div style="font-size: 13px;min-height: 600px; border: dashed 1px grey;">
                            <table class="table table-striped table-striped" style="font-size: 13px;">
                              <thead>
                                <tr class="bg-secondary text-light">
                                  <th scope="col">#</th>
                                  <th scope="col">Nom complet</th>
                                  <!-- <th scope="col">Type identification</th>
                                  <th scope="col">Numero identification</th> -->
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>{{ data.prenom }} {{ data.nom }}</td>
                                  <td class="align-middle text-center text-sm">
                                    <div class="d-flex justify-content-between ">
                                      <a href="javascript:;" class="text-secondary font-weight-bold text-xs " (click)="openModalDetailsPrevenu(data)" data-toggle="tooltip"  data-original-title="Edit user">
                                          <i class="fas fa-solid fa-eye text-primary float-right" style="font-size: 21px;"></i>
                                      </a>
                                      <a href="javascript:;" class="text-secondary font-weight-bold text-xs " (click)="deletePrevenu(data)" data-toggle="tooltip"  data-original-title="Edit user">
                                        <i class="fas fa-solid fa-trash text-danger float-right" style="font-size: 20px;"></i>
                                    </a>


                                  </div>
                                   </td>
                                </tr>
                                <tr *ngFor="let prevenu of prevenusList; let i = index">
                                  <th scope="row">{{ i + 2 }}</th>
                                  <td>{{ prevenu.nom_complet_du_poursuivis }}</td>
                                  <!-- <td>{{ prevenu['type_identification_du_poursuivis'] }}</td>
                                  <td>{{ prevenu['numéro_didentification_du_poursuivis'] }}</td> -->
                                  <td class="align-middle text-center text-sm">
                                    <div class="d-flex justify-content-between ">
                                      <a href="javascript:;" class="text-secondary font-weight-bold text-xs " (click)="openModalDetailsPrevenu(prevenu)" data-toggle="tooltip"  data-original-title="Edit user">
                                          <i class="fas fa-solid fa-eye text-primary float-right" style="font-size: 21px;"></i>
                                      </a>
                                      <a href="javascript:;" class="text-secondary font-weight-bold text-xs " (click)="deletePrevenu(prevenu)" data-toggle="tooltip"  data-original-title="Edit user">
                                        <i class="fas fa-solid fa-trash text-danger float-right" style="font-size: 20px;"></i>
                                    </a>


                                  </div>
                                   </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div><br>

                    </ng-template>

                  </div>

              <input type="submit"  class="action-button btl-lg bg-secondary text-light" value="Sauvegarder">
            </form>
            <hr>
          </div>
           </fieldset>
            </li>

            <li class="tab-content tab-content-3 ">

              <fieldset  class="detailJugement">
                <div class="form-card">
                  <div class="d-flex justify-content-between flex-column align-middle flex-wrap align-items-center ">
                    <div class="title-container">
                      <div class="d-flex justify-content-center">
                          <h6 id="heading" class="text-primary">Détails enrôlement</h6>

                        </div>
                    </div>
                    <div class="card-list d-flex justify-content-between col-12 flex-wrap align-items-center">
                      <div class="col-sm-10 col-md-3 col-lg-3 mb-2 text-center">
                        <div class="card align-items-center" (click)="openModalDetailDossier({name:data.numero_de_dossier})">
                          <h3 class="card-title code code-details">
                            <i class="fas fa-regular fa-folder text-primary" style="font-size: 50px;"></i>
                          </h3>
                          <h6 style="font-size: 11px;" class="card-description texte-limite">{{data.numero_de_dossier}}</h6>
                        </div>
                      </div>
                      <div class="col-sm-10 col-md-3 col-lg-3 mb-2 text-center">
                        <div class="card align-items-center">
                          <h3 class="card-title code code-summary">
                            <i class="fas fa-regular fa-building text-primary" style="font-size: 50px;"></i>
                          </h3>
                          <h6 style="font-size: 11px;" class="card-description">{{enrolle?.chambre}}</h6>
                          <i>{{data?.libelle}}</i>
                        </div>
                      </div>
                      <div class="col-sm-10 col-md-3 col-lg-3 mb-2 text-center">
                        <div class="card align-items-center">
                          <h3 class="card-title code code-details">
                            <i class="fas fa-regular fa-calendar text-primary" style="font-size: 50px;"></i>
                          </h3>
                          <h4 style="font-size: 11px;" class="card-description">{{getFormattedDate(enrolle?.date_du_jugement)}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
             </fieldset>
          </li>

          <li class="tab-content tab-content-4 "><br>

           <fieldset>

            <div class="form-card">
              <div class="d-flex justify-content-between"*ngIf="ALLOWED_SAVE_DECISION ==true">
                <div class="col-lg-3 col-sm-3  actionBtn mt-3" >

                </div>
                <div class="col-lg-3 col-sm-3 actionBtn mt-3">
                  <div class="addButton  ">
                    <div class="button b2 text-nowrap  " [disabled]="!CAN_DO_ACTION" (click)="UpdateDecision()"><span style="font-size: 12px;">Confirmer Décisions</span> </div>
                  </div>
                </div>

              </div>

              <div class="d-flex justify-content-between" *ngIf="ALLOWED_SAVE_DECISION ==false && data.status != 'Definitive' " >
                <div class="col-lg-3 col-sm-3  actionBtn mt-3" >

                </div>
                <div class="col-lg-3 col-sm-3 actionBtn mt-3">
                  <div class="addButton  ">
                    <div class="button b3 text-nowrap  " *ngIf="!can_edit_decision" (click)="active_edit_decision()"><span style="font-size: 12px;">Modifier Décisions</span> </div>
                    <div class="button b2 text-nowrap  " *ngIf="ALLOWED_SAVE_DECISION && can_edit_decision" (click)="UpdateDecision()"><span style="font-size: 12px;">Enregistrer Décisions</span> </div>

                  </div>
                </div>

              </div>

              <div class="container">

                <div class="row mb-3">
                  <div class="col-md-6 d-flex flex-column">
                    <label class="fieldlabels">Décision judiciaire:</label>
                    <div class="input-container">
                      <input type="text" [readOnly]="!can_edit_decision" id="decisionInput" [value]="Decision_Judiciaire != null ? Decision_Judiciaire: ''"  class="form-control" placeholder="Sélectionnez ou Saisissez la décision" />
                      <div class="list_item dropdown-menu" style="display: none;" *ngIf="can_edit_decision">
                        <ul>
                          <!-- Liste dynamique sera remplie par jquery -->
                        </ul>
                      </div>
                      <p class="no-match-suggestion" style="display:none; color: blue; cursor: pointer;">
                        Ajouter "<span class="new-item"></span>"
                      </p>
                    </div>
                    <!-- <select class="form-select decisions" (change)="UpdateTypeCondamnation($event.target.value)">
                      <option [value]="Decision_Judiciaire">{{ Decision_Judiciaire }}</option>
                      <option *ngFor="let option of TypeDeCondamnation" [value]="option.name">{{ option.name }}</option>
                    </select> -->
                    <span *ngIf="false">
                      <label class="fieldlabels">Catégorie de la décision: *</label>
                    <div class="input-container">
                      <input type="text" id="categorieInput" [readOnly]="!can_edit_decision" [value]="Categorie_Decision != null ? Categorie_Decision: '' "  class="form-control" placeholder="Sélectionnez ou Saisissez la catégorie" />
                      <div class="categorieslist_item dropdown-menu" *ngIf="can_edit_decision" style="display: none;">
                        <ul>
                          <!-- Liste dynamique sera remplie par jquery -->
                        </ul>
                      </div>
                      <p class="no-match-suggestion_cat" style="display:none; color: blue; cursor: pointer;">
                        Ajouter "<span class="new-item"></span>"
                      </p>
                    </div>
                    </span>

                    <!-- <select class="form-select" (change)="UpdateCategoriCondoamnatione($event.target.value)">
                      <option [value]="Categorie_Decision">{{ Categorie_Decision }}</option>
                      <option *ngFor="let option of CategorieDeCondamnation" [value]="option.name">{{ option.name }}</option>
                    </select> -->
                  </div>

                  <div class="col-md-6">
                    <label class="fieldlabels">Date de la décision: *</label>
                    <input type="date" [readOnly]="!can_edit_decision" class="form-control" (change)="UpdateDatedeDecision($event.target.value)" name="date_de_decision" [ngModel]="data.date_de_decision" />
                    <label class="fieldlabels">Code de la décision: *</label>
                    <input type="text" [readOnly]="!can_edit_decision" class="form-control"   [readOnly]="true" [ngModel]="jugement_code" />



                  </div>

                </div>&nbsp;&nbsp;

                <div class="row mb-3">
                  <div class="col shadow">
                    <label class="fieldlabels bg-dark text-light">Contrainte par corps:</label>
                   <!-- <i class="fas fa-solid fa-plus text-success" style="font-size: 20px;" (click)="ajouterLigne()"></i>-->

                    <div class="container">
                      <table class="table table-striped table-responsive text-center" style="font-size: 10px;">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Début</th>
                            <th>Main levée</th>
                            <th>Fin</th>
                            <th class="col-md-2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let cpc of ContrainteParCorps; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td><input type="date" [(ngModel)]="cpc.debut" class="form-control" /></td>
                            <td><input type="text" [(ngModel)]="cpc.fin" class="form-control" /></td>
                            <td><input type="date" [(ngModel)]="cpc.main_leve" class="form-control" /></td>
                            <td>
                              <i class="fas fa-solid fa-arrow-up text-success" style="font-size: 17px;"></i>
                            </td>
                          </tr>
                          <tr *ngFor="let ligne of lignes; let i = index">
                            <td>{{ ligne.id }}</td>
                            <td><input type="date" [(ngModel)]="ligne.debut" class="form-control" /></td>
                            <td><input type="date" [(ngModel)]="ligne.fin" class="form-control" /></td>
                            <td><input type="date" [(ngModel)]="ligne.main_leve" class="form-control" /></td>
                            <td>
                              <i class="fas fa-solid fa-trash text-danger" style="font-size: 17px;" (click)="deleteContrainte(ligne)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
               <br>


              <div class="d-flex  flex-column col-md-12 col-lg-12">

              <div class="shadow col-md-12 col-lg-12 col-sm-12">
                <label class="fieldlabels  bg-dark text-light">Emprisonnements: </label> &nbsp;&nbsp; <i class="fas fa-solid fa-plus text-success" *ngIf="can_edit_decision" style="font-size: 20px;"  (click)="ajouterLigne_condamnation()"></i>
                <table class="table table-striped table-responsive text-center "  style="font-size: 10px;">
                  <thead>
                    <tr>
                      <th >Type</th>
                      <th>Durée</th>
                      <th>Périodicite</th>
                      <th class="col-md-2 col-sm-2" >Action</th> <!-- Colonne pour l'icône d'ajout -->
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngFor="let emp of Emprisonements">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td><input type="text" [(ngModel)]="emp.type"></td>
                      <td><input type="text" [(ngModel)]="emp['durée']"></td>
                      <td><input type="text" [(ngModel)]="emp['périodicité']"></td>
                      <td>
                        <i class="fas fa-solid fa-arrow-up text-success mt-2" style="font-size: 17px;"></i></td>
                    </tr>
                    <tr *ngFor="let ligne of lignes_condamenations">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td>
                        <select class="form-select" [(ngModel)]="ligne.type" >
                          <option *ngFor="let option of optionsSelect" [value]="option">{{ option }}</option>
                        </select>
                      </td>
                      <td><input type="text" placeholder="Duree"  [(ngModel)]="ligne['durée']" ></td>
                      <td>
                        <select class="form-select" [(ngModel)]="ligne['périodicité']" >
                          <option *ngFor="let option of periodicite" [value]="option">{{ option }}</option>
                        </select>
                      </td>
                      <td> <i class="fas fa-solid fa-trash text-danger mt-2" style="font-size: 17px;"  (click)="deleteEmprisonnement(ligne)" [(ngModel)]="ligne.colonne2"></i></td>

                    </tr>

                  </tbody>
                </table>
              </div>
                &nbsp;&nbsp;
              <div  class="col-md-12 col-lg-12 col-sm-12 shadow">
                <label class="fieldlabels  bg-dark text-light">Amendes: </label> &nbsp;&nbsp; <i class="fas fa-solid fa-plus text-success" *ngIf="can_edit_decision" (click)="ajouterLigne_ammendements()" style="font-size: 20px;"></i>
                <table class="table table-striped table-responsive text-center"  style="font-size: 10px;">
                  <thead>
                    <tr>
                      <th >Montant</th>
                      <th>Aux depends liquidés à la somme</th>
                      <!-- <th>Periodicite</th> -->
                      <th class="col-md-2 col-sm-2" >Action</th> <!-- Colonne pour l'icône d'ajout -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let amd of Amendes">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td>
                        <input type="number" class="form-control" placeholder="montant" [(ngModel)]="amd.montant">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="depends liquidés ..." [(ngModel)]="amd.aux_depens_liquides_a_la_somme">
                      </td>
                      <td> <i class="fas fa-solid fa-arrow-up text-success mt-2" style="font-size: 17px;"  ></i></td>

                    </tr>
                    <tr *ngFor="let ligne of lignes_ammendes">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td>
                        <input type="number" class="form-control" placeholder="montant" [(ngModel)]="ligne.montant">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="depends liquidés ..." [(ngModel)]="ligne.aux_depens_liquides_a_la_somme">
                      </td>
                      <td> <i class="fas fa-solid fa-trash text-danger mt-2" style="font-size: 17px;"  (click)="deleteAmmendes(ligne)"[(ngModel)]="ligne.colonne2"></i></td>

                    </tr>

                  </tbody>
                </table>
              </div>
              </div>
          </div>

         </fieldset>

        </li>

        <li class="tab-content tab-content-5 "><br><br>
          <span *ngIf="CAN_SEE_DOCUMENTS">
            <h6 class="text-center"><u>Documents Emis</u></h6>
            <!-- (click)="openModalVisualJugement(data)" -->
          <div class="container">

            <div class="form cf">
              <section class="payment-plan cf row d-flex justify-content-between"   ngModel style="width: 100%;">
              <div class="col-3 rounded-circle mt-2 " *ngFor="let jugement of list_documents;">
                <label class="monthly-label four col " for="{{jugement.name}}" (click)="openModalVisualDecision(objet_jugment,jugement,1)"><img src="{{jugement.image}}" class="shadow" width="150" height="100" alt="" style="margin: auto;">
                  <p style="font-size: 13px;margin-top: 5px;">{{jugement.template_name}}</p>
                </label>
                  <input type="radio"   value="{{jugement.name}}" name="tag" id="{{jugement.name}}" ><br>

              </div><br>
            </section>


            </div>
            &nbsp;&nbsp;
        </div>

          </span>
          <span *ngIf="!CAN_SEE_DOCUMENTS && !CAN_SEE_TEMPLATE" class="text-center m-auto">
            Aucune donnée
        </span>

          <span *ngIf="CAN_SEE_TEMPLATE">
            <h6 class="text-center"><u>Genererateur de document</u></h6>
            <!-- (click)="openModalVisualJugement(data)" -->
          <div class="container">

            <div class="form cf">
              <section class="payment-plan cf row d-flex justify-content-between"   ngModel style="width: 100%;">
              <div class="col-3 rounded-circle mt-2 " *ngFor="let jugement of list_jugement;">
                <label class="monthly-label four col " for="{{jugement.name}}" (click)="openModalVisualDecision(objet_jugment,jugement)"><img src="{{jugement.image}}" class="shadow" width="150" height="100" alt="" style="margin: auto;">
                  <p style="font-size: 13px;margin-top: 5px;">{{jugement.template_name}}</p>
                </label>
                  <input type="radio"   value="{{jugement.name}}" name="tag" id="{{jugement.name}}" ><br>

              </div><br>
            </section>


            </div>
            &nbsp;&nbsp;
        </div>
          </span>

          <!-- <div class="card l-bg-blue-dark"  >
              <div class="card-statistic-3 " style="margin: auto;">

                  <div class="card-icon card-icon-large text-center" ><i class="fas fa-solid fa-print" style="font-size: 100px;"></i></div>
                  <h5>
                    <span style="background-color: rgb(209, 188, 147);margin: auto; " class="badge badge-sm bg-gradient-primary text-center">Cliquez pour imprimer</span>
                  </h5>

              </div>
          </div> -->


        </li>

       <li class="tab-content tab-content-6 "><br>

        <fieldset>

         <div class="form-card">
          <div class="d-flex justify-content-between">
            <div class="addButton col-md-2 col-lg-2 float-left"
              style="min-width: 200px;"
              [class.disabled]="!showFactum"
              [style.opacity]="showFactum ? 1 : 0.4"

              (click)="!showFactum ? showFactQuality() : null">
              <div class="button b2">
                <i class="fas fa-solid fa-plus"></i> Factum
              </div>
            </div>


            <div class="addButton col-md-2 col-lg-2 float-left"
              style="min-width: 200px;"
              [class.disabled]="!showFactum"
              [style.opacity]="showFactum ? 0.4 : 1"

              (click)="showFactum ? showFactQuality() : null">
              <div class="button b3">
                <i class="fas fa-solid fa-plus"></i> Qualités
              </div>
            </div>




          </div><br>
          <div class="col-md-12 col-lg-12 m-auto">
            <app-edit-text [data_factas]="data" showTypeData="factas" *ngIf="showFactum"></app-edit-text>
            <app-edit-text [data_qualites]="data"  showTypeData="qualites" *ngIf="!showFactum"></app-edit-text>
          </div>

        </div>

            </fieldset>

          </li>

          <li class="tab-content tab-content-7 "><br>

            <fieldset>
              <div class="form-card" style="min-width: 300px;">
                <div class=" col-12" style="min-height: auto;margin: auto;">
                  <div class="d-flex flex-wrap justify-content-between align-items-start">

                    <form #addRepport="ngForm" (ngSubmit)="updateJugementRepport(addRepport)" class="col-md-6 col-sm-12 mb-3" style="margin: auto; text-align: center;">
                      <div class="d-flex flex-column shadow col-sm-12 p-3"style="min-width: 200px;" >

                        <div class="inputbox mb-2">
                          <select class="custom-select" id="inputGroupSelect01" (change)="filterValueChange($event.target.value)" name="type_d_operation" ngModel>
                            <option value=""  >-- choisir une option --</option>
                            <option *ngFor="let type of type_jugement_reports" [value]="type.name">{{ type.name }}</option>
                          </select>
                        </div>

                        <div class="inputbox mb-2">
                          <textarea class="form-control" style="height: 70px;" placeholder="Raison" name="raison" ngModel></textarea>
                        </div>

                        <div class="inputbox mb-2">
                          <input type="text" class="form-control" placeholder="Demandeur" name="demandeur" ngModel>
                        </div>

                        <div class="inputbox mb-2" *ngIf="need_reenrolement">
                          <input type="text" placeholder="Sélectionner une date" [(ngModel)]="new_date_report" name="nouvelle_date" (click)="openModalCalendar(enrolle)">
                        </div>

                        <br><div class="text-center">
                          <button class="btn bg-success text-light" id="addRowBtn" [disabled]="!(ALLOWED_SAVE_DECISION && (status_jugement.toLowerCase() == 'en attente du jugement'|| status_jugement.toLowerCase() == 'en attente de decision' ))">Enregistrer</button>
                        </div>
                      </div>
                    </form>

                    <div class="col-md-6 col-sm-12 mb-3">
                      <h6 class="text-dark text-center">Liste Opérations</h6>
                      <table id="myTable" class="table table-striped details_infractionTable table-responsive" style="font-size: 11px;min-width: 200px;">
                        <thead>
                          <tr>
                            <th scope="col">Opérations</th>
                            <th scope="col">Raison</th>
                            <th scope="col">Demandeur</th>
                            <th scope="col">Date renvoi</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let operation of jugement_reports">
                              <td>{{ operation.type_d_operation }}</td>
                              <td>{{ operation.raison }}</td>
                              <td>{{ operation.demandeur }}</td>
                              <td>{{ getFormattedDate(operation.nouvelle_date) }}</td>
                              <!-- <td><i class="fas fa-solid fa-arrow-up text-success"></i></td> -->
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

            </div>
            </fieldset>

            </li>


         </ul>

       </div>

       <div class="d-flex justify-content-between flex-wrap" *ngIf="ALLOWED_DECISION_FINAL ==true">
        <div class="col-lg-3 col-md-3 col-sm-10 actionBtn mt-3">
          <div class="addButton  ">
          <!--  <div class="button b1  " (click)="UpdateFinalStatus('En appel')">-->
           <!--<div class="button b1 ">
              <i class="fas fa-solid fa-gavel float-left mt-1 text-nowrap" style="font-size: 20px;"></i> Décision en appel
               </div>-->
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-10  actionBtn mt-3">
          <!--<div class="addButton  ">
           &lt;!&ndash; <div class="button b2  text-light" (click)="UpdateFinalStatus('Definitive')">&ndash;&gt;
              <div class="button b2  text-light">
              <i class="fas fa-solid fa-gavel float-left mt-1 text-nowrap" style="font-size: 20px;"></i> Rendre définitif </div>
          </div>-->
        </div>
        <div>
          <div *ngIf="CAN_APPROUVE " class="addButton col-md-3 col-lg-3 float-right w-auto" style="min-width: 220px;"  >
            <div  class="addButton col-md-3 col-lg-3 float-right w-auto mt-3" style="min-width: 220px;"  >

              <div class="button text-nowrap b6"  (click)="UpdateFinalStatus('',1)"> Approuver informations</div>
            </div>
          </div>
        </div>

        <div>
          <div *ngIf="CAN_CLORE" class="addButton col-md-3 col-lg-3 float-right w-auto" style="min-width: 220px;"  >

            <div  class="addButton col-md-3 col-lg-3 float-right w-auto mt-3" style="min-width: 220px;"  >

              <div class="button text-nowrap b5"  (click)="UpdateFinalStatus('',1)"> Clore l'audience</div>
            </div>
          </div>
        </div>
      </div>
     </div>
     <!-- <ngx-ui-loader></ngx-ui-loader> -->
  </body>
</html>



