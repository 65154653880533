
import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';

import $ from 'jquery';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { log } from 'console';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import {JugementsService} from "../../services/frapper/jugements.service";
import {EnrollementService} from "../../services/frapper/enrollement.service";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {AlertesService} from "../../services/public/alertes.service";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls:[
    './calendar.component.scss'
  ]
})
export class CalendarComponent implements OnInit {


   nomJour: string;
   @Input() parentModal: BsModalRef;

   joursTravail;
   maxDossier : number;
   dossier_encours = 0;
   affaires_chambre;
   getdayMonth = 0;
   type = null;
   newDateEnrole:string;
   @Output() dateSelected: EventEmitter<string> = new EventEmitter<string>();
   @Input() validation: boolean = true;
    joursSemaine = [
    "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"
  ];
  data: any;
  getDossiersEncours(nameChambre){
    let v =  this.penalService._affaires_chambre.find(chambre => chambre.chambre === nameChambre);

    if (v) {
      this.affaires_chambre = v;
      console.log('recherche chambre ===> '+this.affaires_chambre.chambre );
    }

  }
 

  // this.penalService.SelectedChambreChange.subscribe(chambre => {
  //   console.log('chambre on calendar'+ chambre);
  //  if (chambre != null) {

  //    this.joursTravail = chambre.jour_travail;
  //    this.maxDossier = chambre.max;
  //    this.getDossiersEncours(chambre.name)
  //   //  this.dossier_encours =  this.penalService.SelectedChambreChange
  //    console.log('donneee in zooo0909 = > '+ this.joursTravail);
  //    updateDatePicker(false)
  //  }
  // });
  jugement:any =null

   constructor(private penalService: PenalCourrierService,
               private datePipe: DatePipe,
               private modalservice: BsModalService,
               private alerte: AlertesService, public bsModalRef: BsModalRef,
               private role: EnrollementService,
               private ngxService: NgxUiLoaderService,
               private jugementService: JugementsService,
               private options: ModalOptions){
    this.data = this.options.initialState.data;
    try {
      this.type = this.options.initialState.type;
    } catch (error) {
      
    }
    //console.log({'data in cal ===> ':this.data} );

     // date_du_jugement
     // numero_du_jugement
     if(this.data){
       let filter = '[["numero_de_jugement","=","'+this.data.numero_du_jugement+'"]]'
       this.jugementService.getAllMyJugement(filter).then(res =>{
         this.jugement=(res.data)[0]
         //console.log('numero_de_jugement [0] ==> ',(res.data)[0])
       })
     }

   }

   onSelectDate(date: any) {
   let p =  this.penalService.setSelectedDate([date,this.nomJour]);
    this.dateSelected.emit(p[0]);


  }
  closeModal(){
    this.bsModalRef.hide()
  }


  newEnrole(){
    this.ngxService.start()
    console.log('Start ==>')
    console.log('Start ROLE==> ',this.data)
    console.log('Start JUGEMENT==> ',this.jugement)
    let previous_enrole = this.data
    const parts = this.newDateEnrole.split('/');
    const parsedDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    let new_date = this.datePipe.transform(parsedDate, 'yyyy-MM-dd')
    console.log('newDateEnrole'+ new_date);
    console.log({'and objet': previous_enrole});

    let body_role={
      "date_du_jugement": new_date,
      "date_prevue":this.data.date_de_jugement
    }
    if (this.type == null) {
      this.role.updateRole(this.data.name,body_role).then(res=>{
        console.log('Role Updated !',res)
        let body_jugement={
          "date_de_jugement": new_date
        }
        this.jugementService.updateJugement(this.jugement.name,body_jugement).then(res=>{
          console.log('Updated !')
          this.alerte.successNotification('Mise a jours du role !','Effectue')
          this.ngxService.stop()
        },error=>{
          this.alerte.errorNotification('Mise a jours du jugement !','Impossible')
          console.log('Error on Updated Document !')
          this.ngxService.stop()
        })
  
      },
        err=>{
        console.log('Role Error on Update !')
        this.alerte.errorNotification('Mise a jours du role !','Impossible')
        this.ngxService.stop()
      })
    }else{
      this.ngxService.stop()
    }
    

    //this.parentModal.hide()
    this.penalService.hiddeModal(this.parentModal);

  }
  getDateCorrespondant(jour: Number){
    const dateActuelle = new Date();
    const jourActuel = dateActuelle.getDate();
    
    // Obtenez le mois actuel
    const moisActuel = dateActuelle.getMonth() + 1; // Les mois commencent à 0, donc nous ajoutons 1
    
    // Déterminez l'année pour la date
    let annee = dateActuelle.getFullYear();
    const dateCorrespondant = `${jour}-0${moisActuel}-${annee}`;
    console.log('mmbmbmb',dateCorrespondant);
    
    return dateCorrespondant

  }

  ngOnInit(): void {

    // this.joursTravail = this.penalService.selectedChambre;

    // this.penalService.SelectedChambreChange.subscribe(chambre => {
    //   console.log('chambre on calendar'+ chambre);
    //  if (chambre != null) {

    //    this.joursTravail = chambre.jour_travail;
    //    this.maxDossier = chambre.max;
    //    this.getDossiersEncours(chambre.name)
    //   //  this.dossier_encours =  this.penalService.SelectedChambreChange
    //    console.log('donneee in zooo0909 = > '+ this.joursTravail);
    //    updateDatePicker(false)
    //  }
    // });
    this.penalService.SelectedChambreChange.subscribe(chambre => {
      console.log({'chambre on calendar': chambre});
     if (chambre != null) {

       this.joursTravail = chambre.jour_travail;
       this.maxDossier = chambre.max;
       this.getDossiersEncours(chambre.name)
      //  this.dossier_encours =  this.penalService.SelectedChambreChange
       console.log('donneee in zooo0909 = > '+ this.joursTravail);
       updateDatePicker(false)
     }
    });

    var self = this;

    var monthFormatter = new Intl.DateTimeFormat("fr-fr", { month: "long" });
    var weekdayFormatter = new Intl.DateTimeFormat("fr-fr", { weekday: "long" });

   

    var dates = [];
    dates[0] = new Date(); // defaults to today
  
    var actual_day =this.datePipe.transform(dates[0], 'dd');
   
    
    dates[1] = addDays(dates[0], 31);

    var currentDate = 0; // index into dates[]
    var previousDate = 1;

    var datesBoxes = $(".date-picker-date");
    var displayBoxes = $(".date-picker-display");

    // sensible default just in case jQuery doesn't kick in
    // makes sure that the experience is still usable, and when $(window).width() returns then this variable is updated to the correct value
    var windowWidth = 300;
    var colourPickerWidth = 300;

    // set up dates
    $(document).ready(function() {
      // will work the same the first time as every other
      updateDatePicker();

      // update dates shown to correct dates
      $(datesBoxes[0]).text(getDateString(dates[0]));
      $(datesBoxes[1]).text(getDateString(dates[1]));

      $(displayBoxes[0]).text(dates[0].getDate() + " " + monthFormatter.format(dates[0]).slice(0,3));
      $(displayBoxes[1]).text(dates[1].getDate() + " " + monthFormatter.format(dates[1]).slice(0,3));
    });

    // add event listeners
    $(document).ready(function() {


      // has to be applied each time, as it's removed when calendar is reset
      applyDateEventListener();

      $(".date-picker-date").click(function(e) {
        self.joursTravail = self.penalService.selectedChambre;
        console.log('choiced jours '+ self.joursTravail.jour_travail);


        // if active, toggle picker off and return
        var currentlyActive = $(this).hasClass("active");
        if (currentlyActive) {
          $(this).removeClass("active");
          hideDatePicker();
          return;
        }

        $(".date-picker-date").removeClass("active");
        $(this).addClass("active");

        // update currentDate
        previousDate = currentDate;
        if ($(this)[0].id == "date-picker-date-first") {
          currentDate = 0;
        } else {
          currentDate = 1;
        }

        // update calendar
        showDatePicker(e);
        updateDatePicker();
      });

      $("#next").click(function() {
        changeMonth("Next");
      });

      $("#previous").click(function() {
        changeMonth("Previous");
      });

      $("#date-picker-exit").click(function() {
        hideDatePicker();
      });

      $(document).click(function(e) {
        var target = $(e.target);
        var clickedOnPicker = (target.closest("#date-picker-modal_").length);
        var clickedOnDate = (target.closest(".date-picker-date").length);
        var isPreviousOrNext = target.hasClass("previous-month") || target.hasClass("next-month");

        if (!(clickedOnPicker || clickedOnDate || isPreviousOrNext)) {
          hideDatePicker();
        }
      });

    });

    // called on initialising (set to today) and then every time the month changes or on moving between dates
    function updateDatePicker(changeMonth = false) {

      var datePicker = $("#date-picker");
      var curDate = dates[currentDate]; // shorthand
      console.log('cuuurrrr date '+ curDate);
      const jour = ("0" + curDate.getDate()).slice(-2);
      const mois = ("0" + (curDate.getMonth() + 1)).slice(-2);
      const annee = curDate.getFullYear();

      const dateFormatee = `${jour}-${mois}-${annee}`;
  
      updatePickerMonth();

      // check if it needs to update
      // updates if changed month directly (changeMonth) or if switched to other .date-picker-date and month is different (differentMonth)
      var differentMonth = checkChangedMonth();
      if (changeMonth === false && differentMonth === false) { return; }



      // clear out all tr instances other than the header row
      // really just removing all rows and appending header row straight back in
      var headerRow = `
        <tr id="date-picker-weekdays">
          <th>S</th>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
        </tr>`;
      // clear all rows
      datePicker.contents().remove();
      datePicker.append(headerRow);

      var todayDate = curDate.getDate();
      console.log('kjh = '+ todayDate);

      var firstOfMonth = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
      var firstWeekday = firstOfMonth.getDay(); // 0-indexed; 0 is Sunday, 6 is Saturday
      var lastMonthToInclude = firstWeekday; // happily, this just works as-is.
      var firstOfNextMonth = addMonths(firstOfMonth, 1);
      var lastOfMonth = addDays(firstOfNextMonth, -1).getDate();

      var openRow = "<tr class='date-picker-calendar-row'>";
      var closeRow = "</tr>";
      var currentRow = openRow;

      // Add in as many of last month as required
      if (lastMonthToInclude > 0) {
        var lastMonthLastDay = addDays(firstOfMonth, -1);
        var lastMonthDays = lastMonthLastDay.getDate();
        var lastMonthStartAdding = lastMonthDays - lastMonthToInclude + 1;

      }

      currentRow = openRow;

      var counter = 7;
      var addedFromCurrentMonth = 7 - firstWeekday + 1;

      // addToCalendar(1, lastOfMonth, counter, true,curDate); // principal

      // at this point, counter = all of this month + whatever was included from last month

      // Réinitialiser le contenu HTML du datePicker
// Réinitialiser le contenu HTML du datePicker
// Réinitialiser le contenu HTML du datePicker
      datePicker.empty();

      // Ajouter la ligne pour les jours de la semaine
      var daysOfWeekRow = "<tr id='date-picker-days-of-week'>";
      daysOfWeekRow += "<th>Dim</th>"; // Dimanche
      daysOfWeekRow += "<th>Lun</th>"; // Lundi
      daysOfWeekRow += "<th>Mar</th>"; // Mardi
      daysOfWeekRow += "<th>Mer</th>"; // Mercredi
      daysOfWeekRow += "<th>Jeu</th>"; // Jeudi
      daysOfWeekRow += "<th>Ven</th>"; // Vendredi
      daysOfWeekRow += "<th>Sam</th>"; // Samedi
      daysOfWeekRow += "</tr>";

      datePicker.append(daysOfWeekRow);

      // Remplir les jours du mois actuel uniquement
      var openRow = "<tr class='date-picker-calendar-row m-auto' style='text-align:center;'>";
      var closeRow = "</tr>";
      var currentRow = openRow;

      var firstDayOfMonth = new Date(curDate.getFullYear(), curDate.getMonth(), 1).getDay(); // Jour de la semaine du premier jour du mois (0 pour Dimanche, 1 pour Lundi, etc.)
      var currentDayOfMonth = 1; // Jour du mois actuel à afficher
      var lastDayOfMonth = lastOfMonth; // Dernier jour du mois actuel

      // get authorized days for chambre




      // Insérer des cellules vides pour les jours précédant le premier jour du mois
      for (var i = 0; i < firstDayOfMonth; i++) {
          currentRow += "<td></td>";
      }
   


      const today = new Date();
      var curDate = dates[currentDate]; 
      const day_odf_tody = ("0" + curDate.getDate()).slice(-2);
      const month_of_select_calandar = ("0" + (curDate.getMonth() + 1)).slice(-2);

      while (currentDayOfMonth <= lastDayOfMonth) {
        var affaire_en_cours = 0;
        var max_affaire = 0;
        let date;
        var dayOfWeek = (firstDayOfMonth + currentDayOfMonth - 1) % 7; // Calculer le jour de la semaine pour le jour actuel

        self.nomJour = self.joursSemaine[dayOfWeek];
        if (self.affaires_chambre != null ) {
          
          if (self.affaires_chambre.jours.some(jour => jour.hasOwnProperty(self.nomJour))) {
            console.log('Le jour de ===>, formattedDate, est et correspond au affaire chambre:', self.nomJour);
            // Recherchez l'objet jour correspondant
            let jourCorrespondant = self.affaires_chambre.jours.find(jour => jour.hasOwnProperty(self.nomJour));
            console.log('ouuuuu',jourCorrespondant);
            

            if (jourCorrespondant) {
              for (const jour in jourCorrespondant) {
                if (jourCorrespondant.hasOwnProperty(jour)) { // Vérifie si la propriété appartient à l'objet lui-même et non à son prototype
                

                  affaire_en_cours = jourCorrespondant[self.nomJour].nbre_enrollements;
                  max_affaire = self.maxDossier;
                  date =  jourCorrespondant[self.nomJour].date;
                  console.log('Le jour de ===>, formattedDate, est et correspond au affaire enrolllement:', affaire_en_cours);
                  console.log('affaire_en_cours :', affaire_en_cours);
                  console.log('self.maxDossier :', self.maxDossier);
  
                  var _day = date.split('-')[0];
                  console.log('hjhjjhh',_day);
                }
              }
              // for (let y in jourCorrespondant) {
                
            
              // }
                
               
                




            }

        }



        }




        
       // Vérifier si self.nomJour se trouve dans le tableau joursTravail
      //  && parseInt(actual_day) <= currentDayOfMonth
      let c = self.getDateCorrespondant(currentDayOfMonth)
      const date1 = new Date(c.split('-').reverse().join('-'));
      const date2 = new Date(dateFormatee.split('-').reverse().join('-'));

      const mois1: number = date1.getMonth() + 1; // Ajouter 1 car getMonth() est à base zéro
      const mois2: number = date2.getMonth() + 1;
      const y1: number = date1.getFullYear();
      const y2: number = date2.getFullYear();

       if ((self.joursTravail!=null && self.joursTravail.includes(self.nomJour) ) ) {
        
        console.log('mois1 ==> =>  ---> ', mois1)
        console.log('month_odf_tody =>  ---> ', month_of_select_calandar)
        console.log('day_odf_tody =>  ---> ', day_odf_tody)
        console.log('currentDayOfMonth =>  ---> ', currentDayOfMonth)



        let verif = (+month_of_select_calandar > mois1 ) ? true : (+month_of_select_calandar < mois1 )? false: (+month_of_select_calandar == mois1 )?(+day_odf_tody < currentDayOfMonth) : false

        console.log('Ope ==> ', verif)
        
        console.log(`${self.nomJour} se trouve dans le tableau joursTravail.`+ 'and i = '+currentDayOfMonth +' and day = ');
        // currentRow +=  +affaire_en_cours > +max_affaire && currentDayOfMonth == _day  ? `<td class="text-danger">${currentDayOfMonth}</td>` : `<td class="text-success">${currentDayOfMonth}</td>`;
        currentRow +=  verif && y2>= y1?  
        `<td class="text-success " style="height:90px;font-size:15px; font-weight: bolder;cursor: pointer">  <div class="">${currentDayOfMonth} </div> </td>`
        :
        `<td class="text-danger" style="height:90px;font-size:15px; font-weight: bolder"><i class="fas fa-solid fa-ban"></i></td>`
       ;

        } else {
            console.log(`${self.nomJour} ne se trouve pas dans le tableau joursTravail.`);

            currentRow += `<td class="not-monday-tuesday" style="height:90px;font-size:15px; font-weight: bolder"  disabled><i class="fas fa-solid fa-ban"></i></td>`; // Ajouter l'attribut disabled

        }
        // if (condition) {
          
        // }

        // Afficher uniquement les lundis (1) et les mardis (2) du mois actuel
        // if (dayOfWeek === 1 || dayOfWeek === 2) {
        //     currentRow += `<td>${currentDayOfMonth}</td>`;
        // } else {
        //   currentRow += `<td class="not-monday-tuesday" disabled><i class="fas fa-solid fa-ban"></i></td>`; // Ajouter l'attribut disabled

        //     // currentRow += `<td class="not-monday-tuesday" disabled>${currentDayOfMonth}</td>`; // Ajouter l'attribut disabled
        // }

        // Passer au jour suivant
        currentDayOfMonth++;

        // Vérifier si la fin de la semaine est atteinte
        if (dayOfWeek === 6 || currentDayOfMonth > lastDayOfMonth) {
            // Ajouter la ligne au datePicker
            datePicker.append(currentRow + closeRow);
            // Réinitialiser la ligne actuelle
            currentRow = openRow;
        }
    }

    $(".not-monday-tuesday").css("color", "red");
    // Code jQuery pour inclure le CSS
    $('.mycalendar td').append('<style>' +
    '.badge {' +
    '  background-color: red;' +
    '  color: white;' +
    '  font-size: 10px;' +
    ' padding-top: 5px;' +

    '  border-radius: 0%;' +
    '}' +
    '.mycalendar td {' +
    '  border: 0.5px solid black;' +
    'height: 110px;'+
    'font-size: 20px;'+
    'margin: auto;'+
    'font-weight: normal;'+
    '  text-align: center;' +
    '}' +
    '</style>');

    $('table').append('<style>' +
    '#date-picker-days-of-week {' +
    '  background: linear-gradient(to right, #373b44, #4286f4);' +
    '  color: white;' +
    '  font-size: 12px;' +
    '  margin: auto;' +
    '  text-align: center;' +

    '  border-radius: 50%;' +
    '}' +
    '  background-color: red;' +
    '</style>');


      counter = lastMonthToInclude + lastOfMonth;
      var nextMonthToInclude = counter % 7 === 0 ? 0 : 7 - (counter % 7);

      // addToCalendar(1, nextMonthToInclude, counter, "next-month",curDate);

      // add event listener again
      applyDateEventListener();

      // update current date box
      updateDateShown();



      // functions scoped to this outer function
      //############################################################
      function checkChangedMonth() {
        // updates if changed month directly (changeMonth) or if switched to other .date-picker-date and month is different (differentMonth)
        var differentMonth = false;
        // checks if it's the same date again
        if (currentDate !== previousDate) {
          // if either month or year are different then month has changed
          if (dates[0].getMonth() !== dates[1].getMonth() || dates[0].getYear() !== dates[1].getYear() ) {
            differentMonth = true;
          }
        }

        return differentMonth;

      }


    }

    function updatePickerMonth() {
      var monthName = monthFormatter.format(dates[currentDate]);
      var year = dates[currentDate].getFullYear();
      var dateText = monthName + " " + year;
      console.log('PPPPP' +dateText);

      $(".month").text(dateText);
    }

    function dateSelected(currentDay) {

      // update the active .date-picker-date with the current date
      var activeDate = $( $(".date-picker-date.active")[0] );

      // get current date and update
      dates[currentDate].setDate(currentDay);
      updateDateShown();

      // for new enrole
      // self.newEnrole()
    }

    // 'direction' can be either "Next" or "Previous"
    function changeMonth(direction) {

      var increment = direction === "Next" ? 1 : -1;

      // change month
      dates[currentDate] = addMonths(dates[currentDate], increment);

      // change month name in picker
      updatePickerMonth();

      // update calendar
      // passes 'true' that month has changed
      updateDatePicker(true);
    }

    function showDatePicker(e) {

      var pxFromTop = $(".date-picker-date").offset().top;
      var datePicker = $("#date-picker-modal_");
      datePicker.css("top", pxFromTop + 40);
      // check if right edge of colourPicker will go off the edge of the screen, and if so then reduce left by that amount
      var rightEdge = e.pageX + colourPickerWidth;
      var overflowWidth = rightEdge - windowWidth;
      if (overflowWidth > 0) {
        // datePicker.css("left", e.pageX - overflowWidth);
      } else {
        // datePicker.css("left", e.pageX);
      }

      $("#date-picker-modal_").removeClass("hidden-2");
    }

    function hideDatePicker() {
      $(".date-picker-date").removeClass("active");
      $("#date-picker-modal").addClass("hidden-2");
    }

    function applyDateEventListener() {

      $(".cell td").click(function() {

        if (!$(this).hasClass('not-monday-tuesday')) {
           // Calendar UI
           $("#date-picker td").removeClass("active"); // Supprime la classe 'active' de tous les éléments <td>
           $(this).addClass("active"); // Ajoute la classe 'active' à l'élément cliqué

           // Supprime tous les styles ajoutés précédemment
           $('#date-picker-style').remove();

           // Ajoute un nouveau style pour l'élément cliqué
           $('head').append('<style id="date-picker-style">' +
               '#date-picker td.active {' +
               '  background: linear-gradient(to right, #BEE9DA, #CBEBD3);' +
               '  color: #ffffff;' +
               '  font-size: 12px;' +
               '  margin: auto;' +
               '  text-align: center;' +


               '}' +
               '</style>');


        // update variables
       let currentDay = $(this).text();

       self.getdayMonth = currentDay;

        // update the current date
        let o = dateSelected(currentDay);
        console.log('currrent dayyy '+ o)


        // change month based on calendar day class
        if ($(this).hasClass("previous-month")) {
          changeMonth("Previous");
        } else if ($(this).hasClass("next-month")) {
          changeMonth("Next");
        } else {
          // clicked in current month; made selection so hide picker again
          hideDatePicker();
        }
        }



      });

    }


    // Utilities
    //################################################################
    // set location for date picker
    $(document).ready(function() {
      updateWidths();
    });

    $(window).resize(function() {
      updateWidths();
    });

    function updateWidths() {
      windowWidth = $(window).width();
    }

    // courtesy of https://stackoverflow.com/questions/563406/add-days-to-javascript-date
    function addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    function addMonths(date, months) {
      var result = new Date(date);
      result.setMonth(result.getMonth() + months);
      return result;
    }

    // courtesy of https://stackoverflow.com/a/15764763/7170445
    function getDateString(date) {
      var year = date.getFullYear();

      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;

      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;

      return day + '/' + month + '/' + year;
    }

    function updateDateShown() {

      var formattedDate = getDateString(dates[currentDate]);
      
      self.newDateEnrole = formattedDate
      console.log('date changed'+ self.newDateEnrole);
      var updateDateBox = $(datesBoxes[currentDate]);

      var updateDisplayBox = $(displayBoxes[currentDate]);
      console.log('dddddddddd'+ dates[currentDate].getDate());
      var dayAndMonth = dates[currentDate].getDate() + " " + monthFormatter.format(dates[currentDate]).slice(0,3);





      var dayOfWeek = dates[currentDate].getDay();
      let affaire_en_cours = 0;
      let max_affaire = 0;
      let date: any;

      self.nomJour = self.joursSemaine[dayOfWeek];
      console.log('Le jour de la semaine pour la date', formattedDate, 'est:', self.nomJour);
      if (self.affaires_chambre != null ) {
        if (self.affaires_chambre.jours.some(jour => jour.hasOwnProperty(self.nomJour))) {
          console.log('Le jour de ===>, formattedDate, est et correspond au affaire chambre:', self.nomJour);
          // Recherchez l'objet jour correspondant
          let jourCorrespondant = self.affaires_chambre.jours.find(jour => jour.hasOwnProperty(self.nomJour));
          if (jourCorrespondant) {
              affaire_en_cours = jourCorrespondant[self.nomJour].nbre_enrollements;
              date = jourCorrespondant[self.nomJour].date
              max_affaire = self.maxDossier
              console.log('Le jour de ===>, formattedDate, est et correspond au affaire enrolllement:', affaire_en_cours);
              console.log('affaire_en_cours :', affaire_en_cours);
              console.log('self.maxDossier :', self.maxDossier);
              console.log('veriff par date => '+ date);
              var day = date.split('-')[0];

              console.log('current day  => '+ self.getdayMonth);





          }

      }



      }
      if ( day ===  self.getdayMonth && +max_affaire > +affaire_en_cours ) {
        updateDateBox.text(formattedDate); // on calendar
         updateDisplayBox.text(dayAndMonth); // on date
      }else if (day !=  self.getdayMonth) {
        updateDateBox.text(formattedDate); // on calendar
        updateDisplayBox.text(dayAndMonth); // on date
      }

      // +max_affaire > +affaire_en_cours ?  console.log('ok permis :', self.maxDossier):  console.log('onon')


      self.onSelectDate(formattedDate)
      // if (self.joursTravail != null && self.joursTravail.includes(self.nomJour)) {
      //   console.log('lklkl = '+self.joursTravail);

      //   updateDateBox.text(formattedDate); // on calendar
      //   updateDisplayBox.text(dayAndMonth); // on date
      // }

      // if ((self.nomJour == "Lundi" || self.nomJour == "Mardi")) {

      // }



    }
  }
}
