import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import { MyCONST } from 'src/app/CONST/my_const';

@Injectable({
  providedIn: 'root'
})
export class PenalCourrierService {
  donnees: any;
  chambres: any;
  data_substuties: any;
  Dossiersenrolles: any;
  data_interneCourriers: any;
  data_principalCourriers: any;
   currentDossier = new BehaviorSubject<any>(null);
  // currentDossier: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  chambresChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SubstutiesChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  CourrierIntChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  DossierEnrolleChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SelectedChambreChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SelectedInfractionChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private formDataSource = new Subject<any>();
  formData$ = this.formDataSource.asObservable();


   selectedDossier ;
   selectedJugement ;
   selectedChambre ;
   selectedInf ;
   selectedDate ;

   profiles_user: any;


  // selectedDossier = this.selectedDossier;


  



  _dossiers: any = []
 
// dossiers enrolles

 _Dossiersenrolles: any = [
  {
    "id": 1,
    "numero": "AR-01-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 2,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 3,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 4,
    "numero": "AR-09-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 4,
    "numero": "AR-03-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 6,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 7,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 8,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 9,
    "numero": "AR-09-2024",
    "date": "16/03/2024",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 10,
    "numero": "AR-03-2024",
    "date": "28/03/2024",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
]


// courriers principales



// chambres

_chambres: any = [
  {
   "id": 0,
   "name": "chambre 1",
   "libelle": "cassation",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Vendredi', 'Mardi'],
   "max":'10'

 },
 {
  "id": 1,

  "name": "chambre 2",
   "libelle": "crime",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Mercredi', 'Jeudi'],
   "max":'10'

 },
 {
  "id": 2,
  "name": "chambre 3",
  "libelle": "Delits",
  "membres": ['Ibrahim Anne','Moustapha Diop'],
  "jour_travail": ['Mardi', 'Vendredi'],
  "max":'10'
 },
 {
  "id":3,
  "name": "chambre 4",
   "libelle": "judiciaires",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Lundi', 'Jeudi'],
   "max":'10'
 },
 {
  "id":4,
  "name": "chambre 5",
   "libelle": "yyyy",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Mercredi', 'Vendredi'],
   "max":'10'
 },

];

// affaires par chambres

_affaires_chambre: any = [
  {
   "id": 1,
   "chambre": "1 ère Chambre Correctionnelle",
   "jours": [
    {
      "Lundi":{
        "date":'03-06-2024',
        "nbre_enrollements":'20',
      },

    }

   ]


 },

 {
  "id": 2,
  "chambre": "3 ème Chambre Correctionnelle",
  "jours": [
   {
     "Jeudi":{
       "date":'13-06-2024',
       "nbre_enrollements":'20',
     },
     "Vendredi":{
      "date":'15-05-2024',
      "nbre_enrollements":'4',
    },

   }

  ]


},


]

// list infractions





//


  constructor(private http: HttpClient) { }


  private jsonUrl = '../../../assets/data/head_banner.json'; // Chemin vers ton fichier JSON


  // Méthode pour récupérer les données du fichier JSON
  getBannerData(): Observable<any> {
    return this.http.get<any>(this.jsonUrl);
  }


  updateFormData(data: any) {
    console.log('in updatedata', data);
    
    this.formDataSource.next(data);
  }


  getDossiers(){
    this.donnees = this._dossiers;
    this.currentDossier.next(this.donnees);
    return this.donnees;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  // Observable pour que les composants s'abonnent à la valeur
  get selectedDossier$() {
    console.log('Current dossier value: ', this.currentDossier.getValue());
    
    return this.currentDossier.asObservable();
  }

  getChambres(){
    this.chambres = this._chambres;
    this.chambresChange.next(this.chambres);
    return this.chambres;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }


  getDossiersEnrolles(){
    this.Dossiersenrolles = this._Dossiersenrolles;
    this.DossierEnrolleChange.next(this.Dossiersenrolles);
    return this.Dossiersenrolles;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  // trouver un objet enrollement a partir du numero de dossier
  findEnrollement(numero: string): any {
    return this._Dossiersenrolles.find(enrollement => enrollement.numero === numero);
  }


  hiddeModal(modalRef: BsModalRef){
    modalRef.hide();
    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  // findDossier(id: string): any {
  //   return this.courriers.find(courrier => courrier.id === id);
  // }

  // enrollement

  async setSelectedDossier(dossier: any) {
    this.currentDossier.next(dossier);
    this.selectedDossier = dossier;
    // this.currentDossier.next(dossier);
    this.setSelectedInfraction(dossier.table_infractions);
    
    console.log('myinfracsfolderdfd',dossier);
    

  }

  setSelectedJugement(dossier: any) {
    this.selectedJugement = dossier;
    console.log('selected jugment '+ this.selectedJugement);


  }

  setSelectedChambre(dossier: any) {
    this.selectedChambre = "";
      this.selectedChambre = dossier;
    console.log({'is selected chambre ': this.selectedChambre});

    this.SelectedChambreChange.next(this.selectedChambre);



  }

  setSelectedInfraction(infraction: any) {
    console.log('insetinfselecte', infraction);

    this.selectedInf = infraction;
    console.log('selectedinfr', infraction);
    this.SelectedInfractionChange.next(this.selectedInf);
    console.log({'infraction on service': this.selectedInf});

  }

  setSelectedDate(date: any) {
    this.selectedDate = date;
    console.log({'hhi': this.selectedDate[0]});
    return this.selectedDate

  }


  searchDossierByChambre(chambreName: string): string[] {
    const dossiers = this._Dossiersenrolles.filter(dossier => dossier.chambre.name === chambreName);
    const numeros = dossiers.map(dossier => dossier.numero);
    return numeros;
  }



}
