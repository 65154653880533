import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPrintDirective } from 'ngx-print';
import { NgxPrintElementService, Config } from 'ngx-print-element';
import { MyCONST } from '../../CONST/myConst';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';

@Component({
  selector: 'app-visualize-requisitoire',
  templateUrl: './visualize-requisitoire.component.html',
  styleUrls: ['./visualize-requisitoire.component.scss'],
})
export class VisualizeRequisitoireComponent  implements OnInit{
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  dataAllInformation:any;
  dataPiecesJointes:any  =[];
  courriers: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  isButtonDisabled: boolean = true;
  verification : String  = MyCONST.DOMAIN
  nom_pere =null
  prenom_pere =null
  nom_mere =null
  prenom_mere =null
  // type: string = "requisitoire";
  tribunal = MyCONST.TRIBUNAL
  tribunal_detail = MyCONST.DETAIL_TRIBUNAL

  profil: string ;
  @Input() parentModal: BsModalRef;

  @ViewChild('elementToPrint') printElement!:any;


  @ViewChild(NgxPrintDirective) ngxPrint!: NgxPrintDirective;


  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
              private penalCourrierService: PenalCourrierService,
              private userCourrierService: UsercourrierService,
              public print: NgxPrintElementService,
              private datePipe: DatePipe,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
                console.log({'dataaa': this.data});
    try{
      let tribunal_information:any = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
      if(tribunal_information.code){
        //console.log('UPDATE')
        this.tribunal= tribunal_information.tribunal
        this.tribunal_detail= tribunal_information.detail_tribunal
      }
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
    }

  }
  ngOnInit(): void {
    // this.type = this.data.type;

  }

  doAffiliation(){
    for(let aff of this.data.affiliations){
      if(aff.lien =='Pere'){
        this.nom_pere = aff.nom
        this.prenom_pere = aff.prenom
      }
      if(aff.lien =='Mere'){
        this.nom_mere = aff.nom
        this.prenom_mere = aff.prenom
      }
    }
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }




  public config: Config = {
    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    // htmlType: 'text',
    stylesheets: [{ rel: 'stylesheet', href: '../../../assets/style/css/bootstrap.min.css' }],

    styles: [
      `
      @media print {
        @page {
          size: A4; /* Définit la taille de la page comme A4 */
          margin: 20px ; /* Supprime les marges par défaut */
        }

        body {
          background-color: red;
          width: 210mm; /* Largeur de la page A4 en millimètres */
          height: 297mm; /* Hauteur de la page A4 en millimètres */
          margin: 20px; /* Supprime les marges par défaut */
        }
        header{
          display: flex;
        }
      }

      `

    ]
  }



  activePane = 0;



  onPrint2(el: any) {
    try {
      this.print.print(el, this.config).subscribe(console.log);

    } catch (error) {
      console.log('dddddd==> '+ error);


    }

  }
}
