
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
  </head>

  <body>
    <!-- <div class="sticky-container">
      <app-sticky-note></app-sticky-note>
    </div> -->
    <div class="page view-details-courrier">
      <div class="d-flex justify-content-center">
        <h5 id="heading" >Note manuelle d'audience</h5>
        <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="_closeModal()" >
          <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
        </button>
      </div>
        
       <!-- tabs -->
       <div class="pcss3t-canvas pcss3t-canvas-effect-scale pcss3t-canvas-theme-1 ">


         <input type="radio" name="pcss3t-canvas" checked   id="tab1"class="tab-content-first">

         <label for="tab1"></label>

       

        <ul  style="height: 150vh;">
          <li class="tab-content tab-content-first typography" style="overflow: hidden;">
            <br>


            <fieldset >
              <div class="d-flex flex-wrap">
                <div class="button col-xs-6 col-sm-4 col-md-2 col-lg-2 col-xl-2  b2 mb-3 " style="font-size: 13px;"  (click)="savePermanent()" > Sauvegarder</div>&nbsp;&nbsp;
                <div  class="col-xs-10 col-sm-6 col-md-3 col-lg-3 col-xl-3" >
                  <input type="text" name="" id="" placeholder="libelle de la note *" [(ngModel)]="libelle_note" required>
                </div>
              </div>

                 
              <div class="form-card">
                <div class="d-flex flex-column">
                  <!-- <div>
                    <button class="btn btn-outline-secondary" (click)="saveToStorage()">Save to Storage</button>
                    <button class="btn btn-outline-primary" (click)="loadFromStorage()">Load from Storage</button>
                  </div> -->
                  
                  <div>
                    <div class="area-canvas">
                      <!-- Canvas Whiteboard component where drawings happen -->
                      <canvas-whiteboard 
                        #canvasWhiteboard 
                        [options]="canvasOptions" 
                        [drawButtonText]="'actif'"  
                        [clearButtonEnabled] = "true"
                        [clearButtonText]="'supprimer'"
                        [undoButtonText]="'arrière'" 
                        [undoButtonEnabled]="true" 
                        [redoButtonText]="'avancer'" 
                        [redoButtonEnabled]="true"
                        [strokeColorPickerText]="'couleur'" 
                        [strokeColorPickerEnabled]="true" 
                        [saveDataButtonEnabled]="true" 
                        [saveDataButtonText]="'Télécharger'" 
                        [lineWidth]="1"
                        [strokeColor]="'rgb(0,0,0)'" 
                        [shouldDownloadDrawing]="true">
                      </canvas-whiteboard>
                    </div>
                  </div>
                </div>
                
              

                
              </div>
            </fieldset>
          </li>

           
        </ul>

       </div>

       
     </div>
     <ngx-ui-loader></ngx-ui-loader>
  </body>
</html>



