<div class="">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
            <div class="d-flex justify-content-center">
              <h5 id="heading" style="font-size: 13px;">{{categorie}}: {{type}}</h5>
              <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
                <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
              </button>
            </div>

              <!-- <p>Fill all form field to go to next step</p> -->
               <ng-container *ngIf="is_stepped; else notstepped">
                  <form id="msform" #newdemande="ngForm" (ngSubmit)="AddDemande(newdemande)">
                    <!-- progressbar -->
                    <ul id="progressbar">
                        <li  *ngFor="let step of steps_demande; let i = index" [ngStyle]="{'width': widthPercentage + '%'}"  class="{{i==0?'active':''}}" id="step{{i+1}}"><strong>{{step.name_step}}</strong></li>
                    </ul>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <br> <!-- fieldsets -->
                    <div class="d-flex flex-wrap justify-content-center ">
                      <div *ngIf="_elementsArray.length>0" class="col-sm-10 col-md-2 col-lg-2 " style="height: 100px;overflow: auto; border: 1px dashed black;">
                        <h6>Documents</h6><br>
                        <ul class="list_files" *ngFor="let item of _elementsArray" style="list-style: none;">
                          <li (click)="openModalVisualPiece(item.value)">{{ formatLabel(item.key) }}</li>
                         
                        </ul>
                      </div> &nbsp;&nbsp;
                      <fieldset *ngFor="let step of steps_demande; let u = index" class="step{{u + 1}} col-sm-10 col-md-9 col-lg-9">
                      <div class="form-card">
                        <div class="d-flex justify-content-between flex-wrap">
                          <div *ngFor="let field of fieldsByStep[u]" class="col-md-4" style="padding: 0 5px 0 0 ;">
                            <label class="fieldlabels">{{ formatLabel(field.key) }}</label>
                            <ng-container *ngIf="field.value.key == 'input' && field.value.type != 'file'">
                              <input
                                class="form-control"
                                type="{{ field.value.type }}"
                                [readOnly]="template_demande?.is_external == 1"
                                [name]="field.key"
                                [required]="true"
                                [ngModel]="data ? data.content[field.key] : template_demande.content[field.value.value]"
                              />
                            </ng-container>
                            <ng-container *ngIf="field.value.key == 'input' && field.value.type == 'file'">
                              <div class="file-input">
                                <label class="custom-file-upload" for="file{{u}}_{{field.key}}">
                                  <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                                  <div class="text text-nowrap">
                                    <span class="file-label">{{ fileNames[field.key] || 'Importer un fichier' }}</span>
                                  </div>
                                  <input type="file" id="file{{u}}_{{field.key}}"
                                        class="form-control file-input"
                                        [name]="field.key"
                                        [ngModel]="data ? data.content[field.key] : template_demande.content[field.value.value]"
                                        (change)="onFileSelected($event, field.key)" />

                                </label>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="field.value.key == 'select'">
                              <select [name]="field.key" [ngModel]="data ? data.content[field.key] : template_demande.content[field.value.value]" [required]="true">
                                <option value="" selected [disabled]="true">--choisir option--</option>
                                <option *ngFor="let item of field.value.value" [value]="item.value">{{ item.value }}</option>
                              </select>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <input type="button" *ngIf="u < steps_demande.length-1" name="next" class="next action-button" value="Suivant"  />
                      <input  *ngIf="u == steps_demande.length-1" type="submit" name="next" class="next action-button"  value="Enregistrer" />
                        <input *ngIf="u >0" type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />

                     </fieldset>

                    
                    </div>
                    


                </form>
               </ng-container>
               <ng-template #notstepped>
                <form id="msform" #newdemande="ngForm" (ngSubmit)="AddDemande(newdemande)">
                  <!-- progressbar -->
                   
                  <ul id="progressbar">
                      <li class="active" id="step1" [ngStyle]="{'width': 100 + '%'}"><strong>Informations</strong></li>
                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <br> <!-- fieldsets -->
                  <div class="d-flex flex-wrap justify-content-center ">
                    <div *ngIf="_elementsArray.length>0" class="col-sm-10 col-md-2 col-lg-2 " style="height: 100px;overflow: auto; border: 1px dashed black;">
                      <h6>Documents</h6><br>
                      <ul class="list_files" *ngFor="let item of _elementsArray" style="list-style: none;">
                        <li (click)="openModalVisualPiece(item.value)">{{ formatLabel(item.key) }}</li>
                       
                      </ul>
                    </div> &nbsp;&nbsp;
                    <fieldset class="step1 col-sm-10 col-md-9 col-lg-9 ">

                      <!-- <div class="form-card">
                          <div class="row justify-content-between">
                            <div style="width: 49%;" *ngFor="let key of getKeys(template_demande?.content)">
                              <label class="fieldlabels">{{ formatLabel(key) }}</label>
                              <input
                                class="form-control"
                                type="text"
                                [readOnly]="template_demande?.is_external == 1? true :false"
                                [name]="key"
                                [required]="true"
                                [(ngModel)]="template_demande.content[key]"
                              />
                            </div>
                          </div>
                        </div> -->
                        <div class="form-card">

                            <div class="d-flex justify-content-between flex-wrap">
                              <div *ngFor="let _key of elementsArray; let i = index" class="col-md-4 col-sm-12 col-lg-4" style="padding: 0 5px 0 0;">
                                <label class="fieldlabels">{{ formatLabel(_key.key) }}</label>
                                <ng-container *ngIf="_key.value.key == 'input' && _key.value.type != 'file'">
                                  <input
                                    class="form-control"
                                    type="{{ _key.value.type }}"
                                    [readOnly]="template_demande?.is_external == 1"
                                    [name]="_key.key"
                                    [required]="true"
                                    
                                    [ngModel]="data ? data.content[_key.key] : template_demande.content[_key.value.value]" 
                                  />
                                </ng-container>
                                <ng-container *ngIf="_key.value.key == 'input' && _key.value.type == 'file'">
                                  <div class="file-input">
                                    <label class="custom-file-upload" for="file{{i}}">
                                      <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                                      <div class="text text-nowrap">
                                        <span class="file-label">{{ fileNames[_key.key] || 'Importer un fichier' }}</span>
                                      </div>
                                      <input type="{{ _key.value.type }}" id="file{{i}}" class="form-control file-input"
                                             [name]="_key.key"
                                              [ngModel]="data ? data.content[_key.key] : template_demande.content[_key.value.value]"
                                             (change)="onFileSelected($event, _key.key)" />
                                    </label>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="_key.value.key == 'select'">
                                  <select [name]="_key.key" [ngModel]="data ? data.content[_key.key] : template_demande.content[_key.value.value]" [required]="true">
                                    <option value="" selected [disabled]="true">--choisir option--</option>
                                    <option *ngFor="let item of _key.value.value" [value]="item.value">{{ item.value }}</option>
                                  </select>
                                </ng-container>
                              </div>
                            </div>


                        </div>
                      <input type="submit" name="next" class="action-button"
                             [class.disabled-button]="newdemande.invalid"
                             value="Enregistrer" [disabled]="newdemande.invalid" />
                    </fieldset>

                    
                  </div>
                  
                </form>
               </ng-template>

          </div>


      </div>
  </div>
</div>
