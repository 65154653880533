import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { ViewDetailsPlumitifComponent } from '../view-details-plumitif/view-details-plumitif.component';
import { ViewDetailsRepertoireComponent } from '../view-details-repertoire/view-details-repertoire.component';
import $ from 'jquery';
import {EnrollementService} from "../../services/frapper/enrollement.service";
import {DossierService} from "../../services/frapper/dossier.service";
import {CustomService} from "../../services/frapper/custom.service";
import { ViewDetailsDossierComponent } from '../view-details-dossier/view-details-dossier.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-view-details-chambre',
  templateUrl: './view-details-chambre.component.html',
  styleUrls: ['./view-details-chambre.component.scss']
})
export class ViewDetailsChambreComponent {

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  dossiersChambre: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  isButtonDisabled: boolean = true;
  @Input() parentModal: BsModalRef;
  pagePlumitifs: number;
  pagerepertoires: number;
  pageDossier: number = 1;

  list_plumitifs: any[] = [
    {
      'numero_de_jugement': 'AR-06-2024',
      'decision_judiciaire': 'report',
      'date_de_jugement': '2024-04-13',
      'node_daudiences': "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
      'nom_complet_plaignant': "Ahmadou Diagne X Moustapha Seye",
      'prenom': "Moustapha ",
      'nom': "Moustapha Seye"
    }
  ]
  list_repertoires: any[] = [
    {
      'jugement_code': 'AR-06-2024',
      'decision_judiciaire': 'report',
      'date_de_decision': '2024-04-13',
      'factum': "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
      'qualites': "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
      'node_daudiences': "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
      'nom_complet_plaignant': "Ahmadou Diagne X Moustapha Seye",
      'prenom': "Moustapha ",
      'nom': "Moustapha Seye"
    }
  ]

  membres=[]

  CAN_ADD_MEMBRE=false
  profil:any
  getfolder: any

  constructor(private _formBuilder: FormBuilder,
              private datePipe: DatePipe,private options: ModalOptions,
              private penalCourrierService: PenalCourrierService,
              private dossierService:DossierService,
              private customService: CustomService,
              private role:EnrollementService,
              private ngxService: NgxUiLoaderService,
              private bsModalRef: BsModalRef,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.getfolder = this.options.initialState.folder;
    // this.type = this.options.initialState.type;
    console.log(this.data)
    this.profil =localStorage.getItem('profil')
  }





  activePane = 0;

  onTabChange($event: number) {
    this.activePane = $event;
    console.log('onTabChange', $event);
  }

  closeModal(){
    this.bsModalRef.hide()
  }

  async getAllDataOfTheChambre( chambreName: string) {
   //this.dossiersChambre = this.penalCourrierService.searchDossierByChambre(chambreName);
    let result = (await this.customService.getAllInformationsOfChambre(chambreName)).message
    this.dossiersChambre = result.all_dossiers
    this.list_plumitifs = result.audiences
    this.list_repertoires= (result.audiences).filter(objet => (objet.status == 'Decision rendue' || objet.status == 'En appel'||objet.status == 'Definitive'||objet.status == 'Definitive'));
    console.log('the Data', this.dossiersChambre);
  }



  openModalDetailsPlumitif(plumitif: any) {
    const config: ModalOptions = {
      initialState: {
        data: plumitif
      }
     }
     const modalRef = this.modalService.show(ViewDetailsPlumitifComponent, Object.assign({},config,{class:'modal-lg '}));

  }

  openModalDetails(data: any, type:string) {
    // let courrier:any = this.courriers[id];
    // console.log('details === '+ courrier);

    const config: ModalOptions = {
      initialState: {
        data: data,
        type: type
      }
     }
    let modalRef = this.modalService.show(ViewDetailsDossierComponent, Object.assign({},config,{class:'modal-xl '}));

  }

  openModalDetailsRepertoire(plumitif: any) {
    const config: ModalOptions = {
      initialState: {
        data: plumitif
      }
     }
     const modalRef = this.modalService.show(ViewDetailsRepertoireComponent, Object.assign({},config,{class:'modal-lg '}));

  }
  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  async ngOnInit() {
    this.ngxService.start()
    console.log('the Datakkk', this.getfolder);
    console.log({'inf subs == ':this.data});
    if(this.profil=='GREFFIER EN CHEF'){
      this.CAN_ADD_MEMBRE=true
    }
    if(this.profil=='PRESIDENT DU TRIBUNAL'){
      this.CAN_ADD_MEMBRE=true
    }
    await this.getAllDataOfTheChambre(this.data.name)

    await this.role.getOneChambre(this.data.name).then(res=>{
      this.data =res.data
      this.membres =this.data.membres
    })


    $('.dropdown-el').click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).toggleClass('expanded');
      $('#'+$(e.target).attr('for')).prop('checked',true);
    });
    $(document).click(function() {
      $('.dropdown-el').removeClass('expanded');
    });

    $('#addRowBtn').on('click', function() {
      // Créez une nouvelle ligne avec des colonnes Bootstrap
      const newRow = '<tr>' +
                      '<td></td>' +
                       '<td><input type="text" class="form-control" name="prenom"  /></td>' +
                       '<td><input type="text" class="form-control" name="nom"  /></td>' +
                       `<td>
                        <select name="" id="" class="form-control">
                        <option value="">Chef chambre</option>
                        <option value="">Assistant 1</option>
                        <option value="">Assistant 2</option>
                      </select>
                       </td>` +

                     '</tr>'

      // Ajoutez la nouvelle ligne au tableau
      $('#myTable tbody').append(newRow);
    });

    this.ngxService.stop()
  }
}
