<!DOCTYPE html>
<html lang="fr">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>
  <nb-card style="min-height: 80vh;">

    <nb-card-body>
      <i class="fas fa-times text-dark float-right" (click)="closeModal()" style="font-size: 1.5rem;"></i>

      <nb-tabset>
        <!-- Tab Autorisations -->
        <nb-tab tabTitle="Autorisations">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- Utilisateurs ayant accès -->
            <div class="col-md-5 col-lg-5 col-xl-6 col-sm-10 table-responsive">
              <div class="d-flex justify-content-between bg-secondary text-light mb-2  w-100">
                <label class="fieldlabels text-center align-items-center m-auto" style="font-size: 15px;">Utilisateur ayant accés</label>
              </div>
              <table class="table table-striped" >
                <thead>
                  <tr>
                    <th scope="col">Nom complet</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="usersAllowedAcce.length > 0;else emptyarray">
                    <tr *ngFor="let item of usersAllowedAcce | paginate : {itemsPerPage: 10,currentPage: pageUAllowed,id: 'UAllowed'}">
                      <td>{{item.fullname}}</td>
                      <td>{{item.email}}</td>
                      <td>
                        <i class="fas fa-times text-danger" style="font-size: 1rem;"  (click)="revokeAcce(item.email)"></i>
                      </td>
                    </tr>
                    <pagination-controls class="text-nowrap"
                    (pageChange)="pageUAllowed = $event"
                    previousLabel=""
                    nextLabel=""
                    responsive="true"
                    [id]="'UAllowed'"
                  ></pagination-controls>
                  </ng-container>
                  <ng-template #emptyarray>
                    <p>Aucune donnée</p>
                  </ng-template>
                  
                </tbody>
              </table>
            </div>

            <!-- Demandes en attente -->
            <div class="col-md-5 col-lg-5 col-xl-6 col-sm-10 table-responsive">
              <div class="d-flex justify-content-between bg-secondary text-light mb-2  w-100">
                <label class="fieldlabels text-center align-items-center m-auto" style="font-size: 15px;">Demandes en attentes</label>
              </div>
              <table class="table table-striped justify-content-between">
                <thead>
                  <tr>
                    <th scope="col">Demandeur</th>
                    <th scope="col">Profil</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="usersAllowedAcce.length > 0;else emptyarray">
                    <tr *ngFor="let item of newRequestAcce | paginate : {itemsPerPage: 10,currentPage: pageNewrequest,id: 'newrequest'}">
                      <td>{{item.request_sender_name}}</td>
                      <td>{{item.request_profil}}</td>
                      <td style="width: 20%;" class="d-flex justify-content-between">
                        <a class="text-secondary font-weight-bold text-xs" (click)="openModalDetailNotif(item)" data-toggle="tooltip" data-original-title="Voir la demande">
                          <i class="fas fa-solid fa-eye text-primary" style="font-size: 1rem;"></i>
                        </a>&nbsp;&nbsp;
                        <i class="fas fa-solid fa-check text-success" (click)="allowAcce(item.request_sender_id,item.name)" style="font-size: 1rem;"></i>&nbsp;&nbsp;
                        <i class="fas fa-times text-danger" (click)="rejectDemandesAcce(item.request_sender_id,item.name)" style="font-size: 1rem;"></i>
                      </td>
                    </tr>
                    <pagination-controls class="text-nowrap"
                    (pageChange)="pageNewrequest = $event"
                    previousLabel=""
                    nextLabel=""
                    responsive="true"
                    [id]="'newrequest'"
                  ></pagination-controls>
                  </ng-container>
                  
                  <ng-template #emptyarray>
                    <p>Aucune donnée</p>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </nb-tab>

        <!-- Tab Audit -->
        <nb-tab tabTitle="Audit">
          <div class="col-12 m-auto align-items-center">
            <div class="d-flex justify-content-between bg-secondary text-light mb-2  w-100">
              <label class="fieldlabels text-center align-items-center m-auto" style="font-size: 15px;">Actions émis sur le dossier</label>
            </div>
            <section style="min-height: 500px;">
              <!-- Formulaire de recherche avec des checkboxes -->
              <form (ngSubmit)="onSearch($event)">
                <div class="filters-container shadow p-4">
                  <!-- Dropdown Users -->
                  <div class="dropdown">
                    <button class="dropdown-button d-flex justify-content-between  "><span>utilisateurs</span><i class="fas fa-solid fa-angle-down "></i></button>
                    <div class="dropdown-content">
                      <label *ngFor="let user of users ">
                        <input
                          type="checkbox"
                          [(ngModel)]="selectedUsers[user]"
                          [value]="user"
                          name="user">
                        {{ user }}
                      </label>
                    </div>
                  </div>

                  <!-- Dropdown Actions -->
                  <div class="dropdown">
                    <button class="dropdown-button d-flex justify-content-between  "><span>actions</span><i class="fas fa-solid fa-angle-down "></i></button>
                    <div class="dropdown-content">
                      <label *ngFor="let action of actions">
                        <input
                          type="checkbox"
                          [(ngModel)]="selectedActions[action]"
                          [value]="action"
                          name="action">
                        {{ action }}
                      </label>
                    </div>
                  </div>

                  <!-- Dropdown Docs -->
                  <div class="dropdown">
                    <button class="dropdown-button d-flex justify-content-between  "><span>documents</span><i class="fas fa-solid fa-angle-down "></i></button>
                    <div class="dropdown-content">
                      <label *ngFor="let docType of documentTypes">
                        <input
                          type="checkbox"
                          [(ngModel)]="selectedDocumentTypes[docType]"
                          [value]="docType"
                          name="documentType">
                        {{ docType }}
                      </label>
                    </div>
                  </div>

                  <!-- Search Input -->
                  <input type="text" style="margin-top: -5px;" [(ngModel)]="searchTerm" placeholder="Recherche..." class="search-input">

                  <!-- Search Button -->
                  <button type="submit" class="search-btn">Filtrer</button>
                </div>
              </form><br>


              <!-- Résultats de la recherche -->
              <div class="results">
                <div *ngFor="let item of activity_logs | paginate : {itemsPerPage: 15,currentPage: pageLogs,id: 'logs'}" class="m-2">
                  <i class="fas fa-caret-right text-dark"></i>
                  <span>{{ item.content }}</span>
                </div>
                <pagination-controls class="text-nowrap"
                    (pageChange)="pageLogs = $event"
                    previousLabel=""
                    nextLabel=""
                    responsive="true"
                    [id]="'logs'"
                  ></pagination-controls>
              </div>
            </section>
          </div>
        </nb-tab>

      </nb-tabset>

    </nb-card-body>
  </nb-card>
</body>
</html>
