import { Component, OnInit } from '@angular/core';
import {FormBuilder, NgForm} from "@angular/forms";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {DossierService} from "../../services/frapper/dossier.service";
import {AlertesService} from "../../services/public/alertes.service";
import {PenalCourrierService} from "../../services/penal.courrier/penal.courrier.service";
import {SuccessDialogComponent} from "../../widgets/successDialog/success-dialog.component";
import {CustomService} from "../../services/frapper/custom.service";
import {AssignedService} from "../../services/frapper/assigned.service";
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {ChambresService} from "../../services/frapper/chambres.service";

@Component({
  selector: 'app-add-requisitoire',
  templateUrl: './add-requisitoire.component.html',
  styleUrls: ['./add-requisitoire.component.scss'],
})
export class AddRequisitoireComponent implements OnInit {

  dossier:any;
  type:any='';
  ALL_DESTINATAIRE:any=[]
  PROFIL_TO_SELECT = 'JUGE D\'INSTRUCTION'
  IS_CABINET_INSTRUCTION = 1
  IS_AVIS:any = 0
  IS_SUPLETIF:any = 0
  USERS_TO_ASSIGN:any = null

  TYPE_DE_TAG =[];
  content_default:any;
  REQUISITOIRES =[
    {
      name:'REQUISITOIRE INTRODUCTIF',
      is_default_template:1,
      is_final_actions:1,
      template:
        'Nous, procureur de la République près le $_tribunal_$\n' +
        'Vu les articles 71 du Code de procédure pénale, et vues les pièces jointes Desquelles il résulte contre $_nom_complet_du_plaignant_$ Des présomptions de $_nature_$. Fait prévu et puni par l....... article.................. \n' +
        'Requérons qu’il plaise à Monsieur le Juge d’instruction informer par toutes voies de droit sur l… fait ci-dessus énoncé et sur tous faits criminels, délictueux ou contraventionnels que l’information révèlerait, et décerner mandat….'
    },
    {
      name:'REQUISITOIRE AU FIN DE NON LIEU',
      is_default_template:1,
      is_final_actions:1,
      template:
        'Le Procureur de la République près le $_tribunal_$\n' +
        'Vu les pièces de l’information suivie contre $_nom_complet_du_poursuivis_$\n' +
        'Inculpé de $_nature_$\n' +
        '\t\n' +
        'Attendu que l’information a établi les points suivants :\n' +
        'Attendu, en conséquence, qu’il ne résulte pas de l’information des charges suffisantes contre le susnommé $_nom_complet_du_poursuivis_$ d’avoir commis le délit ci-dessus visé ;\n' +
        '\n' +
        'Vu les articles 71 du Code de Procédure pénale,\n' +
        '\n' +
        'Requiert qu’il plaise à M. le juge d’instruction déclarer qu’il n’y a pas lieu à suivre en l’état et ordonner le dépôt de la procédure au greffe ; pour y être reprise en cas de survenance de charges nouvelles.\n' +
        '\n' +
        'Ordonner la mise en liberté de $_nom_complet_du_poursuivis_$ détenu pour autre cause\n' +
        'Ordonner la restitution au profit de  …. des objets saisis.\n' +
        'Statuer sur la condamnation de la partie civile aux dépens.'
    },
    {
      name:'REQUISITOIRE DÉFINITIF DE RENVOI DEVANT LE TRIBUNAL CORRECTIONNEL',
      is_default_template:1,
      is_final_actions:1,
      template:
        'Le Procureur de la République près le $_tribunal_$\n' +
        'Vu les pièces de l’information suivie contre $_nom_complet_du_poursuivis_$\n' +
        'Inculpé de $_nature_$\n' +
        'Attendu que l’information a établi les faits suivants :\n' +
        'Attendu, en conséquence, qu’il résulte de l’information charges suffisantes contre le susnommé $_nom_complet_du_poursuivis_$ d’avoir .......\n' +
        'Faits prévus et punis par .......\n' +
        'Vu les articles 175, 176 et 179 du Code de Procédure pénale,\n' +
        '\n' +
        'Requiert qu’il plaise à M. le juge d’instruction renvoyer l’affaire devant le tribunal correctionnel pour être jugée conformément à la loi.'
    },
    {
      name:'Autre REQUISITOIRE',
      is_default_template:0,
      is_final_actions:0,
      template:'REQUISITOIRE\n'
    }]

  ORDONNANCES=[
     {
      name:'ORDONNANCE AU FIN DE NON LIEU',
       is_default_template:1,
       is_final_actions:1,
       template:
        'Nous $_nom_jue_$, juge d’instruction au $_tribunal_$.\n' +
        'Vu l’information suivie contre $_nom_complet_du_poursuivis_$.\n' +
        'Inculpé de $_nature_$.\n' +
        'Faits prévus et punis par…………………\n' +
        'Vu le réquisitoire de M. le Procureur de la République\n' +
        'en date du $_date_jugement_$.\n' +
        'tendant à déclarer qu’il n’y a pas (1) lieu de suivre ;\n' +
        'Vu les articles $_articles_$ du Code de procédure pénale\n' +
        '\n' +
        'Attendu que……..\n' +
        '\n' +
        ' Et attendu que, dans ces conditions, il n’existe pas de charges suffisantes contre l\'inculpé $_nom_complet_du_poursuivis_$ d’avoir commis l’infraction visée ci-dessus ;\n' +
        '\n' +
        'Déclarons qu’il n’y a pas lieu à suivre en l’état et ordonnons le dépôt du dossier au greffe, pour y être repris en cas de survenance de charges nouvelles\n' +
        '(1) Ordonnons la mise en liberté d…. inculpé, s…  n’est détenu… pour autre cause\n' +
        ' (1) En ce qui concerne la demande de restitution des objets saisis, présentée par……………….\n' +
        '\n' +
        'Ordonnons……………………\n' +
        '\n' +
        ' (1) Condamnons la partie civile aux frais liquidés à la somme de $_montant_amende_$ .\n' +
        '(1) Déchargeons la partie civile de bonne foi des frais $_frais_$.\n'
    },
     {
      name:'ORDONNANCE AU FIN DE RENVOI DEVANT LE TRIBUNAL CORRECTIONNEL',
       is_default_template:1,
       is_final_actions:1,
      template:
        'Nous $_nom_jue_$, juge d’instruction au $_tribunal_$\n' +
        'Le Procureur de la République \n' +
        'Vu l’information suivie contre $_nom_complet_du_poursuivis_$\n' +
        'Inculpé de $_nature_$\n' +
        'Attendu que l’information a établi les points suivants :\n' +
        'Vu le réquisitoire de M. le Procureur de la République, en date du $_date_requisitoire_procureur_$ \n' +
        '(1) Tendant au renvoi devant le Tribunal Correctionnel\n' +
        'Vu les articles $_articles_$ du Code de Procédure pénale\n' +
        'Attendu que .......\n' +
        'Faits prévus et punis par .......\n' +
        'Ordonnons le renvoi de l’affaire devant le Tribunal Correctionnel pour être jugée conformément à la loi.\n' +
        'En conséquence, ordonnons que le dossier de cette procédure, avec la présente ordonnance, sera transmis à M. le Procureur de la République.'
    },
    {
      name:'Autre ORDONNANCE',
      is_default_template:0,
      is_final_actions:0,
      template:'ORDONNANCEL\n'
    }
    ]

  my_tribunal:any = null
  assigned_by = null

  can_change_dossier_step=true
  can_edit_tag=false
  selectedTag = ''
  SELECTED_TAG_OBJECT:any = {}

  DEFAULT_TEXT = ''
  MY_PROFIL = null
  replacements :any ={
    "position_unite":'PARQUET'
  }
  constructor(
              private _formBuilder: FormBuilder,
              private options: ModalOptions,
              private dossierService:DossierService,
              private courrier: UsercourrierService,
              private chambre:ChambresService,
              private assigne: AssignedService,
              private alerte:AlertesService,
              private custom:CustomService,
              public bsModalRef: BsModalRef,
              private ngxService: NgxUiLoaderService,
              private modalService: BsModalService
            ) {
    this.dossier = this.options.initialState.dossier;
    this.IS_AVIS = this.options.initialState.is_avis;
    this.IS_SUPLETIF = this.options.initialState.is_supletif;

    console.log({'data in requist=>':this.dossier});
    this.MY_PROFIL = localStorage.getItem('profil');

    this.type = this.options.initialState.type;
    this.DEFAULT_TEXT = this.type
    try {
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
    }catch (e) {
      this.my_tribunal =null
    }


    if(this.type =='Requisitoire'){
      //this.TYPE_DE_TAG = this.REQUISITOIRES
      this.PROFIL_TO_SELECT = 'JUGE D\'INSTRUCTION'
      this.IS_CABINET_INSTRUCTION =1
    }
    if(this.type =='Ordonance'){
      //this.TYPE_DE_TAG = this.ORDONNANCES
      this.PROFIL_TO_SELECT = 'PROCUREUR'
      this.IS_CABINET_INSTRUCTION=0
    }

    if(this.IS_CABINET_INSTRUCTION==1){
      this.chambre.getAllChambreByType("CABINET D'INSTRUCTION").then(res=>{
        console.log('REs ===> ',res.message)
        this.ALL_DESTINATAIRE = res.message.formations
      })
    }else {
      this.custom.getUserByRole(this.PROFIL_TO_SELECT,this.my_tribunal.name).then(res=> {
        // console.log(res)
        this.ALL_DESTINATAIRE = res.message
        // console.log(this.ALL_JUGE_INSTUCTION)
      })
    }

    if(this.IS_AVIS==1){
      let my_information:any = localStorage.getItem('me');
      try {
        my_information=JSON.parse(my_information)
      }catch (e) {

      }
      // let filter = '[["ToDo","reference_type","=","Dossier"],["ToDo","reference_name","=","'+this.dossier.name+'"]]'
      let filter = '[["ToDo","reference_type","=","Dossier"],["ToDo","reference_name","=","'+this.dossier.name+'"],["ToDo","allocated_to","=","'+my_information.name+'"]]'
      this.assigne.getWhoAssign('Dossier',filter).then(res=>{
        console.log('VERIF  FOR =====> ',my_information)
        console.log('Res assignation =====> ',res)
        console.log('Res DATA allocated_to=====> ',res.data[0].assigned_by)
        this.assigned_by = res.data[0].assigned_by
        this.ALL_DESTINATAIRE = {"parent":this.assigned_by}
      })
    }

    let _profil = this.MY_PROFIL=='JUGE'? 'JUGE CHAMBRE':this.MY_PROFIL
    let filter  = '[["type" , "=","'+this.type+'"],["profil_authoriser" , "like","%'+_profil+'%"],["is_avis_model" , "=","'+this.IS_AVIS+'"],["is_supletif" , "=","'+this.IS_SUPLETIF+'"]]'
    this.dossierService.getAllMyModelOfRequisitoireOrOrdonance(filter).then(res =>{
      console.log('getAllMyModelOfRequisitoireOrOrdonance ===> ',res.data)
      this.TYPE_DE_TAG = res.data
    })
    //console.log('this.dossier ==> ', this.dossier)

  }

  onChangeDestinataire(event: any){
    let choice = event.target.value;
    console.log('choice ==>  ==> ',choice)
    if(this.IS_CABINET_INSTRUCTION==1){
      this.ngxService.start()
      this.replacements['position_unite'] = choice
      this.chambre.getMembreChambreToAssign(choice).then(res =>{
        //console.log('res=====> ',res.message.membres)
        this.USERS_TO_ASSIGN = res.message.membres
        this.ngxService.stop()
      },err =>{
        //console.log('res ===> ',err)
        this.alerte.warningNotification('Erreur lors de la recuperation des informations')
        this.ngxService.stop()
      })
    }
  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
    console.log('in addreq=> ',message);

  }
  closeModal(){
    this.bsModalRef.hide()
  }

  remplacerVariables(template, jugement) {
    let mytemplate = template;
    let correspondanceTrouvee = false;
    console.log({'the te1': template});
    console.log({'the te1': jugement});
    // jugement.infractions =  [
    //   { accusation: "Vol en réunion" },
    //   { accusation: "Escroquerie" },
    //   { accusation: "Corruption" }
    // ]

    for (let key in jugement) {
      if (jugement.hasOwnProperty(key)) {
        let regex = new RegExp("\\$_" + key + "_\\$", "g");

        try {
          // Vérifier si la valeur de l'attribut est un tableau d'objets
          if (Array.isArray(jugement[key])) {
            // Si c'est un tableau d'objets, on peut formater chaque élément
            let formattedArray = this.formatArrayToText(jugement[key]);
            mytemplate = mytemplate.replace(regex, formattedArray);
            correspondanceTrouvee = true;
          }
          // Si la valeur n'est pas un tableau, mais est null ou undefined
          else if (jugement[key] == null || jugement[key] === undefined) {
            mytemplate = mytemplate.replace(regex, '.......');
          }
          // Si c'est une valeur simple, on la remplace directement
          else {
            mytemplate = mytemplate.replace(regex, jugement[key]);
            correspondanceTrouvee = true;
          }
        } catch (e) {
          console.error("Erreur de remplacement", e);
        }
      }
    }

    // Filtrer les remplacements éventuels
    let model_final = this.filter_remplacerVariables(mytemplate, jugement);

    return model_final;
  }

  formatArrayToText(array) {
    // Vérifiez si le tableau est vide
    if (array.length === 0) {
      // return 'Aucune donnée disponible.';
    }

    // Si c'est un tableau d'objets avec un champ spécifique (par exemple 'accusation')
    return array.map((item, index) => {

        return `${index + 1}. ${item.accusation}`;

    }).join('<br>'); // Séparer les éléments par un saut de ligne HTML
  }


  filter_remplacerVariables(template, jugement) {
    let mytemplate = template;
    let modeleFinal: any;

    // Expression régulière pour récupérer les mots commencant par $_ et terminant par _$
    let regexPattern = /\$_([^$]+)_\$/g;// Échapper les caractères spéciaux dans la chaîne de la RegExp

  modeleFinal = mytemplate.replace(regexPattern, '...........................')
    return modeleFinal;
  }

  replacePlaceholders(json, replacements) {
    //console.log('the JSON ===> ',json)
    //console.log('the replacements ===> ',replacements)
    let replacementKeys = Object.keys(replacements)

    //console.log('replacementKet  ===> ',replacementKeys)
    json = JSON.stringify(json)
    for(let key of replacementKeys){
      //console.log("TRY TO REPLACE $_"+key+"_$ to ",replacements[key])
      json= json.replace('$_'+key+'_$',replacements[key])
    }
    //console.log('the JSON ===> ===> ',JSON.parse(json))

    return JSON.parse(json)
  }

  addRequisitoireOr(data: NgForm){

    data.value['numero_dossier'] = this.dossier.name
    let body= data.value
    console.log('=========> ', body)
    this.ngxService.start();
    if(this.can_change_dossier_step==true ){
      if(body.destinataire==''){
        this.ngxService.stop()
        this.alerte.errorNotification('Destinataire','Merci de selectionner le destinataire')
        return
      }
    }

    if (this.can_edit_tag==true) {
      this.selectedTag = body.tag_custom
    }
    let destinataire = [body.destinataire]
    if(this.IS_CABINET_INSTRUCTION==1){
      destinataire = []
      body.destinataire=null
      for (let dest of this.USERS_TO_ASSIGN){
        destinataire.push(dest.utilisateur)
        if(body.destinataire==null){
          body.destinataire = dest.utilisateur
        }
      }
    }

    body['tag'] = this.selectedTag
    this.dossierService.createRequisitoireOrOrdonance(body).then((res:any) => {
      this.assigne.doAssignationForRequiredProfil(this.MY_PROFIL,this.dossierService.Doctype_requisitoire_ordonances,res.data.name)
      if(this.SELECTED_TAG_OBJECT.value_to_update !=0){
        let body_to_update = JSON.parse(this.SELECTED_TAG_OBJECT.value_to_update)
        body_to_update=this.replacePlaceholders(body_to_update,this.replacements)
        if(this.can_change_dossier_step==false){
          delete body_to_update['position_unite']
        }
        console.log('body_to_update ',body_to_update)
        try{
          this.dossierService.updateDossier(this.dossier.name,body_to_update)
        }catch (e) {
          console.log('Error, On Update value ==> ',e)
        }
      }

      console.log('Resultatat ===>')
      if(this.can_change_dossier_step==true){

        this.assigne.doAssignation(this.dossierService.Doctype_requisitoire_ordonances,res.data.name,destinataire)
        let c_interne=  {
          "numéros_du_dossier": this.dossier.name,
          "profil": this.PROFIL_TO_SELECT,
          "destinataire": body.destinataire,
          "tag": this.selectedTag,
          "fichier": null,
          "message": body.continu
        }
        if(this.my_tribunal !=null){
          c_interne['tribunal']= this.my_tribunal.name
        }
        this.courrier.nouveauCourrierInterne(c_interne).then(res=>{
          this.assigne.doAssignation(this.courrier.Docatype_courrier_interne,res.data.name,destinataire)
        })
        let new_state ='JUGE D\'INSTRUCTION'
        if(this.dossier.workflow_state=='Juge d\'instruction' ){
          new_state='PROCUREUR'
        }
        if(this.SELECTED_TAG_OBJECT.can_change_step ==1 ){
          this.dossierService.updateSatate(this.dossier.name,new_state).then(res => {
              this.assigne.doAssignation(this.dossierService.Doctype_dossier,this.dossier.name,destinataire).then(res =>{
                console.log('Assignation Reussie')
                this.openModalSuccessDialog('Dossier transfere en instruction');
              })
            },
            err=>{
              this.ngxService.stop();
              this.alerte.errorNotification( this.type,'Une Erreur est survenue !')
              console.log('ERROR ON CHANGE STATUS ',err)
            })
        }

        this.ngxService.stop();
        this.closeWithMessage("success");
        this.alerte.successNotification( this.IS_AVIS==1?'Avis Envoy3':this.type+' Crée !')
      }

      if(this.SELECTED_TAG_OBJECT.remove_my_assign ==1){
        //remove assign
        let me = localStorage.getItem('me')

        try{
          this.assigne.removeAssignation("Dossier", this.dossier.name, JSON.parse(me).name)
          this.assigne.removeReadAuth("Dossier", this.dossier.name, JSON.parse(me).name)

        }catch (e) {
          console.log('Error, On delete assignation value ==> ',e)
        }
      }
    else {
        this.ngxService.stop();
      }

      this.alerte.successNotification( this.type+' Crée!')
      this.closeWithMessage("success");
    },err=>{
      this.ngxService.stop();
      console.log('ERROR ON CHANGE REQUISITOIRE ',err)
      this.alerte.errorNotification(this.type,'Une Erreur est survenue !')
    })
  }

  onTagChange(selectedValue: string) {
    let the_tag:any = (this.TYPE_DE_TAG.filter(obj => obj.name === selectedValue))[0];
    // this.DEFAULT_TEXT = the_tag.template
    console.log('the_tag ==> ',the_tag)
    this.DEFAULT_TEXT =  this.remplacerVariables(the_tag.template, this.dossier)

    this.selectedTag = the_tag.name
    this.SELECTED_TAG_OBJECT = the_tag;

    this.verifAction();

    let val_test = the_tag.value_to_update
    console.log('val_test 1==> ',val_test)
    this.replacePlaceholders(val_test,this.replacements)
    console.log('val_test 2==> ',val_test)

  }

  verifAction(){
    let is_default_template = this.SELECTED_TAG_OBJECT.is_default_template
    let  is_final_actions = this.SELECTED_TAG_OBJECT.is_final_actions

    if(is_final_actions==1){
      this.can_change_dossier_step = true
    }else{
      this.can_change_dossier_step = false
    }
    if(is_default_template==0){
      this.can_edit_tag = true
    }else {
      this.can_edit_tag = false
    }
  }

  OnCheckChange(){
    this.can_change_dossier_step = !this.can_change_dossier_step
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.modalService.hide()
    // this.penalCourrierService.hiddeModal(this.parentModal);
    setTimeout(() => {
      if (this.modalService) {
        this.modalService.hide();
      }
    }, 1000);
    const modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  ngOnInit(){

  }
}
