import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddJugementComponent } from '../add-jugement/add-jugement.component';
import { WhiteboardCanvasComponent } from '../whiteboard-canvas/whiteboard-canvas.component';
import { AnnotationService } from '../../services/frapper/annotations.service';

@Component({
  selector: 'app-list-notes',
  templateUrl: './list-notes.component.html',
  styleUrls: ['./list-notes.component.scss']
})
export class ListNotesComponent {
  isLinear = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    pageNotes: number = 1;
    num_audience:any;
    doctype:any
    type: any;
    

    @Input() parentModal: BsModalRef;
    my_tribunal:any = null
    searchKeyWord: FormControl = new FormControl('');
    filtered_jugements:any[] = []
     list_notes: any[] = [
      
    ];
    p:number = 1;
    modalRef!: BsModalRef;
    saved_name_note = [];
    constructor(private _formBuilder: FormBuilder,
                private options: ModalOptions,
                private alerte:AlertesService,
                private ngxService: NgxUiLoaderService,

                private bsModalRef: BsModalRef,
                private datePipe: DatePipe,
                private annotationService: AnnotationService,
                
  
  
                private modalService: BsModalService) {
      this.num_audience = this.options.initialState.num_audience;
      this.doctype = this.options.initialState.doctype;
     // console.log('DATE ====> ', this.default_date);
    }
  
    closeModal(){
      this.bsModalRef.hide()
    }
   
  
    openModalSuccessDialog(message: string) {
      console.log('asaaasddddddd');
  
      const config: ModalOptions = {
        initialState: {
          message: message
        }
      };
      setTimeout(() => {
        if (this.modalService) {
          this.modalService.hide();
        }
      }, 1000);
      const modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
    }

  
    openModalAddNote() {
  
  
      const config: ModalOptions = {
        backdrop: true,
        initialState: {
          is_new: true,
          doctype: this.doctype,
          already_saved_name: this.saved_name_note,
          num_audience:this.num_audience
        }
       }
        return new Promise<string>((resolve, reject) => {
             const modalRef  = this.modalService.show(WhiteboardCanvasComponent, Object.assign({},config,{class:'modal-xl  '}))
             modalRef.onHidden.subscribe((value: string) => {
               resolve(value); // Résoudre la promesse avec la valeur retournée
             });
           }).then(async (value: string) => {
             if ( value == "success") {
                this.getAllNotes()
             }
       
             
           });
  
    }
    openModalDetailNote(note) {
  
  
      const config: ModalOptions = {
        backdrop: true,
        initialState: {
          is_new: false,
          doctype: this.doctype,
          num_audience:this.num_audience,
          already_saved_name: this.saved_name_note,
          note: note
        }
       }
       const modalRef  = this.modalService.show(WhiteboardCanvasComponent, Object.assign({},config,{class:'modal-xl  '}));
  
    }

    async getAllNotes(){

      let storage_saved =  sessionStorage.getItem("canvasWhiteboardDrawings"+`_${this.num_audience}`);
      console.log('storage_saved', storage_saved);
      try {
        this.ngxService.start()
       // this.casiers = this.greffierService.getCasiers();
        let filter =this.my_tribunal==null? null : '[["tribunal" , "=","'+this.my_tribunal.name+'"], ["numero_du_doctype" , "=","'+this.num_audience+'"]]'
        this.list_notes = ( await this.annotationService.getAllAnnotations(filter)).data
       console.log('allnotes', this.list_notes);
       
          this.ngxService.stop()
          if (storage_saved.length>0) {
            this.list_notes =  this.list_notes.concat(storage_saved)
          }
          for (const key in this.list_notes) {
            if (Object.prototype.hasOwnProperty.call(this.list_notes, key)) {
              const element = this.list_notes[key];
              this.saved_name_note.push(element.titre)
              
            }
          }
          console.log('name_saved', this.saved_name_note);
          
        
      } catch (error) {
        this.ngxService.stop()
      }
    }
  
   
  
    async ngOnInit() {

      try {
        this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
      }catch (e) {
        this.my_tribunal =null
      }

      this.getAllNotes()
      // this.ngxService.start()
    }
}
