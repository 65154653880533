import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  Doctype_annotations ='Annotations'
  // Doctype_Type_Demande ='type_de_demande'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}


 //all annotations
  async getAllAnnotations(filter =null) {
    try {
      let all_annotations = await this.frapper.GetDoctypes(this.Doctype_annotations,'["*"]',filter).toPromise()
      console.log('resp api Doctype_Signature ===> ', all_annotations)
      return all_annotations
    } catch (error) {
      this.authservice.handleError(error)
    }

  }


  async getOneAnnotation(name) {
    try {
      let annotation = await this.frapper.GetDoctype(this.Doctype_annotations,name,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return annotation
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouvelleAnnotations(body) {
    try {
      let annotation = await this.frapper.CreateDoctype(this.Doctype_annotations,body).toPromise()
      return annotation
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateAnnotation(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_annotations,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
      let result = this.frapper.UpdateDoctype(this.Doctype_annotations,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

 // ** Demande

}
