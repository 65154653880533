<div class="container-fluid page ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

          <div class="card" >
            <div class="d-flex justify-content-center">
              <h5 id="heading">Ajouter {{type.toUpperCase()}}</h5>
              <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
                <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
              </button>
            </div>
            
              <form id="msform" (ngSubmit)="AddNewItem(newinfraction)"  #newinfraction="ngForm"  >
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>{{type.toUpperCase()}}</strong></li>
                  </ul>
                 <br> <!-- fieldsets -->
                 <fieldset class="step1" *ngIf="type == 'infraction'">
                  <div class="d-flex justify-content-center align-items-center flex-column">
                    <div class="col-8">
                      <label class="fieldlabels">Label: *</label>
                      <input type="text" class="form-control" name="label" [(ngModel)]="label_default" required />
                    </div>
                
                    <div class="col-8">
                      <label class="fieldlabels">Type: *</label>
                      <select class="custom-select" name="type" ngModel required>
                        <option value="Délit">Délit</option>
                        <option value="Crime">Crime</option>
                      </select>
                    </div>
                
                    <div class="col-8">
                      <label class="fieldlabels">Textes: </label>
                      <textarea class="form-control" style="height: 100px;" name="textes" ngModel></textarea>
                    </div>
                
                    <div>
                      <input type="submit" name="next" class="action-button" value="Enregistrer" />
                    </div>
                  </div>
                </fieldset>
                  <!-- Decisions judiciaires -->
                <fieldset class="step1" *ngIf="type == 'decision'">
                  <div class="d-flex justify-content-center align-items-center flex-column">
                    <div class="col-8">
                      <label class="fieldlabels">Nom: *</label>
                      <input type="text" class="form-control" name="nom" [(ngModel)]="label_default" required />
                    </div>
                
                    <div class="col-8">
                      <label class="fieldlabels">Applicable à un mineur: *</label>
                      <select class="custom-select" name="applicable_a_un_mineur" ngModel required>
                        <option value="NON">NON</option>
                        <option value="OUI">OUI</option>
                      </select>
                    </div>
                
                    <!-- <div class="col-8">
                      <label class="fieldlabels">Textes: </label>
                      <textarea class="form-control" style="height: 100px;" name="textes" ngModel></textarea>
                    </div> -->
                
                    <div>
                      <input type="submit" name="next" class="action-button" value="Enregistrer" />
                    </div>
                  </div>
                </fieldset>

                  <!-- categorie judiciaires -->
                  <fieldset class="step1" *ngIf="type == 'categorie'">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                      <div class="col-8">
                        <label class="fieldlabels">Libellé: *</label>
                        <input type="text" class="form-control" name="libelle" [(ngModel)]="label_default" required />
                      </div>
                  
                      <div class="col-8">
                        <label class="fieldlabels">Description: </label>
                        <textarea style="height: 100px;" class="form-control" ngModel  name="description"> </textarea>

                        <!-- <select class="custom-select" name="applicable_a_un_mineur" ngModel required>
                          <option value="NON">NON</option>
                          <option value="OUI">OUI</option>
                        </select> -->
                      </div>
                  
                      <!-- <div class="col-8">
                        <label class="fieldlabels">Textes: </label>
                        <textarea class="form-control" style="height: 100px;" name="textes" ngModel></textarea>
                      </div> -->
                  
                      <div>
                        <input type="submit" name="next" class="action-button" value="Enregistrer" />
                      </div>
                    </div>
                  </fieldset>

              </form>
          </div>


      </div>
  </div>
</div>
