import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NbLogoutComponent, NbRegisterComponent } from '@nebular/auth';
import { LoginSigninComponent } from './E-justice/authentification/login-signin/login-signin.component';
import { MyCONST } from './E-justice/CONST/myConst';
import { HomePageComponent } from './E-justice/Dashboards/PUBLIC/home-page/home-page.component';

export const routes: Routes = [
  // Lazy loading des modules
  {
    path: 'E-justice',
    loadChildren: () => import('./E-justice/allpage/allpage.module')
      .then(m => m.AllpageModule),
  },
  {
    path: 'X',
    loadChildren: () => import('./E-justice/Dashboards/PUBLIC/public.module')
      .then(m => m.PublicModule),
  },
  
  // Route principale avec redirection conditionnelle
  {
    path: '',
    // Déterminer le composant à afficher selon IS_PUBLIC
    component: MyCONST.IS_PUBLIC === 0 ? LoginSigninComponent : HomePageComponent,
    children: [
      {
        path: 'login',
        component: LoginSigninComponent,
      },
      {
        path: 'home',
        component: HomePageComponent,  // Définissez la page d'accueil par défaut ici
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
    ],
  },

  // Redirection conditionnelle vers login ou home selon IS_PUBLIC
  {
    path: '',
    redirectTo: MyCONST.IS_PUBLIC === 0 ? 'login' : 'home',  // Dépend de IS_PUBLIC
    pathMatch: 'full',
  },

  // Catch-all pour les routes invalides, redirige vers la route principale (login ou home)
  { 
    path: '**', 
    redirectTo: MyCONST.IS_PUBLIC === 0 ? 'login' : 'home',
  },
];

// Configuration pour le préchargement des modules
const config: ExtraOptions = {
  useHash: true,  // Utilisation du hash dans l'URL
  preloadingStrategy: PreloadAllModules,  // Préchargement de tous les modules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
