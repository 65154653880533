import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { on } from 'events';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private keyLanguage = 'userLanguage'
  private _userLanguage = 'fr';
  // private supportedLanguages = ['fr', 'en'];
  private supportedLanguages = ['fr'];


  constructor(private translate: TranslateService) {
    this.initLanguage();
    this.translate.setDefaultLang(this._userLanguage);
    this.translate.use(this._userLanguage);
  }

  initLanguage() {
    console.log('localstorage', localStorage.getItem(this.keyLanguage));
    console.log('browserLanguage', navigator.language.split('-')[0]);
    if (localStorage.getItem(this.keyLanguage) != null) {
      this._userLanguage = localStorage.getItem(this.keyLanguage);
      console.log('_userLanguageif', this._userLanguage);
    } else {
      const browserLanguage = navigator.language.split('-')[0];
      this._userLanguage = 'fr';
      if (this.supportedLanguages.includes(browserLanguage)) {
        this._userLanguage = browserLanguage;
        console.log('_userLanguageelse', this._userLanguage);
        localStorage.setItem(this.keyLanguage, browserLanguage);
      }
    }
  }
  setLanguage(lang: string) {
    this._userLanguage = lang;
    localStorage.setItem(this.keyLanguage, this._userLanguage);
    this.translate.use(this._userLanguage);
    this.translateElements();
  }
  get userLanguage() {
    return this._userLanguage;
  }

  getAvailableLanguages() {
    return this.supportedLanguages;
  }
  translateElements() {
    const elementsToTranslate = document.querySelectorAll('[data-translate]');

    elementsToTranslate.forEach(element => {
      const translationKey = element.getAttribute('data-translate');
      this.translate.get(translationKey).subscribe((translation: string) => {
        if (element.tagName === 'INPUT') {
          (element as HTMLInputElement).placeholder = translation;
        } else {
          element.textContent = translation;
        }
      });
    });
  }
  translateMenuItems(menuItems: NbMenuItem[]) {
    menuItems.forEach(item => {
      if (item.title) {
        this.translate.get(item.title).subscribe((translation: string) => {
          item.title = translation;
        });
      }
      if (item.children) {
        this.translateMenuItems(item.children);
      }
    });
  }

}
