import { Component, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import * as $ from 'jquery'; 
import {AlertesService} from "../../services/public/alertes.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {CasierService} from "../../services/frapper/casier.service";
import {CustomService} from "../../services/frapper/custom.service";
import {VisualizeCasierComponent} from "../visualize-casier/visualize-casier.component";
import { DatePipe } from '@angular/common';
import { oc } from 'date-fns/locale';
import {PersonnesService} from "../../services/frapper/personnes.service";

@Component({
  selector: 'app-add-casier',
  templateUrl: './add-casier.component.html',
  styleUrls: ['./add-casier.component.scss']
})
export class AddCasierComponent {
  @Input() parentModal: BsModalRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  type:any;
  modalRef!: BsModalRef;
  identification_type:any =[];
  selected_typeIdentification : string ="";
  selected_numIdentification : string ="";
  isMultipleCasier: boolean = false;
  selected_type_of_casier =null
  my_tribunal:any = null

  num_casier:any = 'XXXX';
  number_casier: number = 0;

  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private casierService:CasierService,
              private alerte : AlertesService,
              private customservice: CustomService,
              private ngxService: NgxUiLoaderService,
              private personnes:PersonnesService,
              private bsModalRef: BsModalRef,
              private datePipe: DatePipe,
              private penalService: PenalCourrierService) {
    this.data = options.initialState.data
    if (this.data!= null || this.data != undefined) {
      this.filterValueChange(this.data.type_de_casier,'type_casier')
    }
    this.type = options.initialState.typeCourrier;
    try{
      this.my_tribunal = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }

  }

  async ActiveMultipleCasier(){
    this.isMultipleCasier = !this.isMultipleCasier;
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    setTimeout(() => {
      if (this.modalService) {
        this.modalService.hide();
      }
    }, 1000);
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm '}));
  }

  closeModal(){
    this.bsModalRef.hide()
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg '}));
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  generateInfractionTemplate(AllInfracions){

    let result = null
    if(AllInfracions.length ==0){
      result =`
      <table class="table">
        <thead>
          <tr>
          <th>Date des Condamnations</th>
          <th>Cours ou Tribunaux</th>
          <th>Nature des Infractions</th>
          <th>Crimes ou Délits</th>
          <th>Nature et durée des peines</th>
          <th>Observations</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5" class="text-center opacity-25" ><h1> N &nbsp;&nbsp;E &nbsp;&nbsp;A &nbsp;&nbsp;N &nbsp;&nbsp;T </h1></td>
            <td colspan="1" class="text-center opacity-25 " ><br><p>${this.selected_typeIdentification} <br> ${this.selected_numIdentification} </p> </td>
          </tr>
        </tbody>
      </table>
      `
    }
      else {
      result ="<table>\n" +
        "   <thead>\n" +
        "      <tr>\n" +
        "         <th>Date des Condamnations</th>\n" +
        "         <th>Cours ou Tribunaux</th>\n" +
        "         <th>Nature des Infractions</th>\n" +
        "         <th>Crimes ou Délits</th>\n" +
        "         <th>Nature et durée des peines</th>\n" +
        "         <th>Observations</th>\n" +
        "      </tr>\n" +
        "   </thead>\n" +
        "   <tbody>\n"
      for (let val of AllInfracions){
        let body = "<tr>\n" +
          "         <td>"+ this.getFormattedDate(val['date']) +"</td>\n" +
          "         <td>"+ val['cours_trib']+"</td>\n" +
          "         <td>"+ val['nature_inf']+"</td>\n" +
          "         <td>"+ val['crime_delits']+"</td>\n" +
          "         <td>"+ val['nature_duree_peine']+"</td>\n" +
          "         <td>"+ val['observation']+"</td>\n" +
          // "<td>"+ this.selected_typeIdentification+ "<br>"+ this.selected_numIdentification +"</td>\n"
          "      </tr>\n"
        result = result +body
      }
      result = result+   "</tbody>\n" +
        "</table>"
    }
    return result
  }

  filterValueChange(value,type){
    console.log(value)
    if(type=='type_casier'){
      this.ngxService.startLoader('addcasier')
      //this.selected_type_of_casier = value
      this.casierService.getOneTypeCasier(value).then(res=>{
        this.selected_type_of_casier = res.data
        //console.log(this.selected_type_of_casier)
        this.casierService.getLastNumberCasier(value).then(res =>{
          try {
            this.num_casier= res.message.new_casier_number
            this.ngxService.stopLoader('addcasier')
          } catch (error) {
            this.ngxService.stopLoader('addcasier')
          }

          //console.log('Resultat Casier ==> ',res.message.new_casier_number)
          
        },err =>{
          console.log('error get ttype=> ', err);

          this.alerte.warningNotification('Numero casier','Erreur lors de la recuperation du numero!')
          this.ngxService.stopLoader('addcasier')
        })
        //console.log(this.selected_type_of_casier)

      },err=>{
        console.log('error ==> ',err)
        this.ngxService.stopLoader('addcasier')
        //this.selected_type_of_casier = null
        console.log('Error ==> no existe')
      })
    }
  }

  createCasier(Params_data_casier){
    let data_casier = Params_data_casier;
    return data_casier;
  }


  addPersone(body){
    let body_content ={
      "genre": body.genre,
      "nom": body.nom,
      "prenom": body.prenom,
      "nom_complet_du_pere": body.nom_du_pere,
      "nom_complet_de_la_mere": body.nom_de_la_mere,
      "nationalités": body.nationalite,
      "date_de_naissance": body.date_de_naissance,
      "lieu_de_naissance": body.lieu_de_naissance,
      "domicile": body.domicile,
      "status_marital": body.etat_civil,
      "identifications": [ {
        "type": body.type_didentification,
        "numero_identifications": body.numero_didentication,
        "parentfield": "identifications",
        "parenttype": "personnes",
        "doctype": "personne_identifications"
      }],
      "affiliation": [
        {
          "lien": "Pere",
          "nom": " _",
          "prenom": body.nom_du_pere,
          "parentfield": "affiliation",
          "parenttype": "personnes",
          "doctype": "personne_affiliations"
        },
        {
          "lien": "Mere",
          "nom": " _",
          "prenom": body.nom_de_la_mere,
          "parentfield": "affiliation",
          "parenttype": "personnes",
          "doctype": "personne_affiliations"
        }
      ]
    }
    //nom_de_la_mere
    this.personnes.nouvellePersonne(body_content).then(res =>{
        console.log('Personne ajouter')
      },
      err =>{
        console.log(err)
      })
  }

  onSubmit(form: NgForm) {
    // Initialiser un tableau pour stocker les condamnations
    const condamnations = [];
    let multipleCasier = [];
    this.ngxService.startLoader('addcasier')
    this.selected_typeIdentification = form.value.id_type
    this.selected_numIdentification = form.value.id_num

    //console.log('REturn ===>  ',form.value)
    //return



    // Parcourir chaque ligne du tableau
    $('#myTable tbody tr').each(function() {
      // Récupérer les valeurs des champs de chaque ligne
      const date = $(this).find('input[name="date"]').val();
      const coursTrib = $(this).find('input[name="cours_trib"]').val();
      const natureInf = $(this).find('input[name="nature_inf"]').val();
      const crimeDelits = $(this).find('input[name="crime_delits"]').val();
      const natureDureePeine = $(this).find('input[name="nature_duree_peine"]').val();
      const observation = $(this).find('input[name="observation"]').val();

      // Vérifier si les champs ne sont pas null ou vides
      if (date != null && coursTrib != undefined) {
          // Construire un objet représentant une condamnation
          const condamnation = {
              date: date,
              cours_trib: coursTrib,
              nature_inf: natureInf,
              crime_delits: crimeDelits,
              nature_duree_peine: natureDureePeine,
              observation: observation
          };

          // Ajouter l'objet de condamnation au tableau des condamnations
          condamnations.push(condamnation);
      }
  });


    let condamnationsHTML = this.generateInfractionTemplate(condamnations)


    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 to month since getMonth() returns 0-11
    const day = String(now.getDate()).padStart(2, '0');

    let date_de_generation_today =  `${year}-${month}-${day}`

    let data_casier = {
      "num_casier": form.value.num_casier,
      "type_de_casier": this.selected_type_of_casier.name,
      "message_casier": this.selected_type_of_casier.description,
      "type_didentification": form.value.id_type,
      "numero_didentication": form.value.id_num,
      "json_infractions": condamnationsHTML,
      "barcode_yiku": form.value.id_num+'-'+form.value.id_type,
      "date_de_generation": date_de_generation_today,
      "nom": form.value.nom,
      "prenom": form.value.prenom,
      "date_de_naissance": form.value.date_de_naissance,
      "lieu_de_naissance":  form.value.lieu_de_naissance,
      "genre": form.value.genre,
      "nationalite": form.value.nationalite,
      "domicile": form.value.domicile,
      "profession": form.value.profession,
      "nom_du_pere": form.value.nom_pere,
      "nom_de_la_mere": form.value.nom_mere,
      "etat_civil": form.value.situation_matrimonial,

    }
    console.log('the data form: ', data_casier);

    if(this.my_tribunal!=null){
      data_casier['tribunal']=this.my_tribunal.name
    }


    try {
      this.addPersone(data_casier)
    }catch (e) {
      console.log('Errueur sur enregistrement de la personne')
    }

   // return
    // this.openModalVisualCasier('print',data_casier)
    // this.ngxService.stopLoader('addcasier')

    // Afficher l'objet de contenu dans la console
    // console.log('Body ==> ',data_casier);

    if (this.isMultipleCasier && this.number_casier > 1) {
      for (let occ = 1; occ <= this.number_casier; occ++) {
        // const element = array[occ];
        console.log(occ,this.number_casier);
        if (occ> 1) {
          form.value.num_casier = parseInt(form.value.num_casier)+1
        }
        data_casier = {
          "num_casier": form.value.num_casier,
          "type_de_casier": this.selected_type_of_casier.name,
          "message_casier": this.selected_type_of_casier.description,
          "type_didentification": form.value.id_type,
          "numero_didentication": form.value.id_num,
          "json_infractions": condamnationsHTML,
          "barcode_yiku": form.value.id_num+'-'+form.value.id_type,
          "date_de_generation": date_de_generation_today,
          "nom": form.value.nom,
          "prenom": form.value.prenom,
          "date_de_naissance": form.value.date_de_naissance,
          "lieu_de_naissance":  form.value.lieu_de_naissance,
          "genre": form.value.genre,
          "nationalite": form.value.nationalite,
          "domicile": form.value.domicile,
          "profession": form.value.profession,
          "nom_du_pere": form.value.nom_pere,
          "nom_de_la_mere": form.value.nom_mere,
          "etat_civil": form.value.situation_matrimonial,

        }
        if(this.my_tribunal!=null){
          data_casier['tribunal']=this.my_tribunal.name
        }
      multipleCasier.push(data_casier)  ;
      this.casierService.nouveauCasier(data_casier).then(res =>{

        //this.openModalSuccessDialog('Enregistrement Effectuer')

        this.ngxService.stopLoader('addcasier')
      },err =>{
        console.log(err)
        this.alerte.errorNotification('Enregistrement du casier','Erreur lors de l\'enregistrement !')
        this.ngxService.stopLoader('addcasier')
      })



      }
      console.log({multipleCasier});
      this.openModalVisualCasier('print',multipleCasier,true);
      // this.openModalVisualCasier('print',multipleCasier,true);
      // this.ngxService.stopLoader('addcasier')


    }else{
      console.log('in process normal');

      this.casierService.nouveauCasier(data_casier).then(res =>{

        //this.openModalSuccessDialog('Enregistrement Effectuer')
        this.openModalVisualCasier('print',data_casier,false)
        this.ngxService.stopLoader('addcasier')
      },err =>{
        console.log(err)
        this.alerte.errorNotification('Enregistrement du casier','Erreur lors de l\'enregistrement !')
        this.ngxService.stopLoader('addcasier')
      })
    }



    //this.openModalSuccessDialog("Casier Générer avec succé!")
  }

  openModalVisualCasier(type='unprint',data_casier,isMultiple: boolean) {

    let data =  data_casier
    const config: ModalOptions = {
      backdrop: true,
      initialState: {
        data: data,
        type: type,
        isMultiple: isMultiple,
        parentModal : null
      }
    }
    // Ouvrir le modal   ViewDetailsCourrierComponent
    const modalRef = this.modalService.show(VisualizeCasierComponent,Object.assign({}, config, { class: 'modal-lg ' }));

    // Mettre à jour l'option initialState avec le modalRef
    modalRef.content.parentModal = modalRef;
  }

  hidde() {
    if (this.modalService) {
      this.modalService.hide();
    }
  }


  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.type );
    var stepFirst = this.type;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = steps
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });

  }

  ngOnInit() {
    var self = this;
    this.ngxService.startLoader('addcasier')
    this.casierService.getLastNumberCasier().then(res =>{
      try {
        this.num_casier= res.message.new_casier_number
        this.ngxService.stopLoader('addcasier')
      } catch (error) {
        this.ngxService.stopLoader('addcasier')

      }

      //console.log('Resultat Casier ==> ',res.message.new_casier_number)
     
    },err =>{
      this.alerte.warningNotification('Numero casier','Erreur lors de la recuperation du numero!')
      this.ngxService.stopLoader('addcasier')
    })
    this.customservice.getIdentificationType().then(res=>{
      this.identification_type = res.data
      //console.log('identification_type ==> ',this.identification_type)
      //console.log('identification_type ==> ',res)
    })

    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();



    })
    $('#addRowBtn').on('click', function() {
      // Créez une nouvelle ligne avec des colonnes Bootstrap
      const newRow = '<tr>' +
                       '<td><input type="date" class="form-control" name="date"  /></td>' +
                       '<td><input type="text" class="form-control" name="cours_trib"  /></td>' +
                       '<td><input type="text" class="form-control" name="nature_inf"  /></td>' +
                       '<td><input type="text" class="form-control" name="crime_delits"  /></td>' +
                       '<td><input type="text" class="form-control" name="nature_duree_peine"  /></td>' +
                       '<td><input type="text" class="form-control" name="observation"  /></td>' +
                       '<td>' +
                         '<i class="fas fa-solid fa-trash delete-row text-danger  mt-2" style="font-size:20px; cursor:pointer"></i>' +
                       '</td>' +
                     '</tr>'

      // Ajoutez la nouvelle ligne au tableau
      $('#myTable tbody').append(newRow);
    });

    // Gestionnaire d'événement pour supprimer une ligne
    $('#myTable').on('click', '.delete-row', function() {
      $(this).closest('tr').remove(); // Supprimer la ligne parente de ce bouton
    });

  }

}



