import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import * as $ from 'jquery';
import { ServicesService } from '../../services/public/services.service';
import { AlertesService } from '../../services/public/alertes.service';
import { PublicService } from '../../services/public/public.service';
import { AddPaymentComponent } from '../add-payment/add-payment.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ViewDetailsServicepublicComponent } from '../view-details-servicepublic/view-details-servicepublic.component';

@Component({
  selector: 'app-add-public-service',
  templateUrl: './add-public-service.component.html',
  styleUrls: ['./add-public-service.component.scss']
})
export class AddPublicServiceComponent {
  @Input() parentModal: BsModalRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  DEMANDE_TO_FORMS: any = [];
  request_field_form;
  loadingServiceForms = true;
  KEYS: any;
  DATA_FROM_REQUEST: any = null;
  noForm = true;
  steps_form:any = [];
  lenght_steps : number = 0;
  currentStepIndex: number = 0;
  DEMANDE_KEY_FILES_TO_UPDATE: any = {};
  SERVICE_DEMANDEUR_OPERATION_REF: any = null;
  demandeForm: FormGroup;

  constructor(private modalService: BsModalService,
    private service : ServicesService,
    private publicService: PublicService,
    private alerte: AlertesService,
    public bsModalRef: BsModalRef
    ,public options: ModalOptions, private penalService: PenalCourrierService,private formBuilder: FormBuilder,private ngxService: NgxUiLoaderService, private modalRef: BsModalRef) {
    this.data = options.initialState.data;
    this.DATA_FROM_REQUEST = options.initialState.data_from_request || null
    this.SERVICE_DEMANDEUR_OPERATION_REF = options.initialState.operation_ref || null

    let dataREF = {
      dynamic_field: this.formBuilder.array([])
    }
    this.demandeForm = this.formBuilder.group(dataREF);
    console.log({'dddddhhh': this.data});
     
    
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message); 
  }
  
  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.ngxService.stop();
    }
  }

  async getFormulaires(SERVICE_REF:any) {
    this.ngxService.startLoader('loader-01');
    this.loadingServiceForms = true
    await this.service.getServiceFormsWithEntries(SERVICE_REF).subscribe( (res :any) => {
      
      this.DEMANDE_TO_FORMS= res; 
      console.log({'getted form request': this.DEMANDE_TO_FORMS})     
      this.steps_form = this.DEMANDE_TO_FORMS;

      this.lenght_steps = res.length;
      if (this.lenght_steps <= 0) {
       
        this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services');
        this.hidde()

      }
      document.documentElement.style.setProperty('--lenght_steps', this.lenght_steps.toString());

      console.log({'steps': this.steps_form})
      this.KEYS = []
      let KEYS_VALUE_FROM_REQUEST: any = []
      if(this.DATA_FROM_REQUEST != null) {
        for(let form of this.DATA_FROM_REQUEST){
          for (let entry_key of form.formulaire.entries){

            if(this.DATA_FROM_REQUEST != null){
              KEYS_VALUE_FROM_REQUEST[entry_key.key+'-'+entry_key.id] = entry_key.value
            }
          }
        }
      }
      for(let form of this.DEMANDE_TO_FORMS){
        for (let entry_key of form.formulaire.entries){
          this.KEYS.push(entry_key.key+'-'+entry_key.id)
          if(this.DATA_FROM_REQUEST != null){
            console.log(entry_key.key+'-'+entry_key.id)
            // entry_key.value = KEYS_VALUE_FROM_REQUEST[entry_key.key+'-'+entry_key.id] || null
          }
        }
      }
      if(this.DATA_FROM_REQUEST != null){
        // console.log('KEYS_VALUE_FROM_REQUEST==> ',KEYS_VALUE_FROM_REQUEST)
      }


      let data = {
        dynamic_field: this.formBuilder.array(this.KEYS)
      }
      this.request_field_form = this.formBuilder.group(data);


      //console.log(this.FORM_INPUT)
      if (this.DEMANDE_TO_FORMS.length !=0){
        this.noForm = false
      }
      setTimeout(() => {
        this.loadingServiceForms = false
      }, 3);
      this.ngxService.stopLoader('loader-01');


    }, (error :any) => {
      this.ngxService.stopLoader('loader-01');
      this.loadingServiceForms = false
      this.alerte.errorNotification('FORMULAIRES',error.error)
    })
  }

  nextStep() {
    this.currentStepIndex++;
  }

  // onFileSelected(event: any,key: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     this.readFileAsBase64(file, key);
  //   }
  // }

  readFileAsBase64(file: File, key:any) {
    const reader = new FileReader();
    console.log('IIIIIIIIII')



    reader.onloadend = () => {
      const base64String = reader.result as string;
      this.DEMANDE_KEY_FILES_TO_UPDATE[key]=  base64String
      // Do something with the base64String, such as sending it to the server or displaying it on the page
    };

    reader.readAsDataURL(file);
  }

  // limitation taille fichier:
  onFileSelected(event: Event, key: string) {
    const input = event.target as HTMLInputElement;
  
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
  
      if (file.size > 100 * 1024) { // 100 Ko
        alert('La taille du fichier ne doit pas dépasser 100 Ko.');
        input.value = ''; 
        $(input).parent().find('.file-label').text('Importer un fichier(taille max. 100ko)');
        return;
      } else {
       
        const fileName = $(input).val().split("\\").pop();
        $(input).parent().find('.file-label').text(fileName);
  
        if (file) {
          this.readFileAsBase64(file, key);
        }
      }
  
      console.log(`Fichier sélectionné: ${file.name}`);
    }
  }

  closeModal(){
    this.bsModalRef.hide()
  }
  

  
  
  

  async sendDemande(deamnde: any) {
    
      console.log(deamnde.form.value);
      this.ngxService.start();

      const SENDED_KEYS = Object.keys(deamnde.form.value);
      let nbr_champ_vide = 0
      for (let key of SENDED_KEYS){
        if(deamnde.form.value[key]==''){
          nbr_champ_vide = nbr_champ_vide +1
        }
      }
  
      const FILES_UPDATED_KEYS = Object.keys(this.DEMANDE_KEY_FILES_TO_UPDATE);
      for (let key of FILES_UPDATED_KEYS){
        deamnde.form.value[key] = this.DEMANDE_KEY_FILES_TO_UPDATE[key]
      }
  
  
      let form = {
        service_ref: this.data.ref ,
        body: deamnde.form.value
      }
  
      if(nbr_champ_vide == 0) {
        let type = this.data.type
        if(this.DATA_FROM_REQUEST != null && this.SERVICE_DEMANDEUR_OPERATION_REF != null){
          form.body['custom_data'] = JSON.stringify({operation_sender:this.SERVICE_DEMANDEUR_OPERATION_REF})
        }
        try {
          this.publicService.newOperation(form).subscribe( (res :any) => {
            //console.log(res)
    
            const titre = this.data.label
    
            this.resetInformation()
            
              res.lifecycle_step === 'BILLING' ? res.lifecycle_step =  'EN ATTENTE DE PAIEMENT' :
                res.lifecycle_step === 'WAITING TRAITEMENT' ? res.lifecycle_step = 'EN ATTENTE DE TRAITEMENT' :
                res.lifecycle_step === 'TRAITEMENT ON PROGRESS' ? res.lifecycle_step = 'EN COUR DE TRAITEMENT' : 'TERMINEE'
            
            this.alerte.successNotification(titre+ ' envoyé','Status de la demande : '+ res.lifecycle_step)
            this.ngxService.stop(); 
            if(type == 'D' && res.service.is_facturable==1){
              this.bsModalRef.hide();
                this.openPaiementDialog(res.service, res.ref);
                
                
              
            }
    
            if(type == 'T'){
              this.alerte.successNotification('Traitement ', 'Status de la demande : TRAITEMENT EN COURS');
              this.bsModalRef.hide();
                this.closeWithMessage("for_close")
                this.openDemandeDetailDialog(res.service, res.ref);
                // this.ngxService.stop(); 
            }
            
          }, (error :any) => {
            this.alerte.errorNotification('Erreur ',error.error)
            this.ngxService.stop(); 
          })
        } catch (error) {
          this.bsModalRef.hide();
          this.alerte.errorNotification('Erreur ', error.error || 'Une erreur est survenue');
          this.ngxService.stop(); 
        }
       
      }else {
        this.alerte.warningNotification('','Merci de renseigner toutes les informations.')
        this.ngxService.stop(); 
      }

  }

  openDemandeDetailDialog(service: any,operation_ref:any){
    
    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        is_traitement:1
      }
    }
    if (service != null) {
      const modalRef = this.modalService.show(ViewDetailsServicepublicComponent, Object.assign({}, config, { class: 'modal-lg modal-viewdetailspublic' }));
      // modalRef.content.parentModal = modalRef;
  
    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }

  }
  
  // openDemandeDetailDialog(service: any,operation_ref:any){
  //   const dialogRef = this.dialog.open(ViewDemandeDetailsComponent, {
  //     width: '90vh', height: '85vh', data:{ service: service,operation_ref:operation_ref,is_traitement:1}
  //   });
  //   this.launchDialog(dialogRef);
  // }
  
  getFileInputId(formIndex: number, entryKey: string): string {
    return `file-${formIndex}-${entryKey}`;
  }

  async ngOnInit() {
    console.log({'type courrier': this.data.typeCourrier});
    
   await this.getFormulaires(this.data.ref);
    
    

    setTimeout(() => {
    
      $(document).ready(function(){
        var current_fs, next_fs, previous_fs; //fieldsets
        var opacity;
        var current = 1;
        var steps = $("fieldset").length;
        
  
        setProgressBar(current);
        if ($("fieldset").hasClass("_enrollement")) {
          // Cacher le premier fieldset et afficher le deuxième
          $("fieldset").eq(0).hide();
          $("fieldset").eq(1).show();
          current = 2;
  
          // Ajouter la classe "active" au deuxième élément de la barre de progression
          $("#progressbar li").eq(1).addClass("active");
          console.log("Au moins un élément fieldset a la classe 'cache'");
        }
  
  
  
  
        // Fonction pour définir la barre de progression
        function setProgressBar(curStep){
            var percent = (100 / steps) * curStep;
            percent = percent
            $(".progress-bar").css("width",percent+"%");
        }
  
        // Logique pour passer à l'étape suivante
        $(".next").click(function(){
            current_fs = $(this).parent();
            next_fs = $(this).parent().next();
  
            // Ajouter la classe "active" à l'étape suivante de la barre de progression
            $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
  
            // Afficher le fieldset suivant avec une animation
            next_fs.show();
            current_fs.animate({opacity: 0}, {
                step: function(now) {
                    opacity = 1 - now;
                    current_fs.css({
                        'display': 'none',
                        'position': 'relative'
                    });
                    next_fs.css({'opacity': opacity});
                },
                duration: 500
            });
            setProgressBar(++current);
        });
  
        // Logique pour revenir à l'étape précédente
        $(".previous").click(function(){
            current_fs = $(this).parent();
            previous_fs = $(this).parent().prev();
  
            // Retirer la classe "active" de l'étape actuelle de la barre de progression
            $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
  
            // Afficher le fieldset précédent avec une animation
            previous_fs.show();
            current_fs.animate({opacity: 0}, {
                step: function(now) {
                    opacity = 1 - now;
                    current_fs.css({
                        'display': 'none',
                        'position': 'relative'
                    });
                    previous_fs.css({'opacity': opacity});
                },
                duration: 500
            });
            setProgressBar(--current);
        });

        
  
    });
  
      }, 2000);
    
    
  }
  get stepWidth(): string {
    return `${100 / this.DEMANDE_TO_FORMS.length}%`;
  }



 
  resetInformation(){
    // this.DEMANDE_TO_SEND = null;
    this.DEMANDE_TO_FORMS= [];
    this.DEMANDE_KEY_FILES_TO_UPDATE = {}
    this.KEYS = []
  }


  openPaiementDialog(service: any, operation_ref:any) {
    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        parentModal: null // Initialiser parentModal à null
      }
    }
  
    // Ouvrir le modal
    const modalRef = this.modalService.show(AddPaymentComponent, Object.assign({}, config, { class: 'modal-lg modal-centered' }));
  
    // Mettre à jour l'option initialState avec le modalRef
    // modalRef.content.parentModal = modalRef;
  }



  
  
}
