<div class="">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
            <div class="d-flex justify-content-center">
              <h5 id="heading" class="text-dark" style="font-size: 12px;">Nouvelle demande de naturalisation</h5>
              <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
                <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
              </button>
            </div>
              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform"  #newNaturalisation="ngForm" (ngSubmit)="AddNewNaturalisation(newNaturalisation)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Informations demandeur</strong></li>
                      <li id="step2"><strong>Piéces fournies</strong></li>
                      <li id="step3"><strong>Information courrier</strong></li>

                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br> <!-- fieldsets -->
                  <fieldset class="step1">
                    <div class="form-card">

                      <div class="row">
                        <div class="col-md-6">
                            <label class="fieldlabels">Date de la demande <span class="text-danger">*</span>:</label>
                            <input type="date" class="form-control" name="date_de_demande"  [ngModel]="data ? data.date_de_demande : '' " required />
                    
                            <label class="fieldlabels">Nom <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="nom" [ngModel]="data ? data.nom : '' " required/>
                    
                            <label class="fieldlabels">Prénom <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="prenom" [ngModel]="data ? data.prenom : '' " required/>
                    
                            <label class="fieldlabels">Date de naissance <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="date" name="date_de_naissance" [ngModel]="data ? data.date_de_naissance : '' " required/>
                    
                            <label class="fieldlabels">Lieu de naissance <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="lieu_de_naissance" [ngModel]="data ? data.lieu_de_naissance : '' " required />
                    
                            <label class="fieldlabels">Pays d'origine <span class="text-danger">*</span>:</label>
                            <select class="custom-select" name="pays_dorigine" ngModel required>
                              <option *ngIf="data" value="{{data.pays_dorigine}}">{{data.pays_dorigine}}</option>
                                <option value="SENEGAL">SENEGAL</option>
                                <option value="AUTRES">AUTRES</option>
                            </select>
                        </div>
                    
                        <div class="col-md-6">
                            <label class="fieldlabels">Sexe <span class="text-danger">*</span>:</label>
                            <select class="custom-select" name="sexe" ngModel required>
                              <option *ngIf="data" value="{{data.sexe}}">{{data.sexe}}</option>
                                <option value="Homme">Homme</option>
                                <option value="Femme">Femme</option>
                            </select>
                    
                            <label class="fieldlabels">Numéro de téléphone <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="numero_de_telephone" [ngModel]="data ? data.numero_de_telephone : '' " required/>
                    
                            <label class="fieldlabels">Profession <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="profession"  [ngModel]="data ? data.profession : '' " required/>
                    
                            <label class="fieldlabels">Adresse de l'employeur <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="adresse_de_lemployeur" [ngModel]="data ? data.adresse_de_lemployeur : '' " required/>
                    
                            <label class="fieldlabels">Nom complet de la mère <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="prenom_et_nom_de_la_mere" [ngModel]="data ? data.prenom_et_nom_de_la_mere : '' " required/>
                    
                            <label class="fieldlabels">Nom complet du père <span class="text-danger">*</span>:</label>
                            <input class="form-control" type="text" name="prenom_du_pere" [ngModel]="data ? data.prenom_du_pere : '' " required/>
                    
                            <label class="fieldlabels">Nationalité d'origine <span class="text-danger">*</span>:</label>
                            <select class="custom-select" name="nationalite_d_origine" ngModel required>
                              <option *ngIf="data" value="{{data.nationalite_d_origine}}">{{data.nationalite_d_origine}}</option>
                                <option value="SENEGAL">SENEGAL</option>
                                <option value="GUINEE">GUINEE</option>
                                <option value="BENIN">BENIN</option>
                            </select>
                        </div>
                    </div>
                    


                    </div>
                      <input type="button" name="next" class="next action-button" value="Suivant" />
                  </fieldset >

                  <fieldset class="step2">
                    <div class="form-card">
                      <legend>Demandes</legend>
                      <section class="form-section " style="border: 0.5px solid grey; padding: 10px;">
                        <div class="row">
                          <div class="col-12 col-md-6 mb-3">
                            <label class="fieldlabels">Demande manuscrite (Garde des sceaux): </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file1">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_demande_manuscrite_garde_des_sceaux : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file1" class="form-control file-input" (change)="onFileSelected($event,'demande_manuscrite_garde_des_sceaux')" name="demande_manuscrite_garde_des_sceaux" [ngModel]="data ? data.file_demande_manuscrite_garde_des_sceaux : '' " />
                            </label>
                          </div>
                          <div class="col-12 col-md-6 mb-3">
                            <label class="fieldlabels">Acte de naissance: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file2">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_acte_de_naissance : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file2" class="form-control file-input" (change)="onFileSelected($event,'acte_de_naissance')" name="acte_de_naissance" [ngModel]="data ? data.file_acte_de_naissance : '' " />
                            </label>
                          </div>
                        </div>
                      </section>
                    
                      <br>
                    
                      <legend>Moralité</legend>
                      <section class="form-section" style="border: 0.5px solid grey; padding: 10px;">
                        <div class="row">
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels">Casier judiciaire B3: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file3">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_casier_judiciaire_b3 : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file3" class="form-control file-input" (change)="onFileSelected($event,'casier_judiciaire_b3')" name="casier_judiciaire_b3" [ngModel]="data ? data.file_casier_judiciaire_b3 : '' "  />
                            </label>
                          </div>
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels">Attestation d'imposition (ou non): </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file4">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_attestation_dimposition_ou_non : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file4" class="form-control file-input" (change)="onFileSelected($event,'attestation_dimposition_ou_non')" name="attestation_dimposition_ou_non" [ngModel]="data ? data.file_attestation_dimposition_ou_non : '' "  />
                            </label>
                          </div>
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels">Cértificat de résidence: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file5">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_certificat_de_residence : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file5" class="form-control file-input" (change)="onFileSelected($event,'certificat_de_residence')" name="certificat_de_residence" [ngModel]="data ? data.file_certificat_de_residence : '' " />
                            </label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels mt-2">Cértificat de bonne vie & moeurs: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file6">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_certificat_de_bonne_vie_et_moeurs : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file6" class="form-control file-input" (change)="onFileSelected($event,'certificat_de_bonne_vie_et_moeurs')" name="certificat_de_bonne_vie_et_moeurs" [ngModel]="data ? data.file_certificat_de_bonne_vie_et_moeurs : '' " />
                            </label>
                          </div>
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels mt-2">Cértificat de visite contre visite: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file7">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_certificat_de_visite_contre_visite : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file7" class="form-control file-input" (change)="onFileSelected($event,'certificat_de_visite_contre_visite')" name="certificat_de_visite_contre_visite" [ngModel]="data ? data.file_certificat_de_visite_contre_visite : '' " />
                            </label>
                          </div>
                        </div>
                      </section>
                    
                      <br>
                    
                      <legend>Justificatif civile</legend>
                      <section class="form-section" style="border: 0.5px solid grey; padding: 10px;">
                        <div class="row">
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels">Acte de mariage: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file8">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_acte_de_mariage : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file8" class="form-control file-input" (change)="onFileSelected($event,'acte_de_mariage')" name="acte_de_mariage" [ngModel]="data ? data.file_acte_de_mariage : '' " />
                            </label>
                          </div>
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels">Extrait de naissance du (de la) conjoint (e): </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file9">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_extrait_de_naissance_du_de_la_conjoint_e : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file9" class="form-control file-input" (change)="onFileSelected($event,'extrait_de_naissance_du_de_la_conjoint_e')" name="extrait_de_naissance_du_de_la_conjoint_e" [ngModel]="data ? data.file_extrait_de_naissance_du_de_la_conjoint_e : '' "  />
                            </label>
                          </div>
                          <div class="col-12 col-md-4 mb-3">
                            <label class="fieldlabels">Extrait de naissance des enfants: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file10">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_extrait_de_naissance_des_enfants : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file10" class="form-control file-input" (change)="onFileSelected($event,'extrait_de_naissance_des_enfants')" name="extrait_de_naissance_des_enfants" [ngModel]="data ? data.file_extrait_de_naissance_des_enfants : '' " />
                            </label>
                          </div>
                        </div>
                      </section>
                    
                      <br>
                    
                      <legend>Justificatif raison financière</legend>
                      <section class="form-section" style="border: 0.5px solid grey; padding: 10px;">
                        <div class="row">
                          <div class="col-12 col-md-6 mb-3">
                            <label class="fieldlabels">Piece ou documents justifiant des investissements au Sénégal: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file11">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_piece_investissement : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file11" class="form-control file-input" (change)="onFileSelected($event,'piece_investissement')" name="piece_investissement" [ngModel]="data ? data.file_piece_investissement : '' " />
                            </label>
                          </div>
                          <div class="col-12 col-md-6 mb-3">
                            <label class="fieldlabels">Piece justifiant des activités professionnelles au Sénégal et revenus: </label>
                            <label class="custom-file-upload justify-content-center m-auto" for="file12">
                              <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                              <div class="text text-nowrap"><span class="file-label">{{data ? data.file_piece_travail : 'Importer un fichier' }}</span></div>
                              <input type="file" id="file12" class="form-control file-input" (change)="onFileSelected($event,'piece_travail')" name="piece_travail" [ngModel]="data ? data.file_piece_travail : '' " />
                            </label>
                          </div>
                        </div>
                      </section>
                    </div>
                    
                    <input type="button" name="next" class="next action-button" value="Suivant" /> &nbsp;
                    <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />

                  </fieldset>

                  <fieldset class="step3" >
                    <div class="form-card">
                        <div class="d-flex flex-column col-lg-6  col-md-6 m-auto ">
                          <div>
                            <label class="fieldlabels">Numéro de dossier <span class="text-danger">*</span>: </label>
                            <input  type="text" class="form-control" name="numero_de_dossier"  [ngModel]="data ? data.numero_de_dossier : '' "  required/>
                          </div>
                          <div>

                            <div class="col d-flex  justify-content-between " >
                              <div>
                                <label class="fieldlabels">Date d'arrivée <span class="text-danger">*</span>: </label>
                                <input  type="date" class="form-control "  name="date_d_arrivée" [ngModel]="data ? data['date_d_arrivée'] : '' " required/>
                              </div>

                              <div>
                                <label class="fieldlabels">Numéro de courrier : </label>
                                <input class="form-control" type="text" name="numero_de_courrier"  [ngModel]="data ? data.numero_de_courrier : '' " />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <input type="submit" name="next" class=" action-button" value="Enregistrer"  />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                </fieldset>

              </form>
          </div>


      </div>
  </div>
</div>
