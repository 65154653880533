<app-public-header *ngIf="me == null" ></app-public-header>
<body>
    <!-- Navbar -->
    <!-- Your navbar code goes here -->

    <!-- Hero Section -->
    <!-- <header class="hero text-center py-5" id="home"> -->
        <!-- <div class="container" style="z-index: 1;">
            <h1 class="display-4 text-white mb-3 animate__animated animate__fadeIn animate__delay-1s"> {{'PUBLIC.header_title' | translate}}</h1>
            <p class="lead text-white mb-4 animate__animated animate__fadeIn animate__delay-2s">{{'PUBLIC.header_sub_title' | translate}}</p>
            <a class="btn btn-lg animate__animated animate__fadeIn text-light animate__delay-3s p-2" href= "{{me== null? '#services' : '#/X/demandes'}} "  style="background-color: #3a4c6f;">{{'PUBLIC.btn_service' | translate}}</a>
        </div> -->
        <!-- <carousel
        class="carousel"
        [images]="images"
        [height]="'100%'"
        [cellsToShow]="1"
        [loop]="true"
        [autoplay]="true"
        [autoplayInterval]="5000"
        [transitionDuration]="1000"
        [dots]="true"
        [objectFit]="'cover'"
        [transitionTimingFunction]="'ease-in-out'"
        
      >
      
     
      </carousel> -->
        
        
        
    <!-- </header> -->
    <div class="bd-example">
     
      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="7000">
        <ol class="carousel-indicators">
          <!-- générer dynamiquement les indicateurs -->
          <li *ngFor="let item of bannerData; let i = index" 
            class="m-1"
              [attr.data-target]="'#carouselExampleCaptions'" 
              [attr.data-slide-to]="i" 
              [class.active]="i === 0">
          </li>
        </ol>
      
        <div class="carousel-inner">
          <!-- générer dynamiquement les éléments du carrousel -->
          <div *ngFor="let item of bannerData; let i = index" 
              class="carousel-item" 
              [class.active]="i === 0">
            
            <!-- Si l'élément est une image, affichez l'image -->
            <ng-container *ngIf="item.is_image ==1; else video">
              <img [src]="item.image" class="d-block w-100" [alt]="item.title">
              <div class="carousel-caption">
                <h5>{{ item.title }}</h5>
                <p>{{ item.description }}</p><br>
                <a (click)="callAction(item.action)" class="btn">
                  {{ 'PUBLIC.btn_service' | translate }} 
                  <i class="fas fa-angle-double-right text-light" aria-hidden="true" style="font-size: 25px; margin-left: 15px;"></i>
                </a>
              </div>
            </ng-container>

            <!-- Si l'élément est une vidéo, affichez la vidéo -->
            <ng-template #video>
              <video #videoPlayer class="d-block w-100" autoplay muted loop playsinline>
                <source src="{{item.video_src}}" type="video/mp4">
                impossible de lire la vidéo.
              </video>
              
            </ng-template>
            
          </div>

        </div>
      
        <!-- Contrôles du carrousel -->
        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      
    </div>
    
    

    <!-- Services Section -->
    <section id="services" class="py-5 bg-light">
     
      <section class="section-services">
        <div class="container">
          <div class="row justify-content-center text-center">
            
            <div class="col-md-10 col-lg-8">
              <div class="header-section">
                <ngx-ui-loader [loaderId]="'service-loader'"></ngx-ui-loader>
                <h2 class="title">{{'PUBLIC.services' | translate}}</h2>
                <p class="description">{{'PUBLIC.sub_label_services' | translate}}</p>
              </div>
            </div>
          </div>
          <div class="row">
           
            <!-- Single Service -->
            
            <div class="col-md-6 col-lg-4" *ngFor="let operation of List_operations | paginate: { itemsPerPage: 12, currentPage: pageservices, id: 'services' }" (click)="openService(operation)">
              
              <div class="single-service">
                
                <div class="content">
                  <span class="icon">
                    <i class="fab fa-battle-net"></i>
                  </span>
                  <h3 class="title">{{operation.name}}</h3>
                  <p class="description"><span>{{operation?.organization?.trading_name}}</span></p>
                  <a class="learn-more">{{'PUBLIC.request_demande' | translate}}</a>
                </div>
                <span class="circle-before"></span>
              </div>
            </div>
            <!-- / End Single Service -->
          </div>

        </div><br><br>
        <div class="text-center more align-items-center justify-content-center ">
          <a (click)="moreServices()" class="btn ">{{'PUBLIC.more' | translate}} <i class="fas fa fa-angle-double-right text-light" aria-hidden="true" style="font-size: 25px;margin-left:15px;"></i></a>
          <!-- <a (click)="moreServices()" class="btn m-auto btn-lg animate__animated animate__fadeIn text-light animate__delay-3s p-2" style="background-color: #D7A449;">{{'PUBLIC.more' | translate}} <i class="fas fa fa-angle-double-right text-light" aria-hidden="true" style="font-size: 25px;margin-left:15px;"></i></a> -->
        </div>
        
      </section>

    </section>

    <!-- À Propos Section -->
    <section id="about">
        <div class="container about-section" >
            <div class="d-flex justify-content-between flex-wrap">
                <!--Content Column-->
                <div class="content-column col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <div class="inner-column">
                        <div class="sec-title">
                            <div class="title">{{'PUBLIC.about_ejustice' | translate}}</div>
                            <h2>{{'app_name' | translate}}</h2>
                        </div>
                        <div class="text">
                          {{'PUBLIC.txt_about_ejustice' | translate}}
                        </div>
                        <div class="text">Email: <span class="theme_color">ejustice@contact.sn</span></div>
                        <div class="text">Téléphone: <span class="theme_color">33-820-01-02</span></div>
                    </div>
                </div>

                <!--Image Column-->
                <div class="image-column align-center text-center">
                    <div class="inner-column align-center text-center" >
                        <div class="image shadow">
                            <img src="../../../../../assets/images/ejustice.png" width="100%" alt=""> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Footer -->
    <footer *ngIf="me == null" class=" text-white text-center py-4">
      <p>&copy; {{'footer' | translate}}</p>
      <div>
          <a href="#" class="text-white mx-2"><i class="fab fa-facebook-f"></i></a>
          <a href="#" class="text-white mx-2"><i class="fab fa-twitter"></i></a>
          <a href="#" class="text-white mx-2"><i class="fab fa-instagram"></i></a>
      </div>
    </footer>
    <!-- <ngx-ui-loader></ngx-ui-loader> -->
</body>
