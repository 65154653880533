import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { AlertesService } from '../../../services/public/alertes.service';
import { PublicService } from '../../../services/public/public.service';
import * as $ from 'jquery';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-profil-public',
  templateUrl: './profil-public.component.html',
  styleUrls: ['./profil-public.component.scss']
})
export class ProfilPublicComponent {
  MY_INFORMATIONS: any

  USER_PP:any

  MY_EXTERNAL_ID: any
  MY_EXTERNAL_REFS: any = [{name:'',value:'',icon:''}]
  MY_IDENTIFICATION_RATE: any = []
  MY_REQUIREMENT_RATE: any = []

  ADD_NEW_REFERENCE:boolean = false

  newExternalForm: FormGroup
  newExternalFormControl: any

  USER_ID:any = null
  is_mobile = false

  constructor(private _formBuilder: FormBuilder,
              private publicService: PublicService,
             private ngxService: NgxUiLoaderService,
              private alerte: AlertesService) {


    this.getMyInformations()
    this.newExternalFormControl = {
      icon: new FormControl('', Validators.required),
      key: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required)
    }
    this.newExternalForm = this._formBuilder.group(this.newExternalFormControl);
  }
  firstFormGroup: FormGroup = this._formBuilder.group({firstCtrl: ['']});
  secondFormGroup: FormGroup = this._formBuilder.group({secondCtrl: ['']});

  ngOnInit(): void {

    
  }
  async getMyInformations(){
    this.ngxService.start()
    try {
      await this.publicService.getMyInformation().subscribe((res:any) => {
        this.MY_INFORMATIONS = res
        console.log('allinf', this.MY_INFORMATIONS );
        
        this.MY_EXTERNAL_ID = this.MY_INFORMATIONS.detail.ext_id
        this.getMyExternalRef(this.MY_EXTERNAL_ID)
        this.getIdentifications(this.MY_EXTERNAL_ID)
        this.getRequirements(this.MY_EXTERNAL_ID)
  
        //console.log(res)
      });
      this.ngxService.stop()
    } catch (error) {
      this.ngxService.stop()
      this.alerte.errorNotification("Erreur chargement", "une erreur est survenue lors du chargement des données ")
    }

   

  }

  getMyExternalRef(value:any){
    this.publicService.getMyExternalREf(value).subscribe((res:any) => {
      this.MY_EXTERNAL_REFS = res
      //console.log(res)
    })
  }

  envoiFichier(e:any){

    //console.log(e.target.files)
    this.convertFile(e.target.files[0]).subscribe(base64 => {
      this.USER_PP = base64;
      //console.log(this.USER_PP)
      this.updateProfilPicture()
    });
  }

  getPPUser(){
    console.log('mydientifff',this.MY_IDENTIFICATION_RATE);
    try {
      const REST = this.MY_IDENTIFICATION_RATE.find((b:any) => b.Identifications_custom?.find((c:any) => c.used_key =='pi_img'))
      if(REST?.Identifications_custom[0] != undefined){
        this.USER_PP = REST.Identifications_custom[0]?.value
        //console.log(this.USER_PP)
      }
    } catch (error) {
      console.log('error getppuser', error);
      
    }
    
   

  }

  updateProfilPicture(){
   let body= {
          "my_customer_id": this.MY_EXTERNAL_ID,
          "customer_indentifications": {
                  "pi_img": {
                       "value": this.USER_PP,
                        "issuing_authority": "KYC",
                        "issuing_date": "",
                        "expiring_date": ""
                    }
              }
      }
    this.publicService.updateCustomerIdentification(body).subscribe((res:any) => {
    this.alerte.successNotification('Profil','Succes')
      //console.log(res)
    })
  }

  convertFile(file : File) : Observable<any> {
    const result = new ReplaySubject<any>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event:any) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  getIdentifications(value:any){
    this.publicService.getIdentifications(value).subscribe((res:any) => {
      this.MY_IDENTIFICATION_RATE = res

      //console.log(res)
      this.getPPUser()
    })
  }

  getRequirements(value:any){
    this.publicService.getUserRequirements(value).subscribe((res:any) => {
      this.MY_REQUIREMENT_RATE = res
      console.log(res)
    })
  }

  Annuler (){this.ADD_NEW_REFERENCE = !this.ADD_NEW_REFERENCE}

  AddNewRef(){
    if(this.ADD_NEW_REFERENCE == true){
      this.newExternalForm.value['ext_id'] = this.MY_INFORMATIONS.detail.ext_id
      console.log(this.newExternalForm.value)
      this.publicService.createExternalRef(this.newExternalForm.value).subscribe( (res:any) => {
        this.alerte.successNotification('Nouvelle reference', 'Ajout avec success')
        this.getMyExternalRef(this.MY_EXTERNAL_ID)
      })
    }


    this.ADD_NEW_REFERENCE = !this.ADD_NEW_REFERENCE

  }
}
