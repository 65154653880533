<div class="center">
  <div class="w-70 text-center">
    <h5 id="heading" class="text-light">
      Ajout piéce <span *ngIf="num_courrier != null">- courrier N°: {{num_courrier}}</span>
    </h5>
    <button type="button" class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
      <i class="fas fa-times text-light" style="font-size: 1.5rem;"></i>
    </button>
  </div><br>

  <form #addPiece="ngForm" (submit)="createPiece(addPiece)">
    <div class="d-flex flex-column flex-md-row justify-content-between ">
      <div class="d-flex flex-column flex-grow-1 mb-3 m-auto justify-content-center ">
        <div class="inputbox" style="padding: 0 5px 0 0;">
          <input type="text" class="form-control" name="file_name" ngModel required>
          <span>Saisissez le nom de la pièce</span>
        </div>

        <div class="inputbox" *ngIf="num_courrier != null">
          <input type="text" [value]="num_courrier" [readOnly]="num_courrier != null" class="text-light opacity-2 form-control" name="numero_courrier">
        </div>

        <div class="inputbox" *ngIf="num_courrier == null" style="padding: 0 5px 0 0;">
          <input type="text" class="form-control" name="numero_courrier" ngModel required>
          <span>Numéro courrier</span>
        </div>
      </div>

      <div class="zone flex-grow-1 col-6 mb-3" style="padding: 0 0 0 5px;">
        <div id="dropZ" class="text-center border p-3">
          <i class="fa fa-cloud-upload"></i>
          <div>Déposer ici</div>
          <span>OU</span>
          <div class="selectFile m-auto align-items-center text-center justify-content-center">
            <label class="file-label btn btn-primary text-center col-sm-10 col-md-10" for="file" style="font-size: 13px;margin-left:8%">Importer</label>
            <input type="file" class="file-input d-none" id="file" (change)="onFileSelected($event)" >
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      <button class="btn bg-success text-light">Enregistrer</button>
    </div>
  </form>
</div>

<script>
  $(document).bind('dragover', function (e) {
    var dropZone = $('.zone'),
        timeout = window.dropZoneTimeout;
    if (!timeout) {
        dropZone.addClass('in');
    } else {
        clearTimeout(timeout);
    }
    var found = false,
        node = e.target;
    do {
        if (node === dropZone[0]) {
            found = true;
            break;
        }
        node = node.parentNode;
    } while (node != null);
    if (found) {
        dropZone.addClass('hover');
    } else {
        dropZone.removeClass('hover');
    }
    window.dropZoneTimeout = setTimeout(function () {
        window.dropZoneTimeout = null;
        dropZone.removeClass('in hover');
    }, 100);
});
</script>
