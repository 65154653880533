import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CasierService {

  Doctype_casier ='casier'
  Doctype_casier_type ='Type de casier'

  constructor(private http: HttpClient, private frapper: FrapperService, private assigneService:AssignedService,private authservice: AuthService) {}

 // Jugements
  async getLastNumberCasier(type_de_casier='B3') {
    try {
      const body= {type_de_casier:type_de_casier}
      //console.log('body ==> ',body)
      let infos = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_last_number_casier',body).toPromise()
      return infos
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getAllMyCasier(filter=null) {
    try {
      let my_jugements = await this.frapper.GetDoctypes(this.Doctype_casier,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_jugements
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getAllCasierType(filter=null) {
    try {
      let type_casier = await this.frapper.GetDoctypes(this.Doctype_casier_type,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return type_casier
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getOneCasier(jugementName) {
    try {
      let my_jugement = await this.frapper.GetDoctype(this.Doctype_casier,jugementName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_jugement
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getOneTypeCasier(jugementName) {
    try {
      let my_jugement = await this.frapper.GetDoctype(this.Doctype_casier_type,jugementName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_jugement
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }
  async nouveauCasier(body) {
    try {
      let new_jugements = await this.frapper.CreateDoctype(this.Doctype_casier,body).toPromise()
      return new_jugements
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_casier,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
  
  }

  async updateCasier(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_casier,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_casier,body.name,body.user)
    return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

 // ** Casier
}
