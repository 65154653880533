import { Component, Input } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertesService } from '../../services/public/alertes.service';
import {EnrollementService} from "../../services/frapper/enrollement.service";
import { JugementsService } from '../../services/frapper/jugements.service';

@Component({
  selector: 'app-add-infractions',
  templateUrl: './add-infractions.component.html',
  styleUrls: ['./add-infractions.component.scss']
})
export class AddInfractionsComponent {
  @Input() parentModal: BsModalRef;


  data: any;
  type:any;
  label_default: any;
  modalRef!: BsModalRef;


  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private formBuilder: FormBuilder,
              private enrolementService:EnrollementService,
              private jugementService:JugementsService,
              private ngxService: NgxUiLoaderService,
              private alerte:AlertesService,

              public bsModalRef: BsModalRef,

             ) {

    this.data = options.initialState.data
    this.type = options.initialState.type
    console.log('the type:', this.type);

    this.label_default = options.initialState.label

  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }
  closeModal(){
    this.bsModalRef.hide()
  }

  AddNewItem(infraction: NgForm){
    this.ngxService.start();
    let body_content = infraction.value
    switch (this.type) {
      case "infraction":
        try {
          this.enrolementService.nouvelleInfraction(body_content).then(res =>{
            this.ngxService.stop();
            this.closeWithMessage('success')
          },err=>{
            this.ngxService.stop();
          })
        } catch (error) {
          this.closeWithMessage('error')
        }
       
        break;

      case "decision":
        try {
          this.jugementService.nouveauDecision(body_content).then(res =>{
            this.ngxService.stop();
            this.closeWithMessage('success')
          },err=>{
            this.ngxService.stop();
          })
        } catch (error) {
          this.closeWithMessage('error')
        }
       
        break;

      case "categorie":
        try {
          this.jugementService.nouvelleCategorie(body_content).then(res =>{
            this.ngxService.stop();
            this.closeWithMessage('success')
          },err=>{
            this.ngxService.stop();
          })
        } catch (error) {
          this.closeWithMessage('error')
        }
        
        break;
    
      default:
        break;
    }
    
    //console.log('body=>', body_content)



  }
  ngOnInit() {


    var self = this;


  }
}
