import { Component, EventEmitter, Input, Output } from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder, NgForm} from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {error} from "@angular/compiler-cli/src/transformers/util";
import { AddPieceComponent } from '../add-piece/add-piece.component';
import {AlertesService} from "../../services/public/alertes.service";
import {CustomService} from "../../services/frapper/custom.service";
import {ViewDetailsCourrierComponent} from "../view-details-courrier/view-details-courrier.component";
import { MyCONST } from '../../CONST/myConst';
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';
import { url } from 'inspector';
import {RegistreService} from "../../services/frapper/registre.service";


@Component({
  selector: 'ngx-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss']
})
export class FormStepperComponent {
  @Input() parentModal: BsModalRef;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  isLinear = true;
  courrierArrivee: FormGroup;
  courrierDepart: FormGroup;
  data: any;
  modalRef!: BsModalRef;
  next_number_corrier:any ={last_dossier: '', new_document_number: 'TC/001/2024'}
  isSubmitableForm: any;
  classifications:any =[];
  registres:any =[];
  identification_type:any =[];
  natures:any =[];
  reference: string = 'Non';
  numero_courrierOrigine: string;
  my_tribunal:any = null;
  content_msg: any = "";
  imageFile:any ={}
  imageName:any={}
  name_piece_1='No name'
  name_piece_2='No name'
  name_piece_3='No name'
  visualize_piece_1 = false
  visualize_piece_2 = false
  visualize_piece_3 = false
  nbr_courrier =null;

  selectedRegisre:any
  new_document_number:"00/0000"
  informationRegistre:any


  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private formBuilder: FormBuilder,
              private userCourrierService:UsercourrierService,
              private customservice:CustomService,
              private registreService: RegistreService,
              private alerte: AlertesService,
              public bsModalRef: BsModalRef,
              private penalService: PenalCourrierService,
              private ngxService: NgxUiLoaderService) {
    this.data = options.initialState
    this.isSubmitableForm = options.initialState.isSubmitableForm ?? true;
    this.numero_courrierOrigine = this.data.courrierOrigine
    console.log('yyuyuy'+ this.numero_courrierOrigine);
    try {
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
    }catch (e) {
      this.my_tribunal =null
    }
  }


  getFileNameFromPath(filePath) {
    if (typeof filePath !== 'string') {
        throw new Error('Le chemin de fichier doit être une chaîne de caractères.');
    }
    // retourner  le nom du fichier
    return filePath.substring(filePath.lastIndexOf("\\") + 1);
  }

  closeModal(){
    this.bsModalRef.hide()
  }

  onFileSelected(event: any,key) {

    let image:any = event.target.files[0] as File;
    console.log('this.imageFile ==> ',this.imageFile)
    this.createPiece(image,key)

    this.AddNameToPiece(event,key,this.getFileNameFromPath(event.target.value))
  }
  AddNameToPiece(event: any,key,default_name:string = null){
    let new_name = default_name != null ? default_name: event.target.value
    this.imageName[key] = new_name;
    switch (key) {
      case 'name_piece_1':
        this.visualize_piece_1 = true
        break;
        case 'name_piece_2':
        this.visualize_piece_2 = true
        break;
        case 'name_piece_3':
        this.visualize_piece_3 = true
        break;

      default:
        this.visualize_piece_1,this.visualize_piece_2,this.visualize_piece_3 = false;
        break;
    }

    const ImagesCharged = Object.keys(this.imageFile);
    console.log('the name piece: ',this.imageName[key] );

  }

  openModalVisualPiece(key) {
    const URL=MyCONST.API_URL+this.imageFile[key]
    console.log('urllll ', URL);
    const config: ModalOptions = {
      initialState: {
        data: URL,
        url:URL,

        parentModal : null
      }
    }



    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    // Mettre à jour l'option initialState avec le modalRef
  }
  createPiece(file: File | null, key: number) {
    if (!file) {
      this.alerte.errorNotification('Pieces', 'Aucun fichier sélectionné!');
      return;
    }

    this.ngxService.startLoader('form_step')

    const formData1 = new FormData();
    formData1.append('is_private', '0');
    formData1.append('file', file);

    this.customservice.createFiles(formData1).then(res => {
      // Mettre à jour l'URL de l'image
      this.imageFile[key] = res.message.file_url;
       this.ngxService.stopLoader('form_step')
    }).catch(err => {
       this.ngxService.stopLoader('form_step')
      this.alerte.errorNotification('Pieces', 'Une erreur s\'est produite!');
    });
  }


  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg '}));
  }

  openModalCourrierDetails(data: any) {
    // let courrier:any = this.courriers[id];
    // console.log('details === '+ courrier);
    const config: ModalOptions = {
      initialState: {
        data: data,
        type: 'courrier'
      }
    }
    this.modalRef = this.modalService.show(ViewDetailsCourrierComponent, Object.assign({},config,{class:'modal-xl '}));
  }

  generateRandomString(): any {
    const length = 15;
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result
  }


  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }
  createNewCourrier(courrier: NgForm){
    this.ngxService.start()
    /*if(this.data.typeCourrier=='entrant'){
      courrier.value["registre"]= "Courrier Arrivée"
    }
    if(this.data.typeCourrier=='sortant'){
      courrier.value["registre"]= "Courrier Depart"
      //courrier.value["workflow_state"]= "Expédiée"
    }*/
    if(this.my_tribunal !=null){
      courrier.value["tribunal"]= this.my_tribunal.name
    }

    courrier.value["docstatus"]= 1

    courrier.value["numero_public"] = this.generateRandomString()

    courrier.value["categorie"]= this.selectedRegisre.is_exit_registre==1? 'sortant':'entrant'
    courrier.value["creation_unite"]= this.selectedRegisre.bureau
    courrier.value["position_unite"]= this.selectedRegisre.bureau

    console.log('courrier ===> ',courrier.value)
    let body_piece_content:any={}
    const ImagesCharged = Object.keys(this.imageFile);
    let body_name_piece_courrier=[]
    var values_to_upload = []
    for (let key of ImagesCharged){
      body_piece_content[key] = this.imageFile[key]
      console.log('data piece: ', body_piece_content[key], this.imageFile[key] );

      let add ={
        "nom_du_fichier": this.imageName[key],
        "fichier": body_piece_content[key],
        "parent": null,
        "parenttype":this.userCourrierService.Docatype_courrier_principal,
        "doctype": "Fichiers joins a un dossier"
      }
      values_to_upload.push(add)
    }
    //console.log('imageName ==> ', this.imageName)
    //console.log('body_piece_content ==> ', body_piece_content)
    //console.log('value_to_upload ==> ', values_to_upload)

    //return
   
    if (this.isSubmitableForm) {
      try {
        console.log('For calling api ==> ')
        this.userCourrierService.nouveauCourrier(courrier.value).then( res => {
          let courrier_name = res.data.name
           for (let val of values_to_upload){
             val['parent']=res.data.name
           }
           let toUpDate={}
           toUpDate['pieces_jointe_au_courrier']= values_to_upload
           this.customservice.updateTheDoctype(this.userCourrierService.Docatype_courrier_principal,courrier_name, toUpDate).then(res =>{
             //console.log('Succe ==> ',res)
             this.userCourrierService.updateSatate(courrier_name,'Bureau courrier').then(res => {
              //  this.openModalSuccessDialog('Courrier enregistrer ')
              this.alerte.successNotification('Courrier','Courrier enregistrer!')
                this.ngxService.stop()
               this.closeWithMessage('success');
  
               this.openModalCourrierDetails(res.data)
             },err =>{
                this.ngxService.stop()
              //  this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')
               console.log('Error ==> ',err)
             })
             //this.openModalSuccessDialog('Piece Ajoutee')
              this.ngxService.stop()
           },error=>{
             console.log('IN ADD DOCTYPE ERR ==> ',error)
              this.ngxService.stop()
             this.alerte.errorNotification('Pieces','Une erreur s\'est produite!')
           })
  
          //  this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')
  
  
         return
  
       },
         error =>{
         console.log('Error ==> ',error)
         this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')
  
            this.ngxService.stopLoader('form_step')
         })
      } catch (error) {
        console.log('erroooorrrrr ',error);
  
        this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')
  
      }
    } else {
      // return les valeurs du form pour le modal parent
      // ajout des pieces dans le bodycontent a retourner
      courrier.value['values_to_upload'] = values_to_upload
      this.closeWithMessage(courrier.value)
      this.alerte.successNotification('courrier', 'le courrier est enregistré !')
      this.ngxService.stop()
    }
    

  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    //this.penalService.hiddeModal(null);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm '}));
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  onClassificationChange(event: any) {
    let chaine = event.target.value;
    console.log('this.imageFile ==> ',chaine)
    let nature_filter='[["type_de_courrier" , "=","'+chaine+'"]]'
    this.userCourrierService.getAllowedNatureCourrier(nature_filter).then( res =>{
      //console.log(res.data)
      this.natures = res.data
    })
  }

  onRegistreSelectChange(event: any) {
    this.ngxService.start()
    let registre = event.target.value;
    this.selectedRegisre =  (this.registres.filter(element => element.name == registre ))[0];
    console.log('this.selectedRegisre ==> ', this.selectedRegisre)


   // let nature_filter='[["type_de_courrier" , "=","'+chaine+'"]]'
    this.registreService.getNumInformationRegistre(registre).then(res =>{
      console.log('registre ==> ', res)
      this.nbr_courrier=res.message.count
      this.informationRegistre = res.message
      this.new_document_number = this.informationRegistre.new_courrier_number
      this.ngxService.stop()
    },err=>{
      this.ngxService.stop()
    })
  }

  ngOnInit() {
    this.data.courrierOrigine != null? this.reference = 'Oui' : this.reference = 'Non'
    // this.modalClosed.emit(null);
    try {
      this.ngxService.start()
      this.new_document_number= "00/0000"
      let is_sortant= this.data.typeCourrier=='sortant'?1:0


      let filter =this.my_tribunal==null? '[["is_exit_registre" , "=","'+is_sortant+'"]]' : '[["is_exit_registre" , "=","'+is_sortant+'"],["tribunal" , "=","'+this.my_tribunal.name+'"]]'
      this.registreService.getAllRegistre(filter).then(res =>{
        console.log('REgistre ====> ',res)
        this.registres = res.data

        if(this.registres.length==0){
          this.alerte.warningNotification('Registre Courrier','Vous ne pouvez renseigner aucun registre!')
        }
      },err =>{
        this.alerte.warningNotification('Registre Courrier','Erreur lors de la recuparationn des registres')
      })

      this.userCourrierService.getAllowedClassification().then( res =>{
        //console.log(res.data)
        this.classifications = res.data
      })
      let nature_filter='[["type_de_courrier" , "=",""]]'

      this.userCourrierService.getAllowedNatureCourrier(nature_filter).then( res =>{
        //console.log(res.data)
        this.natures = res.data
      })

      this.ngxService.stop()
    } catch (error) {
      this.ngxService.stop()
    }

    let type_de_courrier='Courrier Arrivée'
    if(this.data.typeCourrier=='sortant'){
      type_de_courrier= "Courrier Depart"
      //courrier.value["workflow_state"]= "Expédiée"
    }
    /*this.userCourrierService.getnumeroCourrier(type_de_courrier).then( res =>{
      console.log(res.message)
      this.next_number_corrier = res.message
     // console.log('---> ',this.next_number_corrier)
    })*/

    this.customservice.getIdentificationType().then(res=>{
      this.identification_type = res.data
      //console.log('identification_type ==> ',this.identification_type)
      //console.log('identification_type ==> ',res)
    })
    console.log({'type courrier': this.data.typeCourrier});
    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;

      setProgressBar(current);
      if ($("fieldset").hasClass("_enrollement")) {
        // Cacher le premier fieldset et afficher le deuxième
        $("fieldset").eq(0).hide();
        $("fieldset").eq(1).show();
        current = 2;

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(1).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });

  $(document).ready(function() {
    $(".file-input").on("change", function() {
        const fileName = $(this).val().split("\\").pop();
        $(this).parent().find('.file-label').text(fileName);

        const parentDiv = $(this).closest('.col-4');
        const textInput = parentDiv.find('input[type="text"]');
        textInput.val(fileName);

        toggleVisualDiv(textInput, parentDiv);
    });

    $('input[type="text"]').on('input', function() {
        const textInput = $(this);
        const parentDiv = textInput.closest('.col-4');

        toggleVisualDiv(textInput, parentDiv);
    });

    function toggleVisualDiv(textInput, parentDiv) {
        const visualDiv = parentDiv.find('div').last();
        if (textInput.val()) {
            visualDiv.show(); // Afficher le div
        } else {
            visualDiv.hide(); // Masquer le div
        }
    }
});

    this.courrierArrivee = this.formBuilder.group({
      classification: new FormControl('', Validators.required),
      registre: new FormControl('', Validators.required),
      numero_courrier: new FormControl('', Validators.required),
      nature: new FormControl('', Validators.required),
      reference: new FormControl('', Validators.required),
      numero_dorigine: new FormControl(null),
      information_de_la_reference: new FormControl(null),
      type_identification: new FormControl('', Validators.required),
      prenom: new FormControl('', Validators.required),
      numero_identification: new FormControl(null),
      nom: new FormControl('', Validators.required),
      pays: new FormControl('', Validators.required),
      region: new FormControl(null),
      adresse: new FormControl(null),
      numero_d_origine: new FormControl(null),
      redaction: new FormControl(null),
      type_identf_expediteur: new FormControl('', Validators.required),
      num_identif_expediteur: new FormControl(null),
      description_doc_fournis: new FormControl(null)
    });
  }
}
