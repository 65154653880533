import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardService,
  CanvasWhiteboardUpdate,
  CanvasWhiteboardOptions,
  CanvasWhiteboardShapeService,
  CircleShape,
  SmileyShape,
  StarShape,
  LineShape,
  RectangleShape
} from "ng2-canvas-whiteboard";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertesService } from '../../services/public/alertes.service';
import { AnnotationService } from '../../services/frapper/annotations.service';
import { CustomService } from '../../services/frapper/custom.service';


@Component({
  selector: 'app-whiteboard-canvas',
  templateUrl: './whiteboard-canvas.component.html',
  
  styleUrls: ['./whiteboard-canvas.component.scss']

})
export class WhiteboardCanvasComponent implements OnInit, OnDestroy {


  private saveInterval: any; 
  my_tribunal:string
  
  libelle_note:string
  defaultname:string
  
  canvasOptions: CanvasWhiteboardOptions = {};
  @ViewChild(CanvasWhiteboardComponent)
  canvasWhiteboardComponent: CanvasWhiteboardComponent;
num_audience:any;
already_saved_name:any;
doctype:any;
note: any = null;
is_new:any = false;
  constructor(
    private modalService: BsModalService,public options: ModalOptions,
        private ngxService: NgxUiLoaderService,
        private alerte:AlertesService,
        private annotationService: AnnotationService,
      
        private bsModalRef: BsModalRef,
        private customservice:CustomService,
    private canvasWhiteboardService: CanvasWhiteboardService,
    private canvasWhiteboardShapeService: CanvasWhiteboardShapeService
  ) {
    this.canvasWhiteboardShapeService.unregisterShapes([
      CircleShape,
      SmileyShape,
      StarShape,
      LineShape,
      RectangleShape
    ]);
    this.num_audience = options.initialState.num_audience
    this.doctype = options.initialState.doctype
    this.note = options.initialState.note
    this.is_new = options.initialState.is_new 
    this.already_saved_name = options.initialState.already_saved_name
  }
  ngOnInit(): void {
    try {
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal')).name
    }catch (e) {
      this.my_tribunal =null
    }
    // active drawing automatic
    this.canvasOptions.drawingEnabled = true;

     this.defaultname = this.note?.titre != null ?  this.note?.titre : "canvasWhiteboardDrawings"
    this.libelle_note = this.note?.titre != null ?  this.note?.titre : "canvasWhiteboardDrawings"
    
  
    // let storage = sessionStorage.getItem(`canvasWhiteboardDrawings_${this.num_audience}`);

    //check if has already content draw
    // if (storage !== null) {

      setTimeout(() => {
        this.loadFromStorage()
      }, 500);
    // }

   // run save every 5 s
    this.saveInterval = setInterval(() => {
      this.saveToStorage();
    }, 3000);

  }
  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }

  ngOnDestroy(): void {
    // clear interval 
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
  }


  _closeModal(){
    this.bsModalRef.hide()
  }

  addName(newName: string): string {
    let name = newName.trim();
    if (!name) {
      return; // Ne rien faire si le champ est vide
    }

    // Vérifier si le nom sans suffixe existe déjà
    if (this.already_saved_name.includes(name)) {
      // Si le nom de base existe, ajouter un suffixe
      let counter = 1;
      let newNameWithSuffix = `${name}(${counter})`;

      // Vérifier les variantes avec suffixe
      while (this.already_saved_name.includes(newNameWithSuffix)) {
        counter++;
        newNameWithSuffix = `${name}(${counter})`;
      }

      // Ajouter le nouveau nom avec suffixe à la liste
      return newNameWithSuffix;
      // this.already_saved_name.push(newNameWithSuffix);
    } else {
      // Si le nom de base n'existe pas, l'ajouter directement
      // this.already_saved_name.push(name);
      return name;
    }
  }

  async createPiece(file: File | null) : Promise<string> {
    let url_file:string;

     this.ngxService.startLoader('form_step')

    const formData1 = new FormData();
    formData1.append('is_private', '0');
    formData1.append('file', file);

    await this.customservice.createFiles(formData1).then(res => {
      console.log('the createfile=>', res.message.file_url);
      
     url_file  = res.message.file_url
      // Mettre à jour l'URL de l'image
      
       this.ngxService.stopLoader('form_step')
    }).catch(err => {
       this.ngxService.stopLoader('form_step')
      // this.alerte.errorNotification('Pieces', 'Une erreur s\'est produite!');
    });

    return url_file;
  }

  async savePermanent() {
    this.ngxService.start()
    if (this.libelle_note != null) {
      const canvasElement: HTMLCanvasElement = this.canvasWhiteboardComponent.canvas.nativeElement;
      let base64_image
      let canvas_text
        // Vérifier si l'élément canvas est disponible et est bien un canvas
        if (canvasElement instanceof HTMLCanvasElement) {

          const updates: Array<CanvasWhiteboardUpdate> = this.canvasWhiteboardComponent.getDrawingHistory();
        
          canvas_text = JSON.stringify(updates);
          console.log('canvastext:', canvas_text);

          // pour l'image
          const imageUrl = canvasElement.toDataURL();
  
            // Extraire la partie Base64 (ignorer le préfixe "data:image/png;base64,")
            const base64Data = imageUrl.split(',')[1];

            // Convertir la chaîne Base64 en un tableau d'octets
            const byteCharacters = atob(base64Data);

            // Créer un tableau d'octets (Uint8Array)
            const byteArray = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteArray[i] = byteCharacters.charCodeAt(i);
            }

            // Créer un Blob à partir du tableau d'octets avec le type MIME image/png
            const blob = new Blob([byteArray], { type: 'image/png' });

            // Créer un objet File à partir du Blob
            const file = new File([blob], this.addName(this.libelle_note), { type: 'image/png' });

            // save piece
            let file_url = await this.createPiece(file)

          console.log('lefile',file);
          // Convertir le canvas en image PNG Base64
          // base64_image = canvasElement.toDataURL('image/png');
          // console.log('Image Base64:', base64_image);


          let content_body = {
            "nom_du_doctype": this.doctype,
            "numero_du_doctype": this.num_audience,
            "titre": this.is_new ? this.addName(this.libelle_note):this.libelle_note,
            
            "contenu": canvas_text,
            "tribunal": this.my_tribunal,
            "image": file_url
            

          }
          console.log('content_body:', content_body);

          if (this.is_new) {
            
            try {
              await this.annotationService.nouvelleAnnotations(content_body).then( (res:any) => {
                console.log('REsult ---> ', res)
                this.ngxService.stop()
              })
              this.closeWithMessage("success")
            this.alerte.successNotification('Notes audience','Notes sauvegardées')
            } catch (error) { 
              this.ngxService.stop()
              console.log('error ---> ', error)
            }
          }else{
            try {
              await this.annotationService.updateAnnotation(this.note.name,content_body).then( (res:any) => {
                console.log('REsult ---> ', res)
                this.ngxService.stop()
              })
              this.closeWithMessage("success");
            this.alerte.successNotification('Notes audience','Notes modifiées')
            } catch (error) { 
              this.ngxService.stop()
              console.log('error ---> ', error)
            }
          }

         
     

          // Télécharger l'image (optionnel)
          // const link = document.createElement('a');
          // link.href = base64Image;
          // link.download = 'mydrawing.png';
          // link.click();
        } else {
          this.ngxService.stop()

          console.error('Canvas non trouvé ou ce n\'est pas un élément canvas!');
        }
    }else{
      this.ngxService.stop()

      this.alerte.warningNotification('remplissez le libelle svp!')
    }
    
  }
  

  saveToStorage(): void {
    // 
    const updates: Array<CanvasWhiteboardUpdate> = this.canvasWhiteboardComponent.getDrawingHistory();
    
    const stringifiedUpdatesArray: string = JSON.stringify(updates);
    sessionStorage.setItem(this.defaultname+`_${this.num_audience}`, stringifiedUpdatesArray);
    
    console.log('Notes saved in storage =>', sessionStorage.getItem(this.defaultname+`_${this.num_audience}`));
    
  }
  
  
  loadFromStorage(): void {
    if(this.note.contenu != null && !sessionStorage.getItem(this.defaultname+`_${this.num_audience}`)) {
      
      this.getNote()
    }else{
       // Get the serialized drawing updates from sessionStorage
      const canvasDrawingsJson: string = sessionStorage.getItem(this.defaultname+`_${this.num_audience}`);
      
      if (canvasDrawingsJson) {
        // Deserialize t
        const updates: Array<CanvasWhiteboardUpdate> = JSON.parse(canvasDrawingsJson);
        
        // show draw
        this.canvasWhiteboardService.drawCanvas(updates);

      } 
    }
   
  }

 
  getNote(): void {
    // Get the serialized drawing updates from sessionStorage
    const canvasDrawingsJson: string = this.note.contenu;
    
    if (canvasDrawingsJson) {
      // Deserialize t
      const updates: Array<CanvasWhiteboardUpdate> = JSON.parse(canvasDrawingsJson);
      
      // show draw
      this.canvasWhiteboardService.drawCanvas(updates);

    } 
  }
  
  
  public changeOptions(): void {
    this.canvasOptions = {
      ...this.canvasOptions,
      fillColorPickerEnabled: false,
      colorPickerEnabled: false
    };
  }
}
