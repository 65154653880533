import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { DashboardPublicComponent } from './dashboard-public/dashboard-public.component';
import { DashboardVerificationDocComponent } from './dashboard-verification-doc/dashboard-verification-doc.component';
import { AuthGuard } from '../../core/guards/auth.guard';  // Assurez-vous que ce garde existe et est correctement importé
import { OperationsComponent } from './operations/operations.component';
import { ProfilPublicComponent } from './profil-public/profil-public.component';
import { TraitementsComponent } from './traitements/traitements.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AllServicesComponent } from './all-services/all-services.component';

const routes: Routes = [
  {
    path: 'X',  // Ce 'X' correspond au préfixe de ton module parent
    component: MainLayoutComponent,
    children: [
      {
        path: 'accueil',
        canActivate: [AuthGuard],
        component: HomePageComponent,
      },
      {
        path: 'demandes',
        canActivate: [AuthGuard],
        component: DashboardPublicComponent,
      },
      {
        path: 'operations',
        canActivate: [AuthGuard],
        component: OperationsComponent,
      },
      {
        path: 'traitements',
        canActivate: [AuthGuard],
        component: TraitementsComponent,
      },
      {
        path: 'profil',
        canActivate: [AuthGuard],
        component: ProfilPublicComponent,
      },
      {
        path: 'document-verification/:code_verification',
        canActivate: [AuthGuard],
        component: DashboardVerificationDocComponent,
      },
      {
        path: 'all_services',
        canActivate: [AuthGuard],
        component: AllServicesComponent,
      },
      {
        path: '',
        redirectTo: 'demandes',  
        pathMatch: 'full',     
      }
      
    ],
  },
];



@NgModule({
  imports: [RouterModule.forChild(routes)],  
  exports: [RouterModule],
})
export class PublicRoutingModule {}
