import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'public-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerText: string = '';

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    // Get the translated text for the 'footer' key
    this.translate.get('footer').subscribe((translatedText: string) => {
      this.footerText = translatedText;
    });
  }
}
