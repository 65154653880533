import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import * as $ from 'jquery';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {AlertesService} from "../../services/public/alertes.service";
import {JugementsService} from "../../services/frapper/jugements.service";
import { DatePipe } from '@angular/common';
import { VisualizeJugementComponent } from '../visualize-jugement/visualize-jugement.component';
import { EditTextComponent } from '../edit-text/edit-text.component';
import {CustomService} from "../../services/frapper/custom.service";
import {ViewDetailsDossierComponent} from "../view-details-dossier/view-details-dossier.component";
import { CalendarComponent } from '../../widgets/calendar/calendar.component';
import { VisualiseDecisionComponent } from '../visualise-decision/visualise-decision.component';
import {DocumentService} from "../../services/frapper/document.service";
import { AddAssociationPrevenuComponent } from '../add-association-prevenu/add-association-prevenu.component';
import { DetailsPrevenuComponent } from '../details-prevenu/details-prevenu.component';
import {AssignedService} from "../../services/frapper/assigned.service";
import { AddInfractionsComponent } from '../add-infractions/add-infractions.component';
import { WhiteboardCanvasComponent } from '../whiteboard-canvas/whiteboard-canvas.component';
import { ListNotesComponent } from '../list-notes/list-notes.component';

@Component({
  selector: 'app-view-details-jugement',
  templateUrl: './view-details-jugement.component.html',
  styleUrls: ['./view-details-jugement.component.scss']
})
export class ViewDetailsJugementComponent {
  @Input() parentModal: BsModalRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  type:any;
  infractions: any;
  objet_jugment:any;
  president_chambre: any;
  assistant_chambre: any;
  concat_tribunal: any = false;
  can_edit_decision: boolean = true;

  enrolle:any = {
    "type_daffaire": "Pénale",
    "numero_du_jugement": "None",
    "chambre": "Aucune",
    "date_du_jugement": "None"
  };
  jugement:any;
  modalRef!: BsModalRef;
  selectedInfractions: string[] = [];
  Infractions: any[] = [];
  Amendes: any[] = [];
  Emprisonements: any[] = [];
  ContrainteParCorps: any[] = [];
  affiliations: any[] = [];

  TypeDeCondamnation: any[] = [];
  CategorieDeCondamnation: any[] = [];
  is_multiple_prevenu: number ;

  numero_du_jugement=null
  Decision_Judiciaire = null
  Date_decision = null
  Categorie_Decision = null
  showDefault: boolean = true;
  showFactum: boolean = false;
  type_report: string;
  demandeur: string;
  date_report: string;
  raison_report: string;
  prevenusList: any[] = [];
  status_jugement: string = "";
  validation_du_juge =0
  IS_CLOS=0
  decision: string = ""
  categorie: string = ""
  identification_type:any =[];

  ALLOWED_DECISION_FINAL = false
  ALLOWED_SAVE_DECISION = false
  profil
  type_jugement_reports=[
    {name:'Repport pour audience',need_reenrolement:1,is_radiation:0 , is_waiting_deliberation:0},
    {name:'Repport pour vider',need_reenrolement:1,is_radiation:0,is_waiting_deliberation:1},
    {name:'Vider',need_reenrolement:0,is_radiation:0,is_waiting_deliberation:0},
    {name:'Radiation',need_reenrolement:0,is_radiation:1,is_waiting_deliberation:0},
  ]
  my_tribunal:any = null

  jugement_reports=[]



  CAN_SEE_DOCUMENTS = false
  CAN_SEE_TEMPLATE = false
  CAN_DO_ACTION = true
  CAN_APPROUVE = false
  CAN_CLORE = false


  list_jugement: any = [
    {
      'id': 1,
      'name': 'template1',
      'template_name': 'Certificat administration legale',

      'image': 'https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg',
      'template': `
      <div class="second" >
      <section class="header d-flex justify-content-between"  >
      <div>
        <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
        </p>
        <b>République du Sénégal <br> Cour d'appel de dakar <br> $_tribunal_$ </b>
      </div>



        <div>
          <p class="mt-1" >
            <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
          </p>
          <b>N°$_numero_de_jugement_$<br>Du $_date_de_jugement_$ </b>

        </div>


      </section>

    </div>
      <div style="font-family: Platypi, serif;">
      <section class="d-flex justify-content-between second mycontent">
      <main style="font-family: Platypi, serif;">
      <section class="title text-center" style="font-family: Platypi, serif;">
          <h4>CERTIFICAT D’ADMINISTRATION LEGALE </h4>
          <p><hr></p>
      </section>

      <section>
          <p>Nous, Mr/Mme <strong>$_name_pdt_tribunal_$</strong>, Présidente du $_tribunal_$ (Sénégal), en notre cabinet sis au Palais de Justice de ladite ville ;</p>
          <p><strong>Vu</strong> la requête écrite en date <strong>$_date_requette_$</strong> présentée par Mr/Mme <strong>$_nom_complet_plaignant_$</strong> né(e) le <strong>$_date_naiss_plaignant_$</strong> à Dakar demeurant à ..........., aux fins d’obtenir un certificat d’administration légale ; </p>
          <p><strong>Vu</strong> les réquisitions du délégué du procureur en date du<strong> $_date_requisition_$</strong>;</p>
          <p><strong>Vu</strong> les articles 277, 278, 279, 300 et suivants du Code de la Famille ;  </p>
      </section>

      <section>
          <p style="text-decoration: underline;"><strong>Et sur le vu des documents ci-après :</strong></p>
          <ul>
              <li>La photocopie de la carte d’identité n°<strong> $_identification_value_$</strong> du <strong>.............</strong> ;</li>
              <li>Le certificat de mariage n° <strong>$_numero_certif_mariage_$</strong> de l’année <strong>$_date_certif_mariage_$</strong> délivré par l’officier $_nom_officier_$;</li>
              <li>Le certificat de décès n°<strong> $_numero_certif_dece_$</strong> de l’année <strong>$_date_certif_mariage_$</strong> délivré par $_lieu_delivrance_$ ;</li>
              <li>L’extrait de naissance de l’enfant :</li>
              <li><strong>$_nom_de_enfant_$ </strong>, acte de naissance n°<strong> $_numero_acte_naiss_$</strong> de l’année <strong>$_annee_de_acte_naiss_$</strong> délivré par l’officier du $_lieu_delivrance_acte_naiss_$ ;</li>
              <li>Le certificat de domicile de l’enfant délivré le <strong>$_date_delivrance_cdomicile_$</strong> ;</li>
              <li>Le certificat de domicile de la requérante délivré le<strong> $_date_delivrance_cdomicile_$</strong> ;</li><br>
              <h6>CERTIFIONS QUE :<br></h6>
              <p>Mr/Mme <strong>$_nom_complet_plaignant_$</strong> exerce la puissance paternelle sur l’enfant mineur  :</p>
              <li><strong>$_nom_de_enfant_$ </strong>, né le <strong>$_date_naiss_enfant_$</strong> à Dakar ;</li>
              <p>Issu de son union avec feu <strong> $_nom_du_mari_$</strong>, décédé à $_lieu_dece_$ le <strong>$_date_dece_$ </strong>;</p>
              <p>En conséquence <strong> Mr/Mme $_nom_complet_plaignant_$</strong> administre légalement les biens du dit enfant ;</p>
              <p>En foi de quoi, nous avons dressé, signé et délivré le présent certificat légal à Mr/Mme <strong>$_nom_complet_plaignant_$</strong>, pour servir et valoir ce que de droit.</p>
          </ul>
      </section>




      <div class="float-right">
      <div class="date">
      <p>Fait et délivré en notre cabinet, le $_date_de_decision_$</p>
      </div>

      <div class="signature center">
          <p style="text-decoration: underline;">LA PRESIDENTE</p>
      </div>
      </div>
    </main>

        </section>
      </div>
      `
    },
    {
      'id': 2,
      'name': 'template2',
      'template_name': 'Certificat décé',
      'image': 'https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg',
      'template': `
      <div class="second" >
      <section class="header d-flex justify-content-between"  >
      <div>
        <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
        </p>
        <b>République du Sénégal <br> Cour d'appel de dakar <br> $_tribunal_$ </b>
      </div>



        <div>
          <p class="mt-1" >
            <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
          </p>
          <b>N°$_numero_de_jugement_$<br>Du $_date_de_jugement_$ </b>

        </div>


      </section>

    </div>
      <div>
      <section class="d-flex justify-content-between second mycontent">

          <main>
          <section class="title text-center">
              <h3><em><u>AUDIENCE PUBLIQUE ORDINAIRE</u></em></h3>
              <h5><em><u>JUGEMENT D’AUTORISATION D’INSCRIPTION DE DECES</u>  </em></h5>
          </section>

          <section>
              <p>Le  $_tribunal_$(Sénégal), statuant en matière civile a, en son audience  du <strong> $_date_de_jugement_$ </strong> à  laquelle siégeait  <strong>Mr/Mme $_nom_juge_$</strong>, Juge, assistée de <strong>$_nom_assistant_$</strong> , Greffier, rendu le jugement dont la teneur suit :</p>
          </section>


          <section>
                  <h6 class="text-center"><u>LE TRIBUNAL :</u></h6>
                  <p><strong>Vu</strong> la requête en date du $_date_requette_$ présentée par $_nom_complet_plaignant_$</p>
                  <p><strong>Vu</strong>le certificat de non inscription de décès délivré par l’Officier de l’Etat Civil $_nom_etat_civil_$ de feu $_nom_defunt_$ né(e) le $_date_naiss_defunt_$</p>
                  <p><strong>OUI</strong> le requérant en ses explications à l’audience ;</p>
                  <p><strong>OUI</strong> le Ministère Public en ses conclusions en date du $_date_de_decision_$ qui déclare s’en rapporter ;</p>
                  <p><strong>OUI LES TEMOINS </strong></p>
                  <ol>
                      <p><li>/ …………...…………….………., CNI …………</li></p>
                      <p><li>/ ………………….………………, CNI  ………..</li></p>
                  </ol>
                  <p>En leurs déclarations orales serment préalablement prêté ;</p>
                  <p><strong>Vu</strong> les articles 3-86 et 87 du Code de la Famille ;</p>
                  <p>Attendu comme résultant  du certificat de non inscription que le décès de :</p>
                  <p>Feu $_nom_defunt_$, né(e) le $_date_naiss_defunt_$, de sexe $_genre_defunt_$, fils, $_pere_defunt_$ et de $_mere_defunt_$ n’a pas été inscrit sur les registres de l’état civil $_nom_etat_civil_$;</p>
                  <p><strong>Attendu</strong> que la preuve du décès de feu $_nom_defunt_$ a été rapportée ;</p>
                  <p><strong>Qu’il </strong>échet de faire droit à la requête de $_nom_demandeur_$</p>
          </section>

          <section class="footer">
                  <h6 class="text-center"><u>PAR CES MOTIFS ;</u></h6>
                  <p>Statuant publiquement, sur requête en matière civile et en premier ressort ;</p>

                  <h6 class="" style="font-size:13px"><u>DIT QUE</u></h6>

                      <p>Feu $_nom_defunt_$, né(e) le $_date_naiss_defunt_$, de sexe $_genre_defunt_$, fils de $_pere_defunt_$ et de $_mere_defunt_$ est bien décédé  le $_date_dece_$</p>
                      <p><strong>Autorise</strong> en conséquence l’inscription du jugement par l’officier de l’Etat Civil $_nom_etat_civil_$ sur le registre de décès  à la date de sa remise à la suite du dernier acte inscrit ;</p>
                      <p><strong>Dit que</strong> mention en sera faite en marge des registres de décès de l’année à laquelle il aurait dû être relaté normalement et de l’acte de l’acte le plus rapproché de sa date ainsi que sur le double de ce registre destiné au Greffe du tribunal $_tribunal_$.</p>
                      <p><strong>Dit que</strong>  la preuve du décès ne pourra être rapportée que par production d’un acte délivré par le dépositaire après exécution des mesures prescrites par le tribunal</p>

                  <p>Ainsi fait, jugé et prononcé publiquement par le Tribunal $_tribunal_$, les jour, mois et an que dessus ;</p>
                  <div class="text"> <h6 class="text-center" style="font-size:13px"><u>ET ONT  SIGNE</u></h6></div>
                  <div class="signatures d-flex justify-content-between">
                      <p >LE JUGE</p>
                      <p>LE GREFFIER</p>
                  </div>
          </section>
      </main>
      </section>
      </div>
      `
    }
    ,{
      'id': 3,
      'name': 'jugement3',
      'template_name': 'Certificat héredité',
      'image': 'https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg',
      'template': `
      <div class="second" >
      <section class="header d-flex justify-content-start"  >
      <div class="d-flex  flex-column justify-content-start col-md-4 col-lg-4 " >
        <span><b>République du Sénégal</b> <br> un peuple - un but - une foi <br><br>  Cour d'appel de dakar <br>&nbsp;&nbsp;&nbsp;<b>.................<b> <br></span>
        <b> $_tribunal_$ </b>
        <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image"><br>
        &nbsp;&nbsp;&nbsp;<b>.................<b></p>

        </p>


        <b>N°$_numero_de_jugement_$<br>Du $_date_de_jugement_$ </b> <br>
        <hb>SUCCESSION DE : Feu $_nom_legateur_$</b>
      </div>

      <div>
      <div>
      <section class="d-flex justify-content-between second mycontent">

          <main>
          <section class="title text-center">
              <h3><em><u>JUGEMENT D’HOMOLOGATION DE PARTAGE</u></em></h3>
              <h6><em><u>AUDIENCE DU $_date_de_jugement_$</u>  </em></h6>
          </section>

          <section>

            <p>A l’audience civile du $_tribunal_$ du $_date_de_jugement_$ sous la présidence de <strong>Mr/Mme $_date_de_jugement_$, Juge, </strong>assistée de <strong>Maître $_nom_assistant_$</strong>, Greffier, tenant la plume, a été rendu le jugement dont la teneur suit dans la cause ;</p>
          </section>


          <section>
             <h6 class="text-center"><u>LE TRIBUNAL :</u></h6>
             <p>
             Vu la requête écrite en date du $_date_requette_$ présentée par $_nom_complet_plaignant_$, tendant à ce qu’il plaise au Tribunal rendre un jugement d’hérédité dans la succession de feu <strong>$_nom_defunt_$</strong>, décédé le $_date_dece_$ à $_lieu_dece_$ ;
         </p>
         <p>OUI le requérant en ses explications orales à la barre ;<br>VU les pièces produites ; <br>OUI les témoins présentés, à savoir :	 </p>
         <ol>
             <li><strong>$_nom_temoin1_$</strong>- CNI n° $_num_identifiant_temoin1_$ ;</li>
             <li> <strong>$_nom_temoin1_$</strong>- CNI n° $_num_identifiant_temoin2_$ ; </li>
         </ol>
         <p>Lesquels, serments préalablement prêtés et avertis des sanctions qu’entraîne le faux témoignage, ont séparément déclaré et affirmé qu’il est à leur connaissance que le défunt a laissé comme héritiers habilités à lui succéder : </p>

         <p><span style="text-decoration: underline;" ><strong>$_nombre_femme_legataires_$ :</strong> </span> <br>
             <ul>
                 <li><strong>$_legataire1_$, né(e) le $_anne_naiss_legataire1_$ à $_lieu_naiss_legataire1_$ ;</strong></li>
                 <li><strong>$_legataire2_$, né(e) le $_anne_naiss_legataire2_$ à $_lieu_naiss_legataire2_$ ;</strong></li>
             </ul>

         </p>

         <p><span style="text-decoration: underline;" ><strong>Et $_nombre_enfants_legataires_$ enfants, dont :</strong> </span> </p>

            <p><span style="text-decoration: underline;" ><strong>$_nombre_garcons_legataires_$ garçons ;</strong></span></p>
                <ol>
                    <li><strong> Moctar MBENGUE, né le 15/03/1973 à Dakar ;</strong></li>
                    <li><strong>Amadou Abdoulaye MBENGUE, né le 22/11/1979 à Pikine ;</strong></li>
                    <li><strong>Seydi Aboubacar MBENGUE, né le 12/10/1990 à Pikine ;</strong></li>
                    <li><strong>El Hadji Malick MBENGUE, né le 23/08/1991 à Tivaouane ;</strong></li>
                    <li><strong>Macoumba MBENGUE, né le 13/01/1993 à Tivaouane ;</strong></li>
                    <li><strong>Thierno Seydou MBENGUE, né le 10/10/1997 à Pikine ;</strong></li>


            <p><span style="text-decoration: underline;" ><strong>Et $_nombre_fille_legataires_$ filles :</strong></span></p>

                    <li><strong>Aminata MBENGUE, née le 05/06/1975 à Pikine ;</strong></li>
                    <li><strong>Codou MBENGUE, née le 20/05/1977 à Pikine :</strong></li>
                    <li><strong>Fatoumata MBENGUE, née le 21/08/1983 à Tivaouane ;</strong></li>
                    <li><strong>Bineta MBENGUE, née le 06/11/1985 à Pikine ;</strong></li>
                    <li><strong>Fatou MBENGUE, née le 20/02/1976 à Pikine ;</strong></li>
                    <li><strong>Sokhna Rokhaya MBENGUE, 01/07/1992 à Pikine ;</strong></li>
                    <li><strong>Wolimata Ritou MBENGUE, née le 01/09/1994 à Pikine ;</strong></li>

                </ol>

            <p>APRES en avoir délibéré conformément à la loi ;</p>
            <p>ATTENDU que de l’enquête à laquelle il a été procédé, des pièces du dossier et des témoignages recueillis, il résulte que feu $_nom_defunt_$, a laissé comme héritiers les susnommés ; </p>
            <p>Qu’il échet en conséquence de faire droit à la requête ci- dessus présentée ; </p>
          </section>

          <section class="footer">
                  <h6 class="text-center"><u>PAR CES MOTIFS ;</u></h6>
                  <p>STATUANT publiquement, sur requête, en matière civile et en premier ressort,   </p>
                      <p>DONNE ACTE à $_nom_demandeur_$ de sa demande ; Y FAISANT DROIT ;</p>
                      <p>DIT ET JUGE que feu $_nom_defunt_$, décédé le $_date_dece_$ à $_lieu_dece_$, a laissé comme héritiers légitimes habilités à lui succéder suivant les dévolutions en matière de succession de droit musulman :

                      </p>

                      <p><span style="text-decoration: underline;" ><strong>$_nombre_femme_legataires_$ :</strong> </span> <br>
                      <ul>
                          <li><strong>$_legataire1_$, né(e) le $_anne_naiss_legataire1_$ à $_lieu_naiss_legataire1_$ ;</strong></li>
                          <li><strong>$_legataire2_$, né(e) le $_anne_naiss_legataire2_$ à $_lieu_naiss_legataire2_$ ;</strong></li>
                      </ul>

                  </p>

                  <p><span style="text-decoration: underline;" ><strong>Et $_nombre_enfants_legataires_$ enfants, dont :</strong> </span> </p>

                     <p><span style="text-decoration: underline;" ><strong>$_nombre_garcons_legataires_$ garçons ;</strong></span></p>
                         <ol>
                             <li><strong> Moctar MBENGUE, né le 15/03/1973 à Dakar ;</strong></li>
                             <li><strong>Amadou Abdoulaye MBENGUE, né le 22/11/1979 à Pikine ;</strong></li>
                             <li><strong>Seydi Aboubacar MBENGUE, né le 12/10/1990 à Pikine ;</strong></li>
                             <li><strong>El Hadji Malick MBENGUE, né le 23/08/1991 à Tivaouane ;</strong></li>
                             <li><strong>Macoumba MBENGUE, né le 13/01/1993 à Tivaouane ;</strong></li>
                             <li><strong>Thierno Seydou MBENGUE, né le 10/10/1997 à Pikine ;</strong></li>


                     <p><span style="text-decoration: underline;" ><strong>Et $_nombre_fille_legataires_$ filles :</strong></span></p>

                             <li><strong>Aminata MBENGUE, née le 05/06/1975 à Pikine ;</strong></li>
                             <li><strong>Codou MBENGUE, née le 20/05/1977 à Pikine :</strong></li>
                             <li><strong>Fatoumata MBENGUE, née le 21/08/1983 à Tivaouane ;</strong></li>
                             <li><strong>Bineta MBENGUE, née le 06/11/1985 à Pikine ;</strong></li>
                             <li><strong>Fatou MBENGUE, née le 20/02/1976 à Pikine ;</strong></li>
                             <li><strong>Sokhna Rokhaya MBENGUE, 01/07/1992 à Pikine ;</strong></li>
                             <li><strong>Wolimata Ritou MBENGUE, née le 01/09/1994 à Pikine ;</strong></li>

                         </ol>


                            <p>LES ENVOIE  en possession de la succession du dé cujus  par  application des articles 403 – 404 – 515 – 571 et suivants du Code de la Famille ;  </p>

                            <p>AINSI fait, jugé et prononcé publiquement les jour, mois et an que dessus ; </p>

                            <h6 class="text-center" style="font-size:13px"><u>ET ONT  SIGNE</u></h6>


                  <div class="signatures d-flex justify-content-between">
                      <p >LE PRESIDENT</p>
                      <p>LE GREFFIER</p>
                  </div>
          </section>
      </main>
      </section>
      </div>


      </div>


      </section>

    </div>
      `
    },
    {
      'id': 4,
      'name': 'template1',
      'template_name': 'Certificat naissance',

      'image': 'https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg',
      'template': `
      <div class="second" >
      <section class="header d-flex justify-content-between"  >
      <div>
        <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
        </p>
        <b>République du Sénégal <br> Cour d'appel de dakar <br> $_tribunal_$ </b>
      </div>



        <div>
          <p class="mt-1" >
            <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
          </p>
          <b>N°$_numero_de_jugement_$<br>Du $_date_de_jugement_$ </b>

        </div>


      </section>

    </div>
      <div>
      <section class="d-flex justify-content-between second mycontent">

          <main>
          <section class="title text-center">
              <h3><em><u>JUGEMENT D’AUTORISATION D’INSCRIPTION DE NAISSANCE</u></em></h3>
              <h6><em><u>AUDIENCE PUBLIQUE ORDINAIRE DU $_date_de_jugement_$</u>  </em></h6>
              <p>------------------------------------------</p>

          </section>
          <section>

          <p>Le $_tribunal_$, statuant en matière civile a, en son audience publique ordinaire du <strong>$_date_de_jugement_$ </strong> tenue sous la présidence de <strong>Mr/Mme $_nom_jge_$</strong>, Juge, assistée de Maître <strong>$_nom_assistant_$</strong> , Greffier, rendu le jugement dont la teneur suit :</p>
          </section>


          <section >
             <h6 class="text-center"><u>LE TRIBUNAL :</u></h6>
             <p><strong>VU</strong> la requête du $_date_requette_$ présentée par $_nom_demandeur_$ demeurant à $_adresse_demandeur_$,  sollicitant un jugement de naissance pour lui-même ;</p>
             <p><strong>VU</strong> le certificat de non inscription de naissance délivré par l’Officier de l’Etat Civil du <strong> Centre $_nom_centre_$ </strong> le Ministère Public en ses conclusions  écrites en date du $_date_de_decision_$.;</p>


             <p><strong>O</strong>UÏ le requérant  en ses explications à l’audience ;</li>
             <p>OUÏ les témoins :</p>
             <ol>
                     <li>$_nom_temoin1_$. CNI $_num_identifiant_temoin1_$</li>
                     <li>$_nom_temoin2_$. CNI $_num_identifiant_temoin2_$</li>
             </ol>
             <p>EN leurs déclarations orales conformément à la loi, serments préalablement prêtés ;
                     VU les articles 30, 86 et 87 du code de la famille ;
             </p>


             <p>APRES en avoir délibéré conformément à la loi ;</p>
             <p> ATTENDU que par requête en date du $_date_requette_$, $_nom_demandeur_$ a saisi  le Tribunal de céans <br> d’une demande aux fins d’autorisation de son inscription de naissance</p>

          </section>
          <section class="footer">
            <h6 class="text-center"><u>EN LA FORME</u></h6>
            <p>ATTENDU que la requête a été initiée dans les forme  et délai  légaux, qu’il échet <br> de la déclarer recevable ;</p>

          </section>

          <section class="footer">
            <h6 class="text-center"><u>AU FOND</u></h6>
            <p>ATTENDU que le requérant  a déclaré à la barre du tribunal qu’il serait né le $_date_naiss_demandeur_$ ;</p>
          <p>Que cependant sa naissance n’a pu être déclarée à l’état civil dans le délai légal ;</p>
          <p><strong>A</strong>TTENDU qu’il ressort du certificat de non inscription de naissance délivré par  l’officier d’état civil du centre <strong>$_nom_centre_$</strong> que la naissance du nommé <strong>$_nom_demandeur_$</strong>, né le <strong>$_date_naiss_demandeur_$</strong>, de sexe $_genre_demandeur_$, fils de $_nom_pere_demandeur_$ et de $_nom_mere_demandeur_$  n’a pu être inscrite sur les  registres d’état civil du centre <strong>$_nom_centre_$</strong> ;</p>
          <p><strong>A</strong>TTENDU qu’il résulte de l’enquête à laquelle il a été procédé que la preuve de  la naissance de <strong>$_nom_demandeur_$</strong> a été rapportée ;</p>
          <p>Mais que cette naissance non déclarée dans les délais ne peut être inscrite  sur les registres de l’état civil de la commune de <strong>$_nom_commune_$</strong> ;</p>
          <p>Qu’il échet de réparer cette omission et de faire droit à la requête de <strong>$_date_requette_$</strong> ;</p>
          </section>

          <section class="footer">
            <h6 class="text-center"><u>PAR CES MOTIFS :</u></h6>
            <p>STATUANT publiquement sur requête en matière civile et en premier ressort ;</p>
            <h6 class="" style="font-size:13px"><u>DIT QUE</u></h6>
            <p > <strong>Le nommé  $_nom_demandeur_$, de sexe $_genre_demandeur_$, est bien né le $_date_naiss_demandeur_$ ,  de $_nom_pere_demandeur_$ , né le $_date_naiss_pere_demandeur_$ .  et de $_nom_mere_demandeur_$ , née le $_date_naiss_mere_demandeur_$</strong>;</p>

            <p><strong>A</strong>UTORISE en conséquence l’inscription du jugement par l’Officier d’Etat Civil  du centre <strong>$_nom_centre_$</strong> sur les registres de naissance de l’année en cours, à la date de la  présentation de ce jugement, à la suite du dernier acte inscrit ;</p>
            <p><strong>D</strong>IT que mention en sera faite en marge du registre de l’année de la naissance à la  suite de l’acte le plus rapproché de la date à laquelle cette naissance aurait dû  être relatée, ainsi que sur le double de ce registre destiné au Greffe du $_tribunal_$ ;</p>
            <p><strong>D</strong>IT également que mention en sera faite sur le répertoire alphabétique et sur l’état  statistique prévus par les articles 39 et 40 du Code de la Famille ;</p>
            <p><strong>D</strong>IT que la preuve de la naissance de <strong>$_nom_demandeur_$</strong> ne pourra être rapportée que par production d’un acte délivré par le dépositaire des registres après exécution des  mesures prescrites par le Tribunal ;</p>

            <p>Ainsi fait, jugé et prononcé les jours, mois et an que dessus.
                Et ont signé, le Président et Le Greffier. /
            </p>
            <div class="signatures d-flex justify-content-between">
                <p >LE JUGE</p>
                <p>LE GREFFIER</p>
            </div>
          </section>
      </main>
      </section>
      </div>
      `
    },
    {
      'id': 5,
      'name': 'jugement5',
      'template_name': "Certificat changement d'option",
      'image': 'https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg',
      'template': `
      <div class="second" >
      <section class="header d-flex justify-content-start"  >
      <div class="d-flex  flex-column justify-content-start col-md-4 col-lg-4 " >
        <span><b>République du Sénégal</b> <br> un peuple - un but - une foi <br><br>  Cour d'appel de dakar <br>&nbsp;&nbsp;&nbsp;<b>.................<b> <br></span>
        <b> $_tribunal_$ </b>
        <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image"><br>
        &nbsp;&nbsp;&nbsp;<b>.................<b></p>

        </p>


        <b>N°$_numero_de_jugement_$<br>Du $_date_de_jugement_$ </b> <br>
        <hb>SUCCESSION DE : Feu $_nom_legateur_$</b>
      </div>

      <div>
      <div>
      <section class="d-flex justify-content-between second mycontent">

          <main>
          <section class="title text-center">
              <h3><em><u>AUDIENCE CIVILE</u></em></h3>
              <p>------------------------------------------</p>

          </section>

          <section>
          <p>A l’audience publique ordinaire du $_tribunal_$ du $_date_de_jugement_$ tenue en la salle des audiences sise au Palais de Justice de ladite ville, sous la présidence de Monsieur <strong> $_nom_president_$ </strong>, Président, assisté de Maître <strong> $_nom_assistant_president_$</strong>, Greffier, a été rendu le jugement dont la teneur suit :</p>

          <p>Par requête en date du $_date_requette_$, Mr/Mme <strong> $_nom_demandeur_$ </strong>,  a saisi  la juridiction de céans, d’une demande aux fins de changement de son régime de la <strong>polygamie</strong> à la <strong>monogamie</strong> ;</p>
          <p>Suite à cet acte, l’affaire a été inscrite au rôle général sous le numéro $_numero_role_$ et au rôle  particulier à l’audience du $_date_audience_$;</p>
          <p>Advenue cette date, l’affaire a été  utilement retenue ; </p>
          </section>


          <section>
             <h6 class="text-center"><u>LE TRIBUNAL :</u></h6>
             <p>ATTENDU que suivant requête en date du $_date_requette_$ Mr/Mme <strong>$_nom_demandeur_$</strong>, a saisi la juridiction de ce siège d’une demande de changement d’option du régime<strong> polygamie</strong> antérieurement choisie par lui en <strong>monogamie</strong> ;</p>
             <p>Qu’il a de ce fait produit l’acte de mariage <strong> $_numero_actemariage_$</strong> de l’année <strong>$_annee_actemariage_$</strong>établi par l’officier du $_nom_centre_$ le concernant avec son épouse <strong>$_nom_epouse_$</strong> ;</p>
             <p>ATTENDU que de l’examen des dispositions de l’article 134 du Code de la Famille, il résulte que le changement d’option est possible s’il tend à la restriction du nombre des épouses ;</p>
             <p>Attendu en l’espèce que Mr/Mme <strong>$_nom_demandeur_$ qui avait souscrit la <strong>polygamie</strong> veut changer l’option en <strong> monogamie </strong>;</p>
             <p>Qu’il échet de faire droit à sa demande et d’ordonner la modification de l’option ;</p>

          </section>
          <section class="footer">
            <h6 class="text-center"><u>EN LA FORME</u></h6>
            <p>ATTENDU que la requête a été initiée dans les forme  et délai  légaux, qu’il échet <br> de la déclarer recevable ;</p>

          </section>

          <section class="footer">
            <h6 class="text-center"><u>AU FOND</u></h6>
            <p>ATTENDU que le requérant  a déclaré à la barre du tribunal qu’il serait né le $_date_naissance_$ ;</p>
          <p>Que cependant sa naissance n’a pu être déclarée à l’état civil dans le délai légal ;</p>
          <p><strong>A</strong>TTENDU qu’il ressort du certificat de non inscription de naissance délivré par  l’officier d’état civil du centre <strong>$_nom_centre_$</strong> que la naissance du nommé <strong>$_nom_du_nee_$</strong>, né le <strong>$_date_naissance_$</strong>, de sexe $_sexe_$, fils de $_nom_pere_$ et de $_nom_mere_$  n’a pu être inscrite sur les  registres d’état civil du centre <strong>$_nom_centre_$</strong> ;</p>
          <p><strong>A</strong>TTENDU qu’il résulte de l’enquête à laquelle il a été procédé que la preuve de  la naissance de <strong>$_nom_du_nee_$</strong> a été rapportée ;</p>
          <p>Mais que cette naissance non déclarée dans les délais ne peut être inscrite  sur les registres de l’état civil de la commune de <strong>$_nom_commune_$</strong> ;</p>
          <p>Qu’il échet de réparer cette omission et de faire droit à la requête de <strong>$ numero_requette_$</strong> ;</p>
          </section>

          <section class="footer">
            <h6 class="text-center"><u>PAR CES MOTIFS :</u></h6>
            <p><strong>Statuant publiquement contradictoirement, en matière civile et en 1er ressort ;</strong></p>

            <h6 class="" style="font-size:13px"><u>En la forme</u></h6>
            <p>
                  <ul>
                      <li><strong>	Reçoit l’action ;</strong></li>
                  </ul>
              </p>

              <h6 class="" style="font-size:13px"><u>Au fond</u></h6>

              <p>
                <ul>
                <li><strong>	Donne acte à  Mr/Mme <strong>$_nom_demandeur_$ de son changement d’option de la polygamie à la monogamie ;</strong></li>
                <li><strong>	Ordonne la modification de ladite option sur les registres de l’état civil du centre de $_nom_centre_$ où son mariage a été enregistré en $_annee_actemariage_$ sous le numéro $_numero_actemariage_$.  </strong></li>
                <li><strong>	Autorise l’Officier d’Etat Civil du centre de $_nom_centre_$ à procéder audit changement ;</strong></li>
                <li><strong>	Dit qu’il ne pourra être délivré aucun extrait ou copie de l’acte de mariage susvisé sans la mention dudit changement.</strong></li>
                </ul>
                </p>


            <p>Ainsi fait, jugé et prononcé les jours, mois et an que dessus.

            </p>
            <div class="text"> <h6 class="text-center" style="font-size:13px"><u>ET ONT  SIGNE</u></h6></div>
                  <div class="signatures d-flex justify-content-between">
                      <p >LE PRESIDENT</p>
                      <p>LE GREFFIER</p>
                  </div>

          </section>
      </main>
      </section>
      </div>
      </div>


      </section>

    </div>
      `


    },
  ]
  list_documents: any = []
  jugement_code: any = null
  need_reenrolement=false
  is_radiation =false
  is_waiting_deliberation =false
  constructor(private modalService: BsModalService,public options: ModalOptions,
    private ngxService: NgxUiLoaderService,
    private customerService:CustomService,
    private alerte:AlertesService,
    private assignService:AssignedService,
    private jugementService: JugementsService,
    private documentService: DocumentService,
    private datePipe: DatePipe,
    public bsModalRef: BsModalRef,
    private role:EnrollementService,
    private penalService: PenalCourrierService,private enrollementService: EnrollementService) {
    this.data = options.initialState.data
    this.concat_tribunal = options.initialState.concat_tribunal
    this.objet_jugment = this.data
    this.status_jugement = this.data.status.toLowerCase()
    console.log('JUGEMNT ==> ', this.data)
    this.IS_CLOS=this.data.is_clos
    this.jugement_code= this.data.jugement_code
    console.log(this.profil)
    if (this.concat_tribunal == true) {
      this.numero_du_jugement = this.data.numero_du_jugement +'-'+ this.data.tribunal

    }else{
      this.numero_du_jugement = this.data.numero_du_jugement
    }
    this.getClosActions()

    this.profil = localStorage.getItem('profil')

    this.getActorOfTheElement()
    this.generateJugementCode()
    try{
      this.my_tribunal = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }
  }

  new_date_report : string;

  generateJugementCode(){
    if(this.jugement_code == '-1'){
      this.jugement_code = this.generateRandomString()
    }
  }

  getFormattedDate(date): string {
    if (date != "None") {
      return this.datePipe.transform(date, 'dd-MM-yyyy');

    }
  }

  closeModal(){
    this.bsModalRef.hide()
  }

  async GetTemplateDisponible(){
  // let filter = '[["type_de_template":"'+this.data.classification+'"]]'
    let filter = '[["type_de_template" , "=","' + this.data.classification + '"]]'
  let templates = (await this.documentService.getAllTemplate(filter)).data
  console.log("guetted templates=> ", templates);

    if(templates.length > 0){
      this.list_jugement = templates
    }
  }
  async GetDocuments(){
    this.list_documents = []
    console.log('in getDocuments',this.data);
    let jugement_filter =  this.jugement_code;

   // let filter =this.my_tribunal==null? '[["numero_du_jugement":"'+this.data.numero_de_jugement+'"]]' : '[["numero_du_jugement":"'+this.data.numero_de_jugement+'"],["tribunal" , "=","'+this.my_tribunal.name+'"]]'
    let filter = '[["numero_du_jugement" , "=","' + jugement_filter + '"]]'
    console.log('thefilter in get document',filter);

    let documents = (await this.documentService.getAllDocuments(filter)).data
    console.log('getted docs', documents);

    if(documents.length > 0){
      this.list_documents = documents
    }
    // if(jugement_filter!='-1'){
    //   let documents = (await this.documentService.getAllDocuments(filter)).data
    //   if(documents.length > 0){
    //     this.list_documents = documents
    //   }
    // }

    console.log('in getDocuments', this.list_documents);
  }

  filterValueChange(value){
    this.ngxService.startLoader('viewJugement')
    let result=  (this.type_jugement_reports.filter(obj => obj.name == value))[0]
    console.log(result)
    if(result.need_reenrolement==1){
      this.need_reenrolement =true
    }else {
      this.need_reenrolement =false
    }
    if(result.is_radiation==1){
      this.is_radiation =true
    }else {
      this.is_radiation =false
    }
    if(result.is_waiting_deliberation==1){
      this.is_waiting_deliberation =true
    }else {
      this.is_waiting_deliberation =false
    }
    this.ngxService.stopLoader('viewJugement')
  }

  showFactQuality(){
    this.showFactum = !this.showFactum;
    console.log('kjkjkj'+ this.showFactum);
  }

  ChangeStatus(){
    this.profil = localStorage.getItem('profil')
    console.log(this.profil)
    console.log(this.data)

    if(
     this.data.status== "Mise en délibéré"
    || this.data.status==  "Decision rendue"
      || this.data.status== "Document généré"
      || this.data.status== "Report"
      || this.data.status==  "Renvoi"
      || this.data.status==  "Radiation"
      || this.data.status== "En appel"
      || this.data.status== "Definitive"
    ){
      if (this.profil.toUpperCase()=='JUGE') {
        this.CAN_APPROUVE = true
        if(this.data.validation_du_juge!=1){
          this.CAN_APPROUVE = true
        }
        if(this.data.is_clos==1){
          this.CAN_APPROUVE = false
        }
      }
      else if (this.profil.toUpperCase()=='GREFFIER' || this.profil.toUpperCase()=='GREFFIER EN CHEF' ) {
        if(this.data.validation_du_juge==1){
          this.CAN_CLORE = true
        }
        if(this.data.is_clos==1){
          this.CAN_CLORE = false
        }
      }
      this.getClosActions()
    }


    if(this.profil.toUpperCase()=='JUGE' || this.profil.toUpperCase()=='GREFFIER' || this.profil.toUpperCase()=='GREFFIER EN CHEF' ){
      this.ALLOWED_DECISION_FINAL =true
      this.ALLOWED_SAVE_DECISION = true
      this.CAN_SEE_TEMPLATE = true
      this.CAN_SEE_DOCUMENTS = true
    }else{
      this.ALLOWED_DECISION_FINAL =false
      this.ALLOWED_SAVE_DECISION = false
      this.CAN_SEE_TEMPLATE = false
      this.CAN_SEE_DOCUMENTS = false
    }

    // if(this.profil.toUpperCase()=='JUGE'){
    //   this.ALLOWED_DECISION_FINAL =true
    //   this.ALLOWED_SAVE_DECISION = true
    //   this.CAN_SEE_TEMPLATE = true
    //   this.CAN_SEE_DOCUMENTS = true
    // }

    // if(this.profil.toUpperCase()=='GREFFIER'){
    //   this.ALLOWED_DECISION_FINAL =true
    //   this.ALLOWED_SAVE_DECISION = true
    //   this.CAN_SEE_TEMPLATE = true
    //   this.CAN_SEE_DOCUMENTS = true
    // }

    if(this.data.status == "En attente de date du Jugement" ||this.data.status == "Decision rendue" || this.data.status == "En appel" || this.data.status == "Definitive"){
      this.ALLOWED_SAVE_DECISION = false
    }

    if(this.data.status !="Decision rendue"){
       this.ALLOWED_DECISION_FINAL = false
     }

    if(this.data.status == "Decision rendue"){
      this.CAN_SEE_TEMPLATE = true
      this.CAN_SEE_DOCUMENTS = true
    }


    if(this.data.status != "Decision rendue"  && this.data.status != "Definitive" ){
      this.CAN_SEE_TEMPLATE = false
      this.CAN_SEE_DOCUMENTS = false
    }
    if(this.data.status == "En appel"){
      //this.CAN_SEE_TEMPLATE = true
      this.CAN_SEE_DOCUMENTS = true
    }
    if (this.data.status=='Document généré') {
      this.CAN_SEE_TEMPLATE = false
    }
    this.data.status.toLowerCase() == 'document généré' ? false: true
    if(this.data.status=='Report' || this.data.status=='Radiation'  || this.data.status=='Renvoi'){
      this.CAN_DO_ACTION =false
    }



  }

  selectTypePrevenu(event: any){

    const isChecked = event.target.checked;
    if (isChecked) {
      this.is_multiple_prevenu = 1;
    }else{
      this.is_multiple_prevenu = 0;
    }
    console.log('is_multiple_prevenu==>',this.is_multiple_prevenu);


}
  generateRandomString(): any {
    const length =10;
    const characters = '0123456789';
    let result = '';
    let currentDate = new Date();
    let currentYear= currentDate.getFullYear();

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

   return  currentYear +'/'+ result;
  }




  UpdateDecision(){
    console.log('uhijo',this.data );

    this.ngxService.start()
    if(this.CAN_DO_ACTION == false){
      this.alerte.warningNotification('Impossible d\'effectuer des operations sur cette audience: audience reportée ou radiée')
      this.ngxService.stop()
    }else{
      let table_tqhi =this.ContrainteParCorps
      let table_emp =this.Emprisonements
      let table_amendes_j =this.Amendes

      for(let lg of this.lignes){
        let to_add ={
          "docstatus": 1,
          "debut": lg.debut,
          "fin": lg.fin,
          "main_leve": lg.main_leve,
          "parent": this.data.name,
          "parentfield": "table_tqhi",
          "parenttype": "Jugements",
          "doctype": "fiche_a_classer_contrainte_par_corps"
        }
        table_tqhi.push(to_add)
      }
      for(let lg of this.lignes_condamenations){
        let to_add ={
          "docstatus": 1,
          "type": lg.type,
          "durée": lg['durée'],
          "périodicité": lg['périodicité'],
          "parent": this.data.name,
          "parentfield": "table_emp",
          "parenttype": "Jugements",
          "doctype": "fiche_a_classer_emprisonement"
        }
        table_emp.push(to_add)
      }
      for(let lg of this.lignes_ammendes){
        let to_add ={
          "docstatus": 1,
          "montant": lg.montant,
          "aux_depens_liquides_a_la_somme": lg.aux_depens_liquides_a_la_somme,
          "parent": this.data.name,
          "parentfield": "table_amendes_j",
          "parenttype": "Jugements",
          "doctype": "fiche_a_classer_amende"
        }
        table_amendes_j.push(to_add)
      }

      let body ={
        "status": "Decision rendue",
        "date_de_decision":this.Date_decision,
        "categorie_de_la_decision":this.Categorie_Decision,
        "decision_judiciaire":this.Decision_Judiciaire,
        "jugement_code": this.jugement_code +'/'+this.my_tribunal.name,
        table_tqhi,table_emp,table_amendes_j
      }

      this.jugementService.updateJugement(this.data.name,body).then(async res =>{
        // this.list_documents=[]
        console.log('REsultat ==> ',res)
        this.jugement_code = res.data.jugement_code
        console.log('the jugment_code ==> ',this.jugement_code)

        try{
          this.enrolle['status'] = "Decision rendue"
          this.enrolle['jugement_code'] = res.data.jugement_code
          this.role.updateRole(this.enrolle.name,this.enrolle).then(async res=>{
            await this.role.getAllRoleGeneral('[["identifiant_e_role" , "=","'+this.enrolle.name+'"]]').then(res=>{
              console.log('res======> ',res)
              let role_general = res.data[0]
              role_general['status'] = "Decision rendue"
              role_general['decision'] = this.Decision_Judiciaire
              console.log('res======> ',role_general)
              this.role.updateRoleGeneral(role_general.name,role_general)
              // this.list_documents=[]
              this.ngxService.stopLoader('viewJugement');
            },err=>{
              this.ngxService.stopLoader('viewJugement');
            })
          })
        }catch (e) {

        }

        this.openModalSuccessDialog('Sauvegarde effective',1)
       await this.jugementService.getOneJugement(this.data.name).then(res =>{
          //console.log(res)
          this.ChangeStatus()
          this.data=res.data
          this.Infractions = res.data.table_infractions
          this.Amendes = res.data.table_amendes_j
          this.Emprisonements = res.data.table_emp
          this.ContrainteParCorps = res.data.table_tqhi
          this.affiliations = res.data.affiliations
          this.jugement_reports = res.data.jugement_reports
          this.ngxService.stop()
        })
        this.getJugement()

      },err=>{
        console.log('Errue ==> ',err)
        this.alerte.errorNotification('Décision','Erreur lors de la mise à jour! Verifier que vous avez les autorisations requises')
        this.ngxService.stop()
      })
    }
    console.log('listdocssssss',this.list_documents );


  }

  UpdateFinalStatus(value,is_sate=0){
    this.ngxService.startLoader('viewJugement')

    let body:any = {
      "status": value
    }
    if(is_sate==1){
      if(this.profil=='JUGE'){
        body= {
          "validation_du_juge":1
        }
      }
      if(this.profil=='GREFFIER'){
        body = {
          "is_clos":1
        }
      }
      if(this.profil=='GREFFIER EN CHEF'){
        body = {
          "is_clos":1
        }
      }
    }

    this.jugementService.updateJugement(this.data.name,body).then(res =>{
      console.log('REsultat ==> ',res)
      this.openModalSuccessDialog('Mise a jour effective',1)
      this.jugementService.getOneJugement(this.data.name).then(res =>{
        //console.log(res)
        this.data=res.data
        this.Infractions = res.data.table_infractions
        this.Amendes = res.data.table_amendes_j
        this.Emprisonements = res.data.table_emp
        this.ContrainteParCorps = res.data.table_tqhi
        this.affiliations = res.data.affiliations
        this.jugement_reports = res.data.jugement_reports
        this.ChangeStatus()
        this.ngxService.stopLoader('viewJugement')
      })
    },err=>{
      console.log('Errue ==> ',err)
      this.alerte.errorNotification('Miser ajour','Erreur lors de la mise a jours')
      this.ngxService.stopLoader('viewJugement')
    })

  }



  async getAllChambres(chambre_:string) {
    //this.chambres = this.penalService.getChambres();

    this.ngxService.startLoader('viewJugement');
    try {
     let chambres  = (await this.enrollementService.getAllMyChambre()).data
      const chambreRecherchee = chambres.find(chambre => chambre.name === chambre_);

      this.ngxService.stopLoader('viewJugement');

      return chambreRecherchee
    } catch (error) {
      this.ngxService.stopLoader('viewJugement');
    }

    //console.log({'the result': result});

  }

  async openModalCalendar(dataEnrole: any) {
    // let courrier:any = this.courriers[id];
    // this.penalService.setSelectedChambre(chambre);

    console.log({'data enrolle': dataEnrole});

    let _chambre = await this.getAllChambres(dataEnrole.chambre)
    console.log({'in search': _chambre});
    let v = this.penalService.setSelectedChambre(_chambre);
    console.log('details detail chmbre === '+ v);

    const config: ModalOptions = {
      initialState: {
        data: dataEnrole,
        type:'report',
        parentModal: null
    }
     }
    const modalRef = this.modalService.show(CalendarComponent, Object.assign({},config,{class:'modal-lg  '}));
    modalRef.content.parentModal = modalRef;
    const calendarComponent = modalRef.content as CalendarComponent;
    calendarComponent.dateSelected.subscribe((selectedDate: string) => {
      this.new_date_report = selectedDate; // Mettre à jour la date dans votre composant parent
      console.log('outpuutttttdate'+ this.new_date_report);

    });

  }

  openModalAddPrevenu() {
    const config: ModalOptions = {
      initialState: {
        url:URL,
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(AddAssociationPrevenuComponent, Object.assign({}, config, { class: 'modal-lg  ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {
      console.log('Nouveau prévenu ajouté :', newPrevenu);
      this.prevenusList.push(newPrevenu);

    });
  }


  openModalDetailsPrevenu(prevenu:any) {
    const config: ModalOptions = {
      initialState: {
        url:URL,
        data: prevenu,
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(DetailsPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {

      let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
      if (existingPrevenuIndex != null) {
        existingPrevenuIndex = newPrevenu;
      }

      console.log('findeddd :', existingPrevenuIndex);
      // this.prevenusList.push(newPrevenu);

    });
  }
  deletePrevenu(prevenu:any){
    let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
    this.prevenusList.splice(existingPrevenuIndex, 1)

  }
  openModalSuccessDialog(message: string, not_close=0) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    // setTimeout(() => {
    //   if (this.modalService) {
    //     if(not_close==0){
    //       this.modalService.hide();
    //     }
    //   }
    // }, 1000);
    // this.penalService.hiddeModal(this.parentModal);
    this.alerte.successNotification('Audience',message)
    // this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  savePersonalInformation(form){
    this.ngxService.startLoader('viewJugement')
    //console.log(form.value)

    form.value['is_multiple_prevenu'] = this.is_multiple_prevenu
    form.value['association_de_prevenu'] = this.prevenusList
    let body =form.value
    console.log({'updateJugement': body});

    this.jugementService.updateJugement(this.data.name,body).then(res =>{
      //console.log('REsultat ==> ',res)
      let jugement_name = this.numero_du_jugement ==null ? this.data.name: this.numero_du_jugement
      this.jugementService.getOneJugement(jugement_name).then(res =>{
        //console.log(res)
        this.ChangeStatus()
        this.data=res.data
        this.Infractions = res.data.table_infractions
        this.Amendes = res.data.table_amendes_j
        this.Emprisonements = res.data.table_emp
        this.ContrainteParCorps = res.data.table_tqhi
        this.affiliations = res.data.affiliations
        this.jugement_reports = res.data.jugement_reports

        this.Categorie_Decision = res.data.categorie_de_la_decision
        this.Decision_Judiciaire  = res.data.decision_judiciaire


        this.getDetailEnrolleJugement()
      })
      this.openModalSuccessDialog('Sauvegarde effective',1)
      this.ngxService.stopLoader('viewJugement')

    },err=>{
      console.log('Errue ==> ',err)
      this.alerte.errorNotification('Information Justiciable','Erreur lors de la mise a jours')
      this.ngxService.stopLoader('viewJugement')
    })

  }

  updateJugementRepport(form){
    this.ngxService.start()
    let nouvel_date= ''
    if (this.new_date_report != null) {
      const parts = this.new_date_report.split('/');
    const formattedDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    const formattedDateString = this.datePipe.transform(formattedDate, 'yyyy-MM-dd');

    console.log('formattedDateString ==> ',formattedDateString)
      nouvel_date= formattedDateString
    form.value['nouvelle_date'] = formattedDateString
    }

    //console.log(form.value)


    this.jugement_reports.push(form.value)
    let body = {jugement_reports:this.jugement_reports}
    if(this.need_reenrolement){
      // body["status"]= "Report"
      body["status"]= "Renvoi"

    }
    if(this.is_radiation){
      body["status"]= "Radiation"
    }
    if(this.is_waiting_deliberation){
      body["status"]= "Mise en délibéré"
    }

    this.jugement_reports =[]
    this.jugementService.updateJugement(this.data.name,body).then(res =>{
      //console.log('REsultat ==> ',res)
      let jugement_name = this.numero_du_jugement ==null ? this.data.name: this.numero_du_jugement

      if (this.new_date_report != null) {
         res.data.date_de_jugement=nouvel_date
        this.createnewAudience(res.data,res.data.status,nouvel_date,1).then(res =>{

          this.jugementService.getOneJugement(jugement_name).then(res =>{
            //console.log(res)
            this.ChangeStatus()
            this.data=res.data
            this.Infractions = res.data.table_infractions
            this.Amendes = res.data.table_amendes_j
            this.Emprisonements = res.data.table_emp
            this.ContrainteParCorps = res.data.table_tqhi
            this.affiliations = res.data.affiliations
            this.jugement_reports = res.data.jugement_reports

            this.Categorie_Decision = res.data.categorie_de_la_decision
            this.Decision_Judiciaire  = res.data.decision_judiciaire


            // this.getDetailEnrolleJugement()
            this.getJugement()
          })


          this.openModalSuccessDialog('Operation Effectue avec success !',0)
          this.ngxService.stop()
        },err => {
          this.alerte.errorNotification('Error')
          console.log('err ==> ',err)
          this.ngxService.stop()
        })
      }else{
        this.jugementService.getOneJugement(jugement_name).then(res =>{
          //console.log(res)
          this.ChangeStatus()
          this.data=res.data
          this.Infractions = res.data.table_infractions
          this.Amendes = res.data.table_amendes_j
          this.Emprisonements = res.data.table_emp
          this.ContrainteParCorps = res.data.table_tqhi
          this.affiliations = res.data.affiliations
          this.jugement_reports = res.data.jugement_reports

          this.Categorie_Decision = res.data.categorie_de_la_decision
          this.Decision_Judiciaire  = res.data.decision_judiciaire


          this.getDetailEnrolleJugement()
        })
        this.openModalSuccessDialog('Operation Effectue avec success !',1)
        this.ngxService.stop()
      }




    },
        err=>{
      console.log('Errue ==> ',err)
      this.alerte.errorNotification('Information Justiciable','Erreur lors de la mise a jours')
      this.ngxService.stop()
    })
  }


  async createnewAudience(body_jugement,status_role,new_date,is_report=0){
    let next_number_corrier:any = {}
    this.role.getnumeroRole().then( res =>{
      console.log('Numero role ==> ',res.message)
      next_number_corrier = res.message
      // console.log('---> ',this.next_number_corrier)
      body_jugement["nbr_repport"] =is_report==1? body_jugement["nbr_repport"]+1 : body_jugement["nbr_repport"]
      body_jugement["numero_de_jugement"] = is_report==1?body_jugement["numero_de_jugement"] :next_number_corrier.new_role_number
      body_jugement["status"] = "En attente du Jugement"
      body_jugement["is_new_role"] = 0


      delete body_jugement ['owner'];
      delete body_jugement ['creation'];
      delete body_jugement ['modified'];
      delete body_jugement ['modified_by'];

      body_jugement["date_de_decision"] =""

      this.jugementService.nouveauJugement(body_jugement).then( (res:any)=> {
          console.log('Create Jugement !', res.data)
          let audience = res.data
          let body_role ={
            "docstatus": 1,
            "type_daffaire": this.enrolle.type_daffaire,
            "numero_du_jugement": res.data.numero_de_jugement,
            "numero_du_jugement_restrint":res.data.numero_de_jugement,
            "audience":res.data.name,
            "num_quittance1":this.enrolle.num_quittance1,
            "num_quittance2":this.enrolle.num_quittance2,
            "chambre": this.enrolle.chambre,
            "date_du_jugement": new_date
          }
          if(this.my_tribunal!=null){
            body_role['tribunal']=this.my_tribunal.name
          }

          this.role.nouveauRole(body_role).then(res =>{
              // console.log('RES ==> ',res)
              let body_role_general= body_role
              body_role_general["identifiant_e_role"] = res.data.name
              body_role_general["numero_du_role"] = res.data.numero_du_jugement_restrint
              body_role_general["plaignants"] = body_jugement.nom_complet_plaignant
              body_role_general["prevenue"] = body_jugement.prenom
              try {
                 this.role.addInRoleGeneral(body_role_general).then(res=>{})
              }catch (e) {

              }
              console.log('Create new role !', res.data)
              let role_info = res
              let detail_chambre=null
              try{
                 this.jugementService.updateJugement(audience.name,{"role":role_info.data.name})
              }catch (e) {

              }
              this.role.getOneChambre(this.enrolle.chambre).then(res=>{
                //console.log('res.data ==> ',res.data)
                detail_chambre=res.data
                let membres = detail_chambre.membres
                //console.log('membres (utilisateur) ==> ',membres)
                for(let membre of membres){
                  this.assignService.doAssignation(this.jugementService.Doctype_jugements,audience.name,[membre.utilisateur]).then(res=>{})
                  this.assignService.doAssignation(this.role.Doctype_enrolement,role_info.data.name,[membre.utilisateur]).then(res=>{})
                }
                for(let membre of this.alloed_user_acces){
                  this.assignService.doAssignation(this.jugementService.Doctype_jugements,audience.name,[membre]).then(res=>{})
                  this.assignService.doAssignation(this.role.Doctype_enrolement,role_info.data.name,[membre]).then(res=>{})
                }

              })

              this.enrolle['status'] = status_role
              this.role.updateRole(this.enrolle.name,this.enrolle).then(res=>{})
              this.role.getAllRoleGeneral('[["identifiant_e_role" , "=","'+this.enrolle.name+'"]]').then(res=>{
                let role_general = res.data[0]
                role_general['status'] = status_role
                console.log('res======> ',role_general)
                this.role.updateRoleGeneral(role_general.name,role_general)
                this.ngxService.stopLoader('viewJugement');
              },err=>{
                this.ngxService.stopLoader('viewJugement');
              })
            },
            err=>{
              console.log('Error Creation de role ==> ',err)
              this.alerte.errorNotification('Creation du Role','Impossible d\'effectuer l\'enrollement')
              this.ngxService.stopLoader('viewJugement');
            })
        },
        (error:any)=> {
          this.alerte.errorNotification('Creation du Role','Impossible d\'effectuer l\'enrollement')
          this.ngxService.stopLoader('viewJugement');
          console.log('Error on creaste Jugement !', error)
        }
      )
    },err=>{
      console.log('ERR==> ',err)
      this.alerte.errorNotification('Erreur')
    })

  }

  async getDetailEnrolleJugement() {
    try {
      let filter = null
      if(this.data.role==null ){
         filter = '[["numero_du_jugement" , "=","' + this.data.numero_de_jugement + '"]]'
      }else {
         filter = '[["name" , "=","' + this.data.role + '"]]'
      }

      //this.enrolle = this.penalService._Dossiersenrolles.find(objet => objet.numero === 'AR-01-2024');
      this.enrolle = (await this.role.getAllMyRole(filter)).data[0]
      console.log('llljjj===> ',this.enrolle);
    } catch (error) {
      console.log('error getdetailsenrollement ===> ',error);
    }

  }

  alloed_user_acces:any =[]

  getClosActions(){
    if(
      this.data.status== "Mise en délibéré"
      || this.data.status==  "Decision rendue"
      || this.data.status== "Document généré"
      || this.data.status== "Report"
      || this.data.status==  "Renvoi"
      || this.data.status==  "Radiation"
      || this.data.status== "En appel"
      || this.data.status== "Definitive"
    ){
      if(this.profil=='JUGE'){
        this.CAN_CLORE =false
        if(this.data.validation_du_juge==0 && this.data.is_clos==0){
          this.CAN_APPROUVE =true
        }
      }

      if(this.profil=='GREFFIER EN CHEF'){
        this.CAN_APPROUVE =false
        if(this.data.validation_du_juge==1 && this.data.is_clos==0){
          this.CAN_CLORE =true
        }
      }
      if(this.profil=='GREFFIER'){
        this.CAN_APPROUVE =false
        if(this.data.validation_du_juge==1 && this.data.is_clos==0){
          this.CAN_CLORE =true
        }
      }
    }


  }
  async getActorOfTheElement(){
    let Users =[]
    this.assignService.getAssignedAnOwnerUser('Dossier',this.data.numero_de_dossier).then(res=>{
      if (res != null) {
        console.log('res ==> ',res?.message[0])
        let data:any = res?.message[0]
        let alloed_user_acces:any = JSON.parse(data["_assign"])
        let owner = data["owner"]
        alloed_user_acces.push(owner)
        this.alloed_user_acces = alloed_user_acces
        console.log( '1 ==> ', this.alloed_user_acces)
      }

      //console.log( '2 ==> ', owner)

    })
  }

  openModalDetailDossier(data:any) {
    // let courrier:any = this.courriers[id];
    // console.log('details === '+ courrier);

    const config: ModalOptions = {
      backdrop: true,
      initialState: {
        data: data
      }
    }
    const modalRef  = this.modalService.show(ViewDetailsDossierComponent, Object.assign({},config,{class:'modal-xl  '}));

  }


  async getTypeOfCondamnation(){
    this.TypeDeCondamnation = (await this.jugementService.getAllCondamnationType()).data
   // console.log(this.TypeDeCondamnation)
  }
  async getCateggorieOfCondamnation(){
    this.CategorieDeCondamnation = (await this.jugementService.getAllCondamnationCategorie()).data
    // console.log(this.TypeDeCondamnation)
  }

  UpdateCategoriCondoamnatione(value){
    this.Categorie_Decision = value
    console.log(this.Categorie_Decision)
  }
  UpdateTypeCondamnation(value){
    this.Decision_Judiciaire = value
   // console.log(this.Decision_Judiciaire)
  }
  UpdateDatedeDecision(value){
    this.Date_decision = value
  }

  lignes: any[] = [];
   indexRow = 2;

  ajouterLigne() {
    this.lignes.push({id:this.indexRow++, colonne1: '', colonne2: '' });
  }
  // condamenation
  lignes_condamenations: any[] = [];
   indexRow_cond = 2;

  ajouterLigne_condamnation() {
    this.lignes_condamenations.push({id:this.indexRow_cond++, colonne1: '', colonne2: '' });
  }

  optionsSelect: string[] = ['Ferme', 'Sursis'];
  periodicite: string[] = ['An(s)', 'Mois', 'Jour(s)'];

  // ammendements
  lignes_ammendes: any[] = [];
   indexRow_ammende = 2;

  ajouterLigne_ammendements() {
    this.lignes_ammendes.push({id:this.indexRow_ammende++, colonne1: '', colonne2: '' });

  }

  deleteInfraction(infraction: any){
    this.selectedInfractions = this.selectedInfractions.filter(item => item != infraction);


  }

  deleteContrainte(element: any){
    this.lignes = this.lignes.filter(item => item != element);
  }

  deleteEmprisonnement(element: any){
    this.lignes_condamenations = this.lignes_condamenations.filter(item => item != element);
  }

  deleteAmmendes(element: any){
    this.lignes_ammendes = this.lignes_ammendes.filter(item => item != element);
  }


  async getAllInfrations() {
    this.ngxService.startLoader('viewJugement');
    try {
      this.infractions = (await this.enrollementService.getAllInfractions()).data
      this.ngxService.stopLoader('viewJugement');

    } catch (error) {
      this.ngxService.stopLoader('viewJugement');
    }

  }

  toggleSelection(infraction: string): void {
    if (this.infractions.includes(infraction)) {
      this.selectedInfractions = this.selectedInfractions.filter(item => item !== infraction);
    } else {
      this.selectedInfractions.push(infraction);
    }
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg '}));
  }

  openModalEditText(data: any) {
    let is_editable = false ;
    if (this.status_jugement.toLocaleLowerCase() !== 'renvoi' && this.status_jugement.toLocaleLowerCase() !== 'report' && this.status_jugement.toLocaleLowerCase() !== 'definitive') {
      is_editable = true
    }
    const config: ModalOptions = {
      initialState: {
        // message: message
        is_editable : is_editable,
        data_noteAudience: data,
        parentModal: null
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(EditTextComponent, Object.assign({}, config, {class:'modal-xl '}));
    this.modalRef.content.parentModal = this.modalRef;
  }

  openModalListNotes(audience: any) {

    const config: ModalOptions = {
      initialState: {
        // message: message
        doctype: this.jugementService.Doctype_jugements,
        num_audience: audience.name,
        // parentModal: null
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    // this.modalRef = this.modalService.show(WhiteboardCanvasComponent, Object.assign({}, config, {class:'modal-xl '}));
    this.modalRef = this.modalService.show(ListNotesComponent, Object.assign({}, config, {class:'modal-xl  modal-list-notes'}));

    // this.modalRef.content.parentModal = this.modalRef;
  }

  hidde() {
    if (this.modalService) {
      this.modalService.hide();
    }
  }

  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.type );
    var stepFirst = this.type;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });



  }



  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }
  async openModalVisualDecision(data: any,template:any,is_document=0) {
    console.log({'datavisualize': data});
    console.log({'temppp': template});
    console.log(this.enrolle);
    const keys1 = Object.keys(data);
    const keys2 = Object.keys(this.enrolle);
    const name_key2 = "role";

    await this.role.getOneChambre(this.enrolle.chambre).then(res=>{
      let mychambre =res.data
      console.log("get details chambre=> ", mychambre);

      this.president_chambre =  mychambre.membres.find(member => member.poste === 'PRESIDENT');
      this.assistant_chambre =   mychambre.membres.find(member => member.poste === 'Assistant 1');

      this.enrolle.president_chambre = this.president_chambre?.utilisateur ?? null
      this.enrolle.assistant_chambre = this.assistant_chambre?.utilisateur ?? null
      console.log("president chambre=> ",   this.enrolle.president_chambre);
      // this.membres =this.data.membres
    })


    const redundantKeys = keys2.filter(key => keys1.includes(key));
    console.log('redondantt',redundantKeys);


// Ajouter le nom de l'objet à partir du deuxième objet
    Object.keys(this.data).forEach(key => {
      if (keys2.includes(key) && redundantKeys.includes(key)) {
        this.enrolle[`${name_key2}.${key}`] = this.enrolle[key];
      }
    });
    const merged = Object.assign(this.enrolle,data);


    const config: ModalOptions = {
      backdrop: true,
      initialState: {
        data: merged,
        data_template: template,
        is_document:is_document,
        type_doctype: this.jugementService.Doctype_jugements,
        parentModal : null
      }
    }


     //Ouvrir le modal   ViewDetailsCourrierComponent
     return new Promise<string>((resolve, reject) => {
      this.modalRef = this.modalService.show(VisualiseDecisionComponent, Object.assign({}, config, {class:'modal-xl '}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe(async (value: string) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
        if ( value == "success") {

          // this.closeWithMessage("")

          await this.GetDocuments()
          // await this.getJugement()
          this.data['status'] = 'Document généré';
          this.CAN_SEE_TEMPLATE = false

       }


      });
    }).then(async (value: string) => {
      // if ( value == "success") {
      //    this.closeWithMessage("")
      //    this.data['status'] = 'document généré'
      // }


    });

     // Mettre à jour l'option initialState avec le modalRef
  }

  active_edit_decision(){
    this.can_edit_decision = !this.can_edit_decision
    this.ALLOWED_SAVE_DECISION = true
  }

  async getJugement() {
    console.log('init data ===> ', this.data);
    this.ngxService.startLoader('viewJugement')
    let jugement_name = this.numero_du_jugement == null ? this.data.name : this.numero_du_jugement;
    if (this.data.audience) {
      jugement_name = this.data.audience
    }
    console.log('num proce ===> ', jugement_name);
    console.log('num proce jugement_name ===> ', jugement_name);

    const fetchJugement = async () => {
        return await this.jugementService.getOneJugement(jugement_name).then(res => {
            this.ChangeStatus();
            this.data = res.data;
            console.log('num proce ===> ', this.data);
            this.status_jugement = this.data.status.toLowerCase();
            this.is_multiple_prevenu = this.data['is_multiple_prevenu'];
            this.prevenusList = this.data['association_de_prevenu'];

            this.Infractions = res.data.table_infractions;
            this.Amendes = res.data.table_amendes_j;
            this.Emprisonements = res.data.table_emp;
            this.ContrainteParCorps = res.data.table_tqhi;
            this.affiliations = res.data.affiliations;
            this.jugement_reports = res.data.jugement_reports;
            console.log('jugements report===> ', this.jugement_reports);

            this.Categorie_Decision = res.data.categorie_de_la_decision;
            this.Decision_Judiciaire = res.data.decision_judiciaire;
            if (this.Decision_Judiciaire != undefined && this.Decision_Judiciaire.toLowerCase() !== 'en attente de date du jugement' && this.Decision_Judiciaire.toLowerCase() !== 'Definitive' ) {
              this.can_edit_decision = false;
            }

            this.getDetailEnrolleJugement();
        });

    };

    fetchJugement().catch(err => {
        console.log('GET JUGEMENT ERROR ==> ', err);
        // this.alerte.errorNotification('Un problème est survenu lors de la première tentative. Nouvelle tentative...');
        this.numero_du_jugement = this.data.numero_du_jugement
        jugement_name =  this.numero_du_jugement
        // Relancer une deuxieme requete sans concatenation avec le tribunal
        fetchJugement().catch(err => {
            console.log('GET JUGEMENT ERROR (2ème tentative) ==> ', err);
            // this.modalRef.hide()
            this.bsModalRef.hide()
            this.alerte.errorNotification('cette audience n\'existe pas!');

        });
    });
    this.ngxService.stopLoader('viewJugement')
}

  async refresh(){
    this.ngxService.startLoader('viewJugement')
    try {
      this.getJugement()

    this.jugementService.getAllTypeRepports().then(res=>{
      this.type_jugement_reports = res.data
    })

    this.GetTemplateDisponible()
    this.GetDocuments()

    this.getTypeOfCondamnation()
    this.getCateggorieOfCondamnation()

    await this.getAllInfrations()
    } catch (error) {

    }
    this.ngxService.stopLoader('viewJugement')


  }

  async ngOnInit() {


    var self = this;
    this.customerService.getIdentificationType().then(res=>{
      this.identification_type = res.data
      //console.log('identification_type ==> ',this.identification_type)
      //console.log('identification_type ==> ',res)
    })
    try {
     await  this.getJugement()
    } catch (error) {

    }


    this.jugementService.getAllTypeRepports().then(res=>{
      this.type_jugement_reports = res.data
    })

    this.GetTemplateDisponible()
    this.GetDocuments()

    this.getTypeOfCondamnation()
    this.getCateggorieOfCondamnation()

    await this.getAllInfrations()

    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();

    // table_infractions

    const selectBtn = document.querySelector(".select-btn"),
    items = document.querySelectorAll(".item"),
    selectedItemsTable = document.getElementById("selectedItemsTable")
    const tableBody = document.querySelector(".details_infractionTable tbody") as HTMLTableSectionElement;

  selectBtn.addEventListener("click", () => {
    selectBtn.classList.toggle("open");
  });

  items.forEach(item => {
    item.addEventListener("click", () => {
        item.classList.toggle("checked");

        const itemText = item.querySelector(".item-text").textContent;

        if (item.classList.contains("checked")) {
            const newRow = tableBody.insertRow();
            const cell1 = newRow.insertCell(0);
            const cell2 = newRow.insertCell(1);
              cell1.textContent = itemText;
              cell2.innerHTML = '<i class="fas fa-eye m-t-3"></i>';
            // cell2.textContent = "Some data"; // Vous pouvez remplir cette colonne avec des données supplémentaires si nécessaire
        } else {
            // Supprimez la ligne correspondant à l'élément désélectionné
            const rows = tableBody.getElementsByTagName("tr");
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].cells[0].textContent === itemText) {
                    tableBody.deleteRow(i);
                    break;
                }
            }
        }
    });
  });


  // table_contraintes




  })

  // add on table reports
  $('#addRowBtn').on('click', () => {
    // Sérialiser les champs de formulaire dans un tableau d'objets JavaScript
    const formData = $('form').serializeArray();

    // Créer un objet pour stocker les valeurs des champs de formulaire
    const formValues = {};
    formData.forEach(item => {
        formValues[item.name] = item.value;
    });

    // Récupérer les valeurs des champs individuels par leur nom
    const typeReportValue = formValues['type_report'];
    const dateReportValue = formValues['date_report'];

    // Créer une nouvelle ligne avec des colonnes Bootstrap
    const newRow = '<tr>' +

                     '<td>'+typeReportValue+'</td>' +
                     '<td>'+dateReportValue+'</td>'  +


                   '</tr>';

    // Ajouter la nouvelle ligne au tableau
        // $('#myTable tbody').append(newRow);
    });

  // liste decision

  $(document).on('focus', '#decisionInput', function() {
    const listItem = $(this).siblings('.list_item');

    // Afficher la liste
    listItem.show();

    // Charger les éléments de la liste (en filtrant sur "nature" des infractions)
    loadListItems('decisionInput');
  });

  $(document).on('blur', '#decisionInput', function() {
    const listItem = $(this).siblings('.list_item');

    // Cacher la liste après un délai
    setTimeout(() => {
      listItem.hide();
    }, 200);
  });

  $(document).on('input', '#decisionInput', function() {
    const searchTerm = $(this).val().toLowerCase();
    const listItems = $(this).siblings('.list_item').find('ul li');
    const noMatchSuggestion = $(this).siblings('.no-match-suggestion');
    const newItemSpan = $(this).siblings('.no-match-suggestion').find('.new-item');

    let matchFound = false;

    // Filtrer la liste
    listItems.each(function() {
      const listItemText = $(this).text().toLowerCase();
      if (listItemText.indexOf(searchTerm) === -1) {
        $(this).hide();
      } else {
        $(this).show();
        matchFound = true;
      }
    });

    // Si aucune correspondance n'est trouvée, afficher la suggestion d'ajout
    if (!matchFound && searchTerm.trim() !== '') {
      noMatchSuggestion.show();
      newItemSpan.text(searchTerm); // Afficher la saisie de l'utilisateur comme élément suggéré
    } else {
      noMatchSuggestion.hide();
    }
  });

  $(document).on('click', '.list_item ul li', function() {
    const selectedText = $(this).text();
    $('#decisionInput').val(selectedText);
    self.UpdateTypeCondamnation(selectedText)
    $(this).closest('.list_item').hide(); // Cacher la liste après la sélection
  });

  $(document).on('click', '.no-match-suggestion', function() {
    self.decision = $('#decisionInput').val().trim();
    addItemToApi( self.decision, 'decision'); // Ajouter un nouvel élément à l'API si aucune correspondance
  });




  // liste categories


  $(document).on('focus', '#categorieInput', function() {
    const listItem = $(this).siblings('.categorieslist_item');

    // Afficher la liste
    listItem.show();

    loadListItems('categorieInput');
  });

  $(document).on('blur', '#categorieInput', function() {
    const listItem = $(this).siblings('.categorieslist_item');

    // Cacher la liste après un délai
    setTimeout(() => {
      listItem.hide();
    }, 200);
  });


  $(document).on('input', '#categorieInput', function() {
    const searchTerm = $(this).val().toLowerCase();
    const listItems = $(this).siblings('.categorieslist_item').find('ul li');
    const noMatchSuggestion = $(this).siblings('.no-match-suggestion_cat');
    const newItemSpan = $(this).siblings('.no-match-suggestion_cat').find('.new-item');

    let matchFound = false;

    // Filtrer la liste
    listItems.each(function() {
      const listItemText = $(this).text().toLowerCase();
      if (listItemText.indexOf(searchTerm) === -1) {
        $(this).hide();
      } else {
        $(this).show();
        matchFound = true;
      }
    });

    // Si aucune correspondance n'est trouvée, afficher la suggestion d'ajout
    if (!matchFound && searchTerm.trim() !== '') {
      noMatchSuggestion.show();
      newItemSpan.text(searchTerm); // Afficher la saisie de l'utilisateur comme élément suggéré
    } else {
      noMatchSuggestion.hide();
    }
  });

  $(document).on('click', '.categorieslist_item ul li', function() {
    const selectedText = $(this).text();
    $('#categorieInput').val(selectedText);
    self.UpdateCategoriCondoamnatione(selectedText)
    $(this).closest('.categorieslist_item').hide(); // Cacher la liste après la sélection
  });

  $(document).on('click', '.no-match-suggestion_cat', function() {
    self.categorie = $('#categorieInput').val().trim();
    addItemToApi( self.categorie,'categorie'); // Ajouter un nouvel élément à l'API si aucune correspondance
  });



  // Charger les éléments de la liste (par exemple depuis une API ou une liste statique)
  function loadListItems(id) {
    let ressource = id == 'decisionInput'? self.TypeDeCondamnation : self.CategorieDeCondamnation
    const items =  ressource.map(infraction => infraction.name);


    if (id == 'decisionInput') {
      $('.list_item ul').empty(); // Vider la liste avant de la remplir

      items.forEach(item => {
        $('.list_item ul').append('<li>' + item + '</li>');
      });
    }else{
      $('.categorieslist_item ul').empty(); // Vider la liste avant de la remplir

      items.forEach(item => {
        $('.categorieslist_item ul').append('<li>' + item + '</li>');
      });
    }
  }

  // Ajouter un élément à l'API (cette fonction peut appeler une API réelle)
  function addItemToApi(itemName,id) {


    const config: ModalOptions = {
      initialState: {
        label: itemName,
        type: id,
      }
    };

    console.log('openingSuccess');
    return new Promise<string>((resolve, reject) => {
      self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-lg'}));
      self.modalRef.content.parentModal = self.modalRef;
      self.modalRef.onHidden.subscribe((value: string) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
      });
    }).then(async (value: string) => {
      console.log('in adddecision', value);

    if ( value == "success") {
      self.getTypeOfCondamnation()
      self.getCateggorieOfCondamnation()
      self.alerte.successNotification("Décision",'Opération effectuée!')


    }else{
      self.alerte.errorNotification("Décision",'Echec de l\'opération !')

    }

    })

  }







  }
}
