import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AmandeService {

  Doctype_Amende ='Recouvrement Amende'


  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}

 // Fiche a classer

  async getAllRecouvrement(filter=null) {
    try {
      let my_Amende = await this.frapper.GetDoctypes(this.Doctype_Amende,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_Amende
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getOneAmende(ficheName) {
    try {
      let my_amende = await this.frapper.GetDoctype(this.Doctype_Amende,ficheName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_amende
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async nouveauRecouvrement(body) {
    try {
      let new_recouv = await this.frapper.CreateDoctype(this.Doctype_Amende,body).toPromise()
    return new_recouv
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_Amende,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
  
  }

  async updateFiche(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_Amende,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_Amende,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

 // ** Fiche a classer
}
