
<div class="d-flex  justify-content-end mr-4 pt-3" >
  <div>
     <span class="mt-2">Imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;" (click)="onPrint2(printElement)"></i>
  </div>
  <div>&nbsp;&nbsp;
     Télécharger: <i class="fas fa-solid fa-download text-success" style="font-size: 30px;" (click)="downloadAsPdf()"></i>
  </div>
 
 
</div><br>
<div class="modal-content" *ngIf="!ticketNumber" >
  <!-- Your web viewer goes here -->
  <img *ngIf="is_image" [src] = "urlSafe" id="myFrame" alt="" width="auto"  style="width:auto;min-height:500px" #elementToPrint ngxPrintElement>
  <iframe *ngIf="!is_image" id="myFrame"  [src]="urlSafe"  frameborder="2" style="width:auto;min-height:500px" #elementToPrint ngxPrintElement></iframe>
  <!-- <img [src]="urlSafe" > -->
</div>


<div class="ticket" *ngIf="ticketNumber && is_ticket" #elementToPrint ngxPrintElement>
  <div class="ticket-header">
    <h3>TICKET</h3>
    <p class="event-name">{{ tribunal }}</p>
  </div>
  <div class="ticket-body">
    <p class="text-center">N° Public : <strong>{{ num_public }}</strong></p>

  </div><br>
  <div class="ticket-footer">
    <p>Veillez bien garder le ticket !</p>
  </div>
</div><br>