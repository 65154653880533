import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomService } from '../../services/frapper/custom.service';
import { DossierService } from '../../services/frapper/dossier.service';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import {DemandesService} from "../../services/frapper/demandes.service";
import {DocumentService} from "../../services/frapper/document.service";
import { VisualizeDemandevisiteComponent } from '../visualize-demandevisite/visualize-demandevisite.component';
import { MyCONST } from '../../CONST/myConst';
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';

@Component({
  selector: 'app-add-demandes',
  templateUrl: './add-demandes.component.html',
  styleUrls: ['./add-demandes.component.scss']
})
export class AddDemandesComponent implements OnInit {
  template_demande: any
  type: any = null;
  type_doctype :any = null;
  numero_du_jugement : any = null;
  fileNames: { [key: string]: string } = {};
  imageFile:any ={}
  is_stepped: boolean = false;
  data: any = null;
  getTheFiles: boolean = false;
  pagefiles:number = 1;
   _elementsArray = []
  listedemandes = [
    {

      type_demande:"visite detenu",

      content:{
        nom_visiteur: '',
        prenom_visiteur: '',
        date_naissance: '',
        lieu_naissance: '',
        num_piece: '',
        date_delivrance_piece: '',
        profession: '',
        adresse: '',
        affiliation: '',
        lieu_detention: '',
        nom_detenu: '',
        prenom_detenu: '',

      },
    },
    {
      type_demande:"autre",

      content:{
        testchamp1: '',
        testchamp2: '',
        testchamp3: '',
        testchamp4: '',

      },
    },
    {
      type_demande:"xxxxx",

      content:{
        nom_visiteur: '',
        prenom_visiteur: '',
        date_naissance: '',
        lieu_naissance: '',
        num_piece: '',
        date_delivrance_piece: '',
        profession: '',
        adresse: '',
        affiliation: '',
        nom_detenu: '',
        prenom_detenu: '',
        lieu_detention: '',
      },
    },
  ]
  widthPercentage: number;
  steps_demande: any = [];
  item:any;
  fieldsByStep: any[] = [];
  elementsArray: Array<{ key: string; value: any }> = [];

  my_tribunal:any=null;

  categorie:any=null
  constructor(private modalService: BsModalService,
    public options: ModalOptions,
    private formBuilder: FormBuilder,
    private dossierService:DossierService,
    private customservice:CustomService,
              private demandeService:DemandesService,
              private documentService:DocumentService,
    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
    private zone: NgZone,
    private custom: CustomService,
    public bsModalRef: BsModalRef,
    private userCourrierService:UsercourrierService,
    private penalService: PenalCourrierService) {
    this.type =  this.options.initialState.type;
    this.data =  this.options.initialState.data != undefined ? this.options.initialState.data:null;
    this.item =  this.options.initialState.item;
    this.type_doctype = this.options.initialState.type_doctype;
    this.numero_du_jugement = this.options.initialState.numero_du_jugement;
    console.log('the type=>', this.type);

    try{
      this.categorie =  this.options.initialState.categorie;
    }catch (e) {
      this.categorie =  null
    }
    try{
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }

    console.log('thedaattta====> ', this.data)
  }


  generateRandomString(): any {
    const length = 5;
    const characters = '0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return  result;
  }

  closeModal(){
    this.bsModalRef.hide()
  }
  replaceUndefinedValue(obj) {
    for (let key in obj) {
        if (obj[key] === undefined) {
            obj[key] = '';
        }
    }
    return obj;
}
  replaceUndefinedWithEmptyString(value) {
  return value === undefined ? '' : value;
}




// adapter le content si la demande a des steps
 buildContent(steps, formValues) {
  let content = {};

  steps.forEach((step, u) => {
      content[`step${u}`] = {
          name_step: step.name_step,
          fields: {}
      };

      this.fieldsByStep[u].forEach(field => {
          // Récupérer la valeur du champ dans le formulaire ou mettre une chaîne vide
          content[`step${u}`].fields[field.key] = this.replaceUndefinedWithEmptyString(formValues[field.key]);
      });
  });

  return content;
}
closeWithMessage(message: string) {
  this.bsModalRef.hide();
  this.bsModalRef.onHidden.emit(message);
}


  AddDemande(demande: NgForm) {
  this.ngxService.start();
  // Charger les images dans les valeurs du formulaire
  const ImagesCharged = Object.keys(this.imageFile);
  for (let key of ImagesCharged) {
      demande.value[key] = this.imageFile[key];
  }

  console.log('document body sended', this.data);

  // Construire le corps de la requête
    if (this.categorie=='document'){
      let body = {
        template_name: this.item.name,
        template: this.item.template, //default template of type_demande
        is_decision:0,
        flag: this.item.flag,
        type_doctype: this.type_doctype,
        numero_du_jugement: this.type_doctype == "Dossier"? this.numero_du_jugement: this.data.name,
        content: this.is_stepped? this.buildContent(this.steps_demande, demande.value): this.replaceUndefinedValue(demande.value)
      };
      if(this.my_tribunal !=null){
        body["tribunal"]= this.my_tribunal.name
      }
      console.log('document body sended', body);
     try {
      this.documentService.nouveauDocument(body).then(async res => {
          this.alerte.successNotification(this.item.name, 'Document généré avec succè!');
          // this.bsModalRef.hide();

          // mis a jours des contraintes a corps dans dossier
          if (this.type_doctype == "Dossier" || this.type_doctype == "Jugement") {
            if (this.type == "MANDAT DE DEPOT") {
              let table_tqhi = {
                "table_tqhi": [{
                  "debut": demande.value['debut'],
                  "fin": demande.value['fin'],
                  "main_leve": demande.value['main_leve']
                }]

              }
              console.log('table_tqhi', table_tqhi);

              await this.dossierService.updateDossier(this.numero_du_jugement,table_tqhi).then(res=>{

                this.alerte.successNotification('Dossier','Mise a jour effectuee!')
              console.log('dossier updated: ', res)
                this.ngxService.stop()
              },err=>{
                this.alerte.errorNotification('Dossier','Erreur lors de la mise a jour du dossier')
                this.ngxService.stop()
              })
            }

          }
          this.closeWithMessage("success")
          this.ngxService.stop();
          this.openModalVisualizeD_visite(res.data)


      }, err => {
        this.ngxService.stop();
      });
     } catch (error) {
      this.alerte.successNotification(this.item.name, 'Impossible d\'effectuer l\'operation !');
      this.ngxService.stop();
     }

    }
    else {

      let body = {
        type_demande: this.item.name,
        code: this.generateRandomString(),
        flag: this.item.flag,
        content: this.is_stepped? this.buildContent(this.steps_demande, demande.value): this.replaceUndefinedValue(demande.value)
      };
      if(this.my_tribunal !=null){
        body["tribunal"]= this.my_tribunal.name
      }
      // Logique de soumission de la demande
      this.demandeService.nouvelleDemande(body).then(res => {
        this.alerte.successNotification(this.item.name, 'Demande effectuée avec succès!');
        this.closeWithMessage("success")
        this.ngxService.stop();
      }, err => {
        this.alerte.successNotification(this.item.name, 'Impossible d\'effectuer l\'operation !');
        this.ngxService.stop();
      });
    }



}

openModalVisualizeD_visite(_data) {

  const config: ModalOptions = {
    initialState: {
      data: _data,



    }
  }

  console.log('inthevisuallllll=>', _data);

   // Ouvrir le modal
   const modalRef = this.modalService.show(VisualizeDemandevisiteComponent, Object.assign({}, config, { class: 'modal-lg ' }));

}
openModalVisualPiece(urlpiece: any = null) {
      const URL=MyCONST.API_URL+urlpiece
      console.log('dddd',urlpiece);
      if (urlpiece != "") {
        const config: ModalOptions = {
          initialState: {
            data: URL,
            url:URL,
            parentModal : null
          }
        }

         // Ouvrir le modal
         const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg modal-dialog-centered' }));

      }else{
        this.alerte.warningNotification('aucune piéce téléchargée')
      }

       // Mettre à jour l'option initialState avec le modalRef
    }
  initializeStepper() {


    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);

      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });
//   $(document).ready(function() {
//     $(".file-input").on("change", function() {
//         // Récupérer le nom du fichier sélectionné
//         const fileName = $(this).val().split("\\").pop();
//         // Trouver l'élément span à l'intérieur du label parent et mettre à jour son texte
//         $(this).parent().find('.file-label').text(fileName);
//     });
// });
  }

//   getFormInputs() {
//        // verifier si il ya des steps
//        const nameStepCount = this.steps_demande.reduce((count, step) => {
//         return count + (step.name_step ? 1 : 0);
//     }, 0);
//     if (nameStepCount>0) {
//       this.is_stepped = true
//     }

//     if (this.is_stepped) {
//       this.fieldsByStep = this.steps_demande.map(step => this.getFields(step));
//       console.log('kkkkk',this.fieldsByStep);

//     }else{
//       for (const [key, value] of Object.entries(this.template_demande.content)) {
//         this.elementsArray.push({ key, value });
//       }
//     }





//     console.log('is name_step encountered: ', this.is_stepped);
// }
getFormInputs() {
  this.steps_demande = [];
  this.elementsArray = [];

  // Récupérer les clés de template_demande.content
  const keys = Object.keys(this.template_demande.content);

  // Vérifier s'il y a des clés qui commencent par 'step'
  const hasSteps = keys.some(key => key.startsWith('step'));

  if (hasSteps) {
    this.is_stepped = true;
    this.steps_demande = keys
      .filter(key => key.startsWith('step'))
      .map(key => this.template_demande.content[key]);

    this.fieldsByStep = this.steps_demande.map(step => this.getFields(step));
  } else {
    this.is_stepped = false;
    for (const [key, value] of Object.entries(this.template_demande.content)) {

      this.elementsArray.push({ key, value });
    }
  }

  console.log('elemetsss ', this.elementsArray);
}



getFields(step: any): any[] {
    return Object.entries(step)
        .filter(([key]) => key !== 'name_step')
        .map(([key, value]) => ({ key, value }));
}



  // getFormInputs(){
  //   for (const [key, value] of Object.entries(this.template_demande.content)) {
  //     this.elementsArray.push({ key, value });
  //   }
  //   console.log('the lengggg', this.elementsArray.length);

  //   this.widthPercentage = 100 / this.elementsArray.length;

  //   console.log('the lengggg perrr', this.widthPercentage);
  // }

  getKeys(content: any): string[] {
    return content ? Object.keys(content) : [];
  }
  // get the files url for file input
  getUrlFiles(){
    if (this.data != null) {
      const keys = Object.keys(this.data.content);
    // Vérifier s'il y a des clés qui commencent par 'step'
    const hasSteps = keys.some(key => key.startsWith('step'));


    if (hasSteps) {
      let mysteps_demande = keys
        .filter(key => key.startsWith('step'))
        .map(key => this.data.content[key]);

      let _fieldsByStep = mysteps_demande.map(step => this.getFields(step));
      console.log('fields=> ',_fieldsByStep);
      _fieldsByStep.forEach(itemArray => {
        itemArray.forEach(item => {
            if (item.key === "fields") {
                const fields = item.value;
                // Parcourir les champs et afficher les valeurs
                for (const [key, value] of Object.entries(fields)) {
                    console.log(`${key}: ${value}`);
                    if (key.startsWith('file_')) {
                      this._elementsArray.push({key,value})
                    }

                }

            }
        });
      });
      console.log('the _elets=>', this._elementsArray);

    } else {

      for (const [key, value] of Object.entries(this.data.content)) {

        this._elementsArray.push({ key, value });
      }
      console.log('eletsarr=> ',this._elementsArray);
    }
    }


  }



  onFileSelected(event: any, key: string) {
    let image: any = event.target.files[0] as File;

    if (image) {
      this.fileNames[key] = image.name; // Store the name of the file
      console.log('this.imageFile ==> ', image.name);
      this.createPiece(image, key);
    }
  }

  createPiece(file: File, key: string) {
    this.ngxService.start();

    const formData1 = new FormData();
    formData1.append('is_private', '0');
    formData1.append('file', file);

    this.custom.createFiles(formData1).then(res => {
      // Update imageFile or fileNames after upload completes
      this.imageFile[key] = res.message.file_url;
      console.log('lll',this.imageFile[key]);

      // Optionally, store the filename in fileNames if needed
      this.fileNames[key] = res.message.file_name || this.fileNames[key]; // Update the filename if returned
      console.log(this.fileNames)
      this.ngxService.stop();
    }, err => {
      this.ngxService.stop();
      this.alerte.errorNotification('Pieces', 'Une erreur s\'est produite!');
    });
  }
  formatLabel(key: string): string {
    // remove "file_" prefix and format the rest of the key
     if (key.startsWith('file_')) {
       return key.replace('file_', '').replace(/_/g, ' ').replace(/\b\w/g, char => char); // Remove "file_" and format
     }

     return key.replace(/_/g, ' ').replace(/\b\w/g, char => char);
     }



  ngOnInit() {
    // pour charger les files
    if (this.type.includes('NATIONALITE')) {
      this.getUrlFiles()
    }



    //this.template_demande = this.listedemandes.find(template => template.type_demande.toLowerCase() === this.type.toLowerCase());
    this.template_demande =this.item
    console.log('thetemplate=>',this.template_demande );

    this.steps_demande = Object.values(this.template_demande.content);
    console.log('steps demandede==> ', this.steps_demande);
    this.widthPercentage = 100 / this.steps_demande.length;
    this.getFormInputs()

    console.log('with value ',this.elementsArray);
    console.log('add demande template', this.template_demande);

    if (this.template_demande == null) {
      this.bsModalRef.hide();
      this.alerte.warningNotification('ajout demande','Aucune demande correspondante')
    }

    var self = this;
    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();
      console.log('jjbjjn');
        // Ajouter une nouvelle ligne au tableau
        $('#addRowBtn').on('click', function() {

        });

        // Gérer la suppression d'une ligne
        $('#myTable').on('click', '.delete-row', function() {
          $(this).closest('tr').remove(); // Supprimer la ligne parente (tr) du clic sur l'icône poubelle (delete-row)
        });



    })
  }

}
