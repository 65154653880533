<!-- <ngx-calendar></ngx-calendar> -->

<ngx-one-column-layout class="uu">
  <body style="background-color: transparent;">
    <div class="row ml-1 mr-1">
      <div class="col-md-12 grid-margin" >
          <div class="row d-flex justify-content-center ">
              <div class="col-12 group2 d-flex justify-content-center">
                  <h3 class="font-weight-bold ">VERIFICATION DU DOCUMENT</h3>
              </div>
          </div>
      </div>

  </div>
  <div>
    <mat-spinner></mat-spinner>
  </div>

    <div class="cards col-md-11 col-sm-11 col-lg-11" style="background-color: transparent;">
      <div class="card-single" (click)="alerteNotifications('OK')">
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex justify-content-between mt-2">
                    <div class="circle mr-4">
                        <span class="fas  fa-solid fa-paperclip"></span>
                    </div>
                    <div class="d-flex flex-column m-auto  nameservice ">
                        <span style="font-size: 14px;" class="text-nowrap title">CASIER JUDICIAIRE</span>
                        <p class="align-left">C-00000001</p>
                    </div>
                </div>
            </div>
        </div>

      <div class="card-single" (click)="alerteNotifications('NOK')">
        <div class="d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-between mt-2">
            <div class="circle mr-4">
              <span class="fas  fa-solid fa-paperclip"></span>
            </div>
            <div class="d-flex flex-column m-auto  nameservice ">
              <span style="font-size: 14px;" class="text-nowrap title">CERTIFICAT DE NATIONALITE</span>
              <p class="align-left">C-00000002</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card-single" (click)="openModal()">
          <div>
              <h1 id="project"></h1>
              <span>Extrait de naissance</span>
          </div>
          <div class="circle">
            <span class="fas fa-users"></span>
          </div>
      </div>
      <div class="card-single" (click)="openModal()">
          <div>
              <h1 id="order"></h1>
              <span>Certificat nationnalite</span>
          </div>
          <div class="circle">
              <span class="fas fa-regular fa-file-import"></span>
          </div>
      </div>

      <div class="card-single" (click)="openModal()">
        <div>
            <h1 id="order"></h1>
            <span>Autres</span>
        </div>
        <div class="circle">
          <span class="fas fa-regular fa-file"></span>
        </div>
    </div> -->

    </div>
    <div class="col col-md-6 col-sm-12" style="margin-top: -10px;text-align: center;margin: auto;">
  </div>

    <!--<table class="table table-striped col-sm-12 col-md-12  align-items-center table-dossier mb-0 text-center m-auto" style="width: 100%; " *ngIf="!loadindata">
      <thead style="font-size: 12px; " >
          <tr >
              <th class="text-uppercase text-light text-xxs font-weight-bolder opacity-7">Numéro</th>
              <th class="text-uppercase text-light  text-xxs font-weight-bolder opacity-7">Type document</th>
              <th class="text-uppercase text-light  text-xxs font-weight-bolder opacity-7">Montant</th>
              &lt;!&ndash; <th class="text-uppercase text-light  text-xxs font-weight-bolder opacity-7 ps-2">Registre</th> &ndash;&gt;
              <th class="text-center text-uppercase text-light  text-xxs font-weight-bolder opacity-7">Statut</th>
              <th class="text-center text-uppercase text-light  text-xxs font-weight-bolder opacity-7">Date</th>
              <th class="text-center text-uppercase text-light  text-xxs font-weight-bolder opacity-7">Auteur</th>
              <th class="text-center text-uppercase text-light  text-xxs font-weight-bolder opacity-7" >Actions</th>
          </tr>
      </thead>
      <tbody style="font-size: 11px;" >
          <tr *ngFor="let element of dataSource ; let i= index">
              <td>
                  <div class="px-2 text-nowrap">{{element.ref}}</div>
              </td>
              <td>
                  <div class="d-flex px-2 py-1">
                      <div class="px-2"></div>
                      <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm" style="font-size: 11px;"> {{element.service.name}}</h6>
                      </div>
                  </div>
              </td>
              <td>
                <span class="text-secondary text-xs font-weight-bold2" >{{element.service.amount}} Fcfa</span>
              </td>
              <td>
                <span class="text-center bg-dark  text-light p-2" style="font-size: 9px;">{{element.status=='BILLING'? 'EN ATTENTE DE PAIEMENT': element.status=='WAITING TRAITEMENT'? 'EN ATTENTE DE TRAITEMENT': element.status=='TRAITEMENT ON PROGRESS'? 'EN COUR DE TRAITEMENT': 'TERMINEE'}}</span>
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{element.created_at | date:'dd-MM-yyyy / HH:mm:ss '}}</span>
              </td>
              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> agent-02</span>
              </td>

              <td class="align-middle m-auto" >

                <a href="javascript:;" *ngIf="element.type=='D'"  class="text-secondary font-weight-bold text-xs m-auto " data-toggle="tooltip"
                data-original-title="Edit user" (click)="openPaiementDialog(element.service, element.ref)">
                <i class="fas fa-solid fa-eye  {{element.status==='BILLING'?'text-danger' :'text-success'}} " style="font-size: 20px; margin-left:50%;"
                    ></i>
                </a>&nbsp;
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs m-auto " data-toggle="tooltip"
                data-original-title="Edit user" (click)="openDemandeDetailDialog(element.service, element.ref)">
                <i class="fas fa-solid fa-eye  text-primary" style="font-size: 20px; margin-left:50%;"
                    ></i>
                </a>

              </td>
          </tr>
          <tr>
              <td colspan="8" class="text-center">
                  &lt;!&ndash; <pagination-controls  (pageChange)="pageDossier = $event" previousLabel="Précédent" nextLabel="Suivant"
                      responsive="true" [id]="'dossier'"></pagination-controls> &ndash;&gt;
                        <mat-paginator style="width: 100%; margin: auto;margin-left: 15px;" [length]="total_operation"
                            [pageSize]="10"

                            (page)="changePage($event)"
                            aria-label="Select page">
                        </mat-paginator>
                      &lt;!&ndash; <ngb-pagination [pageSize]="25" [collectionSize]="total_operation" [(page)]="currentPage" ></ngb-pagination> &ndash;&gt;
                      &lt;!&ndash; <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="totalItems" (page)="onPageChange($event)"></mat-paginator> &ndash;&gt;

              </td>
          </tr>
      </tbody>

    </table>-->

</body>


</ngx-one-column-layout>
