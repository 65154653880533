import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { SuccessDialogComponent } from '../successDialog/success-dialog.component';


@Component({
  selector: 'ngx-form-courier',
  templateUrl: './detailView.component.html',
  styleUrls: ['./detailView.component.scss']
})
export class DetailViewComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  type:any;
  profil: number;
  pageDossier: number = 1;
  @Input() parentModal: BsModalRef;
  modalRef!: BsModalRef;


  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,private penalService: PenalCourrierService,private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.type = this.options.initialState.type;

    console.log(this.data)
  }

  openModalSuccessDialog() {
    const config: ModalOptions = {
      backdrop: true,


      initialState: {
        message: 'Dossier assigne avec success'
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm '}));
  }



  ngOnInit() {

    console.log('dfdff'+ this.type+'and data' + this.data);




  }
}
