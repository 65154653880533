import { Component, ViewChild } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AlertesService } from '../../services/public/alertes.service';
import { Config, NgxPrintElementService } from 'ngx-print-element';

@Component({
  selector: 'app-document-generation-public',
  templateUrl: './document-generation-public.component.html',
  // styles: [':host{display:contents}'], // Makes component host as if it was not there, can offer less css headaches. Use @HostBinding class approach for easier overrides.
})
export class DocumentGenerationPublicComponent {
  SERVICE : any
  OPERATION_REF: any
  KEYS_GEN:any = []

  TEMPLATE :any

  is_printing = false
  qrCodeimg = 'None'
  data:any;
  operation_ref:any;
  @ViewChild('elementToPrint') printElement!:any;


  constructor(private modalService: BsModalService,public options: ModalOptions,private alerte: AlertesService,  public print: NgxPrintElementService,
   ) {
    this.data = options.initialState.data;
    this.operation_ref = options.initialState.operation_ref;
    this.KEYS_GEN = options.initialState.KEYS_GEN;


    this.SERVICE = this.data
    this.OPERATION_REF= this.operation_ref
    this.KEYS_GEN = this.KEYS_GEN

    this.qrCodeimg = this.OPERATION_REF

    this.TEMPLATE = this.SERVICE.resp_template
    if (this.TEMPLATE == null) {
      alerte.warningNotification('', 'Aucune t\'emplate');
    }
    
    //console.log(this.KEYS_GEN)
    this.tranforKey()
  }


  closeAllDialogs(){
    this.modalService.hide();
  }


  tranforKey(){
    for(let val of this.KEYS_GEN){
      try{
        const keys = Object.keys(val);
        const REST = this.KEYS_GEN.find((b:any) => b[keys[0]] != undefined)
        console.log(keys[0], ' ====> ',REST[keys[0]])
        this.TEMPLATE = this.TEMPLATE.replace('{'+keys[0]+'}',REST[keys[0]])
      }catch (e) {
        console.log('ERROR')
      }

    }
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const date = `${year}-${month}-${day}`;

    this.TEMPLATE = this.TEMPLATE.replace('{TODAY}',date)
  }

  public config: Config = {
    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    
    // htmlType: 'text',
    stylesheets: [],

    styles: [
    `
    
      #papier{
        background: #802E2EFF;
      }
    `
    ]
  }

  onPrint2(el: any) {
    this.print.print(el, this.config).subscribe(console.log);

    // if(this.type=='unprint'){
    //   this.alerte.warningNotification('Casier','Impossible d\'imprimer en mode apercu !')
    // }else {
    //   try {
    //     this.print.print(el, this.config).subscribe(console.log);

    //   } catch (error) {
    //     console.log('dddddd==> '+ error);
    //   }
    // }
  }

  onPrint(divName: any) {
    
    
    // try {
    //   const printContents = document.getElementById(divName)?.innerHTML;

    //   if (printContents != null) {
    //     const printWindow = window.open('');
    //     printWindow!.document.write(printContents);
    //     printWindow!.document.close();
    //     printWindow!.focus();
    //     printWindow!.print();
    //     printWindow!.close();
    //   }

    //   this.closeAllDialogs();
    // } catch (e) {
    //   this.closeAllDialogs();
    // }
  }



  // onPrint(divName:any) {
  //   try{

  //     this.is_printing = false
  //     // const divName = 'papier';
  //     const originalContents = document.body.innerHTML;
  //     const printContents = document.getElementById(divName)?.innerHTML;

  //     if (printContents != null) {
  //     document.body.innerHTML = printContents;
  //     }

  //     window.print()
  //     // this.is_printing = false
  //     document.body.innerHTML = originalContents;



  //     // this.closeAllDialogs()


  //   }catch (e) {
  //     this.closeAllDialogs()
  //   }


  // }

  ngOnInit(): void {
  }


  launchDialog(dialogRef: any) {
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe();
  }
}
