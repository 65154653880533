import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";
import {MyCONST} from "../../CONST/myConst";

@Injectable({
  providedIn: 'root'
})
export class FrapperService {

  private apiUrl =  MyCONST.API_URL ;//'https://justice.insoftsas.com';
  constructor(private http: HttpClient, private _route: Router,) {}

  clearSomeLocalStorage(): void {
    // get all variable of localstorage
    const keys = Object.keys(localStorage);
  
    // Parcourir les clés et supprimer celles qui ne sont pas 'device_id'
    keys.forEach(key => {
      if (key !== 'DEVICE_ID' && key !== 'DEVICE_ID_CODE') {
        localStorage.removeItem(key); 
      }
    });
  
    console.log('Tous les éléments sauf device_id ont été supprimés du localStorage.');
  }

  login(username: string, password: string): Observable<any> {
    if (localStorage.length>0) {
      localStorage.clear()
      // this.clearSomeLocalStorage()
    }
    const url = this.apiUrl+'/api/method/casier_judiciaire.my_api.login'
    return this.http.post(url, { usr:username, pwd:password });
  }
  logOut(){
    // localStorage.removeItem('profil');
    // localStorage.removeItem('access_key');
    // localStorage.removeItem('me');
    // this.clearSomeLocalStorage()
    localStorage.clear()
    sessionStorage.clear() // clean the session
   
    this._route.navigate(['/']);
  }
  

  getToken(): any {
    const token = localStorage.getItem('access_key');
    if (token != null) {
      return token
    }
    // console.log('thththt',this.token);

    return null;
  }

  GetUserRoles(email): Observable<any> {
    const token = localStorage.getItem('access_key');
    const url = this.apiUrl+'/api/method/frappe.core.doctype.user.user.get_roles?uid='+email

    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.get(url, { headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }
  }

  GetUsersInformations(email): Observable<any> {
    const token = localStorage.getItem('access_key');
    const url = this.apiUrl+'/api/resource/User/'+email

    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.get(url, { headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }
  }

  GetDoctypes(DotypeName: string,Fields: string,Filter: string =null): Observable<any> {

    const token = localStorage.getItem('access_key');
    let url =''
    Filter == null ?url =this.apiUrl+'/api/resource/'+DotypeName+'?order_by=modified%20desc&fields='+Fields+'&limit_page_length=10000' :
      url =this.apiUrl+'/api/resource/'+DotypeName+'?order_by=modified%20desc&fields='+Fields+'&filters='+Filter+'&limit_page_length=10000'



    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.get(url, { headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }

  }

  GetDoctype(DotypeName: string,DocName: string,Fields: string,Filter: string =null): Observable<any> {

    const token = localStorage.getItem('access_key');
    let url =''
    Filter == null?url =this.apiUrl+'/api/resource/'+DotypeName+'/'+DocName+'?fields='+Fields
      : url =this.apiUrl+'/api/resource/'+DotypeName+'/'+DocName+'?fields='+Fields+'&filters='+Filter

    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.get(url, { headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }

  }

  CreateDoctype(DotypeName,data): Observable<any> {
    const token = localStorage.getItem('access_key');

    let url =this.apiUrl+'/api/resource/'+DotypeName
    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.post(url, {data: data} ,{ headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }
    return null
  }


  UploadFile(data): Observable<any> {
    const token = localStorage.getItem('access_key');

    let url =this.apiUrl+'/api/method/upload_file'
    if (token) {
      const headers = new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.post(url, data ,{ headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }
    return null
  }

  UpdateDoctype(DotypeName,docname,data): Observable<any> {
    const token = localStorage.getItem('access_key');

    let url =this.apiUrl+'/api/resource/'+DotypeName+'/'+docname
    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.put(url, {data: data} ,{ headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }
    return null
  }

  ChangeStateOfDoctype(data): Observable<any> {

    return null
  }


  CallCustomAPI(route,data) : Observable<any>{
    const token = localStorage.getItem('access_key');

    let url =this.apiUrl+'/api/method/'+route
    // Vérifiez si le token existe
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Basic ${token}`
      });
      return this.http.post(url, data ,{ headers }).pipe()
    } else {
      console.error('Token not found in localStorage.');
      this.logOut()
    }
    return null
  }

}
