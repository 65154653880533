import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { DetailViewComponent } from '../../widgets/detailsview/detailView.component';
import { AddDossierComponent } from '../add-dossier/add-dossier.component';
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';
import {DossierService} from "../../services/frapper/dossier.service";
import {MyCONST} from "../../CONST/myConst";
import {AddPieceComponent} from "../add-piece/add-piece.component";
import {AlertesService} from "../../services/public/alertes.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import { ViewDetailsTransmissioncourrierComponent } from '../view-details-transmissioncourrier/view-details-transmissioncourrier.component';
import {TransmissioncourrierComponent} from "../transmissioncourrier/transmissioncourrier.component";
import {CustomService} from "../../services/frapper/custom.service";
import { DatePipe } from '@angular/common';
import {RegistreService} from "../../services/frapper/registre.service";


@Component({
  selector: 'ngx-form-courier',
  templateUrl: './view-details-courrier.component.html',
  styleUrls: ['./view-details-courrier.component.scss']
})
export class ViewDetailsCourrierComponent implements OnInit{
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  dataAllInformation:any;
  dataPiecesJointes:any  =[];
  type: any;
  courriers: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  isButtonDisabled: boolean = true;
  latestSt = null;
  profil: string ;
  Information_Dossier =[]
  @Input() parentModal: BsModalRef;

  Allow_Piece_Actions = false
  Allow_send_To_Procureur = false
  Allow_send_To_Greffe = false
  Allow_send_To_President = false
  Allow_send_To_External = false

  CAN_CREATE_DOSSIER = false
  ACTION_ALLOWED = false

  ALL_ST =[];
   user_info;

  rolesAutorises = [
    'AGENT DU COURRIER',
    'AGENT DU COURRIER PRESIDENT',
    'AGENT DU COURRIER GREF',
    'AGENT DU COURRIER CIVIL'
  ];

  workFlowsActionsAllowed:any = []

  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
              private ngxService: NgxUiLoaderService,
              private alerte:AlertesService,
              private customService:CustomService,
              private penalCourrierService: PenalCourrierService,
              private userCourrierService: UsercourrierService,
              private registreService: RegistreService,
              private dossierService: DossierService,
              public bsModalRef: BsModalRef,
              private datePipe: DatePipe,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    // this.type = this.options.initialState.type;

     this.user_info = JSON.parse(localStorage.getItem('me')).name
     //console.log('the user: ',this.user_info);
    //console.log({'the details courrier ': this.data})

  }



  activePane = 0;

  onTabChange($event: number) {
    this.activePane = $event;
    console.log('onTabChange', $event);
  }

  actionToDo(){


    if(this.dataAllInformation.workflow_state=='Bureau courrier'){
      this.Allow_Piece_Actions = true
      console.log(this.profil)
      this.registreService.getOneRegistreNoAuth(this.dataAllInformation.registre).then(res =>{
        console.log('res.message ==> ',res.message.registre)
        let registre = res.message.registre //
        if(registre.is_exit_registre !='1'){
          this.Allow_send_To_External =false
          if(this.profil=='AGENT DU COURRIER'){
            this.Allow_send_To_Procureur = true
          }else {
            // this.Allow_send_To_Greffe = true
            //this.Allow_send_To_President = true
            if(this.profil=='AGENT DU COURRIER PRESIDENT'){
              this.Allow_send_To_President = true
              this.Allow_send_To_Greffe = false
            }
            if(this.profil=='AGENT DU COURRIER GREF'){
              this.Allow_send_To_President = false
              this.Allow_send_To_Greffe = true
            }
            if(this.profil=='AGENT DU COURRIER CIVIL'){
              this.Allow_send_To_President = true
              this.Allow_send_To_Greffe = true
              this.Allow_send_To_Procureur = true
            }

          }
        }else{
          if (this.profil=='AGENT DU COURRIER PRESIDENT' || this.profil=='AGENT DU COURRIER GREF' || this.profil=='AGENT DU COURRIER CIVIL' || this.profil=='AGENT DU COURRIER') {
            this.Allow_send_To_External =true
          }

        }
      },err =>{
        this.alerte.errorNotification('Registre', 'Le Registre mentionn3 n\'est plus disponible !')
      })

    }
    else {
      this.Allow_Piece_Actions = false
      this.Allow_send_To_Procureur = false
      this.Allow_send_To_Greffe = false
      this.Allow_send_To_President = false
    }

    console.log('PROFIl ',this.profil.toUpperCase() )
    // if(this.data.registre!='Courrier Depart'){
      if(this.data.categorie!='sortant'){
      if(this.profil.toUpperCase() == 'JUGE' || this.profil.toUpperCase() == 'GREFFIER EN CHEF' || this.profil.toUpperCase() == 'SUB PROCUREUR' || this.profil.toUpperCase() == 'PROCUREUR' || this.profil.toUpperCase() == 'PRESIDENT DU TRIBUNAL')
      {
        this.CAN_CREATE_DOSSIER =true
      }

    }

    if(!this.rolesAutorises.includes(this.profil)){
      this.getDossierAttribue(this.data.numero_courrier, this.data.numero_dossier)
    }


  }

  getAllCourriers() {
    // this.courriers = this.penalCourrierService.getCourrier();
   // console.log({'the couuuurrriieerrss': this.courriers});
  }
  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }

  getAllCourriersInternes() {
    this.internesCourriers = this.data;
  }





  openModalDetailView(data: any,type: string) {
    const config: ModalOptions = {
      initialState: {
        data: data,
        type: type,
        parentModal : null
      }
    }

     // Ouvrir le modal
     const modalRef = this.modalService.show(DetailViewComponent, Object.assign({}, config, { class: type == 'courrier' || type == 'substituts'  ? 'modal-md ': 'modal-lg '  }));

     // Mettre à jour l'option initialState avec le modalRef
     modalRef.content.parentModal = modalRef;
  }


  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        type_doctype:this.userCourrierService.Docatype_courrier_principal,
        num_courrier: this.data.numero_courrier,
        doctype:this.dataAllInformation,
        field_to_update:'pieces_jointe_au_courrier'
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    const modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg '}));
  }
  imprimeNumero(data) {
    const config: ModalOptions = {
      initialState: {
       
        data: data,
        is_ticket : true
      }
    }



    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    // Mettre à jour l'option initialState avec le modalRef
  }
  openModalVisualPiece(key) {
    const URL=MyCONST.API_URL+key.fichier
    console.log('urllll ', URL);
    const config: ModalOptions = {
      initialState: {
        data: URL,
        url:URL,

        parentModal : null
      }
    }



    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    // Mettre à jour l'option initialState avec le modalRef
  }
  // openModalVisualPiece(data: any) {
  //   const URL=MyCONST.API_URL+data.fichier
  //   const config: ModalOptions = {
  //     initialState: {
  //       data: 'https://petapixel.com/assets/uploads/2022/06/what-is-a-jpeg-featured.jpg',
  //       url:URL,
  //       parentModal : null
  //     }
  //   }

  //   // Ouvrir le modal
  //   const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

  //   // Mettre à jour l'option initialState avec le modalRef
  // }

  closeModal(){
    this.bsModalRef.hide()
  }

  openModalTransmission(data: any,type: string) {
    const config: ModalOptions = {
      ignoreBackdropClick: false,
      backdrop: true,
      initialState: {

        data: data,
        type: type,
        parentModal : null
      }
    }
    return new Promise<string>((resolve, reject) => {
      const modalRef = this.modalService.show(TransmissioncourrierComponent, Object.assign({}, config, {class:'modal-xl '}));
       modalRef.content.parentModal = modalRef;
       modalRef.onHidden.subscribe((value: string) => {
         resolve(value); // Résoudre la promesse avec la valeur retournée
       });
     }).then(async (value: string) => {
 
       if ( value == "success") {
         this.closeWithMessage("")
       }
 
 
     });
    const modalRef = this.modalService.show(TransmissioncourrierComponent, Object.assign({}, config, { class:   'modal-xl '  }));

    // Mettre à jour l'option initialState avec le modalRef
    modalRef.content.parentModal = modalRef;

  }

  openModalAddDossier(step: string,data:any) {
    data = this.dataAllInformation
    const config: ModalOptions = {
      backdrop: true,
      initialState: {
        showAddCourrier: false,
        data: data,
        step: step,
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    // this.penalCourrierService.hiddeModal(this.parentModal);


    return new Promise<string>((resolve, reject) => {
     const modalRef = this.modalService.show(AddDossierComponent, Object.assign({}, config, {class:'modal-xl modal-addDossier '}));
      modalRef.content.parentModal = modalRef;
      modalRef.onHidden.subscribe((value: string) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
      });
    }).then(async (value: string) => {

      if ( value == "success") {
        this.closeWithMessage("")
      }


    });


  }
  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  async getDossierAttribue(numero_courrier,numero_dossier=null){
    try {
      let filter = '[["numero_courrier" , "=","' + numero_courrier + '"]]'
    let filter_1 = '[["name" , "=","' + numero_dossier + '"]]'

    let dossiers = (await this.dossierService.getAllMyDossiers(filter_1)).data
    console.log('numero_courrier ===> ',numero_courrier)
    console.log('Dossier ===> ',dossiers)
    for(let doc of dossiers){
      let inf = {
        "name": doc.name,
        "workflow_state": doc.workflow_state,
        "numero_courrier": doc.numero_courrier,
      }
      this.Information_Dossier.push(inf)
    }
    } catch (error) {
      console.log('error getDossierAttribue ', error);

    }

  }

  updateValueforChange(value,key){
    this.dataAllInformation[key]=value
  }

  updateValue(){
    this.ngxService.start()
    console.log('update data courrier: ',this.dataAllInformation)
    this.userCourrierService.updateCourrier(this.dataAllInformation.name,this.dataAllInformation).then(res=>{
      this.dataAllInformation=res.data
      this.data = this.dataAllInformation
      this.actionToDo()
      this.alerte.successNotification('Courrier','Mise a jour effectuee!')
      // this.penalCourrierService.hiddeModal(this.parentModal);
      // this.modalService.hide()
      this.closeWithMessage('success')
      this.ngxService.stop()
    },err=>{
      this.alerte.errorNotification('Courrier','Erreur lors de la mise a jour du courrier')
      this.ngxService.stop()
    })
  }

  updateState(new_state){
    this.ngxService.start()
    this.userCourrierService.updateSatate(this.data.name,new_state).then(async res => {
      this.alerte.successNotification('Courrier ','Courrier mis à jour avec succés')
      this.ngxService.stop();
      this.closeWithMessage("success")
      
    // await this.refresh()
      // this.openModalCourrierDetails(res.data)
    },err =>{
      this.ngxService.stop();
      this.alerte.errorNotification('Courrier ','Erreur lors du mis à jour du Courrier')
      console.log('Error ==> ',err)
    })
  }

  // recuperer le dernier st
  private getLatestSt(data: any[]): any {
    if (!data || data.length === 0) {
      return null;
    }

    // Trie  en ordre décroissant
    const sortedData = data.sort((a, b) => new Date(b.creation).getTime() - new Date(a.creation).getTime());

    return sortedData[0];
  }
  async refresh(){
    this.ngxService.start()
    this.userCourrierService.getOneCourrierPrincipal(this.data.name).then(res =>{
      this.dataAllInformation = res.data
      this.data = res.data
      this.actionToDo()

      this.dataPiecesJointes = this.dataAllInformation.pieces_jointe_au_courrier
      //console.log('-p-p-p-p---> ', this.dataPiecesJointes)
    });
    let filter = '[["type_of_st" , "=","Courrier"],["doctype_name" , "=","'+this.data.name+'"]]'
    this.customService.getSoitTransmisObject(filter).then(res=>{
      console.log(res)
      this.ALL_ST=res.data

      this.latestSt = this.getLatestSt(this.ALL_ST);
      console.log('stss ', this.latestSt);
      console.log('stss ', this.ALL_ST);
    })

    this.ngxService.stop()
  }

  ngOnInit() {

    try {
      this.ngxService.start()

      this.userCourrierService.getOneCourrierPrincipal(this.data.name).then(res =>{
        this.dataAllInformation = res.data
        this.data = res.data
        this.userCourrierService.getNextActionWorkFlow(this.dataAllInformation).then(res=>{
          console.log('Workflow ===> ',res)
          this.workFlowsActionsAllowed = res.message
          if(this.workFlowsActionsAllowed.length == 0){
            this.ACTION_ALLOWED = true
          }
          console.log('workFlowsActionsAllowed ==> ',this.workFlowsActionsAllowed)
        },err =>{
          console.log('Error ===> ',err)
        })
        this.actionToDo()

        this.dataPiecesJointes = this.dataAllInformation.pieces_jointe_au_courrier

        //console.log('-p-p-p-p---> ', this.dataPiecesJointes)
      })
      // this.userCourrierService.getAllowedClassification().then( res =>{
      //   //console.log(res.data)
      //   this.classifications = res.data
      // })
      // let nature_filter='[["type_de_courrier" , "=",""]]'
      // this.userCourrierService.getAllowedNatureCourrier(nature_filter).then( res =>{
      //   //console.log(res.data)
      //   this.natures = res.data
      // })

      this.ngxService.stop()
    } catch (error) {
      this.ngxService.stop()
    }

    //console.log('inf subs == '+this.data);
    let filter = '[["type_of_st" , "=","Courrier"],["doctype_name" , "=","'+this.data.name+'"]]'
    this.customService.getSoitTransmisObject(filter).then(res=>{
      console.log(res)
      this.ALL_ST=res.data

      this.latestSt = this.getLatestSt(this.ALL_ST);
      console.log('stss ', this.latestSt);
      console.log('stss ', this.ALL_ST);
    })
    this.profil = localStorage.getItem('profil');
    // this.penalCourrierService.hiddeModal(this.parentModal);
    //console.log('pppaaarneet modal '+ this.parentModal);

  }

  openModalDetailsTransmission(informationST: any) {
    this.ngxService.start()
    const config: ModalOptions = {
      initialState: {
        st_dateil:informationST,
        courrier_origine:this.data,
        numberCourrier: this.data.numero_courrier
      }
    };
    // this.bsModalRef.hide()
    let modalRef = this.modalService.show(ViewDetailsTransmissioncourrierComponent, Object.assign({}, config, {class:'modal-xl  modal-st'}));
    this.ngxService.stop()
  }
}
