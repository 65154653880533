import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AlertesService } from '../public/alertes.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsercourrierService {

  Docatype_courrier_principal ='Courrier'

  Docatype_classification ='Type de Chaine'
  Docatype_nature_courrier ='Nature de courrier'


  Docatype_courrier_interne ='Courrier Interne'

  constructor(private http: HttpClient,private authservice: AuthService, private frapper: FrapperService, private assigneService:AssignedService,private alerte:AlertesService,) {}


 // Courrier Principale
  async getAllMyCourrierPrincipal(filter=null) {
    try {
      let my_courriers = await this.frapper.GetDoctypes(this.Docatype_courrier_principal,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_courriers
    } catch (error) {
      console.log('error access-> ', error);
      // si probleme de token deconnecter le user
      this.authservice.handleError(error)

    }

  }

  async getAllMyCourrierInterne(tribuna_name=null) {
    try {
      let filter = tribuna_name==null? null :'[["tribunal","=","'+tribuna_name+'"]]'
      let my_courriers = await this.frapper.GetDoctypes(this.Docatype_courrier_interne,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_courriers
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneCourrierPrincipal(courrierName) {
    try {
      let my_courrier = await this.frapper.GetDoctype(this.Docatype_courrier_principal,courrierName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_courrier
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneCourrierInterne(courrierName) {
    try {
      let my_courrier = await this.frapper.GetDoctype(this.Docatype_courrier_interne,courrierName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_courrier
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllowedClassification() {
    try {
      let classifications = await this.frapper.GetDoctypes(this.Docatype_classification,'["*"]',null).toPromise()
    //console.log('My classifications ===> ', classifications)
    return classifications
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllowedNatureCourrier(filter=null) {
    try {
      let nature = await this.frapper.GetDoctypes(this.Docatype_nature_courrier,'["*"]',filter).toPromise()
      //console.log('My classifications ===> ', classifications)
      return nature
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouveauCourrier(body) {
    try {
      let courrier = await this.frapper.CreateDoctype(this.Docatype_courrier_principal,body).toPromise()
      return courrier
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getNextActionWorkFlow(courrier=null) {
    try {
      const body= {"doc":courrier}
      let workflow = await this.frapper.CallCustomAPI('frappe.model.workflow.get_transitions',body).toPromise()
      return workflow
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouveauCourrierInterne(body) {
    try {
      let courrier = await this.frapper.CreateDoctype(this.Docatype_courrier_interne,body).toPromise()
      return courrier
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getnumeroCourrier(type_de_courrier=null) {
    try {
      let me = localStorage.getItem('me')
    const body= {utilisateur:JSON.parse(me).name,registre:type_de_courrier}
    console.log('body ==> ',body)
    let courrier = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_my_last_courrier',body).toPromise()
    return courrier
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async assignCourrierToSubstitut(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Docatype_courrier_principal,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
      let result = this.frapper.UpdateDoctype(this.Docatype_courrier_principal,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async updateCourrier(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Docatype_courrier_principal,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

 // ** Courrier Principale
}
