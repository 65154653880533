

<div class="d-flex bar-header  justify-content-between col-12 " style="height: 77px;">
  <div class="header-container">
    <div class="logo-container">
      <div class="d-flex justify-content-start">
        <a class="logoimg" [href]="!is_public ? '#/E-justice/home' : '#/X/demandes'">
          <img class="logoimg" src="../../../../assets/images/ejustice.png" alt="">
        </a>

      </div>

    </div>
  </div>

  <div class="header-container">

    <div class="d-flex justify-content-end second-header">
      <div>
        <ng-container *ngIf="!is_public;else other">
          <div class="second d-flex">
            <span style="font-size: 15px;margin-right: 10px;margin-top: 8%;" class="myprofil">{{profil}}</span>
        
            <div class="sec-center">
              <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
              <label class="for-dropdown d-flex " for="dropdown"><i class="fas fa-regular fa-user" style="margin-right: 10px;"></i> <i class="fas fa-solid fa-caret-down" ></i></label>
        
              <div class="section-dropdown d-flex flex-column text-nowrap">
                <div class="d-flex justify-content-between" (click)="openModalProfile()">
                  <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile"  />
                  <span style="font-size: 10px; cursor: pointer;"  class="text-light"><i class="fas fa-regular fa-user" style="font-size: 10px; left: 0; "  ></i>  {{profil}}</span>
                </div>
                <div class="d-flex justify-content-between" (click)="openModalJuridiction()" *ngIf="my_profil == 'PROCUREUR'">
                  <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile"  />
                  <span style="font-size: 10px; cursor: pointer;"  class="text-light"><i class="fas fa-regular fa-user" style="font-size: 10px; left: 0; "  ></i> Mes Juridictions</span>
                </div>
                <div class="d-flex justify-content-between" (click)="logout()" >
                  <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile" />
                  <span style="font-size: 11px; cursor: pointer;"  class="text-light"><i class="fas fa-solid fa-lock" style="font-size: 10px; cursor: pointer;"  ></i> Se déconnecter </span>
                </div>
            </div>
            </div>
          </div>
        
        </ng-container>
  
        <ng-template #other>
          <div class="secondmenu">
            <div class="frame">
              <input type="checkbox" id="check" hidden>
        
              <label for="check">
                <i class="fas fa-bars" id="btn"></i>
                <i class="fas fa-times" id="cancel"></i>
              </label>
        
              <div class="sidebar">
                <header>Menu</header>
                <a
                  class="menu-item {{item.class}}"
                  *ngFor="let item of menu"
                  [routerLink]="[item.route]"
                  [ngClass]="{'active': item.route === activeRoute}"
                  (click)="onChangeMenu(item.route)">
                  <b><i class="fas fa-solid fa-angle-right" *ngIf="item.class != 'groupTitle'"></i></b>
                  <span>{{item.name}}</span>
                </a>
                <a class="menu-item text-light" style="background-color: red;" (click)="logout()">
                  <b><i class="fas fa-solid fa-times-circle  "></i></b>
                  <span>DECONNEXION</span>
                </a>
        
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      
      <div class="notifications">
        <ul class="dots">
          <li>
            <a>
              <span class="fas fa-solid fa-envelope">
                <mark class="blue tada" *ngIf="new_notifs > 0">{{new_notifs}}</mark>
              </span>
            </a>
          </li>
        </ul>
      
        <div class="dropdown-notif" style="display: none;">
          <ul class="notification-list" style="overflow: auto;" >
            
            <!-- <nb-list>
              <nb-list-item *ngFor="let notif of notifications">
                <nb-user [name]="notif.owner" (click)="openModalDetailView(notif)">
                  <span [innerHTML]="notif.subject"></span>
                  <ng-container *ngIf="notif.read == 0">
                    <span class="badge badge-danger">Nouveau</span>
                  </ng-container>
                </nb-user>
              </nb-list-item>
            </nb-list> -->
            <div>
              <div 
              class="notification" 
              *ngFor="let notif of notifications | paginate: { itemsPerPage: 20, currentPage: p }" 
              (click)="openModalDetailNotif(notif)"
            >
              <img
                alt="Profile picture of Eugene"
                class="notification-avatar"
                src="../../../../assets/images/utilisateur.png"
                style="width: 30px;height: 30px;"
              >
              <div class="notification-content">
                <h1 class="notification-title">{{notif.from_user}}</h1>
                <p class="notification-meta" [innerHTML]="notif.email_content"></p>
              </div>
              <ng-container *ngIf="notif.read == 0">
                <span class="badge badge-danger">Nouveau</span>
              </ng-container>
            </div>
            
            
              <pagination-controls (pageChange)="p = $event" previousLabel="" nextLabel=""
                responsive="true" class="pagination-controls"></pagination-controls>
            </div>
            
            
           
            
          </ul>
        </div>
      </div>
    </div>


    
    
    
    </div>
</div>




