<!DOCTYPE html>
<html lang="fr">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>
  <app-public-header></app-public-header>

<!-- Le contenu dynamique des composants chargés via le router-outlet -->
<div class="content" style="z-index: 1;">
  <!-- <app-home-page></app-home-page> -->
  <router-outlet></router-outlet>
</div>



</body>
<public-footer></public-footer>

</html>