import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxPrintDirective } from 'ngx-print';
import { Config, NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentService } from "../../services/frapper/document.service";
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from "../../widgets/successDialog/success-dialog.component";

@Component({
  selector: 'app-visualise-decision',
  templateUrl: './visualise-decision.component.html',
  styleUrls: ['./visualise-decision.component.scss'],
})
export class VisualiseDecisionComponent {
  content_default:any;
  @Input() showTypeData: string;
  @Input() data_factas: any;
  @Input() data_qualites: any;
  data_noteAudience: any;
  @Input() parentModal: BsModalRef;
  data: any;
  contenuSaisi: any;
  template: any = "";
  default_template: any;
  content_qualite:any ;
  content_factas: any;
  data_template: any;
  type_doctype:any
  is_editable:any = null
  is_document:any=0;

  modalRef!: BsModalRef;
  my_tribunal:any = null
  // @ViewChild('elementToPrint') printElement!:any;
  @ViewChild('elementToPrint', { static: true }) elementToPrint!: ElementRef; // Référence à l'élément à imprimer

  @ViewChild(NgxPrintDirective) ngxPrint!: NgxPrintDirective;
  constructor(private modalService: BsModalService,public options: ModalOptions,
    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
    private datePipe: DatePipe,
    public bsModalref: BsModalRef,
    private documentService: DocumentService,
    public print: NgxPrintElementService,
    private penalService: PenalCourrierService) {

      this.data_noteAudience = options.initialState.data
      this.data = options.initialState.data
      this.type_doctype = options.initialState.type_doctype
      this.data_template = options.initialState.data_template
      this.is_editable = options.initialState.is_editable
    try{
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }
    try{
      this.is_document = options.initialState.is_document
    }catch (e) {
     console.log('No Indication')
    }

  }

  closeWithMessage(message: string) {
    this.bsModalref.hide();
    this.bsModalref.onHidden.emit(message); 
  }
 saveChange(){
  // console.log('JUGEMENT_DATA ==> ',this.data)
   //console.log('data_template ==> ',this.data_template)
    //console.log('SAVE DONNEE===>', this.template);
   // createdocument
   this.ngxService.start()
   
   let body={
     //type_de_template:'PENAL',
     template_name:this.data_template.template_name,
     image:this.data_template.image,
     numero_du_jugement:this.data['role.jugement_code'] || null,
     type_doctype: this.type_doctype,

     is_decision:this.data_template.is_decision,
     template:this.template,
   }
   //console.log('CReation de document  ===> ', body)
   if(this.my_tribunal !=null){
     body["tribunal"]= this.my_tribunal.name
   }
   console.log('CReation de document  ===> ', body)
   this.documentService.nouveauDocument(body).then(res=>{
     this.openModalSuccessDialog('Document enregistré')
     this.closeWithMessage("success")
     this.ngxService.stop()

   },err=>{
     this.alerte.errorNotification('Documents','Erreur lors de la generation du document!')
     this.ngxService.stop()
   })
    //this.documentService.
 }

 UpdateDocument(){
   this.alerte.errorNotification('Documents','Modification non Autorisee!')
  }



  remplacerVariables(template, jugement) {
    let mytemplate = template;
    let correspondanceTrouvee = false;
    console.log({'the te1': template});
    console.log({'the te1': jugement});

    for (let key in jugement) {
      if (jugement.hasOwnProperty(key)) {
        //  let regex = new RegExp("$_" + key + "_$", "g");
        let regex = new RegExp("\\$_" + key + "_\\$", "g");

        // Vérifier si la valeur de l'attribut est null ou n'existe pas
        if (jugement[key] == null || jugement[key] === undefined) {
          mytemplate = mytemplate.replace(regex, '.........');
        } else {
          mytemplate = mytemplate.replace(regex, jugement[key]);
          correspondanceTrouvee = true;
        }
      }
    }

   let model_final =  this.filter_remplacerVariables(mytemplate,jugement)


    return model_final;
  }

  filter_remplacerVariables(template, jugement) {
    let mytemplate = template;
    let modeleFinal: any;

    // Expression régulière pour récupérer les mots commencant par $_ et terminant par _$
    let regexPattern = /\$_([^$]+)_\$/g;// Échapper les caractères spéciaux dans la chaîne de la RegExp

  modeleFinal = mytemplate.replace(regexPattern, '...........................')
    return modeleFinal;
  }


  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }
  onPrint2() {
    if (this.elementToPrint && this.elementToPrint.nativeElement) {
      // Mettre à jour le contenu de elementToPrint avec le contenu de template
      this.elementToPrint.nativeElement.innerHTML = this.template;

      // Imprimer le contenu de elementToPrint
      this.print.print(this.elementToPrint, this.config).subscribe(() => {
        console.log('Impression réussie');
      });
    } else {
      console.error('Élément à imprimer non trouvé');
    }
    // this.printElement.innerHTML = this.template;
    // try {
    //   this.print.print(this.printElement,this.config).subscribe(console.log);

    // } catch (error) {
    //   console.log('dddddd==> '+ error);


    // }

  }
  public config: Config = {

    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    templateString: this.template,
    // htmlType: 'text',
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],

    styles: [
      `

      @media print {
        @page {
          size: A4; /* Définit la taille de la page comme A4 */
          margin: 20px; /* Supprime les marges par défaut */
        }

        body {
          width: 210px; /* Largeur de la page A4 en millimètres */
          margin: 20px;
          font-size: 15px;
          height:297px;





        }
      }





      `

    ]
  }


  ngOnInit() {
    console.log('document=>', this.data_template.template);
  this.template =  this.remplacerVariables(this.data_template.template, this.data)

  }




}
