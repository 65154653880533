<!-- <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="min-height: 600px; overflow: scroll;">
  <button class='btn btn-success  m-3' ><i class="fas fa-regular fa-floppy-disk text-light"></i> Sauvegarder</button>


  <st-editor [(ngModel)]="template"></st-editor>


</body>
</html> -->


<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<!-- <div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div><br> -->
<div class="d-flex  justify-content-between mr-4 pt-3" >
  <div *ngIf="is_document==0" class="ml-3" (click)="saveChange()">
    <button class="btn btn-success">
      Generer le document
    </button>
  </div>
  <div *ngIf="is_document==1" class="ml-3"  (click)="UpdateDocument()">
    <button class="btn btn-warning" *ngIf="is_editable == null">
      Modifier le document
    </button>
  </div>

  <div *ngIf="is_document==1" (click)="onPrint2()">
    <span class="mt-2" >Cliquez pour imprimer:&nbsp; </span>
    <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
  </div>



</div>
<body #elementToPrint>
  <st-editor *ngIf="is_editable == null"  [(ngModel)]="template" ></st-editor>
  <div *ngIf="is_editable == false" [innerHtml]="template" ></div>

</body>
</html>
