<!-- <ngx-calendar></ngx-calendar> -->


<body >
  <div class="content-wrapper " style="width: 100%;">
    <div class="col-12 group2 d-flex justify-content-center">
      <h5 class="titlePage font-weight-bold ">Type de {{type}} </h5>
    </div>

  </div>
  <div class="row m-auto" >
    <div class="col col-md-4 col-lg-4 col-sm-12 mt-4 text-center flex-wrap" *ngFor="let item of items ">
      <div class="d-flex justify-content-center">

        <div class="addButton" (click)="openModalAddDemandeVisite(item.titre,item)">
         <div class="button text-nowrap " style="font-size: 11px;">
            <div class="d-flex justify-content-between ">
              <!-- <i class="fas fa-solid fa-file-import circle mt-1  m-auto " style="font-size: 20px;"></i> -->
              <span style="font-size: 11px;" class="m-auto ml-2">{{item.titre}}</span>
            </div>

            </div>
      </div>
      </div>

    </div>


  </div><br><br>




</body>

