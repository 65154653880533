<!-- <ngx-one-column-layout class="uu">


  
  <div class="container emp-profile">
              <form method="post">
                  <div class="row">
                      <div class="col-md-4">
                          <div class="profile-img">
                              <img src="{{USER_PP==null? '../../../../assets/images/ejustice.png':'data:image/png;base64,'+USER_PP}}"  class="rounded-circle"   style="height: 150px;width: 150px;"/>
                              <div class="file btn btn-lg btn-primary">
                                  Changer Photo
                                  <input type="file" name="file"/>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="profile-head ">
                                      <h5 class="text-dark">
                                      {{MY_INFORMATIONS?.full_name}} ({{MY_INFORMATIONS?.profil}})
                                      
                                      </h5>
                                      <h6>
                                        {{MY_INFORMATIONS?.contact != undefined ? MY_INFORMATIONS?.contact?.email_address : MY_INFORMATIONS?.email }}
                                      </h6>
                              <ul class="nav nav-tabs" id="myTab" role="tablist">
                                  <li class="nav-item">
                                      <a class="nav-link active" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">Informations personnelles</a>
                                  </li>
                               
                              </ul>
                          </div>
                      </div>
                      <div class="col-md-2">
                          <input type="submit" class="profile-edit-btn" name="btnAddMore" value="Modifier"/>
                      </div>
                  </div>
                  <div class="row" style="margin-top: -20px;">
                      <div class="col-md-4">
                          <div class="profile-work">
                          
                             
                          </div>
                      </div>
                      <div class="col-md-8">
                          <div class="tab-content profile-tab" id="myTabContent">
                              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                  <div class="row">
                                      <div class="col-md-6">
                                          <label>Nom complet</label>
                                      </div>
                                      <div class="col-md-6">
                                          <p> {{MY_INFORMATIONS?.full_name}}</p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-6">
                                          <label>E-mail</label>
                                      </div>
                                      <div class="col-md-6">
                                        <p>{{MY_INFORMATIONS?.contact != undefined ? MY_INFORMATIONS?.contact?.email_address : MY_INFORMATIONS?.email }}</p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-6">
                                          <label>Numéro téléphone</label>
                                      </div>
                                      <div class="col-md-6">
                                          <p>{{MY_INFORMATIONS?.contact?.number}}</p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-6">
                                          <label>CNI</label>
                                      </div>
                                      <div class="col-md-6">
                                          <p> {{MY_INFORMATIONS?.identification?.value}}</p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-6">
                                          <label>Adresse</label>
                                      </div>
                                      <div class="col-md-6">
                                          <p>{{MY_INFORMATIONS?.contact?.contry}}</p>
                                      </div>
                                  </div>
                              </div>
    
                          </div>
                      </div>
                  </div>
              </form>           
          </div>
          <ngx-ui-loader></ngx-ui-loader>

</ngx-one-column-layout> -->

<!-- <public-header></public-header> -->
<div class="container emp-profile shadow" style="min-width: fit-content;min-height: 80vh;margin-top: 5%;">
    <form method="post">
        <div class="row">
            <div class="col-md-4">
                <div class="profile-img">
                    <img src="{{USER_PP==null? '../../../../assets/images/ejustice.png':'data:image/png;base64,'+USER_PP}}"  class="rounded-circle"   style="height: 150px;width: 150px;"/>
                    <div class="file btn btn-lg btn-primary">
                        {{'PUBLIC.change_profil' | translate}}
                        <input type="file" name="file"/>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="profile-head ">
                            <h5 class="text-dark">
                            {{MY_INFORMATIONS?.full_name}} ({{MY_INFORMATIONS?.profil}})
                            
                            </h5>
                            <h6>
                              {{MY_INFORMATIONS?.contact != undefined ? MY_INFORMATIONS?.contact?.email_address : MY_INFORMATIONS?.email }}
                            </h6>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">{{'PUBLIC.personal_info' | translate}}</a>
                        </li>
                     
                    </ul>
                </div>
            </div>
            <div class="col-md-2">
                <input type="submit" class="profile-edit-btn" name="btnAddMore" value="Modifier"/>
            </div>
        </div>
        <div class="row" style="margin-top: -20px;">
            <div class="col-md-4">
                <div class="profile-work">
                
                   
                </div>
            </div>
            <div class="col-md-8">
                <div class="tab-content profile-tab" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{'fullname' | translate}}</label>
                            </div>
                            <div class="col-md-6">
                                <p> {{MY_INFORMATIONS?.full_name}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{'PUBLIC.email' | translate}}</label>
                            </div>
                            <div class="col-md-6">
                              <p>{{MY_INFORMATIONS?.contact != undefined ? MY_INFORMATIONS?.contact?.email_address : MY_INFORMATIONS?.email }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{'PUBLIC.num_phone' | translate}}</label>
                            </div>
                            <div class="col-md-6">
                                <p>{{MY_INFORMATIONS?.contact?.number}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{'PUBLIC.cni' | translate}}</label>
                            </div>
                            <div class="col-md-6">
                                <p> {{MY_INFORMATIONS?.identification?.value}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{'adress' | translate}}</label>
                            </div>
                            <div class="col-md-6">
                                <p>{{MY_INFORMATIONS?.contact?.contry}}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>           
</div>
<ngx-ui-loader></ngx-ui-loader>
<!-- <public-footer></public-footer> -->
