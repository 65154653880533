import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DemandesService {

  Doctype_Demande ='demandes'
  Doctype_Type_Demande ='type_de_demande'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}


 // Demande
  async getAllDEmande(filter =null) {
    try {
      let my_roles = await this.frapper.GetDoctypes(this.Doctype_Demande,'["*"]',filter).toPromise()
      console.log('resp api Doctype_Signature ===> ', my_roles)
      return my_roles
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getAllTypeDeDemande(filter =null) {
    try {
      let my_roles = await this.frapper.GetDoctypes(this.Doctype_Type_Demande,'["*"]',filter).toPromise()
      console.log('resp api Doctype_Signature ===> ', my_roles)
      return my_roles
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneDemande(name) {
    try {
      let demande = await this.frapper.GetDoctype(this.Doctype_Demande,name,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return demande
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneTypeDemande(name) {
    try {
      let type_demande = await this.frapper.GetDoctype(this.Doctype_Type_Demande,name,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return type_demande
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouvelleDemande(body) {
    try {
      let new_role = await this.frapper.CreateDoctype(this.Doctype_Demande,body).toPromise()
      return new_role
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateDemande(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_Demande,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

 // ** Demande

}
