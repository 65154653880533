import { Component } from '@angular/core';
import {FormBuilder, NgForm} from "@angular/forms";
import {BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {DossierService} from "../../services/frapper/dossier.service";
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import {AssignedService} from "../../services/frapper/assigned.service";
import {AlertesService} from "../../services/public/alertes.service";
import {CustomService} from "../../services/frapper/custom.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {SuccessDialogComponent} from "../../widgets/successDialog/success-dialog.component";

@Component({
  selector: 'app-add-rapportappel',
  templateUrl: './add-rapportappel.component.html',
  styleUrls: ['./add-rapportappel.component.scss'],
})
export class AddRapportappelComponent {

  dossier:any;
  type:any;

  TYPE_DE_TAG =['RAPPORT D\'APPEL']
  ALL_DESTINATAIRE =[]
  REQUISITOIRES =['REQUISITOIRE INTRODUCTIF', 'REQUISITOIRE AU FIN DE NON LIEU', 'REQUISITOIRE AU FIN DE RENVOI']
  ORDONNANCES =['ORDONNANCE AU FIN DE NON LIEU', 'ORDONNANCE AU FIN DE RENVOI']

  constructor(
    private _formBuilder: FormBuilder,
    private options: ModalOptions,
    private dossierService:DossierService,
    private courrier: UsercourrierService,
    private assigne: AssignedService,
    private alerte:AlertesService,
    private custom:CustomService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService
  ) {
    this.dossier = this.options.initialState.dossier;
    this.type = this.options.initialState.type;
    this.GetActeurss()

  }



  addRequisitoireOrOrdonanceAsRapport(data: NgForm){
    this.ngxService.start();
    data.value['numero_dossier'] = this.dossier.name
    data.value['tag'] = 'Rapport d\'appel'
    let body= data.value
    console.log('=========> ', body)

    return ;

    this.dossierService.createRequisitoireOrOrdonance(body).then((res:any) => {
      console.log('Resultatat ===>')
      this.assigne.doAssignation(this.dossierService.Doctype_requisitoire_ordonances,res.data.name,[body.destinataire])
      let c_interne=  {
        "numéros_du_dossier": this.dossier.name,
        "profil": "JUGE D'INSTRUCTION",
        "destinataire": body.destinataire,
        "tag": body.tag,
        "fichier": null,
        "message": body.continu
      }
      this.courrier.nouveauCourrierInterne(c_interne).then(res=>{
        this.assigne.doAssignation(this.courrier.Docatype_courrier_interne,res.data.name,[body.destinataire])
      })

      this.ngxService.stop();
      this.openModalSuccessDialog('Dossier transfere en instruction')
    /*  this.dossierService.updateSatate(this.dossier.name,'RAPPORT EN APPEL').then(res => {

        this.assigne.doAssignation(this.dossierService.Doctype_dossier,this.dossier.name,[body.destinataire]).then(res =>{
          console.log('Assignation Reussie')
          this.ngxService.stop();
          this.openModalSuccessDialog('Dossier transfere en instruction')
        })
      },err=>{
        this.ngxService.stop();
        this.alerte.errorNotification('Requisitoires ','Une Erreur est survenue !')
        console.log('ERROR ON CHANGE STATUS ',err)
      })*/

    },err=>{
      this.ngxService.stop();
      console.log('ERROR ON CREATE RAPPORT APPEL ',err)
      this.alerte.errorNotification('Requisitoires ','Une Erreur est survenue !')
    })
  }

  async GetActeurss(){
    let filter = '[["numero_dossier" , "=","'+this.dossier.name+'"]]'
    this.dossierService.getAllMyRequisitoireOrOrdonance(filter).then((res:any) =>{
      //this.ALL_DESTINATAIRE =res.data
      for (let dest of res.data) {
        this.ALL_DESTINATAIRE.push(dest.destinataire)
      }
      console.log('this.nbr_requisitoires ==> ',this.ALL_DESTINATAIRE)
    })
  }


  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.modalService.hide()
    // this.penalCourrierService.hiddeModal(this.parentModal);
    setTimeout(() => {
      if (this.modalService) {
        this.modalService.hide();
      }
    }, 1000);
    const modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }
}
