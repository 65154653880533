<div class="container-fluid">
  <div class="row justify-content-center">
    <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>

    <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
      <!-- Formulaire courrier entrant -->
      <div class="card position-relative">
        <!-- Close Icon -->
        <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
          <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
        </button>
        <h5 class="text-center" >{{data.name}}</h5>
        <form id="msform" #deamnde="ngForm" >
          <!-- Progressbar -->
          <ul id="progressbar" class="text-center m-auto">
            <ng-container *ngFor="let form of DEMANDE_TO_FORMS; let i = index">
              <li [ngStyle]="{'width': stepWidth}" [className]="i === 0 ? 'active' : ''" [id]="'step' + (i + 1)" class="step-input text-center m-auto">
                <strong>{{ form.formulaire?.titre }}</strong>
              </li>
            </ng-container>
          </ul>
          <div class="progress text-center m-auto">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <br> <!-- Fieldsets -->
          <ng-container *ngFor="let form of DEMANDE_TO_FORMS; let i = index">
            <fieldset>
              <div class="row">
                <div class="col-12 col-md-6 mb-3" *ngFor="let entrie of form.formulaire.entries">
                  <div class="form-card text-center " style="padding: 0 2px 0 2px;">
                    <label [for]="entrie.key" class="field-label">
                      {{ entrie.name }}
                      <span *ngIf="entrie.is_required == 1" class="text-danger">*</span>
                    </label>
                    <ng-container [ngSwitch]="entrie.entries_type.object">
                      <ng-container *ngIf="entrie.entries_type.type !== 'file'">
                        <input
                          ngModel
                          *ngSwitchCase="'INPUT'"
                          [type]="entrie.entries_type.type"
                          [name]="form.formulaire?.ref + '#' + entrie.key"
                          [placeholder]="entrie.placeholder ? entrie.placeholder : entrie.name"
                          [pattern]="entrie.pattern"
                          [attr.required]="entrie.is_required == 1 ? true : false"
                          class="form-control"
                        />
                      </ng-container>
                      <ng-container *ngIf="entrie.entries_type.type === 'file'">
                        <label class="custom-file-upload m-auto text-center" [for]="getFileInputId(i, entrie.key)">
                          <div class="icon">
                            <i class="fas fa-regular fa-file"></i>
                          </div>
                          <div class="text text-nowrap">
                            <span class="file-label" style="font-size: 10px;">Importer un fichier (taille max. 100ko)</span>
                          </div>
                          <input 
                            [type]="entrie.entries_type.type" 
                            (change)="onFileSelected($event, form.formulaire?.ref + '#' + entrie.key)" 
                            [id]="getFileInputId(i, entrie.key)" 
                            [name]="form.formulaire?.ref + '#' + entrie.key"
                            [pattern]="entrie.pattern"
                            [attr.required]="entrie.is_required == 1 ? true : false"
                            class="form-control file-input"
                          />
                        </label>
                      </ng-container>
                      <ng-container *ngSwitchCase="'SELECT'">
                        <select
                          ngModel
                          [name]="form.formulaire?.ref + '#' + entrie.key"
                          class="form-control"
                        >
                          <option disabled value="" selected>Selectionnez une option</option>
                          <option *ngFor="let option of entrie.datasource_value" [value]="option.value">{{ option.name }}</option>
                        </select>
                      </ng-container>
                      <ng-container *ngSwitchCase="'TEXTAREA'">
                        <textarea
                          ngModel
                          [name]="form.formulaire?.ref + '#' + entrie.key"
                          [placeholder]="entrie.placeholder ? entrie.placeholder : entrie.name"
                          class="form-control"
                          [attr.required]="entrie.is_required == 1 ? true : false"
                        ></textarea>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              
              <input *ngIf="i < DEMANDE_TO_FORMS.length - 1" type="button" name="next" class="next action-button" value="Suivant" />
              <input *ngIf="i === DEMANDE_TO_FORMS.length - 1" type="button" name="submit" class="action-button" [ngClass]="{'disabled-button': !deamnde.valid}" [disabled]="!deamnde.valid"  value="Enregistrer" (click)="sendDemande(deamnde)" />
              <input *ngIf="i > 0" type="button" name="previous" class="previous action-button-previous" value="Précédent" />
            </fieldset>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
  <!-- <ngx-ui-loader></ngx-ui-loader> -->
</div>
