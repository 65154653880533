import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {


  Doctype_document ='Documents'
  Doctype_template ='Templates'
  Doctype_Type_Document ='type_de_document'

  constructor(private http: HttpClient, private frapper: FrapperService, private authservice: AuthService, private assigneService:AssignedService) {}

 // TEMPLATE

  async getAllTemplate(filter=null) {
    try {
      let my_templates = await this.frapper.GetDoctypes(this.Doctype_template,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)

      return my_templates
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneTemplate(templateNane) {
    try {
      let my_template = await this.frapper.GetDoctype(this.Doctype_template,templateNane,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_template
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllTypeDeDocument(filter =null) {
    try {
      let my_roles = await this.frapper.GetDoctypes(this.Doctype_Type_Document,'["*"]',filter).toPromise()
      console.log('resp api Doctype_Signature ===> ', my_roles)
      return my_roles
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  // Documents

  async getAllDocuments(filter=null) {
    try {
      let documents = await this.frapper.GetDoctypes(this.Doctype_document,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return documents
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneDocument(documentName) {
    try {
      let document = await this.frapper.GetDoctype(this.Doctype_document,documentName,'["*"]',null).toPromise()
      //document.log('Information Of The Courriers ===> ', my_courrier)
      return document
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouveauDocument(body) {
    try {
      let my_document = await this.frapper.CreateDoctype(this.Doctype_document,body).toPromise()
      return my_document
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateDocument(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_document,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_document,body.name,body.user)
    return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

 // ** Jugments
}
