<!-- <ngx-calendar></ngx-calendar> -->

<!-- <ngx-one-column-layout class="uu"> -->
  <body style=" min-height: auto; margin-top: 5%;">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 text-center">
          <h3 class="font-weight-bold title">{{'PUBLIC.list_traitements' | translate}}</h3>
        </div>
      </div>

      <div class="row mb-3 ">
        <div class="d-flex justify-content-between m-auto col-10 flex-wrap">
          <div class="input-group col-12 col-sm-12 col-md-5 ">
            <input type="text" class="form-control" [(ngModel)]="keyword" placeholder="Numéro demande">
            <div class="input-group-append">
              <button class="btn btn-outline-primary" type="button" (click)="searchDemande()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <button type="button" class="btn btn-dark col-sm-3 col-md-2 mt-1" (click)="refresh()">{{'actualize' | translate}}</button>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-striped table-striped table-bordered text-center" style="z-index: 20;">
          <thead class="thead-primary">
            <tr style="font-size: 12px;">
              <th class="text-uppercase text-light text-xs">
                <div class="d-flex align-items-start">
                  <span class="me-2">{{'num' | translate}}</span>
                  <div class="dropdown-container position-relative" data-dropdown-id="dropdown1" style="margin-left: 2%;">
                      <div class="dropdown-button d-flex align-items-start">
                          <i class="fa fa-filter me-2"></i>
                      </div>
                      <div class="dropdown-list position-absolute " style="display: none;">
                          <input type="search" placeholder="Search Numéro" class="form-control dropdown-search mb-2"/>
                          <ul class="list-unstyled mb-0"></ul>
                      </div>
                  </div>
                </div>

              </th>
              <th class="text-uppercase text-light text-xs">
                <div class="d-flex align-items-start">
                  <span class="me-2">{{'PUBLIC.operation' | translate}}</span>
                  <div class="dropdown-container position-relative" data-dropdown-id="dropdown2" style="margin-left: 2%;">
                      <div class="dropdown-button d-flex align-items-start">
                          <i class="fa fa-filter me-2"></i>
                      </div>
                      <div class="dropdown-list position-absolute " style="display: none;">
                          <input type="search" placeholder="Opération" class="form-control dropdown-search mb-2"/>
                          <ul class="list-unstyled mb-0"></ul>
                      </div>
                  </div>
                </div>
              </th>
              <th class="text-uppercase text-light text-xs">

                <div class="d-flex align-items-start">
                  <span class="me-2">{{'PUBLIC.amount' | translate}}</span>
                  <div class="dropdown-container position-relative" data-dropdown-id="dropdown3" style="margin-left: 2%;">
                      <div class="dropdown-button d-flex align-items-start">
                          <i class="fa fa-filter me-2"></i>
                      </div>
                      <div class="dropdown-list position-absolute " style="display: none;">
                          <input type="search" placeholder=" Montant" class="form-control dropdown-search mb-2"/>
                          <ul class="list-unstyled mb-0"></ul>
                      </div>
                  </div>
                </div>

              </th>
              <th class="text-center text-uppercase text-light text-xs">

                <div class="d-flex align-items-start">
                  <span class="me-2">{{'status' | translate}}</span>
                  <div class="dropdown-container position-relative" data-dropdown-id="dropdown4" style="margin-left: 2%;">
                      <div class="dropdown-button d-flex align-items-start">
                          <i class="fa fa-filter me-2"></i>
                      </div>
                      <div class="dropdown-list position-absolute " style="display: none;">
                          <input type="search" placeholder=" Statut" class="form-control dropdown-search mb-2"/>
                          <ul class="list-unstyled mb-0"></ul>
                      </div>
                  </div>
                </div>

              </th>
              <th class="text-center text-uppercase text-light text-xs">
                <div class="d-flex align-items-start">
                  <span class="me-2">{{'date' | translate}}</span>
                  <div class="dropdown-container position-relative" data-dropdown-id="dropdown5" style="margin-left: 2%;">
                      <div class="dropdown-button d-flex align-items-start">
                          <i class="fa fa-filter me-2"></i>
                      </div>
                      <div class="dropdown-list position-absolute " style="display: none;">
                          <input type="search" placeholder="Search Date" class="form-control dropdown-search mb-2"/>
                          <ul class="list-unstyled mb-0"></ul>
                      </div>
                  </div>
                </div>

              </th>
              <th class="text-center text-uppercase text-light text-xs">{{'actions' | translate}}</th>
            </tr>
          </thead>

          <tbody class="text-sm" style="font-size: 12px;">
            <ng-container *ngIf="filtered_demandes.length > 0; else empty">
              <tr *ngFor="let element of filtered_demandes | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageIntCourrier,
                  id: 'operations'
                }" >
                <td>
                  <div class="px-2 text-nowrap">{{element.ref}}</div>
                </td>
                <td>
                  <div class="d-flex flex-column justify-content-center px-2 py-1">
                    <span class="text-secondary text-xs texte-limite">{{element.service.name}}</span>
                  </div>
                </td>
                <td>
                  <span class="text-secondary text-xs ">{{element.service.amount}} Fcfa</span>
                </td>
                <td>
                  <span class="badge bg-dark text-light text-xs">
                    {{element.status === 'BILLING' ? 'EN ATTENTE DE PAIEMENT' :
                      element.status === 'WAITING TRAITEMENT' ? 'EN ATTENTE DE TRAITEMENT' :
                      element.status === 'TRAITEMENT ON PROGRESS' ? 'EN COUR DE TRAITEMENT' : 'TERMINEE'}}
                  </span>
                </td>
                <td>
                  <span class="text-secondary text-nowrap text-xs texte-limite ">{{element.created_at | date:'dd-MM-yyyy / HH:mm:ss'}}</span>
                </td>
                <td class="text-nowrap">
                  <!-- <a href="javascript:;" *ngIf="element.status === 'BILLING'" class="text-secondary  text-xs mx-2"
                     (click)="openPaiementDialog(element.service, element.ref)">
                    <i class="fas fa-check text-warning" style="font-size: 20px;"></i>
                  </a>          -->
                  <!-- <button  (click)="openDemandeDetailDialog(element.service, element.ref,element.status)"  class="btn {{element.status==='BILLING'?'btn-danger' :'btn-success'}}">Voir la Demande</button> -->
                 
                  <a href="javascript:;" class="text-secondary text-xs mx-2" data-toggle="tooltip"
                     data-original-title="Edit user" (click)="openDemandeDetailDialog(element.service, element.ref,element.status,element.custom_data)">
                    <i class="fas fa-eye  {{element.status==='BILLING'?'text-danger' :'text-success'}}" style="font-size: 20px;"></i>
                  </a>
                </td>
              </tr>
            </ng-container>

            <!-- Template alternatif affiché lorsque List_demandes est vide -->
            <ng-template #empty>
              <tr>
                <td colspan="6" class="text-center">
                  <p class="text-center">{{'PUBLIC.empty_data' | translate}}</p>
                </td>
              </tr>
            </ng-template>
            <div class="m-auto align-center text-nowrap">
              <pagination-controls
              (pageChange)="pageIntCourrier = $event"
              previousLabel="Précédent"
              nextLabel="Suivant"
              responsive="true"
              [id]="'operations'"
            ></pagination-controls>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </body>
  <ngx-ui-loader></ngx-ui-loader>
<!-- </ngx-one-column-layout> -->
