<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" *ngIf="data.typeCourrier == 'entrant' ">
              <div class="d-flex justify-content-center">
                <h5 id="heading">Nouveau courrier Arrivé</h5>
                <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
                  <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
                </button>
              </div>

              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform" #ArrivalCourrier="ngForm" (ngSubmit)="createNewCourrier(ArrivalCourrier)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Détails</strong></li>
                      <li id="step2"><strong>Information rédacteur</strong></li>
                       <li id="step3"><strong>Documents fournis</strong></li>
                    <!--  <li id="step4"><strong>Elements du dossier penal</strong></li> -->
                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br> <!-- fieldsets -->

                  <fieldset>
                      <div class="form-card">

                        <div class="container">
                          <div class="row " >
                            <div class="col-md-6 col-12 mb-3" style="padding: 0 5px 0 0;">
                              <span >
                                <label class="fieldlabels">Registre: <span class="text-danger">*</span></label>
                                <select class="custom-select" id="registreSelect" name="registre" (change)="onRegistreSelectChange($event)" ngModel required>
                                  <option *ngFor="let registre of registres" value="{{registre.name}}">{{registre.titre}}</option>
                                </select>
                              </span>


                              <label class="fieldlabels">Classification: <span class="text-danger">*</span></label>
                              <select class="custom-select" id="classificationSelect" name="classification" (change)="onClassificationChange($event)" ngModel required>
                                <option *ngFor="let classification of classifications" value="{{classification.name}}">{{classification.name}}</option>
                              </select>

                              <label class="fieldlabels">Nature: <span class="text-danger">*</span></label>
                              <select class="custom-select" id="natureSelect" name="nature" ngModel required>
                                <option *ngFor="let nature of natures" value="{{nature.name}}">{{nature.name}}</option>
                              </select>

                              <label class="fieldlabels">Référence: <span class="text-danger">*</span></label>
                              <select class="custom-select" id="referenceSelect" name="reference" [(ngModel)]="reference" ngModel required>
                                <option value="Non">NON</option>
                                <option value="Oui">OUI</option>
                              </select>

                              <label class="fieldlabels" *ngIf="reference != 'Non'">Numéro d'origine: </label>
                              <input class="form-control" type="text" *ngIf="reference != 'Non'" name="numero_dorigine" ngModel />
                            </div>

                            <div class="col-md-6 col-12 mb-3 d-flex flex-column">
                              <label class="fieldlabels">Numéro courrier: <span class="text-danger">*</span>  <span *ngIf="nbr_courrier!= null">({{nbr_courrier}})</span> </label>
                              <input class="form-control" type="text" placeholder="AR-{000}-{2024}" [ngModel]="new_document_number" name="numero_courrier" ngModel required />

                              <label class="fieldlabels">Date de réception du courrier: </label>
                              <input class="form-control" type="date" name="date_reception" ngModel />

                              <label class="fieldlabels">Date du courrier: </label>
                              <input class="form-control" type="date" name="date_courrier" ngModel />

                              <label class="fieldlabels">Information de la référence: </label>
                              <textarea style="height: 130px;" class="form-control" placeholder="Entrer les informations liées à la référence" name="information_de_la_reference" ngModel></textarea>
                            </div>
                          </div>
                        </div>
                      </div> <input type="button" name="next" class="next action-button" value="Suivant" />
                  </fieldset>

                  <fieldset>
                      <div class="form-card">

                        <div class="container">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 mb-3" style="padding: 0 5px 0 0;">
                              <label class="fieldlabels">Type d'identification: </label>
                              <select class="custom-select" id="inputGroupSelect01" name="type_identification" ngModel>
                                <option *ngFor="let id of identification_type" value="{{id.name}}">{{id.type}}</option>
                              </select>

                              <label class="fieldlabels">Prénom: </label>
                              <input type="text" class="form-control" placeholder="" name="prenom" ngModel/>

                              <label class="fieldlabels">Pays: </label>
                              <select class="custom-select" id="inputGroupSelect01" name="pays" ngModel>
                                <option value="SENEGAL">SENEGAL</option>
                                <option value="AUTRE">AUTRE</option>
                              </select>

                              <label class="fieldlabels">Region: </label>
                              <input class="form-control" type="text" placeholder="" name="region" ngModel/>

                              <label class="fieldlabels">Adresse:</label>
                              <input class="form-control" type="text" placeholder="" name="adresse" ngModel/>
                            </div>

                            <div class="col-md-6 col-sm-12 mb-3 d-flex flex-column justify-content-between">
                              <label class="fieldlabels">Numéro d'identification: </label>
                              <input class="form-control" type="text" placeholder="" name="numero_identification" ngModel/>

                              <label class="fieldlabels">Nom: </label>
                              <input class="form-control" type="text" placeholder="" name="nom" ngModel/>

                              <label class="fieldlabels">Numéro d'origine: </label>
                              <input class="form-control" type="text" placeholder="" name="numero_d_origine" ngModel/>

                              <label class="fieldlabels">Rédaction: </label>
                              <textarea style="height: 120px;" class="form-control" placeholder="Message..." name="redaction" ngModel></textarea>
                            </div>
                          </div>
                        </div>

                      </div> <input type="button" name="next" class="next action-button" value="Suivant"  />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                  </fieldset>

                  <fieldset>
                    <div class="form-card">

                        <div class="row justify-content-center">
                          <div class="d-flex justify-content-between col-md-12 " >
                            <label class="mb-2">Ajouter des piéces au courrier: </label>
                            <a class="text-secondary font-weight-bold text-xs float-right" data-toggle="tooltip"  data-original-title="Edit user">
                              <!-- <i class="fas fa-solid fa-plus text-primary"></i> <span class="text-primary"> Ajout de piéces</span> -->
                          </a>
                            <!-- <button class="btn btn-dark float-right" >Ajouter une piece</button> -->
                          </div><br>
                          <div class=" d-flex justify-content-between flex-wrap col-12" >
                            <div class="col-sm-10 col-md-3 col-lg-3 col-xl-3 align-items-center text-center">
                              <label class="custom-file-upload m-auto" for="file1">
                                <div class="icon">
                                  <i class="fas fa-regular fa-file"></i>
                                </div>
                                <div class="text text-nowrap">
                                  <span class="file-label texte-limite">Piece 1</span>
                                </div>
                                <input type="file" id="file1" (change)="onFileSelected($event,'name_piece_1')"  class="form-control col-sm-12 file-input"  />
                              </label>
                              <input style="font-size: 12px;" type="text" (change)="AddNameToPiece($event,'name_piece_1')" class="file-label col-sm-8 col-md-6 col-lg-6 col-xl-6"    placeholder="Nom Piece 1"/>
                              <div  *ngIf="visualize_piece_1" style="margin-top: -10px;">
                                 <a (click)="openModalVisualPiece('name_piece_1')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                              </div>
                            </div>&nbsp;

                            <div class="col-sm-10 col-md-3 col-lg-3 col-xl-3  align-items-center text-center">
                              <label class="custom-file-upload m-auto" for="file2">
                                <div class="icon">
                                  <i class="fas fa-regular fa-file"></i>
                                </div>
                                <div class="text text-nowrap">
                                  <span  class="file-label texte-limite">Piece 2</span>
                                </div>
                                <input type="file" id="file2" (change)="onFileSelected($event,'name_piece_2')"  class="form-control col-sm-10 file-input" />
                              </label>
                              <input style="font-size: 12px;" type="text" (change)="AddNameToPiece($event,'name_piece_2')" class="col-sm-8 col-md-6 col-lg-6 col-xl-6"   placeholder="Nom Piece 2"/>
                              <div  *ngIf="visualize_piece_2" style="margin-top: -10px;">
                                <a (click)="openModalVisualPiece('name_piece_2')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                             </div>
                            </div>&nbsp;

                            <div class="col-sm-10 col-md-3 col-lg-3 col-xl-3  align-items-center text-center">
                              <label class="custom-file-upload m-auto" for="file3">
                                <div class="icon">
                                  <i class="fas fa-regular fa-file"></i>
                                </div>
                                <div class="text text-nowrap">
                                  <span class="file-label texte-limite">Piece 3</span>
                                </div>
                                <input style="font-size: 10px;" type="file" id="file3" (change)="onFileSelected($event,'name_piece_3')"   class="form-control col-sm-10 file-input" />
                              </label>
                              <input type="text" style="font-size: 12px;" (change)="AddNameToPiece($event,'name_piece_3')" class="col-sm-8 col-md-6 col-lg-6 col-xl-6"   placeholder="Nom Piece 3"/>
                              <div  *ngIf="visualize_piece_3" style="margin-top: -10px;">
                                <a (click)="openModalVisualPiece('name_piece_3')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                             </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!ArrivalCourrier.valid" class="error-message">
                          <span class="text-danger" style="font-size: 10px;">Veuillez remplir correctement tous les champs obligatoires (*) avant de soumettre le formulaire.</span>

                        </div>
                    </div>
                    <input type="submit" name="next"  class=" action-button" value="Envoyer" />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                </fieldset>

              </form>
          </div>


        <!-- courrier sortant -->
        <div class="card" *ngIf="data.typeCourrier == 'sortant' ">
          <div class="d-flex justify-content-center">
            <h5 id="heading">Nouveau courrier Départ</h5>
            <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
              <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
            </button>
          </div>

          <!-- <p>Fill all form field to go to next step</p> -->
          <form id="msform" #ArrivalCourrier="ngForm" (ngSubmit)="createNewCourrier(ArrivalCourrier)">
            <!-- progressbar -->
            <ul id="progressbar">
              <li class="active" id="step1"><strong>Détails</strong></li>
              <li id="step2"><strong>Information rédacteur</strong></li>
              <li id="step3"><strong>Documents fournis</strong></li>
              <!--  <li id="step4"><strong>Elements du dossier penal</strong></li> -->
            </ul>
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div> <br> <!-- fieldsets -->

            <fieldset>
              <div class="form-card">

                <div class="container">
                  <div class="row mb-3">
                    <div class="col-12 col-md-6" style="padding: 0 5px 0 0;">
                      <span >
                                <label class="fieldlabels">Registre: <span class="text-danger">*</span></label>
                                <select class="custom-select" id="registreSelect" name="registre" (change)="onRegistreSelectChange($event)" ngModel required>
                                  <option *ngFor="let registre of registres" value="{{registre.name}}">{{registre.titre}}</option>
                                </select>
                      </span>

                      <label class="fieldlabels">Classification: <span class="text-danger">*</span></label>
                      <select class="custom-select" id="inputGroupSelect01" name="classification" (change)="onClassificationChange($event)" ngModel required>
                        <option *ngFor="let classification of classifications" value="{{classification.name}}">{{classification.name}}</option>
                      </select>

                      <label class="fieldlabels">Nature: <span class="text-danger">*</span></label>
                      <select class="custom-select" id="inputGroupSelect01" name="nature" ngModel required>
                        <option *ngFor="let nature of natures" value="{{nature.name}}">{{nature.name}}</option>
                      </select>

                      <label class="fieldlabels">Référence:</label>
                      <select class="custom-select" id="inputGroupSelect01" name="reference" [(ngModel)]="reference" ngModel required>
                        <option value="Non">NON</option>
                        <option value="Oui">OUI</option>
                      </select>

                      <label class="fieldlabels" *ngIf="reference != 'Non'">Numéro d'origine:</label>
                      <input class="form-control" type="text" *ngIf="reference != 'Non'" [(ngModel)]="numero_courrierOrigine" name="numero_dorigine" ngModel/>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                      <label class="fieldlabels">Numéro courrier: <span class="text-danger">*</span> <span *ngIf="nbr_courrier!= null">({{nbr_courrier}})</span> </label>
                      <input class="form-control" type="text" placeholder="AR-{000}-{2024}" [ngModel]="new_document_number" name="numero_courrier" ngModel required/>

                      <label class="fieldlabels">Date du courrier:</label>
                      <input class="form-control" type="date" name="date_courrier" ngModel/>

                      <label class="fieldlabels">Information de la référence:</label>
                      <textarea style="height: 130px;" class="form-control" placeholder="Entrer les informations liées à la référence" name="information_de_la_reference" ngModel></textarea>
                    </div>
                  </div>
                </div>
              </div> <input type="button" name="next" class="next action-button" value="Suivant" />
            </fieldset>

            <fieldset>
              <div class="form-card">

                <div class="container">
                  <div class="row">
                    <div class="col-md-6" style="padding: 0 5px 0 0;">
                      <div class="form-group">
                        <label class="fieldlabels">Type d'identification:</label>
                        <select class="custom-select" id="inputGroupSelect01" name="type_identification" ngModel>
                          <option *ngFor="let id of identification_type" value="{{id.name}}">{{id.type}}</option>
                        </select>
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Prénom:</label>
                        <input type="text" class="form-control" name="prenom" ngModel placeholder="Saisissez le prénom" />
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Pays:</label>
                        <select class="custom-select" id="inputGroupSelect02" name="pays" ngModel>
                          <option value="SENEGAL">SENEGAL</option>
                          <option value="AUTRE">AUTRE</option>
                        </select>
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Region:</label>
                        <input type="text" class="form-control" name="region" ngModel placeholder="Saisissez la région" />
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Adresse:</label>
                        <input type="text" class="form-control" name="adresse" ngModel placeholder="Saisissez l'adresse" />
                      </div>

                      <!-- <div class="form-group">
                        <label class="fieldlabels">Type d'identification (expéditeur):</label>
                        <select class="custom-select" id="inputGroupSelect03" name="type_identf_expediteur" ngModel>
                          <option *ngFor="let id of identification_type" value="{{id.name}}">{{id.type}}</option>
                        </select>
                      </div> -->
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="fieldlabels">Numéro d'identification:</label>
                        <input type="text" class="form-control" name="numero_identification" ngModel placeholder="Saisissez le numéro d'identification" />
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Nom:</label>
                        <input type="text" class="form-control" name="nom" ngModel placeholder="Saisissez le nom" />
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Numéro d'origine:</label>
                        <input type="text" class="form-control" name="numero_d_origine" ngModel placeholder="Saisissez numéro origine" />
                      </div>

                      <div class="form-group">
                        <label class="fieldlabels">Rédaction:</label>
                        <textarea class="form-control" name="redaction" ngModel placeholder="Message..." rows="5"></textarea>
                      </div>

                      <!-- <div class="form-group">
                        <label class="fieldlabels">Numéro d'identification (expéditeur):</label>
                        <input type="text" class="form-control" name="num_identif_expediteur" ngModel placeholder="Enter sender's ID number" />
                      </div> -->
                    </div>
                  </div>
                </div>

              </div><input type="button" name="next" class="next action-button" value="Suivant"  />
              <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
            </fieldset>

            <fieldset>
              <div class="form-card">
                <div class="row m-auto justify-content-center ">
                  <div class="d-flex justify-content-between col-md-12 " >
                    <label class="mb-2">Ajouter des piéces au courrier: </label>
                    <a class="text-secondary font-weight-bold text-xs float-right" data-toggle="tooltip"  data-original-title="Edit user">
                      <!-- <i class="fas fa-solid fa-plus text-primary"></i> <span class="text-primary"> Ajout de piéces</span> -->
                  </a>
                    <!-- <button class="btn btn-dark float-right" >Ajouter une piece</button> -->
                  </div><br>
                  <!-- <div class=" d-flex justify-content-between flex-wrap">
                    <div class="col-sm-5 col-md-4 col-lg-4 align-items-center text-center">
                      <label class="custom-file-upload m-auto" for="file1">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span class="file-label texte-limite">Piece 1</span>
                        </div>
                        <input type="file" id="file1" (change)="onFileSelected($event,'name_piece_1')"  class="form-control file-input" />
                      </label>
                      <input style="font-size: 12px;" type="text" (change)="AddNameToPiece($event,'name_piece_1')" class="file-label "    placeholder="Nom Piece 1"/>
                      <div  *ngIf="visualize_piece_1" style="margin-top: -10px;">
                         <a (click)="openModalVisualPiece('name_piece_1')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                      </div>
                    </div>&nbsp;

                    <div class="col-sm-5 col-md-4 col-lg-4  align-items-center text-center">
                      <label class="custom-file-upload m-auto" for="file2">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span  class="file-label texte-limite">Piece 2</span>
                        </div>
                        <input type="file" id="file2" (change)="onFileSelected($event,'name_piece_2')"  class="form-control file-input" />
                      </label>
                      <input style="font-size: 12px;" type="text" (change)="AddNameToPiece($event,'name_piece_2')"   placeholder="Nom Piece 2"/>
                      <div  *ngIf="visualize_piece_2" style="margin-top: -10px;">
                        <a (click)="openModalVisualPiece('name_piece_2')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                     </div>
                    </div>&nbsp;

                    <div class="col-sm-5 col-md-4 col-lg-4  align-items-center text-center">
                      <label class="custom-file-upload m-auto" for="file3">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span class="file-label texte-limite">Piece 3</span>
                        </div>
                        <input style="font-size: 10px;" type="file" id="file3" (change)="onFileSelected($event,'name_piece_3')"   class="form-control file-input" />
                      </label>
                      <input type="text" style="font-size: 12px;" (change)="AddNameToPiece($event,'name_piece_3')"   placeholder="Nom Piece 3"/>
                      <div  *ngIf="visualize_piece_3" style="margin-top: -10px;">
                        <a (click)="openModalVisualPiece('name_piece_3')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                     </div>
                    </div>
                  </div> -->
                  <div class=" d-flex justify-content-between flex-wrap col-12" >
                    <div class="col-sm-10 col-md-3 col-lg-3 col-xl-3 align-items-center text-center">
                      <label class="custom-file-upload m-auto" for="file1">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span class="file-label texte-limite">Piece 1</span>
                        </div>
                        <input type="file" id="file1" (change)="onFileSelected($event,'name_piece_1')"  class="form-control file-input" />
                      </label>
                      <input style="font-size: 12px;" type="text" (change)="AddNameToPiece($event,'name_piece_1')" class="file-label col-sm-8 col-md-6 col-lg-6 col-xl-6"    placeholder="Nom Piece 1"/>
                      <div  *ngIf="visualize_piece_1" style="margin-top: -10px;">
                         <a (click)="openModalVisualPiece('name_piece_1')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                      </div>
                    </div>&nbsp;

                    <div class="col-sm-10 col-md-3 col-lg-3 col-xl-3  align-items-center text-center">
                      <label class="custom-file-upload m-auto" for="file2">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span  class="file-label texte-limite">Piece 2</span>
                        </div>
                        <input type="file" id="file2" (change)="onFileSelected($event,'name_piece_2')"  class="form-control file-input" />
                      </label>
                      <input style="font-size: 12px;" type="text" (change)="AddNameToPiece($event,'name_piece_2')" class="col-sm-8 col-md-6 col-lg-6 col-xl-6"   placeholder="Nom Piece 2"/>
                      <div  *ngIf="visualize_piece_2" style="margin-top: -10px;">
                        <a (click)="openModalVisualPiece('name_piece_2')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                     </div>
                    </div>&nbsp;

                    <div class="col-sm-10 col-md-3 col-lg-3 col-xl-3  align-items-center text-center">
                      <label class="custom-file-upload m-auto" for="file3">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span class="file-label texte-limite">Piece 3</span>
                        </div>
                        <input style="font-size: 10px;" type="file" id="file3" (change)="onFileSelected($event,'name_piece_3')"   class="form-control file-input" />
                      </label>
                      <input type="text" style="font-size: 12px;" (change)="AddNameToPiece($event,'name_piece_3')" class="col-sm-8 col-md-6 col-lg-6 col-xl-6"   placeholder="Nom Piece 3"/>
                      <div  *ngIf="visualize_piece_3" style="margin-top: -10px;">
                        <a (click)="openModalVisualPiece('name_piece_3')" class="text-primary" style="text-decoration: underline;">voir la piéce</a>
                     </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!ArrivalCourrier.valid" class="error-message mt-2">
                  <span class="text-danger" style="font-size: 10px;">Veuillez remplir correctement tous les champs obligatoires (*) avant de soumettre le formulaire.</span>

                </div>
              </div>

              <input type="submit" [disabled]="!ArrivalCourrier.valid"  name="next" class="action-button" value="Enregistrer"  />
              <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
            </fieldset>

          </form>
        </div>

      </div>
  </div>
</div>

