import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  Doctype_Signature ='user_signature'
  Doctype_User ='User'
  Doctype_Notification ='Notification Log'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}


 // Signature
  async getAllMySignature(filter =null) {
    try {
      let my_roles = await this.frapper.GetDoctypes(this.Doctype_Signature,'["*"]',filter).toPromise()
      console.log('resp api Doctype_Signature ===> ', my_roles)
      return my_roles
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneSignature(name) {
    try {
      let my_chambres = await this.frapper.GetDoctype(this.Doctype_Signature,name,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_chambres
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouvelleSignature(body) {
    try {
      let new_role = await this.frapper.CreateDoctype(this.Doctype_Signature,body).toPromise()
      return new_role
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateSignature(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_Signature,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

 // ** Signature

  //Notification
  async getAllMyNotification(filter =null) {
    try {
      let my_notif = await this.frapper.GetDoctypes(this.Doctype_Notification,'["*"]',filter).toPromise()
      //console.log('resp api Doctype_Signature ===> ', my_roles)
      return my_notif
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async updateNotification_ext(docname,body){
 
      try {
        let result = this.frapper.UpdateDoctype(this.Doctype_Notification,docname,body).toPromise()
        return result
      } catch (error) {
        this.authservice.handleError(error)
      }
  
    }
  

  async updateNotification(docname,body) {
    try {
      let body = {
        "docname": docname
      }
      let make_to_read = await this.frapper.CallCustomAPI('frappe.desk.doctype.notification_log.notification_log.mark_as_read',body).toPromise()
      return make_to_read
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  //**


 //USER

  async getOneUserDetail(name) {
    try {
      let my_user = await this.frapper.GetDoctype(this.Doctype_User,name,'["*"]',null).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_user
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

 // **USER
}
