<div class="container-fluid l-bg-blue-dark">
  <div class="row justify-content-center">
    <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">

      <!-- Formulaire Enrôlement -->
      <div class="card" style="padding: 10px;">
        <div class="w-100 text-center">
          <h5 id="heading" class="text-primary">
            Soit transmis
            <span *ngIf="data.workflow_state=='Non lieu' || data.workflow_state =='Sans suite' || data.workflow_state=='Enrolement'">
              [ <span style="color: {{textColor}};">{{data.workflow_state}}</span> ]
            </span>
          </h5>
          <button type="button" class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
            <i class="fas fa-times text-primary" style="font-size: 1.5rem;"></i>
          </button>
        </div>
        <form id="msform" style="min-height: 300px;" #stForm="ngForm" (submit)="createSoitTransmis(stForm)">
          <fieldset class="{{step}}">
            <div class="form-card">

              <div class="row">
                <div class="col-12 col-md-6 mb-3" style="padding: 0 5px 0 0;">
                  <h6 class="text-left" style="font-size: 12px;">Désignation:</h6>
                  <select class="custom-select" (change)="filterValueChange($event.target.value)" required name="designation_1" ngModel>
                    <option value="none">none</option>
                    <option *ngFor="let designation of designations" value="{{designation.name}}">{{designation.name}}</option>
                  </select>
                  <h6 class="text-left text-wrap" style="font-size: 12px;">En le priant de bien vouloir procéder aux diligences suivantes:</h6>
                  <select class="custom-select" name="action_1" ngModel>
                    <option value="none">none</option>
                    <option *ngFor="let action of actions" value="{{action.name}}">{{action.name}}</option>
                  </select>
                </div>

                <div class="col-12 col-md-6 mb-3 d-flex flex-column justify-content-between" style="padding: 0 5px 0 0;">
                  <h6 class="text-left mt-3" style="font-size: 12px;"></h6>
                  <input *ngIf="IS_ITERNAL_USER==0" class="form-control" type="text" name="designation_2" ngModel/>
                  <select *ngIf="IS_ITERNAL_USER==1" class="custom-select" name="designation_interne" ngModel>
                    <option *ngFor="let user of users" value="{{user.parent}}">{{user.parent}}</option>
                  </select>
                  <h6 class="text-left mt-3" style="font-size: 12px;"></h6>
                  <input class="form-control" type="text" name="action_2" ngModel/>
                </div>
              </div>

              <h6 class="text-left text-nowrap" style="font-size: 12px;">Remarques:</h6>
              <textarea style="height: 200px;" class="form-control" name="remarque_1" ngModel></textarea>

            </div>
            <input type="submit" name="next" class="next action-button bg-dark" value="Transmettre"
                   style="margin-left: 10%; min-width: 120px;" />
          </fieldset>
        </form>
      </div>

    </div>
  </div>
</div>
