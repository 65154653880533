import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from "@angular/common/http";

import {MyCONST} from "../../CONST/myConst";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  API_URL =  MyCONST.API_PUBLIC
  token :any
  token_type: any

  my_allowed_services: any
  //all_services: any

  constructor(private http:HttpClient) {
    this.getToken()
  }

  getToken(): any {
    const tok = localStorage.getItem('token');
    const type = localStorage.getItem('type');
    if (tok != null) {
      this.token = tok;
      this.token_type = type
    }
    return this.token;
  }

  createService(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/management/service/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  getService(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/service/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  updateServices(form: any,ref:any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/management/service/'+ref,
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  updateServicesTraitement(form: any,ref:any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/management/addTraitementService/'+ref,
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  getServiceForms(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/service_forms/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  getReglementationPoid(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/identification_poid/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }



  getServiceFormsWithEntries(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/service_forms/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  getServiceFormsWithEntriesAndValue(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/service_forms_visualisation/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }



  LinkServiceForm(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/management/service_form/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }



  DeleteLinkServiceForm(service_ref:any, form_ref:any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.delete(this.API_URL + '/management/service_forms/'+service_ref+'/'+form_ref,
      { headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }



  getAllProfilAutorizarion(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/service_authorisations/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  getAllProfilMenuAutorizarion(id: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/menu_authorisations/'+id, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  updateProfilAuthorization(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/management/service_authorisations/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  updateProfilMenuAuthorization(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/management/menu_authorisations/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  getAllServices(): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/services/', { headers: headers })
      .pipe(
        map(rapports => {
         // this.all_services = []
         // this.all_services = rapports
          return rapports;
        })
      );
  }

  getAllTraitementsServices(): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/servicesTraitements/', { headers: headers })
      .pipe(
        map(rapports => {
          // this.all_services = []
          // this.all_services = rapports
          return rapports;
        })
      );
  }

}
