import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DossierService } from '../../services/frapper/dossier.service';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import * as $ from 'jquery';
import {NaturalisationService} from "../../services/frapper/naturalisation.service";
import {CustomService} from "../../services/frapper/custom.service";


@Component({
  selector: 'app-add-naturalisation',
  templateUrl: './add-naturalisation.component.html',
  styleUrls: ['./add-naturalisation.component.scss']
})
export class AddNaturalisationComponent {

  @Input() parentModal: BsModalRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  type:any;
  modalRef!: BsModalRef;
  classifications:any =[];
  natures:any =[];

  my_tribunal:any = null


  imageFile:any ={}

  constructor(private modalService: BsModalService,
              private naturalisation: NaturalisationService,
              public options: ModalOptions,
              private custom: CustomService,
              private formBuilder: FormBuilder,
              private dossierService:DossierService,
              private ngxService: NgxUiLoaderService,
              private alerte:AlertesService,
              public bsModalRef: BsModalRef,
              private userCourrierService:UsercourrierService,
              private penalService: PenalCourrierService) {
    this.data = options.initialState.data
    this.type = options.initialState.typeCourrier;
    console.log('the data====> ', this.data)
    try{
      this.my_tribunal = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }

  }




  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg modal-dialog-centered'}));
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  closeModal(){
    this.bsModalRef.hide()
  }


  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message); 
  }

  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.type );
    var stepFirst = this.type;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });
  }


  onFileSelected(event: any,key) {

    let image:any = event.target.files[0] as File;
    console.log('this.imageFile ==> ',this.imageFile)
    this.createPiece(image,key)
  }

  createPiece(file,key){
    this.ngxService.start();

    const formData1= new FormData();
    formData1.append('is_private', '0');
    formData1.append('file',file);

    this.custom.createFiles(formData1).then(res =>{
      //console.log('IN FILE ==> ',res)
      this.imageFile[key]=res.message.file_url
      this.ngxService.stop();
    },err =>{
      //console.log('IN UPLOAD FILE ERR ==> ',err)
      this.ngxService.stop();
      this.alerte.errorNotification('Pieces','Une erreur s\'est produite!')
    })
  }

  AddNewNaturalisation(form: NgForm){
    this.ngxService.start();
    let init_step =''
    let body_content = form.value
    if(this.my_tribunal !=null){
      body_content["tribunal"]= this.my_tribunal.name
    }
    body_content["statut"]="En cours"
    body_content["docstatus"]= 1

    //console.log('DataFormValue 1', body_content);

    const ImagesCharged = Object.keys(this.imageFile);
    for (let key of ImagesCharged){
      body_content[key] = this.imageFile[key]
    }


    console.log('DataFormValue', body_content);

    //return
    this.naturalisation.nouvelleDemande(body_content).then(res =>{
      this.ngxService.stop();
     if (res != null) {
      this.closeWithMessage("success");
     }else{
      this.alerte.errorNotification('Erreur lors de l\'enregistrement')
     }
      this.openModalSuccessDialog('Demande enregistrée avec success');
     
    },err =>{
      this.alerte.errorNotification('Erreur lors de l\'enregistrement')
      this.ngxService.stop();
    })




    // this.dossierService.nouveauDossier(dossier.value).then( (res:any) => {
    //   //console.log('REsult ---> ', res)
    //   this.dossierService.updateSatate(res.data.name,init_step).then(ress => {
        // this.openModalSuccessDialog('Dossier enregisté avec success')
        //   this.ngxService.stop();
    //   },
    //     error =>{
    //       this.alerte.errorNotification('Mise a jour du dossier','Impossible de mettre a jour le dossier')
    //       console.log('Error On update==> ',error)
    //       this.ngxService.stop();
    //     })
    // },
    //   error =>{
    //     this.alerte.errorNotification('Creation de dossier','Impossible de creer le dossier')
    //     console.log('Error ==> ',error)
    //     this.ngxService.stop();
    //   })


  }

  ngOnInit() {
    //console.log('This.data -----> ', this.data)
    // this.userCourrierService.getAllowedClassification().then( res =>{
    //   //console.log(res.data)
    //   this.classifications = res.data
    // })
    // this.userCourrierService.getAllowedNatureCourrier().then( res =>{
    //   console.log(res.data)
    //   this.natures = res.data
    // })
    //console.log('this.data ==> ',this.data)


    var self = this;
    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();



    })

    $(document).ready(function() {
      $(".file-input").on("change", function() {
          // Récupérer le nom du fichier sélectionné
          const fileName = $(this).val().split("\\").pop();
          // Trouver l'élément span à l'intérieur du label parent et mettre à jour son texte
          $(this).parent().find('.file-label').text(fileName);
      });
  });

  }
}
