import { Component, OnInit, Type } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DossierService } from '../../services/frapper/dossier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { VisualizeDemandevisiteComponent } from '../visualize-demandevisite/visualize-demandevisite.component';
import { MyCONST } from '../../CONST/myConst';
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';
import { TypeDemandesDocumentComponent } from '../../widgets/type-demandes-document/type-demandes-document.component';
import { AddCasierComponent } from '../add-casier/add-casier.component';
import { AddNaturalisationComponent } from '../add-naturalisation/add-naturalisation.component';
import { AddDemandesComponent } from '../add-demandes/add-demandes.component';
import { DemandesService } from '../../services/frapper/demandes.service';

@Component({
  selector: 'app-view-details-demandes',
  templateUrl: './view-details-demandes.component.html',
  styleUrls: ['./view-details-demandes.component.scss']
})
export class ViewDetailsDemandesComponent implements OnInit {

  data:any;
  type: any;
  type_request: any;
  templates: any
  template_demande: any = null
  show_actions: any
  is_stepped: boolean = false;
  steps_demande: any = [];
  fieldsByStep: any[] = [];
  activeTab: number = 0;
  word_filtered:string ="";
  ServicePermis = [];
  constructor(private _formBuilder: FormBuilder,
    private options: ModalOptions,
    private dossierService:DossierService,
    private alerte:AlertesService,
    public bsModalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private demandesService: DemandesService,
    
    private penalCourrierService: PenalCourrierService,
    private modalService: BsModalService) {
      this.data = this.options.initialState.data;
      this.type =  this.options.initialState.type;
      this.type_request =  this.options.initialState.type_request;
      this.show_actions = this.options.initialState.show_actions;
      console.log(this.type);
      
      
      // this.templates = [
      //   {
      //     code:"3457bh",
      //     is_external: 1,
      //     type_demande:"visite detenu",
      //     content:{
      //       nom_visiteur: this.data.nom_visiteur,
      //       prenom_visiteur: this.data.prenom_visiteur,
      //       date_naissance: this.data.date_naissance,
      //       lieu_naissance: this.data.lieu_naissance,
      //       num_piece: this.data.num_piece,
      //       date_delivrance_piece: this.data.date_delivrance_piece,
      //       adresse: this.data.adresse,
      //       profession: this.data.profession,
      //       affiliation: this.data.affiliation,
      //       lieu_detention: this.data.lieu_detention,
      //       nom_detenu: this.data.nom_detenu,
      //       prenom_detenu: this.data.prenom_detenu
            
      //     },
      //   }
      // ]

    }

    // get object type de demande
  getObjectTypeDemande(){
    
    this.demandesService.getOneTypeDemande(this.template_demande.type_demande).then(res =>{
      console.log("get result one demande ==>",res )
      this.word_filtered = res.data.titre
    },err =>{
    })
  }
  ngOnInit(): void {
    //  this.template_demande = this.templates.find(template => template.type_demande === this.type);
    this.template_demande = this.data;
    console.log('templatedemenade', this.template_demande);
    this.steps_demande = Object.values(this.template_demande?.content);
    console.log('the step demandes', this.steps_demande);
    this.getObjectTypeDemande()
    this.getFormInputs()


  }
  

  getFormInputs() {
    // verifier si il ya des steps
    if (this.steps_demande != null) {
      const nameStepCount = this.steps_demande.reduce((count, step) => {
        return count + (step.name_step ? 1 : 0);
       }, 0);
       if (nameStepCount>0) {
         this.is_stepped = true
       }
   
       if (this.is_stepped) {
         this.fieldsByStep = this.steps_demande.map(step => this.getFields(step.fields));
       }
    
    }
  
 console.log('steps: ', this.steps_demande);
 console.log('fieldsByStep: ', this.fieldsByStep);
}

getFields(step: any): any[] {
  return Object.entries(step)
      .filter(([key]) => key !== 'name_step')
      .map(([key, value]) => ({ key, value }));
}
  formatLabel(key: string): string {
     // remove "file_" prefix and format the rest of the key
      if (key.startsWith('file_')) {
        return key.replace('file_', '').replace(/_/g, ' ').replace(/\b\w/g, char => char); // Remove "file_" and format
      }
      
      return key.replace(/_/g, ' ').replace(/\b\w/g, char => char);
      }
  getKeys(content: any): string[] {
    return content ? Object.keys(content) : [];
  }

    openModalVisualizeD_visite() {
      
      const config: ModalOptions = {
        initialState: {
          data: this.data,
         
        }
      }
  
       // Ouvrir le modal
       const modalRef = this.modalService.show(VisualizeDemandevisiteComponent, Object.assign({}, config, { class: 'modal-lg' }));
  
    }

    extractFields(data: any): { [key: string]: any } {
      let result: { [key: string]: any } = {};
      
  
      Object.keys(data.content).forEach(step => {
        if (data.content[step].fields) {
          const fields = data.content[step].fields;
          if (fields) {
            Object.keys(fields).forEach(key => {
              result[key] = fields[key];
            });
          }
        }else{
          result = data.content
        }
        
       
      });
      return result;
    }

    

    openModalModalGenerateDocument() {
      this.ServicePermis = [];
      let _data:any
      let type_doctype:string;

      
      console.log('yyy1', this.data);
      let componentToOpen: Type<AddCasierComponent | TypeDemandesDocumentComponent | AddNaturalisationComponent | AddDemandesComponent>;
      switch (this.template_demande.flag) {
        case "CASIER":
            componentToOpen = AddCasierComponent
            _data = this.extractFields(this.data);
            // console.log('hhhh', _data);
            type_doctype = null;
            
    
          break;
        case "NATURALISATION":
            componentToOpen = AddNaturalisationComponent
            _data = this.extractFields(this.data);
            type_doctype= null;
            // console.log('hhhh', _data);
          break;
      
        default:
          componentToOpen = TypeDemandesDocumentComponent
          _data = this.data;
          type_doctype = this.demandesService.Doctype_Demande
          console.log('hhhh', _data);
          break;
      }
      this.ServicePermis.push(this.template_demande.flag)
      
      const config: ModalOptions = {
        initialState: {
          data: _data,
          type_doctype: type_doctype,
          apply_filter_document: this.word_filtered,
          type: 'document',
          allowed_flag:this.ServicePermis
         
        }
        // initialState: {
        //   data: _data,
        //   type: this.type,
        //   item: this.template_demande,
        //   categorie:'document',
        //   allowed_flag:this.ServicePermis
   
        // }
       
        
      }
      console.log('vratchhh',config);
     

      return new Promise<string>((resolve, reject) => {
        const modalRef = this.modalService.show(componentToOpen, Object.assign({}, config, {class:'modal-lg '}));
        modalRef.onHidden.subscribe((value: string) => {
          console.log("in openModalDetails", value);
  
          resolve(value); // Résoudre la promesse avec la valeur retournée
        });
      }).then(async (value: string) => {
        // this.refresh();
  
  
      });
  
       // Ouvrir le modal
      //  const modalRef = this.modalService.show(VisualizeDemandevisiteComponent, Object.assign({}, config, { class: 'modal-lg' }));
  
    }
    openModalVisualPiece(urlpiece: any = null) {
      const URL=MyCONST.API_URL+urlpiece
      console.log('dddd',urlpiece);
      if (urlpiece != "") {
        const config: ModalOptions = {
          initialState: {
            data: URL,
            url:URL,
            parentModal : null
          }
        }
    
         // Ouvrir le modal
         const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg modal-dialog-centered' }));
    
      }else{
        this.alerte.warningNotification('aucune piéce téléchargée')
      }
    
       // Mettre à jour l'option initialState avec le modalRef
    }

    
}
