

<div class="d-flex justify-content-between flex-column align-middle flex-wrap align-items-center">
  <div class="title-container">
    <div class="d-flex justify-content-center">
        <h5 id="heading" class="modal_title" style="font-size: 13px;">Détails enrôlement {{data.numero_du_jugement}}</h5>
        <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
          <i class="fas fa-times modal_title" style="font-size: 1.5rem; "></i>
        </button>
      </div>
  </div>
  <div class="card-list d-flex justify-content-center col-12 flex-wrap align-items-center">
    <div class="col-sm-10 col-md-3 col-lg-3 mb-2 align-items-center">
        <div class="card text-center align-items-center text-nowrap" (click)="openModalDetailJugement(data)" >
            <h3 class="card-title code code-details"><i  class="fas fa-solid fa-gavel" style="font-size: 50px;"></i></h3>
            <h6 style="font-size: 13px;" class="card-description texte-limite text-nowrap " >{{data.numero_du_jugement}}</h6>
        </div>
    </div>
    <div class="col-sm-10 col-md-6 col-lg-6 mb-2 align-items-center">
        <div class="card text-center align-items-center ">
            <h3 class="card-title code code-summary" (click)="openModalChambre(data)"><i  class="fas fa-regular fa-building" style="font-size: 50px;"></i></h3>
            <h6 style="font-size: 13px;" class="card-description">{{data.chambre}}</h6>
            <i>{{data.libelle}}</i>
        </div>
    </div>
    <div class="col-sm-10 col-md-3 col-lg-3 mb-2 align-items-center">
        <div class="card text-center align-items-center text-nowrap">
            <h3 class="card-title code code-details" ><i class="fas fa-regular fa-calendar" style="font-size: 50px;"></i></h3>
            <h4 style="font-size: 13px;" class="card-description text-nowrap">{{getFormattedDate(data.date_du_jugement)}}</h4>
        </div>
    </div>

  </div>
  <div class="sample" style="visibility: hidden;">
      <h3 class="sample-title code">Membres de la chambre</h3>
      <div class="sample-code" *ngIf="data.chambre != null">
          <div class="code" *ngFor="let item of data.chambre.membres">
              <span class="code-details">{{item}}</span>
              <br>

              <!-- <span class="code-details">Ibrahim Anne</span> -->

          </div>
      </div>

  </div>
</div>
