import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertesService } from '../../services/public/alertes.service';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { AddDemandesComponent } from '../../dialogs/add-demandes/add-demandes.component';
import {DemandesService} from "../../services/frapper/demandes.service";
import { DocumentService } from '../../services/frapper/document.service';

@Component({
  selector: 'app-type-demandes-document',
  templateUrl: './type-demandes-document.component.html',
  styleUrls: ['./type-demandes-document.component.scss']})
export class TypeDemandesDocumentComponent implements OnInit {
  items: any = []
  data: any
  type: any
  type_doctype: any = null
  numero_du_jugement:any = null
  modalRef!: BsModalRef;
  RestrictedDemand:any = null
  apply_filter_document:any;
  constructor(
    private options: ModalOptions,
    private datePipe: DatePipe,
    public bsModalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private demandesService: DemandesService,
    private documentService: DocumentService,
    private alerte: AlertesService,
    private modalService: BsModalService
  ) {
    this.data = this.options.initialState.data;
    this.type = this.options.initialState.type;
    this.type_doctype = this.options.initialState.type_doctype;
    this.numero_du_jugement = this.options.initialState.numero_du_jugement;
    this.apply_filter_document = this.options.initialState.apply_filter_document;
    console.log('theword_filtered',this.apply_filter_document );
    

    this.items = [
      {title: 'Visite detenu'},
      {title: 'Autre'}
    ]

    try {
      this.RestrictedDemand  =  this.options.initialState.allowed_flag
    }catch (e) {
      this.RestrictedDemand  =  null
    }

    switch (this.type) {
      case 'document':
        this.getTypeDocumentDisponible()
        break;

      case 'demande':
        this.getTypeDemandeDisponible()
        break;
    
      default:
        break;
    }
    

  }
  ngOnInit(): void {
    console.log('data on type demande',this.data);
    
  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message); 
  }
  
  async getTypeDemandeDisponible(){
    this.ngxService.start()
    let filter = null;
    if(this.RestrictedDemand != null){
      // filter = '[["flag" , "in","'+this.RestrictedDemand+'"],["titre" , "in","'+this.RestrictedDemand+'"]]'

      filter = '[["flag" , "in","'+this.RestrictedDemand+'"]]'
    }
    this.demandesService.getAllTypeDeDemande(filter).then(res =>{
      let results = res.data
      for(let res of results){
        res.content = JSON.parse(res.content)
      }
      this.items = res.data
      console.log('results items: ', this.items);

      this.ngxService.stop()
    },err => {
      this.alerte.errorNotification('Type de demande','Impossible ')
      this.ngxService.stop()
    })

  }

  async getTypeDocumentDisponible(){
    this.ngxService.start()
    let filter = null;
    if(this.RestrictedDemand != null){
      filter = '[["flag" , "in","'+this.RestrictedDemand+'"]]'
    }
    this.documentService.getAllTypeDeDocument(filter).then(res =>{
      let results = res.data
      for(let res of results){
        res.content = JSON.parse(res.content)
      }
      console.log('resullll=>', results);
      
       // si il y a plusieurs resultat filtrer par le titre
       
       let  filtre = results.filter(res => res.titre === this.apply_filter_document);

       console.log('results filtre: ', filtre);
       
      this.items = filtre.length == 0 ? results:filtre
      // this.items = res.data
      console.log('results items: ', this.items);

      this.ngxService.stop()
    },err => {
      this.alerte.errorNotification('Type de document','Impossible ')
      this.ngxService.stop()
    })

  }


  openModalAddDemandeVisite(type: string, item :any) {

    const config: ModalOptions = {
      initialState: {
        data: this.data,
        type: type,
        item: item,
        categorie:this.type,
        type_doctype: this.type_doctype,
        numero_du_jugement:this.numero_du_jugement,
      }
     }
     console.log('the config=>', config);
     
    return new Promise<string>((resolve, reject) => {
      this.modalRef = this.modalService.show(AddDemandesComponent, Object.assign({}, config, {class:'modal-xl'}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe((value: string) => {
        console.log("in openModalDetailsTypedemande", value);
        if ( value == "success") {
          this.closeWithMessage("success")
        }

      });
    }).then(async (value: string) => {
    
     
      // this.refresh();


    });

  }


}
