<!-- <nav class="navbar navbar-expand-lg navbar-light navbar-mainbg fixed-top" style="z-index: 1050;">
  <a class="navbar-brand navbar-logo" [routerLink]="['accueil']">
    <img src="../../../../../assets/images/ejustice.png" width="40px" height="35px" alt="">
    &nbsp;&nbsp;E-justice
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-bars text-white"></i>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <div class="hori-selector"><div class="left"></div><div class="right"></div></div>
      <ng-container  *ngIf="me == null; else secondmenu">
        <li class="nav-item active">
          <a class="nav-link" href="#home">
            <i class="fas fa fa-home" aria-hidden="true"></i> Accueil
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#services">
            <i class="fas fa fa-cog" aria-hidden="true"></i> Sérvices
          </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" href="#about">
            <i class="fas fa-info-circle" aria-hidden="true"></i> A propos
          </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" href="#about">
            <i class="fas fa-hourglass-start" aria-hidden="true"></i> Suivi
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openLoginModal()">
            <i class="fas fa-user-circle" aria-hidden="true"></i> {{label_btn}}
          </a>
        </li>
      </ng-container>
      <ng-template #secondmenu>
        <li class="nav-item" *ngFor="let item of filtered_menu">
          
          <a
            class="nav-link"
            [routerLink]="[item.route]" routerLinkActive="active"
            [ngClass]="{'active': item.route == activeRoute}"
           >
            <b><i class="fas fa-solid fa-angle-right" *ngIf="item.class != 'groupTitle'"></i></b>
            <span>{{item.name}}</span>
          </a>

        </li>
        

      </ng-template>

    </ul>
  </div>

</nav> -->


<section class="navigation">
  <div class="nav-container">
    <div class="brand">
      <a class="navbar-brand navbar-logo" [routerLink]="[path_home]">
        <img src="../../../../../assets/images/ejustice.png" width="60px" height="55px" alt="">
        &nbsp;&nbsp;{{'second_app_name' | translate}}
      </a>
    </div>
    <nav>
      <div class="nav-mobile"><a id="navbar-toggle"><span></span></a></div>
      <ul class="nav-list">
        <ng-container *ngIf="me == null; else secondmenu">
            <li class="nav-item active">
                <a class="nav-link"  id="btn-home" [routerLink]="[path_home]">
                    <i class="fas fa fa-home" aria-hidden="true"></i> {{'MENU.home' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="moreServices()" id="btn-services">
                    <i class="fas fa fa-cog" aria-hidden="true"></i> {{'MENU.services' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link"  id="btn-about">
                    <i class="fas fa-info-circle" aria-hidden="true"></i> {{'MENU.about' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link"  id="btn-suivi">
                    <i class="fas fa-hourglass-start" aria-hidden="true"></i> {{'MENU.suivi' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="openLoginModal()">
                    <i class="fas fa-user-circle" aria-hidden="true"></i> {{label_btn}}
                </a>
            </li>
        </ng-container>
    
        <ng-template #secondmenu>
            <li *ngFor="let item of filtered_menu" class="nav-item menu-item menu-item-has-children">
                <a>{{ item.name }}</a>
                <ul class="navbar-dropdown">
                    <li *ngFor="let sub_menu of item.subMenus">
                        <a [routerLink]="[sub_menu.route]" routerLinkActive="active" [ngClass]="{'active': sub_menu.route == activeRoute}">
                            {{ sub_menu.name }}
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a>
                    <i class="fas fa-hourglass-start" aria-hidden="true"></i> {{'MENU.suivi' | translate}}
                </a>
            </li>
            <li>
                <a>
                    <i class="fas fa-user-circle" aria-hidden="true"></i> {{label_btn}}
                </a>
                <ul class="navbar-dropdown">
                    <li>
                        <a class="menu-item" style="background-color: red;" (click)="logout()">
                            <b><i class="fas fa-solid fa-times-circle"></i></b>&nbsp;
                            <span>{{'MENU.logout' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ng-template>
    </ul>
    
    </nav>
  </div>
</section>
<br>