
<!DOCTYPE html>
<html lang="fr">

<body>
      <div class="d-flex  justify-content-end mr-4 pt-3"  (click)="onPrint2(printElement)">
          <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
          <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
        </div>
        <div id="papier" #elementToPrint ngxPrintElement  style="background-image: url(assets/images/background-form.png); background-size: cover;overflow: scroll;">

          <div style="margin:1vh;">
            <form #deamnde="ngForm"  novalidate name="deamnde" role="form">
              <div class="row">
                <div class="col-12" >
                  <div class="row">
                    <div class="col-3" >
                      <ngx-qrcode  value="{{qrCodeimg}}"  style="float: right;" cssClass="bshadow"></ngx-qrcode>
                    </div>
                    <div class="col-4">
                      <img src="../../../../assets/images/ejustice.png" width="80">
        
                    </div>
        
                    <div class="col-4">
                      <h6 class="text-center" style="font-size: 14px;">{{SERVICE.name}}</h6>
                    </div>
        
                  </div>
                </div>
        
                <div class="col-12 "  style="margin:1px;overflow: scroll;">
                  <div [innerHTML]="TEMPLATE"></div>
                </div>
        
        
              </div>
        
        
            </form>
          </div>
        </div>
        
        
        <!-- <div class="row" style="margin-top: 2vh">
          <div class="col-6">
            <div class="btn btn-danger text-center" style="width: 100%"  (click)="closeAllDialogs()" >Fermer </div> &nbsp;
          </div>
          <div class="col-6">
            <button class="btn btn-success text-center "  [disabled]="is_printing" (click)="onPrint('papier')" style="width: 100%">
              Imprimer
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-success text-center "  [hidden]="!is_printing" (click)="closeAllDialogs()" style="width: 100%">
              Impression on process
            </button>
          </div>
        
        </div> -->
</body>

</html>




  <!-- </mat-dialog-actions> -->


