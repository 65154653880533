<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">


         <!-- formulaire enrollement -->
         <div class="card"  style="padding: 10px;">
          <div class="title-container">
            <div class="d-flex justify-content-center">
                <h5 id="heading" class="text-primary" style="font-size: 13px;">Soit transmis</h5>
                <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
                  <i class="fas fa-times text-primary" style="font-size: 1.5rem; "></i>
                </button>
              </div>
          </div>
          <!-- <p>Fill all form field to go to next step</p> -->
          <form   novalidate id="msform" style="min-height: 300px;">
              <!-- progressbar -->
              <ul id="progressbar">
                  <!-- <li class="active" id="step1"><strong>Jugement</strong></li>
                  <li id="step2"><strong>Informations amende</strong></li> -->
                  <!-- <li id="step4"><strong>Decisions</strong></li> -->
                  <!-- <li id="step5"><strong>N° role</strong></li>
                  <li id="step3"><strong>Calendrier</strong></li> -->

              </ul>
              <!-- <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
              </div>  -->
               <!-- fieldsets -->
               <fieldset class="{{step}}" >
                <div class="form-card" >

                  <div class="row">
                    <div class="col">

                      <h6 class="text-left" style="font-size: 12px;">Désignation:</h6>
                          <!-- <input *ngIf="data.classification" type="text" class="form-control" name="classification" [ngModel]="data.classification" ngModel required/> -->
                          <input class="form-control" readonly type="text" name="numero_courrier" value="{{data.designation_1}}" />

                          <h6 class="text-left text-nowrap" style="font-size: 12px;">En le priant de bien vouloir procéder aux diligences suivantes:</h6>
                          <input class="form-control" readonly type="text" name="numero_courrier" value="{{data.action_1}}" />

                    </div>&nbsp;&nbsp;
                    <div class="col d-flex  flex-column justify-content-between" >
                      <h6 class="text-left mt-3" style="font-size: 12px;"></h6>
                        <input class="form-control" type="text" value="{{data.designation_2 || data.designation_interne}}" />
                        <h6 class="text-left mt-3" style="font-size: 12px;"></h6>
                     <input class="form-control" type="text" value="{{data.action_2}}" />

                    </div>
                  </div>
                  <h6 class="text-left text-nowrap" style="font-size: 12px;">Remarques:</h6>
                  <textarea style="height: 200px;" class="form-control"  value="{{data.remarque_1}}"> </textarea>

              </div>
              <!-- <input type="button" name="next" class="next action-button" value="Suivant" /> -->

                 <div >
                   <input  type="button" name="next"  class="next action-button bg-dark" style ="display: {{allowowed_send_courrier_depart?'block':'none'}};"
                           value="Générer un courrier de départ" style="min-width: 250px;"  (click)="openModalAddCourier('sortant')"/>
                 </div>



              </fieldset>


          </form>


      </div>


      </div>
  </div>
</div>
