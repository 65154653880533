<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
            <div class="d-flex justify-content-center ">
              <h5 id="heading" class="mt-2">{{'new_folder' | translate}}</h5>&nbsp;&nbsp;
              <input type="button" *ngIf="showAddCourrier" name="" class=" btn btn-outline-secondary action-button mt-2" value="{{'attachment_new_courrier' | translate}}" (click)="openModalAddCourrier('entrant')" />
              <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
                <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
              </button>
            </div>
              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform" #newdossier="ngForm" (ngSubmit)="AddNewDossier(newdossier)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>{{'details' | translate}}</strong></li>
                      <li id="step2"><strong>{{'inf_redacteur' | translate}}</strong></li>
                      <li id="step3"><strong>{{'inf_parties' | translate}}</strong></li>
                      <li id="step5">
                        <strong *ngIf="data.classification!='Pénale'" >{{'infractions' | translate}}</strong>
                        <strong *ngIf="data.classification!='Pénale'" >Fautes civile</strong>
                      </li>
                      <li id="step4"><strong>{{'elt_folder' | translate}}</strong></li>
                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br> <!-- fieldsets -->
                  <fieldset class="step1">
                      <div class="form-card">

                        <div class="row">
                          <div class="col-md-6 mb-3" style="padding: 0 5px 0 0;">
                              <label class="fieldlabels">{{'classification' | translate}}: *</label>
                              <input *ngIf="data.classification" type="text" class="form-control" name="classification"   [ngModel]="data.classification" ngModel required />
                              <select *ngIf="!data.classification" class="custom-select" name="classification" (change)="onClassificationChange($event)" ngModel required>
                                  <option *ngFor="let classification of classifications" [value]="classification.name">{{ classification.name }}</option>
                              </select>

                              <label class="fieldlabels">{{'nature' | translate}}: *</label>
                              <input *ngIf="data.nature" type="text" class="form-control" name="nature" [ngModel]="data.nature" ngModel required />
                              <select *ngIf="!data.nature" class="custom-select" name="nature" ngModel>
                                  <option *ngFor="let nature of natures" [value]="nature.name">{{ nature.name }}</option>
                              </select>

                              <label class="fieldlabels">{{'reference' | translate}}: </label>
                              <input *ngIf="data.reference" type="text" class="form-control" [ngModel]="data.reference" name="reference" ngModel required />
                              <select *ngIf="!data.reference" class="custom-select" name="reference" ngModel>
                                  <option value="Non">Non</option>
                                  <option value="Oui">Oui</option>
                              </select>

                              <label class="fieldlabels">{{'num_origine' | translate}}: </label>
                              <input class="form-control" type="text" name="numero_dorigine" [ngModel]="data.numero_dorigine" ngModel />
                          </div>

                          <div class="col-md-6 mb-3 d-flex flex-column">
                              <label class="fieldlabels">{{'num_folder' | translate}}: *</label>
                              <input class="form-control" type="text" name="numero_courrier" [ngModel]="data.numero_courrier" ngModel required />

                              <label class="fieldlabels">{{'inf_reference' | translate}}: </label>
                              <textarea class="form-control" style="height: 220px;" name="information_de_la_reference" [ngModel]="data.information_de_la_reference" ngModel></textarea>
                          </div>
                      </div>



                      </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" />
                  </fieldset >

                  <fieldset class="step2">
                      <div class="form-card">

                        <div class="row">
                          <div class="col-md-6 mb-3" style="padding: 0 5px 0 0;">
                              <label class="fieldlabels">{{'type_identif' | translate}}: </label>
                              <input *ngIf="data.type_identification" type="text" class="form-control" [ngModel]="data.type_identification" name="type_identification" ngModel />
                              <select *ngIf="!data.type_identification" class="custom-select" name="type_identification" ngModel>
                                  <option *ngFor="let id of identification_type" [value]="id.name">{{ id.type }}</option>
                              </select>

                              <label class="fieldlabels">{{'firstname' | translate}}: </label>
                              <input class="form-control" type="text" name="prenom" [ngModel]="data.prenom" ngModel required />

                              <label class="fieldlabels">{{'country' | translate}}: </label>
                              <input *ngIf="data.pays" type="text" class="form-control" [ngModel]="data.pays" name="pays" ngModel />
                              <select *ngIf="!data.pays" class="custom-select" name="pays" ngModel>
                                  <option value="SENEGAL">SENEGAL</option>
                                  <option value="AUTRES">AUTRES</option>
                              </select>

                              <label class="fieldlabels">{{'zone' | translate}}: </label>
                              <input class="form-control" type="text" name="region" [ngModel]="data.region" ngModel />

                              <label class="fieldlabels">{{'adress' | translate}}: </label>
                              <input class="form-control" type="text" name="adresse" [ngModel]="data.adresse" ngModel />
                          </div>

                          <div class="col-md-6 mb-3 d-flex flex-column">
                              <label class="fieldlabels">{{'num_identif' | translate}}: </label>
                              <input type="text" class="form-control" name="numero_identification" [ngModel]="data.numero_identification" ngModel />

                              <label class="fieldlabels">{{'lastname' | translate}}: </label>
                              <input class="form-control" type="text" name="nom" [ngModel]="data.nom" ngModel />

                              <label class="fieldlabels">{{'num_origine' | translate}}: </label>
                              <input class="form-control" type="text" name="numero_d_origine" [ngModel]="data.numero_d_origine" ngModel />

                              <label class="fieldlabels">{{'redaction' | translate}}: </label>
                              <textarea class="form-control" style="height: 120px;" name="redaction" [ngModel]="data.redaction" ngModel></textarea>
                          </div>
                      </div>


                        <!-- <div class="row">
                          <div class="col">

                            <label class="fieldlabels">Type d'identification (Expéditeur): </label>
                            <input *ngIf="data.type_identf_expediteur" type="text" class="form-control" [ngModel]="data.type_identf_expediteur" name="type_identf_expediteur" ngModel/>
                            <select *ngIf="! data.type_identf_expediteur" class="custom-select" id="inputGroupSelect01" name="type_identf_expediteur" ngModel>
                              <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                            </select>
                          </div>&nbsp;
                          <div class="col">
                            <label class="fieldlabels">Numéro identification (Expéditeur) </label>
                            <input class="form-control" type="text" name="num_identif_expediteur" [ngModel]="data.num_identif_expediteur" ngModel/>
                          </div>
                        </div> -->

                      </div> <input type="button" name="next" class="next action-button" value="{{'next' | translate}}" />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
                  </fieldset>


                  <fieldset class="step3" >
                    <div class="form-card col-12" >

                        <!-- <div class="row"> -->

                          <!-- <div class="filterselect  mt-2 col-xl-5 col-lg-5 col-md-5 m-auto">
                            <label class="select">
                              <select (change)="selectTypePrevenu($event.target.value)">
                                <option>--Choisir le nombre de prevenu--</option>
                                <option [value]="0">Un seul prevenu</option>
                                <option [value]="1">Plusieurs prevenus</option>
                              </select>
                            </label>


                          </div> -->
                        <!-- </div> -->

                        <div class="row">
                          <ng-container >
                              <div class="col-md-6 mb-3" style="padding: 0 5px 0 0;">
                                <div style="visibility: hidden;">
                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'num_identif_plaignant' | translate}}: </label>
                                  <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Numéro d'identification demandeur: </label>
                                    <input type="text" class="form-control" name="numéro_didentification_plaignant" ngModel/>
                                </div>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'type_identif_plaignant' | translate}}: </label>
                                  <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Type d'identification du demandeur: </label>

                                  <select class="custom-select" name="type_identification_plaignant" ngModel>
                                      <option *ngFor="let id of identification_type" [value]="id.name">{{ id.type }}</option>
                                  </select>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'num_identif_plaignant' | translate}}: </label>
                                  <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Numéro d'identification demandeur: </label>
                                  <input type="text" class="form-control" name="numéro_didentification_plaignant" ngModel/>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'fullname_plaignant' | translate}}: </label>
                                  <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Nom complet demandeur: </label>
                                  <input type="text" class="form-control" name="nom_complet_du_plaignant" ngModel/>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'lieuN_plaignant' | translate}}: </label>
                                  <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Lieu de naissance demandeur: </label>
                                  <input type="text" class="form-control" name="lieu_de_naissance_du_plaignant" ngModel/>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'dateN_plaignant' | translate}}: </label>
                                <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Date de naissance demandeur: </label>
                                  <input type="date" class="form-control" name="date_de_naissance_du_plaignant" ngModel/>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'adress_plaignant' | translate}}: </label>
                                <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Adresse complète du demandeur: </label>
                                  <input class="form-control" type="text" name="addresse_complete_du_plaignant" ngModel/>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">Nom complet de la mère du plaignant: </label>
                                <label *ngIf="data.classification!='Pénale'" class="fieldlabels">{{'fullname_mother_plaignant' | translate}}: </label>
                                  <input class="form-control" type="text" name="nom_complet_de_la_mere_du_plaignant" ngModel/>

                                  <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'fullname_father_plaignant' | translate}}: </label>
                                  <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Nom complet du père du plaignant: </label>
                                  <input class="form-control" type="text" name="nom_complet_du_pere_du_plaignant" ngModel/>
                              </div>

                              <div class="col-md-6 mb-3 d-flex flex-column justify-content-between" style="padding: 0 5px 0 0;">
                                  <div class="d-flex mb-3" style="padding: 0 5px 0 0;" >
                                      <label class="fieldlabels mt-4">{{'active_many_prevenus' | translate}}: </label>
                                      <div class="toggle-wrapper">
                                          <input class="toggle-input" type="checkbox" (change)="selectTypePrevenu($event)" id="pin-toggle"/>
                                          <label class="toggle-container" for="pin-toggle">
                                              <div class="toggle-circle">
                                                  <div class="pin-icon">
                                                      <span class="pin-top"></span>
                                                      <span class="pin-middle"></span>
                                                      <span class="pin-bottom"></span>
                                                  </div>
                                              </div>
                                          </label>
                                      </div>
                                  </div>
                                  <ng-container *ngIf="is_multiple_prevenu == 0; else secondBlock">
                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'type_identif_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Type d'identification du defenseur: </label>
                                    <select class="custom-select" name="type_identification_du_poursuivis" ngModel>
                                        <option *ngFor="let id of identification_type" [value]="id.name">{{ id.type }}</option>
                                    </select>

                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'num_identif_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Numéro d'identification defenseur : </label>
                                    <input type="text" class="form-control" name="numéro_didentification_du_poursuivis" ngModel/>

                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'fullname_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Nom complet defenseur: </label>
                                    <input type="text" class="form-control" name="nom_complet_du_poursuivis" ngModel/>

                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'lieuN_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Lieu de naissance defenseur: </label>
                                    <input type="text" class="form-control" name="lieu_de_naissance_du_prévenu" ngModel/>

                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'dateN_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Date de naissance defenseur: </label>
                                    <input type="date" class="form-control" name="date_de_naissance_du_prévenu" ngModel/>

                                    <label *ngIf="data.classification=='Pénale'"  class="fieldlabels">{{'adress_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Adresse complète du defenseur: </label>
                                    <input class="form-control" type="text" name="addresse_complete_du_poursuivis" ngModel/>

                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'fullname_mother_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Nom complet de la mère du defenseur: </label>
                                    <input class="form-control" type="text" name="nom_complet_de_la_mere_du_prévenu" ngModel/>

                                    <label *ngIf="data.classification=='Pénale'" class="fieldlabels">{{'fullname_father_prevenu' | translate}}: </label>
                                    <label *ngIf="data.classification!='Pénale'" class="fieldlabels">Nom complet du père du defenseur: </label>
                                    <input class="form-control" type="text" name="nom_complet_du_pere_du_prévenu" ngModel/>
                                  </ng-container>
                                  <ng-template #secondBlock>
                                    <label><b class="bg-dark text-light">{{'list_prevenus' | translate}}:</b>&nbsp;&nbsp;
                                        <i id="addRowBtn" class="fa fa-solid fa-plus text-success" style="font-size:22px; cursor:pointer" (click)="openModalAddPrevenu()"></i>
                                    </label>
                                    <div style="font-size: 13px; min-height: 600px; border: dashed 1px grey;">
                                        <table class="table table-striped table-striped" style="font-size: 13px;">
                                            <thead>
                                                <tr class="bg-secondary text-light">
                                                    <th scope="col">#</th>
                                                    <th scope="col">{{'full_name' | translate}}</th>
                                                    <th scope="col">{{'actions' | translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let prevenu of prevenusList; let i = index">
                                                    <th scope="row">{{ i + 1 }}</th>
                                                    <td>{{ prevenu.nom_complet_du_poursuivis }}</td>
                                                    <td class="align-middle text-center text-sm">
                                                        <div class="d-flex justify-content-between">
                                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalDetailsPrevenu(prevenu)">
                                                                <i class="fas fa-solid fa-eye text-primary" style="font-size: 21px;"></i>
                                                            </a>
                                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="deletePrevenu(prevenu)">
                                                                <i class="fas fa-solid fa-trash text-danger" style="font-size: 20px;"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  </ng-template>


                              </div>
                          </ng-container>

                          <!-- <ng-template #secondBlock>
                              <div class="col-md-6 mb-3" style="padding: 0 5px 0 0;">
                                  <label class="fieldlabels">Type d'identification du plaignant: </label>
                                  <select class="custom-select" name="type_identification_plaignant" ngModel>
                                      <option *ngFor="let id of identification_type" [value]="id.name">{{ id.type }}</option>
                                  </select>

                                  <label class="fieldlabels">Numéro d'identification plaignant: </label>
                                  <input type="text" class="form-control" name="numéro_didentification_plaignant" ngModel/>

                                  <label class="fieldlabels">Nom complet plaignant: </label>
                                  <input type="text" class="form-control" name="nom_complet_du_plaignant" ngModel/>

                                  <label class="fieldlabels">Lieu de naissance plaignant: </label>
                                  <input type="text" class="form-control" name="lieu_de_naissance_du_plaignant" ngModel/>

                                  <label class="fieldlabels">Date de naissance plaignant: </label>
                                  <input type="date" class="form-control" name="date_de_naissance_du_plaignant" ngModel/>

                                  <label class="fieldlabels">Adresse complète du plaignant: </label>
                                  <input class="form-control" type="text" name="addresse_complete_du_plaignant" ngModel/>

                                  <label class="fieldlabels">Nom complet de la mère du plaignant: </label>
                                  <input class="form-control" type="text" name="nom_complet_de_la_mere_du_plaignant" ngModel/>

                                  <label class="fieldlabels">Nom complet du père du plaignant: </label>
                                  <input class="form-control" type="text" name="nom_complet_du_pere_du_plaignant" ngModel/>
                              </div>

                              <div class="col-md-6 d-flex flex-column justify-content-start">
                                  <div class="d-flex mb-3">
                                      <label class="fieldlabels mt-4">Activer pour le mode plusieurs prévenus: </label>
                                      <div class="toggle-wrapper">
                                          <input class="toggle-input" checked type="checkbox" (change)="selectTypePrevenu($event)" id="pin-toggle"/>
                                          <label class="toggle-container" for="pin-toggle">
                                              <div class="toggle-circle">
                                                  <div class="pin-icon">
                                                      <span class="pin-top"></span>
                                                      <span class="pin-middle"></span>
                                                      <span class="pin-bottom"></span>
                                                  </div>
                                              </div>
                                          </label>
                                      </div>
                                  </div>

                                  <label><b class="bg-dark text-light">Liste prévenus:</b>&nbsp;&nbsp;
                                      <i id="addRowBtn" class="fa fa-solid fa-plus text-success" style="font-size:22px; cursor:pointer" (click)="openModalAddPrevenu()"></i>
                                  </label>
                                  <div style="font-size: 13px; min-height: 600px; border: dashed 1px grey;">
                                      <table class="table table-striped table-striped" style="font-size: 13px;">
                                          <thead>
                                              <tr class="bg-secondary text-light">
                                                  <th scope="col">#</th>
                                                  <th scope="col">Nom complet</th>
                                                  <th scope="col">Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr *ngFor="let prevenu of prevenusList; let i = index">
                                                  <th scope="row">{{ i + 1 }}</th>
                                                  <td>{{ prevenu.nom_complet_du_poursuivis }}</td>
                                                  <td class="align-middle text-center text-sm">
                                                      <div class="d-flex justify-content-between">
                                                          <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalDetailsPrevenu(prevenu)">
                                                              <i class="fas fa-solid fa-eye text-primary" style="font-size: 21px;"></i>
                                                          </a>
                                                          <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="deletePrevenu(prevenu)">
                                                              <i class="fas fa-solid fa-trash text-danger" style="font-size: 20px;"></i>
                                                          </a>
                                                      </div>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </ng-template> -->
                      </div>

                        <label class="fieldlabels">{{'resume_affaire' | translate}}: </label>
                        <textarea style="height: 220px;" class="form-control"  name="resume_de_l_affaire" ngModel></textarea>


                    </div>
                     <input type="button" name="next" class=" next action-button" value="Suivant" /> <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                </fieldset>

                <fieldset  class="step5" >
                    <div class="form-card" style="min-height: 400px;">
                        <div class="d-flex flex-column justify-content-between">
                          <div class="" style="padding: 0 5px 0 0;">
                            <div class="col shadow">
                              <label *ngIf="data.classification=='Pénale'"  class="fieldlabels bg-dark text-light">{{'infractions' | translate}}:</label>
                              <label *ngIf="data.classification!='Pénale'"  class="fieldlabels bg-dark text-light">Fautes civile</label>
                                <!-- Bouton pour ajouter une ligne -->
                                <i id="addRowBtn_inf" class="fa fa-solid fa-plus text-success mt-3" style="font-size:22px; cursor:pointer"></i>

                                <div class="container">
                                    <table id="myTable" class="table table-striped col-12 col-md-12 col-sm-12  text-nowrap text-center m-auto" >
                                        <thead style="font-size: 12px;" class="text-dark" >
                                          <tr class="text-nowrap">
                                            <th>{{'nature' | translate}}</th>
                                            <th>{{'date' | translate}}</th>
                                            <th>{{'textes' | translate}}</th>
                                            <th>{{'actions' | translate}}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let inf of infractions" class="text-nowrap">
                                            <td>

                                                <input type="text" class="form-control col-sm-10" style="min-width: 130px;" name="nature" (change)="filterValueChange($event.target.value, 'nature')" [value]="inf.nature" />

                                            </td>
                                            <td>
                                              <input type="date" class="form-control col-sm-10" name="date" (change)="filterValueChange($event.target.value, 'date')" [value]="inf.date" />
                                            </td>
                                            <td>
                                              <input type="text" class="form-control col-sm-10" style="min-width: 130px;" name="textes" (change)="filterValueChange($event.target.value, 'textes')" [value]="inf.textes" />
                                            </td>
                                            <td>
                                              <i class="fas fa-solid fa-trash delete-row text-danger mt-2"  (click)="deleteInfraction(inf)" style="font-size:22px; cursor:pointer"></i>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                              </div>

                          </div>
                      </div>



                    </div>
                    <input type="button" name="next" class=" next action-button" value="{{'next' | translate}}" />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />
                </fieldset>

                <fieldset  class="step4" >
                <div class="form-card">
                    <div class="row">

                    </div>

                    <div class="row">
                        <div class="col-md-6 " style="padding: 0 5px 0 0;" *ngIf="CAN_SEE_REMARQUE_PROCUREUR">
                            <label class="fieldlabels">{{'notif_procureur' | translate}}: </label>
                            <select class="custom-select" id="inputGroupSelect01" name="rm_proc" ngModel>
                                <option value="En parler">{{'en_parler' | translate}}</option>
                                <option value="M'en parler">{{'m_en_parler' | translate}}</option>
                            </select>
                            <label class="fieldlabels">{{'remarque_procureur' | translate}}: </label>
                            <textarea class="form-control" style="height: 220px;" name="remarque_du_procureur" ngModel></textarea>
                        </div>

                        <div class="col-md-6 d-flex flex-column justify-content-between">
                            <div class="row">
                                <div class="d-flex justify-content-between col-12">
                                    <label class="">{{'piece_folder' | translate}}: </label>
                                    <!--<a (click)="openModalAddPiece()" class="text-secondary font-weight-bold text-xs float-right" data-toggle="tooltip" data-original-title="Edit user">
                                        <i class="fas fa-solid fa-plus text-primary"></i> <span class="text-primary"> Ajouter pièce</span>
                                    </a>-->
                                </div>
                                <p class="text-center m-auto">{{'empty_piece' | translate}}</p>
                                <table class="table table-striped fieldlabels mt-3">
                                    <thead>
                                        <tr style="font-size: 12px;">
                                            <th scope="col">#</th>
                                            <th scope="col">{{'name_piece' | translate}}</th>
                                            <th scope="col">{{'num_courrier' | translate}}</th>
                                            <th scope="col">{{'visualize' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-size: 10px;">
                                        <tr *ngFor="let piece of InternalPieces">
                                            <th scope="row">{{ piece.name }}</th>
                                            <td>{{ piece.nom_du_fichier }}</td>
                                            <td>{{ piece.parent }}</td>
                                            <td>
                                                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                                                    data-original-title="Edit user" (click)="openModalVisualPiece(piece)">
                                                    <i class="fas fa-solid fa-eye text-primary" style="font-size: 20px;"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </div>
                <input type="submit" name="next" class=" action-button" value="{{'save' | translate}}"  />
                <input type="button" name="Précédent" class="previous action-button-previous" value="{{'previous' | translate}}" />


                </fieldset>




              </form>
          </div>


      </div>
  </div>
</div>
