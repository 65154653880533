<body style=" min-height: auto; margin-top: 5%;">
  <div class="">
    <div class="row mb-3">
      <div class="col-12 text-center">
        <h3 class="font-weight-bold title">{{'PUBLIC.list_operations' | translate}}</h3>
      </div>
    </div>

    <!-- Recherche et bouton actualiser -->
    <div class="row mb-3">
      <div class="col-10 col-md-10 m-auto d-flex flex-column flex-md-row justify-content-center">
        <div class="input-group mb-2 mb-md-0 col-12 col-sm-12 col-md-5">
          <input type="text" class="form-control" [(ngModel)]="keyword" placeholder="Numéro demande">
          <div class="input-group-append  justify-content-end">
            <button class="btn btn-outline-primary float-right" type="button" (click)="searchDemande()">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <button type="button" class="btn btn-dark mt-2 mt-md-0 col-12 col-sm-3 col-md-2" (click)="refresh()">{{'actualize' | translate}}</button>
      </div>
    </div>

    <!-- Table responsive -->
    <div class="table-responsive col-12">
      <table class="table table-striped table-bordered text-center" >
        <thead class="">
          <tr style="font-size: 12px;">
            <th class="text-uppercase text-light text-xs">
              <div class="d-flex align-items-start">
                <span class="me-2">{{'num' | translate}}</span>
                <div class="dropdown-container position-relative" data-dropdown-id="dropdown1" style="margin-left: 2%;">
                  <div class="dropdown-button d-flex align-items-start">
                    <i class="fa fa-filter" style="font-size: 12px;"></i>
                  </div>
                  <div class="dropdown-list position-absolute" style="display: none;">
                    <input type="search" placeholder="Search Numéro" class="form-control dropdown-search mb-2" />
                    <ul class="list-unstyled mb-0 text-dark"></ul>
                  </div>
                </div>
              </div>
            </th>
            <th class="text-uppercase text-light text-xs">
              <div class="d-flex align-items-start">
                <span class="me-2">{{'PUBLIC.operation' | translate}}</span>
                <div class="dropdown-container position-relative" data-dropdown-id="dropdown2" style="margin-left: 2%;">
                  <div class="dropdown-button d-flex align-items-start">
                    <i class="fa fa-filter"  style="font-size: 12px;"></i>
                  </div>
                  <div class="dropdown-list position-absolute" style="display: none;">
                    <input type="search" placeholder="recherche Opération" class="form-control dropdown-search mb-2" />
                    <ul class="list-unstyled mb-0 text-dark"></ul>
                  </div>
                </div>
              </div>
            </th>
            <th class="text-uppercase text-light text-xs">
              <div class="d-flex align-items-start">
                <span class="me-2">{{'PUBLIC.amount' | translate}}</span>
                <div class="dropdown-container position-relative" data-dropdown-id="dropdown3" style="margin-left: 2%;">
                  <div class="dropdown-button d-flex align-items-start">
                    <i class="fa fa-filter"  style="font-size: 12px;"></i>
                  </div>
                  <div class="dropdown-list position-absolute" style="display: none;">
                    <input type="search" placeholder="recherche montant" class="form-control dropdown-search mb-2" />
                    <ul class="list-unstyled mb-0 text-dark"></ul>
                  </div>
                </div>
              </div>
            </th>
            <th class="text-center text-uppercase text-light text-xs">
              <div class="d-flex align-items-start">
                <span class="me-2">{{'status' | translate}}</span>
                <div class="dropdown-container position-relative" data-dropdown-id="dropdown4" style="margin-left: 2%;">
                  <div class="dropdown-button d-flex align-items-start">
                    <i class="fa fa-filter"  style="font-size: 12px;"></i>
                  </div>
                  <div class="dropdown-list position-absolute" style="display: none;">
                    <input type="search" placeholder="Search Statut" class="form-control dropdown-search mb-2" />
                    <ul class="list-unstyled mb-0 text-dark"></ul>
                  </div>
                </div>
              </div>
            </th>
            <th class="text-center text-uppercase text-light text-xs">
              <div class="d-flex align-items-start">
                <span class="me-2">{{'date' | translate}}</span>
                <div class="dropdown-container position-relative" data-dropdown-id="dropdown5" style="margin-left: 2%;">
                  <div class="dropdown-button d-flex align-items-start">
                    <i class="fa fa-filter " style="font-size: 12px;"></i>
                  </div>
                  <div class="dropdown-list position-absolute" style="display: none;">
                    <input type="search" placeholder="Search Date" class="form-control dropdown-search mb-2" />
                    <ul class="list-unstyled mb-0 text-dark"></ul>
                  </div>
                </div>
              </div>
            </th>
            <th class="text-center text-uppercase text-light text-xs">{{'actions' | translate}}</th>
          </tr>
        </thead>

        <tbody class="text-sm text-nowrap" style="font-size: 12px;">
          <ng-container *ngIf="filtered_demandes.length > 0; else empty">
            <tr *ngFor="let element of filtered_demandes | paginate : { itemsPerPage: 10, currentPage: pageIntCourrier, id: 'operations' }" class="text-nowrap">
              <!-- Première colonne -->
              <td (click)="handleClick(element, 'ref')">
                <div class="px-2 text-nowrap">{{ element.ref }}</div>
              </td>
          
              <!-- Deuxième colonne -->
              <td (click)="handleClick(element, 'service')">
                <div class="d-flex flex-column justify-content-center px-2 py-1">
                  <span class="text-secondary text-xs texte-limite">{{ element.service.name }}</span>
                </div>
              </td>
          
              <!-- Troisième colonne -->
              <td (click)="handleClick(element, 'amount')">
                <span class="text-secondary text-xs">{{ element.service.amount }} Fcfa</span>
              </td>
          
              <!-- Quatrième colonne (statut) -->
              <td (click)="handleClick(element, 'status')">
                <span class="badge bg-dark text-light text-xs">
                  {{ element.status === 'BILLING' ? 'EN ATTENTE DE PAIEMENT' :
                     element.status === 'WAITING TRAITEMENT' ? 'EN ATTENTE DE TRAITEMENT' :
                     element.status === 'TRAITEMENT ON PROGRESS' ? 'EN COUR DE TRAITEMENT' : 'TERMINEE' }}
                </span>
              </td>
          
              <!-- Cinquième colonne (date) -->
              <td (click)="handleClick(element, 'created_at')">
                <span class="text-secondary text-xs texte-limite">{{ element.created_at | date:'dd-MM-yyyy / HH:mm:ss' }}</span>
              </td>
          
              <!-- Dernière colonne (actions) : Aucun clic -->
              <td class="text-nowrap text-center">
                <a *ngIf="element.status === 'BILLING'" class="text-secondary text-center text-xs mx-2" (click)="openPaiementDialog(element.service, element.ref)">
                  <i class="fas fa-solid fa-money-bill-wave text-warning" style="font-size: 20px;"></i> 
                </a>
                <!-- <a class="text-secondary float-right text-xs mx-2" data-toggle="tooltip" data-original-title="Edit user">
                  <i class="fas fa-eye text-primary" style="font-size: 20px;"></i>
                </a> -->
              </td>
            </tr>
          </ng-container>
          

          <ng-template #empty>
            <tr>
              <td colspan="6" class="text-center">
                <p class="text-center">{{'PUBLIC.empty_data' | translate}}</p>
              </td>
            </tr>
          </ng-template>

          <!-- Pagination -->
          <div class="m-auto align-center text-nowrap">
            <pagination-controls
              (pageChange)="pageIntCourrier = $event"
              previousLabel="Précédent"
              nextLabel="Suivant"
              responsive="true"
              [id]="'operations'"
            ></pagination-controls>
          </div>
        </tbody>
      </table>
    </div>
  </div>
  <router-outlet></router-outlet>
</body>
<ngx-ui-loader></ngx-ui-loader>
