<div class="container">
 
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title">{{SERVICE.label}}</h6>
    <button type="button"  class="btn btn-link float-right" style="top: 10px; " aria-label="Close" (click)="closeAllDialogs()">
      <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
    </button>
  </div>
  
  <div class="modal-body">
    <form [formGroup]="PaiementFormControl">
      <div class="container">
        <div class="form cf">
          <section class="payment-plan cf row d-flex justify-content-around" style="width: 100%;">
            <div class="col-sm-6 col-md-4 col-lg-3 rounded-circle" *ngFor="let paiement of PAIEMENTS">
              <input type="radio" (click)="selectTheProvider(paiement)" name="myRadio" id="{{paiement.name}}" value="{{paiement.id}}">
              <label class="monthly-label" for="{{paiement.name}}">
                <img src="{{paiement?.image == null ? '../../../../assets/images/ejustice.png' : paiement?.image}}" width="50" height="50" alt="">
              </label>
              <p class="text-center" style="font-size: 15px;">{{paiement.name}}</p>
            </div>
          </section>
        </div>
      </div>
      
      <div class="row col-10 mx-auto">
        <!-- Additional content can go here -->
      </div>

      <div class="form-outline mb-4">
        <div class="d-flex justify-content-between mx-auto col-md-12 col-sm-12">
          <img src="{{SELECTED_PAIEMENT?.image == null ? '../../../../assets/images/ejustice.png' : SELECTED_PAIEMENT?.image}}" width="80" height="80" alt="Selected Payment" />
          
          <div class="d-flex flex-column col-md-8 col-sm-9 mt-3">
            <div class="form-outline mb-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text form-control"><i class="fas fa-solid fa-mobile"></i></span>
                </div>
                <input class="form-control" [formControl]="PaiementFormControl.phone_number" type="tel" placeholder="7X XXX XX XX" />
              </div>
            </div>

            <div class="row mb-4">
              <div class="col">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text form-control"><i class="fas fa-solid fa-money-bill"></i></span>
                  </div>
                  <input class="form-control" [readOnly]="true" [formControl]="PaiementFormControl.amount" placeholder="{{SERVICE.amount}} Fcfa" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn  btn-success btn-block rounded-pill mx-auto d-block text-nowrap" *ngIf="!PAIEMENT_EN_COUR" (click)="paiement()" [disabled]="PaiementForm.invalid" style="width: 100%;" >Payer</button>
    </form>
    <ngx-ui-loader [loaderId]="'addpayment'"></ngx-ui-loader>

  </div>
  <ngx-ui-loader ></ngx-ui-loader>

</div>
