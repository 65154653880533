
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
  </head>
  <body>

    <div class="page">



     <!-- details chambres -->


       <div class="d-flex justify-content-center">
        <h5 id="heading" class="text-light" style="font-size: 12px;">Détails : {{data.name}}</h5>
        <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
          <i class="fas fa-times text-light" style="font-size: 1.5rem; "></i>
        </button>
      </div>

       <!-- tabs -->
       <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
         <input type="radio" name="pcss3t" checked  id="tab1"class="tab-content-first">
         <label for="tab1">Détails</label>

         <input type="radio" name="pcss3t"   id="tab2"class="tab-content-2">
         <label for="tab2">Dossiers associés</label>

         <input type="radio" name="pcss3t"   id="tab3"class="tab-content-3">
         <label for="tab3">Plumitifs</label>

         <input type="radio" name="pcss3t"   id="tab4"class="tab-content-4">
         <label for="tab4">Repertoires</label>



         <ul>
           <li  class="tab-content tab-content-first  typography" ><br>
            <div class="container detail_chambre">
              <div class="card-list d-flex flex-column flex-md-row justify-content-between align-items-center">
                <div class="col-12 col-md-3 text-center mb-3 mb-md-0">
                  <div class="card m-auto">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbJbIBw0gfeInyRt-E6e_9gsISNiFF1JxwiQ&usqp=CAU" alt="Admin" class="rounded-circle" style="width: 100%; height: auto;">
                    <h5 class="mt-2" style="font-size: 11px;">{{data.name}}</h5>
                  </div>
                </div>
                <div class="col-12 col-md-9">
                  <div class="sample">
                    <h3 class="sample-title code text-light text-center">Membres</h3>
                    <div class="sample-code">
                      <div class="code">
                        <span *ngIf="CAN_ADD_MEMBRE">
                          Ajouter: <i class="fas fa-solid fa-plus text-success" id="addRowBtn" style="font-size: 20px;"></i>
                        </span>
                        <table id="myTable" class="table table-striped table-responsive">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Membre</th>
                              <th scope="col">Poste</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let membre of membres">
                              <th scope="row">{{membre.index}}</th>
                              <td>{{membre.utilisateur}}</td>
                              <td>{{membre.poste}}</td>
                            </tr>
                          </tbody>
                        </table>
                        <br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

           </li>

           <li  class="tab-content tab-content-2  typography " style="z-index: 1000;" ><br>
            <div class="folderChambre m-auto">
              <div class="row">
                <div class=" col-md-4 col-sm-5 mb-4" *ngFor="let courrier of dossiersChambre | paginate: { itemsPerPage: 9, currentPage: pageDossier, id:'dossier' }">
                  <div class="d-flex flex-column m-auto" (click)="openModalDetails(courrier, 'dossier')">
                    <div class="folder__back m-auto">
                      <div class="paper"></div>
                      <div class="paper"></div>
                      <div class="paper"></div>
                      <div class="folder__front"></div>
                      <div class="folder__front right"></div>
                    </div>
                    <div class="m-auto text-center">
                      <p class="texte-limite text-center" style="font-size: 11px;">{{courrier.name}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <pagination-controls class="m-auto text-center" (pageChange)="pageDossier = $event" previousLabel="Précédent" nextLabel="Suivant"
                                   responsive="true" [id]="'dossier'"></pagination-controls>
            </div>
            

              <!-- <div style="margin: auto;">
                <pagination-controls  (pageChange)="pageDossier = $event" previousLabel="Précédent" nextLabel="Suivant"
              responsive="true" [id]="'substituts'"></pagination-controls>
              </div> -->

          </li>

          <li  class="tab-content tab-content-3"  >
            <div class="card mb-4">
              <div class="tabhead card-header pb-0 d-flex justify-content-center" style="background: $main_color;">
                <h6 class="salhead text-light">Plumitif</h6>
              </div>
              <div class="card-body px-0 pt-0 pb-2">
                <div class="search__container col-12 col-md-8 m-auto mb-3">
                  <input class="search__input form-control" type="text" placeholder="Recherche">
                </div>
                <div class="table-responsive">
                  <table class="table table-striped m-auto" style="background-color: #FFFFFF;">
                    <thead class="bg-secondary text-light" style="font-size: 12px;">
                      <tr>
                        <th class="text-uppercase text-xxs opacity-7">N° audience</th>
                        <th class="text-uppercase text-xxs opacity-7">Parties prenantes</th>
                        <th class="text-uppercase text-xxs opacity-7">Status</th>
                        <th class="text-uppercase text-xxs opacity-7">Décision</th>
                        <th class="text-uppercase text-xxs opacity-7">Date</th>
                        <th class="text-uppercase text-xxs opacity-7">Action</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: 11px; text-align: center;">
                      <tr *ngFor="let plumitif of list_plumitifs | paginate: { itemsPerPage: 5, currentPage: pagePlumitifs}" class="text-nowrap" style="font-size: 10px;">
                        <td><p class="text-secondary   ">{{plumitif.numero_de_jugement}}</p></td>
                        <td><p class="text-secondary   ">{{plumitif.nom_complet_plaignant}} - {{plumitif.prenom}} {{plumitif.nom}}</p></td>
                        <td><p class="text-primary   ">{{plumitif.status}}</p></td>
                        <td><p class="text-primary   ">{{plumitif.decision_judiciaire}}</p></td>
                        <td><p class="text-secondary   ">{{getFormattedDate(plumitif.date_de_jugement)}}</p></td>
                        <td class="align-middle text-center">
                          <div class="d-flex justify-content-center">
                            <a href="javascript:;" class="text-secondary    " (click)="openModalDetailsPlumitif(plumitif)">
                              <i class="fas fa-solid fa-eye text-primary" style="font-size: 20px;"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6" class="text-center">
                          <pagination-controls (pageChange)="pagePlumitifs = $event" previousLabel="Précédent" nextLabel="Suivant" responsive="true"></pagination-controls>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            

          </li>

          <li  class="tab-content tab-content-4 " >
            <div class="card mb-4">
              <div class="tabhead card-header pb-0 d-flex justify-content-center" style="background: $main_color;">
                  <h6 class="salhead text-light">Repertoire</h6>
              </div>
              <div class="card-body px-0 pt-0 pb-2">
                  <div class="search__container mx-auto col-10 col-md-8">
                      <input class="search__input form-control" type="text" placeholder="recherche">
                  </div>
                  <div class="table-responsive p-0">
                      <table class="table table-striped w-100" style="background-color: #FFFFFF;">
                          <thead class="bg-secondary text-light" style="font-size: 12px;">
                              <tr>
                                  <th class="text-uppercase text-xxs opacity-7">N° jugement</th>
                                  <th class="text-uppercase text-xxs opacity-7">Parties prenantes</th>
                                  <th class="text-uppercase text-xxs opacity-7">Décision</th>
                                  <th class="text-uppercase text-xxs opacity-7">Date decision</th>
                                  <th class="text-uppercase text-xxs opacity-7">Action</th>
                              </tr>
                          </thead>
                          <tbody class="text-center m-auto">
                              <tr *ngFor="let repertoire of list_repertoires | paginate: { itemsPerPage: 5, currentPage: pagerepertoires }" style="font-size: 10px;">
                                  <td>
                                      <p class="text-secondary ">{{ repertoire.jugement_code }}</p>
                                  </td>
                                  <td>
                                      <p class="text-secondary ">{{ repertoire.nom_complet_plaignant }} X {{ repertoire.prenom }} {{ repertoire.nom }}</p>
                                  </td>
                                  <td>
                                      <p class="text-primary ">{{ repertoire.decision_judiciaire }}</p>
                                  </td>
                                  <td>
                                      <p class="text-secondary ">{{ getFormattedDate(repertoire.date_de_decision) }}</p>
                                  </td>
                                  <td class="align-middle">
                                      <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalDetailsRepertoire(repertoire)">
                                          <i class="fas fa-solid fa-eye text-primary" style="font-size: 20px;"></i>
                                      </a>
                                  </td>
                              </tr>
                              <tr>
                                  <td colspan="5" class="text-center">
                                      <pagination-controls (pageChange)="pagePlumitifs = $event" previousLabel="Précédent" nextLabel="Suivant" responsive="true"></pagination-controls>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          

          </li>


         </ul>
       </div>
       </div>

  </body>
</html>
