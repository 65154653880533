import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {AlertesService} from "../../services/public/alertes.service";
import {JugementsService} from "../../services/frapper/jugements.service";
import {DatePipe} from "@angular/common";
import {EnrollementService} from "../../services/frapper/enrollement.service";
import {PenalCourrierService} from "../../services/penal.courrier/penal.courrier.service";
import {CustomService} from "../../services/frapper/custom.service";
import {NgForm} from "@angular/forms";
import {SuccessDialogComponent} from "../../widgets/successDialog/success-dialog.component";
import {error} from "@angular/compiler-cli/src/transformers/util";
//import * as ffst from "fs";
var fs = require('fs');

@Component({
  selector: 'app-add-piece',
  templateUrl: './add-piece.component.html',
  styleUrls: ['./add-piece.component.scss'],
})
export class AddPieceComponent implements OnInit {

  modalRef!: BsModalRef;

  doctype_to_add
  data
  field_to_update;
  num_courrier = null;

  imageFile: File | null = null;
  constructor(private modalService: BsModalService,public options: ModalOptions,
              private ngxService: NgxUiLoaderService,
              private alerte:AlertesService,
              public bsModalRef: BsModalRef,
              private custom:CustomService
              ) {
    this.doctype_to_add = this.options.initialState.type_doctype;
    this.data = this.options.initialState.doctype;
    this.num_courrier = this.options.initialState.num_courrier
    this.field_to_update = this.options.initialState.field_to_update;
   


  }
  ngOnInit(): void {
    $(document).ready(function() {
      $(".file-input").on("change", function() {
          // Récupérer le nom du fichier sélectionné
          const fileName = $(this).val().split("\\").pop();
          // Trouver l'élément span à l'intérieur du label parent et mettre à jour son texte
          $(this).parent().find('.file-label').text(fileName);
      });
  });
  }
  closeModal(){
    this.bsModalRef.hide()
  }

  onFileSelected(event: any) {
    this.imageFile = event.target.files[0] as File;
    console.log('this.imageFile ==> ',this.imageFile)
  }
  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message); 
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    // setTimeout(() => {
    //   if (this.modalService) {
    //     this.modalService.hide();
    //   }
    // }, 1000);
    //this.penalService.hiddeModal(this.parentModal);
    this.alerte.successNotification('Piéce', message)
    // this.openModalSuccessDialog('Piéce Ajoutée')
    this.closeWithMessage("")
  }

  createPiece(data: NgForm){
    if (!this.imageFile) {
      this.alerte.warningNotification('Pieces','No Data')
      console.error('No image selected.');
      return;
    }
    this.ngxService.start();

    const formData1= new FormData();
    formData1.append('is_private', '0');
    formData1.append('file',this.imageFile);




    this.custom.createFiles(formData1).then(res =>{
      try {
        const file_uploaded = res.message
        let value_to_upload:any[]
        console.log(this.data)
         value_to_upload = this.data[this.field_to_update]
        let add ={
          "nom_du_fichier": data.value.file_name,
          "fichier": file_uploaded.file_url,
          "parent": this.data.name,
          "parenttype": this.doctype_to_add,
          "doctype": "Fichiers joins a un dossier"
        }
        console.log(value_to_upload)
        value_to_upload.push(add)
        console.log(value_to_upload)
        let toUpDate:any ={}
        toUpDate[this.field_to_update]=value_to_upload
  
            this.custom.updateTheDoctype(this.doctype_to_add,this.data.name, toUpDate).then(res =>{
              //console.log('Succe ==> ',res)
              this.openModalSuccessDialog('Piéce Ajoutée')
              this.ngxService.stop();
            },error=>{
              console.log('IN ADD DOCTYPE ERR ==> ',error)
              this.ngxService.stop();
              this.alerte.errorNotification('Pieces','Une erreur s\'est produite!')
            })
      } catch (error) {
        console.log('IN ADD DOCTYPE ERRjj ==> ',error)
        this.alerte.errorNotification('Pieces','Une erreur s\'est produite!')
        this.ngxService.stop();
      }
      //console.log('IN FILE ==> ',res)
     

    },err =>{
      console.log('IN UPLOAD FILE ERR ==> ',err)
      this.ngxService.stop();
      this.alerte.errorNotification('Pieces','Une erreur s\'est produite!')
    })
    return

  }
}
