import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { DetailViewComponent } from '../../widgets/detailsview/detailView.component';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import { AddRequisitoireComponent } from '../add-requisitoire/add-requisitoire.component';
import { AddRapportappelComponent } from '../add-rapportappel/add-rapportappel.component';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddEnrollementComponent } from '../add-enrollement/add-enrollement.component';
import {DossierService} from "../../services/frapper/dossier.service";
import {AlertesService} from "../../services/public/alertes.service";
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';
import {da} from "date-fns/locale";
import {MyCONST} from "../../CONST/myConst";
import { VisualizeRequisitoireComponent } from '../visualize-requisitoire/visualize-requisitoire.component';
import {NgxUiLoaderService} from "ngx-ui-loader";
import {TransmissioncourrierComponent} from "../transmissioncourrier/transmissioncourrier.component";
import { AddAssociationPrevenuComponent } from '../add-association-prevenu/add-association-prevenu.component';
import { DetailsPrevenuComponent } from '../details-prevenu/details-prevenu.component';
import {CustomService} from "../../services/frapper/custom.service";
import {AssignedService} from "../../services/frapper/assigned.service";
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import { TypeDemandesDocumentComponent } from '../../widgets/type-demandes-document/type-demandes-document.component';
import { DocumentService } from '../../services/frapper/document.service';
import { ViewDetailsDemandesComponent } from '../view-details-demandes/view-details-demandes.component';
import { AddInfractionsComponent } from '../add-infractions/add-infractions.component';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { ResumeDossierComponent } from '../../widgets/resume-dossier/resume-dossier.component';
import { DemandeAccesComponent } from '../demande-acces/demande-acces.component';
import { ActionsDossierComponent } from '../actions-dossier/actions-dossier.component';
import { ListNotesComponent } from '../list-notes/list-notes.component';


@Component({
  selector: 'ngx-form-courier',
  templateUrl: './view-details-dossier.component.html',
  styleUrls: ['./view-details-dossier.component.scss']
})
export class ViewDetailsDossierComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  type: any;
  profil: string;
  courriers: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  listRequisitions: number = 1;
  listPieces: number = 1;
  pagedocs: number = 1;
  pageinfraction: number = 1;
  isButtonDisabled: boolean = true;
  @Input() parentModal: BsModalRef;
  modalRef!: BsModalRef;
  ServicePermis = [];
  is_multiple_prevenu: number ;
  textColor = 'Yellow'
  IS_ENROLABLE = false
  IS_CLASSABLE = false
  IS_TRANSFERABLE= false;
  gettedInfractions: any[] =[]
  updateinfractions: boolean =  false;
  showResume: boolean =  false;
  CAN_DO_REQUISITOIR = false
  CAN_DO_REQUISITOIR_SUPLETIF = false
  CAN_DO_ORDONANCE = false
  CAN_DO_AVIS = false
  CAN_ADD_PIECE = false;

  CAN_SEE_REMARQUE_PROCUREUR:boolean=false
  CAN_NOT_CHANGE_REMARQUE_PROCUREUR:boolean=true

  CAN_SAVE:boolean=false
  CAN_ADD_DOCUMENT:boolean=true
  CAN_ADD_INFRACT:boolean=true

  nbr_requisitoires =0
  nbr_ordonances =0

  InternalPieces:any =[]
  prevenusList: any[] = [];
  listedocuments = []

  my_tribunal:any = null
  infractions:any[] = [
    {
      id: 1,
      name: 'Infraction 1',
      type: 'Délit'
    },
    {
      id: 2,
      name: 'Infraction 2',
      type: 'Délit'
    },
    {
      id: 3,
      name: 'Infraction 3',
      type: 'Crime'
    },
    {
      id: 4,
      name: 'Infraction 4',
      type: 'Crime'
    }
  ]

  constructor(private _formBuilder: FormBuilder,
              private options: ModalOptions,
              private dossierService:DossierService,
              private customService: CustomService,
              private courrier: UsercourrierService,
              private assignService: AssignedService,
              private alerte:AlertesService,
              private enrole: EnrollementService,
              public bsModalRef: BsModalRef,
              private ngxService: NgxUiLoaderService,
              private documentService: DocumentService,
              private penalCourrierService: PenalCourrierService,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.type = this.options.initialState.type;

    this.infractions = [];


    try{
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }

  }
  closeModal(){
    this.bsModalRef.hide()
  }

  changeTextColor(){
    if(this.data.workflow_state =='Procureur'){
      this.textColor = 'grey'
    }
    if(this.data.workflow_state =='Substitut du procureur'){
      this.textColor = '#211C6A'
    }
    if(this.data.workflow_state =='Juge d\'instruction'){
      this.textColor = 'Orange'
    }

    if(this.data.workflow_state =='Non lieu' || this.data.workflow_state== 'Sans suite'){
      this.textColor = 'Red'
    }
    if(this.data.workflow_state =='Enrolement'){
      this.textColor = '#068e51'
    }

  }

  updateInfr(){
    this.updateinfractions = true;
  }

  openModalResumedossier() {
    const config: ModalOptions = {
      initialState: {
        doctype: this.data.doctype,
        numero: this.data.name,
        ismodal:true,
        req_ord: this.list_requisitoire_ordonances,
        docs: this.listedocuments,
        pieces: this.data.pieces_jointe_au_dossier

      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(ResumeDossierComponent, Object.assign({}, config, { class: 'modal-xl ' }));

  }

  openModalActionsdossier() {
    const config: ModalOptions = {
      initialState: {
        doctype: this.data.doctype,
        numero: this.data.name,
        value:this.data,
        ismodal:true,
        req_ord: this.list_requisitoire_ordonances,
        docs: this.listedocuments,
        pieces: this.data.pieces_jointe_au_dossier

      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(ActionsDossierComponent, Object.assign({}, config, { class: 'modal-xl ' }));

  }

  // recuperer que la nature,date,texte de la table_infractions
   extraireDonnees(arr) {
    return arr.map(obj => ({
        date: obj.date,
        nature: obj.nature,
        textes: obj.textes || ""
    }));
}

  async updateInfractionOnDossier(){
    console.log('in methode');
    this.ngxService.start()
    const old_infractions = this.extraireDonnees(this.infractions);
    let new_infractions = [];
    // Use an arrow function to maintain the correct 'this' context
    $('#myTable tbody tr').each((index, row) => {
        const nature = $(row).find('input[name="nature"]').val();
        const date = $(row).find('input[name="date"]').val();
        const textes = $(row).find('input[name="textes"]').val();

        new_infractions.push({
            nature: nature,
            date: date,
            textes: textes
        });
    });

    // filtrer les new infractions non nulls ou undefined
    new_infractions =  new_infractions
    .filter(obj => obj.nature !== undefined && obj.nature !== null)
    .map(obj => ({
        date: obj.date,
        nature: obj.nature,
        textes: obj.textes || ""
    }));
    let updated_infractions_table = old_infractions.concat(new_infractions);
    let table_infractions = {
      "table_infractions": updated_infractions_table

    }

    await this.dossierService.updateDossier(this.data.name,table_infractions).then(res=>{
      this.ngxService.start()
      this.alerte.successNotification('Dossier','Mise à jour effectuee!')
     console.log('dossier updated: ', res)
      this.ngxService.stop()
    },err=>{
      this.alerte.errorNotification('Dossier','Erreur lors de la mise a jour du dossier')
      this.ngxService.stop()
    })

    console.log('Les infractions:', updated_infractions_table);
  }

  openModalAddInfractions(){
    const config: ModalOptions = {
      initialState: {
        message: null,
        type:'infraction'
      }
    };

    console.log('openingSuccess');
    return new Promise<string>((resolve, reject) => {
      this.modalRef = this.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-lg'}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe((value: string) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
      });
    }).then(async (value: string) => {
      console.log('in addinfraction', value);

      if ( value == "success") {
        this.enrole.getAllInfractions().then(res=>{
          this.infractions = res.data

        })

      }

    // self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-sm'}));
  })
  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }

  list_requisitoire_ordonances =[]
  changeActions(){
    //Allowed to classe
    this.IS_ENROLABLE = true
    this.IS_TRANSFERABLE = true
    if(this.profil == 'PROCUREUR' || this.profil =='SUB PROCUREUR'){
      this.IS_CLASSABLE = true
      this.CAN_DO_REQUISITOIR = true
      console.log('this.data.workflow_state ==> ',this.data.workflow_state)
      if (this.data.workflow_state =='Juge d\'instruction' ){
        this.CAN_DO_REQUISITOIR_SUPLETIF = true
      }

      this.CAN_SEE_REMARQUE_PROCUREUR = true
      if(this.profil == 'PROCUREUR'){
        this.CAN_NOT_CHANGE_REMARQUE_PROCUREUR=false
      }
    }

    if(this.profil == 'JUGE D\'INSTRUCTION'){
      this.IS_ENROLABLE = false
      if(this.data.workflow_state =='Juge d\'instruction'){
        this.CAN_DO_ORDONANCE = true
      }
      if(this.data.creation_unite=='PARQUET'){
        this.CAN_DO_AVIS = false
      }
    }
    if(this.profil == 'JUGE' || this.profil == 'PRESIDENT DU TRIBUNAL'){
      this.CAN_DO_ORDONANCE = true
    }
    if(this.profil == 'PRESIDENT DU TRIBUNAL'){
      if(this.data.creation_unite=='PARQUET'){
        this.CAN_DO_AVIS = true
      }
    }

    if(this.profil == 'PROCUREUR' || this.profil =='SUB PROCUREUR'){
      if(this.data.creation_unite!='PARQUET'){
        this.CAN_DO_AVIS = true
      }
    }

    if(this.profil != 'PROCUREUR' && this.profil !='SUB PROCUREUR'
      && this.profil !='GREFFIER'  && this.profil !='GREFFIER EN CHEF'
      && this.profil !='AGENT ENROLEMENT PENAL'  && this.profil !='AGENT ENROLEMENT'
    ){
      this.IS_ENROLABLE =false
    }

   if(this.data.workflow_state =='Sauvegarder' || this.data.workflow_state =='Enrolement' ||this.data.workflow_state== 'Sans suite'
      ||this.data.workflow_state =='Non lieu'|| this.data.workflow_state =='Juge d\'instruction' ){
     this.IS_CLASSABLE = false
     this.IS_ENROLABLE = false
     this.CAN_DO_REQUISITOIR = false
     this.IS_TRANSFERABLE = false
     this.CAN_ADD_DOCUMENT = false
      this.CAN_ADD_INFRACT = false
   }

    if (this.CAN_DO_ORDONANCE==true ||this.CAN_DO_REQUISITOIR==true||this.IS_ENROLABLE==true )
    {
      this.CAN_ADD_PIECE = true
      this.CAN_SAVE = true
      this.CAN_ADD_INFRACT = true;
      this.CAN_ADD_PIECE = true;
      this.CAN_ADD_DOCUMENT = true;

    }
    if(this.profil == 'JUGE' || this.profil == 'GREFFIER'){
      this.CAN_ADD_PIECE = true
    }

    if(this.profil == 'AGENT ENROLEMENT' || this.profil == 'AGENT ENROLEMENT PENAL'){
      this.CAN_SAVE = false
      this.IS_TRANSFERABLE = false
      this.CAN_ADD_DOCUMENT = false;
      this.CAN_ADD_INFRACT = false;
      this.CAN_ADD_PIECE = false;
      let me= JSON.parse(localStorage.getItem('me'))
      try {
        me = JSON.parse(me)
      }catch (e) {

      }
      if(this.data.owner==me.name && this.data.workflow_state !='Enrolement' ){
        this.CAN_ADD_PIECE = true;
      }
    }
  }


  updateValueforChange(value,key){
    this.data[key]=value
  }
  updateValue(){
    this.ngxService.start()
    this.data['is_multiple_prevenu'] = this.is_multiple_prevenu
    this.data['association_de_prevenu'] = this.prevenusList
    console.log('updatedata: ',this.data)
    this.dossierService.updateDossier(this.data.name,this.data).then(res=>{
      this.data=res.data
      this.changeActions()
      this.alerte.successNotification('Dossier','Mise a jour effectuee!')
      //this.penalCourrierService.hiddeModal(this.parentModal);
      this.ngxService.stop()
    },err=>{
      this.alerte.errorNotification('Dossier','Erreur lors de la mise a jour du dossier')
      this.ngxService.stop()
    })
  }

  openModalAddPrevenu() {
    const config: ModalOptions = {
      initialState: {
        url:URL,
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(AddAssociationPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {
      console.log('Nouveau prévenu ajouté :', newPrevenu);
      this.prevenusList.push(newPrevenu);

    });
  }

  openModalDetailsPrevenu(prevenu:any) {
    const config: ModalOptions = {
      initialState: {
        url:URL,
        data: prevenu,
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(DetailsPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {

      let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
      if (existingPrevenuIndex != null) {
        existingPrevenuIndex = newPrevenu;
      }

      console.log('findeddd :', existingPrevenuIndex);
      // this.prevenusList.push(newPrevenu);

    });
  }
  deletePrevenu(prevenu:any){
    let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
    this.prevenusList.splice(existingPrevenuIndex, 1)

  }

  openModalTransmission(data: any,type: string) {
    const config: ModalOptions = {
      ignoreBackdropClick: false,
      backdrop: true,
      initialState: {

        data: data,
        type: type,
        parentModal : null
      }
    }

    // Ouvrir le modal   ViewDetailsCourrierComponent

    //  this.penalCourrierService.hiddeModal(this.parentModal);
    const modalRef = this.modalService.show(TransmissioncourrierComponent, Object.assign({}, config, { class:   'modal-xl '  }));

    // Mettre à jour l'option initialState avec le modalRef
    modalRef.content.parentModal = modalRef;

  }
  selectTypePrevenu(event: any){

    const isChecked = event.target.checked;
    if (isChecked) {
      this.is_multiple_prevenu = 1;
    }else{
      this.is_multiple_prevenu = 0;
    }
    console.log('is_multiple_prevenu==>',this.is_multiple_prevenu);


}

  activePane = 0;

  onTabChange($event: number) {
    this.activePane = $event;
    console.log('onTabChange', $event);
  }

  openModalVisualPiece(data: any) {
    const URL=MyCONST.API_URL+data.fichier
    const config: ModalOptions = {
      initialState: {
        data: URL,
        url:URL,
        parentModal : null
      }
    }

     // Ouvrir le modal
     const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

     // Mettre à jour l'option initialState avec le modalRef
  }

  openModalVisualrequistion(data: any) {
    console.log('visualise req', data);

    const URL=MyCONST.DOMAIN+data.fichier
    const config: ModalOptions = {
      initialState: {
        data: data,
        url:URL,
        parentModal : null
      }
    }

     // Ouvrir le modal
     const modalRef = this.modalService.show(VisualizeRequisitoireComponent, Object.assign({}, config, { class: 'modal-lg ' }));

     // Mettre à jour l'option initialState avec le modalRef
  }

  async getNombredeRequisiToireAndOrdonance(){
/*
    let filter_REQUISITOIRES = '[["numero_dossier" , "=","'+this.data.name+'"],["tag" , "like","REQUISITOIRE%"] ]'
    let filter_ORDONANCE = '[["numero_dossier" , "=","'+this.data.name+'"],["tag" , "like","ORDONANCE%"] ]'
*/
    let filter = '[["numero_dossier" , "=","'+this.data.name+'"]]'
   await this.dossierService.getAllMyRequisitoireOrOrdonance(filter).then(res=>{
      this.list_requisitoire_ordonances = res.data
      console.log('list_requisitoire_ordonances ==> ',this.list_requisitoire_ordonances)
      this.nbr_ordonances =  res.data.filter(reqOrd => (reqOrd.tag.substring(0,10) == 'ORDONNANCE')).length;
      this.nbr_requisitoires =  res.data.filter(reqOrd => (reqOrd.tag.substring(0,10) == 'REQUISITOI')).length;

    })
  }


  openModalDetailView(data: any,type: string) {
    const config: ModalOptions = {
      initialState: {
        data: data,
        type: type,
        parentModal : null
      }
    }

     // Ouvrir le modal
     const modalRef = this.modalService.show(DetailViewComponent, Object.assign({}, config, { class: type == 'courrier' || type == 'substituts'  ? 'modal-md ': 'modal-lg '  }));

     // Mettre à jour l'option initialState avec le modalRef
     modalRef.content.parentModal = modalRef;
  }

  // openModalEnrollement(type_courrier: string) {
  //   const config: ModalOptions = {
  //     initialState: {
  //       typeCourrier: type_courrier,
  //       parentModal: null // Initialiser parentModal à null
  //     }
  //   }

  //   // Ouvrir le modal
  //   const modalRef = this.modalService.show(AddEnrollementComponent, Object.assign({}, config, { class: 'modal-xl' }));

  //   // Mettre à jour l'option initialState avec le modalRef
  //   modalRef.content.parentModal = modalRef;
  // }

  openModalEnrollement(type: string,step?:string,folder?:any) {
    if (folder) {
      this.penalCourrierService.setSelectedDossier(folder)
    }
    const config: ModalOptions = {
      initialState: {
        type: type,
        step: step ?? null,
        data: folder,
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal

    // Mettre à jour l'option initialState avec le modalRef
    return new Promise<string>((resolve, reject) => {
      this.modalRef = this.modalService.show(AddEnrollementComponent, Object.assign({}, config, {class:'modal-xl modal-enrole '}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe((value: string) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
      });
    }).then(async (value: string) => {
      if ( value == "success") {
         this.closeWithMessage("success")
      }


    });
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        type_doctype:this.dossierService.Doctype_dossier,
        doctype:this.data,
        field_to_update:'pieces_jointe_au_dossier'
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
   const modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg '}));
  }

  async SendRequestToPresidentForRequisitoir(){
    if(this.data.date_demande_instruction =='0'){
      this.ngxService.start()
      let PROFIL ='PRESIDENT DU TRIBUNAL'
      let presidents = (await this.customService.getUserByRole(PROFIL)).message
      let the_president = (presidents[0]).parent
      if(this.my_tribunal !=null){
        let tribunal_assigns = ((await this.assignService.getAssignedUser('Tribunal',this.my_tribunal.code)).data[0])['_assign'] || []
        tribunal_assigns = JSON.parse(tribunal_assigns)
        console.log('tribunal_assign ====> ',tribunal_assigns)
        for (let preisdent of presidents){
          for (let assign of tribunal_assigns){
            console.log('Assigm ===> ', assign)
            if(assign== preisdent.parent){
              the_president = assign
            }
          }
        }
      }
      console.log('PResident ====> ',the_president)
      let body={
        "date_demande_instruction": new Date()
      }
      this.assignService.doAssignation('Dossier',this.data.name,[the_president]).then(res => {

        let c_interne=  {
          "numéros_du_dossier": this.data.name,
          "profil": PROFIL,
          "destinataire": the_president,
          "tag": 'Avis de choix du cabinet d\'instruction',
          "fichier": null,
          "message": 'Dans le cadre de l\'instruction d\'un dossier important, je sollicite votre avis sur la désignation d\'un cabinet d\'instruction approprié. Cette décision est cruciale pour assurer une gestion efficace et impartiale de l\'affaire en cours, en tenant compte des spécificités et de la sensibilité du dossier.'
        }
        if(this.my_tribunal !=null){
          c_interne['tribunal']= this.my_tribunal.name
        }
        this.courrier.nouveauCourrierInterne(c_interne).then(res=>{
          this.assignService.doAssignation(this.courrier.Docatype_courrier_interne,res.data.name,[the_president])
        })

        this.dossierService.updateDossier(this.data.name,body).then(ress => {
            this.alerte.successNotification('Demande','Demande envoyée avec succés')
            this.ngxService.stop()
          },
          error =>{
            console.log('Error On update==> ',error)
            this.ngxService.stop()
          })
      },err =>{
        this.alerte.errorNotification('Dossier','Impossible de partager ce dossier !')
        this.ngxService.stop()
      })

    }
    else {
      this.alerte.warningNotification('Demande','Une demande a déjà été envoyée! ')
    }



  }

  openModalTypeDemande(type:string) {

    const config: ModalOptions = {
      initialState: {
        // data: this.data.name,
        type: type,
        type_doctype: 'Dossier',
        numero_du_jugement:this.data.name,
        allowed_flag:this.ServicePermis
      }
     }
    return new Promise<string>((resolve, reject) => {
      this.modalRef = this.modalService.show(TypeDemandesDocumentComponent, Object.assign({}, config, {class:'modal-lg '}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe(async (value: string) => {
        console.log("in openModalDetailsDossier", value);
        if ( value == "success") {
          console.log('jui is closeddd');
          this.getAlldocuments()

          //  this.updateValue();
        }

      });
    }).then(async (value: string) => {


    });

  }


  openModalAddRequisitoire(type='Requisitoire',is_avis=0,is_supletif=0) {
    const config: ModalOptions = {
      initialState: {
         type: type,
         dossier: this.data,
         is_avis:is_avis,
         is_supletif:is_supletif
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
  //  const modalRef = this.modalService.show(AddRequisitoireComponent, Object.assign({}, config, {class:'modal-xl'}));
  return new Promise<string>((resolve, reject) => {
    console.log("in viewdetailsdossier", 1);
    this.modalRef = this.modalService.show(AddRequisitoireComponent, Object.assign({}, config, {class:'modal-xl modal-req '}));
    this.modalRef.content.parentModal = this.modalRef;
    this.modalRef.onHidden.subscribe((value: string) => {
      console.log("in viewdetailsdossier", value);
      if ( value == "success") {
        console.log("refresssshh=> ",value );

         this.closeWithMessage("success")
      }
      resolve(value); // Résoudre la promesse avec la valeur retournée
    });
  }).then(async (value: string) => {
    // console.log("refresssshh in viewdossier => ",value );
    // if ( value == "success") {
    //   console.log("refresssshh=> ",value );

    //    this.closeWithMessage("success")
    // }
    if ( value == "success") {
      console.log("refresssshh=> ",value );

       this.closeWithMessage("success")
    }


  });

  }

  openModalAddRapport() {
    const config: ModalOptions = {
      initialState: {
        type: 'Rapport d\'appel',
        dossier: this.data
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
   const modalRef = this.modalService.show(AddRapportappelComponent, Object.assign({}, config, {class:'modal-xl '}));
  }

  ClasserSansSuite(step='Non lieu'){
    this.dossierService.updateSatate(this.data.name,step).then(ress => {
        this.openModalSuccessDialog('Dossier classé avec succes')
      },
      error =>{
        this.alerte.errorNotification('Mise a jour du dossier','Impossible de classer le dossier')
        console.log('Error On update==> ',error)
        //this.ngxService.stop();
      })
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.modalService.hide()
    // this.penalCourrierService.hiddeModal(this.parentModal);
    setTimeout(() => {
      if (this.modalService) {
        this.modalService.hide();
      }
    }, 1000);
    const modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }
  getDataDossier(){
    this.ngxService.start()
    console.log('getdata', this.data);


    this.dossierService.getOneDossier(this.data.name).then((res:any) => {
      try {
        this.data = res.data
        this.is_multiple_prevenu = this.data.is_multiple_prevenu;
        this.prevenusList = this.data['association_de_prevenu']
        console.log('prevenulist=>',this.prevenusList)
        console.log('prevenulist=>',this.data)
        this.InternalPieces = this.data.pieces_jointe_au_dossier
        // this.listedocuments = this.data.table_tqhi
        this.infractions = this.data.table_infractions
        //console.log(this.InternalPieces)
        this.changeTextColor()
        this.changeActions()
        this.ngxService.stop()
      } catch (error) {
        this.ngxService.stop()
        // this.alerte.errorNotification('Dossier ','dossier introuvable')
        this.alerte.alertDemandeAccé(this.data.name)
        this.bsModalRef.hide()
        // this.openModalDemandeAcce(this.data.name)
        console.log('ERROR ON getting data dossierdd ',error)
      }

      //console.log('DATA ====> ', this.data)
      //console.log('RES ====> ', res)
    },err=>{
      this.ngxService.stop();
      console.log('ERROR ON getting data dossier ',err)
      this.bsModalRef.hide()
      this.alerte.errorNotification('Dossier ','Une Erreur est survenue! Verifier que vous avez les droits sur ce dossier')
    })
  }

  async getAlldocuments(){
    this.ngxService.start()
    //let filter ='ServicePermis'
    let filter = '[["flag" , "in","'+this.ServicePermis+'"],["numero_du_jugement" , "=","' + this.data.name + '"]]'

    this.documentService.getAllDocuments(filter).then(res =>{
      //console.log("get result ==>", )
      this.listedocuments = []
      let results = res.data
      for(let res of results){
        res.content = JSON.parse(res.content)
      }
      this.listedocuments = res.data
      console.log('listedocuments ===> ',  this.listedocuments)
      this.ngxService.stop()
    },err =>{
      this.ngxService.stop()
    })
    /*  try {

        this.listedemandes = this.listedemandes
        this.ngxService.stop()
      } catch (error) {
        this.ngxService.stop()
      }*/
  }

  openModalDetailDocument(data: any,type_request: string) {
    const config: ModalOptions = {
      initialState: {
        data: data,
        type: data.type_demande,
        type_request : type_request,
        show_actions: false
      }
    }


    // Ouvrir le modal
    const modalRef = this.modalService.show(ViewDetailsDemandesComponent, Object.assign({}, config, { class: 'modal-xl '}));

  }

  openModalListNotes(dossier: any) {

      const config: ModalOptions = {
        initialState: {
          // message: message
          doctype: this.dossierService.Doctype_dossier,
          num_audience: dossier.name,
          // parentModal: null
        }
      };
      // this.penalService.hiddeModal(this.parentModal);
      // this.modalRef = this.modalService.show(WhiteboardCanvasComponent, Object.assign({}, config, {class:'modal-xl '}));
      this.modalRef = this.modalService.show(ListNotesComponent, Object.assign({}, config, {class:'modal-xl  modal-list-notes'}));

      // this.modalRef.content.parentModal = this.modalRef;
    }

  openModalDemandeAcce(num_dossier: any) {
    const config: ModalOptions = {
      initialState: {
        data: num_dossier,
        type: this.dossierService.Doctype_dossier,

      }
    }


    // Ouvrir le modal
    const modalRef = this.modalService.show(DemandeAccesComponent, Object.assign({}, config, { class: 'modal-sm bg-secondary '}));

  }


  async ngOnInit() {
    this.ngxService.start()
    this.infractions = [];
    this.listedocuments = []
    this.profil = localStorage.getItem('profil');

    if(this.profil == 'PROCUREUR'){
      this.ServicePermis = ['MANDAT DE DEPOT'];
    }
    if(this.profil == 'JUGE D\'INSTRUCTION'){
      this.ServicePermis = ['PERMIS DE VISITE'];
    }


   await this.getNombredeRequisiToireAndOrdonance();
    this.getDataDossier()
    this.getAlldocuments()
    this.enrole.getAllInfractions().then(res=>{
      this.gettedInfractions = res.data
      console.log('infractions ====> ', this.gettedInfractions);


    })

    var self = this;
    $(document).ready(function(){
      // table infractions
     $('#addRowBtn_inf').on('click', function() {
      const newRow = '<tr>' +
        '<td><input type="text" class="form-control" name="nature" />' +
        '<div class="list_item dropdown-menu"><ul style=" cursor: pointer;max-height:200px;overflow: auto;z-index:10;"></ul></div>' +
        '<p class="no-match-suggestion" style="display:none; color: blue; cursor: pointer;">Ajouter "<span class="new-item"></span>" à la liste d\'infraction</p></td>' +
        '<td><input type="date" class="form-control" name="date" /></td>' +
        '<td><input type="text" class="form-control" name="textes" /></td>' +
        '<td>' +
        '</tr>';

      // Ajoutez la nouvelle ligne au tableau
      $('#myTable tbody').append(newRow);

      // Cachez les éléments .list_item après l'ajout de la ligne
      $('#myTable tbody tr:last .list_item').hide();

      // Chargez la liste des éléments depuis l'API
      loadListItems($('#myTable tbody tr:last'));

      // Focus sur le champ "nature" pour afficher le dropdown
      $('#myTable tbody tr:last input[name="nature"]').on('focus', function() {
        $(this).closest('tr').find('.list_item').show();
      });

      // Optionnel: Ajouter un gestionnaire de blur pour cacher la liste quand on quitte le champ
      $('#myTable tbody tr:last input[name="nature"]').on('blur', function() {
        // Ajouter un délai pour éviter que la liste se cache immédiatement après la sélection d'un élément
        setTimeout(() => {
          $(this).closest('tr').find('.list_item').hide();
        }, 200);
      });

      // Filtrage de la liste pendant la saisie
      $('#myTable tbody tr:last input[name="nature"]').on('input', function() {
        const searchTerm = $(this).val().toLowerCase(); // Récupérer la valeur en minuscules
        const listItems = $(this).closest('tr').find('.list_item ul li'); // Sélectionner tous les éléments de la liste
        const noMatchSuggestion = $(this).closest('tr').find('.no-match-suggestion');
        const newItemSpan = $(this).closest('tr').find('.new-item');

        let matchFound = false;

        listItems.each(function() {
          const listItemText = $(this).text().toLowerCase();
          if (listItemText.indexOf(searchTerm) === -1) {
            $(this).hide(); // Si le texte ne correspond pas, masquer l'élément
          } else {
            $(this).show(); // Sinon, afficher l'élément
            matchFound = true;
          }
        });

        // Si aucune correspondance n'est trouvée, afficher l'option pour ajouter un élément
        if (!matchFound && searchTerm.trim() !== '') {
          noMatchSuggestion.show();
          newItemSpan.text(searchTerm); // Afficher la saisie de l'utilisateur comme élément suggéré
        } else {
          noMatchSuggestion.hide(); // Cacher l'option d'ajout si une correspondance est trouvée
        }
      });

      // Ajouter un gestionnaire d'événements sur les éléments de la liste pour permettre la sélection
      $('#myTable tbody').on('click', '.list_item ul li', function() {
        const selectedText = $(this).text(); // Récupérer le texte de l'élément sélectionné
        $(this).closest('tr').find('input[name="nature"]').val(selectedText); // Mettre à jour la valeur du champ "nature"
        $(this).closest('tr').find('.list_item').hide();
      });

      // Ajouter le nouvel élément à la liste
      $('#myTable tbody tr:last .no-match-suggestion').on('click', function() {
        const searchTerm = $(this).closest('tr').find('input[name="nature"]').val().trim();
        addItemToApi(searchTerm);
      });
    });


    // Fonction pour charger les éléments de la liste depuis l'API
    function loadListItems(row) {
      console.log('the infrac', self.gettedInfractions);

      if (self.gettedInfractions && Array.isArray(self.gettedInfractions)) {
        self.gettedInfractions.forEach((item) => {
          // Ajoutez chaque item à la liste dans la ligne spécifiée
          row.find('.list_item ul').append('<li>' + item.name + '</li>');
        });
      } else {
        console.error('Données invalides reçues de l\'API');
      }
    }

    function addItemToApi(itemName) {


      const config: ModalOptions = {
        initialState: {
          label: itemName,
          type: 'infraction'
        }
      };

      console.log('openingSuccess');
      return new Promise<string>((resolve, reject) => {
        self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-lg'}));
        self.modalRef.content.parentModal = self.modalRef;
        self.modalRef.onHidden.subscribe((value: string) => {
          resolve(value); // Résoudre la promesse avec la valeur retournée
        });
      }).then(async (value: string) => {
        console.log('in addinfraction', value);

        if ( value == "success") {
          self.enrole.getAllInfractions().then(res=>{
            self.gettedInfractions = res.data

          })

        }

      // self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-sm'}));
    })

     // self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-sm'}));
    }

    })


  }
}
