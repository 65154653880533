import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  SOIT_TRANSMIS_DOCTYPE='soit transmis'
  DESIGNATION_DOCTYPE='st_designation'
  ACTIONS_DOCTYPE='st actions'

  TRIBUNAL_DOCTYPE='Tribunal'
  my_tribunal:any = null
  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService) {}



  setTribunal(){
    try{
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }
  }

  async createSoitTransmisObject(data){
    try {
      const result = await this.frapper.CreateDoctype(this.SOIT_TRANSMIS_DOCTYPE,data).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getSoitTransmisObject(filter=null){
    try {
      const result = await this.frapper.GetDoctypes(this.SOIT_TRANSMIS_DOCTYPE,'["*"]',filter).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllMyTribunal(filter=null){
    try {
      const result = await this.frapper.GetDoctypes(this.TRIBUNAL_DOCTYPE,'["*"]',filter).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getTribunal(tribunalName,field='["*"]') {
    try {
      let my_tribunal = await this.frapper.GetDoctype(this.TRIBUNAL_DOCTYPE,tribunalName,field,null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_tribunal
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getDEtailTribunalNotAllowed(tribunal_name) {
    try {
      let body = {
        "tribunal_name": tribunal_name
      }
      let doc_assigned_user = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_tribunal_detail',body).toPromise()
      return doc_assigned_user
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getDesignations(filter=null){
    try {
      let designations = await this.frapper.GetDoctypes(this.DESIGNATION_DOCTYPE,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return designations
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getDesignationsDetails(name){
    try {
      let designation = await this.frapper.GetDoctype(this.DESIGNATION_DOCTYPE,name,'["*"]').toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return designation
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getActions(filter=null){
    try {
      let actions = await this.frapper.GetDoctypes(this.ACTIONS_DOCTYPE,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return actions
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getActionsDetails(name){
    try {
      let action = await this.frapper.GetDoctype(this.ACTIONS_DOCTYPE,name,'["*"]').toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return action
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getUserByRole(role,tribunal='vide') {
    try {
      this.setTribunal()
      let body = {
        "role_name": role,
        "tribunal": this.my_tribunal==null?null:this.my_tribunal.code
      }
      let doc_assigned_user = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_all_user_with_Role',body).toPromise()
      return doc_assigned_user
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getIdentificationType(filter=null){
    try {
      let id_type = await this.frapper.GetDoctypes('type identifications','["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return id_type
    } catch (error) {
      this.authservice.handleError(error)

    }

  }
  async createFiles(data){
    try {
      const  result = await this.frapper.UploadFile(data).toPromise()
      console.log(result)
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async updateTheDoctype(doctype_name,doc_name,data_to_upload){
    try {
      const  result = await this.frapper.UpdateDoctype(doctype_name,doc_name,data_to_upload).toPromise()
      //console.log(result)
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllInformationsOfChambre(chambre_name) {
    try {
      let body = {
        "chambre_name": chambre_name
      }
      let doc_chambre_datas = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_all_information_of_chambre',body).toPromise()
      return doc_chambre_datas
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async SendEmailNotification(subject,to,body_) {
    try {
      let body = {
        "subject": subject,
        "to": to,
        "body": body_
      }
      let send_email = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.rendez_vous.rendez_vous.send_email_notification',body).toPromise()
      return send_email
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllLogOfDoctype(doctype,name){
    try {
      let body = {
        "doctype": doctype,
        "name": name
      }
      let doc_log_iformations = await this.frapper.CallCustomAPI('frappe.desk.form.load.getdoc',body).toPromise()
      return doc_log_iformations
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

}
