
 <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>E-service</title>
</head>


<!-- <app-public-header></app-public-header> -->

  <body style="min-height: 90vh;">
    <br><br><br><br>
    <div class="row ml-1 mr-1">
      <div class="col-md-12 grid-margin">
        <div class="row d-flex justify-content-center">
          <div class="col-12 group2 d-flex justify-content-center">
            <h3 class="font-weight-bold">{{'PUBLIC.available_service' | translate}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap col-8">
      <div class="search">
        <input type="text" class="searchTerm" [(ngModel)]="keyword" placeholder="Nom service">
        <button type="submit" class="searchButton" (click)="filterService()">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div><br>

    <ng-container *ngIf="List_operations.length>0" >
      <div class="d-flex justify-content-between firstSection" >
        <section class="col-md-1 col-xl-2 col-lg-2 col-xs-12 col-sm-12">
          <nav class="main-menu" style="overflow-x: hidden;overflow-y: auto;">
            <ul class="listcats">
              <span class="d-block  mb-3 d-flex justify-content-center" style="font-size: 14px;">
                <u><b>{{'PUBLIC.category' | translate}}</b></u>&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" *ngIf="selectedcategories.length>0" width="20" height="20" fill="currentColor"  class="bi bi-arrow-clockwise mt-1" viewBox="0 0 20 20" style="cursor: pointer;" (click)="clearSelectCats()">
                  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                </svg>
                <!-- <i class="fa fa-cogs fa-2x" style="font-size:17px; color:$main-color"></i> -->
              </span>
              
                <li *ngFor="let category of categories"  [ngClass]="{'selected': isSelectedcat(category)}" >
                    <a (click)="toggleSelectionCategory(category)">
                        <i class=" cat_icon fa fa-cogs fa-2x" [ngClass]="{'selected': isSelectedcat(category)}" style="font-size:17px;"></i>
                        <span class="nav-text">{{category}}</span>
                    </a>
                </li>
                
            </ul>
        </nav>
        </section>
        
        <div class="col-md-10 col-lg-10 col-xs-10 mybloc col-sm-12 d-flex justify-content-between">
          <div class="dropdowncategories">
            <form class="">
              <div class="multi-select"  >
                <label class="toggle-open" for="toggle-open"></label>
                <input type="checkbox" id="toggle-open">
                <ul class="multi-select-options">
                    <li *ngFor="let category of categories" ><label ><input  type="checkbox" name="tags" value="{{category}}" data-state="{{category}}"><span (click)="toggleSelectionCategory(category)">{{category}}</span></label></li>
                 
                
                </ul>
              </div>
              <ul style="display: none;" class="multi-selected"></ul>
      
            </form>
          </div>
          <div class="d-flex search-bloc mt-1">
            <div class="mysearch" (mouseover)="onHover()" (mouseout)="onLeave()">
                <div class="search-container">
                    <button class="search-button" id="searchButton" style="width: 100%;">🔍</button>
                    <input type="text" class="search-input" id="searchInput" placeholder="Search..." [(ngModel)]="searchTerm">
                </div>
            </div>&nbsp;
            <section class="d-flex flex-column" [ngClass]="{'shifted': isHovered}">
                <ul class="tag-list text-start col-12">
                    <li class="tag"
                        *ngFor="let tag of filteredTags"
                        [ngClass]="{'selected': isSelected(tag)}"
                        (click)="toggleSelection(tag)">
                        {{ tag }}
                    </li>
                </ul>
                <div *ngIf="selectedTags.length > 0">
                    <span class="text-danger" (click)="clearSelectTags()" style="font-size: 12px; cursor: pointer;"><b>x</b></span> &nbsp;
                    <span style="font-size: 10px;"><b>Mot-clés:</b></span>
                    <span *ngFor="let item of selectedTags" style="font-size: 10px;">
                        {{item}}
                    </span>
                </div>
            </section>
        </div>
  
    
          <section>
            <div class="d-flex  card-content flex-wrap justify-content-center" >
              <ng-container *ngIf="List_operations.length> 0;else emptyOperations">
                
                <div class="d-flex justify-content-center">
                  <div class="card"
  
                       *ngFor="let operation of List_operations | paginate: { itemsPerPage: 12, currentPage: pageservices, id: 'services' }"
                       (click)="openService(operation)">
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="row">
                          <div class="col-2">
                            <div class="circle-icon">
                              <i class="fas fa-cogs"></i>
                            </div>
                          </div>
                          <div class="col">
                            <div class="info">
                              <div class="title-text">{{operation.name}}</div>
                              <div class="organization ">
                                <span>{{operation?.organization?.trading_name}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="description" style="margin-top: -10px; padding: 10px;">
                      {{operation.description}}
                    </div>
                    <div style="padding: 10px;">
                      <div class="footer d-flex justify-content-between text-nowrap">
                        <div class="float-left text-nowrap">
                          <p class="price">{{'PUBLIC.cost_demande' | translate}}: <b>{{operation.amount}} Fcfa</b></p>
                        </div>
                        <div class="align-end text-nowrap">
                          <p class="request">{{'PUBLIC.request_demande' | translate}} <b>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                            </svg>
                          </b></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </ng-container>
              <ng-template #emptyOperations class="text-center justify-content-center align-items-center m-auto">
                <h6>{{'PUBLIC.empty_service' | translate}}</h6>
              </ng-template>
             
              
              
            </div>
        
            <div class="m-auto align-center">
              <pagination-controls
                (pageChange)="pageservices = $event"
                previousLabel="Précédent"
                nextLabel="Suivant"
                responsive="true"
                [id]="'services'"
              ></pagination-controls>
            </div>
          </section>
  
        </div>
      </div>
     
    </ng-container>

    <ngx-ui-loader></ngx-ui-loader>


  </body>
  <!-- <public-footer></public-footer> -->
</html>
