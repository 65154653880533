<div class="container" >
  <div class="title-container">
      <h5  style="color: #0c83eb; text-align: center;">Courrier</h5>
  </div>
  <div class="card-list d-flex flex-column justify-content-between align-items-center">
    <!-- <div class="col-md-3">
      <div class="card m-auto " >
        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle" width="auto" >
        <div>
          <h5 style="font-size: 11px;">{{data.owner}}</h5>
          <i>Trubinal: {{data.trubinal}}</i>
        </div>
      </div>
    </div> -->
    
    <div class="col-md-12 col-lg-12">
      <div class="sample">
        <h3 class="sample-title code d-flex justify-content-between" > <span> <u class="text-dark">expediteur</u>: {{data.owner}}</span>  <span><u class="text-dark">destinataire</u>: {{data.destinataire}}</span> </h3>
        <div class="sample-code">
            <div class="code" >
              <u> Dossier:</u> <span class="code-details">{{data['numéros_du_dossier']}}</span>
                <br>
               <u>Titre:</u> <span class="code-details">{{data.tag}}</span>
                <br><br>
               <u>Message:</u>           
               <div [innerHTML]="data.message" style="background-color: rgb(234, 236, 237);max-height: 320px; overflow: scroll;"></div>              
               <!-- <textarea style="min-height: 340px;" class="form-control" [readOnly]="true" [innerHTML]="data.message"   ></textarea> -->

               
               <!-- <span class="code-details">{{data.message}}</span> -->
                <br>
                <!-- <span class="code-details">Ibrahim Anne</span> -->

            </div>
        </div>
        <!-- <h3 class="sample-title output">output</h3>
        <div class="sample-html">
            <details class="sample-html-details">
                <summary class="sample-html-summary">What did the cat says when the mouse got away?</summary>
                <div class="speech">You have got to be kitten me!</div>
            </details>
        </div> -->
    </div>
    </div>



      <!-- <div class="card">
          <h3 class="card-title code code-summary"><i  class="fas fa-regular fa-building" style="font-size: 50px;"></i></h3>
          <h5>{{data.name}}</h5>
          <i>Trubinal: {{data.trubinal}}</i>
      </div> -->

      <!-- <div class="card">
        <h3 class="card-title code code-details" (click)="openModalCalendar()"><i class="fas fa-regular fa-calendar" style="font-size: 50px;"></i></h3>
        <h4 class="card-description">{{data.date}}</h4>
    </div> -->
  </div>

</div>












