import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { PublicService } from '../../services/public/public.service';
import { ServicesService } from '../../services/public/services.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent {

  SERVICE: any
  OPERATION_REF: any
  PAIEMENTS : any
  SELECTED_PAIEMENT: any = null
  loading = false;

  PaiementFormControl: any
  PaiementForm: FormGroup

  PAIEMENT_EN_COUR = false
  is_qr_code = false
  QRCODE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM0AAADNAQAAAAAzx8nEAAABsUlEQVR42u1YMY6EMAw0R5GSJ+Qny8eQQNqPwU/yBMoUCN+Mg3TNbXdiiiNFFEjBrD0zttf806r2XP37q93MOn8XnCaz13Dg2WavX/Zx/fkVYczum78ByXq30Y8c726HMVnadsvptBaNbJ0MxpSrDQvTJ4VhuXbAgjeTCAa5sc+lvsyykBtUCgIRmeGmUUosZgYxKJGPpuX7uQHfWHIdHRTd/ODj/TCAIEEfQABAkMuBTZEUBALOVdIa9oWkpHWQJIU8hX3RuZp9KZJyGH4+ZHoOKCzcgEUCo450rhEh4ass4QZ8I1EfsK+eRW5heiRKQTTmAgT4+KVaBYzrBKWU2EIu99eUkqgUYyAMhUXkG9AHK6yf+DiwvIvENxgDBAKWYXPko0gKPWBAKQvLLDRDkYi44TAtJ0GQFHKD7YeCorUpZVguH1e46N6I6e3UEVUnEuxqbLzasEKCyLqvmJnCN1DoN1UvikCEabW4rBpuhH2hnDjbcxDENG0P5xSLLuPnpIHRN4LA0dEJSvqNgBHm0XvMKUyPaKL3YERhv0HpumhqsyszKwu9pBd9/nR6rn67+gZ6pfiDj/m/1gAAAABJRU5ErkJggg=='

  data: any;
  _operation: any;

  constructor(private modalService: BsModalService,
    private service : ServicesService,
    private publicService: PublicService,
    private alerte: AlertesService
    ,public options: ModalOptions, private penalService: PenalCourrierService,private formBuilder: FormBuilder,private modalRef: BsModalRef,private ngxService: NgxUiLoaderService) {
    this.data = options.initialState.data;
    this._operation = options.initialState.operation_ref;
    let dataREF = {
      dynamic_field: this.formBuilder.array([])
    }

    this.SERVICE = this.data;
    this.OPERATION_REF= this._operation
    this.getPaiement()

    this.PaiementFormControl = {
      amount: new FormControl(this.SERVICE.amount),
      phone_number: new FormControl('',Validators.required)
    }
    this.PaiementForm = this.formBuilder.group(this.PaiementFormControl);

  }

  closeAllDialogs(){
    this.modalRef.hide();
  }


  getPaiement(){
    this.ngxService.startLoader('payment')
    this.publicService.getAllowedPayement().subscribe( (res :any) => {
      this.PAIEMENTS = res
      console.log({'paymentsssss':this.PAIEMENTS});
      this.ngxService.stopLoader('payment')
      
    }, (error :any) => {
      this.ngxService.stopLoader('payment')
      this.alerte.errorNotification('Erreur lors de la recuperation des paiements',error.error)
    })
  }

  selectTheProvider(prov:any){
    this.SELECTED_PAIEMENT = prov;
    console.log('selected paie' + this.SELECTED_PAIEMENT.name);
    
    if (this.SELECTED_PAIEMENT.name == "Wave") {
      this.is_qr_code = true;
    }
  }

  paiement(){
    this.ngxService.start();
    
    if(this.SELECTED_PAIEMENT == null){
      this.ngxService.stop();
      this.alerte.warningNotification('Selectionner le provider','Cliquer sur un des operateurs de paiement')

    }else {
     
      this.PAIEMENT_EN_COUR = !this.PAIEMENT_EN_COUR
      let body ={
        operation_ref: this.OPERATION_REF,
        service_ref: this.SERVICE.ref,
        paiement_service_id: this.SELECTED_PAIEMENT.id,
        phone_number: this.PaiementForm.value.phone_number,
        amount: this.PaiementForm.value.amount
      }

      console.log(this.SELECTED_PAIEMENT)
      console.log(body)
      this.publicService.sendPaiementRequest(body).subscribe((res:any) => {

        console.log({'paymenet response': res})
        this.PAIEMENT_EN_COUR = !this.PAIEMENT_EN_COUR
        const transaction: any = res.transaction ;
        // tslint:disable-next-line:variable-name
        console.log(transaction.custom_data)
        const custom_data = JSON.parse(transaction.custom_data);

        if (custom_data.qrCodeImg ){
          this.QRCODE = custom_data.qrCodeImg
          this.is_qr_code = true
          
            this.openModalVisualPieceQRCODE(this.QRCODE)
          
          //this.QRCODE = custom_data.qrCodeImg
        }
       
        this.alerte.successNotification('Paiement', 'Paiement en attente de confirmation')

        this.ngxService.stop();
        // this.modalRef.hide()
        // this.modalRef.hide()

      
      },error => {
        this.ngxService.stop();
          this.alerte.errorNotification('Erreur lors du paiement', 'Merci de revoir les informations de paiement')
        this.PAIEMENT_EN_COUR = !this.PAIEMENT_EN_COUR
        console.log(error)
      })

    }

  }

  openModalVisualPieceQRCODE(_url: any) {
    const config: ModalOptions = {
      initialState: {
        data:_url,
        url:_url,
        parentModal : null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg modal-dialog-centered' }));

    // Mettre à jour l'option initialState avec le modalRef
  }
}
