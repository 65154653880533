<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<!-- <div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div><br> -->
<body>

<!-- the second -->

  <br><div class="second" style="min-height: 500px;">
    <section class="header "  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      <p>République du Sénégal - Ministére de la justice <br> <span [innerHTML]="tribunal"></span></p>


      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>

    </section>
    <h6 class="text-center">Informations du plumitif</h6><br>
    <section>
      <div class="d-flex justify-content-between" >
        <div>
          <div class="d-flex justify-content-start align-items-center">
            <span><b>N° audience:</b>   {{data.numero_de_jugement}}</span>

          </div>
          <div>
             <span><b>Parties prenante:</b> </span><br>
            {{data.nom_complet_plaignant}} X {{data.prenom}} {{data.nom}}
          </div>

        </div>

        <div>
          <div class="d-flex align-items-center">
            <span><b>Date audience:</b>   {{getFormattedDate(data.date_de_jugement)}}</span>

          </div>
          <div>
            <b>Décision audience:</b><br>
            {{data.decision_judiciaire}}
          </div>

        </div>
      </div>
    </section><br>
    <section>
      <h6 class="text-center">Notes d'audiences</h6>
      <div [innerHTML]="data.node_daudiences"></div>
    </section>
  </div>


</body>
</html>
