import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { PublicService } from '../../services/public/public.service';
import { ServicesService } from '../../services/public/services.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddPaymentComponent } from '../add-payment/add-payment.component';
import * as $ from 'jquery';
import { RemplirformsservicepublicComponent } from '../remplirformsservicepublic/remplirformsservicepublic.component';
import { DatePipe } from '@angular/common';
import { VisualizePieceComponent } from '../visualize-piece/visualize-piece.component';
import { AddPublicServiceComponent } from '../add-public-service/add-public-service.component';
import { DocumentGenerationPublicComponent } from '../document-generation-public/document-generation-public.component';

@Component({
  selector: 'app-view-details-servicepublic',
  templateUrl: './view-details-servicepublic.component.html',
  styleUrls: ['./view-details-servicepublic.component.scss']
})
export class ViewDetailsServicepublicComponent {
  @Input() parentModal: BsModalRef;



  DEMANDE_TO_FORMS: any = [];
  data: any;
  operation_ref: any;
  _isTraitement:any;


  IS_TRAITEMENT = false;
  STATUS = 'OK';
  loadingServiceForms = true;
  noForm = true;

  demandeForm: FormGroup;
  KEYS: any;
  KEYS_GEN: any;

  SERVICE : any
  OPERATION_REF: any
  status: any
  is_mobile = false

  custumData:any =null
  traitement_ref :any = null
  operation_ref_ext :any = null



  constructor(private modalService: BsModalService,
    private service : ServicesService,
    private publicService: PublicService,
    private alerte: AlertesService,
    private datePipe: DatePipe,
    private bsrefModal: BsModalRef,
    public options: ModalOptions, private penalService: PenalCourrierService,private formBuilder: FormBuilder,private ngxService: NgxUiLoaderService,private modalRef: BsModalRef) {
    this.data = options.initialState.data;
    this.operation_ref = options.initialState.operation_ref
    this.status = options.initialState.status
    this._isTraitement = options.initialState.is_traitement
    this.SERVICE = this.data
    //console.log('data=> ', this.SERVICE);
    this.custumData = options.initialState.custom_data

    this.OPERATION_REF= this.operation_ref
    // console.log('OPERATION REF ==> ',this.OPERATION_REF)
    console.log('dataffff ==> ',this.data)

    this.verifStatus(this.status || 'OK')
    console.log(this.STATUS)



    try{
      if(this._isTraitement == 1){
        this.IS_TRAITEMENT = true
      }
    }catch (e) {

    }

    let Formdata = {
      dynamic_field: this.formBuilder.array([])
    }
    this.demandeForm = this.formBuilder.group(Formdata);

    this.getFormulaires()
    this.getServiceDetails(this.SERVICE.ref)
    this.getInformationFromCustomData()
  }
  closeWithMessage(message: string) {
    this.modalRef.hide();
    this.modalRef.onHidden.emit(message); 
  }
  _closeWithMessage(message: string) {
    this.modalService.hide();
    this.modalRef.onHidden.emit(message); 
  }

  getInformationFromCustomData(){
    try{
      console.log(this.custumData)
      this.traitement_ref = JSON.parse(this.custumData).traitement_ref
      console.log('traitement_ref ', this.traitement_ref);
      
      this.operation_ref_ext = JSON.parse(this.custumData).operation_ref
    }catch (e) {

    }

  }


  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    // if (this.DEMANDE_TO_FORMS.length ==0) {
    //   this.alerte.warningNotification('Erreur','Aucun formulaire trouvé pour cette demande')
    //   this.bsrefModal.hide()
    // }

  }
  openModalVisualPiece(url_image: any) {
    const config: ModalOptions = {
      initialState: {
        data: url_image,
        url:url_image,
        parentModal : null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg modal-dialog-centered' }));

    // Mettre à jour l'option initialState avec le modalRef
  }

  verifStatus(sendedStatus:any){
    let nn_allowed_status_for_traitement = ['NEW','TRAITEMENT ON PROGRESS','FINISH','CLOSE' ,'REJECTED' ,'FAILED']
    for(let statut of nn_allowed_status_for_traitement){
      if(sendedStatus == statut){
        this.STATUS ='NOK'
      }
    }
  }
  getFileInputId(formIndex: number, entryKey: string): string {
    console.log('fddffd',`file-${formIndex}`);

    return `file-${formIndex}-${entryKey}`;
  }


  getFormulaires() {
    this.loadingServiceForms = true
    this.ngxService.startLoader('detaildemande');
    this.service.getServiceFormsWithEntriesAndValue(this.OPERATION_REF).subscribe( (res :any) => {
      console.log({'getttfffoview demande': res})
      // Ajoutez les entrées d'images manuellement après avoir reçu les données
    // const imagesEntries = [
    //   {
    //     key: 'image1',
    //     name: 'Image 1',
    //     entries_type: {
    //       object: 'INPUT',
    //       type: 'file'
    //     },
    //     value: 'https://lequotidien.sn/wp-content/uploads/2022/10/MEDINA-BAYE.jpg',
    //     pattern: ''
    //   },
    //   {
    //     key: 'image2',
    //     name: 'Image 2',
    //     entries_type: {
    //       object: 'INPUT',
    //       type: 'file'
    //     },
    //     value: 'https://lequotidien.sn/wp-content/uploads/2022/10/MEDINA-BAYE.jpg',
    //     pattern: ''
    //   }
    // ];

    // // Inclure ces entrées d'images dans les données du formulaire
    // if (res && res.length > 0) {
    //   for (let form of res) {
    //     if (form.formulaire && form.formulaire.entries) {
    //       form.formulaire.entries.push(...imagesEntries);
    //     }
    //   }
    // }
      this.DEMANDE_TO_FORMS= res
     
      console.log('after add images=> ',this.DEMANDE_TO_FORMS);

      this.KEYS = []
      this.KEYS_GEN = []

      if (this.DEMANDE_TO_FORMS.length > 0) {
        for (let form of this.DEMANDE_TO_FORMS) {
          for (let entry_key of form.formulaire.entries) {
            const keyValuePair = {};
            keyValuePair[form.formulaire.ref + '#' + entry_key.key] = entry_key.value;
            const jsonStr = JSON.stringify(keyValuePair);
            this.KEYS_GEN.push(JSON.parse(jsonStr));
          }
        }
      } else {
        this.alerte.warningNotification('','un probléme est survenu lors de la récuperation du formulaire !');
        this.closeAllDialogs();
      }
      // if (this.DEMANDE_TO_FORMS.length >0) {
      //   for(let form of this.DEMANDE_TO_FORMS){
      //     for (let entry_key of form.formulaire.entries){
      //       this.KEYS.push(entry_key.key+'-'+entry_key.id)

      //       const obt = '{"'+form.formulaire.ref + '#' + entry_key.key +'": "'+ entry_key.value +'"}'

      //       //console.log(obt)
      //       //JSON.parse(obt)
      //       if (obt != null) {
      //         this.KEYS_GEN.push(JSON.parse(obt))
      //       }

      //     }
      //   }
      // }else{
      //   this.closeAllDialogs()
      // }


      let data = {
        dynamic_field: this.formBuilder.array(this.KEYS)
      }
      this.demandeForm = this.formBuilder.group(data);


      //console.log(this.FORM_INPUT)
      if (this.DEMANDE_TO_FORMS.length !=0){
        this.noForm = false
      }
      setTimeout(() => {
        this.loadingServiceForms = false
      }, 3);
      this.ngxService.stopLoader('detaildemande');


    }, (error :any) => {
      this.ngxService.stopLoader('detaildemande');
      this.loadingServiceForms = false
      this.alerte.warningNotification('Erreur','Aucune information trouvée pour cette demande')
      this.bsrefModal.hide()
    })
  }


  genererDoc(){

    const config: ModalOptions = {
      initialState: {
        data:  this.SERVICE,
        operation_ref:this.OPERATION_REF,
        KEYS_GEN: this.KEYS_GEN
      }
    }
    if (this.SERVICE != null) {
      const modaRef = this.modalService.show(DocumentGenerationPublicComponent, Object.assign({}, config, { class: 'modal-lg modal-centered' }));
      // modalRef.content.parentModal = modalRef;l

    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }

  }



  openTraitementFormDialog(){

    const config: ModalOptions = {
      initialState: {
        data:  this.SERVICE.related_service_detail,
        operation_ref:this.OPERATION_REF,
        data_from_request: this.DEMANDE_TO_FORMS
      }
    }
    console.log('the service===> ', this.SERVICE.related_service_detail);

    if (this.SERVICE.related_service_detail != null) {
      return new Promise<string>((resolve, reject) => {
        const modalRef = this.modalService.show(AddPublicServiceComponent, Object.assign({}, config, { class: 'modal-xl modal-centered' }));
        modalRef.content.parentModal = modalRef;
        modalRef.onHidden.subscribe((value: string) => {
          resolve(value); // Résoudre la promesse avec la valeur retournée
          console.log('after traiteme', value);
          
        });
      }).then(async (value: string) => {
        if ( value == "for_close") {
          this.closeWithMessage("for_refresh")
          // return this.openDemandeDetailDialog(service, operation_ref, status, custom_data);

        }
  
  
      });
      
      // modalRef.content.parentModal = modalRef;

    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }

  }

  openDemandeDetailDialog(service: any =null,operation_ref:any=null,custom_data:any=null){
    const is_traitemant = 0
    service = this.SERVICE
    operation_ref =this.traitement_ref!=null?this.traitement_ref: this.operation_ref_ext!=null?this.operation_ref_ext:null
    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        custom_data:custom_data,
        is_traitement:is_traitemant
      }
    }
    if (service != null) {
      return new Promise<string>((resolve, reject) => {
        const modalRef = this.modalService.show(ViewDetailsServicepublicComponent, Object.assign({}, config, { class: 'modal-xl modal-viewdetailspublic' }));
        modalRef.content.parentModal = modalRef;
        modalRef.onHidden.subscribe((value: string) => {
          resolve(value); // Résoudre la promesse avec la valeur retournée
          console.log(value);
          
        });
      }).then(async (value: string) => {
        if ( value != null) {
          
          this.closeWithMessage('for_open')
        }
  
  
      });
      const modalRef = this.modalService.show(ViewDetailsServicepublicComponent, Object.assign({}, config, { class: 'modal-lg modal-centered' }));
      // modalRef.content.parentModal = modalRef;

    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }

  }


  getServiceDetails(service_ref : any) {
    this.service.getService(service_ref).subscribe( (res :any) => {
      //console.log(this.formulaire.my_inputs)
      this.SERVICE = res
      console.log({'service details ': res})
      //console.log(this.FORM_INPUT)
      //this.loading = false

    }, (error :any) => {
      //this.loading = false
      this.alerte.errorNotification('SERVICE DETAIL',error.error)
    })
  }

  closeAllDialogs(){
    
      // this.modalRef.hide();
      // this.ngxService.stop();
      this.closeWithMessage('uu')
    
  }

  launchDialog(dialogRef: any) {
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe();
  }

}
