import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoginSigninComponent } from '../../../../authentification/login-signin/login-signin.component';
import { AlertesService } from '../../../../services/public/alertes.service';
import { PublicService } from '../../../../services/public/public.service';
import * as $ from 'jquery';
import { filter } from 'rxjs/operators';
import { FrapperService } from '../../../../services/frapper/frapper.service';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent {
  me:any  = null
  menu : any = []
 pageservices: number = 1;
 modalRef!: BsModalRef;
 exec_demande;
 label_btn: string = ''
 filtered_menu: any
 activeRoute: string = '';
path_home: string = "home"
 constructor(
   
  private publicServive: PublicService,private frapper: FrapperService,private alerte:AlertesService,private ngxService: NgxUiLoaderService, private modalService: BsModalService,
  private router: Router,
) {
  this.label_btn = 'Connexion'
  this.me = JSON.parse(localStorage.getItem('me'))
  if (this.me!=null) {
    this.path_home = "accueil"
  }
  console.log('dfdfdf', this.me);
  
//   if (this.me != null) {
//     this.label_btn =  this.me.full_name
//     this.menu = JSON.parse(localStorage.getItem('menu') +'')
//     // Filtrer les éléments ayant la classe 'list-group-item'
//     this.filtered_menu = this.menu.filter(item => item.class.includes('list-group-item'));
//     console.log('mymenu==>', this.menu);
//   }
  
//     // for active route
//     this.router.events.pipe(
//       filter(event => event instanceof NavigationEnd)
//     ).subscribe(() => {
//       this.activeRoute = this.router.url;
//     });
  

// }
if (this.me != null) {
  this.label_btn =  this.me.full_name
  this.menu = JSON.parse(localStorage.getItem('menu') +'')
  // Filtrer les éléments ayant la classe 'list-group-item'
 
  this.filtered_menu =  this.formatMenu(this.menu)
  console.log('mymenu==>', this.filtered_menu);
}

  // for active route
  this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
    this.activeRoute = this.router.url;
  });


}

formatMenu(menu: any[]) {
  const formattedMenu = [];
  let currentGroup = null;
  
  menu.forEach(item => {
    // Afficher le nom de l'élément pour mieux comprendre ce qui se passe
    console.log('item name:', item.name);
  
    // Vérifier si c'est un titre de groupe (par exemple "Metier", "Configurations", etc.)
    if (item.class === "groupTitle") {
      // Si l'élément est un titre de groupe, on le considère comme un menu principal
      currentGroup = {
        name: item.name,
        icon: item.icon,
        route: item.route,
        class: item.class,
        subMenus: []  // Sous-menus seront ajoutés ici
      };
      
      console.log('currentGroup name:', item.name);
  
      // Ajouter à la liste principale du menu
      formattedMenu.push(currentGroup);
  
      // Vérifier si le groupe est "Configurations" pour y ajouter un sous-menu "Déconnexion"
      // if (item.name.trim().toLowerCase() === "configurations") {
      //   console.log('Ajout du sous-menu Déconnexion');
        
      //   currentGroup.subMenus.push({
      //     name: "Déconnexion",
      //     icon: "bi-door-open",  
      //     route: "/logout",  
      //     class: "list-group-item list-group-item-action py-2 ripple"
      //   });
      // }
    
    } else {
      // Si l'élément n'est pas un titre de groupe, on le considère comme un sous-menu
      if (currentGroup) {
        currentGroup.subMenus.push(item);  // Ajouter au sous-menu de l'élément courant
      }
    }
  });
  console.log('formattedmenu:', formattedMenu);
  return formattedMenu;
  }
  async onChangeMenu(route: any ){
  console.log('route selected=> ',route);
  
  this.router.navigateByUrl(route)

}
async logout(){
  
  await this.frapper.logOut();
  this.initializeNavbar()
}

openLoginModal(){
  // this.exec_demande = service
  console.log({'form service': this.exec_demande})
  // this.router.navigate(['/login']);
  const config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: false, 
    initialState: {
      register: false,
      ismodal: true,
      // parentModal: null // Initialiser parentModal à null
    }
  }

  this.modalRef = this.modalService.show(LoginSigninComponent, Object.assign({}, config, {
    class: 'modal-xl',
    // ignoreBackdropClick: true
  }));
  
 
}

moreServices(){
  
  let _route = this.me== null? '/X/all_services':'/X/demandes'
  this.router.navigateByUrl(_route)
 
}

 initializeNavbar() {
  // Toggle du menu mobile
  $('#navbar-toggle').click(function() {
    $('nav ul').slideToggle();
  });

  // Activation de la classe 'active' sur le bouton hamburger
  $('#navbar-toggle').on('click', function() {
    this.classList.toggle('active');
  });

  // Gestion des sous-menus (dropdown)
  $('nav ul li a').not(':only-child').click(function(e) {
    var dropdown = $(this).siblings('.navbar-dropdown');

    // Fermer tous les autres sous-menus (dropdown) sauf celui cliqué
    $('.navbar-dropdown').not(dropdown).slideUp('slow');

    // Si le dropdown est déjà visible, on le ferme
    if (dropdown.is(':visible')) {
      // dropdown.slideUp('slow');
    } else {
      // Sinon on l'ouvre
      dropdown.slideDown('slow');
    }

    // Empêcher la propagation de l'événement pour ne pas fermer tout le menu
    e.stopPropagation();
  });

  // Fermer tous les sous-menus quand on clique sur un élément de sous-menu
  $('nav ul li .navbar-dropdown a').click(function(e) {
    // Fermer tous les sous-menus après avoir sélectionné un élément
    $('.navbar-dropdown').slideUp('slow');
    $('#navbar-toggle').removeClass('active');
    if ($(window).width() <= 768) {
      $('nav ul').slideToggle();
    }
    // $('nav ul').slideToggle();
    
    
    // Empêcher la propagation de l'événement pour ne pas affecter les autres clics
    e.stopPropagation();
  });

  // Fermer tous les sous-menus quand on clique en dehors du menu
  $('html').click(function() {
    $('.navbar-dropdown').slideUp('slow');
  });

  // Empêcher la fermeture des sous-menus quand on clique sur un élément du menu
  $('nav ul li').click(function(e) {
    e.stopPropagation();
  });
}


ngOnInit(){
 

setTimeout(() => {
  $('#btn-home').click(function() {
    $('html, body').animate({
        scrollTop: $('#home').offset().top
    }, 1000); 
});

$('#btn-services').click(function() {
    $('html, body').animate({
        scrollTop: $('#services').offset().top
    }, 1000);
});


$('#btn-about').click(function() {
    $('html, body').animate({
        scrollTop: $('#about').offset().top
    }, 1000);
});

$('#btn-suivi').click(function() {
    $('html, body').animate({
        scrollTop: $('#suivi').offset().top
    }, 1000);
});
  this.initializeNavbar();
}, 1000);


}
ngAfterViewInit() {
  // this.initializeNavbar();
  
}

}
