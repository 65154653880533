import { Component, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AmandeService } from '../../services/frapper/amande.service';
import { DossierService } from '../../services/frapper/dossier.service';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { JugementsService } from '../../services/frapper/jugements.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import * as $ from 'jquery';
import { ViewDetailsTransmissioncourrierComponent } from '../view-details-transmissioncourrier/view-details-transmissioncourrier.component';
import {CustomService} from "../../services/frapper/custom.service";
import {AssignedService} from "../../services/frapper/assigned.service";
import {UsercourrierService} from "../../services/frapper/usercourrier.service";

@Component({
  selector: 'app-transmissioncourrier',
  templateUrl: './transmissioncourrier.component.html',
  styleUrls: ['./transmissioncourrier.component.scss']
})
export class TransmissioncourrierComponent {
  @Input() parentModal: BsModalRef;
  isLinear = true;
  FormEnrollement: FormGroup;
  data: any;
  type: any;
  step: any;
  my_profil;
  modalRef!: BsModalRef;
 my_tribunal: any
  designations:any =[
    {name:'Le procureur du tribunal',is_interne:1,profil:'PROCUREUR'},
    {name:'Le président du tribunal',is_interne:1,profil:'PRESIDENT DU TRIBUNAL'},
    {name:'Agent d\'enrollement',is_interne:1,profil:'AGENT ENROLEMENT'},
    {name:'Agent d\'enrollement Penal',is_interne:1,profil:'AGENT ENROLEMENT PENAL'},
    {name:'Le juge d\'instruction',is_interne:1,profil:'JUGE D\'INSTRUCTION'},
    {name:'Le directeur de la police judiciaire',is_interne:0,profil:null},
    {name:'Le chef de la section de recherche',is_interne:0,profil:null},
    {name:'Le commisaire de police',is_interne:0,profil:null},

    {name:'Le commandant de la brigade de gendarmerie',is_interne:0,profil:null},
    {name:'L\'administrateur des greffes du tribunal',is_interne:1,profil:'GREFFIER EN CHEF'},
    {name:'Le directeur de la prison',is_interne:0,profil:null},
    {name:'Le mediateur de la maison de justice',is_interne:0,profil:null}
  ]
  actions:any =[
    {name:'Faire l\'objet de la requete'},
    {name:'Recevoir la plainte et procéder à l\'enquete'},
    {name:'Remettre contre récépissé les pièces ci-jointes'},
    {name:'Entendre'},
    {name:'Faire connaitre'},
    {name:'Citer-Signifier-Notifier'},
    {name:'Pour renseignement et avis'},
    {name:'A la transmission'}
  ]
  users:any =[
    {parent:'Ibrahima Ba Anne'}
  ]

  SELECETED_PROFIL=''

  IS_ITERNAL_USER=0
  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private jugementService: JugementsService,
              private cutomService:CustomService,
              private assignService:AssignedService,
              private amendeService:AmandeService,
              private role: EnrollementService,
              private courrier: UsercourrierService,
              private alerte:AlertesService,
              public bsModalRef: BsModalRef,
              private dossierService: DossierService,
              private ngxService: NgxUiLoaderService,
              private penalService: PenalCourrierService) {

    this.data = options.initialState.data;

    this.type = options.initialState.type;
    this.step = options.initialState.step;


  }


  async filterValueChange(value){
    //console.log(value)
    this.ngxService.start()
    try{
      const data:any = (this.designations.filter(objet => objet.name === value))[0];
      //console.log(data)
      if(data.is_interne==1){
        this.IS_ITERNAL_USER=1
        await this.getUser(data.profil)
        //this.ngxService.stop()
      }else{
        this.IS_ITERNAL_USER=0
      }
      this.ngxService.stop()
    }catch (e) {
      this.ngxService.stop()
    }

  }
  closeModal(){
    this.bsModalRef.hide()
  }

  async getUser(PROFIL){
    this.users=[]
    this.SELECETED_PROFIL=PROFIL
    this.users= (await this.cutomService.getUserByRole(PROFIL)).message

  }
  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }

  createSoitTransmis(form){
    console.log(form.value)
    this.ngxService.start()
    let data_to_send=form.value
    data_to_send['type_of_st'] = this.type
    data_to_send['doctype_name'] = this.data.name
   


    this.cutomService.createSoitTransmisObject(data_to_send).then(async res=>{  
      if(data_to_send.designation_interne){
       await this.assignService.doAssignation(this.type,this.data.name,[data_to_send.designation_interne]).then(res=>{
        })
        let c_interne=  {
          "profil": this.SELECETED_PROFIL,
          "destinataire": data_to_send.designation_interne,
          "tag": res.data.action_1,
          "fichier": null,
          "message": res.data.action_1 + ' / '+ res.data.remarque_1
        }
        if(this.type =='Dossier'){
          c_interne["numéros_du_dossier"]= this.data.name
        }
        if(this.my_tribunal !=null){
          c_interne['tribunal'] = this.my_tribunal.name
        }

        await this.courrier.nouveauCourrierInterne(c_interne).then(res=>{
          this.assignService.doAssignation(this.courrier.Docatype_courrier_interne,res.data.name,[data_to_send.designation_interne])
        })
      }
      this.openModalSuccessDialog('Transmission Effectuée')
      this.ngxService.stop()
      this.closeWithMessage("success")
      this.ngxService.stop()
    },
        err =>{
      console.log(err)
      this.alerte.errorNotification('Transmission','Erreur lors de la transmission')
      this.ngxService.stop()
    })
  }


  openModalSuccessDialog(message: string) {
    //this.ngxService.start()
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.alerte.successNotification('dossier',message)
    // this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
    //this.ngxService.stop()
  }


  openModalDetailsTransmission() {
    this.ngxService.start()
    const config: ModalOptions = {
      initialState: {
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(ViewDetailsTransmissioncourrierComponent, Object.assign({}, config, {class:'modal-xl modal-dialog-centered'}));
    this.ngxService.stop()
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }



  ngOnInit() {
    this.designations=[]
    this.actions=[]
    this.ngxService.start()
     this.my_profil =localStorage.getItem('profil')
    let my_profil =this.my_profil
    try {
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
    }catch (e) {
      this.my_tribunal =null
    }
    this.cutomService.getDesignations().then(res=>{
      //console.log('====> ',res)
      for(let design of res.data ){
        this.cutomService.getDesignationsDetails(design.name).then(des=>{
          //console.log('des====> ',des)
          let res = des.data.profil_allowed
          const data:any = (res.filter(objet => objet.role === my_profil))[0];
          //console.log('IS ALLOWED ==> ',data)
          if(data){
            this.designations.push(des.data)
          }

        })
      }
    })

    this.cutomService.getActions().then(res=>{
     // console.log('====> ',res)
      for(let design of res.data ){
        this.cutomService.getActionsDetails(design.name).then(des=>{
          //console.log('des====> ',des)
          let res = des.data.profil_allowed
          const data:any = (res.filter(objet => objet.role === my_profil))[0];
          //console.log('IS ALLOWED ==> ',data)
          if(data){
            this.actions.push(des.data)
          }

        })
      }
    })

    this.ngxService.stop()
    //console.log(this.designations)
  }
}
