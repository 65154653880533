import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LanguageService } from '../../../E-justice/services/Languages/LanguageService.service';

@Component({
  selector: 'app-lang-dropdown',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `<div class="dropdown">
        <b>FR</b>

  <!-- <img class="dropbtn" [src]="currentFlag" alt="Language" (click)="toggleDropdown()">

  <div class="dropdown-content" [ngClass]="{'visible': isDropdownVisible}">
    <img *ngFor="let flag of languageFlags" [src]="flag.src" (click)="changeLanguage(flag.language)">
  </div> -->
</div>`,
  styleUrls: ['./LangDropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangDropdownComponent {
  currentFlag: string = '';  // Chemin du drapeau actuel
  isDropdownVisible: boolean = false;  // Indicateur de visibilité du menu déroulant

  // Liste des drapeaux disponibles avec les langues correspondantes
  languageFlags = [
    { language: 'fr', src: '../../../../assets/flags/1x1/sn.svg' },
    { language: 'en', src: '../../../../assets/flags/1x1/gb.svg' },
    // Ajoutez d'autres langues au besoin
  ];

  constructor(private translate: LanguageService, private location: Location) {
    // Initialisez le drapeau actuel avec la langue active
    this.currentFlag = this.getFlagPath(this.translate.userLanguage);
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  changeLanguage(language: string) {
    // Changez la langue et mettez à jour le drapeau actuel
    // return
    this.translate.setLanguage(language);
    this.currentFlag = this.getFlagPath(language);
    this.isDropdownVisible = false;
    this.translate.translateElements();
    window.location.reload();
  }

  private getFlagPath(language: string): string {
    // Retournez le chemin du drapeau en fonction de la langue
    const flag = this.languageFlags.find(flag => flag.language === language);
    return flag ? flag.src : '';
  }
 }
