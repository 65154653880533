import { trigger, transition, style, animate } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { AlertesService } from '../../../services/public/alertes.service';
import { PublicService } from '../../../services/public/public.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddPublicServiceComponent } from '../../../dialogs/add-public-service/add-public-service.component';
import { LoginSigninComponent } from '../../../authentification/login-signin/login-signin.component';
import { Router } from '@angular/router';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { PenalCourrierService } from '../../../services/penal.courrier/penal.courrier.service';
// import  from "../../../../../assets/data/head_banner.json";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HomePageComponent implements OnInit,AfterViewInit {
  filtered_menu: any
  List_operations: any[] = [];

   me:any  = null
   menu : any = []
  pageservices: number = 1;
  modalRef!: BsModalRef;
  exec_demande;
  label_btn: string = ''

  browserName: string = '';
  browserVersion: string = '';
  os: string = '';
  bannerData: any = [];
  @ViewChild('videoPlayer') videoPlayer: any;


  constructor(

    private publicServive: PublicService,private alerte:AlertesService,
    private banner_data: PenalCourrierService,
    private ngxService: NgxUiLoaderService, private modalService: BsModalService,
    private router: Router,
  ) {
    this.label_btn = 'Connexion'
    this.me = JSON.parse(localStorage.getItem('me'))
    this.getLocalInformation().then(res => {
      console.log('SUCCES == == == >  ', res)
    },err =>{
      console.log('ERROR  == == == >  ', err)
    })

    this.getAllowedDemande()
    if (this.me != null) {
      this.label_btn =  this.me.full_name
      this.menu = JSON.parse(localStorage.getItem('menu') +'')
      // Filtrer les éléments ayant la classe 'list-group-item'
      this.filtered_menu = this.menu?.filter(item => item.class.includes('list-group-item'));
      console.log('mymenu==>', this.menu);
    }
  }

  private async getLocalInformation() {
    if(!localStorage.getItem('DEVICE_ID')){
      const fpPromise = FingerprintJS.load();
      fpPromise.then(fp => {
        fp.get().then(result => {
          // Identifiant unique du visiteur
          const visitorId = result.visitorId;
          this.getBrowserInfo()
          this.registerDivice(visitorId).then(res =>{

          },err => {
            console.log('ERROR ', err)
          })


        });
      });
    }

    if(localStorage.getItem('DEVICE_ID') && !localStorage.getItem('DEVICE_ID_CODE')){
      this.loginDivice(localStorage.getItem('DEVICE_ID')).then(res =>{
        console.log('SUCEESS ', res)
      },err => {
        console.log('ERROR ', err)
      })
    }

  }

  ngAfterViewInit() {
   // Assurez-vous que la vidéo démarre au départ
  }

  callAction(action: string) {
    if (action === "moreServices()") {
      this.moreServices();
    }
  }

  moreServices(){
  
    let _route = this.me== null? '/X/all_services':'/X/demandes'
    this.router.navigateByUrl(_route)
   
  }

  getBrowserInfo() {
    const userAgent = navigator.userAgent;

    // Détection du navigateur
    if (userAgent.indexOf('Firefox') > -1) {
      this.browserName = 'Mozilla Firefox';
      this.browserVersion = userAgent.match(/Firefox\/(\d+)/)?.[1] || 'Inconnu';
    } else if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edg') === -1) {
      this.browserName = 'Google Chrome';
      this.browserVersion = userAgent.match(/Chrome\/(\d+)/)?.[1] || 'Inconnu';
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
      this.browserName = 'Apple Safari';
      this.browserVersion = userAgent.match(/Version\/(\d+)/)?.[1] || 'Inconnu';
    } else if (userAgent.indexOf('Edg') > -1) {
      this.browserName = 'Microsoft Edge';
      this.browserVersion = userAgent.match(/Edg\/(\d+)/)?.[1] || 'Inconnu';
    } else {
      this.browserName = 'Navigateur inconnu';
    }

    // Détection du système d'exploitation
    if (userAgent.indexOf('Win') > -1) {
      this.os = 'Windows';
    } else if (userAgent.indexOf('Mac') > -1) {
      this.os = 'MacOS';
    } else if (userAgent.indexOf('Linux') > -1) {
      this.os = 'Linux';
    } else if (userAgent.indexOf('Android') > -1) {
      this.os = 'Android';
    } else if (userAgent.indexOf('like Mac') > -1) {
      this.os = 'iOS';
    } else {
      this.os = 'Système d\'exploitation inconnu';
    }

    // Détection du navigateur mobile spécifique
  if (userAgent.indexOf('Android') > -1 && userAgent.indexOf('Chrome') > -1) {
    this.browserName = 'Google Chrome sur Android';
    this.browserVersion = userAgent.match(/Chrome\/(\d+)/)?.[1] || 'Inconnu';
  } else if (userAgent.indexOf('iPhone') > -1 && userAgent.indexOf('Safari') > -1) {
    this.browserName = 'Safari sur iPhone';
    this.browserVersion = userAgent.match(/Version\/(\d+)/)?.[1] || 'Inconnu';
  }
  }

  // async registerDivice(unique_divice_id) {
  //   this.ngxService.start();
  //   let body = {
  //     pseudo: unique_divice_id,
  //     full_name: unique_divice_id,
  //     password: unique_divice_id,
  //     password_confirmation: unique_divice_id,
  //     customer_informations: {
  //       ext_id: 'ANO-' + unique_divice_id,
  //       givenName: this.browserName,
  //       familyName: this.browserVersion
  //     }
  //   };
  //   console.log('theb0dy=>', body);
  
  //   localStorage.setItem('DEVICE_ID', unique_divice_id);
  //   try {
  //     const res = await this.publicServive.devicesregister(body).toPromise();
  //     console.log('SUCCESS = > ', res);
  //     let result: any = res;
  //     if (!localStorage.getItem('token')) {
  //       localStorage.setItem('token', result.access.token + '');
  //     }
  //     localStorage.setItem('DEVICE_ID_CODE', result.access.token + '');
  //     localStorage.setItem('type', result.access.type + '');
  //     this.ngxService.stop();
  //   } catch (error) {
  //     this.ngxService.stop();
  
  //     // réessayer si le device_id_code n'est pas generer
  //     await this.delay(200);
  //     window.location.reload()
     
  //   }
  // }
  
  // delay(ms: number) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
  
  async registerDivice(unique_divice_id) {
    // this.ngxService.start('register_ananymous');
    let body= {
      pseudo : unique_divice_id,
      full_name : unique_divice_id,
      password : unique_divice_id,
      password_confirmation: unique_divice_id,
      customer_informations :{
        ext_id:'ANO-'+unique_divice_id,
        givenName:this.browserName,
        familyName:this.browserVersion
      }
    }
    localStorage.setItem('DEVICE_ID',unique_divice_id)
    this.publicServive.devicesregister(body).subscribe(res =>{
      console.log('DEVICE SUCCESS = > ',res)
      let result:any = res
      if(!localStorage.getItem('token')){
        localStorage.setItem('token',result.access.token+'')
      }
      localStorage.setItem('DEVICE_ID_CODE',result.access.token+'')
      localStorage.setItem('type', result.access.type+'')

      this.getAllowedDemande()
      // this.ngxService.stop('register_ananymous');
    },async error => {
       // réessayer si le device_id_code n'est pas generer
       await this.delay(200);
       window.location.reload()
      console.log('DEVICE ERROR ', error)
      // this.ngxService.stop('register_ananymous');
    })

  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async loginDivice(unique_divice_id) {
    // this.ngxService.start('login_ananymous');
    let body= {
      pseudo : unique_divice_id,
      password : unique_divice_id
    }
    this.publicServive.login(body, true).subscribe(res => {
      // this.ngxService.stop('login_ananymous');
    },error => {
      //console.log('ERROR ', error)
      this.getAllowedDemande()
      // this.ngxService.stop('login_ananymous');
      //this.alerte.errorNotification('Connexion','erreur lors de la connexion, veuillez verifier les informations saisies')
    })
  }

  async getAllowedDemande(){
    // this.loadingDemandeForms = true
    console.log('response demande getAllowedDemande');
    this.ngxService.startLoader('service-loader');
    try {
      const res: any = await new Promise((resolve, reject) => {
        this.publicServive.getAllowedDemande(true).subscribe(resolve, reject);
      });
      
      //console.log('ALL services ==> ',res)
      if (res != null) {
        // this.List_operations = res
        // limitter a 6 services
        this.List_operations = res.slice(0, 6);

      }
      this.ngxService.stopLoader('service-loader');
      return this.List_operations
    } catch (error) {
      console.log('error recuperation services ',error);
      this.ngxService.stopLoader('service-loader');
    }
    
  //  await this.publicServive.getAllowedDemande().subscribe( (res :any) => {
    //   this.List_operations = []
    //   this.List_operations = res
    //   console.log({'response demande': res})

    //  this.ngxService.stop()

    // }, (error :any) => {
    //   this.ngxService.stop()
    //   this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    // }
  // )
  }


  openService(service:any){
    if (this.me != null) {
      this.exec_demande = service
      console.log({'form service': this.exec_demande})

      const config: ModalOptions = {
        initialState: {
          data: service,
          parentModal: null // Initialiser parentModal à null
        }
      }

      // Ouvrir le modal
      if (service != null) {
        this.modalRef = this.modalService.show(AddPublicServiceComponent, Object.assign({}, config, {class:'modal-lg  modal-centered'}));

      }else{
        this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
      }
    }else{
      this.alerte.warningNotification('Sérvice ','Veillez vous connecter pour exécuter ce sérvice !')
    }
    

  }


  ngOnInit(): void {
    this.banner_data.getBannerData().subscribe(
      (data) => {
         // Trier les données en fonction de la position
         this.bannerData = data.sort((a, b) => a.position - b.position);
         // ne pas recuper les elements commentes
         this.bannerData = this.bannerData.filter(item => !item.commented);

      },
      (error) => {
        console.error('Erreur lors de la récupération des données:', error);
      }
    );
    $(document).ready(function() {
      // window.location.reload()
      const video = this.videoPlayer.nativeElement;

      video.addEventListener('ended', () => {
        console.log('La vidéo est terminée, relancez-la.');
        video.play();  // Reprend la lecture si la vidéo s'arrête
      });
  
      // Pour vérifier si la vidéo redémarre correctement, vous pouvez aussi utiliser un intervalle pour la relancer
      video.play(); 
      $('#btn-services').click(function() {
          $('html, body').animate({
              scrollTop: $('#services').offset().top
          }, 1000); 
      });
  });
  
   



  }
}
