
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
  <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css" />
  </head>
  <body>

    <div class="page">

     <div class="container ">
      <div class="d-flex justify-content-center">
        <h6 class="text-light text-center mt-2">{{type}}</h6>
      </div>
       <!-- tabs -->
        
       
     
     
     <ng-container *ngIf="is_stepped; else notstepped">
      <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">

        <input 
        *ngFor="let step of steps_demande; let i = index" 
        type="radio" 
        name="pcss3t" 
        id="_tab{{i + 1}}" 
        [(ngModel)]="activeTab" 
        [class]="'_tab-content-' + (i + 1)" 
        [value]="i" 
        [checked]="i === 0"> 
        
    <label 
        *ngFor="let step of steps_demande; let j = index" 
        [for]="'_tab' + (j + 1)"
        [class.active]="activeTab === j">{{step.name_step}}</label>

    
        <ul style="min-height: 450px;">
            <li 
                *ngFor="let step of steps_demande; let i = index" 
                class="_tab-content _tab-content-{{i + 1}} typography" 
                style="overflow: auto;">
    
                <fieldset class="step{{i + 1}}">
                    <div class="form-card">
                        <div class="row justify-content-between flex-wrap">
                            <div 
                                *ngFor="let field of fieldsByStep[i]" 
                                class="col-md-6 col-lg-6 col-xl-6 col-sm-10 " 
                                style="padding: 0 5px 0 0;">
                                
                                <label class="fieldlabels">{{ formatLabel(field.key) }}</label>
                                
                                <ng-container *ngIf="field.key.startsWith('file_'); else normalInput">
                                    <div class="file-input"  >
                                        <label class="custom-file-upload" for="file{{i}}">
                                            <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                                            <div class="text text-nowrap" (click)="openModalVisualPiece(field.value)">
                                                <span class="file-label">Cliquez pour voir la pièce</span>
                                            </div>
                                            <input 
                                                id="file{{i}}" 
                                                class="form-control file-input" 
                                                [name]="field.key" 
                                                [ngModel]="field.value" />
                                        </label>
                                    </div>
                                </ng-container>
    
                                <ng-template #normalInput  >
                                    <input 
                                        class="form-control" 
                                        type="text"
                                        [readOnly]="template_demande.is_external" 
                                        [name]="field.key" 
                                        [ngModel]="field.value" />
                                </ng-template>
                                
                                <ng-container *ngIf="field.value.key === 'select'"  >
                                    <select 
                                        [name]="field.key" 
                                        [ngModel]="template_demande.content[field.value.value]" 
                                        [required]="true">
                                        <option value="" disabled selected>--choisir option--</option>
                                        <option *ngFor="let item of field.value.value" [value]="item.value">{{ item.value }}</option>
                                    </select>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </li>
        </ul>
    </div>
    

     </ng-container>

      <ng-template #notstepped >
        <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
          <input type="radio" name="pcss3t" checked  id="_tab1"class="_tab-content-1 " >
          <label for="_tab1">informations  </label>
 
          <ul style="min-height: 450px;"  >
            <li class="_tab-content _tab-content-1 active typography" style="overflow: auto;">
             <br>
           
            <fieldset>
             <div class="form-card">
               <div class="row justify-content-between">
                 <div class="col-md-6 col-lg-6 col-xl-6 col-sm-10 " *ngFor="let key of getKeys(template_demande?.content)"  style="padding: 0 5px 0 0;">
                  <ng-container *ngIf="key.startsWith('file_'); else normalinput">
                    <div class="file-input"  (click)=" openModalVisualPiece(template_demande.content[key]) ">
                      <label class="fieldlabels">{{ formatLabel(key) }} </label>
                      <label class="custom-file-upload" for="file{{i}}">
                        <div class="icon">
                          <i class="fas fa-regular fa-file"></i>
                        </div>
                        <div class="text text-nowrap">
                          <span class="file-label">Cliquez pour voir la pièce</span>
                        </div>
                       
                       
                      </label>
                    </div>
                    
                    
                  </ng-container>
                  <ng-template #normalinput >
                    <div *ngIf=" !key.startsWith('null')">
                      <label class="fieldlabels">{{ formatLabel(key) }} </label>
                      <input 
                      class="form-control" 
                      type="text"
                      [readOnly]="template_demande.is_external == 1? true :false"
                      [name]="key" 
                      [ngModel]="template_demande.content[key]" 
                    />
                    </div>
                    
                  </ng-template>
                   <!-- <label class="fieldlabels">{{ formatLabel(key) }}</label>
                   <ng-container *ngIf="key.startsWith('file_'); else normalinput" >
                     <div class="file-input">
                       <label class="custom-file-upload" for="file{{i}}" >
                         <div>
                           
                         </div>
                         <div class="icon"><i class="fas fa-regular fa-file"></i></div>
                         <div class="text text-nowrap" (click)="openModalVisualPiece(template_demande.content[key])">
                           <span class="file-label">Cliquez pour voir la piéce</span>
                         </div>
                         <input  id="file{{i}}" class="form-control file-input" 
                               [name]="key" 
                               [ngModel]="template_demande.content[key]" 
                                />
                       </label>
                       
                     </div>
                   </ng-container>
                   <ng-template #normalinput>
                     <input 
                     class="form-control" 
                     type="text"
                     [readOnly]="template_demande.is_external == 1? true :false"
                     [name]="key" 
                     [ngModel]="template_demande.content[key]" 
                   />
                   </ng-template> -->
                   
                 </div>
               </div>
             </div>
           </fieldset>
           
            
         
            
            </li>
 
            
          </ul>
        </div>
      </ng-template>
       
     </div><br>
     <div class="d-flex justify-content-between" >
      <button class="bg-success btn"  (click)="openModalVisualizeD_visite()" *ngIf="type_request == 'document'">
          <span class="text-center text-light" style="font-size: 12px;">
            Voir document
           </span>
     </button>

     <button class="bg-success btn" *ngIf="type_request == 'demande'" (click)="openModalModalGenerateDocument()">
      <span class="text-center text-light" style="font-size: 13px;">
        Générer document
       </span>
      </button>
     </div>

     <div class="actions d-flex justify-content-between flex-wrap mt-3" *ngIf="show_actions">
      <button class="btn btn-danger col-md-3  col-lg-3 col-sm-12 text-light text-nowrap mb-1">Vue et s'oppose</button>
      <button class="btn btn-warning col-md-3 col-lg-3 col-sm-12  text-light text-nowrap mb-1">Vue et ne s'oppose</button>
      <button class="btn btn-success col-md-3 col-lg-3 col-sm-12  text-light text-nowrap mb-1">Vue pour établissement</button>
   </div>
     


     
    </div>
  </body>
</html>
