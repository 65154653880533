
   <!DOCTYPE html>
   <html>
     <head>
       <meta charset="utf-8">
       <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
     <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css" />
     </head>
     <body>

       <div class="page view-details-courrier">
        <div class="w-100 text-center">
          <h5 id="heading" style="font-size: 14px;" class="text-light">
           
              <span><b class="text-light">{{'details_courrier' | translate}} {{data.numero_courrier}}</b></span>
             
          </h5>

          <button type="button" class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
              <i class="fas fa-times text-light" style="font-size: 1.5rem;"></i>
          </button>
      </div>
      <i (click)="imprimeNumero(data)" class="fas fa-solid fa-print text-light" style="font-size: 20px;"></i>

        <div class="addButton  text-nowrap  mr-2"  *ngIf="Allow_Piece_Actions==true" >
          <div class="button b2  w-auto text-light  float-right mt-3 " style=" background:#f3a14c !important; " (click)="updateValue()">
            <i class="fas fa-solid fa-save float-left mt-1" style="font-size: 20px;"></i></div>
        </div>
         <div>
          <div class="d-flex flex-column flex-md-row justify-content-between position-relative">

            <div class="sticky" *ngIf="ALL_ST.length > 0" (click)="openModalDetailsTransmission(latestSt)">
              <div class="container">
                <div class="container-inner">
                  <div class="sticky-container">
                    <div class="sticky-outer">
                      <div class="sticky">
                        <svg width="0" height="0">
                          <defs>
                            <clipPath id="stickyClip" clipPathUnits="objectBoundingBox">
                              <path d="M 0 0 Q 0 0.69, 0.03 0.96 0.03 0.96, 1 0.96 Q 0.96 0.69, 0.96 0 0.96 0, 0 0" stroke-linejoin="round" stroke-linecap="square" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div class="sticky-content text-center text-wrap">
                          S.T<br>
                          {{latestSt.designation_1}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between flex-grow-1 mt-2">
              <!-- <h4>Informations du courrier</h4> -->

              <div class="col-md-4 col-lg-4 col-sm-12 mt-3"  *ngIf="ACTION_ALLOWED">
                <div class="actionBtn text-nowrap" *ngIf="CAN_CREATE_DOSSIER">
                  <div class="d-flex justify-content-between addButton" *ngIf="data.registre != 'Courrier Depart'">
                    <div class="button b3" (click)="openModalTransmission(data, 'Courrier')" *ngIf="Information_Dossier.length == 0 && (latestSt?.designation_interne == user_info || ALL_ST.length == 0)">Soit Transmis</div>&nbsp;&nbsp;
                    <div class="button b1" *ngIf="Information_Dossier.length == 0 && (latestSt?.designation_interne == user_info || ALL_ST.length == 0)" (click)="openModalAddDossier('step3', data)">Créer dossier</div>&nbsp;
                    <div class="bg-danger m-auto text-light" *ngIf="Information_Dossier.length != 0">{{'exist_folder' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <button type="button" class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
              <i class="fas fa-times text-light" style="font-size: 1.5rem;"></i>
            </button> -->

          </div><br>



           <!-- tabs -->
           <div class="pcss3t-d-courrier pcss3t-d-courrier-effect-scale pcss3t-d-courrier-theme-1">

             <input type="radio" name="pcss3t-d-courrier" checked   id="tab1"class="tab-content-first">

             <label for="tab1">{{'resume' | translate}}</label>

             <input type="radio" name="pcss3t-d-courrier" id="tab2" class="tab-content-2">
             <label for="tab2">{{'details_courrier' | translate}} </label>

             <input type="radio" name="pcss3t-d-courrier" id="tab4" class="tab-content-4">
             <label for="tab4"><i class="icon-cogs"></i>{{'state' | translate}}</label>

      <!--       <input type="radio" name="pcss3t-d-courrier" id="tab3" class="tab-content-3">
             <label for="tab3"><i class="icon-cogs"></i>Situation du courrier</label>-->

             <input type="radio" name="pcss3t-d-courrier" id="tab5" class="tab-content-5">
             <label for="tab5"><i class="icon-cogs"></i>{{'docs_fournis' | translate}}</label>

             <ul  style="height: 450px;">

               <li class="tab-content tab-content-first typography" style="overflow: auto;">
                 <br>


                 <fieldset>
                  <div class="form-card">


                    <div class="row  justify-content-between">
                      <div class="col-md-6 p-1 col-sm-12">
                        <label class="fieldlabels">{{'classification' | translate}}: </label>
                        <select class="custom-select" id="inputGroupSelect01">
                          <option value="">{{data.classification}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" name="classif" value="{{data.classification}}" /> -->

                        <label class="fieldlabels">{{'nature' | translate}}: </label>
                        <select class="custom-select" id="inputGroupSelect01">
                          <option value="">{{data.nature}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" name="nature" value="{{data.nature}}" /> -->

                        <label class="fieldlabels">{{'reference' | translate}}: </label>
                        <select class="custom-select" id="inputGroupSelect01">
                          <option value="">{{data.reference}}</option>
                        </select>

                        <label class="fieldlabels" *ngIf="data.reference != 'Non'">{{'num_origine' | translate}}: </label>
                        <input *ngIf="data.reference != 'Non'" class="form-control" type="text" name="numero" (change)="updateValueforChange($event.target.value,'numero_dorigine')" value="{{data.numero_origine}}" />
                      </div>

                      <div class="col-md-6 p-1 col-sm-12 d-flex flex-column justify-content-between">
                        <label class="fieldlabels">{{'num_courrier' | translate}}: </label>
                        <input [readOnly]="true" class="form-control" type="text" name="registre" value="{{data.numero_courrier}}" />

                        <label class="fieldlabels">{{'date_receiption' | translate}}: </label>
                        <input class="form-control" type="date" name="date_reception"  [value]="data.date_reception"   (change)="updateValueforChange($event.target.value,'date_reception')"/>

                        <label class="fieldlabels">{{'date_arrived' | translate}}: </label>
                        <input class="form-control" type="date" name="date_courrier" [value]="data.date_courrier"  (change)="updateValueforChange($event.target.value,'date_courrier')"/>

                        <label class="fieldlabels">{{'inf_reference' | translate}}: </label>
                        <textarea style="height: 110px;" class="form-control" value="{{data.information_de_la_reference}}" (change)="updateValueforChange($event.target.value,'information_de_la_reference')"></textarea>
                      </div>
                    </div>
                  </div>
                </fieldset>

               </li>

               <li class="tab-content tab-content-2 typography" style="overflow: auto;height: 450px;top: 0;">
                 <br>


                 <fieldset>
                  <div class="form-card">
                    <div class="row justify-content-between">
                      <div class="col-md-6 col-sm-12 p-1">
                        <label class="fieldlabels">{{'type_identif' | translate}}:</label>
                        <input type="text" class="form-control" name="type_identification" value="{{data.type_identification}}" (change)="updateValueforChange($event.target.value,'type_identification')" />

                        <label class="fieldlabels">{{'firstname' | translate}}:</label>
                        <input class="form-control" type="text" name="prenom" value="{{data.prenom}}" (change)="updateValueforChange($event.target.value,'prenom')" />

                        <label class="fieldlabels">{{'country' | translate}}:</label>
                        <select class="custom-select" id="inputGroupSelect01" (change)="updateValueforChange($event.target.value,'pays')">
                          <option value="1">{{data.pays}}</option>
                          <option value="SENEGAL">SENEGAL</option>
                          <option value="AUTRE">AUTRE</option>
                        </select>

                        <label class="fieldlabels">{{'zone' | translate}}:</label>
                        <input class="form-control" type="text" name="region" value="{{data.region}}" (change)="updateValueforChange($event.target.value,'region')" />

                        <label class="fieldlabels">{{'adress' | translate}}:</label>
                        <input class="form-control" type="text" name="adresse" value="{{data.adresse}}" (change)="updateValueforChange($event.target.value,'adresse')" />
                      </div>

                      <div class="col-md-6 col-sm-12 p-1 d-flex flex-column justify-content-between">
                        <label class="fieldlabels">{{'num_identif' | translate}}:</label>
                        <input type="text" class="form-control" name="numero_identification" value="{{data.numero_identification}}" (change)="updateValueforChange($event.target.value,'numero_identification')" />

                        <label class="fieldlabels">{{'lastname' | translate}}:</label>
                        <input class="form-control" type="text" name="nom" value="{{data.nom}}" (change)="updateValueforChange($event.target.value,'nom')" />

                        <label class="fieldlabels">{{'num_origine' | translate}}:</label>
                        <input class="form-control" type="text" name="numero_origine" value="{{data.numero_origine}}" (change)="updateValueforChange($event.target.value,'numero_origine')" />

                        <label class="fieldlabels">{{'redaction' | translate}}:</label>
                        <textarea style="height: 130px;" class="form-control" value="{{data.redaction}}" (change)="updateValueforChange($event.target.value,'redaction')"></textarea>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-md-6 p-1 col-sm-12">
                        <!-- <label class="fieldlabels">Type d'identification (expéditeur):</label>
                        <select class="custom-select" id="inputGroupSelect01" (change)="updateValueforChange($event.target.value,'type_identf_expediteur')">
                          <option value="1">{{data.type_identf_expediteur}}</option>
                        </select> -->
                      </div>

                      <div class="col-md-6 p-1 col-sm-12">
                        <!-- <label class="fieldlabels">Numéro identification (expéditeur):</label>
                        <input class="form-control" type="text" name="nom" value="{{data.num_identif_expediteur}}" (change)="updateValueforChange($event.target.value,'num_identif_expediteur')" /> -->
                      </div>
                    </div>
                  </div>
                </fieldset>

               </li>

               <li class="tab-content tab-content-4 "><br><br>

                 <div class="card l-bg-blue-dark m-auto text-center" style="min-height: 300px;margin: auto;">

                   <div class="card-statistic-3 " >
                     <div class="card-icon card-icon-large m-auto text-center"><i class="fas fa-solid fa-hourglass-half"> </i><br>       <span style="background-color: rgb(209, 188, 147);" class="badge badge-sm bg-gradient-primary">{{data.workflow_state}}</span></div>


                   </div>
                   <div class="card-statistic-3 ">
                     <!-- <div class="card-icon card-icon-large" style="margin-left: 20px;"></div> -->
                     <h5  *ngIf="data.numero_dossier">
                       <span class="text-light text-center">
                        {{'num_folder' | translate}}:</span>
                     </h5><br>
                     <h5 *ngIf="data.numero_dossier != null; else msg" style="font-size: 12px;" class="text-wrap" >
                       <span style="background-color: rgb(209, 188, 147);margin: auto;" class="badge badge-sm "> {{data.numero_dossier}}</span> :
                       <span style="background-color: rgb(50,229,87);margin: auto;" class="badge badge-sm">{{data.status_du_dossier}}</span>
                     </h5>
                     <ng-template #msg >
                        <h5 class="m-auto text-center align-middle text-light" style="font-size: 12px;" >Aucun dossier lié à ce courrier</h5>
                     </ng-template>


                   </div><!--
                   <div class="card-statistic-3 ">
                     &lt;!&ndash; <div class="card-icon card-icon-large" style="margin-left: 20px;"></div> &ndash;&gt;
                     <h5 *ngFor="let inf of Information_Dossier">
                       <span style="background-color: rgb(209, 188, 147);margin: auto;" class="badge badge-sm "> {{inf.name}}</span> :
                       <span style="background-color: rgb(50,229,87);margin: auto;" class="badge badge-sm">{{inf.workflow_state}}</span>
                       <hr>
                     </h5>

                   </div>-->
                 </div>

               </li>

              <!-- <li class="tab-content tab-content-3 "><br>

                 <div class="card l-bg-blue-dark text-center" style="min-height: 300px;margin: auto;">
                   <div class="card-statistic-3 " >
                     <div class="card-icon card-icon-large m-auto text-center"><i class="fas fa-solid fa-hourglass-half"> </i><br>       <span style="background-color: rgb(209, 188, 147);" class="badge badge-sm bg-gradient-primary">{{data.workflow_state}}</span></div>


                   </div>
                   &lt;!&ndash; <div class="card-statistic-3 " style="margin: auto;">

                       <div class="card-icon card-icon-large" style="margin-left: 20px;"><i class="fas fa-solid fa-hourglass-half"></i></div>
                       <h5>
                         <span style="background-color: rgb(209, 188, 147);margin: auto;" class="badge badge-sm bg-gradient-primary">{{data.workflow_state}}</span>
                       </h5>

                   </div> &ndash;&gt;
                 </div>

               </li>-->

               <li class="tab-content tab-content-5 "><br>

                <fieldset>
                  <div class="form-card">
                    <div class="row">
                      <div class="col-md-5 col-sm-12">
                        <label class="fieldlabels">Description:</label>
                        <textarea style="height: 250px;" class="form-control" placeholder="Message....." (change)="updateValueforChange($event.target.value,'description_doc_fournis')">{{data.description_doc_fournis}}</textarea>
                      </div> &nbsp;&nbsp;

                      <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-start mt-3 mt-md-0">
                        <a *ngIf="Allow_Piece_Actions" (click)="openModalAddPiece()" class="text-secondary font-weight-bold text-xs float-right" data-toggle="tooltip" data-original-title="Edit user">
                          <i class="fas fa-solid fa-plus text-primary"></i> <span class="text-primary">Ajouter pièce</span>
                        </a>

                        <table class="table table-striped table-sm fieldlabels mt-3">
                          <thead>
                            <tr style="font-size: 12px;">
                              <th scope="col">#</th>
                              <th scope="col">{{'name_piece' | translate}}</th>
                              <th scope="col">{{'visualize' | translate}}</th>
                            </tr>
                          </thead>
                          <tbody style="font-size: 10px;">
                            <tr *ngFor="let piece of dataPiecesJointes">
                              <th scope="row">{{piece.name}}</th>
                              <td>{{piece.nom_du_fichier}}</td>
                              <td>
                                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user" (click)="openModalVisualPiece(piece)">
                                  <i class="fas fa-solid fa-eye text-primary" style="font-size: 20px;"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </fieldset>


               </li>

             </ul>
           </div>

           <span>

             <div class="addButton row m-auto d-flex justify-content-between flex-wrap col-12" >
               <span *ngFor="let action of workFlowsActionsAllowed" class="col-xl-4 col-md-4 col-lg-4 col-sm-12 mt-3 ">
                 <!-- <br> -->
               <div  class="button b2 btn-block m-auto bg-warning shadow mt-1   text-dark"  (click)="updateState(action.next_state)"  style="font-size: 12px;">
                  <i class="fas fa-solid fa-arrow-circle-right float-left mt-1" style="font-size: 15px;"></i>
                 {{action.action}}
                </div>
             </span>
             </div>

           </span>

           <span *ngIf="Allow_Piece_Actions==true && false" >

            <br> 
            <div class="addButton row m-auto " >
                <div class="col mt-1" *ngIf="Allow_send_To_President" class="button b2 btn-block m-auto bg-secondary  text-light"  (click)="updateState('President du tribunal')">
                  <i class="fas fa-solid fa-arrow-alt-circle-right float-left mt-1" style="font-size: 20px;"></i>{{'give_to_president' | translate}}</div>

               <div class="col mt-1" *ngIf="Allow_send_To_Greffe" class="button b2 btn-block m-auto bg-warning   text-light"  (click)="updateState('Greffier')">
                  <i class="fas fa-solid fa-arrow-circle-left float-left mt-1" style="font-size: 20px;"></i>{{'give_to_gref' | translate}}</div>

               <div class="col mt-1" *ngIf="Allow_send_To_Procureur" class="button b2 btn-block m-auto bg-success  text-light"  (click)="updateState('Secrétariat du procureur')">
                <!-- <div class="col mt-1" *ngIf="Allow_send_To_Procureur" class="button b2 btn-block m-auto bg-success  text-light"  (click)="updateState('procureur')"> -->

                  <i class="fas fa-solid fa-arrow-up float-left mt-1" style="font-size: 20px;"></i>{{'give_to_procureur' | translate}}</div>
              </div>


             <div class="addButton m-auto text-center"  *ngIf="Allow_send_To_External">
                <div class="button b2 bg-dark text-light m-auto text-center" (click)="updateState('Expédiée')">
                   <i class="fas fa-solid fa-save float-left mt-1" style="font-size: 20px;"></i> {{'make_expedier' | translate}}
                </div>
             </div>
           </span>


         </div>
  
       </div>

     </body>
   </html>
