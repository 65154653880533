import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import {EditorConfig, ST_BUTTONS} from 'ngx-simple-text-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { JugementsService } from '../../services/frapper/jugements.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { GreffierService } from '../../services/greffier/greffier.service';


@Component({
  selector: 'app-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['./edit-text.component.scss']
})
export class EditTextComponent {

  content_default:any = "";
  @Input() showTypeData: string;
  @Input() data_factas: any;
  @Input() data_qualites: any;
  data_noteAudience: any;
  @Input() parentModal: BsModalRef;
  data: any;
  contenuSaisi: any;

  content_qualite:any = "" ;
  content_factas: any = "";

  CAN_EDIT_FACTAS = false;
  CAN_EDIT_QUALITE = false;
  CAN_EDIT_NOTE = false;
  CAN_SAVE = false;
  editable:any;

  profil

  constructor(private modalService: BsModalService,public options: ModalOptions,
    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
    private greffierService: GreffierService,
    private jugement:JugementsService,
    private datePipe: DatePipe,
    private role:EnrollementService,
    private penalService: PenalCourrierService,private enrollementService: EnrollementService) {

      this.data_noteAudience = options.initialState.data
      this.editable = options.initialState.is_editable;

      console.log('iseditable=>', this.CAN_EDIT_NOTE);
      
    this.profil = localStorage.getItem('profil')
  }
//   this.content_qualite =  `
//   <div style="border: 2px solid rgb(169, 167, 167); padding: 10px; ">
//   <section class="d-flex justify-content-between first">
//     <div style="border: 1px solid rgb(169, 167, 167); padding: 10px;" class="col col-md-5 col-lg-5 col-sm-5 first-left" >
//       <p>République du sénégal</p>
//       <p>un peuple - un but - une foi</p>
//       <p>-----------------</p>
//       <p><b>Cour d'appel de DAKAR</b></p>
//       <P><b>Tribunal d'instance pikine/guediawaye</b></P>
//     </div>

//     <div style="border: 1px solid rgb(169, 167, 167); padding: 10px;" class="col col-md-7 col-lg-7 col-sm-7 first-right text-center">
//     <br><br>
//       <h5>Qualité</h5>
//       <h5>Audience du ${this.data_qualites.date_de_jugement != null ? this.getFormattedDate(this.data_qualites.date_de_jugement) :''}</h5>
//     </div>
//   </section>
//   </div>
//   <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
//   <section class="d-flex justify-content-between second mycontent">
//   <div class="d-flex justify-content-between flex-column second mycontent">
//   <span>Nom du plaignant: <strong>${ this.data_qualites.nom_complet_plaignant != null ?  this.data_qualites.nom_complet_plaignant :''}</strong> </span>
//   <span>Avocat du plaignant: <strong>${ this.data_qualites.avocat_du_plaignant!= null ?  this.data_qualites.avocat_du_plaignant:''}</strong> </span>
//   </div>

//   <div class="d-flex justify-content-between flex-column second mycontent">
//   <span>Nom du poursuivis: <strong>${ this.data_qualites.nom_complet_poursuivis != null ?  this.data_qualites.nom_complet_poursuivis :''}</strong> </span>
//   <span>Avocat du poursuivis: <strong>${ this.data_qualites.avocat_du_poursuivis != null ?  this.data_qualites.avocat_du_poursuivis :''}</strong> </span>
//   </div>

//   </section><br><br>
//   <section>
//   ${[this.content_qualite]}
//   </section>
// // </div>
// `


 // EmpEcher l'utilisation du stylet
 onPointerDown(event: PointerEvent): void {
  // Vérifier si l'entrée provient d'un stylet
  if (event.pointerType === 'pen') {
    event.preventDefault();  // EmpEche la saisie avec le stylet
    alert('la saisie avec stylet est inaccessible dans cette zone, veillez utiliser le clavier SVP!')
    console.log('Saisie interdite avec le stylet.');
  }
}


  getFormattedDate(date): string {
    let res = date
    try{
      res =this.datePipe.transform(date, 'dd-MM-yyyy');
    }catch (e) {
      console.log('ERROR ON FORMAT ')
      res = date
    }
    return res
  }
  template_qualite: any


  gestionAutorisations(){
    console.log(this.profil)
    if(this.profil.toUpperCase()=='JUGE'){
      this.CAN_EDIT_QUALITE=false
      this.CAN_EDIT_FACTAS=true
      if(this.showTypeData!='qualites'){
        this.CAN_SAVE=true
      }else {
        this.CAN_SAVE=false
      }
    }

    if(this.profil.toUpperCase()== 'GREFFIER'){
      this.CAN_EDIT_FACTAS=false
      this.CAN_EDIT_QUALITE=true
      this.CAN_EDIT_NOTE = true
      if(this.showTypeData =='qualites'){
        this.CAN_SAVE=true
      }else {
        this.CAN_SAVE=false
      }
    }

    console.log(this.CAN_SAVE)
    console.log(this.CAN_EDIT_FACTAS)
    console.log(this.CAN_EDIT_QUALITE)
    console.log(this.CAN_EDIT_NOTE)

  }

  ngOnInit() {
    this.refreshValue()
     /*switch (this.showTypeData) {
      case 'factas':
        if(this.data_noteAudience.factum != null){
          this.content_factas = this.data_noteAudience.factum
        }else{
          this.content_factas =
            `
        <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
          <section class="d-flex flex-column justify-content-between second mycontent">
          <b class="text-center"> Factas </b>
         <span  class="text-center"> Audience du <strong> ${this.data_factas.date_de_jugement != null ? this.getFormattedDate(this.data_factas.date_de_jugement) :''}</strong> </span>

          <section>
          <p>1</p>
          </section>
        </div>
      `
        }

        break;

      case 'qualites':
        if(this.data_noteAudience.qualite != null){
          this.content_qualite =this.data_noteAudience.qualite
        }else {
          this.content_qualite =  `

          <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
          <section class="d-flex flex-column justify-content-between second mycontent">
          <b class="text-center"> Qualités </b>
          <span  class="text-center"> Audience du <strong > ${this.data_qualites.date_de_jugement != null ? this.getFormattedDate(this.data_qualites.date_de_jugement) :''}</strong> </span>

          <section>
            <p>1</p>
          </section>
        </div>
        `
        }
        // this.content_qualite.nom_complet_plaignant = `<strong>${this.data_qualites.nom_complet_plaignant}</strong>`;

        break;


      default:
        // this.greffierService.content_noteAudience.subscribe(donnees => {
        //   if (donnees) {
        //     this.content_default = donnees

        //   }
        //  });
        if(this.data_noteAudience.node_daudiences != null){
          this.content_default =this.data_noteAudience.node_daudiences
        }else{
          this.content_default =  `


            <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
            <section class="d-flex flex-column justify-content-between second mycontent">
            <span  class="text-center"> Notes d'audience du <strong > ${this.data_noteAudience.date_de_jugement != null ? this.getFormattedDate(this.data_noteAudience.date_de_jugement) :''}</strong> </span>
          </section><br><br>
            <section class="d-flex justify-content-between second mycontent">
            <div class="d-flex justify-content-between flex-column second mycontent">
            <span>Nom du plaignant: <strong>${ this.data_noteAudience.nom_complet_plaignant != null ?  this.data_noteAudience.nom_complet_plaignant :''}</strong> </span>
            <span>Avocat du plaignant: <strong>${ this.data_noteAudience.avocat_du_plaignant!= null ?  this.data_noteAudience.avocat_du_plaignant:''}</strong> </span>
            </div>

            <div class="d-flex justify-content-between flex-column second mycontent">
            <span>Nom du poursuivis: <strong>${ this.data_noteAudience.nom_complet_poursuivis != null ?  this.data_noteAudience.nom_complet_poursuivis :''}</strong> </span>
            <span>Avocat du poursuivis: <strong>${ this.data_noteAudience.avocat_du_poursuivis != null ?  this.data_noteAudience.avocat_du_poursuivis :''}</strong> </span>
            </div>

            </section><br><br>
            <section>
            <p>1</p>
            </section>
          </div>
          `
        }

         console.log({'default note': this.content_default});

        break;
    }*/
  }

  refreshValue(){
    this.ngxService.start()
    this.gestionAutorisations()
    this.jugement.getOneJugement(this.data_noteAudience.name).then(res=>{
      try {
        this.data_noteAudience = res.data
      } catch (error) {
        this.ngxService.stop()
      }
      
      switch (this.showTypeData) {
        case 'factas':
          if(this.data_noteAudience.factum != null){
            this.content_factas = this.data_noteAudience.factum
          }else{
            this.content_factas =
              `
        <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
          <section class="d-flex flex-column justify-content-between second mycontent">
          <b class="text-center"> Factas </b>
         <span  class="text-center"> Audience du <strong> ${this.data_factas.date_de_jugement != null ? this.getFormattedDate(this.data_factas.date_de_jugement) :''}</strong> </span>

          <section>
          <p>1</p>
          </section>
        </div>
      `
          }

          break;
        case 'qualites':
          if(this.data_noteAudience.qualite != null){
            this.content_qualite =this.data_noteAudience.qualite
          }else {
            this.content_qualite =  `

          <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
          <section class="d-flex flex-column justify-content-between second mycontent">
          <b class="text-center"> Qualités </b>
          <span  class="text-center"> Audience du <strong > ${this.data_qualites.date_de_jugement != null ? this.getFormattedDate(this.data_qualites.date_de_jugement) :''}</strong> </span>

          <section>
            <p>1</p>
          </section>
        </div>
        `
          }
          // this.content_qualite.nom_complet_plaignant = `<strong>${this.data_qualites.nom_complet_plaignant}</strong>`;

          break;
        default:
          if(this.data_noteAudience.node_daudiences != null){
            this.content_default =this.data_noteAudience.node_daudiences
          }else{
            this.content_default =  `

            <div style="border: 2px solid rgb(169, 167, 167); padding: 10px;min-height: 500px;">
            <section class="d-flex flex-column justify-content-between second mycontent">
            <span  class="text-center"> Notes d'audience du <strong > ${this.data_noteAudience.date_de_jugement != null ? this.getFormattedDate(this.data_noteAudience.date_de_jugement) :''}</strong> </span>
          </section><br><br>
            <section class="d-flex justify-content-between second mycontent">
            <div class="d-flex justify-content-between flex-column second mycontent">
            <span>Nom du ${this.data_noteAudience.classification=='Pénale'?'plaignant':'demandeur'}: <strong>${ this.data_noteAudience.nom_complet_plaignant != null ?  this.data_noteAudience.nom_complet_plaignant :''}</strong> </span>
            <span>Avocat du ${this.data_noteAudience.classification=='Pénale'?'plaignant':'demandeur'}: <strong>${ this.data_noteAudience.avocat_du_plaignant!= null ?  this.data_noteAudience.avocat_du_plaignant:''}</strong> </span>
            </div>

            <div class="d-flex justify-content-between flex-column second mycontent">
            <span>Nombre de ${this.data_noteAudience.classification=='Pénale'?'poursuivis':'demandé'}: <strong> 1 </strong> </span>
            <span>Nom du ${this.data_noteAudience.classification=='Pénale'?'poursuivis':'demandé'} : <strong>${ this.data_noteAudience.nom_complet_poursuivis != null ?  this.data_noteAudience.nom_complet_poursuivis :''}</strong> </span>
            <span>Avocat du ${this.data_noteAudience.classification=='Pénale'?'poursuivis':'demandé'}: <strong>${ this.data_noteAudience.avocat_du_poursuivis != null ?  this.data_noteAudience.avocat_du_poursuivis :''}</strong> </span>
            </div>

            </section><br><br>
            <section>
             <span>Sur la Comparution: <strong>(Comparait / Ne Comparait pas)</strong> </span>
             <br>
             <span>Identités des témoins : <strong>. . . . . </strong></span>
            </section><br><br>

            <section>
            <p>Notes :</p>
            </section>
          </div>
          `
          }

          console.log({'default note': this.content_default});

          break;
      }
      this.ngxService.stop()
    },err=>{
      // this.alerte.errorNotification('Un probleme est survenu')
      this.ngxService.stop()
    })
  }
  save(type: string){

    this.ngxService.start()
    let body ={
    }
    switch (type) {
      case 'factas':
        console.log({'contenSaisi': this.content_factas});
        body ={
          "factum":this.content_factas
        }
        break;

      case 'qualites':
        console.log({'contenSaisi': this.content_qualite});
        body ={
          "qualite":this.content_qualite
        }
        break;

      default:
        console.log({'contenSaisi': this.content_default});
        body ={
          "node_daudiences":this.content_default
        }
        break;
    }


    console.log('Body Update ==> ',body)
    this.jugement.updateJugement(this.data_noteAudience.name,body).then(res=>{
      this.alerte.successNotification('Sauvegarde','Mise a jour complete')
      this.data_noteAudience = res.data
      this.content_qualite = this.data_noteAudience.factum
      this.content_factas = this.data_noteAudience.qualite
      this.content_default = this.data_noteAudience.node_daudiences
      this.refreshValue()
    },err =>{
      this.alerte.errorNotification('Editeur','Erruer lors de la mise a jour des notes')
      this.ngxService.stop()
    })



      // this.ngxService.start()
      // if(this.parentModal != null)
      //   this.parentModal.hide()
      // this.ngxService.stop()

  }


  // config: EditorConfig = {
  //   placeholder: 'vos notes...',
  //   buttons: ST_BUTTONS,
  // };
}
