import { DatePipe } from '@angular/common';
import {Component, OnInit} from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssignedService } from '../../services/frapper/assigned.service';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { GreffierService } from '../../services/greffier/greffier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { CalendarComponent } from '../../widgets/calendar/calendar.component';

@Component({
  selector: 'app-demande-acces',
  templateUrl: './demande-acces.component.html',
  styleUrls: ['./demande-acces.component.scss']
})
export class DemandeAccesComponent implements OnInit{
  data:any;
  type: any;

  list_users:any  = [
    {
      name: 'Mark Otto',
      email: 'mark.otto@example.com',
    },
    {
      name: 'Jacob Thornton',
      email: 'jacob.thornton@example.com',
    },
    {
      name: 'LARRY John',
      email: 'larry.john@example.com',
    }
  ]

  profil: any = null;


  constructor(
    private _formBuilder: FormBuilder,
    private courrierService: UsercourrierService,
    private assignService: AssignedService,
    private options: ModalOptions,
    private datePipe: DatePipe,
    private ngxService: NgxUiLoaderService,
    private penalCourrierService: PenalCourrierService,
    private greffierService: GreffierService,
    private bsModalRef: BsModalRef,
    private penalService: PenalCourrierService,private enrollementService: EnrollementService,
    private alerte: AlertesService,
    public bsfModal: BsModalService,
  ) {
    this.data = this.options.initialState.num_dossier;
    this.type = this.options.initialState.type;

    this.profil = JSON.parse(localStorage.getItem('me'));

  }
  ngOnInit(): void {
    this.getUsers();
  }
  closeModal(){
    this.bsModalRef.hide()
  }
  async getUsers(){
    this.ngxService.start('GetUSER')
    this.list_users= []
    this.type = this.type? this.type:'Dossier'
    this.assignService.getAssignedAnOwnerUser(this.type,this.data).then(res =>{
      console.log(res)
      let assigned_users = (res.message)[0]
      this.list_users = [
        {'fullname':'', 'email':assigned_users['owner'], 'type':'creator','Profil':''}
      ]
      try{
        assigned_users = JSON.parse(assigned_users["_assign"])
        for( let assigned_user of assigned_users){
          this.list_users.push({
            'fullname':'', 'email':assigned_user, 'type':'actor','Profil':''
          })
        }
      }catch (e) {
        assigned_users=[]
      }



      /* // ADD API to get More detail about a user
      for (let view_user of this.usersAllowedAcce){
         this.userService.getOneUserDetail(view_user.email).then( res =>{
           console.log('res ==> ',res)
         })
       }*/
      console.log('Assigned USer ==> ', assigned_users)
      this.ngxService.stop('GetUSER')
    },err=>{
      console.log('Assigned USer err ==> ', err)
      this.ngxService.stop('GetUSER')
    })
  }

  getFormattedDate(date): string {
    let res = date
    try{
      res =this.datePipe.transform(date, 'dd-MM-yyyy');
    }catch (e) {
      // console.log('ERROR ON FORMAT ')
      res = date
    }
    return res
  }

  /*getUsers() {
    return this.list_users;
  }*/

  submitRequestAcce(_data: NgForm){
    this.ngxService.start()
    let body= _data.value
    console.log(this.profil)
    body['request_sender_id'] = this.profil.name
    body['request_sender_name'] = this.profil.full_name
    body['request_profil'] = localStorage.getItem('profil')
    body['numero'] = this.data
    body['type'] = this.type

    this.assignService.nouvelleDemandeAccess(body).then(res =>{

      this.alerte.successNotification('Autorisation','Demande envoyée avec succès')
      let validator =[]
      for(let to_assign of this.list_users){
        validator.push(to_assign.email)
      }
       this.assignService.doAssignation(this.assignService.Doctype_Demmande_access,res.data.name, validator).then(()=>{
        this.closeWithMessage('succes')
       })
      this.ngxService.stop()
    },err =>{
      this.alerte.errorNotification('Autorisation','Echec de l\'envoi de la demande')
      this.ngxService.stop()
    })
  }



  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }



}
