<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>

<body style="min-height: 600px; overflow: scroll;">
  <div class="d-flex justify-content-center">
    <h5 id="heading" >Liste des notes</h5>
    <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
      <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
    </button>
  </div>
  <!-- <div class="d-flex justify-content-center flex-column ">
    <h1 m-auto align-items-center>hasalp</h1>
    <div *ngFor="let item of list_notes" class="m-auto align-items-center ">
      <p>{{item.libelle}}</p>
    </div>
  </div> -->

  
  <div class="d-flex flex-column ">
    <div class="actionBtn text-center mt-2 ">
      <div  class="addButton float-right mr-4  " >
        <div class="button b1 text-nowrap" (click)="openModalAddNote()" style="font-size: 11px;">Ajouter une note</div>
      </div>
  
    </div>

    <div class="container-fluid">
      <ul class="d-flex flex-wrap justify-content-start ">
      
        <li *ngFor="let item of list_notes  | paginate: { itemsPerPage: 12, currentPage: pageNotes, id: 'notes' }" class="col-xs-10 col-sm-10 col-md-4 col-lg-3 col-xl-3 ">
          <div class="card card-green col-md-12" (click)="openModalDetailNote(item)">
            <p class="card-question">{{item?.titre}}</p>
            <!-- <div class="card-separator"></div> -->
            <!-- <p style="text-align:center;">{{item.date}}</p> -->
            <div class="card-separator"></div>
            <a class="more">voir</a>
            
          </div>
        </li>
       
      </ul>
      <pagination-controls  (pageChange)="pageNotes = $event" previousLabel="" nextLabel=""
      responsive="true" [id]="'note'"></pagination-controls>
    </div>
  </div>
  
  <ngx-ui-loader></ngx-ui-loader>

</body>
</html>
