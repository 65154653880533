


<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">
   
      
        <!-- formulaire courrier entrant -->
          <div class="card" >
              <h5 id="heading">Nouveau {{type}}</h5>
              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform"  #rapportAppel="ngForm" (submit)="addRequisitoireOrOrdonanceAsRapport(rapportAppel)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Informations</strong></li>
                     

                  </ul>
                 <br> <!-- fieldsets -->
                  <fieldset class="step1">
                      <div class="form-card">
                          <div class="row">
                              <!-- <div class="col-7">
                                  <h2 class="fs-title">Account Information:</h2>
                              </div>
                              <div class="col-5">
                                  <h2 class="steps">Step 1 - 4</h2>
                              </div> -->
                          </div>
                          <div class="row col-md-8 col-lg-8 m-auto">
                            <div class="col">

                              <label class="fieldlabels">Destinataire: *</label>
                                  <select  class="custom-select" id="inputGroupSelect01" name="destinataire"  ngModel required>
                                    <option value="" selected disabled hidden>--Choisir le destinataire--</option>
                                    <option selected="selected" *ngFor="let desti of ALL_DESTINATAIRE" value="{{desti}}">{{desti}}</option>
                                  </select>

                                  <textarea style="height: 250px;" class="form-control" id="inputGroupSelect01"  name="information_de_la_reference"  ngModel></textarea>

                                  <!-- <div class="container">
            
                                    <div class="form cf">
                                      <section class="payment-plan cf row d-flex flex-row"   ngModel style="width: 100%;">
                                      <div class="col-4 rounded-circle" *ngFor="let tag of TYPE_DE_TAG;">
                                          <input type="radio"  (change)="onTagChange($event.target.value)" value="{{tag.name}}" name="tag" id="{{tag.name}}" >
                                          <label class="monthly-label four col" for="{{tag.name}}"><img src="https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg" width="50" height="50" alt="">
                                            <p style="font-size: 11px;">{{tag.name}}</p>
                                          </label>
                                      </div>
                                    </section>
                                    </div>
                                    &nbsp;&nbsp;
                                </div>
                               -->
                              <!-- <label class="fieldlabels">Tag: *</label>
                                  <select class="custom-select" name="tag" (change)="onTagChange($event.target.value)"  ngModel required>
                                    <option value="" selected disabled hidden>Select Tag</option>
                                    <option *ngFor="let tag of TYPE_DE_TAG" value="{{tag.name}}">{{tag.name}}</option>
                                  </select> -->
                              
                            </div>&nbsp;&nbsp;
                            <!-- <div class="col d-flex  flex-column justify-content-between " >
                           
                        

                            </div> -->
                          </div>


                      </div> <input type="button" name="next" class="next action-button" value="Enregistrer" />
                  </fieldset >

                  
              </form>
          </div>


      </div>
  </div>
</div>


