// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-view-details-enrollement',
//   templateUrl: './view-details-enrollement.component.html',
//   styleUrls:['./view-details-enrollement.component.scss']
// })
// export class ViewDetailsEnrollementComponent {}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { ViewDetailsDossierComponent } from '../view-details-dossier/view-details-dossier.component';
import { CalendarComponent } from '../../widgets/calendar/calendar.component';
import { ViewDetailsJugementComponent } from '../view-details-jugement/view-details-jugement.component';
import { DatePipe } from '@angular/common';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ViewDetailsChambreComponent } from '../view-details-chambre/view-details-chambre.component';
import { da } from 'date-fns/locale';
// import { DetailViewComponent } from '../../widgets/detailsview/detailView.component';


@Component({
  selector: 'app-view-details-enrollement',
  templateUrl: './view-details-enrollement.component.html',
  styleUrls:['./view-details-enrollement.component.scss']
})
export class ViewDetailsEnrollementComponent {

  data:any;

  enrollements: any[] = [];

 dossier:any;


  constructor(private options: ModalOptions, private penalCourrierService: PenalCourrierService,
    private penalService: PenalCourrierService,
    private enrollementService: EnrollementService,
    private ngxService: NgxUiLoaderService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService, private datePipe: DatePipe,) {
    this.data = this.options.initialState.data;
    console.log('enroles=>',this.data);
    // this.type = this.options.initialState.type;

  }

  openModalDetails( ) {
    // let courrier:any = this.courriers[id];
    console.log('details detail dosss === '+ this.dossier);

    const config: ModalOptions = {
      initialState: {
        data: this.dossier,
    }
     }
    const modalRef = this.modalService.show(ViewDetailsDossierComponent, Object.assign({},config,{class:'modal-xl '}));

  }
  closeModal(){
    this.bsModalRef.hide()
  }

  async getAllChambres(chambre_:string) {
    //this.chambres = this.penalService.getChambres();

    this.ngxService.start();
    try {
     let chambres  = (await this.enrollementService.getAllMyChambre()).data
      const chambreRecherchee = chambres.find(chambre => chambre.name === chambre_);

      this.ngxService.stop();

      return chambreRecherchee
    } catch (error) {
      this.ngxService.stop();
    }

    //console.log({'the result': result});

  }


  async openModalCalendar(dataEnrole: any) {
    // let courrier:any = this.courriers[id];
    // this.penalService.setSelectedChambre(chambre);
    console.log({'data enrolle': dataEnrole});

    let _chambre = await this.getAllChambres(dataEnrole.chambre)
    console.log({'in search': _chambre});
    let v = this.penalService.setSelectedChambre(_chambre);
    console.log('details detail chmbre === '+ v);

    const config: ModalOptions = {
      initialState: {
        data: dataEnrole
    }
     }
    const modalRef = this.modalService.show(CalendarComponent, Object.assign({},config,{class:'modal-lg '}));

  }

  async openModalChambre(dataEnrole: any) {
    // let courrier:any = this.courriers[id];
    // this.penalService.setSelectedChambre(chambre);
    console.log({'data enrolle': dataEnrole});

    let _chambre = await this.getAllChambres(dataEnrole.chambre)
    console.log({'in search': _chambre});
    let v = this.penalService.setSelectedChambre(_chambre);
    console.log('details detail chmbre === '+ v);

    const config: ModalOptions = {
      initialState: {
        data: {name:dataEnrole.chambre}
    }
     }
    const modalRef = this.modalService.show(ViewDetailsChambreComponent, Object.assign({},config,{class:'modal-xl  modal-chambre'}));

  }

  getDossier(numero:any){
    // this.dossier = this.penalCourrierService.findDossier(numero);
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  openModalDetailJugement(data:any) {
    // let courrier:any = this.courriers[id];
    // console.log('details === '+ courrier);

    const config: ModalOptions = {
      backdrop: true,
      initialState: {
        data: data,
        concat_tribunal: true,

      }
     }
     const modalRef  = this.modalService.show(ViewDetailsJugementComponent, Object.assign({},config,{class:'modal-xl  '}));

  }





  ngOnInit() {
    this.enrollements = this.data;
    console.log({'enroles=>:':this.data});
    
    this.getDossier(this.data.numero)


  }
}

