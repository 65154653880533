
   <!DOCTYPE html>
   <html>
     <head>
       <meta charset="utf-8">
       <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
     <!-- <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css" /> -->
     </head>
     <body class="page">

       <div >

        <div class="container ">
          <div class="container">
            <div class="w-100 text-center">
                <h5 id="heading" style="font-size: 14px;" class="text-light">
                    {{'folder' | translate}}
                    <span style="color: {{textColor}};"><b class="text-light">{{data.numero_courrier}}</b></span>
                    <span *ngIf="data.workflow_state=='Non lieu' || data.workflow_state =='Sans suite' || data.workflow_state=='Enrolement'">
                        [ <span style="color: {{textColor}};"><b class="text-light">{{data.workflow_state}}</b></span> ]
                    </span>
                </h5>
                <button type="button" class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()">
                    <i class="fas fa-times text-light" style="font-size: 1.5rem;"></i>
                </button>
            </div>

            <div class="d-flex flex-column flex-wrap">
                <div class="d-flex justify-content-start flex-wrap">
                    <button class="col-md-3 col-lg-3 col-sm-12 btn btn-outline-success float-right mb-2 ml-2" (click)="openModalResumedossier()">
                        <span class="text-end ml-2" style="font-size: 11px;">
                            <b><span class="text-light text-nowrap">{{'composition_du_dossier' | translate}}</span></b>
                        </span>
                    </button>

                    <button class="col-md-3 col-lg-3 col-sm-12 btn btn-outline-primary float-right mb-2 ml-2" (click)="openModalActionsdossier()">
                        <span class="text-end ml-2" style="font-size: 11px;">
                            <b><span class="text-light text-nowrap">{{'audit_des_actions' | translate}}</span></b>
                        </span>
                    </button>
                    <button class="col-md-3 col-lg-3 col-sm-12 btn btn-outline-warning float-right mb-2 ml-2" (click)="openModalListNotes(data)">
                      <span class="text-end ml-2" style="font-size: 11px;">
                          <b><span class="text-light text-nowrap"><i class="fas fa-solid fa-pen text-light" style="font-size: 15px;"></i> Notes</span></b>
                      </span>
                  </button>

                </div>

                <div class="d-flex flex-wrap justify-content-between align-items-end">
                    <div class="d-flex flex-wrap w-100 justify-content-between">
                        <div class="addButton mb-2" *ngIf="CAN_SAVE">
                            <div class="button b2 text-light" style="background:#f3a14c !important; width: 70px;" (click)="updateValue()">
                                <i class="fas fa-solid fa-save float-left mt-1" style="font-size: 20px;"></i>
                            </div>
                        </div>

                        <div class="addButton mb-2" *ngIf="IS_TRANSFERABLE">
                            <div class="button b3" style="width: 140px;" (click)="openModalTransmission(data, 'Dossier')">
                                <i class="fas fa-regular fa-paper-plane float-left" style="font-size: 20px;"></i> <b>{{'st' | translate}}</b>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-xl-3 d-flex flex-wrap justify-content-end">
                            <div class="addButton mb-2 float-right" *ngIf="IS_ENROLABLE" style="padding: 0 5px 0 0;">
                                <div class="button b1" (click)="openModalEnrollement('dossier', 'second', data)">
                                    <i class="fas fa-solid fa-check float-left"></i> {{'enroler' | translate}}
                                </div>
                            </div>

                            <div class="addButton mb-2" *ngIf="IS_CLASSABLE" style="padding: 0 5px 0 0;">
                                <div class="button b2" *ngIf="nbr_ordonances <= 1" (click)="ClasserSansSuite('Sans suite')">
                                    <i class="fas fa-solid fa-ban float-left"></i> {{'sans_suite' | translate}}
                                </div>
                                <div class="button b2" *ngIf="nbr_ordonances > 1" (click)="ClasserSansSuite()">
                                    <i class="fas fa-solid fa-ban float-left"></i> {{'non_lieu' | translate}}
                                </div>
                            </div>

                            <div class="addButton mb-2" *ngIf="CAN_DO_AVIS">
                              <div class="button b2" (click)="openModalAddRequisitoire('Ordonance',1)">
                                <i class="fas fa-solid fa-mail-bulk float-left"></i> Mon Avis
                              </div>
                            </div>
                            <div class="addButton mb-2" *ngIf="CAN_DO_ORDONANCE">
                                <div class="button b2" (click)="openModalAddRequisitoire('Ordonance')">
                                    <i class="fas fa-solid fa-mail-bulk float-left"></i> {{'ordonnances' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



          <!-- tabs -->
          <div class="folder_pcss3t folder_pcss3t-effect-scale folder_pcss3t-theme-1">
            <input type="radio" name="folder_pcss3t" checked  id="_tab1" class="_tab-content-first">
            <label for="_tab1">{{'elt_folder' | translate}}</label>

            <input type="radio" name="folder_pcss3t" id="_tab2" class="_tab-content-2">
            <label for="_tab2">{{'inf_parties' | translate}}</label>

            <input type="radio" name="folder_pcss3t" id="_" class="_tab-content-5">
            <label for="_"><i class="icon-cogs"></i>{{'etat_du_dossier' | translate}}</label>

            <input type="radio" name="folder_pcss3t" id="_tab3" class="_tab-content-3">
            <label for="_tab3">{{'details' | translate}}</label>

            <input type="radio" name="folder_pcss3t" id="_tab4" class="_tab-content-4">
            <label for="_tab4">{{'inf_redacteur' | translate}}</label>

            <input type="radio" name="folder_pcss3t" id="_tab6" class="_tab-content-6">
            <label for="_tab6">{{'others' | translate}}</label>




            <!--
            <input type="radio" name="folder_pcss3t" id="tab5" class="_tab-content-last">
            <label for="tab5"><i class="icon-globe"></i>Newton</label> -->

            <ul style="min-height: 450px;"  >
              <li class="_tab-content _tab-content-first typography d-flex flex-column" style="overflow: auto;">
               <br>

               <fieldset>

                <div class="form-card">
                  <div class="container">

                    <div class="row">
                      <!-- Section pour éléments internes -->
                      <div class="col-12 col-lg-7" style="padding: 0 5px 0 0;">
                        <div class="mb-2 bg-secondary text-light">
                            <label class="fieldlabels" style="font-size: 12px;">{{'elements_internes' | translate}}:</label>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped fieldlabels" style="font-size: 11px;">
                                <thead>
                                    <tr>
                                        <th scope="col">{{'type' | translate}}</th>
                                        <th scope="col">{{'emetteur' | translate}}</th>
                                        <th scope="col">{{'visualize' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of list_requisitoire_ordonances | paginate: { itemsPerPage: 4, currentPage: listRequisitions, id: 'requisitions' }">
                                        <td style="font-size: 10px;">{{item.tag}}</td>
                                        <td>{{item.owner}}</td>
                                        <td>
                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalVisualrequistion(item)" data-toggle="tooltip" data-original-title="{{'visualiser' | translate}}">
                                                <i class="fas fa-solid fa-eye text-primary" style="font-size: 15px;"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination-controls (pageChange)="listRequisitions = $event" previousLabel="" nextLabel="" responsive="true" [id]="'requisitions'" style="width: 100%;"></pagination-controls>
                    </div>


                      <!-- Section pour pièces du dossier -->
                      <div class="col-12 col-lg-5 d-flex flex-column" style="font-size: 11px;">
                        <div class="d-flex justify-content-between bg-secondary text-light mb-2">
                          <label class="fieldlabels">{{'piece_folder' | translate}}:</label>
                          <a *ngIf="CAN_ADD_PIECE" (click)="openModalAddPiece()" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Ajouter pièce">
                            <i class="fas fa-solid fa-plus text-success"></i> <span class="text-success"> {{'add_piece_folder' | translate}}</span>
                          </a>
                        </div>
                        <div class="table-responsive">
                          <table class="table table-striped fieldlabels mt-1">
                            <thead>
                              <tr class="text-nowrap">
                                <th scope="col">#</th>
                                <th scope="col">{{'name_piece' | translate}}</th>
                                <th scope="col">{{'num_courrier' | translate}}</th>
                                <th scope="col">{{'visualize' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let piece of InternalPieces | paginate: { itemsPerPage: 4, currentPage: listPieces, id: 'pieces' }">
                                <th scope="row">{{piece.name}}</th>
                                <td>{{piece.nom_du_fichier}}</td>
                                <td><p style="font-size: 10px;" class="texte-limite">{{piece.parent}}</p></td>
                                <td>
                                  <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalVisualPiece(piece)" data-toggle="tooltip" data-original-title="Visualiser">
                                    <i class="fas fa-solid fa-eye text-primary" style="font-size: 15px;"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <pagination-controls (pageChange)="listPieces = $event" previousLabel="" nextLabel="" responsive="true" [id]="'pieces'" style="width: 100%;"></pagination-controls>
                      </div>

                    </div>

                    <!-- Section pour remarques du procureur -->
                    <div class="d-flex justify-content-between">
                      <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8" *ngIf="CAN_SEE_REMARQUE_PROCUREUR">
                        <label class="fieldlabels">{{'instruction_procureur' | translate}}:</label>
                        <input type="text" class="form-control" [readOnly]="CAN_NOT_CHANGE_REMARQUE_PROCUREUR" (change)="updateValueforChange($event.target.value, 'rm_proc')" name="rm_proc" value="{{data.rm_proc}}" />

                        <label class="fieldlabels mt-2">{{'remarque_procureur' | translate}}:</label>
                        <textarea class="form-control" [readOnly]="CAN_NOT_CHANGE_REMARQUE_PROCUREUR" (change)="updateValueforChange($event.target.value, 'remarque_du_procureur')" name="remarque_du_procureur" style="height: 120px;">{{data.remarque_du_procureur}}</textarea>
                    </div>


                    </div>
                  </div>
                </div>
              </fieldset>

              </li>

              <li class="_tab-content _tab-content-2 typography">
                <fieldset >

                  <div class="form-card">
                    <!-- <div class="row">
                      <div class="filterselect  mt-2 col-xl-5 col-lg-5 col-md-5 m-auto">
                        <label class="select">
                          <select (change)="selectTypePrevenu($event.target.value)">
                            <option>--Choisir le nombre de prevenu--</option>
                            <option [value]="0">Un seul prevenu</option>
                            <option [value]="1">Plusieurs prevenus</option>
                          </select>
                        </label>


                      </div>
                    </div> -->

                    <div class="row">
                      <ng-container >
                        <div class="col-12 col-md-6" style="padding: 0 5px 0 0;">
                          <div style="visibility: hidden;">
                            <label class="fieldlabels">{{'num_identif_plaignant' | translate}}: </label>
                            <input type="text" class="form-control" name="numéro_didentification_plaignant" ngModel/>
                          </div>
                          <label class="fieldlabels">{{'type_identification' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'type_identification_plaignant')" name="type_identification_plaignant" value="{{data.type_identification_plaignant}}" />

                          <label class="fieldlabels">{{'num_identif' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'numéro_didentification_plaignant')" name="numéro_didentification_plaignant" value="{{data['numéro_didentification_plaignant']}}" />

                          <label class="fieldlabels">{{'full_name' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_du_plaignant')" name="nom_complet_du_plaignant" value="{{data.nom_complet_du_plaignant}}" />

                          <label class="fieldlabels">{{'lieu_naissance' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'lieu_de_naissance_du_plaignant')" name="lieu_de_naissance_du_plaignant" value="{{data.lieu_de_naissance_du_plaignant}}" />

                          <label class="fieldlabels">{{'date_naissance' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="date" class="form-control" (change)="updateValueforChange($event.target.value,'date_de_naissance_du_plaignant')" name="date_de_naissance_du_plaignant" value="{{data.date_de_naissance_du_plaignant}}" />

                          <label class="fieldlabels">{{'adresse_complete' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'addresse_complete_du_plaignant')" name="addresse_complete_du_plaignant" value="{{data.addresse_complete_du_plaignant}}" />

                          <label class="fieldlabels">{{'nom_complet_mere' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_mere_plaignant')" name="nom_mere_plaignant" value="{{data.nom_complet_de_la_mere_du_plaignant}}" />

                          <label class="fieldlabels">{{'nom_complet_pere' | translate}} du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_pere_plaignant')" name="nom_pere_plaignant" value="{{data.nom_complet_du_pere_du_plaignant}}" />

                        </div>
                        <div  class="col-md-6 mb-3 d-flex flex-column justify-content-between mt-1" style="padding: 0 5px 0 0;">
                            <div class="d-flex mb-3" style="padding: 0 5px 0 0;" >
                                <label class="fieldlabels mt-4">{{'active_many_prevenus' | translate}} </label>
                                <div class="toggle-wrapper">
                                    <input class="toggle-input" type="checkbox" (change)="selectTypePrevenu($event)" id="pin-toggle"/>
                                    <label class="toggle-container" for="pin-toggle">
                                        <div class="toggle-circle">
                                            <div class="pin-icon">
                                                <span class="pin-top"></span>
                                                <span class="pin-middle"></span>
                                                <span class="pin-bottom"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <ng-container *ngIf="is_multiple_prevenu == 0; else secondBlock">
                              <label class="fieldlabels">{{'type_identif' | translate}} du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'type_identification_du_poursuivis')" name="type_identification_du_poursuivis" value="{{data.type_identification_du_poursuivis}}" />

                              <label class="fieldlabels">{{'num_identif' | translate}} du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'numéro_didentification_du_poursuivis')" name="numéro_didentification_du_poursuivis" value="{{data['numéro_didentification_du_poursuivis']}}" />

                              <label class="fieldlabels">{{'fullname' | translate}}  du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_du_poursuivis')" name="nom_complet_du_poursuivis" value="{{data.nom_complet_du_poursuivis}}" />

                              <label class="fieldlabels">{{'lieu_naissance' | translate}}  du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'lieu_de_naissance_du_prévenu')" name="lieu_de_naissance_du_prévenu" value="{{data['lieu_de_naissance_du_prévenu']}}" />

                              <label class="fieldlabels">{{'date_naissance' | translate}} du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="date" class="form-control" (change)="updateValueforChange($event.target.value,'date_de_naissance_du_prévenu')" name="date_de_naissance_du_prévenu" value="{{data['date_de_naissance_du_prévenu']}}" />

                              <label class="fieldlabels">{{'adresse_complete' | translate}} du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'addresse_complete_du_poursuivis')" name="addresse_complete_du_poursuivis" value="{{data['addresse_complete_du_poursuivis']}}" />

                              <label class="fieldlabels">{{'nom_complet_mere' | translate}} du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_de_la_mere_du_prévenu')" name="nom_complet_de_la_mere_du_prévenu" value="{{data['nom_complet_de_la_mere_du_prévenu']}}" />

                              <label class="fieldlabels">{{'nom_complet_pere' | translate}} du {{data.classification=='Pénale'?'prévenu':'defenseur'}} :</label>
                              <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_du_pere_du_prévenu')" name="nom_complet_du_pere_du_prévenu" value="{{data['nom_complet_du_pere_du_prévenu']}}" />
                            </ng-container>
                            <ng-template #secondBlock>
                              <label><b class="bg-dark text-light">{{'list_prevenus' | translate}}:</b>&nbsp;&nbsp;
                                <i id="addRowBtn" class="fa fa-solid fa-plus text-success" style="font-size:22px; cursor:pointer" (click)="openModalAddPrevenu()"></i>
                            </label>
                            <div style="font-size: 13px; min-height: 600px; border: dashed 1px grey;">
                                <table class="table table-striped table-striped" style="font-size: 13px;">
                                    <thead>
                                        <tr class="bg-secondary text-light">
                                            <th scope="col">#</th>
                                            <th scope="col">{{'fullname' | translate}}</th>
                                            <th scope="col">{{'actions' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let prevenu of prevenusList; let i = index">
                                            <th scope="row">{{ i + 1 }}</th>
                                            <td>{{ prevenu.nom_complet_du_poursuivis }}</td>
                                            <td class="align-middle text-center text-sm">
                                                <div class="d-flex justify-content-between">
                                                    <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalDetailsPrevenu(prevenu)">
                                                        <i class="fas fa-solid fa-eye text-primary" style="font-size: 21px;"></i>
                                                    </a>
                                                    <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="deletePrevenu(prevenu)">
                                                        <i class="fas fa-solid fa-trash text-danger" style="font-size: 20px;"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </ng-template>


                        </div>
                    </ng-container>



                      <!-- <ng-container *ngIf="data.is_multiple_prevenu == 0; else secondBlock">
                        <div class="col-12 col-md-6" style="padding: 0 8px 0 0;">
                          <label class="fieldlabels">Type d'identification du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'type_identification_plaignant')" name="type_identification_plaignant" value="{{data.type_identification_plaignant}}" />

                          <label class="fieldlabels">Numéro d'identification du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'numéro_didentification_plaignant')" name="numéro_didentification_plaignant" value="{{data['numéro_didentification_plaignant']}}" />

                          <label class="fieldlabels">Nom complet du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_du_plaignant')" name="nom_complet_du_plaignant" value="{{data.nom_complet_du_plaignant}}" />

                          <label class="fieldlabels">Lieu de naissance du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'lieu_de_naissance_du_plaignant')" name="lieu_de_naissance_du_plaignant" value="{{data.lieu_de_naissance_du_plaignant}}" />

                          <label class="fieldlabels">Date de naissance du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="date" class="form-control" (change)="updateValueforChange($event.target.value,'date_de_naissance_du_plaignant')" name="date_de_naissance_du_plaignant" value="{{data.date_de_naissance_du_plaignant}}" />

                          <label class="fieldlabels">Adresse complète du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'addresse_complete_du_plaignant')" name="addresse_complete_du_plaignant" value="{{data.addresse_complete_du_plaignant}}" />

                          <label class="fieldlabels">Nom complet de la mère du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_mere_plaignant')" name="nom_mere_plaignant" value="{{data.nom_complet_de_la_mere_du_plaignant}}" />

                          <label class="fieldlabels">Nom complet du père du {{data.classification=='Pénale'?'plaignant':'demandeur'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_pere_plaignant')" name="nom_pere_plaignant" value="{{data.nom_complet_du_pere_du_plaignant}}" />
                        </div>

                        <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                          <div class="d-flex  mt-4">
                            <label class="fieldlabels">Activer pour le mode plusieurs prévenus :</label>
                            <div class="toggle-wrapper">
                              <input class="toggle-input" [checked]="data.is_multiple_prevenu === 1" type="checkbox" (change)="selectTypePrevenu($event)" id="pin-toggle" />
                              <label class="toggle-container" for="pin-toggle">
                                <div class="toggle-circle">
                                  <div class="pin-icon">
                                    <span class="pin-top"></span>
                                    <span class="pin-middle"></span>
                                    <span class="pin-bottom"></span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>

                          <label class="fieldlabels">Type d'identification du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'type_identification_du_poursuivis')" name="type_identification_du_poursuivis" value="{{data.type_identification_du_poursuivis}}" />

                          <label class="fieldlabels">Numéro d'identification du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'numéro_didentification_du_poursuivis')" name="numéro_didentification_du_poursuivis" value="{{data.type_identification_du_poursuivis}}" />

                          <label class="fieldlabels">Nom complet du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_du_poursuivis')" name="nom_complet_du_poursuivis" value="{{data.nom_complet_du_poursuivis}}" />

                          <label class="fieldlabels">Lieu de naissance du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'lieu_de_naissance_du_prévenu')" name="lieu_de_naissance_du_prévenu" value="{{data['lieu_de_naissance_du_prévenu']}}" />

                          <label class="fieldlabels">Date de naissance du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="date" class="form-control" (change)="updateValueforChange($event.target.value,'date_de_naissance_du_prévenu')" name="date_de_naissance_du_prévenu" value="{{data['date_de_naissance_du_prévenu']}}" />

                          <label class="fieldlabels">Adresse complète du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'addresse_complete_du_poursuivis')" name="addresse_complete_du_poursuivis" value="{{data['addresse_complete_du_poursuivis']}}" />

                          <label class="fieldlabels">Nom complet de la mère du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_de_la_mere_du_prévenu')" name="nom_complet_de_la_mere_du_prévenu" value="{{data['nom_complet_de_la_mere_du_prévenu']}}" />

                          <label class="fieldlabels">Nom complet du père du {{data.classification=='Pénale'?'prévenu':'demandé'}} :</label>
                          <input type="text" class="form-control" (change)="updateValueforChange($event.target.value,'nom_complet_du_pere_du_prévenu')" name="nom_complet_du_pere_du_prévenu" value="{{data['nom_complet_du_pere_du_prévenu']}}" />
                        </div>
                      </ng-container>

                      <ng-template #secondBlock>
                        <div class="col-12 col-md-6 col-sm-12-auto justify-content-center">
                          <label><b class="bg-dark text-light">Liste prévenus:</b>&nbsp;&nbsp;
                            <i id="addRowBtn" class="fa fa-solid fa-plus text-success" style="font-size:22px; cursor:pointer" (click)="openModalAddPrevenu()"></i>
                        </label>
                        <div style="font-size: 13px; min-height: 600px; border: dashed 1px grey;" class="table-responsive">
                            <table class="table table-striped table-striped" style="font-size: 13px;">
                                <thead>
                                    <tr class="bg-secondary text-light">
                                        <th scope="col">#</th>
                                        <th scope="col">Nom complet</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prevenu of prevenusList; let i = index">
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ prevenu.nom_complet_du_poursuivis }}</td>
                                        <td class="align-middle text-center text-sm">
                                            <div class="d-flex justify-content-between">
                                                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="openModalDetailsPrevenu(prevenu)">
                                                    <i class="fas fa-solid fa-eye text-primary" style="font-size: 21px;"></i>
                                                </a>
                                                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" (click)="deletePrevenu(prevenu)">
                                                    <i class="fas fa-solid fa-trash text-danger" style="font-size: 20px;"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div class="col-12 col-md-6">
                        </div>
                      </ng-template> -->
                    </div>

                    <label class="fieldlabels">Resumé de l'affaire: </label>
                    <textarea style="height: 220px;" class="form-control" (change)="updateValueforChange($event.target.value,'resume_de_l_affaire')"   >{{data['resume_de_l_affaire']}}</textarea>


                </div>
                  <br>
              </fieldset>

              </li>

              <li class="_tab-content _tab-content-3 typography">
                <fieldset>
                  <div class="form-card">
                    <div class="row">
                      <!-- Premier Bloc de Colonne -->
                      <div class="col-12 col-md-6 mb-3" style="padding: 0 8px 0 0;">
                        <label class="fieldlabels">{{'classification' | translate}}: *</label>
                        <input class="form-control mb-2" type="text" name="classif" [readOnly]="true" value="{{data.classification}}" />

                        <label class="fieldlabels">{{'registre' | translate}}:</label>
                        <input class="form-control mb-2" type="text" name="registre" [readOnly]="true" value="{{data.registre}}" />

                        <label class="fieldlabels">{{'reference' | translate}}:</label>
                        <input class="form-control mb-2" type="text" name="ref" [readOnly]="true" value="{{data.reference}}" />

                        <label class="fieldlabels">{{'num_origine' | translate}}:</label>
                        <input class="form-control mb-2" type="text" name="numero" [readOnly]="true" value="{{data.numero_dorigine}}" />
                      </div>

                      <!-- Deuxième Bloc de Colonne -->
                      <div class="col-12 col-md-6 d-flex flex-column mb-3">
                        <label class="fieldlabels">{{'nature' | translate}}:</label>
                        <input class="form-control mb-2" type="text" name="nature" [readOnly]="true" value="{{data.nature}}" />

                        <label class="fieldlabels">{{'inf_reference' | translate}}:</label>
                        <textarea class="form-control" [readOnly]="true" style="height: 220px;" name="information_de_la_reference">{{data.information_de_la_reference}}</textarea>
                      </div>
                    </div>

                  </div>
                </fieldset>


              </li>

              <li class="_tab-content _tab-content-4 typography" style="margin-top: 20px;">
               <fieldset>
                 <div class="form-card">

                  <div class="row">
                    <!-- Premier Bloc de Colonne -->
                    <div class="col-12 col-md-6 mb-3" style="padding: 0 8px 0 0;">
                      <label class="fieldlabels">{{'type_identif' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="type_identification" value="{{data.type_identification}}" />

                      <label class="fieldlabels">{{'firstname' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="prenom" value="{{data.prenom}}" />

                      <label class="fieldlabels">{{'country' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="pays" value="{{data.pays}}" />

                      <label class="fieldlabels">{{'zone' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="region" value="{{data.region}}" />

                      <label class="fieldlabels">{{'adress' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="adresse" value="{{data.adresse}}" />
                    </div>

                    <!-- Deuxième Bloc de Colonne -->
                    <div class="col-12 col-md-6 d-flex flex-column mb-3">
                      <label class="fieldlabels">{{'num_identif' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="numeroId" value="{{data.numero_identification}}" />

                      <label class="fieldlabels">{{'lastname' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="nom" value="{{data.nom}}" />

                      <label class="fieldlabels">{{'num_origine' | translate}}:</label>
                      <input type="text" class="form-control" [readOnly]="true" name="numero_d_origine" value="{{data.numero_d_origine}}" />

                      <label class="fieldlabels">{{'redaction' | translate}}:</label>
                      <textarea class="form-control" style="height: 120px;" [readOnly]="true">{{data.redaction}}</textarea>
                    </div>
                  </div>

                 </div>
             </fieldset>
              </li>

              <li class="_tab-content _tab-content-5  " style="overflow: hidden;"><br><br>


                 <div class="card l-bg-blue-dark m-auto" >
                     <div class="card-statistic-3 m-auto" style="margin: auto;min-height: 300px"><br><br><br>
                         <div class="card-icon card-icon-large text-center m-auto"  ><i class="fas fa-solid fa-hourglass-half" ></i></div>
                         <h5>
                           <span style="background-color: rgb(209, 188, 147);margin: auto; height: 20px;font-size: 15px;" class="badge badge-sm bg-gradient-primary text-center">{{data.workflow_state}}</span>
                         </h5>

                     </div>
                 </div>

              </li>

              <li class="_tab-content _tab-content-6 typography" style="margin-top: 20px;">
                <fieldset>

                   <div class="d-flex justify-content-between  flex-wrap">
                     <!-- Premier Bloc de Colonne -->
                      <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 d-flex flex-column" style="font-size: 11px;">
                        <div class="d-flex justify-content-end mb-2" *ngIf="CAN_ADD_DOCUMENT">
                          <button class="btn btn-success" (click)="openModalTypeDemande('document')">
                              <span class="text-center ml-2" style="font-size: 11px;">
                                <b><i class="fas fa-solid fa-plus text-light"></i> <span class="text-light"> {{'add' | translate}}</span></b>
                              </span>
                          </button>
                        </div>
                        <div class="d-flex justify-content-between bg-secondary text-light mb-2  w-100">
                          <label class="fieldlabels text-center align-items-center m-auto" style="font-size: 15px;">{{'list_docs' | translate}}</label>
                        </div>
                        <div class="table-responsive">
                          <table class="table w-100 table-striped fieldlabels mt-1">
                            <thead>
                              <tr class="text-nowrap">
                                <th scope="col">{{'type_doc' | translate}}</th>
                                <th scope="col">{{'details' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody class=" text-center  m-auto">
                              <ng-container *ngIf="listedocuments.length> 0;else emptydemandes">
                                <tr *ngFor="let document of listedocuments | paginate: { itemsPerPage: 5, currentPage: pagedocs, id:'demande' }" class="text-start " style="font-size: 11px;">
                                    <!-- <td class="align-middle text-center">
                                        <span class="text-secondary text-xs d-flex justify-content-center " style=" padding: 0 10px 0 10px;">{{document.code}}</span>
                                    </td> -->
                                    <td class="text-start">
                                        <span class="text-secondary text-xs d-flex justify-content-start " style=" padding: 0 10px 0 10px;">{{document.template_name}}</span>
                                    </td>




                                  <td style="width: 20px;" class="m-auto text-sstart">
                                      <a class="text-secondary font-weight-bold text-xs " (click)="openModalDetailDocument(document, 'document')" data-toggle="tooltip" data-original-title="Edit user" >
                                          <i class="fas fa-solid fa-eye text-primary" style="font-size: 20px;"></i>
                                      </a>&nbsp;&nbsp;
                                      <!-- <span >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-subtract" viewBox="0 0 16 16" style="margin-top: -7px;">
                                              <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                                          </svg>
                                      </span> -->
                                  </td>
                              </tr>
                              </ng-container>
                              <ng-template #emptydemandes>
                                <p>Aucune donnée trouvée!</p>
                              </ng-template>

                            </tbody>
                          </table>
                          <pagination-controls (pageChange)="pagedocs = $event" [id]="'demande'" previousLabel="" nextLabel="" responsive="true"></pagination-controls>

                        </div>
                      </div>

                      <div class=" col-md-5 col-lg-5 col-xl-5 col-sm-12 d-flex flex-column  " style="font-size: 11px;">
                        <div class="d-flex justify-content-end mb-2" *ngIf="CAN_ADD_INFRACT">
                          <button class="btn btn-success" id="addRowBtn_inf" (click)="updateInfr()">
                              <span class="text-center ml-2" style="font-size: 11px;">
                                <b ><i class="fas fa-solid fa-plus text-light"></i> <span class="text-light"> {{'add' | translate}}</span></b>
                              </span>
                          </button>
                          <button class="btn btn-warning" (click)="updateInfractionOnDossier()"  *ngIf="updateinfractions">
                            <span class="text-center ml-2" style="font-size: 11px;">
                              <b > <span class="text-light"> {{'edit' | translate}}</span></b>
                            </span>
                          </button>
                        </div>
                        <div class="d-flex justify-content-between bg-secondary text-light mb-2">
                          <div class="d-flex justify-content-between bg-secondary text-light mb-2  w-100">
                            <label class="fieldlabels text-center align-items-center m-auto" style="font-size: 15px;">Liste {{data.classification=='Pénale'?'Infractions':'Fautes civile'}}</label>
                          </div>
                        </div>
                        <div class="table-responsive ">
                          <table id="myTable" class="table table-striped col-12 col-md-12 col-sm-12  text-nowrap text-center m-auto" >
                              <thead style="font-size: 12px;" class="text-dark" >
                                <tr class="text-nowrap ">
                                  <th>{{'nature' | translate}}</th>
                                  <th>{{'date' | translate}}</th>
                                  <th>{{'textes' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let inf of infractions | paginate: { itemsPerPage: 5, currentPage: pageinfraction, id:'infr' }" class="text-nowrap">
                                  <td class="text-center">
                                    <span class="text-secondary text-xs d-flex justify-content-center " style=" padding: 0 10px 0 10px;">{{inf.nature}}</span>
                                  </td>
                                  <td class="text-center">
                                    <span class="text-secondary text-xs d-flex justify-content-center " style=" padding: 0 10px 0 10px;">{{inf.date}}</span>
                                  </td>
                                  <td class="text-center">
                                    <span class="text-secondary text-xs d-flex justify-content-center " style=" padding: 0 10px 0 10px;">{{inf.textes}}</span>
                                  </td>


                                </tr>
                                <pagination-controls (pageChange)="pageinfraction = $event" previousLabel="" [id]="'infr'" nextLabel="" responsive="true"></pagination-controls>
                              </tbody>
                            </table>
                          </div>
                          <!-- <table class="table w-100 table-striped fieldlabels mt-1">
                            <thead>
                              <tr class="text-nowrap">
                                <th scope="col">nature</th>
                                <th scope="col">type</th>
                              </tr>
                            </thead>
                            <tbody class=" text-center  m-auto">
                              <ng-container *ngIf="infractions.length> 0;else emptydemandes">
                                <tr *ngFor="let document of infractions | paginate: { itemsPerPage: 5, currentPage: pagedocs }" class="text-start " style="font-size: 11px;">

                                    <td class="text-start" style="max-height: 10px;">
                                        <span class="text-secondary text-xs d-flex justify-content-start " style=" padding: 0 10px 0 10px;">{{document.name}}</span>
                                    </td>
                                    <td class="text-start" style="max-height: 10px;">
                                      <span class="text-secondary text-xs d-flex justify-content-start " style=" padding: 0 10px 0 10px;">{{document.type}}</span>
                                  </td>

                              </tr>
                              </ng-container>
                              <ng-template #emptydemandes>
                                <p>Aucune donnée trouvée!</p>
                              </ng-template>


                            </tbody>
                          </table> -->


                      </div>
                     </div>
              </fieldset>
               </li>

            </ul>
          </div>
        </div>



         <!--/ tabs -->

         <div *ngIf="CAN_DO_REQUISITOIR ">
          <div class="d-flex flex-wrap justify-content-between mt-3 " style="font-size: 10px;">
            <!-- Requisitoire Button -->
            <div class="" style="padding: 0 5px 0 0;" *ngIf="data.allow_instruction==1">
              <div class="addButton">
                <div class="button b1 text-nowrap" (click)="openModalAddRequisitoire()">
                  <i class="fas fa-regular fa-folder float-left mt-1 "></i>&nbsp; {{'requisitoire' | translate}}
                </div>
              </div>
            </div>
            <div class="" style="padding: 0 5px 0 0;" *ngIf="data.allow_instruction==0">
              <div class="addButton">
                <div class="button b1 text-nowrap" (click)="SendRequestToPresidentForRequisitoir()">
                  <i class="fas fa-regular fa-folder float-left mt-1 "></i>&nbsp; {{'avis_president' | translate}}
                </div>
              </div>
            </div>

            <!-- Action Buttons -->
            <div class="mt-1" style="padding: 0 5px 0 0;" *ngIf="data.allow_instruction==1">
              <div class="row">
                <!-- Vue sans appel Button -->
                <!--<div class="">
                  <div class="addButton">
                    <div class="button b1 text-nowrap" [disabled]="true" (click)="openModalSuccessDialog('Vue sans appel effectué')">
                      <i class="fas fa-solid fa-check float-left text-nowrap mt-1"></i>&nbsp; {{'sans_appel' | translate}}
                    </div>
                  </div>
                </div>&nbsp;&nbsp;-->

                <!-- Rapport d'appel Button -->
                <div *ngIf="nbr_ordonances >1" class="" style="padding: 0 5px 0 0;">
                  <div class="addButton">
                    <div class="button b3 text-nowrap" (click)="openModalAddRapport()">
                      <i class="fas fa-regular fa-folder float-left text-nowrap mt-1"></i>&nbsp; {{'rapport_appel' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>

         <div *ngIf="CAN_DO_REQUISITOIR_SUPLETIF ">
           <div class="d-flex flex-wrap justify-content-between mt-3 " style="font-size: 10px;">
             <!-- Requisitoire Button -->
             <div class="" style="padding: 0 5px 0 0;" *ngIf="data.allow_instruction==1">
               <div class="addButton" >
                 <div class="button b1 text-nowrap" (click)="openModalAddRequisitoire('Requisitoire',0,1)">
                   <i class="fas fa-regular fa-folder float-left mt-1 "></i>&nbsp; {{'requisitoire_supletif' | translate}}
                 </div>
               </div>

             </div>

             <!-- Action Buttons -->
           </div>
           <br>
         </div>


       </div>
     </body>
   </html>
