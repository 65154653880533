import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template:`
       <footer class="text-dark text-center py-4">
  <p>&copy; 2025 E-justice. Tous droits réservés.</p>
  <div>
    <a href="#" class="text-dark  mx-2"><i class="fab fa-facebook-f"></i></a>
    <a href="#" class="text-dark  mx-2"><i class="fab fa-twitter"></i></a>
    <a href="#" class="text-dark  mx-2"><i class="fab fa-instagram"></i></a>
  </div>
</footer>`,
})
export class FooterComponent {
}
