import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { SuccessDialogComponent } from '../successDialog/success-dialog.component';
import { ViewDetailsDossierComponent } from '../../dialogs/view-details-dossier/view-details-dossier.component';
import { DetailViewComponent } from '../detailsview/detailView.component';
import { ViewDetailsCourrierComponent } from '../../dialogs/view-details-courrier/view-details-courrier.component';
import { ViewDetailsChambreComponent } from '../../dialogs/view-details-chambre/view-details-chambre.component';
import { ViewDetailsJugementComponent } from '../../dialogs/view-details-jugement/view-details-jugement.component';
import { ViewDetailsEnrollementComponent } from '../../dialogs/view-details-enrollement/view-details-enrollement.component';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { VisualizeRequisitoireComponent } from '../../dialogs/visualize-requisitoire/visualize-requisitoire.component';
import { DossierService } from '../../services/frapper/dossier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { JugementsService } from '../../services/frapper/jugements.service';
import { AssignedService } from '../../services/frapper/assigned.service';
import { AlertesService } from '../../services/public/alertes.service';

@Component({
  selector: 'app-read-notification',
  templateUrl: './read-notification.component.html',
  styleUrls: ['./read-notification.component.scss']})
export class ReadNotificationComponent {

  data:any;
  modalRef!: BsModalRef;
  activeElement:boolean  = false;
  is_demande_acce: any;
 
  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
    private roleService: EnrollementService,
    private courrierService: UsercourrierService,
    private bsrefmodal: BsModalRef,
    private jugementService: JugementsService,
    private ngxserviceLoader: NgxUiLoaderService,
    private dossierService:DossierService,
    private assignService: AssignedService,
    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
    private penalService: PenalCourrierService,private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.is_demande_acce = this.options.initialState.is_demande_acce;
    // if (this.options.initialState.is_demande_acce != null ) {
      
    // }
    
    console.log('eeee1', this.data);
    console.log(this.data)
  }


  async getOneRole(){
    this.ngxserviceLoader.start();
   let p =  await this.roleService.getOneRole(this.data.document_name);
   this.data = p.data;
   this.ngxserviceLoader.stop();
   

  }
  async getOneCourrierInterne(){
    this.ngxserviceLoader.start();
    let p =  await this.courrierService.getOneCourrierInterne(this.data.document_name);
    this.data = p.data;
    console.log('courrier interne', this.data);
    this.ngxserviceLoader.stop();
    
 
   }

   async getNombredeRequisiToireAndOrdonance(){
    this.ngxserviceLoader.start();
       await this.dossierService.getOneRequisitoireOrOrdonance(this.data.document_name).then(res=>{
         this.data =  res.data
     
    
        })
        this.ngxserviceLoader.stop();
  }
  async getDataJugement(){
    this.ngxserviceLoader.start();
     await this.jugementService.getOneJugement(this.data.document_name).then(res => {;
   this.data = res.data;
  //  this.ngxserviceLoader.stop();
  });

  }
    
  async openModalDetails() {

    const componentToOpen = this.getComponentForDocumentType(this.data.document_type.toLowerCase());
      this.data['name'] = this.data.document_name;
      console.log('the docname = =>', this.data);
      
      if ( this.data.document_type.toLowerCase() == "chambres") {
        this.data = {
          "name": this.data.document_name,
          "owner": null,
          "creation": null,
          "modified": null,
          "modified_by": null,
          "docstatus": null,
          "idx": null,
          "nom": null,
          "label": null,
          "jour_travail": null,
          "max": 4,
          "type": null,
        }
      }
      if (this.data.document_type.toLowerCase() == "e-role") {
       await this.getOneRole()
      }else if (this.data.document_type.toLowerCase() == "courrier interne") {
        
        await this.getOneCourrierInterne()
      }else if (this.data.document_type.toLowerCase() == "requisitoiresordonances") {
        await this.getNombredeRequisiToireAndOrdonance()
      }else if (this.data.document_type.toLowerCase() == "jugements") {
        await this.getDataJugement()
      }
      console.log('dassss', this.data);
      const config: ModalOptions = {
        initialState: {
         
          
          data: this.data,
          // type: this.data.document_type.toLowerCase()
        }
       }
       console.log('dassss', this.data);
       this.bsrefmodal.hide()
       this.modalRef = this.modalService.show(await componentToOpen, Object.assign({},config,{class:'modal-xl modal-dialog-centered'}));


  }



  
  private async getComponentForDocumentType(documentType: string): Promise<any> {
    switch (documentType) {
      case 'courrier':
        this.activeElement = true;
        return ViewDetailsCourrierComponent;
      case 'jugements':
        this.activeElement = true;
        return ViewDetailsJugementComponent;
      case 'e-role':
        this.activeElement = true;
        return ViewDetailsEnrollementComponent;
      case 'courrier interne':
        this.activeElement = true;
        
        return DetailViewComponent;
      case 'requisitoiresordonances':
       
          this.activeElement = true;
          return VisualizeRequisitoireComponent;

      case 'chambres':
      
        this.activeElement = true;
        this.data = {
          "name": this.data.document_name,
          "owner": null,
          "creation": null,
          "modified": null,
          "modified_by": null,
          "docstatus": null,
          "idx": null,
          "nom": null,
          "label": null,
          "jour_travail": null,
          "max": 4,
          "type": null,
        }
        return VisualizeRequisitoireComponent;
      case 'dossier':
        this.activeElement = true;
        
        return ViewDetailsDossierComponent;
      default:
    }
  }
  

  allowAcce(){
    this.ngxService.start()
    let me:any = localStorage.getItem('me')
    me =JSON.parse(me)
    let body={
      "status":"Accepter",
      "validator":me.name
    }
    this.assignService.updateDemande(this.data.name,body).then(res =>{
      this.assignService.doAssignation(this.data.type,this.data.numero,[this.data.for_user]).then(res =>{

        this.alerte.successNotification('Acces Accepter')
        this.bsrefmodal.hide()
        this.ngxService.stop()
      },err =>{
        this.alerte.warningNotification('erreur')
        this.ngxService.stop()
      })
    },err =>{
      this.alerte.warningNotification('erreur')
      this.ngxService.stop()
    })
  }

  rejectDemandesAcce(){
    this.ngxService.start()
    let me:any = localStorage.getItem('me')
    me =JSON.parse(me)
    let body={
      "status":"Rejeter",
      "validator":me.name
    }
    this.assignService.updateDemande(this.data.name,body).then(res =>{
      this.alerte.successNotification('Acces Rejeter')
      this.bsrefmodal.hide()
      this.ngxService.stop()
    },err =>{
      this.alerte.warningNotification('erreur')
      this.ngxService.stop()
    })

  }

  revokeAcce(email:string){

  }
  

  

  async ngOnInit() {
    if (this.data.document_type != null) {
      await this.getComponentForDocumentType(this.data.document_type.toLowerCase())
    }
  
  console.log('eeee', this.data);

  }
}
