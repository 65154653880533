import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { NavigationEnd, Router } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Subject, Subscription } from 'rxjs';
import { LayoutService } from '../../../@core/utils';
import { ProfileComponent } from '../../../E-justice/dialogs/profile/profile.component';
import { FrapperService } from "../../../E-justice/services/frapper/frapper.service";
import {ViewListJuridictionsComponent} from "../../../E-justice/dialogs/view-list-juridictions/view-list-juridictions.component";
import { MyCONST } from '../../../E-justice/CONST/myConst';
import { filter, map, switchMap } from 'rxjs/operators';
import * as $ from 'jquery';
import { ReadNotificationComponent } from '../../../E-justice/widgets/read-notification/read-notification.component';
import { UserService } from '../../../E-justice/services/frapper/user.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  profil: any;
  my_profil: any;
  is_public: boolean = false;
  myConst = MyCONST;
  menu : any = []
  active_menu= false
  activeRoute: string = '';
 isDropdownOpen = false;
 notifications: any[] = [];
 new_notifs: number = 0
 private subscription: Subscription;
 p:number = 0

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private frapper: FrapperService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private modalService: BsModalService,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,

    private router: Router) {

      this.menu = JSON.parse(localStorage.getItem('menu') +'')
      console.log('the menu===> list-group-item/ "groupTitle"', this.menu);


      // for active route
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.activeRoute = this.router.url;
      });

      if (this.is_public) {
        this.getNotifications()
      }
      

  }

  openModalProfile() {
    const config: ModalOptions = {
      initialState: {
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(ProfileComponent, Object.assign({}, config, { class: 'modal-xl' }));

    // Mettre à jour l'option initialState avec le modalRef
    // modalRef.content.parentModal = modalRef;
  }

  openModalDetailNotif(data: any) {
    const config: ModalOptions = {
      initialState: {
        data: data,
        parentModal : null
      }
    }
    if (data.read === 0) {
      this.readNotifications(data)
    }
   

    // Ouvrir le modal
    const modalRef = this.modalService.show(ReadNotificationComponent, Object.assign({}, config, { class: 'modal-lg' }));

     // Mettre à jour l'option initialState avec le modalRef
  }

  onChangeMenu(route: any ){
    console.log('route selected=> ',route);

    this.router.navigate([route])


  }





  openModalJuridiction() {
    const config: ModalOptions = {
      initialState: {
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(ViewListJuridictionsComponent, Object.assign({}, config, { class: 'modal-xl' }));

    // Mettre à jour l'option initialState avec le modalRef
    // modalRef.content.parentModal = modalRef;
  }


  getNotifications() {
    try {
      // Récupérer périodiquement toutes les notifications toutes les minutes
      this.subscription = interval(60000)
        .pipe(
          switchMap(() => this.userService.getAllMyNotification()),
          map(res => res.data) // Filtrer les notifications non lues
        )
        .subscribe(
          (filteredNotifications) => {
            this.notifications = filteredNotifications;
            this.new_notifs = this.notifications.filter(notification => notification.read == 0).length
            console.log('notifications: ', this.notifications);
          },
          (error) => {
            console.log('error get notifications', error);
          }
        );
    } catch (error) {
      console.log('error get notfi');
    }
  }
  
  readNotifications(notif: any){
    console.log('in red notif');
    
    notif.read = 1;
    if (this.new_notifs>0) {
      this.new_notifs -= 1;
    }
   
    try {
      const index = this.notifications.findIndex(notification => notification.name === notif.name);
      if (index !== -1) {
        this.notifications[index] = notif;  // Remplacer la notification modifiée
      }
      this.userService.updateNotification(notif.name,notif).then(()=>{
        console.log('end red notif');
        
      })

        // this.userService.getAllMyNotification().then((res)=>{
        //   this.notifications =  res.data
  
        // })
        


    } catch (error) {
      console.log('error get notfi');
      
    }
  }

  ngOnInit() {
    this.is_public = MyCONST.IS_PUBLIC === 1? true : false

    this.profil = localStorage.getItem('me_username');
    this.my_profil = localStorage.getItem('profil');
    try {
      if (!this.is_public) {
        console.log('firstcall',this.is_public);
        
        this.userService.getAllMyNotification().then((res)=>{
          this.notifications =  res.data
          this.new_notifs = res.data.filter(notification => notification.read == 0).length
  
        })
      }
     
    } catch (error) {
      
    }

  }

  // openMenu(route: String)
  async refresh(){
    this.ngxService.start();
    try {
     await this.ngOnInit();

      this.ngxService.stop();


    } catch (error) {
      this.ngxService.stop();
    }

  }

  async logout(){
    await this.frapper.logOut();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  handleAction(action: string) {
    switch (action) {
      case 'Log out':
        this.frapper.logOut();
        break;

      case 'Profile':
        console.log('Profil');
        this.openModalProfile();
        // Votre logique pour afficher la famille de l'utilisateur ici
        // this.opentabsfamille(this.selectedUser);
        break;

      // Ajoutez d'autres cas selon vos besoins

      default:
      // Gérez le cas par défaut si aucune correspondance n'est trouvée
      // console.warn('Action non gérée:', action);
    }
  }

  navigateHome() {
    if (!this.is_public) {
      this.router.navigate(['E-justice/home'])

    }
  }


  ngAfterViewInit() {
    $(document).ready(function () {
      $('.notifications').on('click', function (event) {
        const dropdown = $(this).find('.dropdown-notif');
    
        if (!dropdown.is(':visible')) {
          dropdown.show(); // Affiche le dropdown
        } 
    
    
        event.stopPropagation();
      });
    
      $(document).on('click', function (event) {
        if ($('.dropdown-notif:visible').length && !$(event.target).closest('.notifications').length) {
          $('.dropdown-notif').hide(); // Ferme le dropdown
        }
      });
    });
    
    // });
  }




}
