import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddPaymentComponent } from '../../../dialogs/add-payment/add-payment.component';
import { AddPublicServiceComponent } from '../../../dialogs/add-public-service/add-public-service.component';
import { ViewDetailsServicepublicComponent } from '../../../dialogs/view-details-servicepublic/view-details-servicepublic.component';
import { PenalCourrierService } from '../../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../../services/public/alertes.service';
import { PublicService } from '../../../services/public/public.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent {
  List_demandes: any[] = [];

  exec_demande;

  modalRef!: BsModalRef;
  pageDossier: number = 1;
  pageSubs: number = 1;
  pageIntCourrier: number = 1;
  numberDossierAV: number = 0;
  numberDossierJI: number = 0;
  numberDossierJE: number = 0;
  OPERATIONS: any[] = [];
  total_operation = 0;
  private dropdowns: any[] = [];
  filtered_demandes: any[] = []


  ELEMENT_DATA = [
    {ref: 1, name: 'Hydrogen', description: 1.0079, symbol: 'H'},
    {ref: 2, name: 'Helium', description: 4.0026, symbol: 'He'},
  ];
  dataSource :any ;
  @Input() donnees: any;

  pageIndex = 0; // Commence à 0
pageSize = 10; // Taille de la page
keyword: string = '';


  constructor(private modalService: BsModalService,
    private datePipe: DatePipe,
    private penalCourrierService: PenalCourrierService,private publicServive: PublicService,private alerte:AlertesService,private ngxService: NgxUiLoaderService) {

  }
  refresh(){
    window.location.reload();
  }


    searchDemande(){
      this.ngxService.start();
      try {
        let filteredData = this.dataSource.filter(element => element.ref?.toLowerCase().includes(this.keyword.toLowerCase()) || element.demandeur.full_name?.toLowerCase().startsWith(this.keyword.toLowerCase()));

        if (filteredData.length === 0) {
          this.alerte.errorNotification('Recherche ','Aucun résultat trouvé pour le numéro de recherche ' + this.keyword)
        } else{
          this.filtered_demandes = filteredData

        }
        this.ngxService.stop();
        this.keyword = ''
      } catch (error) {
        this.ngxService.stop();
      }


  }
  filterDemande() {
    this.ngxService.start();
    try {
        // Récupérer les filtres sélectionnés pour chaque colonne
        const selectedFilters: { [key: string]: string[] } = {};
        this.dropdowns.forEach(dropdown => {
            const dropdownId = dropdown.data('dropdown-id');
            let selectedValues = dropdown.find('[type="checkbox"]:checked')
                                          .map(function() { return $(this).attr('name'); })
                                          .get();

            if (selectedValues.length > 0) {
              // if (dropdownId === "dropdown5") {
              //   console.log(selectedValues);
              //   try {
              //     selectedValues = selectedValues.map(value => this.getNormalDate(value));
              //     console.log(selectedValues);
              //   } catch (error) {
              //     console.log(error);
              //   }
              // }
              if (dropdownId === "dropdown4") {

                selectedValues == 'EN ATTENTE DE PAIEMENT' ? selectedValues =  'BILLING' :
                  selectedValues == 'EN ATTENTE DE TRAITEMENT' ? selectedValues = 'WAITING TRAITEMENT' :
                  selectedValues == 'EN COUR DE TRAITEMENT'  ? selectedValues = 'TRAITEMENT ON PROGRESS': 'TERMINEE'

              }
              selectedFilters[dropdownId] = selectedValues;
            }

        });

        // Filtrer les données en fonction des filtres sélectionnés
        let filteredData = this.List_demandes.filter(element => {
            return Object.keys(selectedFilters).every(dropdownId => {
                const selectedValues = selectedFilters[dropdownId];

                switch (dropdownId) {
                    case 'dropdown1':
                        return selectedValues.includes(element.ref);
                    case 'dropdown2':
                        return selectedValues.includes(element.service.name);
                    case 'dropdown3':

                        return selectedValues.includes(element.amount.toString());
                    case 'dropdown4':
                      // console.log('iddrop', selectedValues);
                        return selectedValues.includes(element.status.toString());
                    case 'dropdown5':
                      console.log('iddrop', element.created_at);
                        return selectedValues.includes(this.getFormattedDate(element.created_at));

                    default:
                        return true;
                }
            });
        });

        // Appliquer le filtre de recherche textuel
        // filteredData = filteredData.filter(element =>
        //     element.ref?.toLowerCase().includes(this.keyword.toLowerCase()) ||
        //     element.service.name?.toLowerCase().startsWith(this.keyword.toLowerCase()) ||
        //     element.status?.toLowerCase().startsWith(this.keyword.toLowerCase()) ||
        //     element.service.amount?.toLowerCase().startsWith(this.keyword.toLowerCase()) ||
        //     element.created_at?.toLowerCase().startsWith(this.keyword.toLowerCase())
        // );

        // Mettre à jour les données filtrées
        if (filteredData.length === 0) {
            this.alerte.errorNotification('Recherche ', 'Aucun résultat trouvé ');
        } else {
            this.filtered_demandes = filteredData;
            this.pageIntCourrier = 1
        }
        this.ngxService.stop();
        this.keyword = '';
    } catch (error) {
        this.ngxService.stop();
    }
}



  async getMyDemandes(page?: number){
    this.ngxService.start();

    console.log('pageactu...'+ page);

    try {
      const res: any = await new Promise((resolve, reject) => {
          this.publicServive.getMyDemandes(page).subscribe(resolve, reject);
      });
      this.ELEMENT_DATA=[];
      this.dataSource = [];
      this.ELEMENT_DATA = res.data
      this.dataSource = this.ELEMENT_DATA
      this.List_demandes = this.dataSource
      this.filtered_demandes = this.List_demandes;
      this.total_operation = res.meta.total



      console.log({ 'listdemandes': this.List_demandes });

      const stateTemplate = _.template(
          `<li>
              <input name="<%= abbreviation %>" type="checkbox">
              <label for="<%= abbreviation %>" style="font-size:12px;"><%= capName %></label>
          </li>`
      );

      const populateDropdown = (dropdownId: string, columnKey: string) => {
          const dropdown = $(`[data-dropdown-id="${dropdownId}"]`);
          const dropdownList = dropdown.find('.dropdown-list ul');
          const searchInput = dropdown.find('.dropdown-search');

          // Remplir la liste avec les données filtrées par colonne
          dropdownList.empty();

          const columnData = _.map(this.filtered_demandes, columnKey);
          console.log(`Données pour ${columnKey}:`, columnData);

          const uniqueDataSet = new Set(
            columnData.map(item => {
                if (columnKey === 'created_at' && item != null && item !== '') {
                    return this.getFormattedDate(item); // Convertir la date
                }
                return item; // Retourner l'item tel quel
            }).filter(item => item != null && item !== '') // Filtrer les valeurs nulles et vides
        );
          const uniqueData = Array.from(uniqueDataSet);


          console.log(`Valeurs uniques pour ${columnKey}:`, uniqueData);

          uniqueData.forEach((item: string) => {
              if (item) {
                  if (columnKey === 'created_at') {
                      item = this.getFormattedDate(item);
                  }
                  if (columnKey === 'status') {
                    item === 'BILLING' ? item =  'EN ATTENTE DE PAIEMENT' :
                      item === 'WAITING TRAITEMENT' ? item = 'EN ATTENTE DE TRAITEMENT' :
                      item === 'TRAITEMENT ON PROGRESS' ? item = 'EN COUR DE TRAITEMENT' : 'TERMINEE'
                  }
                  const capName = _.startCase(item.toString());
                  dropdownList.append(stateTemplate({ abbreviation: item, capName }));
              }
          });

          // Gérer l'affichage et la recherche
          dropdown.on('click', '.dropdown-button', () => {
              this.dropdowns.forEach(d => {
                  if (d.get(0) !== dropdown.get(0)) {
                      d.find('.dropdown-list').hide();
                  }
              });
              dropdown.find('.dropdown-list').toggle();
          });

          searchInput.on('input', function() {
              const search = $(this).val() as string;
              const searchLowerCase = search?.toLowerCase();

              dropdownList.find('li').each(function() {
                  const text = $(this)?.text().toLowerCase();
                  const match = text.includes(searchLowerCase);
                  $(this).toggle(match);
              });
          });

          dropdown.on('change', '[type="checkbox"]', () => {
              this.filterDemande();
          });

          this.dropdowns.push(dropdown);
      };

      populateDropdown('dropdown1', 'ref');
      populateDropdown('dropdown2', 'service.name');
      populateDropdown('dropdown3', 'service.amount');
      populateDropdown('dropdown4', 'status');
      populateDropdown('dropdown5', 'created_at');

      this.ngxService.stop();
      console.log({ 'oppppeeerrr': res });
  } catch (error) {
      this.ngxService.stop();
      this.alerte.errorNotification('Erreur lors de la récupération de vos demandes', error.error);
  }

}

 // for pagination
 changePage(event: PageEvent)  {
  console.log('event page',event);

  this.pageIndex = event.pageIndex; // Mettre à jour l'index de la page
  this.pageSize = event.pageSize;
  this.getMyDemandes(this.pageIndex++)


}

 // Method to handle page change event


  openService(service:any){
    this.exec_demande = service
    console.log({'form service': this.exec_demande})

    const config: ModalOptions = {
      initialState: {
        data: service,
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    if (service != null) {


      return new Promise<string>((resolve, reject) => {
        this.modalRef = this.modalService.show(AddPublicServiceComponent, Object.assign({}, config, {class:'modal-lg  modal-centered'}));
        this.modalRef.content.parentModal = this.modalRef;
        this.modalRef.onHidden.subscribe((value: string) => {
          resolve(value); // Résoudre la promesse avec la valeur retournée
        });
      }).then(async (value: string) => {
        console.log('response after close=> ',value);

        if ( value == "success") {
          await this.getMyDemandes()

        }


      });

    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }



  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }







  // openModal(type_courrier:string) {
  //   const config: ModalOptions = {
  //     initialState: {
  //       typeCourrier: type_courrier,
  //       parentModal: this.modalRef
  //     }
  //   }
  //   this.modalRef = this.modalService.show(FormStepperComponent, Object.assign({},config,{class:'modal-lg '}));

  // }



  openPaiementDialog(service: any, operation_ref:any){

    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    if (service != null) {
      const modalRef = this.modalService.show(AddPaymentComponent, Object.assign({}, config, { class: 'modal-lg modal-centered' }));
      // modalRef.content.parentModal = modalRef;

    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }
  }

  openDemandeDetailDialog(service: any,operation_ref:any,custom_data:any=null){
    const is_traitemant = service.type=='T'? 1:0
    const config: ModalOptions = {
      initialState: {
        data: service,
        operation_ref:operation_ref,
        custom_data:custom_data,
        is_traitement:is_traitemant
      }
    }
    if (service != null) {
      return new Promise<string>((resolve, reject) => {
        const modalRef = this.modalService.show(ViewDetailsServicepublicComponent, Object.assign({}, config, { class: 'modal-xl modal-viewdetailspublic' }));
        modalRef.content.parentModal = modalRef;
        modalRef.onHidden.subscribe((value: string) => {
          resolve(value); // Résoudre la promesse avec la valeur retournée
          console.log(value);
          
        });
      }).then(async (value: string) => {
        if ( value == "for_open") {
          return this.openDemandeDetailDialog(service, operation_ref, custom_data);

        }
  
  
      });
      const modalRef = this.modalService.show(ViewDetailsServicepublicComponent, Object.assign({}, config, { class: 'modal-lg modal-centered' }));
      // modalRef.content.parentModal = modalRef;

    }else{
      this.alerte.errorNotification('Demande ','Une Erreur est survenue lors de la recuperation des services')
    }

  }
  handleClick(element: any, column: string) {
    if (column === 'actions') {
      return;
    }
  
    this.openDemandeDetailDialog(element.service, element.ref, element.custom_data);
  }
  



  async ngOnInit(): Promise<void> {

   await this.initializeDropdowns()

  }

  private async initializeDropdowns() {
    this.ngxService.start()
    await this.getMyDemandes()
    console.log('result=> ', this.List_demandes);


    // populateDropdown('dropdown5', 'created_at');


    $(document).on('click', (event) => {
      const target = $(event.target);
      if (!target.closest('.dropdown-list, .dropdown-button').length) {
          this.dropdowns.forEach(d => {
              d.find('.dropdown-list').hide();
          });
      }
  });
}
}
