import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {MyCONST} from "../../CONST/myConst";
import {Router} from "@angular/router";
import {AlertesService} from "./alertes.service";

import * as tweetnacl from 'tweetnacl';
import * as tweetnaclUtil from 'tweetnacl-util';
import {map} from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicService {


  API_URL = MyCONST.API_PUBLIC
  USE_CHACHA = MyCONST.active_chacha
  KEY_WORD:any = MyCONST.key_word
  KEY:any = MyCONST.key_size
  NONCE:any = MyCONST.nonce_size
  token :any
  token_device :any
  token_type: any

  constructor(private http:HttpClient, private router: Router, private alerte:AlertesService) {
    this.getToken()

  }

  clearSomeLocalStorage(): void {
    // get all variable of localstorage
    const keys = Object.keys(localStorage);

    // Parcourir les clés et supprimer celles qui ne sont pas 'device_id'
    keys.forEach(key => {
      if (key !== 'DEVICE_ID' && key !== 'DEVICE_ID_CODE') {
        localStorage.removeItem(key);
      }
    });

    console.log('Tous les éléments sauf device_id ont été supprimés du localStorage.');
  }


  encryptJSON(my_json: any, key: any, nonce: any) {
    if (this.USE_CHACHA == 1){
      my_json = JSON.stringify(my_json)
      const textBytes = tweetnaclUtil.decodeUTF8(my_json);
      const encryptedBytes = tweetnacl.secretbox(textBytes, nonce, key);
      const encryptedBase64 = tweetnaclUtil.encodeBase64(encryptedBytes);
      my_json = {find_me:encryptedBase64}
      return my_json;
    }else return my_json

  }

  decryptJSON(encryptedText: any, key: any, nonce: any) {
    const encryptedBytes = tweetnaclUtil.decodeBase64(encryptedText);
    const decryptedBytes = tweetnacl.secretbox.open(encryptedBytes, nonce, key);
    if (!decryptedBytes) {
      throw new Error('Erreur de déchiffrement : le texte chiffré est invalide.');
    }
    const decryptedText = tweetnaclUtil.encodeUTF8(decryptedBytes);
    return decryptedText;
  }

  generateKey(){
    let myKey = this.KEY_WORD;
    let myNonce = this.KEY_WORD

    let enc = new TextEncoder()
    myKey= enc.encode(myKey)
    myNonce = enc.encode(myNonce)

    myKey = tweetnacl.hash(myKey);
    myKey = myKey.slice(0, this.KEY)
    myNonce = tweetnacl.hash(myNonce);
    myNonce = myNonce.slice(0, this.NONCE)
    return {KEY:myKey, NONCE:myNonce}
  }


  logOut (){
    localStorage.clear()
    this.router.navigate(['/']);
  }

  login(form: any,is_device=false){
    const KY = this.generateKey()
    let myKey = KY.KEY;
    let myNonce = KY.NONCE
    //const key = tweetnacl.randomBytes(32);
    //const nonce = tweetnacl.randomBytes(24);


    form =  this.encryptJSON(form, myKey,myNonce)
    //msg =  this.decryptJSON(msg, key,nonce)
    this
    return this.http.post(this.API_URL + '/public/auth',
      form
    ).pipe(
      map((resultat: any) => {
        console.log(resultat)
        if(!localStorage.getItem('token')){
          localStorage.setItem('token', JSON.stringify(resultat.token));
        }
        localStorage.setItem('type', JSON.stringify(resultat.type));
        if(is_device==true){
          localStorage.setItem('DEVICE_ID_CODE', JSON.stringify(resultat.token));
        }
        localStorage.setItem('menu', resultat.me.attribut.value);

        //this.token = JSON.parse(token);

        return resultat;
      }),
    );
  }

  register(form: any){

    const KY = this.generateKey()
    let myKey = KY.KEY;
    let myNonce = KY.NONCE



    //const key = tweetnacl.randomBytes(32);
    //const nonce = tweetnacl.randomBytes(24);

    form =  this.encryptJSON(form, myKey,myNonce)

    return this.http.post(this.API_URL + '/public/register',
      form
    ).pipe(
      map((resultat: any) => {
        // console.log(resultat)
        //localStorage.setItem('token', JSON.stringify(resultat.token));
        //localStorage.setItem('type', JSON.stringify(resultat.type));
        //this.token = JSON.parse(token);
        return resultat;
      }),
    );
  }
  devicesregister(form: any){

    const KY = this.generateKey()
    let myKey = KY.KEY;
    let myNonce = KY.NONCE
    //const key = tweetnacl.randomBytes(32);
    //const nonce = tweetnacl.randomBytes(24);
    form =  this.encryptJSON(form, myKey,myNonce)
    return this.http.post(this.API_URL + '/public/device_register',
      form
    ).pipe(
      map((resultat: any) => {
        // console.log(resultat)
        //localStorage.setItem('token', JSON.stringify(resultat.token));
        //localStorage.setItem('type', JSON.stringify(resultat.type));
        //this.token = JSON.parse(token);
        return resultat;
      }),
    );
  }

  adminRegister(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.post(this.API_URL + '/management/createUser',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        // console.log(resultat)
        //localStorage.setItem('token', JSON.stringify(resultat.token));
        //localStorage.setItem('type', JSON.stringify(resultat.type));
        //this.token = JSON.parse(token);
        return resultat;
      }),
    );
  }

  getToken(): any {
    const tok = localStorage.getItem('token');
    const type = localStorage.getItem('type');
    if (tok != null) {
      this.token = tok;
      this.token_type = type
    }
    this.token_device=localStorage.getItem('DEVICE_ID_CODE');
    return this.token;
  }

  gardsVerification(route: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.post(this.API_URL + '/v0/verifAccessGard',
      route,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        // console.log(resultat)
        //localStorage.setItem('token', JSON.stringify(resultat.token));
        //localStorage.setItem('type', JSON.stringify(resultat.type));
        //this.token = JSON.parse(token);
        return resultat;
      }),
    );
  }

  //SUPER ADMIN

  getMyInformation(): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/getMyInformation/', { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getUserInformation(user_id :any ): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/getUserInformation/'+user_id, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getDosseirInformation(ext_id :any ): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/getCustomerDossierInformation/'+ext_id, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getMyExternalREf(my_id: any): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/customer_external_ref/'+my_id, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getIdentifications(my_id: any): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/identifications/'+my_id, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getIdentificationKey(customer_ext_id:any ,identification_id: any): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/identification/'+identification_id+'/'+customer_ext_id, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getUserRequirements(my_id: any): any {
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/v0/user_requirements/'+my_id, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getAllProfils(): any {
    //this.getToken();

    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/management/profil/', { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getAllMenus(): any {
    //this.getToken();

    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.get<any>(this.API_URL + '/management/menus/', { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  updateMenu(data: any,id:any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/management/menu/'+id,
      data,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  updateKeyPoid(data: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/v0/identification_poid/',
      data,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }
  createProfils(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/management/profil/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  createExternalRef(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/v0/customer_external_ref/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  createMenu(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/management/menu/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  getAllFromulaires(): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/formulaire/', { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getMyDemandes(page:any): any {
    this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/operations/'+page, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getMyDemandesForTraitement(page:any): any {
    //this.getToken();
    if(page==undefined){ page =1}
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/operationsTraitements/'+page, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getAllUsers(page:any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/users/'+page, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getAllDossier(page:any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/management/dosseirs/'+page, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getReglementation(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/requirement/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  getRegle(ref: any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/rule/'+ref, { headers: headers })
      .pipe(
        map(rapports => {
          //this.my_inputs = []
          //this.my_inputs = rapports
          return rapports;
        })
      );
  }

  updateReglementation(form: any,ref:any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/v0/requirement/'+ref,
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  updateCustomerIdentification(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/v0/customer_identification/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  updateRegle(form: any,ref:any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.put(this.API_URL + '/v0/rule/'+ref,
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  getAllReglementation(page:any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/requirements/'+page, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getAllRegles(page:any): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/rules/'+page, { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  createFromulaires(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/management/formulaire/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  createReglementation(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/v0/requirement/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  createRegle(form: any){
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/v0/rule/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }

  getAllowedDemande(is_anonyme=false): any {
    this.getToken();
    let token_to_use = is_anonyme==true?this.token_device.replaceAll('"','') : this.token.replaceAll('"','')
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+ token_to_use
    });
    return this.http.get<any>(this.API_URL + '/v0/getAllowedDemande/', { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  getAllowedPayement(): any {
    //this.getToken();
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });
    return this.http.get<any>(this.API_URL + '/v0/paiements/', { headers: headers })
      .pipe(
        map(rapports => {
          return rapports;
        })
      );
  }

  newOperation (form: any){
    console.log('the tokennnn=> ', this.token_type, this.token);

    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/v0/operations/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        console.log('resultResponse', resultat);

        return resultat;
      }),
    );
  }

  sendPaiementRequest (form: any){
    console.log('IIIII')
    const headers = new HttpHeaders({
      'Authorization': this.token_type.replaceAll('"','')+' '+this.token.replaceAll('"','')
    });

    return this.http.post(this.API_URL + '/v0/mobil_money/paid/',
      form,{ headers: headers }
    ).pipe(
      map((resultat: any) => {
        return resultat;
      }),
    );
  }


}
