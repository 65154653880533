
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">

</head>
<!-- <div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div><br> -->
<div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span style="font-size: 12px;" class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div>
<body #elementToPrint>
  <!-- <ngx-qrcode 
  [elementType]="elementType" 
  [value]="value"
  cssClass="aclass"
  errorCorrectionLevel="L">
</ngx-qrcode> -->



  <div #templateContainer   [innerHTML]="default_template" style="font-size: 12px;"></div>
  
  <!-- <ngx-qrcode *ngIf="default_template.includes('QR code')" 
            [elementType]="elementType" 
            [value]="value" 
            errorCorrectionLevel="L">
</ngx-qrcode> -->
</body>

</html>








