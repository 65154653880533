<body [ngClass]="isModal ? 'showModal': ''">
  <header>
    <div class="d-flex justify-content-center">
      <h5 id="heading" class="text-dark" style="font-size: 14px;">Liste des éléments constituant le dossier</h5>
      <button *ngIf="isModal" type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
        <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
      </button>
    </div>
  </header><br>
  
  <!-- <nb-card>
    <nb-card-body>
  
      <nb-tabset>
        <nb-tab tabTitle="Requisitoires"
                badgeText="{{list_requisitoires.length ?? 0}}"
                badgeStatus="success">
            <section class=" d-flex justify-content-between" *ngIf="list_requisitoires?.length > 0; else vide_req">
              <div class="card col-3" *ngFor="let item of list_requisitoires | paginate: { itemsPerPage: 9, currentPage: pageReq, id: 'req' }"  (click)="openModalVisualrequistion(item)">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="fas fa-regular fa-file "></i>
                  <p class="text-start mt-1">{{ item.name }}</p>
                </div>
              </div>
            </section>
            <pagination-controls  (pageChange)="pageReq = $event" previousLabel="" nextLabel=""
            responsive="true" [id]="'req'"></pagination-controls>
            <ng-template #vide_req>
              <p>acun requisitoire</p>
            </ng-template>
        </nb-tab>
        <nb-tab tabTitle="Ordonnances"
        badgeText="{{list_ordonnances.length ?? 0}}"
        badgeStatus="success">
            <section class=" d-flex justify-content-between" *ngIf="list_ordonnances?.length > 0;else vide_ord">
              <div class="card col-3"  *ngFor="let item of list_ordonnances | paginate: { itemsPerPage: 9, currentPage: pageOrd, id: 'ord' }"  (click)="openModalVisualrequistion(item)">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="fas fa-regular fa-file "></i>
                  <p class="text-start mt-1">{{ item.name }}</p>
                </div>
              </div>
            </section>
            <pagination-controls  (pageChange)="pageOrd = $event" previousLabel="" nextLabel=""
            responsive="true" [id]="'ord'"></pagination-controls>
            <ng-template #vide_ord>
              <p>acune ordonnance</p>
            </ng-template>
        </nb-tab>
        <nb-tab tabTitle="Pièces"
        badgeText="{{pieces.length ?? 0}}"
        badgeStatus="success">
            <section class=" d-flex flex-wrap justify-content-between" *ngIf="pieces?.length > 0; else vide_p">
              <div class="card col-3" *ngFor="let item of pieces | paginate: { itemsPerPage: 9, currentPage: pagePiece, id: 'piece' }"  (click)="openModalVisualPiece(item)">
                <div class="d-flex justify-content-start flex-wrap align-items-center">
                  <i class="fas fa-regular fa-file "></i>
                  <p class="text-start mt-1 text-wrap">{{ item.nom_du_fichier}}</p>
                </div>
              </div>
            </section>
            <pagination-controls  (pageChange)="pagePiece = $event" previousLabel="" nextLabel=""
            responsive="true" [id]="'piece'"></pagination-controls>
            <ng-template #vide_p>
              <p>acune piece</p>
            </ng-template>
        </nb-tab>
        <nb-tab tabTitle="Documents"
        badgeText="{{docs.length ?? 0}}"
        badgeStatus="success">
            <section class=" d-flex flex-wrap  justify-content-between"  *ngIf="docs?.length > 0; else vide_doc">
              <div class="card col-3" *ngFor="let item of docs | paginate: { itemsPerPage: 9, currentPage: pageDocs, id: 'doc' }"  (click)="openModalDetailDocument(item, 'document')">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="fas fa-regular fa-file "></i>
                  <p class="text-start mt-1 text-wrap ">{{ item.name}}</p>
                </div>
              </div>
            </section>
            <pagination-controls  (pageChange)="pageDocs = $event" previousLabel="" nextLabel=""
            responsive="true" [id]="'doc'"></pagination-controls>
            
            <ng-template #vide_doc>
              <p>acun document</p>
            </ng-template>
        </nb-tab>
      </nb-tabset>
  
    </nb-card-body>
  </nb-card> -->


  <div class="container">
    <div class="accordion">
      <div class="accordion-item shadow p-2 mb-2">
        <a  id="accordion-button-1" aria-expanded="false"><span class="accordion-title">Requisitoires <span class="badge badge-danger text-light">{{list_requisitoires?.length}}</span></span><span class="icon" aria-hidden="true"></span></a>
        <div class="accordion-content">
         
            <section class=" d-flex justify-content-between" *ngIf="list_requisitoires?.length > 0; else vide_req">
              <div class="card col-lg-3 col-md-3 col-xl-3 col-sm-10 col-xs-11" *ngFor="let item of list_requisitoires | paginate: { itemsPerPage: 9, currentPage: pageReq, id: 'req' }"  (click)="openModalVisualrequistion(item)">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="fas fa-regular fa-file "></i>
                  <p class="text-start mt-1">{{ item.name }}</p>
                </div>
              </div>
            </section>
            <pagination-controls  (pageChange)="pageReq = $event" previousLabel="" nextLabel=""
            responsive="true" [id]="'req'"></pagination-controls>
            <ng-template #vide_req>
              <p>acun requisitoire</p>
            </ng-template>
        
        </div>
      </div>
      <div class="accordion-item shadow p-2 mb-2">
        <a id="accordion-button-2" aria-expanded="false"><span class="accordion-title">Ordonnances <span class="badge badge-danger text-light">{{list_ordonnances?.length}}</span></span><span class="icon" aria-hidden="true"></span></a>
        <div class="accordion-content">
          <section class=" d-flex justify-content-between" *ngIf="list_ordonnances?.length > 0;else vide_ord">
            <div class="card col-lg-3 col-md-3 col-xl-3 col-sm-10 col-xs-11"  *ngFor="let item of list_ordonnances | paginate: { itemsPerPage: 9, currentPage: pageOrd, id: 'ord' }"  (click)="openModalVisualrequistion(item)">
              <div class="d-flex justify-content-start align-items-center">
                <i class="fas fa-regular fa-file "></i>
                <p class="text-start mt-1">{{ item.name }}</p>
              </div>
            </div>
          </section>
          <pagination-controls  (pageChange)="pageOrd = $event" previousLabel="" nextLabel=""
          responsive="true" [id]="'ord'"></pagination-controls>
          <ng-template #vide_ord>
            <p>acune ordonnance</p>
          </ng-template>
        </div>
      </div>
      <div class="accordion-item shadow p-2 mb-2">
        <a id="accordion-button-3" aria-expanded="false"><span class="accordion-title">pieces <span class="badge badge-danger text-light">{{pieces?.length}}</span></span><span class="icon" aria-hidden="true"></span></a>
        <div class="accordion-content">
          <section class=" d-flex flex-wrap justify-content-between" *ngIf="pieces?.length > 0; else vide_p">
            <div class="card col-lg-3 col-md-3 col-xl-3 col-sm-10 col-xs-11" *ngFor="let item of pieces | paginate: { itemsPerPage: 9, currentPage: pagePiece, id: 'piece' }"  (click)="openModalVisualPiece(item)">
              <div class="d-flex justify-content-start flex-wrap align-items-center">
                <i class="fas fa-regular fa-file "></i>
                <p class="text-start mt-1 text-wrap">{{ item.nom_du_fichier}}</p>
              </div>
            </div>
          </section>
          <pagination-controls  (pageChange)="pagePiece = $event" previousLabel="" nextLabel=""
          responsive="true" [id]="'piece'"></pagination-controls>
          <ng-template #vide_p>
            <p>acune piece</p>
          </ng-template>
        </div>
      </div>
      <div class="accordion-item shadow p-2 mb-2">
        <a id="accordion-button-4" aria-expanded="false"><span class="accordion-title">Documents <span class="badge badge-danger text-light">{{docs?.length}}</span></span><span class="icon" aria-hidden="true"></span></a>
        <div class="accordion-content">
          <section class=" d-flex flex-wrap  justify-content-between"  *ngIf="docs?.length > 0; else vide_doc">
            <div class="card col-lg-3 col-md-3 col-xl-3 col-sm-10 col-xs-11" *ngFor="let item of docs | paginate: { itemsPerPage: 9, currentPage: pageDocs, id: 'doc' }"  (click)="openModalDetailDocument(item, 'document')">
              <div class="d-flex justify-content-start align-items-center">
                <i class="fas fa-regular fa-file "></i>
                <p class="text-start mt-1 text-wrap ">{{ item.name}}</p>
              </div>
            </div>
          </section>
          <pagination-controls  (pageChange)="pageDocs = $event" previousLabel="" nextLabel=""
          responsive="true" [id]="'doc'"></pagination-controls>
          
          <ng-template #vide_doc>
            <p>acun document</p>
          </ng-template>
        </div>
      </div>
      
    
    </div>
  </div>
  
  
    <!-- <div class="table-responsive">
      <table class="table table-striped fieldlabels" style="font-size: 11px;">
        <thead>
          <tr class="text-center">
            <th scope="col"><ul>Liste requisitoires</ul></th>
            <th scope="col"><ul>Liste ordonnances</ul></th>
            <th scope="col"><ul>Liste piéces</ul></th>
            <th scope="col"><ul>Liste documents</ul></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <section class=" d-flex flex-column" *ngIf="list_requisitoires?.length > 0; else vide_req">
                <div class="card" *ngFor="let item of list_requisitoires | paginate: { itemsPerPage: 5, currentPage: pageReq, id: 'req' }"  (click)="openModalVisualrequistion(item)">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="fas fa-regular fa-file "></i>
                    <p class="text-start mt-1">{{ item.name }}</p>
                  </div>
                </div>
              </section>
              <pagination-controls  (pageChange)="pageReq = $event" previousLabel="" nextLabel=""
              responsive="true" [id]="'req'"></pagination-controls>
              <ng-template #vide_req>
                <p>acun requisitoire</p>
              </ng-template>
            </td>
            <td>
              <section class=" d-flex flex-column" *ngIf="list_ordonnances?.length > 0;else vide_ord">
                <div class="card"  *ngFor="let item of list_ordonnances | paginate: { itemsPerPage: 5, currentPage: pageOrd, id: 'ord' }"  (click)="openModalVisualrequistion(item)">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="fas fa-regular fa-file "></i>
                    <p class="text-start mt-1">{{ item.name }}</p>
                  </div>
                </div>
              </section>
              <pagination-controls  (pageChange)="pageOrd = $event" previousLabel="" nextLabel=""
              responsive="true" [id]="'ord'"></pagination-controls>
              <ng-template #vide_ord>
                <p>acune ordonnance</p>
              </ng-template>
            </td>
            <td>
              <section class=" d-flex flex-column" *ngIf="pieces?.length > 0; else vide_p">
                <div class="card" *ngFor="let item of pieces | paginate: { itemsPerPage: 5, currentPage: pagePiece, id: 'piece' }"  (click)="openModalVisualPiece(item)">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="fas fa-regular fa-file "></i>
                    <p class="text-start mt-1">{{ item.nom_du_fichier}}</p>
                  </div>
                </div>
              </section>
              <pagination-controls  (pageChange)="pagePiece = $event" previousLabel="" nextLabel=""
              responsive="true" [id]="'piece'"></pagination-controls>
              <ng-template #vide_p>
                <p>acune piece</p>
              </ng-template>
            </td>
            <td>
              <section class=" d-flex flex-column"  *ngIf="docs?.length > 0; else vide_doc">
                <div class="card" *ngFor="let item of docs | paginate: { itemsPerPage: 5, currentPage: pageDocs, id: 'doc' }"  (click)="openModalDetailDocument(item, 'document')">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="fas fa-regular fa-file "></i>
                    <p class="text-start mt-1">{{ item.name}}</p>
                  </div>
                </div>
              </section>
              <pagination-controls  (pageChange)="pageDocs = $event" previousLabel="" nextLabel=""
              responsive="true" [id]="'doc'"></pagination-controls>
              
              <ng-template #vide_doc>
                <p>acun document</p>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->


    
  
  
  
  
  
</body>