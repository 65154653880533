import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPrintDirective } from 'ngx-print';
import { NgxPrintElementService, Config } from 'ngx-print-element';
import { MyCONST } from '../../CONST/myConst';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import * as QRCode from 'qrcode';


@Component({
  selector: 'app-visualize-demandevisite',
  templateUrl: './visualize-demandevisite.component.html',
  styleUrls: ['./visualize-demandevisite.component.scss']
})
export class  VisualizeDemandevisiteComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;

  // type: string = "requisitoire";
  tribunal = MyCONST.TRIBUNAL
  tribunal_detail = MyCONST.DETAIL_TRIBUNAL

  profil: string ;
  @Input() parentModal: BsModalRef;

  @ViewChild('elementToPrint') printElement!:any;
  base64Image: string = '';

  @ViewChild(NgxPrintDirective) ngxPrint!: NgxPrintDirective;

  default_template = `
    <div class="second" style="min-height: 500px;">
    <section class="header d-flex justify-content-between"  >
      <div>
       
        <p class="text-center">${this.tribunal_detail}</p>
        <p>1er cabinet d'instruction</p>
      </div>
     
      

      <p class="" >
        <img style="width: 80px;height:80px;" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      
    </section>
    <h6 style="border: dashed 1px black; padding: 10px;height: 45px;margin: auto; text-align: center ">PERMIS DE VISITER UN DETENU PROVISOIRE</h6>

    
    <br><section >
     <div >
      <p>
        M. / Mlle / Mme $_prenom_visiteur_$ $_nom_visiteur_$ <br>
        Né(e) le $_date_naissance_$ à $_lieu_naissance_$ <br>
        Titulaire de la piéce d'identification N° $_num_piece_$, Délivrée le $_date_delivrance_$ <br>
        Profession $_profession_$ demeurant à $_adresse_$ <br>
        Est autorisé(e) en sa qualité de $_affiliation_$ à visiter, dans les conditions prévenues par la réglementation en vigeur, le (la) nommé(e) $_prenom_detenu_$ $_nom_detenu_$ <br>
        détenu(e) à $_lieu_detention_$
      </p> <br>
      
     </div>
     <div class="d-flex  justify-content-between">
      <div class="col-6">
        <u>VALIDITE DU PRESENT PERMIS:</u> Trois (03) mois <br>
        <u>NB:</u> Pour être valable, le permis doit être présenté avec une pièce d'identité du bénéficiaire.
      </div>
      <div>
        <p class="text-center" [innerHTML]="tribunal_detail"></p>
        <p>Le $_date_generation_$</p>
      </div>
     </div>

    </section><br>
    
  </div>
  `


  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
              private penalCourrierService: PenalCourrierService,
              private userCourrierService: UsercourrierService,
              public print: NgxPrintElementService,
              private datePipe: DatePipe,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
                console.log('dataaa', this.data);

 
    console.log({'the this.base64Image': this.base64Image});
    try{
      let tribunal_information:any = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
      if(tribunal_information.code){
        //console.log('UPDATE')
        this.tribunal= tribunal_information.tribunal
        this.tribunal_detail= tribunal_information.detail_tribunal
      }
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
    }

  }
  ngOnInit(): void {
    // this.type = this.data.type;
    this.default_template =   this.remplacerVariables( this.replacePlaceholders(this.data.template), this.data.content)

  }
  generateQRCode(text: string): void {
    QRCode.toDataURL(text, { errorCorrectionLevel: 'L' }, (err, url) => {
      if (err) throw err;
      this.base64Image = url; 
    });
  }
  // replacePlaceholders(template: string): string {
  //   return template
  //     .replace(/\$_tribunal_detail_\$/g, this.tribunal_detail)
  //     // Add other replacements as needed
  // }
  replacePlaceholders(template:any): string {
    let parsed_data_content;
    console.log('kkk',template);
       // content qr_code
       if (typeof this.data.content === 'object' && this.data.content !== null){
        parsed_data_content = this.data.content;
       }else{
        parsed_data_content = JSON.parse(this.data.content);
       }
      //  let parsed_data_content = JSON.parse(this.data.content);
       let content_qrcode = `${this.data.doctype}@${parsed_data_content.cabinet_instruction}` ;
    this.generateQRCode(content_qrcode)
    
    // Liste des cles  à remplacer avec leurs valeurs
    const placeholders: { [key: string]: string } = {
      '$_tribunal_detail_$': this.tribunal_detail,
      '$_tribunal_$': this.tribunal,
      '$_date_generation_$': this.getFormattedDate(this.data.creation) ,
      '$_the_qr_$': `<img style="width: 60px;height:60px;" src="${this.base64Image}" alt="QR Code">`

    };
  
    // Remplacer chaque clee avec sa valeur correspondante
    Object.keys(placeholders).forEach(placeholder => {
      // Créer une expression régulière dynamique pour chaque placeholder
      const regex = new RegExp(this.escapeRegExp(placeholder), 'g');
      const value = placeholders[placeholder];
  
      // Remplacer le clee par la valeur dans le template
      template = template.replace(regex, value);
    });
  
    return template;
  }
  
   escapeRegExp(string: string): string {
    return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'); // Échappe les caractères spéciaux
  }
  
  

  ngAfterViewInit() {
    const tribunalDetailElement = document.getElementById('tribunalDetail');
    if (tribunalDetailElement) {
        tribunalDetailElement.innerHTML = this.tribunal_detail;
    }
}

  remplacerVariables(template, donnees) {
    let mytemplate = template;
    let correspondanceTrouvee = false;
    console.log({'the te1': template});
    console.log({'the te1': donnees});
    if (typeof donnees === 'object' && this.data.content !== null){
      donnees = donnees

     }else{
      donnees = JSON.parse(donnees)
     }
    

    for (let key in donnees) {
      
      if (donnees.hasOwnProperty(key)) {
        //  let regex = new RegExp("$_" + key + "_$", "g");
        let regex = new RegExp("\\$_" + key + "_\\$", "g");

        // Vérifier si la valeur de l'attribut est null ou n'existe pas
        if (donnees[key] == null || donnees[key] === undefined) {
          mytemplate = mytemplate.replace(regex, '.........');
        } else {
          mytemplate = mytemplate.replace(regex, donnees[key]);
          correspondanceTrouvee = true;
        }
      }
    }

   let model_final =  this.filter_remplacerVariables(mytemplate,donnees)


    return model_final;
  }

  filter_remplacerVariables(template, jugement) {
    let mytemplate = template;
    let modeleFinal: any;

    // Expression régulière pour récupérer les mots commencant par $_ et terminant par _$
    let regexPattern = /\$_([^$]+)_\$/g;// Échapper les caractères spéciaux dans la chaîne de la RegExp

  modeleFinal = mytemplate.replace(regexPattern, '...........................')
    return modeleFinal;
  }


  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }




  public config: Config = {
    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    // htmlType: 'text',
    stylesheets: [{ rel: 'stylesheet', href: '../../../assets/style/css/bootstrap.min.css' }],

    styles: [
      `
      @media print {
      img{
        width: 80px;
        height: 80px;
      }
        @page {
          size: A4; /* Définit la taille de la page comme A4 */
          margin: 20px ; /* Supprime les marges par défaut */
          font-size:14px;
        }
          h6{
            text-align: center;
          }

        body {
          background-color: red;
          width: 210mm; /* Largeur de la page A4 en millimètres */
          height: 297mm; /* Hauteur de la page A4 en millimètres */
          margin: 20px; /* Supprime les marges par défaut */
          
        }
      
      }

      `

    ]
  }



  activePane = 0;



  onPrint2(el: any) {
    try {
      this.print.print(el, this.config).subscribe(console.log);

    } catch (error) {
      console.log('dddddd==> '+ error);


    }

  }
}
