import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { DetailViewComponent } from '../../widgets/detailsview/detailView.component';
import { AddDossierComponent } from '../add-dossier/add-dossier.component';
import { MyCONST } from '../../CONST/myConst';
import { Config, NgxPrintElementService } from 'ngx-print-element';
import {AlertesService} from "../../services/public/alertes.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-visualize-casier',
  templateUrl: './visualize-casier.component.html',
  styleUrls: ['./visualize-casier.component.scss'],

})
export class VisualizeCasierComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  dataAllInformation:any;
  dataPiecesJointes:any  =[];
  type: any;
  isMultiple: any;
  courriers: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  isButtonDisabled: boolean = true;
  verification : String  = MyCONST.DOMAIN+ MyCONST.VERIFICATION_URI
  Tribunal:any =MyCONST.DETAIL_TRIBUNAL

  profil: string ;
  @Input() parentModal: BsModalRef;

  @ViewChild('elementToPrint') printElement!:any;

  emptyInfractions: any = `
  <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
  `



  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
              private alerte:AlertesService,
              public print: NgxPrintElementService,
            private datePipe: DatePipe,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.type = this.options.initialState.type;
    this.isMultiple = this.options.initialState.isMultiple;
    console.log('inVisualize: ismultiple=>',this.isMultiple);
    console.log('inVisualize: data=>',this.data);
    
    try{
      let tribunal_information:any = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
      if(tribunal_information.code){
        //console.log('UPDATE')
        this.Tribunal= tribunal_information.detail_tribunal
        // this.tribunal_detail= tribunal_information.detail_tribunal
      }
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
    }

  }

  public config: Config = {
    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    
    // htmlType: 'text',
    stylesheets: [],

    styles: [
      `
      .mypage {
  min-height: 297px; 
  page-break-after: always;
}
      @page {
        size: A4;
        margin: 15;
        page-break-before: always;

      }
        @media print {

        
      /* Base styles */
      /* Base styles */
      body {
        margin: 15;
        padding: 10;

        background: #f0f0f0;
        font-family: 'Arial', sans-serif;
        // height: 100%;
          min-height: 297px; /* Ajustez la hauteur minimale selon vos besoins */
  page-break-after: always;
        page-break-before: always;
        tr{
          font-size: 11px;
        }
        
      }
        .mypage
          page-break-before: always;
        }

      /* Styles to ensure content fits A4 page size */


      header, .records-table, .stamps-and-verification, footer {
      padding: 10px;
      text-align: center;
      }

      header {
        border: none;
        padding: 10px;
        margin-top: 10px;
      }
      .header{
        display: flex;
        justify-content: space-between;
      }
        .document-container{
            min-height: 297px;
            page-break-before: always;
        }

      .left-aligned h1, .left-aligned h2, .left-aligned h3 {
        text-align: left;
        margin: 0;
        padding: 0;
      }

      .center-aligned h4, .center-aligned h5, .center-aligned p {
        text-align: center;
        margin: 0;
        padding: 0;
      }

      .center-aligned h4 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px; /* Add space above the centered text */
      }

      .center-aligned h5 {
        font-size: 16px;
        margin: 5px 0;
      }

      .center-aligned p {
        font-size: 14px;
        margin: 5px 0;
      }


      hr {
        border: none;
        height: 1px;
        background-color: #000;
        margin: 5px auto; /* Center the horizontal line */
        width: 80%; /* Adjust as needed */
      }


      .content {
      display: flex;
      justify-content: space-between;
      margin-top: 10%;
      margin-bottom: 0px; /* Spacing before the table */
      }

      .mfooter{
        width:100%;
        margin:auto;
        display: flex;
        justify-content: space-between;
      }



      .greffe {
      flex-grow: 1;
      }

       .form {
        flex-grow: 1;
        margin-top: -25px;
        }

      .records-table table {
      width: 100%;
      border-collapse: collapse;



      }

      .records-table th, .records-table td {
      border: 1px solid #000;
      padding: 8px;
      text-align: center;
      }

      .records-table th {
      background-color: #cff4cf;
      }


      .field {
      margin-bottom: 5px;
      }

      .field label {
      display: block;
      margin-bottom: 0px;
      }
      .qr-code {
        width: 150px;       /* Définir la largeur */
        height: 150px;      /* Définir la hauteur */

        padding: 0px;      /* Ajouter du padding autour du QR Code (facultatif) */
        margin: 0px;       /* Ajouter des marges autour de l'image (facultatif) */
      }
      .field .input {
      border-bottom: 1px solid #000;
      text-align: center;
       font-size: 16px;
      width: 100%;
      height: 20px;
      }

      footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;
        margin-top: 10px;
      }

      .stamps {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      

      .text-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 60%;
      }

      .visa {
        text-align: center;
        margin-top: 0px;
        margin-left:20px;
      }
      .verification {
        text-align: left;
        margin-top: 0px;
        margin-left:20px;
      }

      .visa p, .verification p {
        margin: 0;
        padding: 0;
      }
      .mfooter{
        width:100%;
        margin:auto;
        display: flex;
        flex-direction: row
        justify-content: space-between;
      }

      @media (max-width: 768px) {

        .mfooter{
          width:100%;
          margin:auto;
          display: flex;
          flex-direction: row
          justify-content: space-between;
        }

        .text-content {
          flex-direction: row;
          align-items: flex-start;
        }

        .visa, .verification {
          text-align: left;
          margin-left: 0;
        }
        .content {
            flex-direction: row;
            margin-top: -4%;
          }
          .greffe {
            flex-grow: 1;
            }

             .form {
              flex-grow: 3;
              margin-top: -25px;
              }


          .greffe {
            margin-right: 0;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          .myqr{
            margin-left: -20px;
          }
      }


      /* Print-specific styles */
      `
    ]
  }



  activePane = 0;



  onPrint2(el: any) {
    if(this.type=='unprint'){
      this.alerte.warningNotification('Casier','Impossible d\'imprimer en mode apercu !')
    }else {
      try {
        this.print.print(el, this.config).subscribe(console.log);

      } catch (error) {
        console.log('dddddd==> '+ error);
      }
    }
  }





  // afficherContenu(id: string) {
  //   console.log('hhhhhhhhh');

  //   const element = document.getElementById(id);
  //   switch (id) {

  //     case 'idp':

  //     $('.idp').addClass('active');
  //     $('.infred').removeClass('active');
  //     $('.detail').removeClass('active');
  //     $('.elt').removeClass('active');
  //       break;



  //     default:
  //       break;
  //   }

  // }


  ngOnInit() {
    console.log('data casier',this.data);

    // this.onPrint2(this.printElement)

  }
  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
}


