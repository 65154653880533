
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<!-- <div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div><br> -->
<div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span style="font-size: 12px;" class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div><br>
<body #elementToPrint ngxPrintElement style="margin: 10px; text-align: center;">

<!-- the second -->

  <br><div class="second" style="min-height: 500px;">
    <section class="header d-flex justify-content-between"  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      <p>République du Sénégal - Ministére de la justice <br> </p>
      

      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      
    </section>
    <h6 class="text-center" [innerHTML]="tribunal_detail"></h6>
    <section>
      <span class="float-left">N° du Parquet :............</span><br>
  
      <span class="float-left">N° de l’instruction :............</span>
      <div class="d-flex flex-column justify-content-between" >
       <!-- <div>
           <div class="d-flex justify-content-start align-items-center">
            <span><b>N° audience:</b>   {{data.numero_audience}}</span>
          
          </div>
          <div>
             <span><b>Parties prenante:</b> </span><br>
          {{data.parties_prenantes}}
          </div> -->

          <!-- <div *ngIf="type == 'requisitoire'">
            <p>Parquet du Procureur de la <br> République</p><br>
            <p>Substitut :.....</p><br>
          </div>
  
          <div  *ngIf="type == 'ordonnance'">
            <p>Cabinet de M........ <br> Juge d'instruction</p><br>
          </div> 
  
         
  
        

        </div>-->
        

        <!-- <div>
          <div class="d-flex align-items-center">
            <span><b>Date audience:</b>   {{getFormattedDate(data.date_audience)}}</span>
           
          </div>
          <div>
          
            <b>Décision audience:</b><br>
            {{data.decision}}
          </div>

        </div> -->
      </div>
    </section><br>
    <section>
      <h6 class="text-center">{{data.tag}}</h6>
      <div  [innerHTML]="data.continu" style="padding:20px; text-align: left;">
      </div>
      <!-- {{data.continu}} -->
    </section>
    
  </div>


</body>
</html>








