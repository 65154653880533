<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
            <div class="d-flex justify-content-center">
              <h5 id="heading" class="text-dark" style="font-size: 12px;">Nouveau Casier</h5>
              <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
                <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
              </button>
            </div>
              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform"  #form="ngForm" (ngSubmit)="onSubmit(form)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Informations du casier</strong></li>
                      <!-- <li id="step2"><strong>Information du sujet</strong></li> -->


                  </ul><br>
                  <!-- <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br>  -->
                  <!-- fieldsets -->
                  <fieldset class="step1">
                    <div class="d-flex justify-content-end mb-2">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" (click)="ActiveMultipleCasier()" id="customSwitch1">
                        <label class="custom-control-label" for="customSwitch1">Plusieurs casiers ?</label>
                      </div>
                      <div class="col-auto" *ngIf="isMultipleCasier">
                        <input class="form-control" type="text" name="nbr_casier" placeholder="nombre de casier" [(ngModel)]="number_casier" />
                      </div>
                    </div>
                    
                    <div class="form-card">
                      <div class="row justify-content-center mb-3">
                        <div class="col-sm-12 col-md-6">
                          <label class="fieldlabels">Numéro du casier: </label>
                          <input class="form-control" type="text" name="num_casier" [ngModel]="data ? data.num_casier : '' " />
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Type de casier: </label>
                          <select class="custom-select" (change)="filterValueChange($event.target.value,'type_casier')" name="type_casier" [ngModel]="data ? data.type_de_casier : '' ">
                            <option value="" *ngIf="data">{{data.type_de_casier}} </option>
                            <option value="B1">B1</option>
                            <option value="B2">B2</option>
                            <option value="B3">B3</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-6"  style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Situation matrimonial: </label>
                          <select class="custom-select" name="situation_matrimonial" [ngModel]="data ? data.situation_matrimonial : '' ">
                            <option value="" *ngIf="data" >{{data.situation_matrimonial}} </option>
                            <option value="Célibataire">Célibataire</option>
                            <option value="Marié(e)">Marié(e)</option>
                            <option value="veuf(ve)">Veuf(ve)</option>
                            <option value="Divorcé(e)">Divorcé(e)</option>
                          </select>
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6"  style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Type d'identifiant du demandeur: </label>
                          <select class="custom-select" (change)="filterValueChange($event.target.value,'id_type')" name="id_type" [ngModel]="data ? data.type_didentification : '' ">
                            <option value="" *ngIf="data" >{{data.type_didentification}} </option>
                            <option *ngFor="let id of identification_type" [value]="id.name">{{ id.type }}</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Numéro d'identification: </label>
                          <input class="form-control" type="text" name="id_num" [ngModel]="data ? data.numero_didentication : '' " />
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Prenom: </label>
                          <input class="form-control" type="text" name="prenom" [ngModel]="data ? data.prenom : '' " />
                        </div>
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Nom: </label>
                          <input class="form-control" type="text" name="nom" [ngModel]="data ? data.nom : '' " />
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Genre: </label>
                          <select class="custom-select" name="genre" [ngModel]="data ? data.genre : '' ">
                            <option  *ngIf="data" value="">{{data.genre}}</option>
                            <option value="M">Homme</option>
                            <option value="F">Femme</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Nationalité: </label>
                          <select class="custom-select" name="nationalite" [ngModel]="data ? data.nationalite : '' ">
                            <option value=""  *ngIf="data">{{data.nationalite}} </option>
                            <option value="Sénégalais(e)">Sénégalais(e)</option>
                          </select>
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Nom complet du père: </label>
                          <input class="form-control" type="text" name="nom_pere" [ngModel]="data ? data.nom_du_pere : '' "  />
                        </div>
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Nom complet de la mère: </label>
                          <input class="form-control" type="text" name="nom_mere" [ngModel]="data ? data.nom_de_la_mere : '' " />
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Date de naissance: </label>
                          <input class="form-control" type="date" name="date_de_naissance" [ngModel]="data ? data.date_de_naissance : '' " />
                        </div>
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Lieu de naissance: </label>
                          <input class="form-control" type="text" name="lieu_de_naissance" [ngModel]="data ? data.lieu_de_naissance : '' "  />
                        </div>
                      </div>
                    
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Domicile: </label>
                          <input class="form-control" type="text" name="domicile" [ngModel]="data ? data.domicile : '' " />
                        </div>
                        <div class="col-sm-12 col-md-6" style="padding: 0 5px 0 0;">
                          <label class="fieldlabels">Profession: </label>
                          <input class="form-control" type="text" name="profession" [ngModel]="data ? data.profession : '' " />
                        </div>
                      </div>
                    
                      <div class="shadow col-md-12">
                        <label class="fieldlabels bg-dark text-light p-2"><b>Condamnations</b>:</label>
                        <i class="fas fa-solid fa-plus text-success" id="addRowBtn" style="font-size: 20px;"></i>
                        <table id="myTable" class="table table-striped text-nowrap table-responsive text-center m-auto" style="font-size: 12px;">
                          <thead class="text-dark">
                            <tr>
                              <th>Date des Condamnations</th>
                              <th>Cours ou Tribunaux</th>
                              <th>Nature infractions</th>
                              <th>Crimes ou Délits</th>
                              <th>Nature et durée des peines</th>
                              <th>Observations</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- Lignes ajoutées ici dynamiquement via le jquery -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <input type="submit" name="next" class=" action-button " value="Générer"  />

                  </fieldset >

              </form>
          </div>

          <!-- <ngx-ui-loader></ngx-ui-loader> -->
      </div>
  </div>
</div>
