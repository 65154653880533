



<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">


        <!-- formulaire courrier entrant -->
          <div class="card" >
            <div class="d-flex justify-content-center">
              <h5 id="heading" class="text-dark"  style="font-size: 12px;">{{IS_AVIS==1?'Mon Avis Sur le Dossier':'Ajouer '+type }}</h5>
              <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeModal()" >
                <i class="fas fa-times text-dark" style="font-size: 1.5rem; "></i>
              </button>
            </div>
              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform"  #requisitoire="ngForm" >
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Informations</strong></li>


                  </ul>
                 <br> <!-- fieldsets -->
                  <fieldset class="step1">
                      <div class="form-card">
                        <div class="row">
                          <div class="col-md-3 col-sm-10">
                            <div class="checkbox-wrapper-33">
                              <label class="checkbox">
                                <input class="checkbox__trigger visuallyhidden" (click)="OnCheckChange()" type="checkbox" [checked]="can_change_dossier_step"/>
                                <span class="checkbox__symbol">
                                  <svg aria-hidden="true" class="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 14l8 7L24 7"></path>
                                  </svg>
                                </span>
                                <p class="checkbox__textwrapper">Transferer le dossier</p>
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-10">
                            <label class="fieldlabels">Destinataire:</label>
                            <select [disabled]="!can_change_dossier_step" class="custom-select" id="inputGroupSelect01" name="destinataire" (change)="onChangeDestinataire($event)"  ngModel>
                              <option value="" selected >--Choisir le {{PROFIL_TO_SELECT}}--</option>
                              <option *ngFor="let desti of ALL_DESTINATAIRE" value="{{IS_CABINET_INSTRUCTION==1? desti.name:desti.parent}}" >{{IS_CABINET_INSTRUCTION==1? desti.nom:desti.parent}}</option>
                            </select>
                          </div>&nbsp;
                          <div class="col-md-4 col-sm-10">
                            <label class="fieldlabels">type:</label>
                            <input [disabled]="!can_edit_tag" class="custom-select" id="inputGroupSelect01" [ngModel]="selectedTag" name="tag_custom" ngModel>

                          </div>
                        </div>
                        <div class="container">
                          <div class="form cf">
                            <section class="payment-plan cf row d-flex justify-content-between" ngModel style="width: 100%;">
                              <div class="col-6 col-sm-4 col-md-3 rounded-circle mt-2" *ngFor="let tag of TYPE_DE_TAG;">
                                <input type="radio" (change)="onTagChange(tag.name)" value="{{tag.name}}" name="tag" id="{{tag.name}}" class="visually-hidden">
                                <label class="monthly-label four col text-center" for="{{tag.name}}">
                                  <img src="https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg" width="50" height="50" alt="" class="img-fluid mb-1">
                                  <p style="font-size: 10px; font-style: normal;">{{tag.name}}</p>
                                </label>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div class="col d-flex  flex-column justify-content-between " >

                          <st-editor name="continu" [ngModel]="DEFAULT_TEXT" class="area_input"  ngModel></st-editor>



                        </div>



                          <!-- <div class="row">
                            <div class="col">



                                  <div class="container">
                                    <div class="form cf">
                                      <section class="payment-plan cf row d-flex justify-content-between"   ngModel style="width: 100%;">
                                      <div class="col-3 rounded-circle mt-2 " *ngFor="let tag of TYPE_DE_TAG;">
                                          <input type="radio"  (change)="onTagChange($event.target.value)" value="{{tag.name}}" name="tag" id="{{tag.name}}" >
                                          <label class="monthly-label four col" for="{{tag.name}}"><img src="https://infomed.sn/wp-content/uploads/2021/05/Justice-860x490.jpg" width="50" height="50" alt="">
                                            <p style="font-size: 11px;">{{tag.name}}</p>
                                          </label>
                                      </div>
                                    </section>


                                    </div>
                                    &nbsp;&nbsp;
                                </div>

                            </div>&nbsp;&nbsp;
                            <div class="col d-flex  flex-column justify-content-between ">

                              <div>
                                <input [disabled]="!can_edit_tag" class="custom-select" id="inputGroupSelect01" [ngModel]="selectedTag" name="tag_custom" ngModel>
                              </div>
                              <st-editor name="continu" [ngModel]="DEFAULT_TEXT" class="area_input"  ngModel></st-editor>



                            </div>
                          </div> -->


                      </div> <input (click)="addRequisitoireOr(requisitoire)" name="next" class="next action-button" value="Enregistrer" />
                  </fieldset >


              </form>
          </div>


      </div>
  </div>
</div>

