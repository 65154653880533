import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder, NgForm} from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import {DossierService} from "../../services/frapper/dossier.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {AlertesService} from "../../services/public/alertes.service";
import {CustomService} from "../../services/frapper/custom.service";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {MyCONST} from "../../CONST/myConst";
import {VisualizePieceComponent} from "../visualize-piece/visualize-piece.component";
import { AddAssociationPrevenuComponent } from '../add-association-prevenu/add-association-prevenu.component';
import { DetailsPrevenuComponent } from '../details-prevenu/details-prevenu.component';
import { ViewDetailsDossierComponent } from '../view-details-dossier/view-details-dossier.component';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { AddInfractionsComponent } from '../add-infractions/add-infractions.component';
import { FormStepperComponent } from '../add-courrier/form-stepper.component';
import {AssignedService} from "../../services/frapper/assigned.service";


@Component({
  selector: 'ngx-add-dossier',
  templateUrl: './add-dossier.component.html',
  styleUrls: ['./add-dossier.component.scss']
})
export class AddDossierComponent {
  @Input() parentModal: BsModalRef;

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  step:any;
  modalRef!: BsModalRef;
  is_multiple_prevenu: number ;

  my_tribunal:any = null

  classifications:any =[];
  natures:any =[];
  identification_type:any =[];
  InternalPieces:any =[]
  prevenusList: any[] = [];
  CAN_SEE_REMARQUE_PROCUREUR:boolean=false
  CAN_NOT_CHANGE_REMARQUE_PROCUREUR:boolean=true
  profil
  profil_initial_step=[
    {
      "role": "AGENT DU COURRIER",
      "step_dossier":'Procureur',
      "step_courrier":'Procureur'
    },
    {
      "role": "AGENT DU COURRIER CIVIL",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER FAMILIAL",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER SOCIAL",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER PRESIDENT",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER GREF",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "PROCUREUR",
      "step_dossier":'Procureur',
      "step_courrier":'Procureur'
    },
    {
      "role": "SUB PROCUREUR",
      "step_dossier":'Substitut du procureur',
      "step_courrier":'Substitut du procureur'
    },
    {
      "role": "GREFFIER",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "GREFFIER EN CHEF",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "JUGE D'INSTRUCTION",
      "step_dossier":'Juge d\'instruction',
      "step_courrier":'Juge d\'instruction'
    },
    {
      "role": "JUGE",
      "step_dossier":'Juge',
      "step_courrier":'Juge'
    },
    {
      "role": "PRESIDENT DU TRIBUNAL",

      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT ENROLEMENT",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "AGENT ENROLEMENT PENAL",
      "step_dossier":'Procureur',
      "step_courrier":'Procureur'
    }
  ]

  infractions: any[] =[]
  gettedInfractions: any[] =[];
  createCourrierOnFirst: boolean = false;
  showAddCourrier: boolean = true;
  ALL_DESTINATAIRE:any =[]

  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private formBuilder: FormBuilder,
              private dossierService:DossierService,
              private assigneService:AssignedService,
              private customservice:CustomService,
              private ngxService: NgxUiLoaderService,
              private alerte:AlertesService,
              private zone: NgZone,
              private enrole: EnrollementService,
              public bsModalRef: BsModalRef,

              private userCourrierService:UsercourrierService,
              private penalService: PenalCourrierService) {
    this.profil = localStorage.getItem('profil');
    this.getInitialStepOfDossier()
    this.data = options.initialState
    this.data.showAddCourrier != null ? this.showAddCourrier =  this.data.showAddCourrier: this.showAddCourrier =true
    console.log('courrier ==> ',this.data)
    try {
      this.InternalPieces = this.data.data.pieces_jointe_au_courrier
    }catch (e) {
      //this.InternalPieces =[]
    }
    console.log(this.InternalPieces)
    this.step = options.initialState.step;

  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message);
  }

  openModalAddCourrier(type_courrier: string) {
    const config: ModalOptions = {
      initialState: {
        typeCourrier: type_courrier,
        isSubmitableForm: false,
        parentModal: null // Initialiser parentModal à null
      }
    }
    return new Promise<any>((resolve, reject) => {
      this.modalRef = this.modalService.show(FormStepperComponent, Object.assign({}, config, {class:'modal-lg  modal-newcourrier'}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe((value: any) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
      });
    }).then(async (value: any) => {
      if (value.classification != null) {
        this.data = value
        this.step = 'step3'
        this.createCourrierOnFirst = true
        this.InternalPieces = this.data.values_to_upload
        this.initializeStepper()
      }

      // this.returnedValue = value;
      // if ( this.returnedValue == "success") {
      //   await this.getCourriersFromBackEnd()
      // }
      console.log('returned object=> ',  value);


    });

  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  doAction(){
    if(this.profil == 'PROCUREUR' || this.profil =='SUB PROCUREUR'){
      this.CAN_SEE_REMARQUE_PROCUREUR = true
      if(this.profil == 'PROCUREUR'){
        this.CAN_NOT_CHANGE_REMARQUE_PROCUREUR=false
      }
    }
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg '}));
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  selectTypePrevenu(event: any){

    const isChecked = event.target.checked;
    if (isChecked) {
      this.is_multiple_prevenu = 1;
    }else{
      this.is_multiple_prevenu = 0;
    }
    console.log('is_multiple_prevenu==>',this.is_multiple_prevenu);


}

  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.step );
    var stepFirst = this.step;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          // percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });
  }


  getInitialStepOfDossier(){
    let init_step =''
    let profil_step = this.profil_initial_step.filter(element => element.role?.toUpperCase() == this.profil);
    console.log('profil_step ==> ',profil_step)
    if(profil_step.length > 0){
      init_step = profil_step[0].step_dossier
    }
    console.log('init_step ==> ',init_step)
    return init_step
  }

  onClassificationChange(event: any) {
    let chaine = event.target.value;
    console.log('this.imageFile ==> ',chaine)
    let nature_filter='[["type_de_courrier" , "=","'+chaine+'"]]'
    this.userCourrierService.getAllowedNatureCourrier(nature_filter).then( res =>{
      //console.log(res.data)
      this.natures = res.data
    })
  }

  async createNewCourrierForDossier(body_content){
    try {
      // body_content['workflow_state'] = 'Secrétariat du procureur'
      console.log('For calling api ==> ')

      await this.userCourrierService.nouveauCourrier(body_content).then( res => {
        console.log('Succe create courrier ==> ', res)

        this.data = res.data
        let courrier_name = res.data.name
         for (let val of body_content['values_to_upload']){
           val['parent']=res.data.name
         }
         let toUpDate={}
         toUpDate['pieces_jointe_au_courrier']= body_content['values_to_upload']
         this.customservice.updateTheDoctype(this.userCourrierService.Docatype_courrier_principal,courrier_name, toUpDate).then(res =>{
           //console.log('Succe ==> ',res)
           this.assigneService.doAssignationForRequiredProfil(this.profil,this.userCourrierService.Docatype_courrier_principal,courrier_name)

          this.userCourrierService.updateSatate(courrier_name,'En cours de traitement').then(res => {
            //  this.openModalSuccessDialog('Courrier enregistrer ')
            this.alerte.successNotification('Courrier','Courrier enregistrer!')
              this.ngxService.stop()
             this.closeWithMessage('success');

            //  this.openModalCourrierDetails(res.data)
           },err =>
           {
              this.ngxService.stop()
            //  this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')
             console.log('Error ==> ',err)
           })
           //this.openModalSuccessDialog('Piece Ajoutee')
            this.ngxService.stop()
         },error=>{
           console.log('IN ADD DOCTYPE ERR ==> ',error)
            this.ngxService.stop()
           this.alerte.errorNotification('Pieces','Une erreur s\'est produite!')
         })

        //  this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')


       return

     },
       error =>{
       console.log('Error ==> ',error)
       this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')

          this.ngxService.stopLoader('form_step')
       })
    } catch (error) {
      console.log('erroooorrrrr ',error);

      this.alerte.errorNotification('Une erreur s\'est produite, verifier les données saisies ou vos autorisations')

    }
  }


  async AddNewDossier(dossier: NgForm){
    this.ngxService.start();
    let init_step =''
    // console.log('Form ===> ', dossier.value)
     init_step= this.getInitialStepOfDossier()

    if(dossier.value.reference ==''){
      dossier.value.reference='Non'
    }
    if (this.prevenusList.length == 0) {
     let prevenu = {

        addresse_complete_du_poursuivis: dossier.value['addresse_complete_du_poursuivis'],
        date_de_naissance_du_prévenu: dossier.value['date_de_naissance_du_prévenu'],
        lieu_de_naissance_du_prévenu: dossier.value['lieu_de_naissance_du_prévenu'],
        nom_complet_de_la_mere_du_prévenu: dossier.value['nom_complet_de_la_mere_du_prévenu'],
        nom_complet_du_pere_du_prévenu: dossier.value['nom_complet_du_pere_du_prévenu'],
        nom_complet_du_poursuivis: dossier.value['nom_complet_du_poursuivis'],
        numéro_didentification_du_poursuivis: dossier.value['numéro_didentification_du_poursuivis'],
        type_identification_du_poursuivis: dossier.value['type_identification_du_poursuivis'],
     }
     this.prevenusList.push(prevenu);
    }

    dossier.value["registre"]= 'Courrier Arrivé'

    if(this.data){
      dossier.value["registre"]= this.data.registre
      dossier.value["creation_unite"] = this.data.creation_unite
      dossier.value["position_unite"] = this.data.position_unite
      dossier.value["annotation"] = this.data.annotation
    }
    dossier.value["docstatus"]= 0

    dossier.value["pieces_jointe_au_courrier"]= this.InternalPieces

    let pieces_jointe_au_dossier =[]
    for (let piece of this.InternalPieces){
      let body =  {
          "nom_du_fichier": piece.nom_du_fichier,
          "fichier": piece.fichier,
          "parentfield": "pieces_jointe_au_dossier",
          "parenttype": "Dossier",
          "doctype": "Fichiers joins a un dossier"
        }
      pieces_jointe_au_dossier.push(body)
    }

   this.getValuesFromTableInfraction()
    // console.log('this.lignes ==> ', this.lignes);

    // for(let lg of this.lignes){
    //   let to_add ={
    //     "nature": lg.debut,
    //     "date": lg.fin,
    //     "texte": lg.main_leve
    //   }
    //   infractions.push(to_add)
    // }

    dossier.value["pieces_jointe_au_dossier"]= pieces_jointe_au_dossier
    dossier.value['is_multiple_prevenu'] = this.is_multiple_prevenu
    dossier.value['association_de_prevenu'] = this.prevenusList
    dossier.value['table_infractions'] = this.infractions

    if(this.my_tribunal !=null){
      dossier.value['tribunal'] = this.my_tribunal.name
    }

    console.log('Form ===> ', dossier.value)

    // si createCourrierOnFirst creer le courrier d'abord
    if (this.createCourrierOnFirst) {
      try {
        await this.createNewCourrierForDossier(this.data);
        // si un nveau courrier est creer ajout de la clee name dans data.
        // this.data.name = this.data.numero_courrier
      } catch (error) {
        console.log('dfffdff', error);

      }

    }



    this.dossierService.nouveauDossier(dossier.value).then( (res:any) => {
      console.log('REsult ---> ', res)
      this.dossierService.updateSatate(res.data.name,init_step).then(ress => {
          this.assigneService.doAssignationForRequiredProfil(this.profil,this.dossierService.Doctype_dossier,res.data.name)

        // this.openModalSuccessDialog('Dossier enregisté avec success')
        this.alerte.successNotification('Dossier','Dossier enregistré avec succés')
        this.closeWithMessage("success")
        this.openModalDetailsDossier(res.data)
          this.ngxService.stop();
      },
        error =>{
          this.alerte.errorNotification('Mise a jour du dossier','Impossible de mettre a jour le dossier')
          console.log('Error On update==> ',error)
          this.ngxService.stop();
        })

      let update_courrier_infor ={
        "numero_dossier":res.data.name,
        "status_du_dossier":init_step
      }

      console.log('before update courrier',this.data.name,update_courrier_infor);


      this.userCourrierService.updateCourrier(this.data.name,update_courrier_infor).then(res=>{
        console.log('Success ==> ',res)

      },error=>{
        console.log('Error On Update courrier => ',error)
      })
    },
      error =>{
        this.alerte.errorNotification('Creation de dossier','Impossible de creer le dossier')
        console.log('Error ==> ',error)
        this.ngxService.stopLoader('add_dossier');
      })


  }

  openModalDetailsDossier(data: any) {
    // let courrier:any = this.courriers[id];
    // console.log('details === '+ courrier);

    const config: ModalOptions = {
      initialState: {
        data: data,
        type: 'dossier'
      }
     }
    // this.modalRef = this.modalService.show(ViewDetailsDossierComponent, Object.assign({},config,{class:'modal-xl '}));
    return new Promise<string>((resolve, reject) => {
      this.modalRef = this.modalService.show(ViewDetailsDossierComponent, Object.assign({}, config, {class:'modal-xl modal-dossier '}));
      this.modalRef.content.parentModal = this.modalRef;
      this.modalRef.onHidden.subscribe((value: string) => {
        resolve(value); // Résoudre la promesse avec la valeur retournée
      });
    }).then(async (value: string) => {
      if ( value == "success") {
         this.closeWithMessage("")
      }


    });

  }

  closeModal(){
    this.bsModalRef.hide()
  }


  openModalAddPrevenu() {
    const config: ModalOptions = {
      initialState: {
        type: 'dossier',
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(AddAssociationPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {
      console.log('Nouveau prévenu ajouté :', newPrevenu);
      this.prevenusList.push(newPrevenu);
      console.log('Nouveau prévenu ajouté==> :', this.prevenusList);
    });
  }

  openModalDetailsPrevenu(prevenu:any) {
    const config: ModalOptions = {
      initialState: {
        url:URL,
        data: prevenu,
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(DetailsPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {

      let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
      if (existingPrevenuIndex != null) {
        existingPrevenuIndex = newPrevenu;
      }

      console.log('findeddd :', existingPrevenuIndex);
      // this.prevenusList.push(newPrevenu);

    });
  }
  deleteInfraction(element: any){
    this.infractions = this.infractions.filter(item => item != element);
  }
  openModalVisualPiece(data: any) {
    const URL=MyCONST.API_URL+data.fichier
    const config: ModalOptions = {
      initialState: {
        data: URL,
        url:URL,
        parentModal : null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    // Mettre à jour l'option initialState avec le modalRef
  }

  filterValueChange(value,type){
    if(type=='nature'){
     this.infractions[0].nature = value
    }
    if(type=='date'){
      this.infractions[0].date = value
    }
    if(type=='textes'){
      this.infractions[0].textes = value
    }
  }
  // deleteContrainte(element: any){
  //   this.lignes = this.lignes.filter(item => item != element);
  // }

  // for(let lg of this.lignes){
  //   let to_add ={
  //     "docstatus": 1,
  //     "debut": lg.debut,
  //     "fin": lg.fin,
  //     "main_leve": lg.main_leve,
  //     "parent": this.data.name,
  //     "parentfield": "table_tqhi",
  //     "parenttype": "Jugements",
  //     "doctype": "fiche_a_classer_contrainte_par_corps"
  //   }
  //   table_tqhi.push(to_add)
  // }

  getValuesFromTableInfraction() {
    console.log('in methode');

    this.infractions = [];

    // Use an arrow function to maintain the correct 'this' context
    $('#myTable tbody tr').each((index, row) => {
        const nature = $(row).find('input[name="nature"]').val();
        const date = $(row).find('input[name="date"]').val();
        const textes = $(row).find('input[name="textes"]').val();

        this.infractions.push({
            nature: nature,
            date: date,
            textes: textes
        });
    });

    console.log('Les infractions:', this.infractions);
}




  ngOnInit() {

    this.doAction()
    this.is_multiple_prevenu = 0;
    //console.log('This.data -----> ', this.data)
    try {
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
    }catch (e) {
      this.my_tribunal =null
    }
    this.userCourrierService.getAllowedClassification().then( res =>{
      //console.log(res.data)
      this.classifications = res.data
    })
    this.userCourrierService.getAllowedNatureCourrier().then( res =>{
      console.log(res.data)
      this.natures = res.data
    })

    this.customservice.getIdentificationType().then(res=>{
      this.identification_type = res.data

    })

    this.enrole.getAllInfractions().then(res=>{
      this.gettedInfractions = res.data

    })


    var self = this;
    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();
      console.log('jjbjjn');
        // Ajouter une nouvelle ligne au tableau
        $('#addRowBtn_inf').on('click', function() {

        });

        // Gérer la suppression d'une ligne
        $('#myTable').on('click', '.delete-row', function() {
          $(this).closest('tr').remove(); // Supprimer la ligne parente (tr) du clic sur l'icône poubelle (delete-row)
        });


    })


    // table infractions
    $('#addRowBtn_inf').on('click', function() {
      const newRow = '<tr>' +
        '<td><input type="text" class="form-control" name="nature" />' +
        '<div class="list_item dropdown-menu"><ul style=" cursor: pointer;max-height:200px;overflow: auto"></ul></div>' +
        '<p class="no-match-suggestion" style="display:none; color: blue; cursor: pointer;">Ajouter "<span class="new-item"></span>" à la liste d\'infraction</p></td>' +
        '<td><input type="date" class="form-control" name="date" /></td>' +
        '<td><input type="text" class="form-control" name="textes" /></td>' +
        '<td>' +
        '<i class="fas fa-solid fa-trash delete-row text-danger  mt-2" style="font-size:22px; cursor:pointer"></i>' +
        '</td>' +
        '</tr>';

      // Ajoutez la nouvelle ligne au tableau
      $('#myTable tbody').append(newRow);

      // Cachez les éléments .list_item après l'ajout de la ligne
      $('#myTable tbody tr:last .list_item').hide();

      // Chargez la liste des éléments depuis l'API
      loadListItems($('#myTable tbody tr:last'));

      // Focus sur le champ "nature" pour afficher le dropdown
      $('#myTable tbody tr:last input[name="nature"]').on('focus', function() {
        $(this).closest('tr').find('.list_item').show();
      });

      // Optionnel: Ajouter un gestionnaire de blur pour cacher la liste quand on quitte le champ
      $('#myTable tbody tr:last input[name="nature"]').on('blur', function() {
        // Ajouter un délai pour éviter que la liste se cache immédiatement après la sélection d'un élément
        setTimeout(() => {
          $(this).closest('tr').find('.list_item').hide();
        }, 200);
      });

      // Filtrage de la liste pendant la saisie
      $('#myTable tbody tr:last input[name="nature"]').on('input', function() {
        const searchTerm = $(this).val().toLowerCase(); // Récupérer la valeur en minuscules
        const listItems = $(this).closest('tr').find('.list_item ul li'); // Sélectionner tous les éléments de la liste
        const noMatchSuggestion = $(this).closest('tr').find('.no-match-suggestion');
        const newItemSpan = $(this).closest('tr').find('.new-item');

        let matchFound = false;

        listItems.each(function() {
          const listItemText = $(this).text().toLowerCase();
          if (listItemText.indexOf(searchTerm) === -1) {
            $(this).hide(); // Si le texte ne correspond pas, masquer l'élément
          } else {
            $(this).show(); // Sinon, afficher l'élément
            matchFound = true;
          }
        });

        // Si aucune correspondance n'est trouvée, afficher l'option pour ajouter un élément
        if (!matchFound && searchTerm.trim() !== '') {
          noMatchSuggestion.show();
          newItemSpan.text(searchTerm); // Afficher la saisie de l'utilisateur comme élément suggéré
        } else {
          noMatchSuggestion.hide(); // Cacher l'option d'ajout si une correspondance est trouvée
        }
      });

      // Ajouter un gestionnaire d'événements sur les éléments de la liste pour permettre la sélection
      $('#myTable tbody').on('click', '.list_item ul li', function() {
        const selectedText = $(this).text(); // Récupérer le texte de l'élément sélectionné
        $(this).closest('tr').find('input[name="nature"]').val(selectedText); // Mettre à jour la valeur du champ "nature"
        $(this).closest('tr').find('.list_item').hide();
      });

      // Ajouter le nouvel élément à la liste
      $('#myTable tbody tr:last .no-match-suggestion').on('click', function() {
        const searchTerm = $(this).closest('tr').find('input[name="nature"]').val().trim();
        addItemToApi(searchTerm);
      });
    });


    // Fonction pour charger les éléments de la liste depuis l'API
    function loadListItems(row) {
      if (self.gettedInfractions && Array.isArray(self.gettedInfractions)) {
        self.gettedInfractions.forEach((item) => {
          // Ajoutez chaque item à la liste dans la ligne spécifiée
          row.find('.list_item ul').append('<li>' + item.name + '</li>');
        });
      } else {
        console.error('Données invalides reçues de l\'API');
      }
    }

    function addItemToApi(itemName) {


      const config: ModalOptions = {
        initialState: {
          label: itemName,
          type: 'infraction'
        }
      };

      console.log('openingSuccess');
      return new Promise<string>((resolve, reject) => {
        self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-lg'}));
        self.modalRef.content.parentModal = self.modalRef;
        self.modalRef.onHidden.subscribe((value: string) => {
          resolve(value); // Résoudre la promesse avec la valeur retournée
        });
      }).then(async (value: string) => {
        console.log('in addinfraction', value);

        if ( value == "success") {
          self.enrole.getAllInfractions().then(res=>{
            self.gettedInfractions = res.data

          })

        }

      // self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-sm'}));
    })

     // self.modalRef = self.modalService.show(AddInfractionsComponent, Object.assign({}, config, {class:'modal-sm'}));
    }



  }
}
