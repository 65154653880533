<!DOCTYPE html>
<html lang="fr">

<body>
        <div class="d-flex  justify-content-end mr-4 pt-3"  (click)="onPrint2(printElement)">
            <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
            <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
          </div>
        <div class="document-container p-3" #elementToPrint ngxPrintElement *ngIf="isMultiple == true">
            <ng-container  style="min-height:297px ; "  *ngFor="let item of data" >
                <div style="page-break-after: always;">
                    <section class="header "  >
                        <p class="mt-1" style=" min-width: 300px;">
                            <b>République du sénégal</b> <br>
                            <i>un peuple - un but - une foi</i><br>
                            <span>--------------------------------------</span><br>

                                <i>Cour d'appel de Dakar</i> <br>
                                <i [innerHTML]="Tribunal"></i><br>


                                    <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">

                        </p>
                        <div class="center-aligned text-center" style="margin-right: 10%;">
                            <div class="d-flex justify-content-center">
                                <h4>EXTRAIT DU CASIER JUDICIAIRE</h4>
                            </div>

                            <h5>BULLETIN {{item.type_de_casier}}</h5>
                            <p [innerHTML]="item.message_casier" ></p>
                        </div>
                      </section>


                    <div class="content -mcontent">

                        <section class="greffe">
                            <div >
                                <strong>
                                <p>GREFFE <br>
                                    REF. N°<b>{{item.num_casier != null ? item.num_casier : '......'}}</b>
                                </p>
                                </strong>

                                <p>
                                    <!-- Timbre: ..... -->
                                    <br>Frais: <b>{{item.type_de_casier == 'B3'? 200: '.....'}}</b> Fcfa
                                </p>
                                <strong>
                                    <p>Total: <b>{{item.type_de_casier == 'B3'? 200: '.....'}}</b> Fcfa</p>
                                </strong>
                            </div>



                            <div>
                              <!-- value="{{verification}}CJ_{{item.name}}"-->
                              <!-- <ngx-qrcode
                                errorCorrectionLevel="M"
                                [width]="120"
                                value="CJ_{{item.name}}"
                                alt="Demo QR Code"
                                cssClass="bshadow" class="myqr">
                              </ngx-qrcode> <br> -->


                              <!-- <qrcode class="qr-code " [qritem]="item.name" imageSrc="../../../assets/images/ejustice.png" errorCorrectionLevel="'M'"></qrcode> -->

                                <!-- <img src='https://api.services.justice.sn/qr-code.png' class='qr-code'> -->
                            </div>

                        </section>
                        <section class="form" style="margin-top: -20px;">
                            <div class="field">
                                <label for="nom">{{ item.genre === 'F' ? 'La nommée' : (item.genre === 'M' ? 'Le nommé' : 'L( ) nommé(e)') }}</label>
                                <div class="input" id="nom">{{item.prenom}} {{item.nom}}</div>
                            </div>
                            <div class="field">
                                <label for="father">{{ item.genre === 'F' ? 'Fille de' : (item.genre === 'M' ? 'Fils de' : 'Fil( ) de') }}</label>
                                <div class="input" id="father">{{item.nom_du_pere}}</div>
                            </div>
                            <div class="field">
                                <label for="mother">Et de </label>
                                <div class="input" id="mother">{{item.nom_de_la_mere}} </div>
                            </div>
                            <div class="field">
                                <label for="birth">{{ item.genre === 'F' ? 'Née le' : (item.genre === 'M' ? 'Né le' : 'Né(e) le') }}</label>
                                <div class="input" id="birth">{{getFormattedDate(item.date_de_naissance)}}</div>
                            </div>
                            <div class="field">
                                <label for="birthplace">À</label>
                                <div class="input" id="birthplace">{{item.lieu_de_naissance}}</div>
                            </div>
                            <div class="field">
                                <label for="domicile">Domicile</label>
                                <div class="input" id="domicile">{{item.domicile}}</div>
                            </div>
                            <div class="field">
                                <label for="status">État Civil de Famille</label>
                                <div class="input" id="status">{{item.etat_civil}}</div>
                            </div>
                            <div class="field">
                                <label for="profession">Profession</label>
                                <div class="input" id="profession">{{item.profession}} </div>
                            </div>
                            <div class="field">
                                <label for="nationality">Nationalité</label>
                                <div class="input" id="nationality">{{item.nationalite}}</div>
                            </div>
                        </section>
                    </div>


                      <section class="records-table text-center" >
                        <div [innerHTML]="item.json_infractions"></div>
                      </section>


                      <footer>

                        <div class="text-content mfooter col-md-12 col-lg-12 col-sm-12x d-flex justify-content-between">
                            <div class="visa">
                                <p><strong>VISA DU PARQUET</strong></p>
                                <p><em>Le Procureur de la République</em></p>
                            </div>
                            <div class="verification float-right">
                                <p>Pour le relevé conforme</p>
                                <p>Pikine-Guédiawaye, le {{getFormattedDate(item.date_de_generation)}}</p>
                                <p><em>L'Administrateur Des Gréffes</em></p>
                            </div>
                        </div>
                    </footer>

                </div>

            </ng-container>
        </div>

        <div class="document-container p-3" #elementToPrint ngxPrintElement *ngIf="isMultiple != true">
            <section class="header "  >
                <p class="mt-1" style=" min-width: 300px;">
                    <b>République du sénégal</b> <br>
                    <i>un peuple - un but - une foi</i><br>
                    <span>--------------------------------------</span><br>

                        <i>Cour d'appel de Dakar</i> <br>
                        <i [innerHTML]="Tribunal"></i><br>


                            <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">

                </p>
                <div class="center-aligned text-center" style="margin-right: 10%;">
                    <div class="d-flex justify-content-center">
                        <h4>EXTRAIT DU CASIER JUDICIAIRE</h4>
                    </div>

                    <h5>BULLETIN {{data.type_de_casier}}</h5>
                    <p [innerHTML]="data.message_casier" ></p>
                </div>
              </section>


            <div class="content -mcontent">

                <section class="greffe">
                    <div >
                        <strong>
                        <p>GREFFE <br>
                            REF. N°<b>{{data.num_casier != null ? data.num_casier : '......'}}</b>
                        </p>
                        </strong>

                        <p>
                            <!-- Timbre: ..... -->
                            <br>Frais: <b>{{data.type_de_casier == 'B3'? 200: '.....'}}</b> Fcfa
                        </p>
                        <strong>
                            <p>Total: <b>{{data.type_de_casier == 'B3'? 200: '.....'}}</b> Fcfa</p>
                        </strong>
                    </div>



                    <div>
                      <!-- value="{{verification}}CJ_{{data.name}}"-->
                      <!-- <ngx-qrcode
                        errorCorrectionLevel="M"
                        [width]="120"
                        value="CJ_{{data.name}}"
                        alt="Demo QR Code"
                        cssClass="bshadow" class="myqr">
                      </ngx-qrcode> <br> -->


                      <!-- <qrcode class="qr-code " [qrdata]="data.name" imageSrc="../../../assets/images/ejustice.png" errorCorrectionLevel="'M'"></qrcode> -->

                        <!-- <img src='https://api.services.justice.sn/qr-code.png' class='qr-code'> -->
                    </div>

                </section>
                <section class="form" style="margin-top: -20px;">
                    <div class="field">
                        <label for="nom">{{ data.genre === 'F' ? 'La nommée' : (data.genre === 'M' ? 'Le nommé' : 'L( ) nommé(e)') }}</label>
                        <div class="input" id="nom">{{data.prenom}} {{data.nom}}</div>
                    </div>
                    <div class="field">
                        <label for="father">{{ data.genre === 'F' ? 'Fille de' : (data.genre === 'M' ? 'Fils de' : 'Fil( ) de') }}</label>
                        <div class="input" id="father">{{data.nom_du_pere}}</div>
                    </div>
                    <div class="field">
                        <label for="mother">Et de </label>
                        <div class="input" id="mother">{{data.nom_de_la_mere}} </div>
                    </div>
                    <div class="field">
                        <label for="birth">{{ data.genre === 'F' ? 'Née le' : (data.genre === 'M' ? 'Né le' : 'Né(e) le') }}</label>
                        <div class="input" id="birth">{{getFormattedDate(data.date_de_naissance)}}</div>
                    </div>
                    <div class="field">
                        <label for="birthplace">À</label>
                        <div class="input" id="birthplace">{{data.lieu_de_naissance}}</div>
                    </div>
                    <div class="field">
                        <label for="domicile">Domicile</label>
                        <div class="input" id="domicile">{{data.domicile}}</div>
                    </div>
                    <div class="field">
                        <label for="status">État Civil de Famille</label>
                        <div class="input" id="status">{{data.etat_civil}}</div>
                    </div>
                    <div class="field">
                        <label for="profession">Profession</label>
                        <div class="input" id="profession">{{data.profession}} </div>
                    </div>
                    <div class="field">
                        <label for="nationality">Nationalité</label>
                        <div class="input" id="nationality">{{data.nationalite}}</div>
                    </div>
                </section>
            </div>


              <section class="records-table text-center" >
                <div [innerHTML]="data.json_infractions"></div>
              </section>


              <footer>

                <div class="text-content mfooter col-md-12 col-lg-12 col-sm-12x d-flex justify-content-between">
                    <div class="visa">
                        <p><strong>VISA DU PARQUET</strong></p>
                        <p><em>Le Procureur de la République</em></p>
                    </div>
                    <div class="verification float-right">
                        <p>Pour le relevé conforme</p>
                        <p>Pikine-Guédiawaye, le {{getFormattedDate(data.date_de_generation)}}</p>
                        <p><em>L'Administrateur Des Gréffes</em></p>
                    </div>
                </div>
            </footer>

        </div>

</body>

</html>
