import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NaturalisationService {

  Doctype_naturalisation_t ='Traitement Naturalisation'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}

 // NATURALISATION

    async getAllDemandes(filter=null) {
      try {
        let my_rdvs = await this.frapper.GetDoctypes(this.Doctype_naturalisation_t,'["*"]',filter).toPromise()

        //console.log('My Courriers Principale ===> ', my_courriers)
        return my_rdvs
      } catch (error) {
        this.authservice.handleError(error)
      }
      
    }

  async getOneDemandes(rdvName) {
    try {
      let my_rdv = await this.frapper.GetDoctype(this.Doctype_naturalisation_t,rdvName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_rdv
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async nouvelleDemande(body) {
    try {
      let my_rdv = await this.frapper.CreateDoctype(this.Doctype_naturalisation_t,body).toPromise()
      return my_rdv
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_naturalisation_t,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async updateTraitement(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_naturalisation_t,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_naturalisation_t,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

 // ** naturalisaiton
}
