<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>

<body style="min-height: 600px; overflow: scroll;">
  <button class='btn btn-success  m-3' [disabled]="!CAN_SAVE" (click)="save(showTypeData)"><i class="fas fa-regular fa-floppy-disk text-light"></i> Sauvegarder</button>

  <st-editor  #stEditor *ngIf="showTypeData == 'factas' " (pointerdown)="onPointerDown($event)"    [(ngModel)]="content_factas" [disabled]="!CAN_EDIT_FACTAS"></st-editor>
  <st-editor  #stEditor *ngIf="showTypeData == 'qualites'" (pointerdown)="onPointerDown($event)" [(ngModel)]="content_qualite" [disabled]="!CAN_EDIT_QUALITE">
    <!-- Utilisez la balise <p> ou <div> pour afficher le nom complet du plaignant avec du HTML -->
  </st-editor>

  <!-- <st-editor [(ngModel)]="content_default" [disabled]="!CAN_EDIT_NOTE"  *ngIf="showTypeData != 'factas' && showTypeData != 'qualites';" ></st-editor> -->
  <st-editor
  #stEditor
  [(ngModel)]="content_default"
  [disabled]="CAN_EDIT_NOTE && editable ? false : true "
  *ngIf="showTypeData != 'factas' && showTypeData != 'qualites'"
  (pointerdown)="onPointerDown($event)"
  >
</st-editor>





</body>
</html>
