import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { PenalCourrierService } from '../../../services/penal.courrier/penal.courrier.service';
import { AddNewDocComponent } from '../../../dialogs/add-new-doc/add-new-doc.component';
import { PublicService } from '../../../services/public/public.service';
import { AlertesService } from '../../../services/public/alertes.service';
import { AddPublicServiceComponent } from '../../../dialogs/add-public-service/add-public-service.component';
import { AddPaymentComponent } from '../../../dialogs/add-payment/add-payment.component';
import { ViewDetailsServicepublicComponent } from '../../../dialogs/view-details-servicepublic/view-details-servicepublic.component';
import { PageEvent } from '@angular/material/paginator';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import {GreffierService} from "../../../services/greffier/greffier.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dashboard-public',
  templateUrl: './dashboard-verification-doc.component.html',
  styleUrls: ['./dashboard-verification-doc.component.scss']
})
export class DashboardVerificationDocComponent {

  code_verification

  DIC=[
    {label:'Casier Judiciaire',code:'CJ'},
    {label:'Certificat de nationalite',code:'CN'},

  ]

  constructor(
              private modalService: BsModalService,
              private route: ActivatedRoute,
              private alert:AlertesService,
              private serviceTest:GreffierService, private ngxService: NgxUiLoaderService) {


  }

  decodeur(){
    let result = this.code_verification.split('_')
    console.log(result)
    let type_Document_demande=  (this.DIC.filter(obj => obj.code === result[0]))[0]
    if(type_Document_demande){
      console.log(type_Document_demande)
      this.alerteNotifications('OK',type_Document_demande.label)
    }else {
      this.alerteNotifications('NOK','Document','est un Faux')
      //console.log('Cest un faut')
    }

  }

  alerteNotifications(data,document='Casier Judiciaire',datail_reject='non valide '){
    this.ngxService.start();
    let casier = this.serviceTest.casiers[0]

    if(data=='OK'){
      this.alert.successNotification('Verification De document',document+' de '+casier.prenom+' ' +casier.nom +'  valide !')
      // this.alertService.danger('Connection rejected, please try again');
      setTimeout(() => {
        this.ngxService.stop();
        // this.loading = false;
      }, 1000);
    }else{
      this.alert.errorNotification('Verification De document',document+' est '+datail_reject+' !')
      // this.alertService.danger('Connection rejected, please try again');
      setTimeout(() => {
        this.ngxService.stop();
        // this.loading = false;
      }, 1000);
    }


  }



  ngOnInit(): void {
    this.code_verification = this.route.snapshot.paramMap.get('code_verification');
    this.decodeur()
  }
}
