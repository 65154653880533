

<!DOCTYPE html>
<html>
	<head>
		<title>service-public</title>
		<meta charset="utf-8">
		<meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
	<!-- <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css" /> -->
	</head>
	<body *ngIf="DEMANDE_TO_FORMS.length>0;">

		<div class="page" *ngIf="DEMANDE_TO_FORMS != null">
      <div class="d-flex justify-content-between">
        <h5 class="text-light text-center" style="font-size: 11px;">{{data.name}} - {{operation_ref}}</h5>
        <div class="addButton  text-nowrap  mr-2"   *ngIf="traitement_ref!=null"  >
          <div class="button b2  w-auto text-light  float-right mt-2 mr-5 "  (click)="openDemandeDetailDialog()" >
            Voir réponse</div>
        </div>
        <button type="button"  class="btn btn-link position-absolute" style="top: 10px; right: 10px;" aria-label="Close" (click)="closeAllDialogs('uu')">
          <i class="fas fa-times text-light" style="font-size: 1.5rem; "></i>
        </button>
      </div>


    <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1 mt-1  col-sm-12 col-lg-12 col-md-12 " >
      <ng-container *ngIf=" operation_ref_ext!=null">
        <input
          type="radio"
          name="pcss3t"
          [id]="'tab0'"
          [checked]=" operation_ref_ext!= null"
          [ngClass]="'tab-content-0'"
          class="tab-input "
        >
        <label [for]="'tab0'">
          <i [class]="'XXX'"></i><strong>{{operation_ref_ext!=null ? 'Demande':'Liaison' }}</strong>
        </label>
      </ng-container>

      <ng-container *ngFor="let form of DEMANDE_TO_FORMS; let i = index">
        <input
          type="radio"
          name="pcss3t"
          [id]="'tab' + (i)"
          [checked]="(i === 0 && (operation_ref_ext == null ))"
          [ngClass]="'tab-content-' + (i)"
          class="tab-input "
        >
        <label [for]="'tab' + (i)">
          <i [class]="form.icon"></i><span>{{form.formulaire?.titre}}</span>
        </label>
      </ng-container>

      <ul class="tab-content-list">
        <!-- <div class="col-4 ">
           <img class="mt-5" src="../../../../assets/images/ejustice.png" alt="">
        </div> -->
        <!-- <ng-container  *ngIf="traitement_ref==null || operation_ref_ext!=null">
          <li [id]="'tab0-content'" [ngClass]="'tab-content-0'" class="tab-content typography">
            <button class="btn btn-lg btn-outline-primary"  (click)="openDemandeDetailDialog()" style="width: 100%">
              Voir informations
            </button>
          </li>
        </ng-container> -->

        <ng-container *ngFor="let form of DEMANDE_TO_FORMS; let i = index" >
          <li [id]="'tab' + (i) + '-content'" [ngClass]="'tab-content-' + (i)" class="tab-content typography">
            <div class="form-card">
                <div class="row">
                    <div class="col-12 col-md-6 mb-1" style="padding: 0 2px 0 2px;" *ngFor="let entrie of form.formulaire?.entries; let j = index">
                        <label for="{{ entrie.key }}" class="fieldlabels">
                            <span *ngIf="entrie.entries_type.type != 'file'">{{ entrie.name }} </span>
                        </label>

                        <input
                            *ngIf="entrie.entries_type.object == 'INPUT' && entrie.entries_type.type != 'file'"
                            [type]="entrie.entries_type.type"
                            [name]="form.formulaire?.ref + '#' + entrie.key"
                            [pattern]="entrie.pattern"
                            [value]="entrie.value ?? null "
                            class="form-control"
                        />

                        <ng-container *ngIf="entrie.entries_type.object == 'INPUT' && entrie.entries_type.type == 'file'">
                            <label class="custom-file-upload m-auto text-center">
                                <div class="icon">
                                    <i class="fas fa-regular fa-file-image" style="font-size: 30px;"></i>
                                </div>
                                <div class="text text-nowrap">
                                    <span class="file-label">{{entrie.name}}</span><br>
                                </div>
                                <input
                                    [name]="form.formulaire?.ref + '#' + entrie.key"
                                    [pattern]="entrie.pattern"
                                    [attr.required]="entrie.is_required == 1 ? true : null"
                                    class="form-control file-input"
                                    (click)="openModalVisualPiece(entrie.value)"
                                />
                            </label>
                        </ng-container>

                        <select
                            name="{{ form.formulaire.ref + '#' + entrie.key }}"
                            class="form-control"
                            *ngIf="entrie.entries_type.object == 'SELECT'"
                        >
                            <option value="-1" selected>{{ entrie.value }}</option>
                            <option *ngFor="let option of entrie.datasource_value" [value]="option.value">{{ option.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </li>
        </ng-container>

      </ul>

  </div>
  <div class="row" style="margin-top: 2vh">

    <div class="col-sm-12 col-lg-4 col-md-5">

      <button class="btn btn-outline-warning text-center text-light text-nowrap "  *ngIf="IS_TRAITEMENT && SERVICE.type=='D'"  [disabled]="(loadingServiceForms || STATUS=='NOK')" (click)="openTraitementFormDialog()" style="width: 100%">
        Traiter la demande
      </button>
      <button class="btn btn-success text-center text-nowrap "  *ngIf="IS_TRAITEMENT && SERVICE.type=='T'"  [disabled]="loadingServiceForms" (click)="genererDoc()" style="width: 100%">
        Générer le Document
      </button>
    </div>
    <!--<mat-dialog-actions align="end" style="margin-top: 10px; display: flex; justify-content: space-between;">
      <button class="btn btn-danger" (click)="closeAllDialogs()" style="margin-left: 10px;">Fermer </button> &nbsp;
      <button class="btn btn-primary" *ngIf="IS_TRAITEMENT"  [disabled]="loadingServiceForms" (click)="genererDoc()" style="margin-right: 10px;">Générer le Document</button>
    </mat-dialog-actions>-->
  </div>


		</div>
      

	</body>
  <!-- <ng-template #templateName>
    <p>aucune donnée</p>
  </ng-template> -->
  <ngx-ui-loader [loaderId]="'detaildemande'"></ngx-ui-loader>
</html>









