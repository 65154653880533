import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChambresService {

  Doctype_chambres ='Chambres'

  my_tribunal:any = null
  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {

  }





 // Chambre
  setTribunal(){
    try{
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }
  }

  async getAllChambreByType(type) {
    this.setTribunal()
    try{
      const body= {
        "type":type,
        "tribunal":this.my_tribunal==null?null:this.my_tribunal.code
      }
      //console.log('body ==> ',body)
      let chambres = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.chambres.chambres.get_chambre_by_type',body).toPromise()
      return chambres
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllMyChambresWhereIm(type:any=0) {
    this.setTribunal()
    let me = localStorage.getItem('me')

    try{
      const body= {
        "user":JSON.parse(me).name,
        "type":type,
        "tribunal":this.my_tribunal==null?null:this.my_tribunal.code
      }
      //console.log('body ==> ',body)
      let chambres = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.chambres.chambres.get_my_chambres',body).toPromise()
      return chambres
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllMyChambre(filter =null) {
    this.setTribunal()
    try {
      let my_chambres = await this.frapper.GetDoctypes(this.Doctype_chambres,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_chambres
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneChambre(name) {
    this.setTribunal()
    try {
      let my_chambres = await this.frapper.GetDoctype(this.Doctype_chambres,name,'["*"]',null).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_chambres
    } catch (error) {
      this.authservice.handleError(error)
    }

  }
  async getMembreChambreToAssign(name) {
    this.setTribunal()
    try{
      const body= {
        "name":name,
        "tribunal":this.my_tribunal==null?null:this.my_tribunal.code
      }
      //console.log('body ==> ',body)
      let chambre = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.chambres.chambres.get_president_of_chambre_to_assign',body).toPromise()
      return chambre
    } catch (error) {
      this.authservice.handleError(error)
    }

  }


 // ** Chambre
}
