import { Component, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AmandeService } from '../../services/frapper/amande.service';
import { DossierService } from '../../services/frapper/dossier.service';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { JugementsService } from '../../services/frapper/jugements.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import * as $ from 'jquery';
import { FormStepperComponent } from '../add-courrier/form-stepper.component';

@Component({
  selector: 'app-view-details-transmissioncourrier',
  templateUrl: './view-details-transmissioncourrier.component.html',
  styleUrls: ['./view-details-transmissioncourrier.component.scss']
})
export class ViewDetailsTransmissioncourrierComponent {
  @Input() parentModal: BsModalRef;
  isLinear = true;
  FormEnrollement: FormGroup;
  data: any;
  courrier_origine:any;
  type: any;
  step: any;
  modalRef!: BsModalRef;
 number_courrier: any;

  profil
  allowowed_send_courrier_depart=false

  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private jugementService: JugementsService,
              private amendeService:AmandeService,
              private role: EnrollementService,
              private alerte:AlertesService,
              private bsModalRef: BsModalRef,
              private dossierService: DossierService,
              private ngxService: NgxUiLoaderService,
              private penalService: PenalCourrierService) {

    this.data = options.initialState.st_dateil;
    this.courrier_origine = options.initialState.courrier_origine;

    this.type = options.initialState.type;
    this.step = options.initialState.step;
    this.number_courrier = options.initialState.numberCourrier;

    //console.log(this.data)
  }




  openModalSuccessDialog(message: string) {
    this.ngxService.start()
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
    this.ngxService.stop()
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  closeModal(){
    this.bsModalRef.hide()
  }

  openModalAddCourier(type_courrier: string) {
    const config: ModalOptions = {
      initialState: {
        typeCourrier: type_courrier,
        //courrier_origine:this.courrier_origine,
        parentModal: null, // Initialiser parentModal à null
        courrierOrigine: this.number_courrier
      }
    }


    // Ouvrir le modal
    this.bsModalRef.hide()
    const modalRef = this.modalService.show(FormStepperComponent, Object.assign({}, config, { class: 'modal-lg modal-dialog-centered' }));
    
    // Mettre à jour l'option initialState avec le modalRef
    modalRef.content.parentModal = modalRef;

  }







  ngOnInit() {
    this.profil= localStorage.getItem('profil')
    if(this.profil.includes('COUR')){
      this.allowowed_send_courrier_depart=true
    }

  }
}
