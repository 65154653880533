import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegistreService {


  Doctype_registre_courrier ='type courrier'

  constructor(private http: HttpClient, private frapper: FrapperService, private authservice: AuthService, private assigneService:AssignedService) {}

  // Registre Courrier

  async getAllRegistre(filter=null) {
    try {
      let documents = await this.frapper.GetDoctypes(this.Doctype_registre_courrier,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return documents
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneRegistre(documentName) {
    try {
      let document = await this.frapper.GetDoctype(this.Doctype_registre_courrier,documentName,'["*"]',null).toPromise()
      //document.log('Information Of The Courriers ===> ', my_courrier)
      return document
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getOneRegistreNoAuth(type_de_courrier=null) {
    try {
      const body= {registre:type_de_courrier}
      let registre = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.type_courrier.type_courrier.get_information_of_registre',body).toPromise()
      return registre
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async nouveauRegistre(body) {
    try {
      let my_document = await this.frapper.CreateDoctype(this.Doctype_registre_courrier,body).toPromise()
      return my_document
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async updateRegistre(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_registre_courrier,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async assignRegistreToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_registre_courrier,body.name,body.user)
    return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getNumInformationRegistre(type_de_courrier=null) {
    try {
      let me = localStorage.getItem('me')
      const body= {utilisateur:JSON.parse(me).name,registre:type_de_courrier}
      console.log('body ==> ',body)
      let courrier = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.type_courrier.type_courrier.get_number_of_element_in_registre',body).toPromise()
      return courrier
    } catch (error) {
      this.authservice.handleError(error)
    }

  }

  async getAllCourrierOfRegistre(type_de_courrier=null) {
    try {
      const body= {registre:type_de_courrier}
      let courriers = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.type_courrier.type_courrier.get_all_courrier_in_registre',body).toPromise()
      return courriers
    } catch (error) {
      this.authservice.handleError(error)
    }

  }


 // ** registre Courrier
}
